import React, { useMemo, useRef, useState } from "react";
import "./GroupeCardGestion.scss";
import PropTypes from "prop-types";
import { Menu } from "primereact/menu";
import BtnRond from "Components/Boutons/BtnRond/btn-rond";
import { FaEllipsisH } from "react-icons/fa";
import img_groupe1 from "assets/img_groupe1.webp";
import img_groupe2 from "assets/img_groupe2.webp";
import img_groupe3 from "assets/img_groupe3.webp";
import img_groupe4 from "assets/img_groupe4.webp";
import img_groupe5 from "assets/img_groupe5.webp";
import img_groupe6 from "assets/img_groupe6.webp";
import { stripHtml, truncate } from "Services/functions";
import { fetchDelete, fetchGet, fetchPost } from "Services/api";
import { connect } from "react-redux";
import ModalEditerGroupe from "Components/Modal/ModalEditerGroupe/ModalEditerGroupe";
import { useEffect } from "react";
import ModalDroitsAdminGroupe from "Components/Modal/ModalDroitsAdminGroupe/ModalDroitsAdminGroupe";
import ModalAjoutMembres from "Components/Modal/ModalAjoutMembres/ModalAjoutMembres";

const GroupeCardGestion = (props) => {
  const menuRef = useRef(null);

  const [currentGroup, setCurrentGroup] = useState(null);

  const [editGroup, setEditGroup] = useState({
    group: null,
    state: null,
    result: null,
  });

  const [promoteUser, setPromoteUser] = useState({
    state: null,
    result: null,
  });

  const [addMembers, setAddMembers] = useState({
    group: null,
    state: null,
    result: null,
  });

  const [groupMembersCount, setGroupMembersCount] = useState(null);

  useMemo(async () => {
    let count = undefined;
    if (props.group.id)
      count = await fetchGet(
        `/groups/${props.group.id}/number_of_active_members_in_the_group`,
        props.auth.token
      );
    count && typeof count === "number" && setGroupMembersCount(count);
  }, [props.group.id]);

  /**
   *  Gère la réponse de la requête
   * @param {} response - Réponse de la requête
   * @param {{detail: String, header: String}} message - Message à afficher
   */
  const handleResponse = (response, message) => {
    if (response == undefined)
      response = {
        status: 300,
        data: {
          title: "",
        },
      };
    const isError = response.status >= 400;
    props.setManagementHandler({
      visible: true,
      type: !isError ? "success" : "error",
      header: message.header || "",
      detail: !isError
        ? message.detail || response?.message || "Opération réussie"
        : response?.data["title"] ||
          response?.data["hydra:description"] ||
          "Une erreur est survenue",
    });
  };

  /**
   *  Gère les actions de groupe
   * @param {String} url - Url de la requête
   * @param {{detail: String, header: String}} message - Message à afficher
   * @param {String} method - Méthode de la requête
   * @param {any} payload - Données à envoyer
   */
  const handleGroupAction = async (
    url,
    message,
    method = "GET",
    payload = null,
    type = null
  ) => {
    var request = null;

    switch (method) {
      case "GET":
        request = await fetchGet(url, props.auth.token);
        break;
      case "POST":
        request = await fetchPost(url, payload, props.auth.token, null, type);
        break;
      case "DELETE":
        request = await fetchDelete(url, props.auth.token);
        break;
      default:
        break;
    }

    const { response } = request;

    handleResponse(response, {
      detail: message.detail,
      header: message.header,
    });
  };

  useEffect(async () => {
    if (props.group && props.group.id) {
      setCurrentGroup(
        await fetchGet("/groups/" + props.group.id, props.auth.token)
      );
    }
  }, [props.group]);

  useEffect(async () => {
    if (editGroup.result) {
      props.setManagementHandler({
        visible: true,
        type: editGroup.result.status == 201 ? "success" : "error",
        detail:
          editGroup.result.status == 201
            ? "Le groupe a été édité"
            : "Une erreur est survenue",
        header: "Edition de groupe",
      });
    }
    setEditGroup({ ...editGroup, result: null, state: null });
  }, [editGroup.result]);

  useEffect(async () => {
    if (promoteUser.result) {
      props.setManagementHandler({
        visible: true,
        type: promoteUser.result.status == 200 ? "success" : "error",
        header: "Nouvel administrateur",
        detail:
          promoteUser.result.status == 200
            ? "L'utilisateur est désormais administrateur"
            : promoteUser.result.data["hydra:description"],
      });
    }
    setPromoteUser({ ...promoteUser, result: null, state: null });
  }, [promoteUser.result]);

  useEffect(async () => {
    if (addMembers.result) {
      setCurrentGroup(
        await fetchGet("/groups/" + props.group.id, props.auth.token)
      );
    }
    setAddMembers({ result: null, group: null, state: null });
  }, [addMembers.result]);

  const handlePromoteUser = async () => {
    setPromoteUser({ ...promoteUser, state: true, group: currentGroup });
  };

  const itemsActif = [
    {
      label: "Modifier",
      command: () => {
        setEditGroup({ ...editGroup, state: true, group: currentGroup });
      },
    },
    { separator: true },
    {
      label: "Inviter des membres",
      command: () => {
        setAddMembers({ ...addMembers, state: true, group: currentGroup });
      },
    },
    { separator: true },
    {
      label: "Donner droits admin",
      command: () => {
        handlePromoteUser();
      },
    },
    { separator: true },
    {
      label: "Désactiver",
      command: () => {
        let message = {
          detail: "Le groupe a été désactivé",
          header: "Désactivation du groupe",
        };
        handleGroupAction(
          "/groups/" + props.group.id + "/disable",
          message,
          "POST",
          "{}"
        );
      },
    },
    { separator: true },
    {
      label: "Supprimer",
      command: () => {
        let message = {
          detail: "Le groupe a été supprimé",
          header: "Suppression du groupe",
        };
        handleGroupAction("/groups/" + props.group.id, message, "DELETE");
      },
    },
  ];

  const itemsDesactiveAdmin = [
    {
      label: "Modifier",
      command: () => {
        setEditGroup({ ...editGroup, state: true, group: currentGroup });
      },
    },
    { separator: true },
    {
      label: "Inviter des membres",
      command: () => {
        setAddMembers({ ...addMembers, state: true, group: currentGroup });
      },
    },
    { separator: true },
    {
      label: "Donner droits admin",
      command: () => {
        handlePromoteUser();
      },
    },
    { separator: true },
    {
      label: "Activer",
      command: () => {
        let message = {
          detail: "Le groupe a été activé",
          header: "Activation du groupe",
        };
        handleGroupAction(
          "/groups/" + props.group.id + "/enable",
          message,
          "POST",
          "{}"
        );
      },
    },
    { separator: true },
    {
      label: "Supprimer",
      command: () => {
        let message = {
          detail: "Le groupe a été supprimé",
          header: "Suppression du groupe",
        };
        handleGroupAction("/groups/" + props.group.id, message, "DELETE");
      },
    },
  ];

  const itemsDesactive = [
    {
      label: "Modifier",
      command: () => {
        setEditGroup({ ...editGroup, state: true, group: currentGroup });
      },
    },
    { separator: true },
    {
      label: "Inviter des membres",
      command: () => {
        setAddMembers({ ...addMembers, state: true, group: currentGroup });
      },
    },
    { separator: true },
    {
      label: "Donner droits admin",
      command: () => {
        handlePromoteUser();
      },
    },
    { separator: true },
    {
      label: "Demander à réactiver le groupe",
      command: () => {
        let message = {
          detail: "La demande a été envoyée",
          header: "Demande de réactivation",
        };
        handleGroupAction(
          "/groups/" + props.group.id + "/request_validation_again",
          message,
          "POST",
          "{}",
          "multipart/form-data"
        );
      },
    },
    { separator: true },
    {
      label: "Supprimer",
      command: () => {
        let message = {
          detail: "Le groupe a été supprimé",
          header: "Suppression du groupe",
        };
        handleGroupAction("/groups/" + props.group.id, message, "DELETE");
      },
    },
  ];

  const itemsInvite = [
    {
      label: "Accepter",
      command: () => {
        let message = {
          header: "Acceptation de l'invitation",
          detail: "L'invitation au groupe a été acceptée",
        };
        handleGroupAction(
          "/group_members/invite/" + props.inviteId + "/accept",
          message,
          "POST",
          "{}"
        );
      },
    },
    { separator: true },
    {
      label: "Refuser",
      command: () => {
        let message = {
          header: "Refus de l'invitation",
          detail: "L'invitation au groupe a été refusée",
        };
        handleGroupAction(
          "/group_members/invite/" + props.inviteId + "/refuse",
          message,
          "POST",
          "{}"
        );
      },
    },
  ];

  const itemsAttente = [
    {
      label: "Modifier",
      command: () => {
        setEditGroup({ ...editGroup, state: true, group: currentGroup });
      },
    },
    { separator: true },
    {
      label: "Inviter des membres",
      command: () => {
        setAddMembers({ ...addMembers, state: true, group: currentGroup });
      },
    },
    { separator: true },
    {
      label: "Annuler la demande",
      command: () => {
        let message = {
          header: "Annulation de la demande",
          detail: "La demande de validation du groupe a été annulée",
        };
        handleGroupAction(
          "/groups/" + props.group.id + "/disable",
          message,
          "POST",
          "{}"
        );
      },
    },
  ];
  const itemsAttenteAdmin = [
    {
      label: "Accepter",
      command: () => {
        let message = {
          header: "Acceptation de la demande",
          detail: "La demande de validation du groupe a été acceptée",
        };
        handleGroupAction(
          "/groups/" + props.group.id + "/validate",
          message,
          "POST",
          "{}"
        );
      },
    },
    { separator: true },
    {
      label: "Refuser",
      command: () => {
        let message = {
          header: "Refus de la demande",
          detail: "La demande de validation du groupe a été refusée",
        };
        handleGroupAction(
          "/groups/" + props.group.id + "/refuse",
          message,
          "POST",
          "{}"
        );
      },
    },
  ];

  const itemsRefuse = [
    {
      label: "Modifier",
      command: () => {
        setEditGroup({ ...editGroup, state: true, group: currentGroup });
      },
    },
    { separator: true },
    {
      label: "Inviter des membres",
      command: () => {
        setAddMembers({ ...addMembers, state: true, group: currentGroup });
      },
    },
    { separator: true },
    {
      label: "Supprimer",
      command: () => {
        let message = {
          detail: "Le groupe a été supprimé",
          header: "Suppression du groupe",
        };
        handleGroupAction("/groups/" + props.group.id, message, "DELETE");
      },
    },
    { separator: true },
    {
      label: "Renouveler la demande",
      command: () => {
        let message = {
          detail: "La demande a été envoyée",
          header: "Demande de réactivation",
        };
        handleGroupAction(
          "/groups/" + props.group.id + "/request_validation_again",
          message,
          "POST",
          "{}",
          "multipart/form-data"
        );
      },
    },
  ];

  return (
    <>
      {currentGroup && (
        <div className="groupe_card_gestion_container">
          <div className="groupe_card_gestion_container__info">
            <div className="groupe_card_gestion_container__info__logo">
              {props.image == "img1" && (
                <img
                  src={img_groupe1}
                  alt="Logo du groupe"
                  title="Logo du groupe"
                />
              )}
              {props.image == "img2" && (
                <img
                  src={img_groupe2}
                  alt="Logo du groupe"
                  title="Logo du groupe"
                />
              )}
              {props.image == "img3" && (
                <img
                  src={img_groupe3}
                  alt="Logo du groupe"
                  title="Logo du groupe"
                />
              )}
              {props.image == "img4" && (
                <img
                  src={img_groupe4}
                  alt="Logo du groupe"
                  title="Logo du groupe"
                />
              )}
              {props.image == "img5" && (
                <img
                  src={img_groupe5}
                  alt="Logo du groupe"
                  title="Logo du groupe"
                />
              )}
              {props.image == "img6" && (
                <img
                  src={img_groupe6}
                  alt="Logo du groupe"
                  title="Logo du groupe"
                />
              )}
              {props.image == "link" && (
                <img
                  src={props.url}
                  alt="Logo du groupe"
                  title="Logo du groupe"
                />
              )}
            </div>
            <div>
              <h6 className="title">{truncate(currentGroup?.label, 45)}</h6>
              <h6 className="description">
                {truncate(stripHtml(currentGroup?.description), 45)}
              </h6>
              <div className="groupe_card_gestion_container__info__bottom">
                {currentGroup.groupCategory && (
                  <h2>
                    <i className="pi pi-tag"></i>{" "}
                    {currentGroup.groupCategory.label}
                  </h2>
                )}
                {!currentGroup.public ? (
                  <h2>
                    <i className="pi pi-lock"></i> Groupe privé{" "}
                  </h2>
                ) : (
                  <h2>
                    <i className="pi pi-lock-open"></i> Groupe public{" "}
                  </h2>
                )}

                <h2>
                  <i className="pi pi-users"></i>
                  {` ${groupMembersCount || 0} `}
                </h2>
              </div>
            </div>
          </div>
          <div className="groupe_card_gestion_container__bouton">
            {!isNaN(props.inviteId) ? (
              <>
                <div className="groupe_menu">
                  <Menu
                    appendTo={document.getElementById("app")}
                    model={itemsInvite}
                    popup
                    ref={menuRef}
                    style={{ textAlign: "center" }}
                  />
                  <BtnRond btnAction={(event) => menuRef.current.toggle(event)}>
                    <FaEllipsisH size={"1.5rem"} />
                  </BtnRond>
                </div>
                <span className="invite">Invité</span>
              </>
            ) : (
              <>
                {currentGroup.state &&
                  currentGroup.state.statusLabel == "Désactivé" && (
                    <>
                      <div className="groupe_menu">
                        <Menu
                          appendTo={document.getElementById("app")}
                          model={
                            props.admin ? itemsDesactiveAdmin : itemsDesactive
                          }
                          popup
                          ref={menuRef}
                          style={{ textAlign: "center" }}
                        />
                        <BtnRond
                          btnAction={(event) => menuRef.current.toggle(event)}
                        >
                          <FaEllipsisH size={"1.5rem"} />
                        </BtnRond>
                      </div>
                      <span className="desactive">
                        {currentGroup.state.statusLabel}
                      </span>
                    </>
                  )}
                {currentGroup.state &&
                  currentGroup.state.statusLabel == "Actif" && (
                    <>
                      <div className="groupe_menu">
                        <Menu
                          appendTo={document.getElementById("app")}
                          model={itemsActif}
                          popup
                          ref={menuRef}
                          style={{ textAlign: "center" }}
                        />
                        <BtnRond
                          btnAction={(event) => menuRef.current.toggle(event)}
                        >
                          <FaEllipsisH size={"1.5rem"} />
                        </BtnRond>
                      </div>
                      <span className="actif">
                        {currentGroup.state.statusLabel}
                      </span>
                    </>
                  )}
                {currentGroup.state &&
                  currentGroup.state.statusLabel == "En Attente" && (
                    <>
                      <div className="groupe_menu">
                        <Menu
                          appendTo={document.getElementById("app")}
                          model={props.admin ? itemsAttenteAdmin : itemsAttente}
                          popup
                          ref={menuRef}
                          style={{ textAlign: "center" }}
                        />
                        <BtnRond
                          btnAction={(event) => menuRef.current.toggle(event)}
                        >
                          <FaEllipsisH size={"1.5rem"} />
                        </BtnRond>
                      </div>
                      <span className="attente">
                        {currentGroup.state.statusLabel}
                      </span>
                    </>
                  )}
                {currentGroup.state &&
                  currentGroup.state.statusLabel == "Refusé" && (
                    <>
                      <div className="groupe_menu">
                        <Menu
                          appendTo={document.getElementById("app")}
                          model={itemsRefuse}
                          popup
                          ref={menuRef}
                          style={{ textAlign: "center" }}
                        />
                        <BtnRond
                          btnAction={(event) => menuRef.current.toggle(event)}
                        >
                          <FaEllipsisH size={"1.5rem"} />
                        </BtnRond>
                      </div>
                      <span className="refuse">
                        {currentGroup.state.statusLabel}
                      </span>
                    </>
                  )}
              </>
            )}
          </div>
        </div>
      )}
      {addMembers.state && (
        <ModalAjoutMembres visible={addMembers} setVisible={setAddMembers} />
      )}
      {promoteUser.state && (
        <ModalDroitsAdminGroupe
          visible={promoteUser}
          setVisible={setPromoteUser}
        />
      )}
      {currentGroup && editGroup.state && (
        <ModalEditerGroupe visible={editGroup} setVisible={setEditGroup} />
      )}
    </>
  );
};

GroupeCardGestion.propTypes = {
  group: PropTypes.object,
  setManagementHandler: PropTypes.func,
  admin: PropTypes.any,
  image: PropTypes.string,
  url: PropTypes.string,
  auth: PropTypes.object,
  inviteId: PropTypes.number,
};

GroupeCardGestion.defaultProps = {
  invited: NaN,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GroupeCardGestion);
