import React, { useEffect, useRef, useState } from "react";
import "./GroupePublication.scss";
import PropTypes from "prop-types";
import GroupePublicationCreation from "./GroupePublicationCreation/GroupePublicationCreation";
import GroupeCardPublication from "Components/Groupes/GroupeCard/GroupeCardPublication/GroupeCardPublication";
import { connect } from "react-redux";
import { Toast } from "primereact/toast";
import { updateGroups } from "Redux/Actions/groupsActions";
import ModalEditerCommentaire from "Components/Modal/ModalEditerCommentaire/ModalEditerCommentaire";
import ModalSignaler from "Components/Modal/ModalSignaler/ModalSignaler";
import ModalSupprimer from "Components/Modal/ModalSupprimer/ModalSupprimer";
import { useFetchGetPagination } from "Services/api";
import ModalEditerPublication from "Components/Modal/ModalEditerPublication/ModalEditerPublication";
import ModalDesactiverCommentaires from "Components/Modal/ModalDesactiverCommentaires/ModalDesactiverCommentaires";
import { Button } from "primereact/button";
import Loader from "Components/Loader/loader";

/**
 * Permet d'afficher une publication de groupe
 * Elles se trouvent uniquement dans le détail d'un groupe
 */
const GroupePublication = (props) => {
  const [publicationsUrl, setPublicationsUrl] = useState(undefined);
  const publicationsQuery = useFetchGetPagination(
    publicationsUrl,
    props.auth.token
  );
  const [publications, setPublications] = useState([]);
  // Gestion toast + popin pour suppression
  const deleteRef = useRef(null);
  const [deleteAction, setDeleteAction] = useState({
    id: null,
    visible: false,
    state: null,
    result: null,
  });

  const [voirPlus, setVoirPlus] = useState({
    display: false,
    amount: 5,
  });

  useEffect(async () => {
    // Si une demande de suppression d'un commentaire | publication a été faite :
    if (deleteAction.result) {
      // On affiche le toast de confirmation en fonction du type
      let message = "";
      if (deleteAction.type == "comment")
        message = "Ce commentaire a été supprimé avec succès";
      else if (deleteAction.type == "publication")
        message = "Cette publication a été supprimée avec succès";
      if (deleteAction.result.status == 204) {
        deleteRef.current.show({
          severity: "success",
          summary: "Succès",
          detail: message,
          life: 5000,
        });
        // On affiche le toast d'erreur
      } else {
        deleteRef.current.show({
          severity: "error",
          summary: "Erreur",
          detail: deleteAction.result,
          life: 5000,
        });
      }
    }
  }, [deleteAction.result]);

  // Gestion toast + popin pour édition d'un commentaire
  const editCommentRef = useRef(null);
  const [editComment, setEditComment] = useState({
    id: null,
    visible: false,
    state: null,
    result: null,
  });

  // Gestion toast + popin pour édition d'un commentaire
  const disableCommentsRef = useRef(null);
  const [disableComments, setDisableComments] = useState({
    id: null,
    type: null,
    detail: null,
    header: null,
    state: null,
    result: null,
  });

  function reloadPublications() {
    setPublications([]);
    setPublicationsUrl(new URL(publicationsUrl));
  }

  useEffect(async () => {
    // Si une requête de modification d'un commentaire a été faite :
    if (editComment.result) {
      // On affiche le toast de confirmation
      if (editComment.result.status == 200) {
        editCommentRef.current.show({
          severity: "success",
          summary: "Succès",
          detail: "Ce commentaire a été modifié avec succès",
          life: 5000,
        });
        createPublicationsURL();
        // On affiche le toast d'erreur
      } else {
        editComment.current.show({
          severity: "error",
          summary: "Erreur",
          detail: editComment.result,
          life: 5000,
        });
      }
    }
  }, [editComment.result]);

  // Gestion toast + popin pour édition d'une publication
  const editPublicationRef = useRef(null);
  const [editPublication, setEditPublication] = useState({
    id: null,
    visible: false,
    state: null,
    result: null,
  });

  useEffect(async () => {
    // Si une requête de modification d'un commentaire a été faite :
    if (editPublication.result) {
      // On affiche le toast de confirmation
      if (editPublication.result.status == 200) {
        editPublicationRef.current.show({
          severity: "success",
          summary: "Succès",
          detail: "Cette publication a été modifiée avec succès",
          life: 5000,
        });
        createPublicationsURL();
        // On affiche le toast d'erreur
      } else {
        editPublication.current.show({
          severity: "error",
          summary: "Erreur",
          detail: editPublication.result,
          life: 5000,
        });
      }
    }
  }, [editPublication.result]);

  // Gestion toast + popin pour signalement d'une entité
  const reportRef = useRef(null);
  const [reportAction, setReportAction] = useState({
    id: null,
    type: undefined,
    visible: false,
    state: null,
    result: null,
  });

  useEffect(async () => {
    // Si une requête de signalement d'un commentaire | publication a été faite :
    if (reportAction.result) {
      // On affiche le toast de confirmation en fonction du type
      let message = "";
      if (reportAction.type == "comment")
        message = "Ce commentaire a été signalé avec succès";
      else if (reportAction.type == "publication")
        message = "Cette publication a été signalée avec succès";
      if (reportAction.result.status == 200) {
        reportRef.current.show({
          severity: "success",
          summary: "Succès",
          detail: message,
          life: 5000,
        });
        // On affiche le toast d'erreur
      } else {
        reportRef.current.show({
          severity: "error",
          summary: "Erreur",
          detail: reportAction.result,
          life: 5000,
        });
      }
      setReportAction({ ...reportAction, result: null });
    }
  }, [reportAction.result]);

  // Gestion de la création de commentaire (succès ou erreur)
  const commentToast = useRef(null);

  useEffect(() => {
    if (disableComments.message) {
      disableCommentsRef.current.show({
        severity: disableComments.type,
        summary: disableComments.header,
        detail: disableComments.message,
        life: 5000,
      });
      setDisableComments({
        ...disableComments,
        message: null,
        header: null,
        type: null,
        id: null,
      });
    }
  }, [disableComments]);

  function createPublicationsURL() {
    let url = process.env.REACT_APP_BASE_URL_API;
    url += "/publications";
    url = new URL(url);
    url.searchParams.append("sourceGroup", props.currentGroupId);
    url.searchParams.append("itemsPerPage", 50);
    url.searchParams.append("order[createdAt]", "DESC");
    setPublicationsUrl(url);
  }

  useEffect(() => {
    if (props.currentGroupId) createPublicationsURL();
  }, [props.currentGroupId]);

  useEffect(() => {
    if (publicationsQuery.loaded && publicationsQuery.data)
      setPublications(publicationsQuery.data);
  }, [publicationsQuery]);

  useEffect(() => {
    if (
      (deleteAction &&
        deleteAction.result &&
        deleteAction.result.status === 204) ||
      (editPublication && editPublication.result) ||
      (disableComments && disableComments.type === "success")
    ) {
      createPublicationsURL();
    }
  }, [deleteAction.result, editPublication, editComment, disableComments]);

  return (
    <>
      <Toast ref={deleteRef} />
      <Toast ref={editCommentRef} />
      <Toast ref={editPublicationRef} />
      <Toast ref={reportRef} />
      <Toast ref={commentToast} />
      <Toast ref={disableCommentsRef} />
      {!props.finder && (
        <GroupePublicationCreation
          currentGroupId={props.currentGroupId}
          currentUser={props.auth.userConnected}
          reloadPublications={reloadPublications}
        />
      )}
      {publicationsQuery.loaded ? (
        publications
          .slice(0, voirPlus.amount)
          .map((publication) => (
            <GroupeCardPublication
              key={publication.id}
              publication={publication}
              setDeleteAction={setDeleteAction}
              deleteAction={deleteAction}
              setEditComment={setEditComment}
              editComment={editComment}
              setReportAction={setReportAction}
              setEditPublication={setEditPublication}
              editPublication={editPublication}
              setDisableComments={setDisableComments}
              finder={props.finder}
              isGroupAdmin={props.isGroupAdmin}
            />
          ))
      ) : (
        <Loader />
      )}
      {publications.length > voirPlus.amount && (
        <div className="publications-voirPlus">
          <Button
            label="Voir plus de publications"
            className="p-button-text publications-bouton"
            onClick={() =>
              setVoirPlus({
                amount: voirPlus.amount + 5,
                display: voirPlus.display,
              })
            }
          ></Button>
        </div>
      )}
      {deleteAction && (
        <ModalSupprimer visible={deleteAction} setVisible={setDeleteAction} />
      )}
      {editComment && (
        <ModalEditerCommentaire
          visible={editComment}
          setVisible={setEditComment}
        />
      )}
      {reportAction && (
        <ModalSignaler visible={reportAction} setVisible={setReportAction} />
      )}
      {editPublication && (
        <ModalEditerPublication
          visible={editPublication}
          setVisible={setEditPublication}
        />
      )}
      {disableComments && (
        <ModalDesactiverCommentaires
          visible={disableComments}
          setVisible={setDisableComments}
        />
      )}
    </>
  );
};

GroupePublication.propTypes = {
  currentGroupId: PropTypes.number,
  handleUpdateGroups: PropTypes.func,
  groups: PropTypes.object,
  auth: PropTypes.object,
  users: PropTypes.object,
  isGroupAdmin: PropTypes.bool,

  //? Si on est sur la page de recherche de groupe
  finder: PropTypes.bool,
};

GroupePublication.defaultProps = {
  finder: false,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  users: state.users,
  groups: state.groups,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateGroups: (value) => {
    dispatch(updateGroups(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupePublication);
