import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import { errorToast, successToast } from "Services/toastFunctions";
import axios from "axios";
import { Checkbox } from "primereact/checkbox";
import { ConfirmPopup } from "primereact/confirmpopup";
import { FileUpload } from "primereact/fileupload";
import { InputTextarea } from "primereact/inputtextarea";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { FaDownload } from "react-icons/fa";
import { connect } from "react-redux";
import "./CandidatureForm.scss";

const CandidatureForm = (props) => {
  const getCvsStatus = () => {
    if (props.auth.userConnected.availableToWork) return 1;
    if (props.auth.userConnected.resume.document) return 2;
    return 0;
  };

  const isAvailableToWork =
    !!props.auth?.userConnected?.availableToWork || false;

  const cvAvailable = props.auth?.userConnected?.resume?.document
    ? true
    : false;

  const isRecruiter =
    new URLSearchParams(window.location.search).get("r") == "true";

  const [message, setMessage] = useState("");
  const [cvCheckbox, setCvCheckbox] = useState(getCvsStatus());
  const [dowloadedResume, setDownloadedResume] = useState(null);

  const uploadOptions = {
    label: " ",
    icon: "pi pi-check",
    className: "bouton_valider",
  };
  const cancelOptions = {
    label: " ",
    icon: "pi pi-times",
    className: "bouton_supprimer",
  };
  const uploadHandler = ({ files }) => {
    setDownloadedResume(files[0]);
    successToast("Le fichier a bien été téléchargé");
  };

  const reset = () => {
    setMessage("");
    setCvCheckbox(getCvsStatus());
    setDownloadedResume(null);
    props.setVisibleModalPostuler(false);
  };

  // Fonction de validation de la candidature
  const accept = async () => {
    props.setIsLoadingCandidature(true);
    const data = {
      user: "/api/users/" + props.auth.userConnected.id,
      dateOfCandidature: new Date(),
      offer: "/api/offers/" + props.offre.id,
      message: message,
    };
    if (props.offre.transmitter.name === "Recruteur" || isRecruiter) {
      if (dowloadedResume !== null) {
        data.documentFile = dowloadedResume;
      } else if (cvCheckbox === 1) {
        data.resumeReseauPro = true;
      } else if (cvCheckbox === 2) {
        data.extraResumeFilePath = props.auth.userConnected.resume.document;
      }
    }

    let url = `${process.env.REACT_APP_BASE_URL_API}${"/candidature/apply"}`;
    var dataForm = new FormData();
    for (var key in data) dataForm.append(key, data[key]);
    // On envoie le formData au serveur
    axios
      .post(url, dataForm, {
        headers: props.auth.token
          ? {
              accept: "application/json",
              Authorization: `Bearer ${props.auth.token}`,
            }
          : {
              accept: "application/json",
            },
      })
      .then(() => {
        props.offre.transmitter.name === "Recruteur" &&
          successToast("Vous avez postulé");
        reset();
      })
      .catch((error) =>
        errorToast(
          error?.response?.data?.detail ||
            "Une erreur est survenue ou vous avez déjà postulé à cette offre"
        )
      )
      .finally(() => props.setIsLoadingCandidature(false));
  };

  return (
    <div className="description__postulate">
      {/* Message au recruteur */}
      <p className="message_recruteur">
        {"Transmettez un message au recruteur (facultatif) :"}
      </p>
      <InputTextarea
        className="text-area-pop-in"
        onChange={(e) => {
          setMessage(e.target.value);
        }}
      />
      {/* Checkbox de choix de CV */}
      <p className="message_recruteur">
        {"Vous devez choisir un CV pour candidater :"}
      </p>
      <div
        className={`checkbox_RP${
          isAvailableToWork ? "" : " checkbox_disabled"
        }`}
        title={
          isAvailableToWork
            ? "Utilisation du CV Réseau Alumni"
            : "Vous devez avoir coché la case 'A l'écoute du marché' dans votre profil pour pouvoir utiliser ce CV"
        }
      >
        <Checkbox
          id="cb1"
          onChange={() => isAvailableToWork && setCvCheckbox(1)}
          checked={!isAvailableToWork ? false : cvCheckbox === 1}
          value="test"
          disabled={!isAvailableToWork}
        ></Checkbox>
        <span> Utiliser le CV Réseau Alumni</span>
      </div>
      <div
        className={`checkbox_Own${cvAvailable ? "" : " checkbox_disabled"}`}
        title={
          cvAvailable
            ? "Utilisation du CV importé dans votre profil"
            : "Vous devez avoir importé un CV dans votre profil pour pouvoir utiliser ce CV"
        }
      >
        <Checkbox
          id="cb1"
          onChange={() => cvAvailable && setCvCheckbox(2)}
          checked={!cvAvailable ? false : cvCheckbox === 2}
          disabled={!cvAvailable}
        ></Checkbox>
        <span> Utiliser le CV importé dans mon profil</span>
      </div>
      {/* Téléchargement d'un CV externe */}
      <div className="download">
        <FaDownload className="upload_icone"></FaDownload>
        <FileUpload
          className="download_CV"
          customUpload={true}
          uploadOptions={uploadOptions}
          cancelOptions={cancelOptions}
          uploadHandler={uploadHandler}
          chooseLabel="Importer un CV depuis mon PC"
          onClear={() => {
            setDownloadedResume(undefined);
            errorToast("Le fichier a bien été supprimé", "Suppression : ");
          }}
          accept="application/pdf"
          maxFileSize={1000000}
        ></FileUpload>
      </div>

      {/* Bouton candidater et annuler */}
      <div className="postulate_buttons">
        <button className="btn-bleu" name="Candidater" onClick={() => accept()}>
          Candidater
        </button>
        <BtnBlanc
          btnTexte="Annuler"
          btnAction={() => {
            reset();
          }}
        />
      </div>

      <ConfirmPopup />
    </div>
  );
};

CandidatureForm.propTypes = {
  auth: PropTypes.object,
  setIsLoadingCandidature: PropTypes.func,
  setVisibleModalPostuler: PropTypes.func,
  offre: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(CandidatureForm);
