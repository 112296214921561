import Localisation from "Components/Localisation/Localisation";
import { updateSecondaryTables } from "Redux/Actions/secondaryTablesActions";
import { useFetchGet, useFetchGetConditional } from "Services/api";
import iconeParrainage from "assets/iconeParrainage.png";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";
import { connect } from "react-redux";
import "./AnnuaireAlumniSearchBar.scss";

const AnnuaireAlumniSearchBar = (props) => {
  const { t } = useTranslation("common");

  // données qui seront en props pour la recherche
  const [motscles, setMotscles] = useState("");
  const [entreprise, setEntreprise] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [secteur, setSecteur] = useState([]);
  const [composante, setComposante] = useState([]);
  const [formation, setFormation] = useState([]);
  const [mentor, setMentor] = useState(false);
  const [createur, setCreateur] = useState(false);
  const [localisation, setLocalisation] = useState("");
  const [isVisibleLocation, setIsVisibleLocation] = useState(false);

  const formationData = useFetchGetConditional(
    "/domains",
    props.auth.token,
    props.secondaryTables.domainsData
  );
  const composanteData = useFetchGetConditional(
    "/education_composantes",
    props.auth.token,
    props.secondaryTables.educationComposanteData
  );
  const secteurData = useFetchGetConditional(
    "/sector_of_offers",
    props.auth.token,
    props.secondaryTables.sectorOfOffersData
  );
  const localisationData = useFetchGet(
    "/users/liste_localisation",
    props.auth.token
  );

  useEffect(() => {
    formationData.loaded &&
      composanteData.loaded &&
      secteurData.loaded &&
      props.handleUpdateSecondaryTables({
        domainsData: formationData.data,
        educationComposanteData: composanteData.data,
        sectorOfOffersData: secteurData.data,
      });
  }, [formationData.loaded, composanteData.loaded, secteurData.loaded]);
  // traitement de la recherche
  const [isSearched, setIsSearched] = useState(false);
  const setRecherche = () => {
    let url = new URL(`${process.env.REACT_APP_BASE_URL_API}/users/annuaire`);
    url.searchParams.set("role", "alumni");

    if (motscles != "") url.searchParams.set("keyword", motscles);
    if (entreprise != "")
      url.searchParams.set("resume.experiences.company", entreprise);
    if (nom != "") url.searchParams.set("surname", nom);
    if (prenom != "") url.searchParams.set("firstname", prenom);
    if (localisation != "") url.searchParams.set("localisation", localisation);
    if (mentor) url.searchParams.set("mentorAccept[]", 1);
    if (createur) url.searchParams.set("companyCreator[]", 1);

    if (composante) {
      composante.forEach((item) =>
        url.searchParams.append("composante[]", item.id)
      );
    }
    if (secteur) {
      secteur.forEach((item) =>
        url.searchParams.append("resume.experiences.sector[]", item.id)
      );
    }
    if (formation) {
      formation.forEach((item) => url.searchParams.append("domain[]", item.id));
    }

    if (
      motscles === "" &&
      entreprise === "" &&
      nom === "" &&
      prenom === "" &&
      localisation === "" &&
      !secteur &&
      !formation &&
      !composante &&
      !mentor &&
      !createur
    ) {
      setIsSearched(false);
    }
    props.setUrlAnnuaire(url);
  };
  return (
    <div className="annuaire_search_bar etudiant_bar">
      {/* <h2 className="annuaire_search_bar__title">Trouvez un alumni</h2> */}
      <div className="annuaire_search_bar__container">
        <div className="annuaire_search_bar__container__top">
          <div className="annuaire_search_bar__container__top__boxes">
            <div className="annuaire_search_bar__container__top__boxes__box">
              <label className="label-annuaire">
                {t("academicYearbook.alumniKeyword")}
              </label>
              <span className="annuaire_p-input-icon-left">
                <InputText
                  value={motscles}
                  onChange={(e) => setMotscles(e.target.value)}
                  placeholder=" Poste, Entreprise..."
                  className="box__input"
                />
              </span>
            </div>
            <Divider layout="vertical" />
            <div className="annuaire_search_bar__container__top__boxes__box">
              <label className="label-annuaire">
                {t("academicYearbook.alumniPrenom")}
              </label>
              <InputText
                value={prenom}
                onChange={(e) => setPrenom(e.target.value)}
                placeholder=" Alex, Emma, Julie"
                className="box__input"
              />
            </div>
            <Divider layout="vertical" />
            <div className="annuaire_search_bar__container__top__boxes__box">
              <label className="label-annuaire">
                {t("academicYearbook.alumniNom")}
              </label>
              <InputText
                value={nom}
                onChange={(e) => setNom(e.target.value)}
                placeholder=" Lebon, Dupont"
                className="box__input"
              />
            </div>
            <Divider layout="vertical" />
            <div className="annuaire_search_bar__container__top__boxes__box input_localisation_annuaire">
              <label className="label-annuaire">
                {t("academicYearbook.alumniLocalisation")}
              </label>
              <InputText
                value={localisation}
                onChange={(e) => {
                  setLocalisation(e.target.value);
                  if (e.target.value.length > 2) {
                    setIsVisibleLocation(true);
                  } else {
                    setIsVisibleLocation(false);
                  }
                }}
                placeholder={t("academicYearbook.alumniLocalisationPlace")}
                className="box__input"
              />
              {isVisibleLocation && localisationData.loaded && (
                <Localisation
                  location={localisation}
                  listOfData={localisationData.data}
                  btnAction={(element) => {
                    setLocalisation(element.toLowerCase());
                  }}
                  setIsVisibleLocation={setIsVisibleLocation}
                ></Localisation>
              )}
            </div>
            <Divider layout="vertical" />
            <div className="annuaire_search_bar__container__top__boxes__box">
              <label className="label-annuaire">
                {t("academicYearbook.alumniCompany")}
              </label>
              <InputText
                value={entreprise}
                onChange={(e) => setEntreprise(e.target.value)}
                placeholder=" Darty, Lactalis"
                className="box__input"
              />
            </div>
          </div>
          <div
            className="annuaire_search_bar__container__top__inputSearch not_mobile"
            onClick={() => {
              setIsSearched(true);
              setRecherche();
            }}
          >
            <FaSearch />
          </div>
        </div>
        <div className="annuaire_search_bar__container__bottom">
          <ul>
            <li>
              <label className="label-annuaire">
                {t("academicYearbook.alumniSector")}
              </label>
              <MultiSelect
                appendTo={document.getElementById("app")}
                value={secteur}
                options={secteurData.data?.sort((a, b) =>
                  a.label.localeCompare(b.label)
                )}
                onChange={(e) => setSecteur(e.value)}
                optionLabel="label"
                placeholder={t("academicYearbook.alumniSector")}
                maxSelectedLabels={3}
                selectedItemsLabel={`${secteur.length} options sélectionnées`}
                className="box__input"
              />
            </li>
            <li>
              <label className="label-annuaire">
                {t("academicYearbook.alumniComposante")}
              </label>
              <MultiSelect
                appendTo={document.getElementById("app")}
                value={composante}
                options={composanteData.data?.sort((a, b) =>
                  a.label.localeCompare(b.label)
                )}
                onChange={(e) => setComposante(e.value)}
                optionLabel="label"
                placeholder={t("academicYearbook.alumniComposante")}
                maxSelectedLabels={3}
                className="box__input"
                selectedItemsLabel={`${composante.length} options sélectionnées`}
              />
            </li>
            <li>
              <label className="label-annuaire">
                {t("academicYearbook.alumniFormation")}
              </label>
              <MultiSelect
                appendTo={document.getElementById("app")}
                value={formation}
                options={formationData.data?.sort((a, b) =>
                  a.label.localeCompare(b.label)
                )}
                onChange={(e) => setFormation(e.value)}
                optionLabel="label"
                placeholder={t("academicYearbook.alumniFormation")}
                maxSelectedLabels={1}
                selectedItemsLabel={`${formation.length} options sélectionnées`}
                className="box__input"
              />
            </li>
            <li className="li_checkbox">
              <Checkbox
                id="cb1"
                checked={mentor}
                onChange={(e) => setMentor(e.checked)}
              ></Checkbox>
              <label htmlFor="cb1" className="p-checkbox-label">
                <img src={iconeParrainage} alt="" />{" "}
                {t("academicYearbook.alumniMentor")}
              </label>
            </li>
            <li className="li_checkbox">
              <Checkbox
                id="cb1"
                checked={createur}
                onChange={(e) => setCreateur(e.checked)}
              ></Checkbox>
              <label htmlFor="cb1" className="p-checkbox-label">
                <i className="pi pi-fw pi-building buildingLogoAnnuaire" />
                {t("academicYearbook.alumniCreateur")}
              </label>
            </li>
            {isSearched && (
              <li className="search_bar_refresh search_bar_refresh__annuaireEntreprise">
                <i
                  className="pi pi-refresh"
                  style={{ fontSize: "1.5rem", fontWeight: "600" }}
                  onClick={() => {
                    setIsSearched(false);
                    setFormation([]);
                    setEntreprise("");
                    setLocalisation("");
                    setMotscles("");
                    setNom("");
                    setPrenom("");
                    setComposante([]);
                    setSecteur([]);
                    let url = new URL(
                      `${process.env.REACT_APP_BASE_URL_API}/users`
                    );
                    url.searchParams.set("role", "alumni");
                    props.setUrlAnnuaire(url);
                  }}
                />
              </li>
            )}
          </ul>
        </div>
        <div
          className="annuaire_search_bar__container__top__inputSearch not_desktop"
          onClick={() => {
            setIsSearched(true);
            setRecherche();
          }}
        >
          <FaSearch />
        </div>
      </div>
    </div>
  );
};

AnnuaireAlumniSearchBar.propTypes = {
  setUrlAnnuaire: PropTypes.func,
  auth: PropTypes.object,
  secondaryTables: PropTypes.object,
  handleUpdateSecondaryTables: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  secondaryTables: state.secondaryTables,
});
const mapDispatchToProps = (dispatch) => ({
  handleUpdateSecondaryTables: (value) => {
    dispatch(updateSecondaryTables(value));
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnuaireAlumniSearchBar);
