import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import "./CarouselActu.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Actualite from "Components/Actualites/Actualite/Actualite";
import { formatDateFrenchLocale } from "Services/functions";
import { Link } from "react-router-dom";
import Loader from "Components/Loader/loader";
import { updateAuth } from "Redux/Actions/authActions";
import { useTranslation } from "react-i18next";
import ModalConnexion from "Components/Modal/ModalConnexion/ModalConnexion";
import { authenticationRedirection } from "Services/redirections";

const CarouselActu = (props) => {
  const [loginVisible, setLoginVisible] = useState(false);
  const [clickedNewsId, setClickedNewsId] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { t } = useTranslation("common");

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const indexForCaroussel = (index, place) => {
    let multiplyer = 3;
    if (windowWidth < 1600) multiplyer = 2;
    if (windowWidth < 1200) multiplyer = 1;

    return index > (place - 1) * multiplyer - 1 && index < place * multiplyer;
  };

  const multiplyerForCaroussel = (place) => {
    let multiplyer = 3;
    if (windowWidth < 1600) multiplyer = 2;
    if (windowWidth < 1200) multiplyer = 1;

    return multiplyer * place;
  };

  return (
    <div>
      <div className="actualites_container__top">
        {props.pageEvents == "accueil" ? (
          // si on est dans la page d'accueil on n'affiche pas les filtres
          <h1 className="h1_trait_dessus-blanc">Les dernières actus</h1>
        ) : (
          <div>
            {/* si on est sur la page Evenements on affiche les filtres */}
            <div className="actualites_container__top__title">
              <h1 className="h1_trait_dessus">
                {/* {props.title ? props.title : "Mes dernières actus"} */}

                {props.auth.isConnected
                  ? t("actusApresConnexion.title")
                  : t("actusHorsConnexion.title_accueil")}
              </h1>
            </div>
          </div>
        )}
      </div>
      <div className="noCarouselActus">
        {props.news?.dataFromPagination?.loaded ? (
          props.news.dataFromPagination.data &&
          props.news.dataFromPagination.data.map(
            (actualite, index) =>
              index < 3 &&
              (props.auth.userConnected || actualite.public ? (
                <Link
                  to={{
                    pathname: `/actu-detail/${actualite.id}`,
                    params: actualite.id,
                  }}
                  key={actualite.id}
                >
                  <Actualite
                    key={actualite.id}
                    title={actualite.title}
                    chapeau={actualite.chapo}
                    publicationDate={formatDateFrenchLocale(
                      actualite?.publishedAt
                    )}
                    url={actualite.imageUrl ? actualite.imageUrl : "img1"}
                    categorie={actualite.category.label}
                    newsDetails={actualite}
                  />
                </Link>
              ) : (
                <Actualite
                  key={actualite.id}
                  title={actualite.title}
                  chapeau={actualite.chapo}
                  publicationDate={formatDateFrenchLocale(
                    actualite?.publishedAt
                  )}
                  url={actualite.imageUrl ? actualite.imageUrl : "img1"}
                  categorie={actualite.category.label}
                  newsDetails={actualite}
                  onClick={() => {
                    if (!authenticationRedirection()) {
                      setLoginVisible(true);
                      setClickedNewsId(actualite.id);
                    }
                  }}
                />
              ))
          )
        ) : (
          <Loader />
        )}
        <div className="carouselEvent-button">
          <button className="carouselActuBtn"> Voir toutes les actus</button>
        </div>
      </div>
      <div className="carouselDesktopActus">
        <div className="logo_container">
          <div className="logos_univ">
            {props.news?.dataFromPagination?.loaded ? (
              <Carousel style={{ width: "100%" }}>
                <Carousel.Item className="carousel_actu_acceuil_alumni">
                  <div className="item-carousel">
                    {props.news.dataFromPagination.data ? (
                      props.news.dataFromPagination.data.map(
                        (actualite, index) =>
                          indexForCaroussel(index, 1) &&
                          (props.auth.userConnected || actualite.public ? (
                            <Link
                              to={{
                                pathname: `/actu-detail/${actualite.id}`,
                                params: actualite.id,
                              }}
                              key={actualite.id}
                            >
                              <Actualite
                                key={actualite.id}
                                title={actualite.title}
                                chapeau={actualite.chapo}
                                publicationDate={formatDateFrenchLocale(
                                  actualite?.publishedAt
                                )}
                                url={
                                  actualite.imageUrl
                                    ? actualite.imageUrl
                                    : "img1"
                                }
                                categorie={actualite.category.label}
                                newsDetails={actualite}
                              />
                            </Link>
                          ) : (
                            <Actualite
                              key={actualite.id}
                              title={actualite.title}
                              chapeau={actualite.chapo}
                              publicationDate={formatDateFrenchLocale(
                                actualite?.publishedAt
                              )}
                              url={
                                actualite.imageUrl ? actualite.imageUrl : "img1"
                              }
                              categorie={actualite.category.label}
                              newsDetails={actualite}
                              onClick={() => {
                                if (!authenticationRedirection()) {
                                  setLoginVisible(true);
                                  setClickedNewsId(actualite.id);
                                }
                              }}
                            />
                          ))
                      )
                    ) : (
                      <React.Fragment />
                    )}
                  </div>
                </Carousel.Item>
                {props.news?.dataFromPagination?.data?.length >
                  multiplyerForCaroussel(1) && (
                  <Carousel.Item className="carousel_actu_acceuil_alumni">
                    <div className="item-carousel">
                      {props.news.dataFromPagination.data.map(
                        (actualite, index) =>
                          indexForCaroussel(index, 2) &&
                          (props.auth.userConnected || actualite.public ? (
                            <Link
                              to={{
                                pathname: `/actu-detail/${actualite.id}`,
                                params: actualite.id,
                              }}
                              key={actualite.id}
                            >
                              <Actualite
                                key={actualite.id}
                                title={actualite.title}
                                chapeau={actualite.chapo}
                                publicationDate={formatDateFrenchLocale(
                                  actualite?.publishedAt
                                )}
                                url={
                                  actualite.imageUrl
                                    ? actualite.imageUrl
                                    : "img1"
                                }
                                categorie={actualite.category.label}
                                newsDetails={actualite}
                              />
                            </Link>
                          ) : (
                            <Actualite
                              key={actualite.id}
                              title={actualite.title}
                              chapeau={actualite.chapo}
                              publicationDate={formatDateFrenchLocale(
                                actualite?.publishedAt
                              )}
                              url={
                                actualite.imageUrl ? actualite.imageUrl : "img1"
                              }
                              categorie={actualite.category.label}
                              newsDetails={actualite}
                              onClick={() => {
                                if (!authenticationRedirection()) {
                                  setLoginVisible(true);
                                  setClickedNewsId(actualite.id);
                                }
                              }}
                            />
                          ))
                      )}
                    </div>
                  </Carousel.Item>
                )}
                {props.news?.dataFromPagination?.data?.length >
                  multiplyerForCaroussel(2) && (
                  <Carousel.Item className="carousel_actu_acceuil_alumni">
                    <div className="item-carousel">
                      {props.news.dataFromPagination.data.map(
                        (actualite, index) =>
                          indexForCaroussel(index, 3) &&
                          (props.auth.userConnected || actualite.public ? (
                            <Link
                              to={{
                                pathname: `/actu-detail/${actualite.id}`,
                                params: actualite.id,
                              }}
                              key={actualite.id}
                            >
                              <Actualite
                                key={actualite.id}
                                title={actualite.title}
                                chapeau={actualite.chapo}
                                publicationDate={formatDateFrenchLocale(
                                  actualite?.publishedAt
                                )}
                                url={
                                  actualite.imageUrl
                                    ? actualite.imageUrl
                                    : "img1"
                                }
                                categorie={actualite.category.label}
                                newsDetails={actualite}
                              />
                            </Link>
                          ) : (
                            <Actualite
                              key={actualite.id}
                              title={actualite.title}
                              chapeau={actualite.chapo}
                              publicationDate={formatDateFrenchLocale(
                                actualite?.publishedAt
                              )}
                              url={
                                actualite.imageUrl ? actualite.imageUrl : "img1"
                              }
                              categorie={actualite.category.label}
                              newsDetails={actualite}
                              onClick={() => {
                                if (!authenticationRedirection()) {
                                  setLoginVisible(true);
                                  setClickedNewsId(actualite.id);
                                }
                              }}
                            />
                          ))
                      )}
                    </div>
                  </Carousel.Item>
                )}
              </Carousel>
            ) : (
              <Loader />
            )}
          </div>
          <div className="carouselEvent-button">
            <button
              className="carouselActuBtn"
              onClick={() => (window.location.href = "/actualites")}
            >
              {" "}
              {t("actusHorsConnexion.button")}
            </button>
          </div>
        </div>
      </div>
      {loginVisible && (
        <ModalConnexion
          visibleModal={loginVisible}
          setVisbleModal={setLoginVisible}
          redirect={clickedNewsId ? `/actu-detail/${clickedNewsId}` : null}
        />
      )}
    </div>
  );
};

CarouselActu.propTypes = {
  auth: PropTypes.object,
  news: PropTypes.any,
  pageEvents: PropTypes.string,
  statuses: PropTypes.any,
  title: PropTypes.string,
  handleUpdateNews: PropTypes.func,
  handleUpdateAuth: PropTypes.func,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  statuses: state.events.statusData,
  news: state.news,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateAuth: (value) => dispatch(updateAuth(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CarouselActu);
