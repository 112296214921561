import React, { useState } from "react";
import "./ALaUneCard.scss";
import alaune from "assets/alaune.jpg";
import PropTypes from "prop-types";
import { formatDateFrenchLocale, truncate } from "Services/functions";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HTMLParseOptions } from "Services/htmlParseOptions";
import parse from "html-react-parser";
import ModalConnexion from "Components/Modal/ModalConnexion/ModalConnexion";
import { authenticationRedirection } from "Services/redirections";

const ALaUneCard = (props) => {
  const navigate = useNavigate();
  const [loginVisible, setLoginVisible] = useState(false);
  const { t } = useTranslation("common");

  return (
    <div className="ALaUneCard_container">
      <div className="ALaUneCard_container__left">
        <div className="ALaUneCard_container__left__titleCard">
          <b>{t("aLaUne.title")}</b>
        </div>
        <div className="ALaUneCard_container__left__titleActu">
          <b>{truncate(props.title, 50)}</b>
        </div>
        <div className="ALaUneCard_container__left__date">
          {formatDateFrenchLocale(props.date)}
        </div>
        <p className="ALaUneCard_container__left__text">
          {parse(truncate(props.description, 450), HTMLParseOptions)}
        </p>
        <div
          className="ALaUneCard_container__left__link"
          onClick={() => {
            if (props.public) {
              if (props.type === "news")
                navigate(`/actu-detail/${props.itemId}`);
              else navigate(`/event-detail/${props.itemId}`);
            } else {
              if (!authenticationRedirection()) setLoginVisible(true);
            }
          }}
        >
          {t("aLaUne.lirePlus")}
        </div>
      </div>
      <div className="ALaUneCard_container__right">
        <img src={props.imageUrl ? props.imageUrl : alaune} alt="" />
        <div
          className="image__bg"
          style={{
            backgroundImage: `url(${props.imageUrl ? props.imageUrl : alaune})`,
          }}
        />
      </div>
      {loginVisible && (
        <ModalConnexion
          visibleModal={loginVisible}
          setVisibleModal={setLoginVisible}
        />
      )}
    </div>
  );
};
ALaUneCard.propTypes = {
  title: PropTypes.string,
  date: PropTypes.object,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  public: PropTypes.bool,
  itemId: PropTypes.number,
  type: PropTypes.string,
  setVisibleModal: PropTypes.func,
};
export default ALaUneCard;
