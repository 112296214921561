import React, { useEffect } from "react";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import Margin from "../Margin";

/**
 * Composant {@link Matomo} sert à afficher les widgets de la partie Matomo
 * à l'aide de l'api de l'outil éponyme ainsi que d'un package prévu à cet effet
 *
 * Les widgets importés viennent directement du dashboard Matomo visible
 * sur la page d'administration de celui-ci.
 *
 * {@link useMatomo} est un hook permettant de récupérer des fonctions
 * de Matomo qui vont fournir des statistiques sur l'utilisateur
 * ou le visiteur actuel aux administrateurs et/ou personnes authorisées
 *
 */
const Matomo = () => {
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView();
  }, []);

  return (
    <div className="stats_container">
      <div className="stats_container__iframe">
        <iframe
          src="http://localhost:8080/index.php?module=Widgetize&action=iframe&moduleToWidgetize=Dashboard&actionToWidgetize=index&idSite=1&period=week&date=yesterday"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          width="100%"
          height="100%"
        ></iframe>
      </div>
      <Margin position="bottom" size="s" />
    </div>
  );
};

export default Matomo;
