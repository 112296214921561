import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import HeaderUserLogin from "Components/Header/HeaderTop/HeaderUserLogin/HeaderUserLogin";
import React from "react";
import { useState } from "react";
// import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./PlanDuSite.scss";
import { authenticationRedirection } from "Services/redirections";

const PlanDuSite = () => {
  const [loginVisible, setLoginVisible] = useState(false);

  function LiSitemapUniversities() {
    const universities = [
      {
        label: "Université Paris 1 Panthéon Sorbonne",
        universityUrl: "https://www.pantheonsorbonne.fr/",
      },
    ];
    return (
      <ol>
        {universities.map((university) => (
          <li key={university}>
            <a
              href={university.universityUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {university.label}
            </a>
          </li>
        ))}
      </ol>
    );
  }
  function LiSitemapInfos() {
    const infos = [
      { label: "Contactez-nous", path: "/contact" },
      { label: "Mentions légales", path: "/mentions-legales" },
      { label: "Plan du site", path: "/plan-du-site" },
    ];
    return (
      <ol>
        {infos.map((info, index) => (
          <li
            key={index}
            onClick={() => {
              navigate(info.path);
            }}
          >
            {info.label}
          </li>
        ))}
      </ol>
    );
  }

  const navigate = useNavigate();

  function LiSitemapServices() {
    const services = [
      { label: "Toutes les actualités", path: "/actualites" },
      { label: "Tous les évènements", path: "/evenements" },
      { label: "Toutes les offres", path: "/offres" },
      { label: "Cvtheque", path: null },
      { label: "Groupes", path: null },
      { label: "Annuaire", path: null },
      { label: "Profil", path: null },
    ];
    return (
      <ol>
        {services.map((service, index) => (
          <li
            className="liServices"
            key={index}
            onClick={() => {
              service.path
                ? navigate(service.path)
                : !authenticationRedirection()
                ? setLoginVisible(true)
                : null;
            }}
          >
            {service.label}
          </li>
        ))}
      </ol>
    );
  }

  return (
    <div>
      <Header />
      <div className="sitemapContainer">
        <h1 className="h1_trait_dessus">Plan du portail Alumni</h1>
        <div className="sitemapContainer__global">
          <div className="sitemapContainer__global__left">
            <div className="sitemapContainer__global__container">
              <h2 className="h2_sitemap"> Paris 1 Panthéon Sorbonne</h2>
              <ul className="ul_sitemap">{LiSitemapUniversities()}</ul>
            </div>

            <div className="sitemapContainer__global__container">
              <h2 className="h2_sitemap">Pour en savoir plus</h2>
              <ul>{LiSitemapInfos()}</ul>
            </div>
          </div>
          <div className="sitemapContainer__global__container">
            <h2 className="h2_sitemap">Les services du portail Alumni</h2>
            <ul>{LiSitemapServices()}</ul>
          </div>
        </div>
      </div>
      {loginVisible && (
        <HeaderUserLogin
          loginVisible={loginVisible}
          setLoginVisible={setLoginVisible}
        />
      )}
      <Footer />
    </div>
  );
};

// PlanDuSite.propTypes = {
//   toto: PropTypes.func,
//   test: PropTypes.object,
// };

// const mapStateToProps = (state) => ({
//   test: state.universities,
// });

// const mapDispatchToProps = (dispatch) => ({
//   toto: (value) => dispatch(updateUniversities(value)),
// });

export default PlanDuSite;
