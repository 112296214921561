import React, { useMemo, useRef } from "react";
import "./ProfilBar.scss";
import avatar_group1 from "assets/avatar_group1.webp";
import laurier_alumnis from "assets/laurier.webp";
import { FaPaperclip, FaArrowDown, FaEllipsisH, FaTrash } from "react-icons/fa";
import ProgressBarCustom from "./ProgressBarCustom";
import BtnBleu from "../Boutons/BtnBleu/btn-bleu";
import { Link } from "react-router-dom";
import { Divider } from "primereact/divider";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { updateMenu } from "Redux/Actions/menuActions";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import { useState } from "react";
import axios from "axios";
import { updateAuth } from "Redux/Actions/authActions";
import { isNotEmptyArray } from "Services/functions";
import Loader from "Components/Loader/loader";
import { updateGroups } from "Redux/Actions/groupsActions";
import { useFetchGetPagination } from "Services/api";
import parse from "html-react-parser";
import neutralUser from "assets/neutralUser.png";
import { useTranslation } from "react-i18next";

const ProfilBar = (props) => {
  const { t } = useTranslation("common");

  const API_LINK = process.env.REACT_APP_BASE_URL_API.split("/api")[0];

  const uploadToast = useRef(null);
  const cancelToast = useRef(null);

  const [myGroupsUrl, setMyGroupsUrl] = useState(null);
  const myGroups = useFetchGetPagination(myGroupsUrl, props.auth.token);

  const buildMyGroupsUrl = () => {
    let url = API_LINK;

    url += props.auth.isAdmin
      ? "/api/groups"
      : "/api/groups/member/" + props.auth.userConnected.id;
    let filteredUrl = new URL(url);
    filteredUrl.searchParams.append("page", 1);
    filteredUrl.searchParams.append("itemsPerPage", 2);
    filteredUrl.searchParams.append(
      "state[]",
      props.states.statesData.find((state) => state.statusLabel == "Actif").id
    );
    setMyGroupsUrl(filteredUrl);
  };

  useMemo(() => {
    if (props.auth.userConnected) buildMyGroupsUrl();
  }, [props.auth.userConnected]);

  // Options pour boutons upload / annuler d'upload d'image

  const uploadHandler = ({ files }) => {
    let message = "";

    if (props.auth.userConnected.resume.contentUrl !== null) {
      message =
        "Le fichier " +
        files[0].name +
        " a bien été téléchargé, l'ancien CV a été écrasé";
    } else {
      message = "Le fichier " + files[0].name + " a bien été téléchargé";
    }

    let formData = new FormData();
    const data = { documentFile: files[0] };
    for (var key in data) formData.append(key, data[key]);

    let url = `${process.env.REACT_APP_BASE_URL_API}/cvs/${props.auth.userConnected.resume.id}/update`;
    axios
      .post(url, formData, {
        headers: props.auth.token && {
          Authorization: `Bearer ${props.auth.token}`,
          "Content-Type": "multipart/form-data",
          accept: "application/json",
        },
      })
      .then((res) => {
        uploadToast.current.show({
          severity: "success",
          summary: "Succès : ",
          detail: message,
          life: 3000,
        });

        let author = props.auth;
        author.userConnected.resume.contentUrl = res.data.contentUrl;

        props.handleAuth({
          auth: author,
        });
      })
      // Si il y a une erreur, on l'affiche dans la console
      .catch((error) => {
        console.log(error.message);
        cancelToast.current.show({
          severity: "error",
          summary: "Suppression : ",
          detail: "Un problème est survenu au moment du téléchargement",
          life: 3000,
        });
      });
  };

  const deleteDowloadedCV = () => {
    let url = `${process.env.REACT_APP_BASE_URL_API}/cvs/${props.auth.userConnected.resume.id}/file/remove`;
    axios
      .get(url, {
        headers: props.auth.token && {
          Authorization: `Bearer ${props.auth.token}`,
        },
      })
      .then(() => {
        let author = props.auth;
        author.userConnected.resume.contentUrl = null;

        props.handleAuth({
          auth: author,
        });
        cancelToast.current.show({
          severity: "error",
          summary: "Suppression : ",
          detail: "Le fichier a bien été supprimé",
          life: 3000,
        });
      })
      // Si il y a une erreur, on l'affiche dans la console
      .catch((error) => {
        console.log(error.message);
        cancelToast.current.show({
          severity: "error",
          summary: "Suppression : ",
          detail: "Un problème est survenu au moment du téléchargement",
          life: 3000,
        });
      });
  };

  return (
    <div className="monProfil" id={props.id}>
      <Toast ref={uploadToast} />
      <Toast ref={cancelToast} />
      <div className="monProfil__container">
        <div className="monProfil__container__header">
          <div className="monProfil__container__header__title">
            <h1>
              {props.auth.userConnected.firstname}{" "}
              {props.auth.userConnected.surname}
            </h1>
            <h2>{t("profil.subtitle")}</h2>
          </div>

          <div className="bouton">
            <Link to={{ pathname: `/offres` }}>
              <BtnBleu
                btnTexte={t("profil.deposer_offre")}
                btnAction={() =>
                  props.handleMenu({
                    activeItemOffre: props.items.itemsOffre.findIndex(
                      (v) => v.label == "Déposer une offre"
                    ),
                  })
                }
              >
                <FaArrowDown />
              </BtnBleu>
            </Link>
          </div>
        </div>

        <div className="profilDescription">
          {props.auth.isAlumni && (
            <div className="user__alumnis">
              <img
                src={laurier_alumnis}
                alt="Laruier alumni"
                title="Laurier alumni"
              />
            </div>
          )}
          <div className="user__picture">
            <img
              src={
                props.auth.userConnected.imageUrl
                  ? props.auth.userConnected.imageUrl
                  : neutralUser
              }
              alt="Mon avatar"
              title="Mon avatar"
            />
          </div>
          <div className="column">
            <h1>{t("profil.title_profil")}</h1>
            <Link
              to={{ pathname: `/profil-show/${props.auth.userConnected.id}` }}
            >
              <span className="apercu"> {t("profil.apercu")}</span>
            </Link>
            <ProgressBarCustom />
            <p className="column__infoApplyJob">{t("profil.completion")}</p>
            <Link to={{ pathname: `/profil-edit` }} className="underline">
              {t("profil.edit")}
            </Link>
          </div>
          <Divider className="div_horiz" />
          <Divider className="div_vert" layout="vertical" />
          <div className="column myresume">
            <h1>{t("profil.title_cv")}</h1>

            <p>
              <FaPaperclip />
              {props.auth.userConnected.resume.contentUrl !== null ? (
                <a
                  href={API_LINK + props.auth.userConnected.resume.contentUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>{"1 " + t("profil.cv")}</span>
                </a>
              ) : (
                <span>{t("profil.aucun_cv")}</span>
              )}
              <FaTrash
                onClick={() => {
                  props.auth.userConnected.resume.contentUrl !== null &&
                    deleteDowloadedCV();
                }}
              />
            </p>
            <p>
              <FaPaperclip />{" "}
              <Link
                to={{ pathname: `/profil-show/${props.auth.userConnected.id}` }}
              >
                <span>
                  {"1 " + t("profil.cv")}
                  <span className="span_gris">{t("profil.rp_cv")}</span>
                </span>
              </Link>
            </p>
            {/* <a className="underline">Télécharger un CV</a> */}
            <FileUpload
              className="underline"
              chooseLabel={<a className="underline">{t("profil.dwnld_cv")}</a>}
              customUpload={true}
              uploadHandler={uploadHandler}
              auto
              accept="application/pdf"
              maxFileSize={10000000}
              url="./upload"
            ></FileUpload>
          </div>
          <Divider className="div_horiz" />
          <Divider className="div_vert" layout="vertical" />
          <div className="column">
            <h1>{t("profil.title_group")}</h1>
            <div className="column__groups">
              {myGroups && myGroups.loaded ? (
                isNotEmptyArray(myGroups.data) &&
                myGroups.data.map(
                  (group, index) =>
                    index < 3 && (
                      <Link
                        key={index}
                        onClick={() => {
                          props.auth.isAdmin
                            ? props.handleMenu({
                                activeItemGroupesAdmin:
                                  props.items.itemsGroupes.findIndex(
                                    (v) => v.label == "Mes groupes d'échanges"
                                  ),
                              })
                            : props.handleMenu({
                                activeItemGroupes:
                                  props.items.itemsGroupes.findIndex(
                                    (v) => v.label == "Mes groupes d'échanges"
                                  ),
                              });
                          props.handleUpdateGroups({
                            selectedGroup: group,
                          });
                        }}
                        to={{ pathname: `/groupes` }}
                      >
                        <div className="column__groups__item" key={group}>
                          <img
                            src={
                              group.imageUrl
                                ? API_LINK + group.imageUrl
                                : avatar_group1
                            }
                            alt="Logo de groupe"
                            title="Logo de groupe"
                          />
                          <span>
                            {/* Acronyme du groupe */}
                            {parse(group.label)
                              .split(/\s/)
                              .reduce(
                                (response, word) =>
                                  (response += word.slice(0, 1)),
                                ""
                              )
                              .toUpperCase()}
                          </span>
                        </div>
                      </Link>
                    )
                )
              ) : (
                <Loader />
              )}
              <Link
                onClick={() =>
                  props.auth.isAdmin
                    ? props.handleMenu({
                        activeItemGroupesAdmin:
                          props.items.itemsGroupes.findIndex(
                            (v) => v.label == "Mes groupes d'échanges"
                          ),
                      })
                    : props.handleMenu({
                        activeItemGroupes: props.items.itemsGroupes.findIndex(
                          (v) => v.label == "Mes groupes d'échanges"
                        ),
                      })
                }
                to={{ pathname: `/groupes` }}
              >
                <div className="column__groups__item">
                  <BtnBleu>
                    <FaEllipsisH />
                  </BtnBleu>
                  <span>{t("profil.all_group")}</span>
                </div>
              </Link>
            </div>
            <Link
              onClick={() =>
                props.auth.isAdmin
                  ? props.handleMenu({
                      activeItemGroupesAdmin:
                        props.items.itemsGroupes.findIndex(
                          (v) => v.label == "Trouver un groupe"
                        ),
                    })
                  : props.handleMenu({
                      activeItemGroupes: props.items.itemsGroupes.findIndex(
                        (v) => v.label == "Trouver un groupe"
                      ),
                    })
              }
              to={{ pathname: `/groupes` }}
              className="underline"
            >
              {t("profil.more_groups")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
ProfilBar.propTypes = {
  id: PropTypes.string,
  auth: PropTypes.object,
  items: PropTypes.object,
  groups: PropTypes.object,
  states: PropTypes.object,
  handleMenu: PropTypes.func,
  handleAuth: PropTypes.func,
  handleUpdateGroups: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
  groups: state.groups,
  states: state.states,
});

const mapDispatchToProps = (dispatch) => ({
  handleMenu: (value) => {
    dispatch(updateMenu(value));
  },
  handleAuth: (value) => {
    dispatch(updateAuth(value));
  },
  handleUpdateGroups: (value) => {
    dispatch(updateGroups(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilBar);
