import axios from "axios";
// import { fetchPost } from "./api";
import { parseJwt } from "./functions";
import { DateTime } from "luxon";
import { fetchPost } from "./api";

/**
 * Fonction qui permet de renvoyer un nouveau token
 * @returns {String| null} - Le nouveau token
 */
const regenerateTokens = async () => {
  // On envoie le refresh token au serveur pour qu'il nous renvoie un nouveau token
  let response = await fetchPost("/token/refresh", "{}");
  // On vérifie que la réponse contient un token
  if (!response.token) return null;

  // On renvoie le nouveau toke
  return response.token;
};

/**
 * Fonction qui permet de vérifier si le token est encore valide (si no,, on renvoie le token et le refresh token, si oui on renvoie null)
 * @returns {String} | null} - Le nouveau token
 */
export const processToken = async (userToken) => {
  if (!userToken) return null;
  // On décode le token pour récupérer ses infos (date d'expiration)
  if (checkTokenValidity(userToken)) return await regenerateTokens();
  else return null;
};

export async function fetchUser(token) {
  // On récupère les infos de l'user
  let url = process.env.REACT_APP_BASE_URL_API + "/user/connected";
  parseJwt(token).username;
  url = new URL(url);
  let response = await axios.get(url, {
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  // On vérifie que l'user existe bien
  if (!response || !response.data) throw new Error("User not found");
  // On renvoie l'user
  return response.data;
}

/**
 * Fonction qui vérifie si un token est encore valide grâce à sa date d'expiration
 * @param {String} userToken - Le token d'authentification
 * @returns {Boolean} - true si le token est n'est pas valide, false sinon
 */
export const checkTokenValidity = (userToken) => {
  let decodedToken = parseJwt(userToken);
  if (!decodedToken || !decodedToken?.exp) return false;

  // On récupère la date d'expiration du token et la date actuelle
  let exp = DateTime.fromJSDate(new Date(decodedToken.exp * 1000));
  let now = DateTime.local();

  // On vérifie si il reste moins de 100 secondes avant que le token expire
  let diff = exp.diff(now, "seconds").seconds;
  if (diff < 40) return true;
  else return false;
};
