import GroupeDetails from "Components/Groupes/GroupeDetails/GroupeDetails";
import React from "react";
import { connect } from "react-redux";
import Modal from "../Modal";
import PropTypes from "prop-types";

const ModalDétailsGroupe = (props) => {
  return (
    <>
      {props.detailsVisible && (
        <Modal
          visible={props.detailsVisible.visible}
          setVisible={() =>
            props.setDetailsVisible({
              ...props.detailsVisible,
              visible: !props.detailsVisible.visible,
            })
          }
          header={"Détails du groupe"}
          width="80%"
        >
          <GroupeDetails
            dataLoaded={props.dataLoaded}
            groupId={props.detailsVisible.id}
            finder={true}
            setJoinVisible={props.setJoinVisible}
          />
        </Modal>
      )}
    </>
  );
};

ModalDétailsGroupe.propTypes = {
  detailsVisible: PropTypes.object,
  setDetailsVisible: PropTypes.func,
  joinVisible: PropTypes.bool,
  setJoinVisible: PropTypes.func,
  auth: PropTypes.object,
  groups: PropTypes.object,

  dataLoaded: PropTypes.bool,
};

ModalDétailsGroupe.defaultProps = {
  dataLoaded: false,
};

const mapStateToProps = (state) => ({
  items: state.items,
  auth: state.auth,
  groups: state.groups,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ModalDétailsGroupe);
