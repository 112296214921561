import BtnBlancLeft from "Components/Boutons/BtnBlancLeft/btn-blanc-left";
import Filters from "Components/Filters/Filters";
import MassActions from "Components/Offres/GestionDepotOffre/MassActions/MassActions";
import { updateMenu } from "Redux/Actions/menuActions";
import { useFetchGet } from "Services/api";
import { formatDateDatabase, getTransmitterId } from "Services/functions";
import { fr } from "date-fns/locale";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { FaPen } from "react-icons/fa";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "../Evenements/EvenementsManagement/EvenementsManagement.scss";
import "./Management.scss";
import { ALL, ME, RECRUTEUR } from "./ManagementButtons";

registerLocale("fr", fr);
const Management = (props) => {
  const { t } = useTranslation("common");

  // *****************************************************************
  // ****************** UseStates / UseRef *********************
  // *****************************************************************
  const diffusersData = useFetchGet("/diffusers", props.auth.token);
  const companiesData = useFetchGet("/companies/only_name", props.auth.token);

  const [filtreStatus, setFiltreStatus] = useState(null);
  const [filtreMotsCles, setFiltreMotsCles] = useState("");
  const [filtreDate, setFiltreDate] = useState(null);
  const [filtreCompany, setFiltreCompany] = useState(null);
  const [filtreDiffuseur, setFiltreDiffuseur] = useState(null);
  const [filtreOnlyCompanies, setFiltreOnlyCompanies] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [filterCompanyList, setFilterCompanyList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [filterDiffuseurList, setFilterDiffuseurList] = useState([]);
  const [massActions, setMassActions] = useState(false);
  const [isKeywordFiltering, setIsKeywordFiltering] = useState(false);

  // *****************************************************************
  // ****************** UseEffect *********************
  // *****************************************************************

  useEffect(() => {
    diffusersData.loaded && setFilterDiffuseurList(diffusersData.data);

    companiesData.loaded &&
      companiesData.data &&
      setFilterCompanyList(companiesData.data["hydra:member"]);
  }, [diffusersData.loaded, companiesData.loaded]);

  // Hook qui se déclenche au changement de statut d'un des filtres
  useEffect(() => {
    if (!filtreDate || (filtreDate[0] && filtreDate[1])) {
      handleFilter();
    }
  }, [
    filtreStatus,
    filtreDate,
    filtreCompany,
    filtreDiffuseur,
    isKeywordFiltering,
    filtreOnlyCompanies,
  ]);

  const setFilters = (url) => {
    url.searchParams.set(
      "itemsPerPage",
      props.itemsPerPage ? props.itemsPerPage : 20
    );

    if (
      (props.auth.isAdmin ||
        (props.actualPage === "offre" && props.auth.isValidator)) &&
      props.currentTransmitter[0] !== ME
    ) {
      if (
        props.currentTransmitter[0] === ALL ||
        props.currentTransmitter[0] === RECRUTEUR
      ) {
        url.searchParams.delete("transmitter[]");
      } else {
        getTransmitterId(
          props.currentTransmitter,
          props.secondaryTables.transmittersData
        ).forEach((transmitterId) => {
          url.searchParams.append("transmitter[]", transmitterId);
        });
      }
    } else {
      props.actualPage === "offre"
        ? url.searchParams.append(
            "createdByUser[]",
            props.auth.userConnected.id
          )
        : url.searchParams.append("createdBy[]", props.auth.userConnected.id);
    }

    if (filtreOnlyCompanies) {
      // Delete existing transmitter filter
      url.searchParams.delete("transmitter[]");
      url.searchParams.append(
        "transmitter[]",
        props.secondaryTables.transmittersData.find(
          (transmitter) => transmitter.name === "Recruteur"
        )?.id
      );
    } else url.searchParams.delete("onlyCompanies");
    if (filtreMotsCles != "") url.searchParams.set("keyword", filtreMotsCles);
    //? On ne veut pas afficher les entités ayant le status supprimé
    //? Donc soit on met le query param du filtre sélectionné soit on me le query de tous les status (en props)
    if (filtreStatus) url.searchParams.set("state", filtreStatus.id);
    else {
      if (props.states) {
        props.states?.forEach((state) => {
          //? Si on choisit tous les status, on vérifie si le transmitter est un recruteur, auquel cas on ne veut pas afficher les offres publiées
          if (
            state?.statusLabel === "Publié" &&
            props.currentTransmitter[0] === RECRUTEUR &&
            props.currentTransmitter?.length === 1
          ) {
            return;
          }
          url.searchParams.append("state[]", state?.id);
        });
      }
    }
    if (filtreCompany) url.searchParams.set("companyName", filtreCompany.name);
    if (filtreDiffuseur) url.searchParams.set("diffuser", filtreDiffuseur.id);
    if (filtreDate && filtreDate[0])
      url.searchParams.set(
        props.actualPage === "événement"
          ? "startingAt[after]"
          : "createdAt[after]",
        formatDateDatabase(filtreDate[0])
      );
    if (filtreDate && filtreDate[1])
      url.searchParams.set(
        props.actualPage === "événement"
          ? "endingAt[before]"
          : "createdAt[before]",
        formatDateDatabase(filtreDate[1])
      );
    return url;
  };

  // Fonction globale de filtre
  const handleFilter = () => {
    let url = null;
    if (!props?.states?.length > 0) return;

    if (props.actualPage === "offre") {
      url = setFilters(
        props.currentTransmitter[0] === RECRUTEUR
          ? new URL(`${process.env.REACT_APP_BASE_URL_API}/offers/recruiter`)
          : new URL(`${process.env.REACT_APP_BASE_URL_API}/offers`)
      );
      // Si pas de filtre createdAt[after] ou createdAt[before] on trie par date de modification par défaut
      if (
        !url.searchParams.get("createdAt[after]") &&
        !url.searchParams.get("createdAt[before]")
      ) {
        url.searchParams.set("order[modifiedAt]", "desc");
      } // Sinon on trie par date de création
      else url.searchParams.set("order[createdAt]", "desc");
    } else if (props.actualPage === "événement") {
      url = setFilters(
        props.currentTransmitter[0] === RECRUTEUR
          ? new URL(`${process.env.REACT_APP_BASE_URL_API}/events/recruiter`)
          : new URL(`${process.env.REACT_APP_BASE_URL_API}/events`)
      );
      url.searchParams.set("order[startingAt]", "asc");
      url.searchParams.set("endingAt[after]", formatDateDatabase(new Date()));
    } else if (props.actualPage === "actu") {
      url = setFilters(
        props.currentTransmitter[0] === RECRUTEUR
          ? new URL(`${process.env.REACT_APP_BASE_URL_API}/news/recruiter`)
          : new URL(`${process.env.REACT_APP_BASE_URL_API}/news`)
      );
      url.searchParams.set("order[modifiedAt]", "desc");
    }
    url.searchParams.append("university", props.auth.universityConnected?.id);

    props.setUrl(url);
    props.updateFunction({
      dataFromPagination: null,
    });
  };

  // Si on change de transmetteur, on remet le filtre à false pour ne pas persister le filtre sur les autres requêtes
  useEffect(() => {
    setFiltreOnlyCompanies(false);
  }, [props.offers.currentTransmitter]);

  return (
    <div className="management_new_event_offer_filtres">
      <div className="management_new_event_offer_filtres__top">
        <Filters
          filtreMotsCles={filtreMotsCles}
          setFiltreMotsCles={setFiltreMotsCles}
          filtreDate={filtreDate}
          setFiltreDate={setFiltreDate}
          filtreDropdown={filtreStatus}
          setFiltreDropdown={(value) => {
            if (props.actualPage === "offre") {
              if (value?.statusLabel === "En Attente") setMassActions(true);
              else {
                setMassActions(false);
                props.setItemsPerPage(20);
              }
            }

            setFiltreStatus(value);
          }}
          dataDropdown={
            (props.offers.currentTransmitter[0] === RECRUTEUR &&
              props.offers.currentTransmitter?.length === 1) ||
            (props.currentTransmitter[0] === RECRUTEUR &&
              props.currentTransmitter?.length === 1)
              ? props.states?.filter((state) => state?.statusLabel !== "Publié")
              : props.states
          }
          dropdownOption="statusLabel"
          setIsKeywordFiltering={setIsKeywordFiltering}
          filterFunction={handleFilter}
          noReset
        ></Filters>
        {/* Filtre par entreprise si le isActive du bouton entreprise est true */}
        {props.auth.isConnected &&
          (props.auth.isAdmin ||
            (props.actualPage === "offre" && props.auth?.isValidator)) &&
          props.btnEntreprise.isActive && (
            <Dropdown
              value={filtreCompany}
              className="membership_input_search"
              placeholder={t("filters.companyFilter")}
              showClear
              filter
              filterBy="name"
              options={filterCompanyList?.sort((a, b) =>
                a.name.localeCompare(b.name)
              )}
              optionLabel="name"
              onChange={(e) => {
                setFiltreCompany(e.target.value);
              }}
            />
          )}
        {/* Filtre par entreprise si les transmetteurs sont ceux de l'univ */}

        {props.currentTransmitter.length > 1 &&
          props.actualPage === "offre" &&
          props.auth.isConnected &&
          (props.auth.isAdmin ||
            (props.actualPage === "offre" && props.auth.isValidator)) && (
            <>
              <Checkbox
                inputId="onlyCompanies"
                name="onlyCompanies"
                value={true}
                checked={filtreOnlyCompanies}
                onChange={(e) => {
                  setFiltreOnlyCompanies(e.checked);
                }}
                style={{
                  marginLeft: "0.5rem",
                }}
              />

              <label
                htmlFor="onlyCompanies"
                style={{
                  marginLeft: "0.5rem",
                }}
              >
                {t("filters.companyFilterCheckbox")}
              </label>
            </>
          )}
        {/* Filtre par diffuseur si le isActive du bouton diffuseur est true */}
        {props.auth.isConnected &&
          (props.auth.isAdmin ||
            (props.actualPage === "offre" && props.auth?.isValidator)) &&
          props.btnDiffuseur?.isActive && (
            <Dropdown
              value={filtreDiffuseur}
              className="membership_input_search"
              placeholder={t("filters.diffuserFilter")}
              showClear
              options={filterDiffuseurList}
              onChange={(e) => {
                setFiltreDiffuseur(e.target.value);
              }}
            />
          )}
        {/* refresh filters */}
        {(filtreMotsCles ||
          filtreStatus ||
          filtreCompany ||
          filtreDiffuseur ||
          filtreOnlyCompanies ||
          (filtreDate && filtreDate[1])) && (
          <li className="management_new_event_offer_filtres__top__refresh">
            <i
              className="pi pi-refresh"
              style={{ fontSize: "1.5rem", fontWeight: "600" }}
              onClick={() => {
                setFiltreStatus(null);
                setIsKeywordFiltering(false);
                setFiltreDiffuseur(null);
                setFiltreOnlyCompanies(false);
                setFiltreCompany(null);
                setFiltreDate(null);
                setFiltreMotsCles("");
                props.setItemsPerPage(20);
                setMassActions(false);
              }}
            />
          </li>
        )}
        {props.actualPage &&
          props.actualPage === "événement" &&
          (props.auth.isAlumni || props.auth.isPersonnel) && (
            <div className="management_new_event_offer_filtres__top__createBtn">
              <Link to={{ pathname: `/evenements` }}>
                <BtnBlancLeft
                  btnTexte={t("eventApresConnexion.submenuProposerAlumni")}
                  btnAction={() => {
                    props.handleMenu({
                      activeItemEvenement: props.items.itemsEvenement.findIndex(
                        (v) => v.label == "Proposer un événement"
                      ),
                    });
                  }}
                ></BtnBlancLeft>
              </Link>
            </div>
          )}
        {props.actualPage &&
          props.actualPage === "événement" &&
          props.auth.isAdmin && (
            <div className="management_new_event_offer_filtres__top__createBtn">
              <Link to={{ pathname: `/evenements` }}>
                <BtnBlancLeft
                  btnTexte={t("eventApresConnexion.submenuCreateAdmin")}
                  btnAction={() => {
                    props.handleMenu({
                      activeItemEvenementAdmin:
                        props.items.itemsEvenementAdmin.findIndex(
                          (v) => v.label == "Créer un événement"
                        ),
                    });
                  }}
                ></BtnBlancLeft>
              </Link>
            </div>
          )}
        {props.actualPage &&
          props.actualPage === "actu" &&
          (props.auth.isAlumni || props.auth.isPersonnel) && (
            <div className="management_new_event_offer_filtres__top__createBtn">
              <BtnBlancLeft
                btnTexte={t("actusApresConnexion.submenuCreateAlumni")}
                btnAction={() =>
                  props.handleMenu({
                    activeItemActu: props.items.itemsActu.findIndex(
                      (v) => v.label == "Proposer une actu"
                    ),
                  })
                }
              >
                <FaPen />
              </BtnBlancLeft>
            </div>
          )}
        {props.actualPage &&
          props.actualPage === "actu" &&
          props.auth.isAdmin && (
            <div className="management_new_event_offer_filtres__top__createBtn">
              <BtnBlancLeft
                btnTexte={t("actusApresConnexion.submenuCreateAdmin")}
                btnAction={() =>
                  props.handleMenu({
                    activeItemActuAdmin: props.items.itemsActuAdmin.findIndex(
                      (v) => v.label == "Créer une actu/conseil"
                    ),
                  })
                }
              >
                <FaPen />
              </BtnBlancLeft>
            </div>
          )}
      </div>
      {massActions && (props.auth.isAdmin || props.auth?.isValidator) && (
        <MassActions
          itemsPerPage={props.itemsPerPage}
          setItemsPerPage={props.setItemsPerPage}
          url={props.url}
          setUrl={props.setUrl}
        ></MassActions>
      )}
    </div>
  );
};

Management.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.object,
  offers: PropTypes.object,
  secondaryTables: PropTypes.object,

  handleMenu: PropTypes.func,
  updateFunction: PropTypes.func,

  states: PropTypes.array,

  btnEntreprise: PropTypes.object,
  btnDiffuseur: PropTypes.object,

  currentTransmitter: PropTypes.array,

  actualPage: PropTypes.string,
  itemsPerPage: PropTypes.number,
  setItemsPerPage: PropTypes.func,

  url: PropTypes.objectOf(URL),
  setUrl: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
  offers: state.offers,
  secondaryTables: state.secondaryTables,
});

const mapDispatchToProps = (dispatch) => ({
  handleMenu: (value) => {
    dispatch(updateMenu(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Management);
