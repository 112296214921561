import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import Loader from "Components/Loader/loader";
import { RECRUTEUR } from "Components/Management/ManagementButtons";
import Modal from "Components/Modal/Modal";
import CandidatureForm from "Components/Offres/CandidatureForm/CandidatureForm";
import GestionDepotOffreActions from "Components/Offres/GestionDepotOffre/GestionDepotOffreActions/GestionDepotOffreActions";
import { updateAuth } from "Redux/Actions/authActions";
import { fetchGet } from "Services/api";
import { sanitizeHtml } from "Services/functions";
import { HTMLParseOptions } from "Services/htmlParseOptions";
import { errorToast } from "Services/toastFunctions";
import recruitersImage from "assets/recruteurs.png";
import axios from "axios";
import parse from "html-react-parser";
import { Divider } from "primereact/divider";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { BsHouseDoor, BsPeople } from "react-icons/bs";
import { FaChevronLeft, FaRegBuilding, FaWheelchair } from "react-icons/fa";
import { IoMdDesktop, IoMdHome } from "react-icons/io";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import image from "../../../../assets/offre1.webp";
import "./OffreDetail.scss";

const OffreDetail = (props) => {
  const API_LINK = process.env.REACT_APP_BASE_URL_API.split("/api")[0];
  const [visibleModalPostuler, setVisibleModalPostuler] = useState(false);
  const [isModalPostulerRapide, setIsModalPostulerRapide] = useState(false);

  const [loaderIsVisible, setLoaderIsVisible] = useState(false);
  const [isLoadingCandidature, setIsLoadingCandidature] = useState(false);
  const [offre, setOffre] = useState();
  const [isOfferSaved, setIsOfferSaved] = useState();
  const [isEditable, setIsEditable] = useState(false);

  const [companyInfos, setCompanyInfos] = useState(null);

  let id = props.id ? props.id : useParams().id;

  const paramRecruteur = new URLSearchParams(window.location.search).get("r");
  const isRecruiter = paramRecruteur === "true";

  /**
   * Récupère les informations de l'entreprise de l'offre
   * @param {{companyName: String}} offre
   */
  const getCompanyInfos = async () => {
    const companyData = await fetchGet(
      `/companies?name=${offre.companyName}`,
      props.auth.token
    );
    setCompanyInfos(companyData["hydra:member"][0] || null);
  };

  useEffect(async () => {
    if (
      !companyInfos &&
      offre?.id &&
      offre?.companyName &&
      (offre?.transmitter?.name === RECRUTEUR || isRecruiter)
    ) {
      await getCompanyInfos();
    }
  }, [offre, isRecruiter]);

  // Va chercher dans la base l'offre que l'on veut afficher
  const getOffer = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL_API}/offers/${id}${
          isRecruiter ? "/recruiter" : ""
        }`,
        {
          headers: props.auth.token
            ? {
                accept: "application/json",
                Authorization: `Bearer ${props.auth.token}`,
              }
            : {
                accept: "application/json",
              },
        }
      )
      .then(function (response) {
        setLoaderIsVisible(false);
        setOffre(response.data);
        setIsOfferSaved(
          props.auth.userConnected.savedOffers.filter(
            (offer) => offer.id === response.data.id
          ).length > 0
        );
        if (
          (props.auth.isAdmin ||
            response.data.id === props.auth.userConnected.id ||
            props.auth?.isValidator) &&
          !!paramRecruteur
        )
          setIsEditable(true);
      })
      .catch(function (error) {
        errorToast(
          error?.response?.data?.detail ||
            "Erreur lors du chargement de l'offre"
        );

        // Retournement en arrière si l'offre n'est pas trouvée (après 2 secondes)
        setTimeout(() => {
          window.history.back();
        }, 2000);
      });
  };

  //  Useeffect de chargement
  useEffect(() => {
    setLoaderIsVisible(true);
    getOffer();
  }, []);

  useEffect(() => {
    // Si la carte est modifiée depuis le depot d'offre on affiche sa nouvelle valeur dans le détail
    if (props.offers?.dataFromPagination?.data && props.id) {
      const newOffer = props.offers.dataFromPagination.data.filter(
        (offer) => offer.id == id
      );
      newOffer.length > 0 && setOffre(newOffer[0]);
    }
  }, [props.offers?.dataFromPagination?.data]);

  // Ajoute l'offre aux offres sauvegardées
  const addOfferToSaveList = () => {
    const url = `${process.env.REACT_APP_BASE_URL_API}/offers/${offre.id}/save`;
    const data = { savedByUsers: [`api/users/${props.auth.userConnected.id}`] };
    axios
      .post(url, data, {
        headers: props.auth.token
          ? {
              accept: "application/json",
              Authorization: `Bearer ${props.auth.token}`,
            }
          : {
              accept: "application/json",
            },
      })
      .then((res) => {
        setIsOfferSaved(true);
        let user = props.auth.userConnected;
        user.savedOffers = user.savedOffers.concat(res.data);
        props.handleAuth({
          userConnected: user,
        });
      })
      .catch((error) => {
        errorToast(error?.response?.data?.detail || "Une erreur est survenue");
      });
  };

  // Retire l'offre des offres sauvegardées
  const removeOfferFromSaveList = () => {
    const url = `${process.env.REACT_APP_BASE_URL_API}/offers/${offre.id}/erase`;
    const data = { savedByUsers: [`api/users/${props.auth.userConnected.id}`] };
    axios
      .post(url, data, {
        headers: props.auth.token
          ? {
              accept: "application/json",
              Authorization: `Bearer ${props.auth.token}`,
            }
          : {
              accept: "application/json",
            },
      })
      .then((res) => {
        setIsOfferSaved(false);
        let user = props.auth.userConnected;
        (user.savedOffers = user.savedOffers.filter(
          (saved) => saved.id !== res.data.id
        )),
          props.handleAuth({
            userConnected: user,
          });
      })
      .catch((error) => {
        errorToast(error?.response?.data?.detail || "Une erreur est survenue");
      });
  };

  /**
  );
   * Permet de renvoyer l'url du logo de l'entreprise soit par l'url de notre API, soit par clearbit
   * @param {{companyImageUrl: null|String, companyUrl: null|String}} offre
   * @returns {String} url du logo de l'entreprise
   */
  const findCompanyLogo = (offre) => {
    // Si l'offre a un logo on l'utilise
    if (offre?.companyImageUrl || offre?.company?.imageUrl)
      return offre.companyImageUrl || offre.company.imageUrl;
    if (!offre?.companyUrl && !offre?.company?.companyUrl)
      return recruitersImage;
    // Sinon on utilise clearbit pour trouver le logo de l'entreprise

    try {
      const url = new URL(`${offre?.companyUrl || offre?.company?.companyUrl}`);
      return `https://logo.clearbit.com/${url.hostname}`;
    } catch (e) {
      return recruitersImage;
    }
  };

  const findLogo = (offre) => {
    if (isRecruiter || offre?.transmitter?.name === "Recruteur")
      return findCompanyLogo(offre);
    const logo =
      offre.university?.imageTransparentAndColorUrl ||
      props.universities?.universitiesData?.find(
        (university) => university.slug === offre.universitySlug
      )?.imageTransparentAndColorUrl ||
      "";
    switch (offre.transmitter?.name) {
      case "Diffuseur":
        return props.secondaryTables.transmittersData
          ? props.secondaryTables.transmittersData.find(
              (transmitter) => transmitter.name == "Diffuseur"
            ).imageUrl
          : API_LINK + logo;
      case "Admin":
        return props.secondaryTables.transmittersData
          ? props.secondaryTables.transmittersData.find(
              (transmitter) => transmitter.name == "Admin"
            ).imageUrl
          : API_LINK + logo;
      case "Etudiant":
        return API_LINK + logo;
      case "Université":
        return API_LINK + logo;
      case "Personnel":
        return API_LINK + logo;
      case "Alumni":
        return API_LINK + logo;
      case "Recruteur":
        return API_LINK + (offre?.company?.logoLink || logo);
      default:
        return recruitersImage;
    }
  };

  /**

   * Affiche les contacts de l'entreprise
   * en JSX
   * @return {JSX.Element} Les contacts de l'entreprise
   */

  const displayCompanyContacts = () => {
    return (
      <div className="modal_contact_candidature_rapide">
        {offre.contacts.map((contact) => (
          <>
            {contact.email && (
              <a href={`mailto:${contact?.email}`}>
                <p>Par mail :</p>
                <span>{contact?.email}</span>
              </a>
            )}
            {contact.phone && (
              <a href={`tel:${offre.contacts[0]?.phone}`}>
                <p>Par téléphone :</p>
                <span>{contact?.phone}</span>
              </a>
            )}
          </>
        ))}
        {offre.companyUrl && (
          <a
            href={offre.companyUrl.includes("http") && offre.companyUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>Depuis le site de l&apos;entreprise :</p>
            <span>{offre.companyUrl}</span>
          </a>
        )}
        {offre.urlCandidature && (
          <a
            href={offre.urlCandidature.includes("http") && offre.urlCandidature}
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>Depuis le lien de l&apos;offre :</p>
            <span>{offre.urlCandidature}</span>
          </a>
        )}
      </div>
    );
  };

  return (
    <div>
      {!props.id && <Header />}
      {loaderIsVisible ? (
        <Loader />
      ) : (
        props.auth.isConnected &&
        offre && (
          <div className="offre_detail_container">
            {!props.id && (
              <div className="offre_detail_container__title">
                <h1 className="h1_trait_dessus">Détail de l&apos;offre</h1>
                <BtnBlanc
                  btnTexte="Revenir aux offres"
                  btnIcone={<FaChevronLeft />}
                  btnAction={() => window.history.back()}
                />
              </div>
            )}
            <div className="offre_detail_container__offre">
              {(offre?.imageUrl || offre?.nexusImageUrl) && (
                <div className="offre_detail_container__offre__background">
                  <img
                    src={
                      offre?.imageUrl ? offre?.imageUrl : offre?.nexusImageUrl
                    }
                    alt="Image de l'offre"
                    title="Image de l'offre"
                    onError={(e) => {
                      e.target.src = image;
                    }}
                  />
                </div>
              )}
              <div className="offre_detail_container__offre__top">
                <div className="offre_detail_container__offre__top__logo">
                  <img
                    className="offre_detail_container__offre__top__logo-img"
                    src={findLogo(offre)}
                    onError={(e) => {
                      e.target.src = recruitersImage;
                      e.target.onerror = null;
                    }}
                    alt="Logo entreprise"
                    title="Logo entreprise"
                  />
                </div>
                {isEditable && (
                  <div className="offre_detail_container__offre__top__buttons">
                    <GestionDepotOffreActions
                      statut={offre.state}
                      id={offre.id}
                      offre={offre}
                      feedback={offre.feedback?.feedback}
                      isRecruteur={isRecruiter}
                      secondClass="desktop"
                    ></GestionDepotOffreActions>
                  </div>
                )}
              </div>

              <div className="offre_detail_container__offre__description">
                <p className="offre_detail_container__offre__description__title--main">
                  <a href={offre.urlCompany}>{offre.companyName}</a>
                  <span className="offre_detail_container__offre__description__title--main__offre">{` - ${offre.title}`}</span>
                </p>
                <div className="offre_detail_container__offre__description__infoEntreprise">
                  <ul className="offre_detail_container__offre__description__infoEntreprise__list">
                    <li className="offre_detail_container__offre__description__infoEntreprise__list__item">
                      <FaRegBuilding title="Structure de l'entreprise" />{" "}
                      {companyInfos?.structure
                        ? companyInfos.structure?.label
                        : "Non renseigné"}
                    </li>
                    <li className="offre_detail_container__offre__description__infoEntreprise__list__item">
                      <BsPeople title="Effectif de l'entreprise" />{" "}
                      {companyInfos?.totalStaff
                        ? companyInfos.totalStaff?.label
                        : "Non renseigné"}
                    </li>
                    {offre?.sector && (
                      <li className="offre_detail_container__offre__description__infoEntreprise__list__item">
                        <BsHouseDoor title="Secteur de l'entreprise" />{" "}
                        {offre?.sector?.label}
                      </li>
                    )}
                  </ul>
                </div>
                {offre.domains != null && (
                  <p className="offre_detail_container__offre__description__title">
                    {offre.domains.length > 1 ? "Domaines" : "Domaine"} de
                    l&apos;offre : <br />
                    <span>
                      {offre.domains.map((domain) => (
                        <React.Fragment key={domain.label}>
                          {domain.label}
                          {offre.domains.indexOf(domain) !==
                          offre.domains.length - 1
                            ? ", "
                            : ""}
                        </React.Fragment>
                      ))}{" "}
                    </span>
                  </p>
                )}
                <p className="offre_detail_container__offre__description__title">
                  Catégorie de contrat de l&apos;offre :{" "}
                  <span>{offre.offerCategory.label}</span>
                </p>
                <p className="offre_detail_container__offre__description__title">
                  Type de contrat de l&apos;offre :{" "}
                  <span>
                    {offre.typeOfContract?.label
                      ? offre.typeOfContract?.label
                      : offre.offerCategory.label}
                  </span>
                </p>
                {offre?.levelOfEducations[0] !== undefined && (
                  <p className="offre_detail_container__offre__description__title">
                    {offre.levelOfEducations.length > 1 ? "Niveaux" : "Niveau"}{" "}
                    d&apos;études : <br />
                    <span>
                      {offre.levelOfEducations.map((level) => (
                        <>
                          {level.label}
                          {offre.levelOfEducations.indexOf(level) !==
                          offre.levelOfEducations.length - 1
                            ? ", "
                            : ""}
                        </>
                      ))}{" "}
                    </span>
                  </p>
                )}
                <p className="offre_detail_container__offre__description__title">
                  Lieu de l&apos;offre :{" "}
                  <span>
                    {offre.country} - {offre.department} - {offre.city}
                  </span>
                </p>
                {!offre.minSalary && !offre.maxSalary && offre.gratification ? (
                  <p className="offre_detail_container__offre__description__title">
                    Gratification : <span>{offre.gratification}</span>
                  </p>
                ) : (
                  <p className="offre_detail_container__offre__description__title">
                    Tranche de salaire :{" "}
                    <span>
                      {offre.minSalary && offre.maxSalary
                        ? `${offre.minSalary} € - ${offre.maxSalary} €`
                        : "Non renseigné"}
                    </span>
                  </p>
                )}
                <p className="offre_detail_container__offre__description__titleWithMarge">
                  Description de l&apos;entreprise ou du service
                </p>
                <p className="offre_detail_container__offre__description__text">
                  {offre.companyDescription &&
                    parse(sanitizeHtml(offre.companyDescription))}
                </p>
                <p className="offre_detail_container__offre__description__titleWithMarge">
                  Description de l&apos;offre
                </p>
                <p className="offre_detail_container__offre__description__text">
                  {parse(sanitizeHtml(offre.description), HTMLParseOptions)}
                </p>
                {offre.experience && (
                  <>
                    <p className="offre_detail_container__offre__description__titleWithMarge">
                      Description du profil recherché
                    </p>
                    <p className="offre_detail_container__offre__description__text">
                      {parse(sanitizeHtml(offre.experience), HTMLParseOptions)}
                    </p>
                  </>
                )}
              </div>
              <div className="offre_detail_container__offre__description__bottom">
                <div className="offre_detail_container__offre__description__bottom--right">
                  <p className="offre_detail_container__offre__description__title">
                    INFORMATIONS COMPLEMENTAIRES :
                  </p>
                  {(!offre.accessibleForDisabled ||
                    !offre.remoteWorking ||
                    !offre.accommodation) && (
                    <p className="offre_detail_container__offre__description__title">
                      Non renseigné
                    </p>
                  )}
                  {offre.accessibleForDisabled && (
                    <p className="offre_detail_container__offre__description__title">
                      <FaWheelchair /> Poste handi-accueillant
                    </p>
                  )}
                  {offre.remoteWorking && (
                    <p className="offre_detail_container__offre__description__title">
                      <IoMdDesktop /> Télétravail possible
                    </p>
                  )}
                  {offre.accommodation && (
                    <p className="offre_detail_container__offre__description__title">
                      <IoMdHome /> Hébergement possible
                    </p>
                  )}
                </div>
              </div>
              <div className="offre_detail_container__boutons">
                {!isRecruiter && (
                  <BtnBleu
                    btnTexte="Postuler"
                    btnAction={async () => {
                      if (
                        offre.transmitter?.name === RECRUTEUR ||
                        isRecruiter
                      ) {
                        setVisibleModalPostuler(!visibleModalPostuler);
                      } else {
                        setIsModalPostulerRapide(!isModalPostulerRapide);
                      }
                    }}
                  />
                )}
                {isModalPostulerRapide && (
                  <Modal
                    visible={isModalPostulerRapide}
                    setVisible={setIsModalPostulerRapide}
                    header="Vous souhaitez postuler à cette offre "
                  >
                    {displayCompanyContacts()}
                  </Modal>
                )}
                {visibleModalPostuler && (
                  <Modal
                    visible={visibleModalPostuler}
                    setVisible={setVisibleModalPostuler}
                    header="Vous souhaitez postuler à cette offre "
                  >
                    {isLoadingCandidature ? (
                      <Loader />
                    ) : (
                      <>
                        {displayCompanyContacts()}
                        <Divider />
                        <CandidatureForm
                          setIsLoadingCandidature={setIsLoadingCandidature}
                          setVisibleModalPostuler={setVisibleModalPostuler}
                          offre={offre}
                        />
                      </>
                    )}
                  </Modal>
                )}
                {isOfferSaved ? (
                  <BtnBleu
                    btnTexte="Retirer des offres sauvegardéees"
                    btnAction={() => {
                      removeOfferFromSaveList();
                    }}
                  ></BtnBleu>
                ) : (
                  <BtnBlanc
                    btnTexte="Sauvegarder l'offre"
                    btnAction={() => {
                      addOfferToSaveList();
                    }}
                  ></BtnBlanc>
                )}
              </div>
            </div>
          </div>
        )
      )}
      {!props.id && <Footer />}
    </div>
  );
};

OffreDetail.propTypes = {
  auth: PropTypes.object,
  universities: PropTypes.object,
  offers: PropTypes.object,
  secondaryTables: PropTypes.object,

  handleAuth: PropTypes.func,
  adminAuth: PropTypes.func,

  id: PropTypes.number,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  offers: state.offers,
  secondaryTables: state.secondaryTables,
  universities: state.universities,
});

const mapDispatchToProps = (dispatch) => ({
  handleAuth: (value) => {
    dispatch(updateAuth(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OffreDetail);
