import React from "react";
import PropTypes from "prop-types";
import "./EvenementCard.scss";
import event1 from "assets/Event1.webp";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import { FaEllipsisH, FaUsers } from "react-icons/fa";
import { Menu } from "primereact/menu";
import { useRef } from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";
import { formatDate } from "Services/functions";
import { useTranslation } from "react-i18next";

const API_LINK = `${process.env.REACT_APP_BASE_URL_API}`.replace("/api", "");

const EvenementCard = (props) => {
  const menu = useRef(null);
  const items = [
    { label: "Modifier" },
    { separator: true },
    { label: "Supprimer" },
  ];
  const { t } = useTranslation("common");
  return (
    <div
      className="evenement_container cardHoverEvenement"
      onClick={() => {
        props.onClick && props.onClick();
      }}
    >
      <div className="evenement_container__top">
        <div className="evenement_container__top__categorie">
          <span className="etiquette"> {props.categorie.label} </span>
        </div>
        <div className="evenement_container__image">
          {props.url == "event1" ? (
            <>
              <img src={event1} alt="Fond événement" />
              <div
                className="image__bg"
                style={{ backgroundImage: `url(${event1})` }}
              ></div>
            </>
          ) : (
            <>
              <img src={props.url} alt="Fond événement" />
              <div
                className="image__bg"
                style={{
                  backgroundImage: `url(${props.url})`,
                }}
              ></div>
            </>
          )}
        </div>
      </div>
      <div className="evenement_container__corps">
        <div className="evenement_container__corps__debutdate">
          {props.debutDate}
          {props.groupe && (
            <>
              <Menu
                appendTo={document.getElementById("app")}
                model={items}
                popup
                ref={menu}
                style={{ textAlign: "center" }}
              />
              <div className="btn-point">
                <BtnBlanc
                  btnAction={() => (event) => menu.current.toggle(event)}
                >
                  <FaEllipsisH />
                </BtnBlanc>
              </div>
            </>
          )}
        </div>
        <div className="evenement_container__corps__findate">
          {props.finDate}
        </div>
        {props.groupe ? (
          <div className="event_groupe">
            <FaUsers /> {props.groupe_name}
          </div>
        ) : (
          <div className="evenement_container__corps__inscription">
            {props.inscription
              ? t("evenements.closedInscriptions")
              : `${t("evenements.openInscriptions")} ${
                  props?.registerEnd != undefined
                    ? t("evenements.openInscriptionsUntil", {
                        date: formatDate(props.registerEnd),
                      })
                    : ""
                }`}
          </div>
        )}

        <div className="evenement_container__corps__title" title={props.title}>
          {props.title}
        </div>
        <div
          className="evenement_container__corps__chapeau"
          title={props.chapeau}
        >
          <span>{parse(props?.chapeau)}</span>
        </div>
      </div>
      <div className="evenement_container__bottom">
        <div className="evenement_container__bottom__lien">
          {t("aLaUne.savoirPlus")}
        </div>
        <div className="evenement_container__bottom__logo">
          <img
            src={
              API_LINK +
              "/media/logos_univs/logo_pantheon_sorbonne_transparent_couleur.webp"
            }
            alt={"Logo Université"}
            title={"Logo Université"}
          />
        </div>
      </div>
    </div>
  );
};

EvenementCard.propTypes = {
  categorie: PropTypes.object,
  debutDate: PropTypes.string,
  finDate: PropTypes.string,
  title: PropTypes.string,
  inscription: PropTypes.bool,
  chapeau: PropTypes.any,
  logo: PropTypes.any,
  image: PropTypes.any,
  groupe: PropTypes.bool,
  groupe_name: PropTypes.string,
  url: PropTypes.string,
  onClick: PropTypes.func,
  registerEnd: PropTypes.string,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EvenementCard);
