import { UPDATE_EVENTS } from "Redux/Actions/eventsActions";

const INITIAL_STATE = {
  categoriesData: [],
  etats: [],
  randomEvent: [],
  participants: [],
  eventParticipants: [],
  myEvents: [],
  entreprise: "",
  currentTransmitter: [],
};

const eventsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_EVENTS: {
      return {
        ...state,
        ...action.value,
      };
    }
    default:
      return state;
  }
};

export default eventsReducer;
