import { Chart as PrimeChart } from "primereact/chart";
import React from "react";
import PropTypes from "prop-types";
import Margin from "../Margin";
import { Button } from "primereact/button";
import { exportForExcel } from "../Data/Export";

import ChartDataLabels from "chartjs-plugin-datalabels";

/**
 * Composant de type Ligne recevant trois props :
 * @param {Object} props.data qui contient les données à afficher
 * @param {Object} props.options qui contient la façon dont on les affiche
 * @param {string} props.type qui contient le type de la stat affiché
 *
 * Le composant {@link PrimeChart} provient de prime react, qui intègre ChartJS
 *
 * {@link ChartDataLabels} permet quant à lui de rajouter des options au
 * composant {@link PrimeChart} afin d'afficher les données des graphiques
 * directement sur celui-ci.
 */
const Line = ({ data, options, type }) => {
  const exportChartExcel = () => {
    exportForExcel("line", data, options, type);
  };

  return (
    <div className="stats_container__component-boxshadow">
      <PrimeChart
        type="line"
        data={data}
        plugins={[ChartDataLabels]}
        options={options}
      />
      <Margin position="bottom" size="xs" />
      <div className="align-items-center export-buttons stats_container__component-export">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportChartExcel}
          className="p-button-success xl"
          data-pr-tooltip="XLS"
        />
      </div>
    </div>
  );
};

Line.propTypes = {
  data: PropTypes.object,
  options: PropTypes.object,
  type: PropTypes.string,
};

Line.defaultProps = {
  type: "statistiques",
};

export default Line;
