import ButtonsBarDisconnected from "Components/Header/ButtonsBarDisconnected/ButtonsBarDisconnected";
import Header from "Components/Header/Header";
import Loader from "Components/Loader/loader";
import NavBarTab from "Components/NavBar/NavBarTab/NavBarTab";
import TempPagination from "Components/Pagination/TempPagination";
import { useFetchGetPagination } from "Services/api";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Dropdown } from "primereact/dropdown";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import EtudiantCard from "../../AnnuaireEtudiant/EtudiantCard/EtudiantCard";
import AnnuaireAlumniSearchBar from "../AnnuaireAlumniSearchBar/AnnuaireAlumniSearchBar";
import "./AnnuaireAlumniContainer.scss";

/**
 * Permet d'afficher l'annuaire alumni
 */
const AnnuaireAlumniContainer = (props) => {
  const { t } = useTranslation("common");

  const annuaireContainerRef = useRef(null);

  // dropdown de tri
  const [triAlumnis, setTriAlumnis] = useState(null);
  const triAlumnisData = [
    { label: "Par défaut", value: "Default" },
    { label: "Par entreprise", value: "Entreprise" },
    {
      label: "Par date d'obtention du dernier diplôme",
      value: "Dernier diplome",
    },
  ];

  // Set type as URL or null
  /**
   * @type {[URL | null, Function]}
   */
  const [urlAnnuaire, setUrlAnnuaire] = useState(null);
  const usersQuery = useFetchGetPagination(urlAnnuaire, props.auth.token);

  useEffect(() => {
    let url = new URL(
      `${process.env.REACT_APP_BASE_URL_API}/users/annuaire/alumni`
    );
    setUrlAnnuaire(url);
  }, []);

  const orderAlumni = (orderBy) => {
    let newUrl = new URL(
      `${process.env.REACT_APP_BASE_URL_API}/users/annuaire/alumni`
    );
    newUrl.search = urlAnnuaire.search;
    newUrl.searchParams.delete("orderCompanies");
    newUrl.searchParams.delete("orderDiplomes");

    orderBy === "Entreprise" &&
      newUrl.searchParams.set("orderCompanies", "asc");
    orderBy === "Dernier diplome" &&
      newUrl.searchParams.set("orderDiplomes", "desc");

    setUrlAnnuaire(newUrl);
  };
  return (
    <div>
      <Header>
        {props.auth.isConnected ? (
          <NavBarTab
            select="annuaire"
            activeIndex={props.items.activeItemAnnuaire}
            items={props.items}
          />
        ) : (
          <ButtonsBarDisconnected />
        )}
      </Header>

      <div className="article_offre" id="alumni">
        <div className="annuaire__container">
          <div className="annuaire__title">
            <h1 className="h1_trait_dessus">
              {t("academicYearbook.alumniTitle")}
            </h1>
            {usersQuery?.data?.length > 0 ? (
              //TODO Solution temporaire au ticket #30404
              //! Si aucune recherche n'est effectuée, on n'affiche pas le nombre total d'alumni
              //! Par défaut, la recherche est effectuée avec 3 paramètres (page, role, datasVisibleForAllMembers)
              //! Donc si le nombre de paramètres est supérieur à 3, on affiche le nombre total d'alumni correspondant à la recherche
              urlAnnuaire?.searchParams?.size > 3 && (
                <p>
                  {usersQuery?.totalItems} {t("academicYearbook.alumniNumber")}
                </p>
              )
            ) : (
              <p>{t("academicYearbook.alumniNoneProfile")}</p>
            )}
          </div>

          <div className="annuaire_filtre">
            <div className="annuaire_filtre__label">
              <Dropdown
                value={triAlumnis}
                options={triAlumnisData}
                onChange={(e) => {
                  setTriAlumnis(e.value);
                  orderAlumni(e.value);
                }}
                placeholder={t("academicYearbook.alumniTri")}
              />
            </div>
          </div>
        </div>
        <>
          <AnnuaireAlumniSearchBar setUrlAnnuaire={setUrlAnnuaire} />
          <Accordion
            expandIcon="pi pi-sliders-h"
            collapseIcon="pi pi-sliders-h"
          >
            <AccordionTab header="Fitres de recherche">
              <AnnuaireAlumniSearchBar setUrlAnnuaire={setUrlAnnuaire} />
            </AccordionTab>
          </Accordion>
        </>

        <div
          className="annuaire__container__cards alumni"
          ref={annuaireContainerRef}
        >
          {usersQuery.loaded ? (
            usersQuery?.data?.length > 0 ? (
              usersQuery?.data?.map((alumni, index) => (
                <EtudiantCard {...alumni} key={index} />
              ))
            ) : (
              <div
                // Style to center element
                style={{
                  position: "absolute",
                  left: "50%",
                  transform: "translate(-50%, 0)",
                }}
              >
                {t("academicYearbook.alumniError")}
              </div>
            )
          ) : (
            <Loader></Loader>
          )}
        </div>
        <TempPagination
          result={usersQuery}
          refItemParent={annuaireContainerRef}
          rows={50}
        ></TempPagination>
      </div>
    </div>
  );
};

AnnuaireAlumniContainer.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.object,
  activeIndex: PropTypes.number,
  setActiveIndex: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnuaireAlumniContainer);
