/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import "./Competences.scss";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FaCheck, FaPen, FaTrash } from "react-icons/fa";
import BtnEcouteMarche from "Components/Boutons/BtnEcouteMarche/BtnEcouteMarche";
import { InputText } from "primereact/inputtext";
import BtnBlancLeft from "Components/Boutons/BtnBlancLeft/btn-blanc-left";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import NavBarTab from "Components/NavBar/NavBarTab/NavBarTab";
import BtnBleuLeft from "Components/Boutons/BtnBleuLeft/btn-bleu-left";
import axios from "axios";
import { Toast } from "primereact/toast";
import { updateAuth, updateCompletionLevel } from "Redux/Actions/authActions";
import BtnRetour from "Components/Boutons/BtnRetour/btn-retour";
import { useTranslation } from "react-i18next";

const Competences = (props) => {
  const [currentUser, setCurrentUser] = useState(props.auth.userConnected);
  const successUpdate = useRef(null);
  const [countForm, setCountForm] = useState(1);
  const [validated, setValidated] = useState(false);
  const [competences, setCompetences] = useState([
    { value: "", numCompetence: 0 },
  ]);
  const { t } = useTranslation("common");

  const [modified, setModified] = useState(false);

  const convertObjectNumericKeys = () => {
    // If skills is an object, convert it to an array
    if (typeof currentUser.resume.skills === "object")
      return Object.values(currentUser.resume.skills);
    return currentUser.resume.skills;
  };

  useEffect(() => {
    if (currentUser.resume !== null && currentUser.resume?.skills)
      setCompetences(convertObjectNumericKeys());
  }, []);

  const verifyDataInstance = (i) => {
    if (competences[i].skill != "") return true;
    return false;
  };

  const verifyData = () => {
    setValidated(true);
    var verifiedData = true;
    for (let index = 0; index < competences.length; index++) {
      let check = verifyDataInstance(index);
      verifiedData = verifiedData ? check : false;
    }
    return verifiedData;
  };

  const addCompetence = () => {
    if (competences.length == 8) {
      showToast({
        severity: "error",
        summary: "Erreur",
        detail: t("profilEdition.skills.validationMax"),
        life: 5000,
      });
      return;
    }
    setValidated(true);
    var verified = verifyData();
    if (verified == true) {
      const newCompetence = new Object();
      newCompetence.skill = "";
      newCompetence.numCompetence = countForm;
      setCountForm(countForm + 1);
      const newArray = [...competences];
      newArray.push(newCompetence);
      setCompetences(newArray);
      setValidated(false);
    }
  };

  const majCompetence = (e, i) => {
    const newArray = [...competences];
    newArray[i].skill = e.target.value;
    setCompetences(newArray);
    verifyData(i);
  };

  const supprCompetence = (i) => {
    const newArray = [...competences];
    newArray.splice(i, 1);
    setCompetences(newArray);
  };

  const Valider = () => {
    setValidated(true);
    if (competences.length == 0) {
      showToast({
        severity: "error",
        summary: "Erreur",
        detail: "Au moins une compétence est requise",
        life: 5000,
      });
      return;
    }
    if (verifyData()) {
      updateSkills();
      setModified(false);
    }
  };

  const updateSkills = () => {
    //? Remove id de l'objet props.user et modification l'objet du CV
    const skillsArray = {
      skills: [...competences],
    };
    const cVId = props.auth.userConnected.resume.id;
    axios
      .put(`${process.env.REACT_APP_BASE_URL_API}/cvs/${cVId}`, skillsArray, {
        headers: props.auth.token
          ? {
              accept: "application/json",
              "Content-Type": "application/json",

              Authorization: `Bearer ${props.auth.token}`,
            }
          : {
              accept: "application/json",
              "Content-Type": "application/json",
            },
      })
      .then((res) => {
        if (res.status === 200) {
          showToast({
            severity: "success",
            summary: "Succès",
            detail: "Vos compétences ont bien été modifiées",
            life: 5000,
          });
          axios
            .get(
              `${process.env.REACT_APP_BASE_URL_API}/users/${props.auth.userConnected.id}/profile_completion_rate`,
              {
                headers: props.auth.token
                  ? {
                      accept: "application/json",
                      "Content-Type": "application/json",

                      Authorization: `Bearer ${props.auth.token}`,
                    }
                  : {
                      accept: "application/json",
                      "Content-Type": "application/json",
                    },
              }
            )
            .then((res) => props.handleUpdateCompletionLevel(res.data))
            .catch((error) => console.log(error));

          props.handleUpdateAuth({
            userConnected: {
              ...props.auth.userConnected,
              resume: {
                ...props.auth.userConnected.resume,
                skills: res.data.skills,
              },
            },
          });
          setModified(!modified);
        }
      })
      .catch((err) => console.log(err))
      .finally((fin) => console.log(fin));
  };

  const showToast = (message) => {
    successUpdate.current.show({
      severity: message.severity,
      summary: message.summary,
      detail: message.detail,
      life: message.life,
    });
  };

  return (
    <div>
      <Toast ref={successUpdate} />
      {props.auth.isConnected && (
        <div className="article_actu">
          <NavBarTab
            id="desktop"
            select="profiledit"
            activeIndex={props.activeIndex}
            items={props.items}
          />
          <BtnEcouteMarche items={props.items} />

          <div className="titre_de_page">
            <h1 className="h1_trait_dessus">
              {t("profilEdition.skills.header")}
            </h1>
            <BtnRetour />
          </div>
          <div className="form-demo">
            {/* <div className="flex justify-content-center"> */}
            <div className="card">
              <div className="competences" key={modified}>
                {competences.map((item, indexCompetences) =>
                  modified ? (
                    <div key={item}>
                      <div className="form_bouton_suppr">
                        <h1>
                          {t("profilEdition.skills.name")}{" "}
                          {indexCompetences + 1}
                        </h1>
                        <BtnBlanc
                          btnAction={() => {
                            supprCompetence(indexCompetences);
                          }}
                        >
                          <FaTrash />
                        </BtnBlanc>
                      </div>
                      <div className="form_columns">
                        <div className="field">
                          <InputText
                            id="competence"
                            placeholder={t("profilEdition.skills.placeholder")}
                            key={indexCompetences}
                            value={item.skill}
                            onChange={(e) => majCompetence(e, indexCompetences)}
                          />
                          {item.skill == "" && validated && (
                            <label className="validation">
                              {t("profilEdition.skills.validation")}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : competences.length == 0 ? (
                    t("profilEdition.skills.none")
                  ) : competences[0].skill == "" ? (
                    t("profilEdition.skills.none")
                  ) : (
                    <div className="competence" key={indexCompetences}>
                      <h4>{competences[indexCompetences].skill}</h4>
                    </div>
                  )
                )}
              </div>
              {modified && competences.length < 8 && (
                <div className="form_bouton_add">
                  <i
                    className="pi pi-plus-circle"
                    onClick={() => addCompetence()}
                    size="2rem"
                  />
                  <label onClick={() => addCompetence()}>
                    {t("profilEdition.skills.add")}
                  </label>
                </div>
              )}

              <div className="form_boutons">
                {modified ? (
                  <BtnBleuLeft
                    btnTexte={t("profilEdition.validate")}
                    btnAction={Valider}
                  >
                    <FaCheck />
                  </BtnBleuLeft>
                ) : (
                  <BtnBlancLeft
                    btnTexte={t("profilEdition.edit")}
                    btnAction={() => setModified(true)}
                  >
                    <FaPen />
                  </BtnBlancLeft>
                )}
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

Competences.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.array,
  activeIndex: PropTypes.number,
  handleUpdateAuth: PropTypes.func,
  handleUpdateCompletionLevel: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateAuth: (value) => {
    dispatch(updateAuth(value));
  },
  handleUpdateCompletionLevel: (value) => {
    dispatch(updateCompletionLevel(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Competences);
