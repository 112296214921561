import React, { useEffect, useRef, useState } from "react";
import "./GlobalSearch.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { VscSearch } from "react-icons/vsc";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import { Toast } from "primereact/toast";

const GlobalSearch = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [visibleInput, setVisibleInput] = useState(false);
  const { t } = useTranslation("common");

  const [error, setError] = useState(false);

  const searchRef = useRef(null);

  useEffect(() => {
    if (error) {
      searchRef.current.show({
        severity: "error",
        summary: t("header.global_search_error"),
        life: 3000,
      });
    }
  }, [error]);

  return (
    <div className="global_search">
      <Toast ref={searchRef} />
      <div
        className={
          visibleInput ? "global_search_input active" : "global_search_input"
        }
      >
        <InputText
          placeholder={t("header.global_search")}
          value={search}
          className="membership_input_text"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              if (search.length > 2) {
                navigate("/search", {
                  state: {
                    inputSearch: search,
                  },
                });
                setError(false);
              } else setError(true);
            }
          }}
          onChange={(e) => {
            setSearch(e.target.value.toLowerCase());
          }}
        />
      </div>
      <button
        className="header__bandeau__userConnected__home__search"
        onClick={() => setVisibleInput(!visibleInput)}
      >
        <VscSearch />
      </button>
    </div>
  );
};

GlobalSearch.propTypes = {
  auth: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearch);
