import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Actualite from "Components/Actualites/Actualite/Actualite";
import Loader from "Components/Loader/loader";
import { connect } from "react-redux";
import { updateEvents } from "Redux/Actions/eventsActions";
import { updateNews } from "Redux/Actions/newsActions";
import Evenement from "Components/Evenements/EvenementCard/EvenementCard";
import { formatDateFrenchLocale, formatDateLocale } from "Services/functions";
import { Link } from "react-router-dom";
import { useFetchGet } from "Services/api";

/**
 * Permet d'afficher un événement, une actualité et un conseil (ou une deuxième actualité s'il n'y a pas de conseil)
 * choisis de manière aléatoire dans les événements et actualités en enlevant de la liste l'événement ou l'actu de la page sur laquelle on est
 * Utilisé dans actu-detail et event-detail
 */
const EventActuConseilRandom = (props) => {
  // événement aléatoire
  let event = props.events.randomEvent;

  const news = useFetchGet("/news", props.auth.token, {
    state:
      props.states?.statesData?.find((state) => state.statusLabel === "Publié")
        ?.id || 4,
  });
  const events = useFetchGet("/events", props.auth.token, {
    state:
      props.states?.statesData?.find((state) => state.statusLabel === "Publié")
        ?.id || 4,
  });

  /**
   * Fonction qui retourne un objet aléatoire d'un tableau
   * @param {[{}]} array - tableau d'objets
   * @param {{}} existingItem - objet à ne pas retourner (si indiqué)
   * @returns un objet aléatoire du tableau
   */
  function getRandomItem(array, existingItem = null) {
    //? si le tableau est vide ou null, on retourne null
    if (!array || array.length === 0) return null;
    //? si le tableau ne contient qu'un élément, on retourne cet élément
    if (array.length === 1) return array[0];

    let randomItem = array[Math.floor(Math.random() * array.length)];
    if (randomItem.id === existingItem?.id) {
      getRandomItem(array, existingItem);
    }
    return randomItem;
  }

  useEffect(() => {
    events.loaded &&
      props.event &&
      props.handleUpdateEvents({
        randomEvent: getRandomItem(events.data, props.event),
      });
    events.loaded &&
      !props.event &&
      props.handleUpdateEvents({
        randomEvent: getRandomItem(events.data),
      });
  }, [events.loaded]);

  // actualité aléatoire
  useEffect(() => {
    news.loaded &&
      props.new &&
      props.handleUpdateActus({
        randomNews: getRandomItem(news.data, props.new),
      });
    news.loaded &&
      !props.new &&
      props.handleUpdateActus({
        randomNews: getRandomItem(news.data),
      });
  }, [news.loaded]);

  // conseil alétoire
  useEffect(() => {
    news.loaded &&
      news.data.filter((news) => news.category?.label === "Conseil").length >
        0 &&
      props.handleUpdateActus({
        randomConseil: getRandomItem(
          news.data.filter((news) => news.category?.label === "Conseil"),
          props.new
        ),
      });
    news.loaded &&
      news.data.filter((news) => news.category?.label === "Conseil").length <=
        0 &&
      props.handleUpdateActus({
        randomConseil: getRandomItem(news.data, props.new),
      });
  }, [news.loaded]);

  return (
    <div className="evenement_detail_container__bottom__event">
      {props.events.randomEvent &&
      // props.events.randomEvent.length > 0 &&
      props.events.randomEvent.id ? (
        <Link
          to={{
            pathname: `/event-detail/${props.events.randomEvent.id}`,
            params: props.events.randomEvent.id,
          }}
          reloadDocument={true}
        >
          <Evenement
            categorie={event.category}
            filter={event.label}
            debutDate={
              (props.i18n.language === "en" ? "From " : "Du ") +
              formatDateLocale(
                props.events.randomEvent.startingAt,
                props.i18n.language
              )
            }
            finDate={
              (props.i18n.language === "en" ? "to " : "au ") +
              formatDateLocale(
                props.events.randomEvent.endingAt,
                props.i18n.language
              )
            }
            inscription={event.fulfilled}
            title={event.title}
            chapeau={props.events.randomEvent.description}
            eventDetails={event}
            registerEnd={event.registerEnd}
            url={event?.imageUrl ? event.imageUrl : "event1"}
          />
        </Link>
      ) : (
        <Loader />
      )}
      {props.news.randomNews && props.news.randomNews.id ? (
        <Link
          to={{
            pathname: `/actu-detail/${props.news.randomNews.id}`,
            params: props.news.randomNews.id,
          }}
          reloadDocument={true}
        >
          <Actualite
            // key={actualite.id}
            title={props.news.randomNews.title}
            chapeau={props.news.randomNews.chapo}
            publicationDate={formatDateFrenchLocale(
              props.news?.randomNews?.publishedAt
            )}
            url={
              props.news.randomNews.imageUrl
                ? props.news.randomNews.imageUrl
                : "img1"
            }
            newsDetails={props.news.randomNews}
            categorie={props.news.randomNews.category?.label}
            random={true}
          />
        </Link>
      ) : (
        <Loader />
      )}

      {props.news.randomConseil && props.news.randomConseil.id ? (
        <Link
          to={{
            pathname: `/actu-detail/${props.news.randomConseil.id}`,
            params: props.news.randomConseil.id,
          }}
          reloadDocument={true}
        >
          <Actualite
            // key={actualite.id}
            title={props.news.randomConseil.title}
            chapeau={props.news.randomConseil.chapo}
            publicationDate={formatDateFrenchLocale(
              props.news?.randomConseil?.publishedAt
            )}
            url={
              props.news.randomConseil.imageUrl
                ? props.news.randomConseil.imageUrl
                : "img1"
            }
            newsDetails={props.news.randomConseil}
            categorie={props.news.randomConseil.category?.label}
            random={true}
          />
        </Link>
      ) : (
        <Loader />
      )}
    </div>
  );
};
EventActuConseilRandom.propTypes = {
  handleUpdateEvents: PropTypes.func,
  events: PropTypes.object,
  handleUpdateActus: PropTypes.func,
  news: PropTypes.object,
  event: PropTypes.object,
  new: PropTypes.object,
  auth: PropTypes.object,
  i18n: PropTypes.object,
  states: PropTypes.object,
};

const mapStateToProps = (state) => ({
  events: state.events,
  news: state.news,
  auth: state.auth,
  i18n: state.i18n,
  states: state.states,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateEvents: (value) => {
    dispatch(updateEvents(value));
  },
  handleUpdateActus: (value) => {
    dispatch(updateNews(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventActuConseilRandom);
