import { Months, Companies, Utils } from "./Const";

export function getNewUsersByMonth() {
  return {
    datasets: [
      {
        data: Utils.generateData(12, 30, 300),
        backgroundColor: ["#168aad"],
        hoverBackgroundColor: ["#0E3C4E"],
        label: "Nombre de nouveaux inscrits",
      },
    ],
    labels: Months.all,
  };
}

export function getNewUsersByMonthOptions() {
  return {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      datalabels: {
        display: true,
        color: "#FDFDFD",
      },
      legend: {
        labels: {
          color: "#495057",
        },
      },
      title: Utils.generateTitle(
        "Nombre de nouveaux inscrits à Réseau Pro par mois"
      ),
    },
  };
}

export function getNewUsersByMonthAndChart() {
  return {
    datasets: [
      {
        type: "bar",
        data: Utils.generateData(12, 120, 500),
        backgroundColor: ["#44AC8D"],
        hoverBackgroundColor: ["#388E74"],
        label: "Inscrits ayant acceptés la charte",
      },
      {
        type: "bar",
        data: Utils.generateData(12, 10, 120),
        backgroundColor: ["#C64A5B"],
        hoverBackgroundColor: ["#CE293F"],
        label: "Inscrits ayant refusés la charte",
      },
    ],
    labels: Months.all,
  };
}

export function getNewUsersByMonthAndChartOptions() {
  return {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      tooltips: {
        mode: "index",
        intersect: false,
      },
      datalabels: {
        display: true,
        color: "#FDFDFD",
      },
      legend: {
        labels: {
          color: "#495057",
        },
      },
      title: Utils.generateTitle(
        "Acceptation de la charte RéseauPro des nouveaux inscrits par mois"
      ),
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
        y: {
          stacked: true,
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
      },
    },
  };
}

export function getTotalUsersAndAlumnis() {
  return {
    datasets: [
      {
        data: [520, 219],
        backgroundColor: ["#05668d", "#02c39a"],
        hoverBackgroundColor: ["#0E3C4E", "#02765D"],
      },
    ],
    labels: ["Nombre d'étudiants", "Nombre d'alumnis"],
  };
}

export function getTotalUsersAndAlumnisOptions() {
  return {
    plugins: {
      datalabels: {
        formatter: (value, ctx) => Utils.calculatePercentage(value, ctx, true),
        display: true,
        color: "#FDFDFD",
      },
      legend: {
        labels: {
          color: "#495057",
        },
      },
      title: Utils.generateTitle(
        "Nombre d'étudiants et d'alumnis pour l'université A"
      ),
    },
  };
}

export function getUsersRegardingChart() {
  return {
    datasets: [
      {
        data: [956, 102],
        backgroundColor: ["#44AC8D", "#C64A5B"],
        hoverBackgroundColor: ["#388E74", "#CE293F"],
      },
    ],
    labels: ["Validé", "Refusé"],
  };
}

export function getUsersRegardingChartOptions() {
  return {
    plugins: {
      datalabels: {
        formatter: (value, ctx) => Utils.calculatePercentage(value, ctx, true),
        display: true,
        color: "#FDFDFD",
      },
      legend: {
        labels: {
          color: "#495057",
        },
      },
      title: Utils.generateTitle(
        "Utilisateurs ayant validé ou refusé la charte"
      ),
    },
  };
}

export function getUsersRegardingChartMonthly() {
  return {
    datasets: [
      {
        data: Utils.generateData(12, 200, 1200),
        backgroundColor: Companies.firstFiveColors,
      },
      {},
    ],
    labels: Months.all,
  };
}

export function getUsersWhoApplied() {
  return {
    datasets: [
      {
        data: [60, 30, 90, 65, 44],
        backgroundColor: Companies.firstFiveColors,
      },
    ],
    labels: Companies.firstFiveUni,
  };
}

export function getUsersWhoAppliedOptions() {
  return {
    plugins: {
      datalabels: {
        formatter: (value, ctx) => Utils.calculatePercentage(value, ctx),
        display: true,
        color: "#FDFDFD",
      },
      legend: {
        labels: {
          color: "#495057",
        },
      },
      title: Utils.generateTitle(
        "Nombre d'étudiants ayant candidaté par université"
      ),
    },
  };
}
