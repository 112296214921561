import {
  UPDATE_AUTH,
  UPDATE_EDUCATIONS,
  UPDATE_EXPERIENCE,
  UPDATE_BIRTHDAY,
  UPDATE_COMPLETION_LEVEL,
} from "../Actions/authActions.js";
import { formatDate } from "Services/functions.js";

const INITIAL_STATE = {
  isConnected: false,
  token: null,
  userConnected: null,
  universityConnected: null,
  mode: "light",
  isAdmin: false,
  isAlumni: false,
  isPersonnel: false,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_AUTH: {
      return {
        ...state,
        ...action.value,
      };
    }
    case UPDATE_EDUCATIONS: {
      return {
        ...state,
        userConnected: {
          ...state.userConnected,
          resume: {
            ...state.userConnected.resume,
            educations: { ...action.value },
          },
        },
      };
    }
    case UPDATE_EXPERIENCE: {
      return {
        ...state,
        userConnected: {
          ...state.userConnected,
          resume: {
            ...state.userConnected.resume,
            experiences: { ...action.value },
          },
        },
      };
    }
    case UPDATE_BIRTHDAY: {
      return {
        ...state,
        userConnected: {
          ...state.userConnected,
          birthday: formatDate(action.value),
        },
      };
    }
    case UPDATE_COMPLETION_LEVEL: {
      return {
        ...state,
        userConnected: {
          ...state.userConnected,
          profileCompletionRate: action.value,
        },
      };
    }
    default:
      return state;
  }
};

export default authReducer;
