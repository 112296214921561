import React, { useEffect, useState } from "react";
import "./Search.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  formatDate,
  formatDateDatabase,
  formatDateFrenchLocale,
} from "Services/functions";
import { useFetchGetPagination } from "Services/api";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import Offre from "Components/Offres/Offre/Offre";
import EvenementCard from "Components/Evenements/EvenementCard/EvenementCard";
import Actualite from "Components/Actualites/Actualite/Actualite";
import HeaderUserLogin from "Components/Header/HeaderTop/HeaderUserLogin/HeaderUserLogin";
import { useTranslation } from "react-i18next";
import { authenticationRedirection } from "Services/redirections";

const Search = (props) => {
  const location = useLocation();
  const inputSearch = location.state.inputSearch;
  const [loginVisible, setLoginVisible] = useState(false);

  const [urlForOffers, setUrlForOffers] = useState("");
  const [urlForEvents, setUrlForEvents] = useState("");
  const [urlForNews, setUrlForNews] = useState("");

  const offersQuery = useFetchGetPagination(urlForOffers);
  const eventsQuery = useFetchGetPagination(urlForEvents);
  const newsQuery = useFetchGetPagination(urlForNews);

  const { t } = useTranslation("common");

  useEffect(() => {
    if (props.states.statesData.length > 0) {
      // Création des URL
      let urlOffers = new URL(`${process.env.REACT_APP_BASE_URL_API}/offers`);
      let urlEvents = new URL(`${process.env.REACT_APP_BASE_URL_API}/events`);
      let urlNews = new URL(`${process.env.REACT_APP_BASE_URL_API}/news`);

      // Ajout des paramètres de recherche pour les offres
      urlOffers.searchParams.set(
        "state",
        props.states.statesData.filter(
          (etat) => etat.statusLabel === "Publié"
        )[0].id
      );
      urlOffers.searchParams.set("order[modifiedAt]", "desc");
      urlOffers.searchParams.set("keyword", inputSearch);

      // Ajout des paramètres de recherche pour les events
      urlEvents.searchParams.set(
        "state",
        props.states.statesData.filter(
          (etat) => etat.statusLabel === "Publié"
        )[0].id
      );
      urlEvents.searchParams.set("order[startingAt]", "asc");
      urlEvents.searchParams.set(
        "endingAt[after]",
        formatDateDatabase(new Date())
      );
      urlEvents.searchParams.set("keyword", inputSearch);

      // Ajout des paramètres de recherche pour les actus
      urlNews.searchParams.set(
        "state",
        props.states.statesData.filter(
          (etat) => etat.statusLabel === "Publié"
        )[0].id
      );
      urlNews.searchParams.set("order[modifiedAt]", "desc");
      urlNews.searchParams.set("keyword", inputSearch);

      setUrlForOffers(urlOffers);
      setUrlForEvents(urlEvents);
      setUrlForNews(urlNews);
    }
  }, [props.states.statesData.length, inputSearch]);

  return (
    <div>
      <Header noImage={true}></Header>
      {offersQuery?.loaded &&
      eventsQuery?.loaded &&
      newsQuery?.loaded &&
      offersQuery?.data?.length === 0 &&
      eventsQuery?.data?.length === 0 &&
      newsQuery?.data?.length === 0 ? (
        <div className="search_global_empty">
          {t("header.global_search_empty") + " '" + inputSearch + "'"}
        </div>
      ) : (
        <div className="search_global">
          {offersQuery?.data?.length > 0 && (
            <div className="search_offer_container">
              <h1>{"'" + inputSearch + "' parmi les offres :"}</h1>
              <div className="search_offer_container_body">
                {offersQuery.data.slice(0, 20).map((offre, index) =>
                  props.auth.isConnected ? (
                    <Offre
                      categorie={index}
                      url={offre.imageUrl ? offre.imageUrl : "img1"}
                      offreDetails={offre}
                      date={formatDate(offre.postedAt, ".")}
                      logoEntreprise={index}
                      employes="200 - 320"
                      accessibleForDisabled={offre.accessibleForDisabled}
                      key={offre.id}
                    />
                  ) : (
                    <Offre
                      key={offre.id}
                      categorie={index}
                      url={offre.imageUrl ? offre.imageUrl : "img1"}
                      offreDetails={offre}
                      date={formatDate(offre.postedAt, ".")}
                      logoEntreprise={index}
                      employes="200 - 320"
                      accessibleForDisabled={offre.accessibleForDisabled}
                      onClick={() => {
                        setLoginVisible(true);
                      }}
                    />
                  )
                )}
              </div>
            </div>
          )}
          {eventsQuery?.data?.length > 0 && (
            <div className="search_event_container">
              <h1>{"'" + inputSearch + "' parmi les événements :"}</h1>
              <div className="search_event_container_body">
                {eventsQuery.data.slice(0, 20).map((event, index) =>
                  props.auth.userConnected || event.public ? (
                    <Link
                      to={{
                        pathname: `/event-detail/${event.id}`,
                        params: event.id,
                      }}
                      key={index}
                    >
                      <EvenementCard
                        key={index}
                        categorie={event.category}
                        inscription={event.fulfilled}
                        debutDate={
                          "Du " + formatDateFrenchLocale(event.startingAt)
                        }
                        finDate={"Au " + formatDateFrenchLocale(event.endingAt)}
                        title={event.title}
                        chapeau={event.description}
                        eventDetails={event}
                        url={event?.imageUrl ? event.imageUrl : "event1"}
                        registerEnd={event.registerEnd}
                      />
                    </Link>
                  ) : (
                    <EvenementCard
                      key={index}
                      categorie={event.category}
                      inscription={event.fulfilled}
                      debutDate={
                        "Du " + formatDateFrenchLocale(event.startingAt)
                      }
                      finDate={"Au " + formatDateFrenchLocale(event.endingAt)}
                      title={event.title}
                      chapeau={event.description}
                      eventDetails={event}
                      url={event?.imageUrl ? event.imageUrl : "event1"}
                      onClick={() => {
                        if (!authenticationRedirection()) setLoginVisible(true);
                      }}
                      registerEnd={event.registerEnd}
                    />
                  )
                )}
              </div>
            </div>
          )}
          {newsQuery?.data?.length > 0 && (
            <div className="search_news_container">
              <h1>{"'" + inputSearch + "' parmi les actualités :"}</h1>
              <div className="search_news_container_body">
                {newsQuery.data.slice(0, 20).map((actualite, index) =>
                  props.auth.userConnected || actualite.public ? (
                    <Link
                      to={{
                        pathname: `/actu-detail/${actualite.id}`,
                        params: actualite.id,
                      }}
                      key={index}
                    >
                      <Actualite
                        key={actualite.id}
                        title={actualite.title}
                        chapeau={actualite.chapo}
                        url={actualite.imageUrl ? actualite.imageUrl : "img1"}
                        publicationDate={formatDateFrenchLocale(
                          actualite?.publishedAt
                        )}
                        categorie={actualite.category.label}
                        newsDetails={actualite}
                      />
                    </Link>
                  ) : (
                    <Actualite
                      key={actualite.id}
                      title={actualite.title}
                      chapeau={actualite.chapo}
                      url={actualite.imageUrl ? actualite.imageUrl : "img1"}
                      publicationDate={formatDateFrenchLocale(
                        actualite?.publishedAt
                      )}
                      categorie={actualite.category.label}
                      newsDetails={actualite}
                      onClick={() => {
                        if (!authenticationRedirection()) setLoginVisible(true);
                      }}
                    />
                  )
                )}
              </div>
            </div>
          )}
        </div>
      )}
      {loginVisible && (
        <HeaderUserLogin
          key={loginVisible}
          loginVisible={loginVisible}
          setLoginVisible={setLoginVisible}
          redirect={null}
        />
      )}
      <Footer />
    </div>
  );
};

Search.propTypes = {
  auth: PropTypes.object,
  states: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  states: state.states,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
