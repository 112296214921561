import React, { useRef, useState } from "react";
import "./EvenementManagementCard.scss";
import PropTypes from "prop-types";
import { Menu } from "primereact/menu";
import BtnRond from "Components/Boutons/BtnRond/btn-rond";
import { FaEllipsisH } from "react-icons/fa";
import event2 from "assets/Event2.webp";
import { connect } from "react-redux";
import Modal from "Components/Modal/Modal";
import { InputTextarea } from "primereact/inputtextarea";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import { updateEvents } from "Redux/Actions/eventsActions";
import { truncate } from "Services/functions";
import axios from "axios";
import EvenementsCreation from "Components/Evenements/EvenementsCreation/EvenementsCreation";
import { Link } from "react-router-dom";
const EvenementManagementCard = (props) => {
  const menu = useRef(null);
  const [openRefusInterface, setOpenRefusInterface] = useState(false);
  const [openModifyInterface, setOpenModifyInterface] = useState(false);
  const [refus, setRefus] = useState("");
  const [errorRefus, setErrorRefus] = useState(false);
  const [openMotifRefusInterface, setOpenMotifRefusInterface] = useState(false);

  const itemsPublie = [
    {
      label: "Modifier",
      command: () => {
        setOpenModifyInterface(!openModifyInterface);
      },
    },

    { separator: true },

    {
      label: "Clôturer",
      command: () => {
        modifyCard("close");
      },
    },
    { separator: true },
    {
      label: "Supprimer",
      command: () => {
        deleteEvents();
      },
    },
  ];
  const itemsBrouillon = [
    {
      label: "Modifier",
      command: () => {
        setOpenModifyInterface(!openModifyInterface);
      },
    },
    { separator: true },
    {
      label: "Supprimer",
      command: () => {
        deleteEvents();
      },
    },
  ];
  const itemsAttente = [
    {
      label: "Modifier",
      command: () => {
        setOpenModifyInterface(!openModifyInterface);
      },
    },
    { separator: true },
    {
      label: "Annuler",
      command: () => {
        deleteEvents();
      },
    },
  ];
  const itemsCloture = [
    {
      label: "Supprimer",
      command: () => {
        deleteEvents();
      },
    },
  ];
  const itemsRefuse = [
    {
      label: "Renouveler la demande",
      command: () => {
        setOpenMotifRefusInterface(!openMotifRefusInterface);
      },
    },
  ];
  // uniquement pour le rôle admin
  const itemsRefuseAdmin = [
    {
      label: "Supprimer",
      command: () => {
        deleteEvents();
      },
    },
  ];
  const itemsAttenteAdmin = [
    {
      label: "Accepter",
      command: () => {
        modifyCard("publish");
      },
    },
    { separator: true },
    {
      label: "Refuser",
      command: () => {
        setOpenRefusInterface(true);
      },
    },
  ];
  const Statut = () => {
    switch (props.statut.statusLabel) {
      case "Publié":
        return itemsPublie;
      case "Brouillon":
        return itemsBrouillon;
      case "En Attente":
        switch (props.auth.isAdmin) {
          case true:
            return itemsAttenteAdmin;
          default:
            return itemsAttente;
        }
      case "Cloturé":
        return itemsCloture;
      case "Refusé":
        switch (props.auth.isAdmin) {
          case true:
            return itemsRefuseAdmin;
          default:
            return itemsRefuse;
        }
      default:
        break;
    }
  };
  const modifyCard = (path) => {
    if (!props.transmitterName) return;

    let url = `${process.env.REACT_APP_BASE_URL_API}/events/${props.id}/${path}`;
    if (props.transmitterName === "Recruteur") {
      url += "/recruiter";
    }

    let data = {};
    if (path === "refuse") data = { feedback: refus };
    axios
      .post(url, data, {
        headers: props.auth.token
          ? {
              accept: "application/json",
              Authorization: `Bearer ${props.auth.token}`,
            }
          : {
              accept: "application/json",
            },
      })
      .then((response) => {
        let tempVar = props.events.dataFromPagination;
        tempVar.data = tempVar.data.map((event) => {
          if (event.id === response.data.id) {
            return response.data;
          } else {
            return event;
          }
        });
        props.handleUpdateEvents({
          dataFromPagination: tempVar,
        });
      });
  };

  const deleteEvents = () => {
    if (!props.transmitterName) return;

    let url = `${process.env.REACT_APP_BASE_URL_API}/events/${props.id}`;
    if (props.transmitterName === "Recruteur") {
      url += "/recruiter";
    }

    axios
      .delete(url, {
        headers: props.auth.token
          ? {
              accept: "application/json",
              Authorization: `Bearer ${props.auth.token}`,
            }
          : {
              accept: "application/json",
            },
      })
      .then(() => {
        let tempVar = props.events.dataFromPagination;
        tempVar.data = tempVar.data.filter((event) => event.id !== props.id);
        props.handleUpdateEvents({
          dataFromPagination: tempVar,
        });
      });
  };
  return (
    <div className="gestion_evenements_container">
      <div className="gestion_evenements_container__left">
        <Link
          to={{
            pathname: `/event-detail/${props.id}`,
            params: props.id,
          }}
          className="logo__card__gestion"
        >
          {props.url == "event2" ? (
            <>
              <img src={event2} alt="Fond événement" />
              <div
                className="image__bg"
                style={{ backgroundImage: `url(${event2})` }}
              ></div>
            </>
          ) : (
            <>
              <img
                src={props.url}
                alt="Fond événement"
                onError={(e) => {
                  e.target.src = event2;
                }}
              />
              <div
                className="image__bg"
                style={{
                  backgroundImage: `url(${props.url})`,
                }}
              ></div>
            </>
          )}
        </Link>

        <div className="groupe_menu mobile">
          <Menu
            appendTo={document.getElementById("app")}
            model={Statut()}
            popup
            ref={menu}
            style={{ textAlign: "center" }}
          />
          <BtnRond btnAction={(event) => menu.current.toggle(event)}>
            <FaEllipsisH size={"1.5rem"} />
          </BtnRond>
        </div>
        <div className="gestion_evenements_container__left__info">
          <Link
            to={{
              pathname: `/event-detail/${props.id}`,
              params: props.id,
            }}
          >
            <h5 className="title">{truncate(props.titre, 27)}</h5>
          </Link>
          <h2></h2>
          <div className="gestion_evenements_container__left__info__bottom">
            <i className="pi pi-calendar"></i>
            {props.dateFin ? (
              <div className="gestion_evenements_container__left__info__bottom__dates">
                <h2>Du : {props.dateDebut}</h2>
                <h2>Au : {props.dateFin}</h2>
              </div>
            ) : (
              <div className="gestion_evenements_container__left__info__bottom__dates">
                <h2>{props.dateDebut}</h2>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="gestion_evenements_container__bouton">
        <div className="groupe_menu desktop">
          <Menu
            appendTo={document.getElementById("app")}
            model={Statut()}
            popup
            ref={menu}
            style={{ textAlign: "center" }}
          />
          <BtnRond btnAction={(event) => menu.current.toggle(event)}>
            <FaEllipsisH size={"1.5rem"} />
          </BtnRond>
        </div>
        {props.statut.statusLabel == "Publié" && (
          <span className="publie">Publié</span>
        )}
        {props.statut.statusLabel == "En Attente" && (
          <span className="attente">En attente</span>
        )}
        {props.statut.statusLabel == "Brouillon" && (
          <span className="brouillon">Brouillon</span>
        )}
        {props.statut.statusLabel == "Cloturé" && (
          <span className="cloture">Clôturé</span>
        )}
        {props.statut.statusLabel == "Refusé" && (
          <span className="refuse">Refusé</span>
        )}
      </div>
      {openRefusInterface && (
        <Modal
          visible={openRefusInterface}
          setVisible={setOpenRefusInterface}
          header={`Justifiez votre refus de l'événement`}
        >
          <div className="modal_motif_refus">
            <InputTextarea
              className="modal_refus"
              rows={6}
              placeholder="Justifiez votre refus"
              value={refus}
              onChange={(e) => {
                setRefus(e.target.value);
              }}
            />
            {errorRefus && (
              <span className="error_refus_length">
                Le motif de refus doit faire au moins 10 caractères
              </span>
            )}
            <BtnBleu
              btnTexte="Envoyer"
              btnAction={() => {
                if (refus.length > 9) {
                  setErrorRefus(false);
                  setRefus("");
                  setOpenRefusInterface(false);
                  modifyCard("refuse");
                } else setErrorRefus(true);
              }}
            />
          </div>
        </Modal>
      )}
      {openMotifRefusInterface && (
        <Modal
          visible={openMotifRefusInterface}
          setVisible={setOpenMotifRefusInterface}
          header={`Motif du refus`}
        >
          <div className="motif_refus_interface">
            <div className="motif_refus_interface_message">
              <span className="motif_refus_interface_message_title">
                Motif du refus :
              </span>
              <span className="motif_refus_interface_message_text">
                {props.feedback
                  ? props.feedback
                  : " Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim maiores vero totam exercitationem at asperiores quibusdam eligendi labore fuga, porro tempora dolor nihil repellat error aut distinctio et earum molestiae accusamus rem."}
              </span>
            </div>
            <div className="motif_refus_interface_bouton">
              <BtnBleu
                btnTexte="Renouveler la demande"
                btnAction={() => {
                  setOpenModifyInterface(true);
                  setOpenMotifRefusInterface(false);
                }}
              ></BtnBleu>
            </div>
          </div>
        </Modal>
      )}
      {openModifyInterface && (
        <Modal
          visible={openModifyInterface}
          setVisible={setOpenModifyInterface}
          header={`Modifiez votre événement`}
        >
          <EvenementsCreation
            activeIndex={props.items.itemsEvenementAdmin.findIndex(
              (v) => v.label == "Créer un événement"
            )}
            items={props.items.itemsEvenementAdmin}
            value={props.events.dataFromPagination.data.find(
              (e) => e.id === props.id
            )}
            setVisible={setOpenModifyInterface}
          ></EvenementsCreation>
        </Modal>
      )}
    </div>
  );
};

EvenementManagementCard.propTypes = {
  titre: PropTypes.string,
  dateDebut: PropTypes.string,
  dateFin: PropTypes.string,
  participants: PropTypes.string,
  statut: PropTypes.string,
  image: PropTypes.string,
  auth: PropTypes.object,
  events: PropTypes.object,
  handleUpdateEvents: PropTypes.func,
  id: PropTypes.number,
  event: PropTypes.object,
  items: PropTypes.object,
  url: PropTypes.string,
  feedback: PropTypes.string,
  transmitterName: PropTypes.string,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  events: state.events,
  items: state.items,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateEvents: (value) => {
    dispatch(updateEvents(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EvenementManagementCard);
