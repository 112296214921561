import React, { useRef } from "react";
import "./ButtonsBarConnected.scss";
import BtnRond from "../..//Boutons/BtnRond/btn-rond";
import {
  FaMapMarkerAlt,
  FaUsers,
  FaCalendarPlus,
  FaPollH,
  FaSearch,
  // FaRegCalendarCheck,
  FaEllipsisH,
  FaUserCog,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateMenu } from "Redux/Actions/menuActions";
import { Menu } from "primereact/menu";
import { useTranslation } from "react-i18next";

const ButtonsBarConnected = (props) => {
  const { t } = useTranslation("common");
  const menuMobileConnected = useRef(null);
  let itemsMobileConnected = [
    {
      label: "Déposer une offre",
      command: () => {
        if (window.location.pathname != "/offres") {
          window.location.pathname = "/offres";
        }
        props.handleMenu({
          activeItemOffre: props.items.itemsOffre.findIndex(
            (v) => v.label == "Déposer une offre"
          ),
        });
      },
    },
    {
      label: "Mes groupes d'échanges",
      command: () => {
        if (window.location.pathname != "/groupes") {
          window.location.pathname = "/groupes";
        }
        props.handleMenu({
          activeItemGroupes: props.items.itemsGroupes.findIndex(
            (v) => v.label == "Mes groupes d'échanges"
          ),
        });
      },
    },
    {
      label: "Les événements à venir",
      command: () => {
        if (window.location.pathname != "/profil") {
          window.location.pathname = "/profil";
        }
        props.handleMenu({
          activeItemProfil: props.items.itemsProfil.findIndex(
            (v) => v.label == "Mes événements à venir"
          ),
        });
      },
    },
  ];
  return (
    <div className="buttonsBarConnected">
      <div className="buttonsBarConnected__bandeau">
        {/* bandeau avec opacité de l'acceuil une fois connecté */}
      </div>
      <div className="buttons">
        <div className="bouton-cache-mobile">
          <Link to="/profil-edit">
            <BtnRond
              btnAction={() =>
                props.handleMenu({
                  activeItemProfilEdit: props.items.itemsProfilEdit.findIndex(
                    (v) => v.label == "Informations personnelles"
                  ),
                })
              }
            >
              <FaUserCog size={"2rem"} />
            </BtnRond>
          </Link>
        </div>
        <div className="bouton-cache-mobile">
          <Link to="/evenements">
            <BtnRond
              btnAction={() =>
                props.handleMenu({
                  activeItemEvenement: props.items.itemsEvenement.findIndex(
                    (v) => v.label == "Tous les événements"
                  ),
                  activeItemEvenementAdmin:
                    props.items.itemsEvenementAdmin.findIndex(
                      (v) => v.label == "Tous les événements"
                    ),
                })
              }
            >
              <FaCalendarPlus size={"2rem"} />
            </BtnRond>
          </Link>
        </div>
        <div className="bouton-cache-mobile">
          <Link to="/actualites">
            <BtnRond
              btnAction={() =>
                props.handleMenu({
                  activeItemActu: props.items.itemsActu.findIndex(
                    (v) => v.label == "Toutes les actus"
                  ),
                  activeItemActuAdmin: props.items.itemsActuAdmin.findIndex(
                    (v) => v.label == "Toutes les actus/conseils"
                  ),
                })
              }
            >
              <FaPollH size={"2rem"} />
            </BtnRond>
          </Link>
        </div>

        <div className="bouton-cache-mobile">
          <Link to="/offres">
            <BtnRond
              btnAction={() =>
                props.handleMenu({
                  activeItemOffre: props.items.itemsOffre.findIndex(
                    (v) => v.label == "Trouver une offre"
                  ),
                  activeItemOffreAdmin: props.items.itemsOffreAdmin.findIndex(
                    (v) => v.label == "Trouver une offre"
                  ),
                })
              }
            >
              <FaSearch size={"2rem"} />
            </BtnRond>
          </Link>
        </div>

        <div className="bouton-cache-mobile">
          <Link to="/groupes">
            <BtnRond
              btnAction={() =>
                props.handleMenu({
                  activeItemGroupes: props.items.itemsGroupes.findIndex(
                    (v) => v.label == "Trouver un groupe"
                  ),
                  activeItemGroupesAdmin:
                    props.items.itemsGroupesAdmin.findIndex(
                      (v) => v.label == "Trouver un groupe"
                    ),
                })
              }
            >
              <FaUsers size={"2rem"} />
            </BtnRond>
          </Link>
        </div>

        <div className="bouton-cache-mobile">
          <BtnRond
            btnAction={() => {
              window.scroll({
                top: props.mapRef.current.offsetTop,
                behavior: "smooth",
              });
            }}
          >
            <FaMapMarkerAlt size={"2rem"} />
          </BtnRond>
        </div>

        <div className="bouton-cache-mobile">
          <p>{t("boutonsAccueilApresConnexion.gererProfil")}</p>
        </div>
        <div className="bouton-cache-mobile">
          <p>{t("boutonsAccueilApresConnexion.prochainsEvenements")}</p>
        </div>
        <div className="bouton-cache-mobile">
          <p>{t("boutonsAccueilApresConnexion.dernieresActus")}</p>
        </div>
        <div className="bouton-cache-mobile">
          <p>{t("boutonsAccueilApresConnexion.trouverOffre")}</p>
        </div>
        <div className="bouton-cache-mobile">
          <p>{t("boutonsAccueilApresConnexion.trouverGroupe")}</p>
        </div>
        <div className="bouton-cache-mobile">
          <p>{t("boutonsAccueilApresConnexion.trouverAlumni")}</p>
        </div>

        <div className="bouton-visible-mobile">
          <Link to="/profil-edit">
            <BtnRond
              btnAction={() =>
                props.handleMenu({
                  activeItemProfilEdit: props.items.itemsProfilEdit.findIndex(
                    (v) => v.label == "Informations personnelles"
                  ),
                })
              }
            >
              <FaUserCog size={"2rem"} />
            </BtnRond>
          </Link>
          <p>Gérer mon profil</p>
        </div>
        <div className="bouton-visible-mobile navigation__hamburger">
          <Menu
            appendTo={document.getElementById("app")}
            model={itemsMobileConnected}
            popup
            ref={menuMobileConnected}
            style={{ textAlign: "center", width: "100%", left: "0" }}
          />
          <BtnRond
            btnAction={(event) => menuMobileConnected.current.toggle(event)}
          >
            <FaEllipsisH size={"2rem"} />
          </BtnRond>
          <p>Découvrir plus de fonctionnalités</p>
        </div>
      </div>
    </div>
  );
};

ButtonsBarConnected.propTypes = {
  auth: PropTypes.object,
  handleAuth: PropTypes.func,
  handleMenu: PropTypes.func,
  items: PropTypes.object,
  mapRef: PropTypes.func,
};

const mapStateToProps = (state) => ({
  items: state.items,
});

const mapDispatchToProps = (dispatch) => ({
  handleMenu: (value) => {
    dispatch(updateMenu(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ButtonsBarConnected);
