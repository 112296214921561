/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import HeaderUserLogin from "Components/Header/HeaderTop/HeaderUserLogin/HeaderUserLogin";
import Modal from "Components/Modal/Modal";
import ModalConnexion from "Components/Modal/ModalConnexion/ModalConnexion";
import { updateAuth } from "Redux/Actions/authActions";
import { updateMenu } from "Redux/Actions/menuActions";
import { authenticationRedirection } from "Services/redirections";
import logo_paris1Blanc from "assets/logo_paris1_blanc.webp";
import logo_unif from "assets/logo_unif.webp";
import avatar from "assets/neutralUser.png";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { SlideMenu } from "primereact/slidemenu";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaRegUserCircle } from "react-icons/fa";
import { connect } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import "../NavBar/Navbar.scss";
import MenuDepliant from "./MenuDepliant/MenuDepliant";
import NavBarMobile from "./NavBarMobile/NavBarMobile";

/**
 * Construit la barre de navigation
 * Fait appel à NavBarMobile pour les menus mobile
 * et MenuDépliant pour les menus dépliant de la navigation desktop
 */
const NavBar = (props) => {
  const [navBarOpen, setNavBarOpen] = useState(false);
  const menu = useRef(null);
  const [loginVisible, setLoginVisible] = useState(false);
  const [openAnnuaireInterface, setOpenAnnuaireInterface] = useState(false);
  const [openEvenementInterface, setOpenEvenementInterface] = useState(false);
  const [openActuInterface, setOpenActuInterface] = useState(false);
  const [openOffreInterface, setOpenOffreInterface] = useState(false);
  const [openGroupeInterface, setOpenGroupeInterface] = useState(false);
  const [visibleModalAnnuaire, setVisibleModalAnnuaire] = useState(false);
  const menuNotConnected = useRef(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const { t } = useTranslation("common");

  let itemsNotConnected = [
    {
      label: t("navigation.accueil"),
      command: () => (window.location.href = "/"),
    },

    { separator: true },
    {
      label: t("navigation.actus"),
      command: () => (window.location.href = "/actualites"),
    },
    { separator: true },
    {
      label: t("navigation.evenements"),
      command: () => (window.location.href = "/evenements"),
    },
    { separator: true },
    {
      label: t("navigation.partenaires"),
      command: () => (window.location.href = "/partenaires"),
    },
    { separator: true },
    {
      label: t("navigation.annuaire"),
      command: () => (window.location.href = "/annuaire"),
    },
    { separator: true },
    {
      label: t("header.connexion"),
      command: () => {
        if (!authenticationRedirection()) setVisibleModal(!visibleModal);
      },
    },
    { separator: true },
    {
      image: { logo_unif },
      className: "logoUnif_burger",
    },
  ];

  return (
    <div>
      {visibleModal && (
        <ModalConnexion
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
        />
      )}
      <nav className="navigation">
        <div className="navigation__hamburger">
          {/* On est connecté en tant qu'admin */}
          {props.auth.isAdmin && props.auth.isConnected && (
            // menu déroulant administrateur connecté
            <div>
              <NavBarMobile menuNotConnected={menuNotConnected} role="admin" />
              <Button
                icon="pi pi-bars"
                onClick={(event) => (
                  menuNotConnected.current.toggle(event),
                  setNavBarOpen(!navBarOpen)
                )}
              />
            </div>
          )}
          {/* on est connecté */}
          {(props.auth.isAlumni || props.auth.isPersonnel) &&
            props.auth.isConnected && (
              <div>
                <NavBarMobile
                  menuNotConnected={menuNotConnected}
                  role="alumni"
                />
                <Button
                  icon="pi pi-bars"
                  onClick={(event) => menuNotConnected.current.toggle(event)}
                />
              </div>
            )}
          {/* on n'est pas connecté */}
          {!props.auth.isConnected && (
            <div>
              <SlideMenu
                appendTo={document.getElementById("app")}
                model={itemsNotConnected}
                viewportHeight={220}
                menuWidth={175}
                popup
                ref={menu}
                style={{ textAlign: "start", left: "0", width: "175px" }}
              />
              <Button
                icon="pi pi-bars"
                onClick={(event) => (
                  menu.current.toggle(event), setNavBarOpen(!navBarOpen)
                )}
              />
            </div>
          )}
        </div>
        {/* logo au milieu du menu burger */}
        {!props.auth.isConnected ? (
          <img
            src={logo_paris1Blanc}
            className="brand-name"
            alt="Logo de Paris 1"
            title="Logo de Paris 1"
          />
        ) : (
          <img
            className="brand-name"
            src={logo_paris1Blanc}
            alt={
              "Logo Université " + props.auth.universityConnected.name || null
            }
            title={
              "Logo Université " + props.auth.universityConnected.name || null
            }
          />
        )}
        {/* logo utilisateur du menu burger */}
        {props.auth.isConnected ? (
          <Link to="/profil">
            <img
              src={avatar}
              className="img-profil"
              alt="Mon avatar"
              title="Mon avatar"
            />
          </Link>
        ) : (
          <FaRegUserCircle className="img-profil__disconnected" />
        )}

        {/* ----------------------------------------- barre de navigation  */}
        <div className="navigation-menu">
          <ul>
            {/* Barre de navigation hors connexion */}
            {!props.auth.isConnected && (
              <>
                <li>
                  <NavLink
                    to="/"
                    className={
                      useLocation().pathname == "/" ? "activeLink" : ""
                    }
                  >
                    <button className="annuaire__button">
                      {t("navigation.accueil")}
                    </button>
                  </NavLink>
                </li>
                <li>
                  <Link
                    to="/actualites"
                    className={
                      useLocation().pathname == "/actualites"
                        ? "activeLink"
                        : ""
                    }
                  >
                    <button className="annuaire__button">
                      {t("navigation.actus")}
                    </button>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/evenements"
                    className={
                      useLocation().pathname == "/evenements"
                        ? "activeLink"
                        : ""
                    }
                  >
                    <button className="annuaire__button">
                      {t("navigation.evenements")}
                    </button>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/offres"
                    className={
                      useLocation().pathname == "/offres" ? "activeLink" : ""
                    }
                  >
                    <button
                      className="annuaire__button"
                      onClick={() => {
                        props.handleMenu({
                          activeItemOffre: 0,
                        });
                      }}
                    >
                      {t("navigation.offres")}
                    </button>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/partenaires"
                    className={
                      useLocation().pathname == "/partenaires"
                        ? "activeLink"
                        : ""
                    }
                  >
                    <button className="annuaire__button">
                      {t("navigation.partenaires")}
                    </button>
                  </Link>
                </li>
                <li>
                  <button
                    className="annuaire__button"
                    onClick={() => {
                      if (!authenticationRedirection()) setLoginVisible(true);
                    }}
                  >
                    {t("navigation.annuaire")}
                  </button>
                </li>
              </>
            )}
            {/* Barre de navigation connecté */}
            {props.auth.isConnected && props.auth.userConnected && (
              <>
                <li>
                  <NavLink
                    to="/"
                    className={
                      useLocation().pathname == "/" ? "activeLink" : ""
                    }
                  >
                    <button className="annuaire__button">
                      {t("navigation.accueil")}
                    </button>
                  </NavLink>
                </li>
                <li>
                  <Link
                    to="/actualites"
                    className={
                      useLocation().pathname == "/actualites"
                        ? "activeLink"
                        : ""
                    }
                    onMouseEnter={() => setOpenActuInterface(true)}
                    onMouseLeave={() => setOpenActuInterface(false)}
                  >
                    {props.auth.isConnected && !props.auth.isAdmin && (
                      <button
                        className="annuaire__button"
                        onClick={() => {
                          props.handleMenu({
                            activeItemActu: 0,
                          });
                        }}
                      >
                        {t("navigation.actus")}
                        {openActuInterface && (
                          <div className="annuaireInterface">
                            <MenuDepliant item="actu" />
                          </div>
                        )}
                      </button>
                    )}
                    {props.auth.isAdmin && (
                      <button
                        className="annuaire__button"
                        onClick={() =>
                          props.handleMenu({
                            activeItemActuAdmin: 0,
                          })
                        }
                      >
                        {t("navigation.actus")}
                        {openActuInterface && (
                          <div className="annuaireInterface">
                            <MenuDepliant item="actuadmin" />
                          </div>
                        )}
                      </button>
                    )}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/evenements"
                    className={
                      useLocation().pathname == "/evenements"
                        ? "activeLink"
                        : ""
                    }
                    onMouseOver={() => setOpenEvenementInterface(true)}
                    onMouseLeave={() => setOpenEvenementInterface(false)}
                  >
                    {props.auth.isConnected && !props.auth.isAdmin && (
                      <button
                        className="annuaire__button"
                        onClick={() => {
                          props.handleMenu({
                            activeItemEvenement: 0,
                          });
                        }}
                      >
                        {t("navigation.evenements")}
                        {openEvenementInterface && (
                          <div className="annuaireInterface">
                            <MenuDepliant item="event" />
                          </div>
                        )}
                      </button>
                    )}
                    {props.auth.isAdmin && (
                      <button
                        onMouseEnter={() => setOpenEvenementInterface(true)}
                        onMouseLeave={() => setOpenEvenementInterface(false)}
                        className="annuaire__button"
                        onClick={() => {
                          props.handleMenu({
                            activeItemEvenementAdmin: 0,
                          });
                        }}
                      >
                        {t("navigation.evenements")}
                        {openEvenementInterface && (
                          <div
                            className="annuaireInterface"
                            onMouseEnter={() => setOpenEvenementInterface(true)}
                            onMouseLeave={() =>
                              setOpenEvenementInterface(false)
                            }
                          >
                            <MenuDepliant item="eventadmin" />
                          </div>
                        )}
                      </button>
                    )}
                  </Link>
                </li>
                <li
                  onMouseOver={() => setOpenOffreInterface(true)}
                  onMouseLeave={() => setOpenOffreInterface(false)}
                >
                  <Link
                    to="/offres"
                    className={
                      useLocation().pathname == "/offres" ? "activeLink" : ""
                    }
                  >
                    <button
                      className="annuaire__button"
                      onClick={() => {
                        props.handleMenu({
                          activeItemOffre: 0,
                        });
                      }}
                    >
                      {t("navigation.offres")}
                      {openOffreInterface && (
                        <div className="annuaireInterface">
                          <MenuDepliant item="offres" />
                        </div>
                      )}
                    </button>
                  </Link>
                </li>
                {props.auth.isAdmin ? (
                  <>
                    <li>
                      <Link
                        to="/groupes"
                        className={
                          useLocation().pathname == "/groupes"
                            ? "activeLink"
                            : ""
                        }
                        onMouseEnter={() => setOpenGroupeInterface(true)}
                        onMouseLeave={() => setOpenGroupeInterface(false)}
                      >
                        <button
                          className="annuaire__button"
                          onClick={() => {
                            props.handleMenu({
                              activeItemGroupesAdmin: 0,
                            });
                          }}
                        >
                          {t("navigation.groupes")}

                          {openGroupeInterface && (
                            <div className="annuaireInterface">
                              <MenuDepliant item="groupeadmin" />
                            </div>
                          )}
                        </button>
                      </Link>
                    </li>
                    {t("navigation.annuaire") && (
                      <li>
                        <Link
                          to="/annuaire"
                          className={
                            useLocation().pathname == "/annuaire"
                              ? "activeLink"
                              : ""
                          }
                          onMouseEnter={() => setOpenAnnuaireInterface(true)}
                          onMouseLeave={() => setOpenAnnuaireInterface(false)}
                        >
                          <button
                            className="annuaire__button"
                            onClick={() =>
                              props.handleMenu({
                                activeItemAnnuaire: 0,
                              })
                            }
                          >
                            {t("navigation.annuaire")}
                            {openAnnuaireInterface && (
                              <div className="annuaireInterface">
                                <MenuDepliant item="annuaire" />
                              </div>
                            )}
                          </button>
                          {visibleModal && (
                            <Modal
                              visible={visibleModalAnnuaire}
                              setVisible={setVisibleModalAnnuaire}
                              header="Se connecter"
                            >
                              <form action="/action_page.php">
                                <label htmlFor="fname">First name:</label>
                                <input
                                  type="text"
                                  id="fname"
                                  name="fname"
                                  value="John"
                                />
                                <label htmlFor="lname">Last name:</label>
                                <input
                                  type="text"
                                  id="lname"
                                  name="lname"
                                  value="Doe"
                                />
                                <input type="submit" value="Submit" />
                              </form>
                            </Modal>
                          )}
                        </Link>
                      </li>
                    )}
                  </>
                ) : (
                  <>
                    <li>
                      <Link
                        to="/groupes"
                        className={
                          useLocation().pathname == "/groupes"
                            ? "activeLink"
                            : ""
                        }
                        onMouseEnter={() => setOpenGroupeInterface(true)}
                        onMouseLeave={() => setOpenGroupeInterface(false)}
                      >
                        <button
                          className="annuaire__button"
                          onClick={() => {
                            props.handleMenu({
                              activeItemGroupes: 0,
                            });
                          }}
                        >
                          {t("navigation.groupes")}
                          {openGroupeInterface && (
                            <div className="annuaireInterface">
                              <MenuDepliant item="groupe" />
                            </div>
                          )}
                        </button>
                      </Link>
                    </li>
                    {t("navigation.annuaire") && (
                      <li>
                        <Link
                          to="/annuaire"
                          className={
                            useLocation().pathname == "/annuaire"
                              ? "activeLink"
                              : ""
                          }
                          onMouseEnter={() => setOpenAnnuaireInterface(true)}
                          onMouseLeave={() => setOpenAnnuaireInterface(false)}
                        >
                          <button
                            className="annuaire__button"
                            onClick={() =>
                              props.handleMenu({
                                activeItemAnnuaire: 0,
                              })
                            }
                          >
                            {t("navigation.annuaire")}

                            {openAnnuaireInterface && (
                              <div className="annuaireInterface">
                                <MenuDepliant item="annuaire" />
                              </div>
                            )}
                          </button>
                        </Link>
                      </li>
                    )}
                  </>
                )}
              </>
            )}
          </ul>
        </div>
      </nav>
      {loginVisible && (
        <HeaderUserLogin
          loginVisible={loginVisible}
          setLoginVisible={setLoginVisible}
          redirect={"/annuaire"}
        />
      )}
    </div>
  );
};

NavBar.propTypes = {
  navbarOpen: PropTypes.bool,
  handleToggle: PropTypes.func,
  auth: PropTypes.object,
  admin: PropTypes.object,
  handleAuth: PropTypes.func,
  handleMenu: PropTypes.func,
  items: PropTypes.object,
  annuaire: PropTypes.number,
  activeIndex: PropTypes.number,
  setActiveIndex: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
  admin: state.admin,
});

const mapDispatchToProps = (dispatch) => ({
  handleAuth: (value) => {
    dispatch(updateAuth(value));
  },
  handleMenu: (value) => {
    dispatch(updateMenu(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);

{
  /* <li>
                    <Link to={{ pathname: "/parametrage" }}>
                      <button
                        onMouseEnter={() => setOpenParametreInterface(true)}
                        onMouseLeave={() => setOpenParametreInterface(false)}
                        className="annuaire__button"
                        onClick={() => {
                          props.handleMenu({
                            activeItemParametrage: 0,
                          });
                        }}
                      >
                        {location.pathname === "/parametrage" ? (
                          <img src={settingsBlanc} className="cog-annuaire" />
                        ) : (
                          <img src={settings} className="cog-annuaire" />
                        )}
                        {openParametreInterface && (
                          <div
                            className="annuaireInterface"
                            onMouseEnter={() => setOpenParametreInterface(true)}
                            onMouseLeave={() =>
                              setOpenParametreInterface(false)
                            }
                          >
                            <MenuDepliant item="parametres" />
                          </div>
                        )}
                      </button>
                    </Link>
                  </li> */
}
