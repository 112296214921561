import React, { useEffect } from "react";
import "./ProfilEdit.scss";
import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import ProfilBar from "Components/ProfilBar/ProfilBar";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormationDiplomes from "Components/EditionProfil/FormationDiplomes/FormationDiplomes";
import ExperiencesPro from "Components/EditionProfil/ExperiencesPro/ExperiencesPro";
import Competences from "Components/EditionProfil/Competences/Competences";
import ParamConfidentialite from "Components/EditionProfil/ParamConfidentialite/ParamConfidentialite";
import NavBarTab from "Components/NavBar/NavBarTab/NavBarTab";
import InformationsPerso from "Components/EditionProfil/InformationsPerso/InformationsPerso";
import { updateAuth } from "Redux/Actions/authActions";
import { useFetchGet } from "Services/api";
import { formatDate } from "Services/functions";

/**
 * Permet d'afficher la page de modification de profil
 * Accessible pour tous les utilisateurs
 * Accessible depuis la page de profil avec "modifier mon profil"
 * On accède à des formulaires : informations personnelles, formations et diplômes,
 * expériences professionnelles, compétences et paramètres de confidentialité
 */
const ProfilEdit = (props) => {
  const currentUser = useFetchGet(
    `/users/${props.auth.userConnected.id}/private`,
    props.auth.token
  );

  useEffect(async () => {
    if (currentUser.loaded && currentUser.data) {
      props.handleUpdateAuth({
        userConnected: {
          ...currentUser.data,
          birthday: formatDate(currentUser.data.birthday),
        },
      });
    }
  }, [currentUser.loaded]);

  return (
    <div className="profil_edit">
      <Header>
        <div className="profilPage__headerProfilBar">
          {props.auth.isConnected && (
            <>
              {props.items.activeItemProfilEdit ==
                props.items.itemsProfilEdit.findIndex(
                  (v) => v.label == "Informations personnelles"
                ) && (
                <NavBarTab
                  id="mobile"
                  select="profiledit"
                  activeIndex={props.items.activeItemProfilEdit}
                  items={props.items.itemsProfilEdit}
                />
              )}
              {props.items.activeItemProfilEdit ==
                props.items.itemsProfilEdit.findIndex(
                  (v) => v.label == "Formations et diplômes"
                ) && (
                <NavBarTab
                  id="mobile"
                  select="profiledit"
                  activeIndex={props.items.activeItemProfilEdit}
                  items={props.items.itemsProfilEdit}
                />
              )}
              {props.items.activeItemProfilEdit ==
                props.items.itemsProfilEdit.findIndex(
                  (v) => v.label == "Expériences professionnelles"
                ) && (
                <NavBarTab
                  id="mobile"
                  select="profiledit"
                  activeIndex={props.items.activeItemProfilEdit}
                  items={props.items.itemsProfilEdit}
                />
              )}
              {props.items.activeItemProfilEdit ==
                props.items.itemsProfilEdit.findIndex(
                  (v) => v.label == "Compétences"
                ) && (
                <NavBarTab
                  id="mobile"
                  select="profiledit"
                  activeIndex={props.items.activeItemProfilEdit}
                  items={props.items.itemsProfilEdit}
                />
              )}
              {props.items.activeItemProfilEdit ==
                props.items.itemsProfilEdit.findIndex(
                  (v) => v.label == "Paramètres de confidentialité"
                ) && (
                <NavBarTab
                  id="mobile"
                  select="profiledit"
                  activeIndex={props.items.activeItemProfilEdit}
                  items={props.items.itemsProfilEdit}
                />
              )}
              <ProfilBar />
            </>
          )}
        </div>
      </Header>

      {props.auth.isConnected && (
        <>
          {props.items.activeItemProfilEdit ==
            props.items.itemsProfilEdit.findIndex(
              (v) => v.label == "Informations personnelles"
            ) && (
            <InformationsPerso
              activeIndex={props.items.itemsProfilEdit.findIndex(
                (v) => v.label == "Informations personnelles"
              )}
              items={props.items.itemsProfilEdit}
              user={
                currentUser.data
                  ? {
                      ...currentUser.data,
                      birthday: formatDate(currentUser.data?.birthday),
                    }
                  : null
              }
            />
          )}
          {props.items.activeItemProfilEdit ==
            props.items.itemsProfilEdit.findIndex(
              (v) => v.label == "Formations et diplômes"
            ) && (
            <FormationDiplomes
              activeIndex={props.items.itemsProfilEdit.findIndex(
                (v) => v.label == "Formations et diplômes"
              )}
              items={props.items.itemsProfilEdit}
            />
          )}
          {props.items.activeItemProfilEdit ==
            props.items.itemsProfilEdit.findIndex(
              (v) => v.label == "Expériences professionnelles"
            ) && (
            <ExperiencesPro
              activeIndex={props.items.itemsProfilEdit.findIndex(
                (v) => v.label == "Expériences professionnelles"
              )}
              items={props.items.itemsProfilEdit}
            />
          )}
          {props.items.activeItemProfilEdit ==
            props.items.itemsProfilEdit.findIndex(
              (v) => v.label == "Compétences"
            ) && (
            <Competences
              activeIndex={props.items.itemsProfilEdit.findIndex(
                (v) => v.label == "Compétences"
              )}
              items={props.items.itemsProfilEdit}
            />
          )}
          {props.items.activeItemProfilEdit ==
            props.items.itemsProfilEdit.findIndex(
              (v) => v.label == "Paramètres de confidentialité"
            ) && (
            <ParamConfidentialite
              activeIndex={props.items.itemsProfilEdit.findIndex(
                (v) => v.label == "Paramètres de confidentialité"
              )}
              items={props.items.itemsProfilEdit}
            />
          )}
        </>
      )}
      <Footer />
    </div>
  );
};

ProfilEdit.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.object,
  handleUpdateAuth: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateAuth: (value) => {
    dispatch(updateAuth(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilEdit);
