/* eslint-disable no-unused-vars */
import React from "react";
import "./PresentationRPAlumni.scss";
import { Accordion, AccordionTab } from "primereact/accordion";
import parse from "html-react-parser";
import { findItemInEditManagement } from "Services/functions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/**
 * Permet d'afficher la présentation de Réseau Pro sur la page d'accueil hors connexion
 */
const PresentationRPAlumni = (props) => {
  const { t } = useTranslation("common");

  return (
    <div className="article_presentation">
      <div className="presentation_title">
        <Accordion>
          <AccordionTab
            header={
              <h1 className="h1_trait_dessus">
                {t("presentation.presentation_reseaupro")}
              </h1>
            }
          >
            {props.admin?.editManagement?.length > 0 &&
              (props.i18n.language === "en"
                ? parse(t("presentation.presentation_content"))
                : parse(
                    findItemInEditManagement(
                      "presentationRPAlumni",
                      props.admin.editManagement
                    )
                  ))}
          </AccordionTab>
        </Accordion>
      </div>
    </div>
  );
};

PresentationRPAlumni.propTypes = {
  admin: PropTypes.object,
  i18n: PropTypes.object,
};
const mapStateToProps = (state) => ({
  admin: state.admin,
  i18n: state.i18n,
});

export default connect(mapStateToProps)(PresentationRPAlumni);
