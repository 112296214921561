import React, { useState, useEffect, useRef } from "react";
import "./ActualitesContainer.scss";
import Actualite from "../Actualite/Actualite";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import { FaChevronRight } from "react-icons/fa";
import PropTypes from "prop-types";
import { HiSearch } from "react-icons/hi";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Accordion, AccordionTab } from "primereact/accordion";
import { formatDateDatabase, formatDateFrenchLocale } from "Services/functions";
import { updateNews } from "Redux/Actions/newsActions";
import { updatePagination } from "Redux/Actions/paginationActions";
import { useFetchGet, useFetchGetPagination } from "Services/api";
import { updateAuth } from "Redux/Actions/authActions";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { CgClose } from "react-icons/cg";
import { fr } from "date-fns/locale";
import TempPagination from "Components/Pagination/TempPagination";
import Loader from "Components/Loader/loader";
import { useTranslation } from "react-i18next";
import { authenticationRedirection } from "Services/redirections";

// import axios from "axios";
registerLocale("fr", fr);
/**
 * Permet d'afficher les actualités et actus/conseils
 */
const ActualitesContainer = (props) => {
  const { t } = useTranslation("common");
  // Fetch les catégories des actus depuis la BDD
  const categoriesData = useFetchGet("/news_categories", props.auth.token);
  const actualitesContainerRef = useRef(null);
  //
  const [categories, setCategories] = useState([]);

  // Les différents filtres de recherche des actualités
  const [filtreDate, setFiltreDate] = useState(null);
  const [filtreMotsCles, setFiltreMotsCles] = useState("");
  const [filtreCategory, setFiltreCategory] = useState(null);
  const [resetStatus, setResetStatus] = useState(false);
  const [newsUrl, setNewsUrl] = useState(null);

  const newsRef = useRef(null);

  const newsQuery = useFetchGetPagination(
    newsUrl,
    props.auth.token,
    props.handleUpdateNews
  );
  useEffect(() => {
    return () => {
      props.handleUpdateNews({ dataFromPagination: null });
    };
  }, []);

  useEffect(() => {
    /// Récupérer les catégories, et les filtrer par ordre alphabétique
    if (categoriesData.loaded && categoriesData.data.length > 0) {
      setCategories([]);
      let tempArray = [];
      categoriesData.data.forEach((category) => tempArray.push(category));
      tempArray = tempArray.sort((a, b) => a.label.localeCompare(b.label));
      setCategories(tempArray);
    }
  }, [categoriesData.loaded]);

  // Fonction globale de filtre
  const handleFilter = () => {
    if (props.states.statesData.length > 0) {
      let url = new URL(`${process.env.REACT_APP_BASE_URL_API}/news`);
      url.searchParams.set(
        "state",
        props.states.statesData.filter(
          (etat) => etat.statusLabel === "Publié"
        )[0].id
      );
      url.searchParams.set("order[modifiedAt]", "desc");
      if (filtreMotsCles != "") url.searchParams.set("keyword", filtreMotsCles);
      if (filtreCategory) url.searchParams.set("category", filtreCategory.id);
      if (filtreDate && filtreDate[0])
        url.searchParams.set(
          "modifiedAt[after]",
          formatDateDatabase(filtreDate[0])
        );
      if (filtreDate && filtreDate[1])
        url.searchParams.set(
          "modifiedAt[before]",
          formatDateDatabase(filtreDate[1])
        );

      setNewsUrl(url);
    }
  };

  useEffect(() => {
    if (!filtreDate || (filtreDate[0] && filtreDate[1])) {
      handleFilter();
    }
  }, [filtreCategory, filtreDate, resetStatus, props.states.statesData.length]);
  return (
    <div
      style={{
        backgroundColor:
          props.pageActus == "accueil" ? `backgroundColor : "white"` : "",
      }}
      ref={newsRef}
    >
      <div className="actualites_container">
        <div className="actualites_container__top">
          {props.pageActus == "accueil" ? (
            // si on est dans la page d'accueil on n'affiche pas les filtres
            <h1 className="h1_trait_dessus">
              {props.title ? props.title : "Les actus"}
            </h1>
          ) : (
            <div className="actualites_container__top__title">
              {/* si on est sur la page Actualites on affiche les filtres */}
              <h1 className="h1_trait_dessus">
                {/* {props.title ? props.title : "Toutes les actus"} */}
                {t("actusHorsConnexion.title_page_actus")}
              </h1>
              <div className="actualites_container__top__title__filtres__visible">
                <div className="actualites_container__top__title__filtres">
                  <span>
                    <HiSearch
                      className="searchBar_groupe_finder_icon"
                      onClick={(e) => {
                        e.preventDefault();
                        handleFilter();
                      }}
                    />
                    <InputText
                      placeholder={t("actusHorsConnexion.placeholder_research")}
                      value={filtreMotsCles}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          handleFilter();
                        }
                      }}
                      onChange={(e) => {
                        setFiltreMotsCles(e.target.value.toLowerCase());
                      }}
                      className="membership_input_text"
                    />
                  </span>
                  <span>
                    <Dropdown
                      value={filtreCategory}
                      optionLabel="label"
                      showClear
                      options={categories}
                      onChange={(e) => {
                        setFiltreCategory(e.target.value);
                      }}
                      placeholder={t("actusHorsConnexion.placeholder_filter")}
                    />
                  </span>
                  <span>
                    {props.auth.isConnected && (
                      <div
                        className={
                          !filtreDate || (filtreDate[0] && filtreDate[1])
                            ? "management_new_event_offer_filtres__top__date"
                            : "management_new_event_offer_filtres__top__date warning"
                        }
                      >
                        <ReactDatePicker
                          placeholderText={t("actusApresConnexion.filtreDate")}
                          selected={filtreDate && filtreDate[0]}
                          onChange={(e) => {
                            setFiltreDate(e);
                          }}
                          dateFormat="dd/MM/yyyy"
                          startDate={filtreDate && filtreDate[0]}
                          endDate={filtreDate && filtreDate[1] && filtreDate[1]}
                          selectsRange
                        />
                        {filtreDate !== null && (
                          <CgClose
                            onClick={() => {
                              setFiltreDate(null);
                            }}
                          ></CgClose>
                        )}
                      </div>
                    )}
                  </span>

                  {(filtreCategory ||
                    (filtreDate && filtreDate[1]) ||
                    filtreMotsCles) && (
                    <li className="actualites_container__top__title__filtres__refresh">
                      <i
                        className="pi pi-refresh"
                        style={{ fontSize: "1.5rem", fontWeight: "600" }}
                        onClick={() => {
                          setFiltreCategory(null);
                          setFiltreDate(null);
                          setFiltreMotsCles("");
                          setResetStatus(!resetStatus);
                        }}
                      />
                    </li>
                  )}
                </div>
              </div>
              <Accordion
                className="accordion_actus"
                expandIcon="pi pi-sliders-h"
                collapseIcon="pi pi-sliders-h"
              >
                <AccordionTab header="Filtres de recherche">
                  {""}
                  <div className="actualites_container__top__title__filtres">
                    <span>
                      <HiSearch
                        className="searchBar_groupe_finder_icon"
                        onClick={(e) => {
                          e.preventDefault();
                          handleFilter();
                        }}
                      />
                      <InputText
                        placeholder="Rechercher une actualité"
                        value={filtreMotsCles}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleFilter();
                          }
                        }}
                        onChange={(e) => {
                          setFiltreMotsCles(e.target.value.toLowerCase());
                        }}
                        className="membership_input_text"
                      />
                    </span>
                    {!props.title && (
                      <Dropdown
                        value={filtreCategory}
                        optionLabel="label"
                        showClear
                        options={categories}
                        onChange={(e) => {
                          setFiltreCategory(e.target.value);
                        }}
                        placeholder="Filtrer par catégorie"
                      />
                    )}

                    {props.auth.isConnected && (
                      <div
                        className={
                          !filtreDate || (filtreDate[0] && filtreDate[1])
                            ? "management_new_event_offer_filtres__top__date"
                            : "management_new_event_offer_filtres__top__date warning"
                        }
                      >
                        <ReactDatePicker
                          placeholderText="Filtrer par date"
                          selected={filtreDate && filtreDate[0]}
                          onChange={(e) => {
                            setFiltreDate(e);
                          }}
                          dateFormat="dd/MM/yyyy"
                          startDate={filtreDate && filtreDate[0]}
                          endDate={filtreDate && filtreDate[1] && filtreDate[1]}
                          selectsRange
                        />
                        {filtreDate !== null && (
                          <CgClose
                            onClick={() => {
                              setFiltreDate(null);
                            }}
                          ></CgClose>
                        )}
                      </div>
                    )}
                    {(filtreCategory ||
                      (filtreDate && filtreDate[1]) ||
                      filtreMotsCles) && (
                      <li className="actualites_container__top__title__filtres__refresh">
                        <i
                          className="pi pi-refresh"
                          style={{ fontSize: "1.5rem", fontWeight: "600" }}
                          onClick={() => {
                            setFiltreCategory(null);
                            setFiltreDate(null);
                            setFiltreMotsCles("");
                            setResetStatus(!resetStatus);
                          }}
                        />
                      </li>
                    )}
                  </div>
                </AccordionTab>
              </Accordion>
            </div>
          )}
          {/* peu importe la page on affiche le container avec les actualités */}

          <div
            className="actualites_container__body"
            ref={actualitesContainerRef}
          >
            {newsQuery?.data ? (
              newsQuery.data.length > 0 ? (
                (props.nbActus && props.nbActus != 12
                  ? newsQuery.data.slice(0, props.nbActus)
                  : newsQuery.data
                ).map((actualite, index) =>
                  props.auth.userConnected || actualite.public ? (
                    <Link
                      to={{
                        pathname: `/actu-detail/${actualite.id}`,
                        params: actualite.id,
                      }}
                      key={index}
                    >
                      <Actualite
                        key={actualite.id}
                        title={actualite.title}
                        chapeau={actualite.chapo}
                        url={actualite.imageUrl ? actualite.imageUrl : "img1"}
                        publicationDate={formatDateFrenchLocale(
                          actualite?.publishedAt
                        )}
                        categorie={actualite.category.label}
                        newsDetails={actualite}
                      />
                    </Link>
                  ) : (
                    <Actualite
                      key={actualite.id}
                      title={actualite.title}
                      chapeau={actualite.chapo}
                      url={actualite.imageUrl ? actualite.imageUrl : "img1"}
                      publicationDate={formatDateFrenchLocale(
                        actualite?.publishedAt
                      )}
                      categorie={actualite.category.label}
                      newsDetails={actualite}
                      onClick={() => {
                        authenticationRedirection();
                      }}
                    />
                  )
                )
              ) : (
                <div className="actualites_container__empty">
                  <span>{t("erreur.erreur_actu")}</span>
                </div>
              )
            ) : (
              <Loader></Loader>
            )}
          </div>
        </div>
        <div className="actualites_container__pagination">
          {props.pageActus == "accueil" ? (
            <BtnBleu
              btnTexte="Voir toutes les actus"
              btnAction={() => (window.location.href = "./actualites")}
            >
              <FaChevronRight />
            </BtnBleu>
          ) : (
            <TempPagination
              result={newsQuery}
              refItemParent={actualitesContainerRef}
              rows={50}
            />
          )}
        </div>
      </div>
    </div>
  );
};

ActualitesContainer.propTypes = {
  auth: PropTypes.object,
  nbActus: PropTypes.number,
  pageActus: PropTypes.string,
  filtre: PropTypes.string,
  filtres: PropTypes.array,
  setFiltre: PropTypes.func,
  title: PropTypes.string,
  news: PropTypes.any,
  handleUpdateNews: PropTypes.func,
  pagination: PropTypes.object,
  handleUpdatePagination: PropTypes.func,
  handleUpdateAuth: PropTypes.func,
  states: PropTypes.object,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  news: state.news,
  pagination: state.pagination,
  states: state.states,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateNews: (value) => dispatch(updateNews(value)),
  handleUpdatePagination: (value) => dispatch(updatePagination(value)),
  handleUpdateAuth: (value) => dispatch(updateAuth(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActualitesContainer);
