import React, { useState } from "react";
import "./MembreCard.scss";
import avatar from "assets/neutralUser.png";
import { FaCog, FaRegTrashAlt } from "react-icons/fa";
import Modal from "Components/Modal/Modal";
import { Checkbox } from "primereact/checkbox";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import PropTypes from "prop-types";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { fr } from "date-fns/locale";

/**
 * Permet d'afficher les cards des utilisateurs avec leurs rôles s'ils en ont
 */
const MembreCard = (props) => {
  const [modal, setModal] = useState(false);
  const [validerOffre, setValiderOffre] = useState(false);
  const [contributeur, setContributeur] = useState(false);
  const [dateFin, setDateFin] = useState("");
  const DATE_OPTIONS = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  return (
    <div className="membre_card">
      <div className="membre_card__title">
        <img src={avatar} />
        <h6>
          {props.prenom} {props.nom}
        </h6>
      </div>
      <div className="membre_card__item">
        <p>Création de compte :</p>
        <p>{props.dateCreationCompte}</p>
      </div>
      <div className="membre_card__item">
        <p>Dernière connexion :</p>
        <p>{props.dateDerniereConnexion}</p>
      </div>
      <div className="membre_card__item role">
        {props.dejaRole && (
          <>
            <p>Rôle {props.role}</p>
            <p>Fin : {props.dateFin}</p>
          </>
        )}
      </div>
      <div>
        <FaCog size={"1.5rem"} onClick={() => setModal(true)} />
        {/* TO DO : onClick enlever de la liste des contributeurs dans l'api la personne concernée */}
        {props.contributeur && (
          <FaRegTrashAlt size={"1.5rem"} className="poubelle" />
        )}
      </div>

      {modal && (
        <Modal
          visible={modal}
          setVisible={setModal}
          header="Options de contribution"
          height="fit-content"
        >
          <div>
            <div className="membre_card__checkbox">
              <Checkbox
                checked={validerOffre}
                onChange={(e) => setValiderOffre(e.checked)}
              />
              <label>
                Rôle « valider les offres » : ce rôle permet à un utilisateur
                d’accéder à la page de validation des offres
              </label>
            </div>
            <div className="membre_card__checkbox">
              <Checkbox
                checked={contributeur}
                onChange={(e) => setContributeur(e.checked)}
              />
              <label>
                Rôle « contributeur » : ce rôle permet à un utilisateur
                d’accéder à la rédaction des actus
              </label>
            </div>
            <div className="membre_card__checkbox">
              <p>Choisir une date de fin de période</p>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
                <DatePicker
                  label="Date de fin"
                  placeholderText="Date de fin"
                  selected={dateFin === " " ? null : dateFin}
                  onChange={(date) =>
                    setDateFin(
                      new Intl.DateTimeFormat("fr-FR", DATE_OPTIONS).format(
                        date,
                        "DD/MM/YY"
                      )
                    )
                  }
                  labelFunc={() => dateFin || "08/05/2022..."}
                  cancelLabel="Annuler"
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>

          {/* TO DO : action valider pour mettre à jour l'api */}
          <div className="membre_card__boutons">
            <BtnBleu btnTexte="Valider" />
            <BtnBlanc btnTexte="Annuler" btnAction={() => setModal(false)} />
          </div>
        </Modal>
      )}
    </div>
  );
};

MembreCard.propTypes = {
  contributeur: PropTypes.bool,
  dejaRole: PropTypes.bool,

  nom: PropTypes.string,
  prenom: PropTypes.string,
  role: PropTypes.string,
  dateFin: PropTypes.string,
  dateCreationCompte: PropTypes.string,
  dateDerniereConnexion: PropTypes.string,
};

export default MembreCard;
