import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import BtnBleuLeft from "Components/Boutons/BtnBleuLeft/btn-bleu-left";
import BtnEcouteMarche from "Components/Boutons/BtnEcouteMarche/BtnEcouteMarche";
import BtnRetour from "Components/Boutons/BtnRetour/btn-retour";
import Modal from "Components/Modal/Modal";
import NavBarTab from "Components/NavBar/NavBarTab/NavBarTab";
import { updateAuth } from "Redux/Actions/authActions";
import axios from "axios";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaCheck } from "react-icons/fa";
import { connect } from "react-redux";
import "./ParamConfidentialite.scss";

const ParamConfidentialite = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [formData, setFormData] = useState({});
  const successUpdate = useRef(null);
  const { t } = useTranslation("common");

  // Popup validation charte
  const [visibleModal, setVisibleModal] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues: useMemo(() => {
      return {
        ...props.auth.userConnected,
      };
    }, [props.auth.userConnected]),
  });
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  useEffect(() => {}, [watch("charteSigned")]);

  /**
   * Fonction qui va créer un lien de téléchargement de la charte
   *
   * Une fois le lien cliqué, la charte sera téléchargée
   *
   * Nom du fichier : charteRP.pdf
   */
  const downloadCharter = () => {
    //? Création de l'url de l'api
    let url = `${process.env.REACT_APP_BASE_URL_API}/charter/pdf`;
    //? Appel de l'api
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${props.auth.token}`,
          accept: "application/json",
        },
        responseType: "blob",
        params: {
          name: "charteRPAlumni",
        },
      })
      //? En cas de succès, création du lien de téléchargement
      .then((res) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(res.data);
        link.target = "_blank";
        link.setAttribute("download", `charteRPAlumni.pdf`);
        link.click();
      })
      //? En cas d'erreur, affichage de l'erreur
      .catch((error) => {
        console.error(error.message);
      });
  };

  const putDataToApi = (data) => {
    //? Remove id de l'objet props.user
    const newParamsConfidential = {
      charteSigned: data.charteSigned,
      newsLetterNotification: data.newsLetterNotification,
      datasVisibleForAnnuaire: data.datasVisibleForAnnuaire,
      datasAllPrivate: data.datasAllPrivate,
    };

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL_API}/users/${props.auth.userConnected.id}`,
        newParamsConfidential,
        {
          headers: props.auth.token
            ? {
                accept: "application/json",
                "Content-Type": "application/json",

                Authorization: `Bearer ${props.auth.token}`,
              }
            : {
                accept: "application/json",
                "Content-Type": "application/json",
              },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          showSuccess();
          props.handleUpdateAuth({ userConnected: res.data });
        }
      })
      .catch((err) => console.log(err))
      .finally((fin) => console.log(fin));
  };

  const showSuccess = () => {
    successUpdate.current.show({
      severity: "success",
      summary: "Mise à jour effectuée avec succès",
      detail: "Votre mise à jour a bien été prise en compte",
      life: 3000,
    });
  };

  const onSubmit = (data) => {
    setFormData(data);
    putDataToApi(data);
  };
  return (
    <div>
      <Toast ref={successUpdate} />
      {props.auth.isConnected && (
        <div className="article_actu">
          <NavBarTab
            id="desktop"
            select="profiledit"
            activeIndex={props.activeIndex}
            items={props.items}
          />
          <BtnEcouteMarche items={props.items} />

          <div className="titre_de_page">
            <h1 className="h1_trait_dessus">
              {t("profilEdition.privacySettings.header")}
            </h1>
            <BtnRetour />
          </div>
          <div className="form-demo">
            <div className="flex justify-content-center">
              <div className="card">
                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                  <Divider>
                    <span className="p-tag">
                      {t("profilEdition.privacySettings.availability")}
                    </span>
                  </Divider>
                  <div className="field-checkbox">
                    <Controller
                      name="datasVisibleForAnnuaire"
                      control={control}
                      render={({ field, fieldState }) => (
                        <Checkbox
                          inputId={field.name}
                          onChange={(e) => field.onChange(e.checked)}
                          checked={field.value}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                        />
                      )}
                    />
                    <label
                      htmlFor="datasVisibleForAnnuaire"
                      className={classNames({ "p-error": errors.accept })}
                    >
                      {t("profilEdition.privacySettings.availabilityAlumni")}
                    </label>
                    {getFormErrorMessage("name")}
                  </div>
                  <div className="field-checkbox">
                    <Controller
                      name="datasAllPrivate"
                      control={control}
                      render={({ field, fieldState }) => (
                        <Checkbox
                          inputId={field.name}
                          onChange={(e) => field.onChange(!e.checked)}
                          checked={!field.value}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                        />
                      )}
                    />
                    <label
                      htmlFor="datasAllPrivate"
                      className={classNames({ "p-error": errors.accept })}
                    >
                      {t("profilEdition.privacySettings.availabilityNoOne")}
                    </label>
                    {getFormErrorMessage("name")}
                  </div>
                  <Divider>
                    <span className="p-tag">
                      {t("profilEdition.privacySettings.newsletter")}
                    </span>
                  </Divider>
                  <div className="field-checkbox">
                    <Controller
                      name="newsLetterNotification"
                      control={control}
                      render={({ field, fieldState }) => (
                        <Checkbox
                          inputId={field.name}
                          onChange={(e) => field.onChange(e.checked)}
                          checked={field.value}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                        />
                      )}
                    />
                    <label
                      htmlFor="newsLetterNotification"
                      className={classNames({ "p-error": errors.accept })}
                    >
                      {t("profilEdition.privacySettings.newsletterSubscribe")}
                    </label>
                    {getFormErrorMessage("name")}
                  </div>
                  <Divider>
                    <span className="p-tag">
                      {t("profilEdition.privacySettings.policy")}
                    </span>
                  </Divider>

                  <div className="field-checkbox">
                    <Controller
                      name="charteSigned"
                      control={control}
                      render={({ field, fieldState }) => (
                        <Checkbox
                          inputId={field.name}
                          onChange={(e) => {
                            field.onChange(!e.checked);
                            if (e.checked) setVisibleModal(true);
                          }}
                          checked={!field.value}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                        />
                      )}
                    />
                    <label
                      htmlFor="charteSigned"
                      className={classNames({ "p-error": errors.accept })}
                    >
                      {t("profilEdition.privacySettings.leave")}
                    </label>
                    {getFormErrorMessage("name")}
                  </div>

                  <div>
                    <span
                      className="charter_link"
                      onClick={() => downloadCharter()}
                    >
                      {t("profilEdition.privacySettings.download")}
                    </span>
                  </div>

                  {visibleModal && (
                    <Modal
                      visible={visibleModal}
                      setVisible={setVisibleModal}
                      header={t("profilEdition.privacySettings.leaveHeader")}
                      width="50%"
                    >
                      <div className="modal_description_charte">
                        <form action="/action_page.php" className="form_charte">
                          {/* <label htmlFor="fname">First name:</label> */}
                          {/* <input type="text" id="fname" name="fname" value="John" /> */}
                          <label className="charte_modal">
                            {t("profilEdition.privacySettings.leaveWarning")}
                          </label>
                        </form>
                      </div>

                      <div className="boutons_modal">
                        <BtnBlanc
                          btnAction={() => setVisibleModal(false)}
                          btnTexte={t(
                            "profilEdition.privacySettings.leaveConfirm"
                          )}
                        />
                        <BtnBlanc
                          btnTexte={t(
                            "profilEdition.privacySettings.leaveCancel"
                          )}
                          btnAction={() => {
                            setVisibleModal(false);
                            setValue("charteSigned", true);
                          }}
                        />
                      </div>
                    </Modal>
                  )}

                  <div className="form_columns">
                    <BtnBleuLeft
                      btnTexte={t("profilEdition.privacySettings.save")}
                    >
                      <FaCheck />
                    </BtnBleuLeft>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

ParamConfidentialite.propTypes = {
  auth: PropTypes.object,
  admin: PropTypes.object,

  items: PropTypes.array,
  activeIndex: PropTypes.number,
  handleUpdateAuth: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  admin: state.admin,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateAuth: (value) => {
    dispatch(updateAuth(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParamConfidentialite);
