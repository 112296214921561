import { Months, Offers, Utils } from "./Const";

/**
 *
 * @param {Object[]} offersData - Résultat de la requête fetch des offres
 * @param {Object} offersData[].offerCategory - Catégorie d'une offre
 * @param {typeOfContract} offersData[].offerCategory[].type - Nom de la catégorie d'une offre
 *
 * - {@link Offers} Classe utilitaire contenant des données CONSTS pour les offres
 * @returns  données formattées sous ChartJS
 */
export function getOffersByCategory(offersData) {
  let offersByCategory = {};
  /* Création d'un tableau pour stocker le nombre d'offres par catégorie : {catégorie : nombre} */
  for (let i = 0; i < offersData.length; ++i) {
    if (!offersByCategory[offersData[i].offerCategory.type])
      offersByCategory[offersData[i].offerCategory.type] = 0;
    ++offersByCategory[offersData[i].offerCategory.type];
  }
  /* tri des clés du tableau par ordre alphabétique */
  offersByCategory = Object.keys(offersByCategory)
    .sort()
    .reduce((obj, key) => {
      obj[key] = offersByCategory[key];
      return obj;
    }, {});
  /* Formattage des données pour ChartJS */
  return {
    labels: Object.keys(offersByCategory),
    datasets: [
      {
        data: Object.values(offersByCategory),
        /* Couleur des données, stockées dans un tableau grâce à Offers */
        backgroundColor: Offers.categoriesColor,
        hoverBackgroundColor: Offers.categoriesHoverColor,
      },
    ],
  };
}

/**
 *
 * @param {Object[]} offersData Résultat de la requête fetch des offres
 *
 * - {@link Utils} Classe utilitaire contenant des données CONSTS pour les graphiques
 * @returns Options du graphique formattées pour ChartJS
 */
export function getOffersByCategoryOptions(offersData) {
  return {
    plugins: {
      /* Affichage des labels sur les catégories en pourcentage + valeur totale entre parenthèses */
      datalabels: {
        formatter: (value, ctx) => Utils.calculatePercentage(value, ctx, true),
        display: true,
        color: "#FDFDFD",
      },
      legend: {
        labels: {
          color: "#495057",
          padding: 13,
        },
        /* Génération du titre grâce à une fonction de la classe Utils */
        title: Utils.generateTitle(
          "Nombre d'offres par catégorie sur l'université A " +
            "(" +
            offersData.length +
            ")"
        ),
      },
    },
    /* Graphique responsive qui va maintenir son ratio */
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 0.9,
  };
}

export function getOffersByUniType() {
  return {
    labels: ["Diffuseurs", "Portail Universitaire", "Portail Entreprise"],
    datasets: [
      {
        data: [
          Utils.generateData(1, 500, 900).at(0),
          Utils.generateData(1, 100, 300).at(0),
          Utils.generateData(1, 200, 500).at(0),
        ],
        backgroundColor: ["#fd7f6f", "#7eb0d5", "#b2e061"],
        hoverBackgroundColor: ["#e60049", "#0bb4ff", "#50e991"],
      },
    ],
  };
}

export function getOffersByUniTypeOptions() {
  return {
    plugins: {
      datalabels: {
        formatter: (value, ctx) => Utils.calculatePercentage(value, ctx, true),
        display: true,
        color: "#FDFDFD",
      },
      legend: {
        labels: {
          color: "#495057",
          padding: 20,
        },
        title: Utils.generateTitle("Provenance des offres sur l'université A"),
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 0.9,
  };
}

export function getOffersByUniProfile() {
  return {
    labels: ["Admins", "Étudiants", "Alumni"],
    datasets: [
      {
        data: Utils.generateData(3, 80, 500),
        backgroundColor: ["#59a5d8", "#91e5f6", "#08415C"],
        hoverBackgroundColor: ["#3795D3", "#46D6F3", "#014464"],
      },
    ],
  };
}

export function getOffersByUniProfileOptions() {
  return {
    plugins: {
      datalabels: {
        formatter: (value, ctx) => Utils.calculatePercentage(value, ctx, true),
        display: true,
        color: "#FDFDFD",
      },
      legend: {
        labels: {
          color: "#495057",
          padding: 20,
        },
        title: Utils.generateTitle(
          "Provenance des offres du portail universitaire par profil sur l'université A",
          10,
          12
        ),
      },
    },
    cutout: "40%",
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 0.9,
  };
}

export function getOffersByCompByApplic() {
  return {
    labels: Months.firstSeven,
    datasets: [
      {
        label: "Nombre d'offres des entreprises",
        data: [20, 32, 62, 65, 79, 50, 42],
        fill: false,
        tension: 0.4,
        borderColor: "#3577E9",
        backgroundColor: "#FFFFFF",
        datalabels: {
          align: "start",
          offset: 12,
          color: "#3577E9",
        },
      },
      {
        label: "Nombre d'entreprises",
        data: [12, 25, 40, 46, 48, 50, 52],
        fill: false,
        borderDash: [5, 5],
        tension: 0.4,
        borderColor: "#A80874",
        backgroundColor: "#FFFFFF",
        datalabels: {
          align: "start",
          offset: 12,
          color: "#71084E",
        },
      },
      {
        label: "Nombre de candidatures aux offres des entreprises sur RP",
        data: [30, 39, 70, 58, 109, 69, 65],
        fill: true,
        borderColor: "#d6ccf9",
        tension: 0.4,
        backgroundColor: "#a8a5ec",
        datalabels: {
          align: "start",
          offset: 12,
          color: "#3d0066",
        },
      },
    ],
  };
}

export function getOffersByCompByApplicOptions() {
  return {
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
      legend: {
        labels: {
          color: "#495057",
        },
      },
      title: Utils.generateTitle("Université A"),
    },
    scales: {
      x: {
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
      y: {
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
    },
  };
}

export function getOffersByCategoryByMonth() {
  return {
    labels: Months.firstSeven,
    datasets: [
      {
        type: "bar",
        label: "Jobs étudiants",
        backgroundColor: "#fd7f6f",
        data: Utils.generateData(7, 10, 200),
      },
      {
        type: "bar",
        label: "Emplois",
        backgroundColor: "#7eb0d5",
        data: Utils.generateData(7, 10, 200),
      },
      {
        type: "bar",
        label: "Stages",
        backgroundColor: "#b2e061",
        data: Utils.generateData(7, 10, 200),
      },
      {
        type: "bar",
        label: "Alternance",
        backgroundColor: "#bd7ebe",
        data: Utils.generateData(7, 10, 200),
      },
      {
        type: "bar",
        label: "VIE-VIA",
        backgroundColor: "#ffb55a",
        data: Utils.generateData(7, 10, 200),
      },
      {
        type: "bar",
        label: "Service civique",
        backgroundColor: "#fdcce5",
        data: Utils.generateData(7, 10, 200),
      },
    ],
  };
}

export function getOffersByCategoryByMonthOptions() {
  return {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
      datalabels: {
        display: true,
        color: "#FFFFFF",
        font: {
          size: window.innerWidth > 830 ? 12 : 8,
        },
        formatter: (value, ctx) => Utils.calculatePercentage(value, ctx),
      },
      tooltips: {
        mode: "index",
        intersect: false,
      },
      legend: {
        labels: {
          color: "#495057",
        },
      },
      title: Utils.generateTitle(
        "Nombre d'offres en ligne mensuelles par catégorie dans l'université A"
      ),
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
    },
  };
}

export function getOffersByStatus() {
  return {
    labels: ["A pourvoir", "Pourvues"],
    datasets: [
      {
        data: Utils.generateData(2, 1000, 6000),
        backgroundColor: ["#fd7f6f", "#b2e061"],
        hoverBackgroundColor: ["#e60049", "#50e991"],
      },
    ],
  };
}

export function getOffersByStatusOptions() {
  return {
    plugins: {
      datalabels: {
        formatter: (value, ctx) => Utils.calculatePercentage(value, ctx),
        display: true,
        color: "#FDFDFD",
      },
      legend: {
        labels: {
          color: "#495057",
        },
      },
      title: Utils.generateTitle("Statut des offres dans l'université A"),
    },
  };
}

export function getOffersNumberByMonth() {
  return {
    labels: Months.all,
    datasets: [
      {
        data: Utils.generateData(12, 80, 400),
        backgroundColor: ["#b2e061"],
        hoverBackgroundColor: ["#50e991"],
        label: "Offres déposées",
      },
    ],
  };
}

export function getOffersNumberByMonthOptions() {
  return {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 0.5,
    plugins: {
      datalabels: {
        display: true,
        color: "#FDFDFD",
      },
      legend: {
        labels: {
          color: "#495057",
        },
      },
      title: Utils.generateTitle(
        "Nombre total d'offres déposées par mois dans l'université A"
      ),
    },
  };
}

export function getOffersViewsByMonth() {
  return {
    labels: Months.all,
    datasets: [
      {
        data: Utils.generateData(12, 400, 6000),
        backgroundColor: ["#b2e061"],
        hoverBackgroundColor: ["#50e991"],
        label: "Vues Étudiants",
      },
      {
        data: Utils.generateData(12, 200, 6000),
        backgroundColor: ["#7eb0d5"],
        hoverBackgroundColor: ["#0bb4ff"],
        label: "Vues Alumni",
      },
    ],
  };
}

export function getOffersViewsByMonthOptions() {
  return {
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
      datalabels: {
        display: true,
        color: "#FFFFFF",
        font: {
          size: window.innerWidth > 830 ? 14 : 10,
        },
        formatter: (value) => Utils.numberWithSpaces(value),
      },
      title: Utils.generateTitle(
        "Nombre de vues sur les offres RéseauPro par mois pour l'université A"
      ),
    },
  };
}

export function getOffersViewsCategoriesByMonth() {
  return {
    labels: Months.all.slice(0, 6),
    datasets: [
      {
        data: Utils.generateData(6, 400, 6000),
        borderColor: Offers.categoriesColor.at(0),
        hoverBackgroundColor: Offers.categoriesHoverColor.at(0),
        label: Offers.categories.at(0),
        tension: 0.4,
        fill: false,
      },
      {
        data: Utils.generateData(6, 400, 6000),
        borderColor: Offers.categoriesColor.at(1),
        hoverBackgroundColor: Offers.categoriesHoverColor.at(1),
        label: Offers.categories.at(1),
        tension: 0.4,
        fill: false,
      },
      {
        data: Utils.generateData(6, 400, 6000),
        borderColor: Offers.categoriesColor.at(2),
        hoverBackgroundColor: Offers.categoriesHoverColor.at(2),
        label: Offers.categories.at(2),
        tension: 0.4,
        fill: false,
      },
      {
        data: Utils.generateData(6, 400, 6000),
        borderColor: Offers.categoriesColor.at(3),
        hoverBackgroundColor: Offers.categoriesHoverColor.at(3),
        label: Offers.categories.at(3),
        tension: 0.4,
        fill: false,
      },
      {
        data: Utils.generateData(6, 400, 6000),
        borderColor: Offers.categoriesColor.at(4),
        hoverBackgroundColor: Offers.categoriesHoverColor.at(4),
        label: Offers.categories.at(4),
        tension: 0.4,
        fill: false,
      },
      {
        data: Utils.generateData(6, 400, 6000),
        borderColor: Offers.categoriesColor.at(5),
        hoverBackgroundColor: Offers.categoriesHoverColor.at(5),
        label: Offers.categories.at(5),
        tension: 0.4,
        fill: false,
      },
    ],
  };
}

export function getOffersViewsCategoriesByMonthOptions() {
  return {
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
      beforeInit(chart) {
        // Get reference to the original fit function
        const originalFit = chart.legend.fit;

        // Override the fit function
        chart.legend.fit = function fit() {
          // Call original function and bind scope in order to use `this` correctly inside it
          originalFit.bind(chart.legend)();
          // Change the height as suggested in another answers
          this.height += 15;
        };
      },
      datalabels: {
        display: false,
      },
      title: Utils.generateTitle(
        "Nombre de vues mensuelles sur les offres RéseauPro par catégorie pour l'université A"
      ),
    },
    scales: {
      x: {
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
      y: {
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
    },
  };
}

export function getOffersByDiffuserByCategoriesMonthly() {
  return {
    labels: Months.all,
    datasets: [
      {
        type: "bar",
        label: "Jobs étudiants",
        backgroundColor: "#fd7f6f",
        data: Utils.generateData(12, 20, 200),
      },
      {
        type: "bar",
        label: "Emplois",
        backgroundColor: "#7eb0d5",
        data: Utils.generateData(12, 20, 200),
      },
      {
        type: "bar",
        label: "Stages",
        backgroundColor: "#b2e061",
        data: Utils.generateData(12, 20, 200),
      },
      {
        type: "bar",
        label: "Alternance",
        backgroundColor: "#bd7ebe",
        data: Utils.generateData(12, 20, 200),
      },
      {
        type: "bar",
        label: "VIE-VIA",
        backgroundColor: "#ffb55a",
        data: Utils.generateData(12, 20, 200),
      },
      {
        type: "bar",
        label: "Service civique",
        backgroundColor: "#fdcce5",
        data: Utils.generateData(12, 20, 200),
      },
    ],
  };
}

export function getOffersByDiffuserByCategoriesMonthlyOptions(diffuser) {
  return {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
      datalabels: {
        display: true,
        color: "#FFFFFF",
        font: {
          size: window.innerWidth > 830 ? 12 : 8,
        },
        formatter: (value, ctx) => Utils.calculatePercentage(value, ctx),
      },
      tooltips: {
        mode: "index",
        intersect: false,
      },
      legend: {
        labels: {
          color: "#495057",
        },
      },
      title: Utils.generateTitle(
        "Nombre d'offres en ligne mensuelles par catégorie du diffuseur " +
          diffuser
      ),
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
    },
  };
}

export function getOffersByDiffuserByType() {
  return {
    labels: Offers.categories,
    datasets: [
      {
        data: Utils.generateData(6, 5, 60),
        backgroundColor: Offers.categoriesColor,
        hoverBackgroundColor: Offers.categoriesHoverColor,
      },
    ],
  };
}

export function getOffersByDiffuserByTypeOptions(diffuser) {
  return {
    plugins: {
      datalabels: {
        display: true,
        color: "#FFFFFF",
        font: {
          size: window.innerWidth > 830 ? 12 : 8,
        },
        formatter: (value, ctx) => Utils.calculatePercentage(value, ctx, true),
      },
      legend: {
        labels: {
          color: "#495057",
          padding: 13,
        },
        title: Utils.generateTitle(
          "Nombre d'offres par catégorie du diffuseur " + diffuser
        ),
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 0.9,
  };
}
