import React, { useEffect, useRef } from "react";
import "./EvenementsAVenir.scss";
import PropTypes from "prop-types";
import NavBarTab from "Components/NavBar/NavBarTab/NavBarTab";
import Evenement from "Components/Evenements/EvenementCard/EvenementCard";
import ProfilBar from "Components/ProfilBar/ProfilBar";
import { Link } from "react-router-dom";
import { formatDateFrenchLocale } from "Services/functions";
import { connect } from "react-redux";
import Pagination from "Components/Pagination/Pagination";
import { updatePagination } from "Redux/Actions/paginationActions";
import { useFetchGet } from "Services/api";
import { useTranslation } from "react-i18next";
import Loader from "Components/Loader/loader";

const EvenementsAVenir = (props) => {
  const eventContainerRef = useRef(null);
  const { t } = useTranslation("common");
  const myEventsData = useFetchGet(
    `/user_events/${props.auth.userConnected.id}`,
    props.auth.token
  );
  const dataCategories = useFetchGet("/event_categories", props.auth.token);

  useEffect(() => {
    //? Remise à zéro des informations de paginations pour ne pas interférer les affichages des autres pages
    return () => {
      props.handleUpdatePagination({ currentPage: 1 });
      props.handleUpdatePagination({ paginationBasicFirst: 0 });
      props.handleUpdatePagination({ paginationBasicRows: 12 });
    };
  }, []);

  return (
    <div className="dernieres_offres">
      <ProfilBar id="mobile" />
      <NavBarTab
        id="desktop"
        select="profil"
        activeIndex={props.activeIndex}
        items={props.items}
      />
      <div className="evenements_agenda__container">
        <div className="evenements_agenda__container__title">
          <h1 className="h1_trait_dessus">{t("profilItem.upcomingEvents")}</h1>
        </div>
        <div className="evenements_container">
          <div className="evenements_container__body" ref={eventContainerRef}>
            {!myEventsData.loaded ? (
              <Loader />
            ) : myEventsData.data?.length > 0 ? (
              // affichage des events filtrés
              myEventsData.data
                .filter((event) => new Date(event.endingAt) >= new Date())
                .slice(
                  props.pagination.paginationBasicFirst,
                  props.pagination.paginationBasicRows *
                    props.pagination.currentPage
                )
                .map((event, index) =>
                  // affichage des events sur la page d'accueil (avec la props nbEvents)

                  props.auth.isConnected ? (
                    <Link
                      to={{
                        pathname: `/event-detail/${event.id}`,
                        params: event.id,
                      }}
                      key={index}
                    >
                      <Evenement
                        categorie={
                          dataCategories.loaded
                            ? dataCategories.data.filter(
                                (category) =>
                                  category.id == event.category.split("/")[3]
                              )[0]
                            : ""
                        }
                        // pas en bdd donc valeur par défaut
                        inscription={event.fulfilled}
                        debutDate={
                          "Du " + formatDateFrenchLocale(event.startingAt)
                        }
                        finDate={"Au " + formatDateFrenchLocale(event.endingAt)}
                        title={event.title}
                        chapeau={event.description}
                        eventDetails={event}
                        url={event?.imageUrl ? event.imageUrl : "event1"}
                      />
                    </Link>
                  ) : (
                    ""
                  )
                )
            ) : (
              // <Loader />
              <p className="event_aucun">{t("profilItem.noUpcomingEvents")}</p>
            )}
          </div>

          <div className="evenements_container__button">
            <Pagination
              data={props.events.myEvents}
              refItemParent={eventContainerRef}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

EvenementsAVenir.propTypes = {
  items: PropTypes.object,
  activeIndex: PropTypes.number,
  auth: PropTypes.object,
  events: PropTypes.object,
  pagination: PropTypes.object,
  handleUpdatePagination: PropTypes.func,
};

const mapStateToProps = (state) => ({
  items: state.items,
  auth: state.auth,
  groups: state.groups,
  events: state.events,
  pagination: state.pagination,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdatePagination: (value) => dispatch(updatePagination(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EvenementsAVenir);
