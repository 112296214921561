import React, { useEffect, useRef } from "react";
import "./OffresSauvegardees.scss";
import PropTypes from "prop-types";
import NavBarTab from "Components/NavBar/NavBarTab/NavBarTab";
import ProfilBar from "Components/ProfilBar/ProfilBar";
import Offre from "../Offre/Offre";
import { updatePagination } from "Redux/Actions/paginationActions";
import Pagination from "Components/Pagination/Pagination";
import { connect } from "react-redux";
import { formatDate } from "Services/functions";
import { updateOffers } from "Redux/Actions/offersActions";
import { useTranslation } from "react-i18next";
import { useFetchGet } from "Services/api";
import Loader from "Components/Loader/loader";

const OffresSauvegardees = (props) => {
  const saveRef = useRef(null);
  const { t } = useTranslation("common");
  const savedOffers = useFetchGet(
    "/offers?savedByUsers=" + props.auth.userConnected.id
  );
  useEffect(() => {
    //? Remise à zéro des informations de paginations pour ne pas interférer les affichages des autres pages
    return () => {
      props.handleUpdatePagination({ currentPage: 1 });
      props.handleUpdatePagination({ paginationBasicFirst: 0 });
      props.handleUpdatePagination({ paginationBasicRows: 12 });
    };
  }, []);

  return (
    <div className="saved_offers" ref={saveRef}>
      <ProfilBar id="mobile" />
      <NavBarTab
        id="desktop"
        select="profil"
        activeIndex={props.activeIndex}
        items={props.items}
      />
      <h1 className="h1_trait_dessus">{t("profilItem.savedOffers")}</h1>

      <div className="saved_offers_container">
        {savedOffers.loaded ? (
          savedOffers.data && savedOffers.data.length > 0 ? (
            savedOffers.data
              .slice(
                props.pagination.paginationBasicFirst,
                props.pagination.paginationBasicRows *
                  props.pagination.currentPage
              )
              .map((offer, index) => (
                <Offre
                  categorie={index}
                  url={offer.imageUrl ? offer.imageUrl : "img1"}
                  offreDetails={offer}
                  date={formatDate(offer.postedAt, ".")}
                  accessibleForDisabled={offer.accessibleForDisabled}
                  key={index}
                  bookmark
                />
              ))
          ) : (
            <span>{t("profilItem.noOffersSaved")}</span>
          )
        ) : (
          <Loader></Loader>
        )}
      </div>

      <Pagination data={savedOffers.data} refItemParent={saveRef} />
    </div>
  );
};

OffresSauvegardees.propTypes = {
  items: PropTypes.array,
  activeIndex: PropTypes.number,
  offers: PropTypes.object,
  pagination: PropTypes.object,
  auth: PropTypes.object,
  handleUpdatePagination: PropTypes.func,
  handleUpdateOffers: PropTypes.func,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  pagination: state.pagination,
  offers: state.offers,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdatePagination: (value) => {
    dispatch(updatePagination(value));
  },
  handleUpdateOffers: (value) => {
    dispatch(updateOffers(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OffresSauvegardees);
