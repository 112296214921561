/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./ParametresSite.scss";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FaEye, FaEyeSlash, FaPen, FaRedo } from "react-icons/fa";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { updateAdmin } from "Redux/Actions/adminActions";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import { formatDateHourFrenchLocale } from "Services/functions";

/**
 * Permet d'afficher la page de paramétrage du site
 * Page accessible uniquement pour les administrateurs
 */
const ParametresSite = (props) => {
  const { titles, visibility } = props.admin;

  // la variable renommage sert à afficher ou non l'input de renommage
  // la variable Title permet de stoker la valeur du grand module et de l'afficher
  // la variable error permet d'afficher une erreur si l'utilisateur veut soumettre un nom de module vide
  const [renommageAccueil, setRenommageAccueil] = useState(false);
  const [errorAccueil, setErrorAccueil] = useState(false);

  const [renommageEvenements, setRenommageEvenements] = useState(false);
  const [errorEvenements, setErrorEvenements] = useState(false);

  const [renommageActus, setRenommageActus] = useState(false);
  const [errorActus, setErrorActus] = useState(false);

  const [renommageMecenat, setRenommageMecenat] = useState(false);
  const [errorMecenat, setErrorMecenat] = useState(false);

  const [renommagePartenaires, setRenommagePartenaires] = useState(false);
  const [errorPartenaires, setErrorPartenaires] = useState(false);

  // BOUTIQUE EN EVOLUTION
  // const [renommageBoutique, setRenommageBoutique] = useState(false);
  // const [errorBoutique, setErrorBoutique] = useState(false);

  const [renommageOffres, setRenommageOffres] = useState(false);
  const [errorOffres, setErrorOffres] = useState(false);

  const [renommageGroupes, setRenommageGroupes] = useState(false);
  const [errorGroupes, setErrorGroupes] = useState(false);

  const [renommageAnnuaire, setRenommageAnnuaire] = useState(false);
  const [errorAnnuaire, setErrorAnnuaire] = useState(false);

  const [renommageConseils, setRenommageConseils] = useState(false);
  const [errorConseils, setErrorConseils] = useState(false);

  const [renommageProjets, setRenommageProjets] = useState(false);
  const [errorProjets, setErrorProjets] = useState(false);

  //? Fonction permettant la réinitialisation des paramètres par défaut
  function Redo(item) {
    switch (item) {
      case "Accueil":
        props.handleAdmin({
          titles: { ...titles, accueilTitle: "Accueil" },
        });
        break;
      case "Événements":
        props.handleAdmin({
          titles: { ...titles, evenementsTitle: "Événements" },
        });
        break;
      case "Actus":
        props.handleAdmin({ titles: { ...titles, actusTitle: "Actus" } });
        break;
      case "Offres d'emploi":
        props.handleAdmin({
          titles: { ...titles, offresTitle: "Offres d'emploi" },
        });
        break;
      case "Mécénat":
        props.handleAdmin({
          titles: { ...titles, mecenatTitle: "Mécénat" },
        });
        break;
      case "Nos Partenaires":
        props.handleAdmin({
          titles: { ...titles, partenairesTitle: "Nos Partenaires" },
        });
        break;

      // BOUTIQUE EN EVOLUTION
      // case "Boutique":
      //   props.handleAdmin({
      //     titles: { ...titles, boutiqueTitle: "Boutique" },
      //   });
      //   break;

      case "Groupes":
        props.handleAdmin({ titles: { ...titles, groupesTitle: "Groupes" } });
        break;
      case "Annuaire":
        props.handleAdmin({ titles: { ...titles, annuaireTitle: "Annuaire" } });
        props.handleAdmin({
          visibility: {
            ...visibility,
            visibiliteAnnuaireEtudiant: true,
            visibiliteAnnuaire: true,
            visibiliteAnnuaireAlumni: true,
            visibiliteAnnuaireEntreprise: true,
          },
        });
        break;
      case "Conseils":
        props.handleAdmin({ titles: { ...titles, conseilsTitle: "Conseils" } });
        props.handleAdmin({
          visibility: { ...visibility, visibiliteConseils: true },
        });
        break;
      case "Projets tutorés":
        props.handleAdmin({
          titles: { ...titles, projetsTitle: "Projets tutorés" },
        });
        props.handleAdmin({
          visibility: { ...visibility, visibiliteProjets: false },
        });
        break;
      default:
        break;
    }
    updateDate();
  }

  //? Fonction gérant les erreurs des inputs vides
  function changeError(item, value) {
    switch (item) {
      case "accueilTitle":
        value === "" ? setErrorAccueil(true) : setErrorAccueil(false);
        break;
      case "evenementsTitle":
        value === "" ? setErrorEvenements(true) : setErrorEvenements(false);
        break;
      case "actusTitle":
        value === "" ? setErrorActus(true) : setErrorActus(false);
        break;
      case "mecenatTitle":
        value === "" ? setErrorMecenat(true) : setErrorMecenat(false);
        break;
      case "partenairesTitle":
        value === "" ? setErrorPartenaires(true) : setErrorPartenaires(false);
        break;
      // case "boutiqueTitle":
      //   value === "" ? setErrorBoutique(true) : setErrorBoutique(false);
      //   break;
      case "offresTitle":
        value === "" ? setErrorOffres(true) : setErrorOffres(false);
        break;
      case "groupesTitle":
        value === "" ? setErrorGroupes(true) : setErrorGroupes(false);
        break;
      case "annuaireTitle":
        value === "" ? setErrorAnnuaire(true) : setErrorAnnuaire(false);
        break;
      case "conseilsTitle":
        value === "" ? setErrorConseils(true) : setErrorConseils(false);
        break;
      case "projetsTitle":
        value === "" ? setErrorProjets(true) : setErrorProjets(false);
        break;
      default:
        break;
    }
  }
  //? MAJ de Redux pour les titres
  function handleUpdateTitles(currentEvent) {
    const name = currentEvent.name;
    const value = currentEvent.value;
    changeError(name, value);
    const newTitles = { ...titles, [name]: value };
    props.handleAdmin({
      titles: {
        ...newTitles,
      },
    });
  }
  //? Fonction permettant d'afficher les inputs de renommage
  function renommage(item) {
    switch (item) {
      case "Accueil":
        return (
          renommageAccueil && (
            <div className="parametres_site__container__content__left__item small input">
              <div className="p-inputgroup">
                <InputText
                  placeholder={titles.accueilTitle}
                  value={titles.accueilTitle}
                  name="accueilTitle"
                  onChange={(e) => handleUpdateTitles(e.target)}
                />
                <Button
                  label="OK"
                  onClick={() =>
                    props.handleAdmin({
                      titles: { ...titles, accueilTitle: "Accueil" },
                    })
                  }
                />
              </div>
            </div>
          )
        );
      case "Événements":
        return (
          renommageEvenements && (
            <div className="parametres_site__container__content__left__item small input">
              <div className="p-inputgroup">
                <InputText
                  placeholder={titles.evenementsTitle}
                  value={titles.evenementsTitle}
                  name="evenementsTitle"
                  onChange={(e) => handleUpdateTitles(e.target)}
                />
                <Button label="OK" onClick={() => changeError("Événements")} />
              </div>
            </div>
          )
        );
      case "Actus":
        return (
          renommageActus && (
            <div className="parametres_site__container__content__left__item small input">
              <div className="p-inputgroup">
                <InputText
                  placeholder={titles.actusTitle}
                  value={titles.actusTitle}
                  name="actusTitle"
                  onChange={(e) => handleUpdateTitles(e.target)}
                />
                <Button label="OK" onClick={() => changeError("Actus")} />
              </div>
            </div>
          )
        );
      case "Mecenat":
        return (
          renommageMecenat && (
            <div className="parametres_site__container__content__left__item small input">
              <div className="p-inputgroup">
                <InputText
                  placeholder={titles.mecenatTitle}
                  value={titles.mecenatTitle}
                  name="mecenatTitle"
                  onChange={(e) => handleUpdateTitles(e.target)}
                />
                <Button label="OK" onClick={() => changeError("Mécénat")} />
              </div>
            </div>
          )
        );
      case "Nos Partenaires":
        return (
          renommagePartenaires && (
            <div className="parametres_site__container__content__left__item small input">
              <div className="p-inputgroup">
                <InputText
                  placeholder={titles.partenairesTitle}
                  value={titles.partenairesTitle}
                  name="partenairesTitle"
                  onChange={(e) => handleUpdateTitles(e.target)}
                />
                <Button
                  label="OK"
                  onClick={() => changeError("Nos Partenaires")}
                />
              </div>
            </div>
          )
        );
      // case "Boutique":
      //   return (
      //     renommageBoutique && (
      //       <div className="parametres_site__container__content__left__item small input">
      //         <div className="p-inputgroup">
      //           <InputText
      //             placeholder={titles.boutiqueTitle}
      //             value={titles.boutiqueTitle}
      //             name="boutiqueTitle"
      //             onChange={(e) => handleUpdateTitles(e.target)}
      //           />
      //           <Button label="OK" onClick={() => changeError("Boutique")} />
      //         </div>
      //       </div>
      //     )
      //   );
      case "Offres d'emploi":
        return (
          renommageOffres && (
            <div className="parametres_site__container__content__left__item small input">
              <div className="p-inputgroup">
                <InputText
                  placeholder={titles.offresTitle}
                  value={titles.offresTitle}
                  name="offresTitle"
                  onChange={(e) => handleUpdateTitles(e.target)}
                />
                <Button
                  label="OK"
                  onClick={() => changeError("Offres d'emploi")}
                />
              </div>
            </div>
          )
        );
      case "Groupes":
        return (
          renommageGroupes && (
            <div className="parametres_site__container__content__left__item small input">
              <div className="p-inputgroup">
                <InputText
                  placeholder={titles.groupesTitle}
                  value={titles.groupesTitle}
                  name="groupesTitle"
                  onChange={(e) => handleUpdateTitles(e.target)}
                />
                <Button label="OK" onClick={() => changeError("Groupes")} />
              </div>
            </div>
          )
        );
      case "Annuaire":
        return (
          renommageAnnuaire && (
            <div className="parametres_site__container__content__left__item small input">
              <div className="p-inputgroup">
                <InputText
                  placeholder={titles.annuaireTitle}
                  value={titles.annuaireTitle}
                  name="annuaireTitle"
                  onChange={(e) => handleUpdateTitles(e.target)}
                />
                <Button label="OK" onClick={() => changeError("Annuaire")} />
              </div>
            </div>
          )
        );
      case "Conseils":
        return (
          renommageConseils && (
            <div className="parametres_site__container__content__left__item small input">
              <div className="p-inputgroup">
                <InputText
                  placeholder={titles.conseilsTitle}
                  value={titles.conseilsTitle}
                  name="conseilsTitle"
                  onChange={(e) => handleUpdateTitles(e.target)}
                />
                <Button label="OK" onClick={() => changeError("Conseils")} />
              </div>
            </div>
          )
        );
      case "Projets tutorés":
        return (
          renommageProjets && (
            <div className="parametres_site__container__content__left__item small input">
              <div className="p-inputgroup">
                <InputText
                  placeholder={titles.projetsTitle}
                  value={titles.projetsTitle}
                  name="projetsTitle"
                  onChange={(e) => handleUpdateTitles(e.target)}
                />
                <Button
                  label="OK"
                  onClick={() => changeError("Projets tutorés")}
                />
              </div>
            </div>
          )
        );
      default:
        break;
    }
  }
  // fonction permettant d'afficher l'erreur en dessous de l'input
  function getError(item) {
    switch (item) {
      case "Accueil":
        return (
          errorAccueil && (
            <div className="parametres_site__container__content__left__item small error">
              <p>Veuillez remplir ce champ</p>
            </div>
          )
        );
      case "Événements":
        return (
          errorEvenements && (
            <div className="parametres_site__container__content__left__item small error">
              <p>Veuillez remplir ce champ</p>
            </div>
          )
        );
      case "Actus":
        return (
          errorActus && (
            <div className="parametres_site__container__content__left__item small error">
              <p>Veuillez remplir ce champ</p>
            </div>
          )
        );
      case "Offres d'emploi":
        return (
          errorOffres && (
            <div className="parametres_site__container__content__left__item small error">
              <p>Veuillez remplir ce champ</p>
            </div>
          )
        );
      case "Groupes":
        return (
          errorGroupes && (
            <div className="parametres_site__container__content__left__item small error">
              <p>Veuillez remplir ce champ</p>
            </div>
          )
        );
      case "Annuaire":
        return (
          errorAnnuaire && (
            <div className="parametres_site__container__content__left__item small error">
              <p>Veuillez remplir ce champ</p>
            </div>
          )
        );
      case "Conseils":
        return (
          errorConseils && (
            <div className="parametres_site__container__content__left__item small error">
              <p>Veuillez remplir ce champ</p>
            </div>
          )
        );
      case "Projets tutorés":
        return (
          errorProjets && (
            <div className="parametres_site__container__content__left__item small error">
              <p>Veuillez remplir ce champ</p>
            </div>
          )
        );
      default:
        break;
    }
  }

  // --------------------------- DATE DE MODIFICATION ------------------
  function updateDate() {
    props.handleAdmin({ editedAtForTitlesAndVisibility: new Date() });
  }
  //? MAJ de Redux pour les visibilités
  function handleUpdateVisibilities(itemToUpdate) {
    const newVisibilities = {
      ...visibility,
      [itemToUpdate]: !visibility[itemToUpdate],
    };
    props.handleAdmin({
      visibility: {
        ...newVisibilities,
      },
    });
  }

  const EyesIconTrue = (item) => {
    const value = Object.values(item)[0];
    return (
      <FaEye size={"2em"} onClick={() => handleUpdateVisibilities(value)} />
    );
  };

  const EyesIconFalse = (item) => {
    const value = Object.values(item)[0];
    return (
      <FaEyeSlash
        size={"2em"}
        onClick={() => handleUpdateVisibilities(value)}
      />
    );
  };
  //TODO refermer l'ouverture de l'input de renommage si reclic sur le stylo FaPen
  return (
    <div className="parametres_site">
      {props.auth.isConnected && (
        <div className="parametres_site__container">
          <div className="parametres_site__container__title">
            <h1 className="h1_trait_dessus">
              {props.items[props.activeIndex].label}
            </h1>
            <div className="parametres_site__container__title__filtre">
              Dernière modification le{" "}
              {formatDateHourFrenchLocale(
                props.admin.editedAtForTitlesAndVisibility
              )}
            </div>
          </div>
          <div className="parametres_site__container__content">
            <div className="parametres_site__container__content__left">
              <div className="parametres_site__container__content__left__item">
                Accueil
                <div className="parametres_site__container__content__left__item__icons">
                  <FaPen
                    size={"1.5em"}
                    onClick={() => setRenommageAccueil(true)}
                  />
                  <FaRedo size={"1.5em"} onClick={() => Redo("Accueil")} />
                </div>
              </div>
              {renommage("Accueil")}
              {getError("Accueil")}
              <div className="parametres_site__container__content__left__item">
                Événements
                <div className="parametres_site__container__content__left__item__icons">
                  <FaPen
                    size={"1.5em"}
                    onClick={() => setRenommageEvenements(true)}
                  />
                  <FaRedo size={"1.5em"} onClick={() => Redo("Événements")} />
                </div>
              </div>
              {renommage("Événements")}
              {getError("Événements")}
              <div className="parametres_site__container__content__left__item">
                Actualités
                <div className="parametres_site__container__content__left__item__icons">
                  <FaPen
                    size={"1.5em"}
                    onClick={() => setRenommageActus(true)}
                  />
                  <FaRedo size={"1.5em"} onClick={() => Redo("Actus")} />
                </div>
              </div>
              {renommage("Actus")}
              {getError("Actus")}
              <div className="parametres_site__container__content__left__item">
                Offres d&apos;emploi
                <div className="parametres_site__container__content__left__item__icons">
                  <FaPen
                    size={"1.5em"}
                    onClick={() => setRenommageOffres(true)}
                  />
                  <FaRedo
                    size={"1.5em"}
                    onClick={() => Redo("Offres d'emploi")}
                  />
                </div>
              </div>
              {renommage("Offres d'emploi")}
              {getError("Offres d'emploi")}
              <div className="parametres_site__container__content__left__item">
                Mécénat
                <div className="parametres_site__container__content__left__item__icons">
                  <FaPen
                    size={"1.5em"}
                    onClick={() => setRenommageMecenat(true)}
                  />
                  <FaRedo size={"1.5em"} onClick={() => Redo("Mécénat")} />
                </div>
              </div>
              {renommage("Mécénat")}
              {getError("Mécénat")}
              <div className="parametres_site__container__content__left__item">
                Groupes
                <div className="parametres_site__container__content__left__item__icons">
                  <FaPen
                    size={"1.5em"}
                    onClick={() => setRenommageGroupes(true)}
                  />
                  <FaRedo size={"1.5em"} onClick={() => Redo("Groupes")} />
                </div>
              </div>
              {renommage("Groupes")}
              {getError("Groupes")}
              <div className="parametres_site__container__content__left__item">
                Annuaire
                <div className="parametres_site__container__content__left__item__icons">
                  {visibility.visibiliteAnnuaire ? (
                    <EyesIconTrue item={"visibiliteAnnuaire"} />
                  ) : (
                    <EyesIconFalse item={"visibiliteAnnuaire"} />
                  )}
                  <FaPen
                    size={"1.5em"}
                    onClick={() => setRenommageAnnuaire(true)}
                  />
                  <FaRedo size={"1.5em"} onClick={() => Redo("Annuaire")} />
                </div>
              </div>
              {renommage("Annuaire")}
              {getError("Annuaire")}
              <div className="parametres_site__container__content__left__item small">
                Étudiants
                <div className="parametres_site__container__content__left__item__icons">
                  {visibility.visibiliteAnnuaireEtudiant ? (
                    <EyesIconTrue item={"visibiliteAnnuaireEtudiant"} />
                  ) : (
                    <EyesIconFalse item={"visibiliteAnnuaireEtudiant"} />
                  )}
                </div>
              </div>
              <div className="parametres_site__container__content__left__item small">
                Alumni
                <div className="parametres_site__container__content__left__item__icons">
                  {visibility.visibiliteAnnuaireAlumni ? (
                    <EyesIconTrue item={"visibiliteAnnuaireAlumni"} />
                  ) : (
                    <EyesIconFalse item={"visibiliteAnnuaireAlumni"} />
                  )}
                </div>
              </div>
              <div className="parametres_site__container__content__left__item small">
                Entreprises
                <div className="parametres_site__container__content__left__item__icons">
                  {visibility.visibiliteAnnuaireEntreprise ? (
                    <EyesIconTrue item={"visibiliteAnnuaireEntreprise"} />
                  ) : (
                    <EyesIconFalse item={"visibiliteAnnuaireEntreprise"} />
                  )}
                </div>
              </div>
              <div className="parametres_site__container__content__left__item">
                Conseils
                <div className="parametres_site__container__content__left__item__icons">
                  {visibility.visibiliteConseils ? (
                    <EyesIconTrue item={"visibiliteConseils"} />
                  ) : (
                    <EyesIconFalse item={"visibiliteConseils"} />
                  )}
                  <FaPen
                    size={"1.5em"}
                    onClick={() => setRenommageConseils(true)}
                  />
                  <FaRedo size={"1.5em"} onClick={() => Redo("Conseils")} />
                </div>
              </div>
              {renommage("Conseils")}
              {getError("Conseils")}
              <div className="parametres_site__container__content__left__item">
                Projets
                <div className="parametres_site__container__content__left__item__icons">
                  {visibility.visibiliteProjets ? (
                    <EyesIconTrue item={"visibiliteProjets"} />
                  ) : (
                    <EyesIconFalse item={"visibiliteProjets"} />
                  )}
                  <FaPen
                    size={"1.5em"}
                    onClick={() => setRenommageProjets(true)}
                  />
                  <FaRedo
                    size={"1.5em"}
                    onClick={() => Redo("Projets tutorés")}
                  />
                </div>
              </div>
              {renommage("Projets tutorés")}
              {getError("Projets tutorés")}
              {/* TO DO : au clic sur le bouton, envoyer les données de redux à
              l'api */}
              <BtnBleu btnTexte="Mettre à jour" />
            </div>
            <div className="parametres_site__container__content__right">
              <div className="parametres_site__container__content__right__item">
                <FaEye size={"2em"} /> Votre module est visible dans la barre de
                navigation
              </div>
              <div className="parametres_site__container__content__right__item">
                <FaEyeSlash size={"2em"} /> Votre module est invisible dans la
                barre de navigation
              </div>
              <div className="parametres_site__container__content__right__item">
                <FaRedo size={"2em"} className="small" />
                Réinitialiser les paramètres par défaut
              </div>
              <div className="parametres_site__container__content__right__item">
                <FaPen size={"2em"} className="small" />
                Renommer mon module
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

ParametresSite.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.array,
  activeIndex: PropTypes.number,
  checked: PropTypes.bool,
  setChecked: PropTypes.func,
  admin: PropTypes.object,
  handleAdmin: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  admin: state.admin,
});

const mapDispatchToProps = (dispatch) => ({
  handleAdmin: (value) => {
    dispatch(updateAdmin(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ParametresSite);
