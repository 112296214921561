import React from "react";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import "../FooterPage.scss";
import { useTranslation } from "react-i18next";

/**
 * Page de contact du site
 * 
 * Accessible depuis le footer
 * @returns {JSX.Element}
 
 */
const Contact = () => {
  const { t } = useTranslation("common");

  return (
    <div>
      <Header />
      <div className="contactPage">
        <h1 className="h1_footerPage">{t("contact.header")}</h1>
        <div>
          <h3>{t("contact.intro")}</h3>
          <p>
            {""}
            {t("contact.email")}
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
