import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import { RECRUTEUR } from "Components/Management/ManagementButtons";
import Modal from "Components/Modal/Modal";
import { updateOffers } from "Redux/Actions/offersActions";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import "./MassActions.scss";

const MassActions = (props) => {
  const [massActionConfirmation, setMassActionConfirmation] = useState({
    visible: false,
    type: "",
    action: "",
  });
  // En attente du back pour le codage de cette fonction
  const massActionsFunction = (action) => {
    setMassActionConfirmation({ visible: false, type: "", action: "" });

    if (!props.offers?.currentTransmitter?.length) return;
    let url = `${process.env.REACT_APP_BASE_URL_API}/offers/${action}/multiple`;
    if (props.offers.currentTransmitter[0] === RECRUTEUR) {
      url += "/recruiter";
    }
    url += "?ids=";
    props.offers.dataFromPagination.data.forEach(
      (offer) => (url = url.concat(`${offer.id},`))
    );
    let data = {};
    if (action === "refuse")
      data = {
        feedback: "Votre offre a été refusée par un administrateur du portail",
      };
    axios
      .post(url, data, {
        headers: props.auth.token && {
          Authorization: `Bearer ${props.auth.token}`,
        },
      })
      .then(() => {
        let tempData = props.offers.dataFromPagination;
        tempData.data = [];
        props.handleUpdateOffers({ dataFromPagination: tempData });
      })
      // Si tout s'est bien passé, on reset le formulaire
      .finally(() => {});
  };
  return (
    <div className="mass_actions_buttons">
      <BtnBlanc
        btnAction={() =>
          setMassActionConfirmation({
            visible: true,
            type: "Accepter",
            action: "validate",
          })
        }
      >
        Accepter tout
      </BtnBlanc>
      <BtnBlanc
        btnAction={() =>
          setMassActionConfirmation({
            visible: true,
            type: "Refuser",
            action: "refuse",
          })
        }
      >
        Refuser tout
      </BtnBlanc>
      <BtnBlanc
        btnAction={() =>
          setMassActionConfirmation({
            visible: true,
            type: "Archiver",
            action: "archive",
          })
        }
      >
        Archiver tout
      </BtnBlanc>
      <div className="mass_actions_buttons_numberOffer">
        <span className="mass_actions_buttons_numberOffer_text">
          Nombre d&apos;offres :
        </span>
        <Dropdown
          value={props.itemsPerPage}
          className="membership_input_search"
          options={[20, 50, 100, 200]}
          onChange={(e) => {
            props.setItemsPerPage(e.target.value);
            if (!props.offers?.currentTransmitter?.length) return;
            let url = "";
            if (props.offers.currentTransmitter[0] === RECRUTEUR) {
              url = new URL(
                `${process.env.REACT_APP_BASE_URL_API}/offers/recruiter`
              );
            } else {
              url = new URL(`${process.env.REACT_APP_BASE_URL_API}/offers`);
            }
            url.search = props.url.search;
            url.searchParams.set("itemsPerPage", e.target.value);
            props.setUrl(url);
          }}
        />
      </div>

      {massActionConfirmation.visible && (
        <Modal
          visible={massActionConfirmation.visible}
          setVisible={() =>
            setMassActionConfirmation({ visible: false, type: "", action: "" })
          }
          header={`${massActionConfirmation.type} tout`}
        >
          <div className="mass_actions_buttons_confirmation">
            <span>
              Etes vous sur de vouloir{" "}
              {massActionConfirmation.type.toLowerCase()} toutes les offres
              visibles?
            </span>
            <BtnBleu
              btnAction={() =>
                massActionsFunction(massActionConfirmation.action)
              }
            >
              Confirmer
            </BtnBleu>
          </div>
        </Modal>
      )}
    </div>
  );
};

MassActions.propTypes = {
  auth: PropTypes.object,
  offers: PropTypes.object,
  handleUpdateOffers: PropTypes.func,
  itemsPerPage: PropTypes.number,
  setItemsPerPage: PropTypes.func,
  url: PropTypes.string,
  setUrl: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  offers: state.offers,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateOffers: (value) => {
    dispatch(updateOffers(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MassActions);
