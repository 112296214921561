import React, { useEffect, useRef, useState } from "react";
import Doughnut from "../Charts/Doughnut";
import Margin from "../Margin";
import Line from "../Charts/Line";
import Bar from "../Charts/Bar";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";

import {
  getCompaniesAndOffersByMonth,
  getCompaniesAndOffersByMonthOptions,
  getCompaniesAndTheirOffers,
  getCompaniesAndTheirOffersOptions,
  getOffersInACompany,
  getOffersInACompanyOptions,
} from "../Data/Entreprises";
import {
  getOffersByCompByApplic,
  getOffersByCompByApplicOptions,
} from "../Data/Offres";
import CompaniesTable from "../Tables/CompaniesTable";

/**
 * Composant Entreprises sert à afficher les graphiques de la partie entreprises
 * à l'aide  de primeReact et de composants custom.
 *
 * Les données ainsi ques les options proviennent du dossier Data, et sont
 * stockées grâce aux hooks {@link useState}.
 *
 * Les composants custom utilisés :
 * - {@link Line},
 * - {@link Bar},
 * - {@link Doughnut}
 */
const Entreprises = () => {
  const [selectedCompany, setSelectedCompany] = useState("Entreprise 1");
  const op = useRef();

  const [offersInACompany] = useState(getOffersInACompany());
  const [offersInACompanyOptions, setOffersInACompanyOptions] = useState(
    getOffersInACompanyOptions(selectedCompany)
  );

  const [companiesAndTheirOffersData] = useState(getCompaniesAndTheirOffers());
  const [companiesAndTheirOffersOptions] = useState(
    getCompaniesAndTheirOffersOptions()
  );

  const [offerCompApplicData] = useState(getOffersByCompByApplic());
  const [offerCompApplicDataOptions] = useState(
    getOffersByCompByApplicOptions()
  );

  const [companiesAndOffersByMonthData] = useState(
    getCompaniesAndOffersByMonth()
  );
  const [companiesAndOffersByMonthOptions] = useState(
    getCompaniesAndOffersByMonthOptions()
  );

  useEffect(() => {
    setOffersInACompanyOptions(
      getOffersInACompanyOptions(selectedCompany.toLowerCase())
    );
  }, [selectedCompany]);

  const handleClick = (e) => {
    console.log(e);
    if (e.target) setSelectedCompany(e.target.outerText);
    op.current.hide();
  };

  return (
    <div className="stats_container">
      <CompaniesTable />
      <Margin position="bottom" size="" />
      <div className="stats_container__component-center">
        <Doughnut
          data={companiesAndTheirOffersData}
          options={companiesAndTheirOffersOptions}
        />
      </div>
      <Margin position="bottom" size="" />

      <Button
        type="button"
        label={selectedCompany}
        onClick={(e) => op.current.toggle(e)}
      />
      <OverlayPanel ref={op}>
        <Button id="1" label="Entreprise 1" onClick={handleClick} />
        <Button id="2" label="Entreprise 2" onClick={handleClick} />
        <Button id="3" label="Entreprise 3" onClick={handleClick} />
        <Button id="4" label="Entreprise 4" onClick={handleClick} />
        <Button id="5" label="Entreprise 5" onClick={handleClick} />
      </OverlayPanel>
      <Margin position="bottom" size="xs" />
      <div className="stats_container__component-center">
        <Doughnut data={offersInACompany} options={offersInACompanyOptions} />
      </div>
      <Margin position="bottom" size="" />
      <Bar
        data={companiesAndOffersByMonthData}
        options={companiesAndOffersByMonthOptions}
      />
      <Margin position="bottom" size="" />
      <Line data={offerCompApplicData} options={offerCompApplicDataOptions} />
      <Margin position="bottom" size="s" />
    </div>
  );
};

export default Entreprises;
