/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./SearchContainer.scss";
import PropTypes from "prop-types";
import { Dropdown } from "primereact/dropdown";
import { FaAngleRight } from "react-icons/fa";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import { HiSearch } from "react-icons/hi";
import { InputText } from "primereact/inputtext";
import { connect } from "react-redux";
import axios from "axios";
import avatar1 from "assets/avatar2.webp";
import avatar2 from "assets/avatar3.webp";
import avatar3 from "assets/avatar4.webp";
import avatar4 from "assets/avatar5.webp";
import avatar5 from "assets/avatar6.webp";
import avatar6 from "assets/AvatarFemme.webp";
import { updateUsers } from "Redux/Actions/usersActions";
import { useTranslation } from "react-i18next";
import { authenticationRedirection } from "Services/redirections";

//? Composant situé au milieu de la carte permettant de recherche des alumnis
const SearchContainer = (props) => {
  const { t } = useTranslation("common");
  const [isVisibleCity, setIsVisibleCity] = useState(false);
  const [listOfCity, setListOfCity] = useState([]);
  const [listOfCoor, setListOfCoor] = useState([]);
  const [listOfBounds, setListOfBounds] = useState([]);
  const [coordinate, setCoordinate] = useState();

  const avatars = [avatar1, avatar2, avatar3, avatar4, avatar5, avatar6];
  const getCity = (city) => {
    let listOfCities = [];
    let listOfCoorArray = [];
    let listOfBoundsArray = [];
    axios
      .get(`https://nominatim.openstreetmap.org/search?q=${city}&format=json`)
      .then((res) => {
        res.data.forEach((city) => listOfCities.push([city.display_name]));
        res.data.forEach((coor) =>
          listOfCoorArray.push([parseFloat(coor.lat), parseFloat(coor.lon)])
        );
        res.data.forEach((coor) =>
          listOfBoundsArray.push([
            [
              Number(coor.boundingbox[0]) - 0.5,
              Number(coor.boundingbox[2]) - 1.5,
            ],
            [
              Number(coor.boundingbox[1]) + 0.5,
              Number(coor.boundingbox[3]) + 1.5,
            ],
          ])
        );
        setListOfCity(listOfCities);
        setListOfCoor(listOfCoorArray);
        setListOfBounds(listOfBoundsArray);
      });
  };

  return (
    <div className="searchContainer">
      <h3 className="searchContainer__title">
        {t("mapHorsConnexion.container_rechercher")}
      </h3>
      <h4 className="searchContainer__subtitle">
        {t("mapHorsConnexion.container_echanger")}{" "}
      </h4>
      <div className="searchContainer__avatars">
        {avatars.map((avatar, index) => (
          <Avatar srcImg={avatar} key={index} />
        ))}
      </div>
      <div className="searchContainer__sentences">
        <p className="searchContainer__sentences__sentence">
          <FaAngleRight />
          {t("mapHorsConnexion.container_pays")}
        </p>
        <p className="searchContainer__sentences__sentence">
          <FaAngleRight /> {t("mapHorsConnexion.container_groupe")}
        </p>
      </div>

      <div className="searchContainer__btns">
        {props.auth.userConnected == null ? (
          <>
            <BtnBleu btnAction={() => authenticationRedirection()}>
              {t("mapHorsConnexion.rechercher_alumni")}{" "}
            </BtnBleu>
            <BtnBlanc btnAction={() => authenticationRedirection()}>
              {t("mapHorsConnexion.acceder_annuaire")}{" "}
            </BtnBlanc>
          </>
        ) : (
          <div className="searchContainer__btns__inputs">
            <div className="searchContainer__btns__inputs__radios">
              <label className="searchContainer__btns__inputs__radios__mainLabel">
                {t("mapHorsConnexion.container_rechercherPar")}{" "}
              </label>
              <label htmlFor="surname">
                <input
                  type="radio"
                  id="surname"
                  name="typeSearch"
                  value="surname"
                  onChange={(e) => {
                    props.setIsSearch(false);
                    props.setSearchRadioValue(e.target.value);
                    props.setSearchInputValue("");
                  }}
                  checked={props.searchRadioValue === "surname"}
                />
                {t("mapHorsConnexion.container_nom")}
              </label>
              <label htmlFor="domain">
                <input
                  type="radio"
                  id="domain"
                  name="typeSearch"
                  value="domain"
                  onChange={(e) => {
                    props.setSearchRadioValue(e.target.value);
                    props.setSearchInputValue("");
                    props.setIsSearch(false);
                  }}
                  checked={props.searchRadioValue === "domain"}
                />
                {t("mapHorsConnexion.container_formation")}{" "}
              </label>
              <label htmlFor="Ville">
                <input
                  type="radio"
                  id="Ville"
                  name="typeSearch"
                  value="Ville"
                  onChange={(e) => {
                    props.setSearchRadioValue(e.target.value);
                    props.setSearchInputValue("");
                    props.setIsSearch(false);
                  }}
                  checked={props.searchRadioValue === "Ville"}
                />
                {t("mapHorsConnexion.container_ville")}{" "}
              </label>
            </div>
            <span className="searchContainer__btns__finder p-input-icon-left">
              {props.searchRadioValue === "domain" ? (
                <Dropdown
                  value={props.searchInputValue}
                  options={props.domainsData}
                  onChange={
                    (e) => props.setSearchInputValue(e.value)
                    // props.setIsSearch(false);
                  }
                  optionLabel="label"
                  placeholder={t("mapHorsConnexion.selection_formation")}
                />
              ) : (
                <>
                  <HiSearch className="searchContainer__btns__icon" />
                  <InputText
                    placeholder={`Rechercher par ${
                      props.searchRadioValue === "surname" ? "nom" : "ville"
                    }`}
                    value={props.searchInputValue}
                    onChange={(e) => {
                      props.setSearchInputValue(e.target.value);
                      props.setIsSearch(false);
                      if (props.searchRadioValue === "Ville") {
                        if (e.target.value.length > 2) {
                          setIsVisibleCity(true);
                          getCity(e.target.value);
                        } else {
                          setIsVisibleCity(false);
                        }
                      }
                    }}
                    className="searchContainer__btns__input"
                  />
                </>
              )}
              {isVisibleCity && (
                <div className="searchContainer__btns__finder__listOfCities">
                  {listOfCity.map((city, key) => (
                    <div
                      className="searchContainer__btns__finder__listOfCities__searchedCity"
                      key={key}
                      onClick={() => {
                        props.setSearchInputValue(city);
                        setCoordinate([listOfCoor[key], listOfBounds[key]]);
                        setIsVisibleCity(false);
                      }}
                    >
                      <p className="searchContainer__btns__finder__listOfCities__searchedCity__city">
                        {city[0]}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </span>
            <div className="searchContainer__btns__inputs__searchAndRedo">
              <BtnBlanc
                btnAction={() => {
                  props.setIsSearch(true);
                  if (
                    props.searchRadioValue === "Ville" &&
                    props.searchInputValue !== ""
                  ) {
                    props.setCenterPosition(coordinate[0]);
                    props.handleUpdateUsers({
                      mapBounds: coordinate[1],
                    });
                  }
                }}
                btnTexte={t("mapHorsConnexion.container_btnRechercher")}
              />
              <BtnBlanc
                btnAction={() => {
                  props.setIsSearch(true);
                  props.handleUpdateUsers({
                    mapBounds: [
                      [-44, -206],
                      [83, 212],
                    ],
                  });
                }}
                btnTexte={t("mapHorsConnexion.container_btnAnnuler")}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Avatar = ({ srcImg }) => {
  return (
    <img
      src={srcImg}
      className="searchContainer__avatars__avatar"
      alt="Avatar de l'étudiant"
      title="Avatar de l'étudiant"
    />
  );
};

SearchContainer.propTypes = {
  auth: PropTypes.object,
  domainsData: PropTypes.array,
  setCenterPosition: PropTypes.func,
  setIsSearch: PropTypes.func,
  searchInputValue: PropTypes.string,
  setSearchInputValue: PropTypes.func,
  setSearchRadioValue: PropTypes.func,
  searchRadioValue: PropTypes.string,
  handleSearch: PropTypes.func,
  handleUpdateUsers: PropTypes.func,
};

Avatar.propTypes = {
  srcImg: PropTypes.string,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateUsers: (value) => {
    dispatch(updateUsers(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchContainer);
