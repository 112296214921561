import { UPDATE_I18N } from "Redux/Actions/i18nActions";

const INITIAL_STATE = {
  language: "fr",
};

const i18nReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_I18N: {
      return {
        ...state,
        ...action.value,
      };
    }
    default:
      return state;
  }
};

export default i18nReducer;
