import React, { useEffect, useMemo, useState } from "react";
import "./Partenaires.scss";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import { Link } from "react-router-dom";
import EntreprisesCard from "../../Components/Annuaire/AnnuaireEntreprise/EntrepriseCard/EntrepriseCard";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import parse from "html-react-parser";
import { InputText } from "primereact/inputtext";
import { HiSearch } from "react-icons/hi";
import HeaderUserLogin from "Components/Header/HeaderTop/HeaderUserLogin/HeaderUserLogin";
import { updatePagination } from "Redux/Actions/paginationActions";
import { useRef } from "react";
import ButtonsBarDisconnected from "Components/Header/ButtonsBarDisconnected/ButtonsBarDisconnected";
import { useFetchGetPagination } from "Services/api";
import TempPagination from "Components/Pagination/TempPagination";
import { useTranslation } from "react-i18next";
import { findItemInEditManagement } from "Services/functions";
// import { authenticationRedirection } from "Services/redirections";
import CreationCompteEntreprise from "Components/Modal/ModalCreationCompteEntreprise/CreationCompteEntreprise";
import Modal from "Components/Modal/Modal";
import { authenticationRedirection } from "Services/redirections";

// TO DO : intégrer les partenaires Réseau Pro
const Partenaires = (props) => {
  const { t } = useTranslation("common");

  const refCompany = useRef(null);
  const [filterSearchInput, setFilterSearchInput] = useState("");
  const [urlCompany, setUrlCompany] = useState("");
  const companiesQuery = useFetchGetPagination(urlCompany);

  useEffect(() => {
    setUrlCompany(new URL(`${process.env.REACT_APP_BASE_URL_API}/companies`));
  }, []);

  const [loginVisible, setLoginVisible] = useState(false);
  const [creationCompteVisible, setCreationCompteVisible] = useState(false);
  const [clickedCompanyId, setClickedCompanyId] = useState(null);
  const [joinRP, setJoinRP] = useState(null);

  useMemo(() => {
    setJoinRP(
      findItemInEditManagement("rejoindreRP", props.admin.editManagement)
    );
  }, [props.admin.editManagement]);

  const companiesFiltered = () => {
    let url = new URL(`${process.env.REACT_APP_BASE_URL_API}/companies`);
    url.searchParams.set("keyword", filterSearchInput);
    setUrlCompany(url);
  };

  return (
    <div className="universites">
      <Header>
        <ButtonsBarDisconnected />
      </Header>
      {!props.auth.isConnected && (
        <div className="universites__container">
          <div className="universites__container__top">
            <div className="universites__container__top__title">
              <h1 className="h1_trait_dessus">
                {t("partenairesHorsConnexion.title_page")}{" "}
              </h1>
            </div>

            <div>
              {props.i18n.language === "en" ? (
                parse(t("partenairesHorsConnexion.content"))
              ) : joinRP ? (
                parse(joinRP)
              ) : (
                <div className="universites__container__top__description">
                  <span>{t("partenairesHorsConnexion.description_page")}</span>
                  <ul>
                    <li>{t("partenairesHorsConnexion.createAccount")}</li>

                    <li>{t("partenairesHorsConnexion.creationForm")}</li>

                    <li>{t("partenairesHorsConnexion.validationRequest")}</li>

                    <li>{t("partenairesHorsConnexion.confirmation")}</li>
                  </ul>
                </div>
              )}
            </div>
            <div className="universites__container__top__boutons">
              <BtnBleu
                btnTexte={t("partenairesHorsConnexion.connectButton")}
                btnAction={(e) => {
                  e.preventDefault();
                  //! Redirection vers l'ancien portail tant que la connexion avec un compte entreprise n'est pas implémentée
                  window.location.href =
                    "http://reseaupro.unpidf.fr/index.php/user/login";
                  // if (!authenticationRedirection()) {
                  //   setClickedCompanyId(null);
                  //   setLoginVisible(true);
                  // }
                }}
              />
              <BtnBlanc
                btnTexte={t("partenairesHorsConnexion.createButton")}
                btnAction={(e) => {
                  e.preventDefault();
                  //! Tant que la création de compte entreprise n'est pas implémentée, redirection vers l'ancien portail
                  window.location.href =
                    "http://reseaupro.unpidf.fr/index.php/inscription/partenaires";
                  // setCreationCompteVisible(true);
                }}
              />
            </div>
          </div>
          <div className="universites__container__bottom">
            <div className="universites__container__bottom__title">
              <h1 className="h1_trait_dessus">
                {t("partenairesHorsConnexion.entreprise")}
              </h1>
              <span className="container_input_entreprises">
                <HiSearch className="search_icon_entreprises" />
                <InputText
                  placeholder={t("partenairesHorsConnexion.findButton")}
                  className="company_search"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      companiesFiltered();
                    }
                  }}
                  onChange={(e) => {
                    setFilterSearchInput(e.target.value.toLowerCase());
                  }}
                />
              </span>
            </div>

            <div
              className="universites__container__bottom__entreprises"
              ref={refCompany}
            >
              {companiesQuery.loaded &&
                companiesQuery?.data?.length > 0 &&
                companiesQuery.data.map((company, index) => (
                  <React.Fragment key={company.id}>
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        if (!authenticationRedirection()) {
                          setClickedCompanyId(company.id);
                          setLoginVisible(true);
                        }
                      }}
                      to={{
                        pathname: `/annuaire/detail/${company.id}`,
                      }}
                    >
                      <EntreprisesCard
                        key={index}
                        nomEntreprise={company.name}
                        type={
                          company.structure
                            ? company.structure.label
                            : company.type.label
                        }
                        imageLogo={"entreprise2"}
                        imageFond={"entrepriseFond2"}
                        taille={
                          company.totalStaff
                            ? company.totalStaff.label
                            : "Non renseigné"
                        }
                        offresDisponibles={true}
                        companyId={company.id}
                        isPartner={company.isPartner}
                      />
                    </Link>
                  </React.Fragment>
                ))}
            </div>
            <TempPagination
              result={companiesQuery}
              refItemParent={refCompany}
              rows={50}
            />
          </div>
        </div>
      )}
      {loginVisible && (
        <HeaderUserLogin
          key={loginVisible + clickedCompanyId}
          loginVisible={loginVisible}
          setLoginVisible={setLoginVisible}
          redirect={
            clickedCompanyId ? `/annuaire/detail/${clickedCompanyId}` : null
          }
        />
      )}
      {creationCompteVisible && (
        <Modal
          setVisible={setCreationCompteVisible}
          visible={creationCompteVisible}
          header={t("header.compte_creation")}
        >
          <CreationCompteEntreprise
            setVisibleModal={setCreationCompteVisible}
            visibleModal={creationCompteVisible}
          />
        </Modal>
      )}
      <Footer></Footer>
    </div>
  );
};
Partenaires.propTypes = {
  auth: PropTypes.object,
  admin: PropTypes.array,
  rejoindreRP: PropTypes.string,
  pagination: PropTypes.object,
  handleUpdatePagination: PropTypes.func,
  companies: PropTypes.object,
  i18n: PropTypes.object,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  rejoindreRP: state.admin.editManagement.rejoindreRP,
  admin: state.admin,
  pagination: state.pagination,
  companies: state.companies,
  i18n: state.i18n,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdatePagination: (value) => {
    dispatch(updatePagination(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Partenaires);
