import React from "react";
import PropTypes from "prop-types";
import "./headerTop.scss";
import HeaderUserConnected from "./HeaderUserConnected/HeaderUserConnected";
import HeaderUserDisconnected from "./HeaderUserDisconnected/headerUserDisconnected";
// import logo_unif from "assets/logo_unif.webp";
import { connect } from "react-redux";
const HeaderTop = (props) => {
  const API_LINK = process.env.REACT_APP_BASE_URL_API.split("/api")[0];
  return (
    <div className="header__bandeau">
      <img
        className="logo_top"
        src={
          API_LINK +
          "/media/logos_univs/logo_pantheon_sorbonne_transparent_couleur.webp"
        }
        onClick={() =>
          window.open("https://www.pantheonsorbonne.fr/", "_blank")
        }
        alt="Logo Panthéon Sorbonne"
        title="Logo Panthéon Sorbonne"
      />
      {props.auth.isConnected ? (
        <HeaderUserConnected />
      ) : (
        <HeaderUserDisconnected />
      )}
    </div>
  );
};
HeaderTop.propTypes = {
  auth: PropTypes.object,
  handleAuth: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderTop);
