import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import { Menu } from "primereact/menu";
import React, { useRef, useState } from "react";
import { FaEllipsisH } from "react-icons/fa";
import "./GroupeCardPublication.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Loader from "Components/Loader/loader";
import GroupeCardCommentaire from "../GroupeCardCommentaire/GroupeCardCommentaire";
import { InputTextarea } from "primereact/inputtextarea";
import { Controller, useForm } from "react-hook-form";
import { updateGroups } from "Redux/Actions/groupsActions";
import axios from "axios";
import { fetchGet, useFetchGetPagination } from "Services/api";
import { Button } from "primereact/button";
import neutralUser from "assets/neutralUser.png";
import { useEffect } from "react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

const GroupeCardPublication = (props) => {
  const { t } = useTranslation("common");
  const [commentairesURL, setCommentairesURL] = useState(undefined);
  const commentairesQuery = useFetchGetPagination(
    commentairesURL,
    props.auth.token
  );
  const [commentaires, setCommentaires] = useState([]);
  const [voirPlus, setVoirPlus] = useState({
    state: false,
    amount: 3,
  });

  const [publication, setPublication] = useState(props.publication);

  // Si le commentaire est en cours de création ou non
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      content: "",
      publication: undefined,
    },
  });

  const handleDisableComments = async () => {
    props.setDisableComments({
      state: true,
      id: publication.id,
    });
  };

  /**
   * Fonction qui permet d'envoyer une requête post pour créer un commentaire
   * @param {{content: string, publication: string}} data données du formulaire
   */
  const postComment = (data) => {
    let response = undefined;
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL_API}${"/publication_comments"}`,
        data,
        {
          headers: props.auth.token
            ? {
                accept: "application/json",
                Authorization: `Bearer ${props.auth.token}`,
              }
            : {
                accept: "application/json",
              },
        }
      )
      .then((res) => (response = res))
      .catch((error) => {
        props.auth.toast.current.show({
          severity: "error",
          summary: error.response?.data["hydra:title"] || "Erreur",
          detail:
            error.response?.data["hydra:description"] ||
            "Une erreur est survenue lors de la publication du commentaire",
          life: 5000,
        });
      })
      .finally(async () => {
        if (response) {
          reset();
          await reloadPublication();
          props.auth.toast.current.show({
            severity: "success",
            summary: "Commentaire publié",
            detail: "Votre commentaire a bien été publié",
            life: 5000,
          });
        }
        setIsLoading(false);
      });
  };

  const reloadPublication = async () => {
    const response = await fetchGet(
      `/publications/${publication.id}`,
      props.auth.token
    );
    if (response) {
      setPublication(response);
    }
  };

  /**
   *  Fonction qui va remplir la propriété publication avec la publication actuelle
   * @param {{content: string, publication: string}} data données du formulaire
   * @returns données du formulaire avec champ sourceGroup renseigné
   */
  const assignPublication = (data) => {
    data = {
      ...data,
      publication: "/api/publications/" + publication.id.toString(),
    };
    return data;
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    data = assignPublication(data);
    postComment(data);
  };

  /**
   * Afffichage d'une erreur si nécessaire dans le formulaire
   * @param {String} name nom du champ
   * @returns {FieldError, JSX.Element}
   */
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <small
          className="p-error"
          style={{ display: "grid", textAlign: "center" }}
        >
          {errors[name].message}
        </small>
      )
    );
  };

  const menuTop = useRef(null);
  let adminMenu = [
    {
      label: t("groupPublication.modals.delete"),
      command: () =>
        props.setDeleteAction({
          id: publication.id,
          type: "publication",
          state: true,
          visible: true,
        }),
    },
    { separator: true },
    {
      label: t("groupPublication.modals.disableComments"),
      command: () => handleDisableComments(),
    },
  ];

  let ownerMenu = [
    {
      label: t("groupPublication.modals.edit"),
      command: () =>
        props.setEditPublication({
          id: publication.id,
          state: true,
          visible: true,
          content: publication.content,
        }),
    },
    { separator: true },
    {
      label: t("groupPublication.modals.delete"),
      command: () =>
        props.setDeleteAction({
          id: publication.id,
          type: "publication",
          state: true,
          visible: true,
        }),
    },
    { separator: true },
    {
      label: t("groupPublication.modals.disableComments"),
      command: () => handleDisableComments(),
    },
  ];

  const userMenu = [
    {
      label: t("groupPublication.modals.report"),
      command: () =>
        props.setReportAction({
          id: publication.id,
          type: "publication",
          state: true,
          visible: true,
        }),
    },
  ];

  // EN fonction de si les commentaires sont déjà désactivés ou non, on affiche ou non les deux derniers éléments des menus
  adminMenu =
    publication && publication.availableForComments
      ? adminMenu
      : adminMenu.splice(0, adminMenu.length - 2);
  ownerMenu =
    publication && publication.availableForComments
      ? ownerMenu
      : ownerMenu.splice(0, ownerMenu.length - 2);

  function makeCommentsUrl(id) {
    let url = process.env.REACT_APP_BASE_URL_API;
    url += "/publication_comments";
    url = new URL(url);
    url.searchParams.append("publication", id);
    url.searchParams.append("order[createdAt]", "ASC");
    url.searchParams.append("itemsPerPage", 90);
    setCommentairesURL(url);
  }

  async function downloadFile(url, token) {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          accept: "application/json",
        },
        responseType: "blob",
      })
      .then((res) => {
        const blobUrl = URL.createObjectURL(res.data);
        const link = document.createElement("a");
        link.download = publication.document.slice(
          publication.document.lastIndexOf("/") + 1
        );
        link.href = blobUrl;
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
          URL.revokeObjectURL(blobUrl);
          document.body.removeChild(link);
        }, 100);
      })
      // Si il y a une erreur, on l'affiche dans la console
      .catch((error) => {
        console.log(error.message);
      });
  }

  // On crée l'url des commentaires à chaque fois que la publication change
  useEffect(async () => {
    if (publication?.id) {
      makeCommentsUrl(publication.id);
    }
  }, [publication]);

  // On récupère les commentaires à chaque fois que la query change
  useEffect(() => {
    if (commentairesQuery.loaded && commentairesQuery.data) {
      setCommentaires(commentairesQuery.data);
    }
  }, [commentairesQuery]);

  return (
    <>
      <div className="groupe_comment">
        {publication ? (
          <>
            <div className="groupe_comment__top">
              <div className="groupe_comment__top__container">
                <div className="groupe_comment__top__container__user">
                  <img
                    src={
                      publication.user.imageUrl
                        ? publication.user.imageUrl
                        : neutralUser
                    }
                    alt="Avatar"
                    title="Avatar"
                  />
                  <h5>
                    {`${publication.user.firstname} ${publication.user.surname}`}
                    <span className="groupe_comment__top__container__user-date">
                      {new Intl.DateTimeFormat(
                        props.i18n.language === "en" ? "en-GB" : "fr-FR",
                        {
                          year: "numeric",
                          month: "long",
                          day: "2-digit",
                          hour: "numeric",
                          minute: "numeric",
                        }
                      ).format(
                        DateTime.fromISO(publication.createdAt).toJSDate()
                      )}
                      {"  "}
                      {publication.updatedAt
                        ? t("groupPublication.edited")
                        : ""}
                    </span>
                  </h5>
                </div>
                {!props.finder && (
                  <div className="groupe_comment__top__container__right">
                    <Menu
                      appendTo={document.getElementById("app")}
                      // Affichage du menu en fonction du rôle de l'utilisateur
                      model={
                        // Vérification si l'utilisateur n'est pas le créateur de la publication
                        publication.user.id != props.auth.userConnected.id
                          ? // Si oui, vérification si l'utilisateur est un administrateur ou non
                            props.auth.isAdmin || props.isGroupAdmin
                            ? adminMenu
                            : userMenu
                          : ownerMenu
                      }
                      popup
                      ref={menuTop}
                      style={{ textAlign: "left" }}
                    />
                    <BtnBlanc
                      btnAction={(event) => menuTop.current.toggle(event)}
                    >
                      <FaEllipsisH size={"1.5rem"} />
                    </BtnBlanc>
                  </div>
                )}
              </div>
              {publication?.content}
              {publication?.document && !props.finder && (
                <div className="groupe_comment__top__file">
                  <div
                    className="groupe_comment__top__file-link"
                    onClick={async () =>
                      await downloadFile(
                        `${process.env.REACT_APP_BASE_URL_API}/publications/${publication.id}/download`,
                        props.auth.token
                      )
                    }
                  >
                    {/* On récupère le nom du fichier dans l'url */}
                    <strong>
                      {publication.document.slice(
                        publication.document.lastIndexOf("/") + 1
                      )}
                    </strong>
                  </div>
                </div>
              )}
            </div>
            {publication.availableForComments && !props.finder && (
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="groupe_comment__form"
              >
                <div className="groupe_comment__bottom">
                  <img
                    src={
                      props.auth.userConnected.imageUrl
                        ? props.auth.userConnected.imageUrl
                        : neutralUser
                    }
                    className="groupe_comment__bottom-avatar"
                    alt="Mon avatar"
                    title="Mon avatar"
                  />
                  <Controller
                    name="content"
                    control={control}
                    rules={{
                      required: t("groupPublication.comment.required"),
                    }}
                    render={({ field }) => (
                      <InputTextarea
                        {...field}
                        className="groupe_comment__bottom-message"
                        placeholder={t("groupPublication.comment.placeholder")}
                        rows={10}
                        cols={30}
                        autoResize
                      />
                    )}
                  />
                  <Button
                    type="submit"
                    loading={isLoading}
                    className="groupe_comment__bottom-button"
                  >
                    {isLoading ? "" : t("groupPublication.publish")}
                  </Button>
                </div>
                {getFormErrorMessage("content")}
              </form>
            )}
          </>
        ) : (
          <Loader />
        )}
        <div className="groupe_comment__messages">
          {publication &&
          props.setDeleteAction &&
          props.setEditComment &&
          props.setReportAction &&
          commentairesQuery.loaded ? (
            commentaires
              .slice(0, voirPlus.amount)
              .map((comment) => (
                <GroupeCardCommentaire
                  key={comment.id}
                  comment={comment}
                  currentUser={props.auth.userConnected}
                  setDeleteComment={props.setDeleteAction}
                  setEditComment={props.setEditComment}
                  setReportComment={props.setReportAction}
                  isGroupAdmin={props.isGroupAdmin}
                  finder={props.finder}
                />
              ))
          ) : (
            <Loader />
          )}
          {commentaires.length > voirPlus.amount && (
            <Button
              label={t("groupPublication.comment.seeMore")}
              className="p-button-text commentaires-bouton"
              onClick={() => setVoirPlus({ amount: voirPlus.amount + 3 })}
            ></Button>
          )}
        </div>
      </div>
    </>
  );
};

GroupeCardPublication.propTypes = {
  publication: PropTypes.object,
  groups: PropTypes.object,
  auth: PropTypes.object,
  handleUpdateGroups: PropTypes.func,
  comments: PropTypes.array,
  setDeleteAction: PropTypes.func,
  deleteAction: PropTypes.object,
  setEditComment: PropTypes.func,
  editComment: PropTypes.object,
  setEditPublication: PropTypes.func,
  editPublication: PropTypes.object,
  setReportAction: PropTypes.func,
  setDisableComments: PropTypes.func,
  i18n: PropTypes.object,
  isGroupAdmin: PropTypes.bool,

  //? Si le composant est utilisé dans la recherche de groupe
  finder: PropTypes.bool,
};

GroupeCardPublication.defaultProps = {
  finder: false,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  groups: state.groups,
  i18n: state.i18n,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateGroups: (value) => {
    dispatch(updateGroups(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupeCardPublication);
