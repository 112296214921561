import React from "react";
import { Marker as MarkerLeaflet } from "react-leaflet";
// import PopupCustom from "../PopUp/PopUp";
import PropTypes from "prop-types";
import PopupCustom from "../PopUp/PopUp";

const MarkerCustom = (props) => {
  return (
    <>
      <MarkerLeaflet position={props.position}>
        <PopupCustom {...props.userDetail} />
      </MarkerLeaflet>
    </>
  );
};

MarkerCustom.propTypes = {
  position: PropTypes.array,
  userDetail: PropTypes.object,
};

export default MarkerCustom;
