import React, { useEffect, useRef, useState } from "react";
import { Offers } from "../Data/Const";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import Margin from "../Margin";
import { exportForExcel } from "../Data/Export";

const OffersTable = () => {
  const [data, setData] = useState(null);
  const dt = useRef(null);
  const [dateRange, setDateRange] = useState(null);
  const [selectedOffersTypes, setSelectedOffersTypes] = useState(null);
  const [selectedDiffusers, setSelectedDiffusers] = useState(null);
  const [selectedOrigin, setSelectedOrigin] = useState(null);

  const offersTypes = Offers.categoriesObject;
  const diffusers = Offers.diffusersObject;
  const origin = [{ name: "Portail et site université", code: "U" }];

  useEffect(() => {
    setData(Offers.generateOfferStatuses());
  }, []);

  useEffect(() => {
    setData(Offers.generateOfferStatuses());
  }, [dateRange, selectedOffersTypes, selectedDiffusers, selectedOrigin]);

  const cols = [
    { field: "status", header: "Statut de l'offre" },
    { field: "offersNumber", header: "Nombre d'offres" },
    { field: "applicationsNumber", header: "Nombre de candidatures" },
    { field: "viewsNumber", header: "Nombre de vues" },
    {
      field: "applicationButtonClickNumber",
      header: "Nombre de clic bouton 'Postuler`",
    },
    { field: "diffuserNumber", header: "Diffuseur" },
    { field: "siteNumber", header: "Site établissement" },
    { field: "companyPortalNumber", header: "Portail entreprise" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const exportCSV = () => {
    dt.current.exportCSV(data);
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, data);
        doc.save("statutOffres.pdf");
      });
    });
  };

  const exportTableExcel = () => {
    exportForExcel("offers-table", data, cols);
  };

  const header = (
    <div className="flex align-items-center export-buttons stats_container__table-tooltip">
      <Button
        type="button"
        icon="pi pi-file"
        onClick={() => exportCSV(false)}
        className="mr-2 csv"
        data-pr-tooltip="CSV"
      />
      <Button
        type="button"
        icon="pi pi-file-excel"
        onClick={exportTableExcel}
        className="p-button-success mr-2 xl"
        data-pr-tooltip="XLS"
      />
      <Button
        type="button"
        icon="pi pi-file-pdf"
        onClick={exportPdf}
        className="p-button-warning mr-2 pdf"
        data-pr-tooltip="PDF"
      />
    </div>
  );

  return (
    <div>
      <div className="stats_container__table">
        <div className="stats_container__table__forms">
          <br />
          <div className="stats_container__table__forms-dual">
            <Calendar
              id="range"
              value={dateRange}
              onChange={(e) => setDateRange(e.value)}
              selectionMode="range"
              readOnlyInput
              placeholder="Trier par date"
            />

            <MultiSelect
              appendTo={document.getElementById("app")}
              id="offersTypes"
              value={selectedOffersTypes}
              options={offersTypes}
              onChange={(e) => setSelectedOffersTypes(e.value)}
              optionLabel="name"
              placeholder="Trier par type d'offre"
            />
          </div>
          <Margin position="bottom" size="s" />

          <div className="stats_container__table__forms-dual">
            <MultiSelect
              appendTo={document.getElementById("app")}
              id="diffusers"
              value={selectedDiffusers}
              options={diffusers}
              onChange={(e) => setSelectedDiffusers(e.value)}
              optionLabel="name"
              placeholder="Trier par diffuseur"
            />
            <MultiSelect
              appendTo={document.getElementById("app")}
              id="origins"
              value={selectedOrigin}
              options={origin}
              onChange={(e) => setSelectedOrigin(e.value)}
              optionLabel="name"
              placeholder="Trier par provenance"
            />
          </div>
        </div>
        <h5 className="stats_container__table-title">Statut des offres</h5>
        <Margin position="bottom" size="xxs" />
        <Tooltip target=".export-buttons>button" position="bottom" />
        <DataTable
          id="offers-table"
          value={data}
          responsiveLayout="scroll"
          dataKey="id"
          header={header}
          ref={dt}
        >
          {cols.map((col, index) => (
            <Column key={index} field={col.field} header={col.header} />
          ))}
        </DataTable>
      </div>
    </div>
  );
};

export default OffersTable;
