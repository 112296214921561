import React, { useEffect, useState } from "react";
import "./Evenements.scss";
import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import ButtonsBarDisconnected from "Components/Header/ButtonsBarDisconnected/ButtonsBarDisconnected";
import { useDispatch, useSelector } from "react-redux";
import EvenementsContainer from "Components/Evenements/EvenementsContainer/EvenementsContainer";
import NavBarTab from "Components/NavBar/NavBarTab/NavBarTab";
import EvenementsCreation from "Components/Evenements/EvenementsCreation/EvenementsCreation";
import EvenementsManagement from "Components/Evenements/EvenementsManagement/EvenementsManagement";
import { updateEvents } from "Redux/Actions/eventsActions";
import axios from "axios";

/**
 * Permet d'afficher la page des événements
 * Avant connexion et après connexion pour les étudiants sont similaires
 * Les alumni et personnels peuvent proposer des événements et les gérer, les admins peuvent les créer et les gérer
 */
const Evenements = () => {
  // eslint-disable-next-line no-unused-vars
  const [activeIndex, setActiveIndex] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [activeIndexAdmin, setActiveIndexAdmin] = useState(0);

  // --- Données REDUX ---
  const authState = useSelector((state) => state.auth);
  const itemsState = useSelector((state) => state.items);
  const statesState = useSelector((state) => state.states);
  const dispatch = useDispatch();

  useEffect(() => {
    if (authState.userConnected != null) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL_API}/user_events/${authState.userConnected.id}`,
          {
            headers: authState.token
              ? {
                  accept: "application/json",
                  Authorization: `Bearer ${authState.token}`,
                }
              : {
                  accept: "application/json",
                },
          }
        )
        .then((response) => {
          dispatch(updateEvents({ myEvents: response.data }));
        })
        .catch((error) => console.log(error.message));
    }
  }, []);

  // remplissage de Redux avec les données des événements
  useEffect(() => {
    if (statesState.statesData.length > 0) {
      const dataEventStatusModified = statesState.statesData.map((state) => {
        return {
          id: state.id,
          name: state.statusLabel,
          statusLabel: state.statusLabel,
        };
      });
      const listOfStatus = [
        "Brouillon",
        "Refusé",
        "En Attente",
        "Publié",
        "Cloturé",
      ];

      dispatch(
        updateEvents({
          etats: dataEventStatusModified.filter(
            (state) => listOfStatus.indexOf(state.name) !== -1
          ),
        })
      );
    }
  }, [statesState.statesData.length]);

  return (
    <div>
      {/* affichage du header  */}
      {authState.isConnected ? (
        <>
          {(authState.isAlumni || authState.isPersonnel) && (
            <Header noImage={false}>
              <NavBarTab
                select="evenement"
                activeIndex={itemsState.activeItemEvenement}
                items={itemsState.itemsEvenement}
              />
            </Header>
          )}
          {authState.isAdmin && (
            <Header noImage={false}>
              <NavBarTab
                select="evenementadmin"
                activeIndex={itemsState.activeItemEvenementAdmin}
                items={itemsState.itemsEvenementAdmin}
              />
            </Header>
          )}
        </>
      ) : (
        <>
          {
            <Header noImage={true}>
              <ButtonsBarDisconnected />
            </Header>
          }
        </>
      )}
      {/* affichage du reste de la page  */}
      {!authState.isConnected ? (
        <>{<EvenementsContainer />}</>
      ) : (
        <>
          {(authState.isAlumni || authState.isPersonnel) &&
            itemsState.activeItemEvenement ===
              itemsState.itemsEvenement.findIndex(
                (i) => i.label === "Tous les événements"
              ) && <EvenementsContainer />}
          {authState.isAdmin &&
            itemsState.activeItemEvenementAdmin ===
              itemsState.itemsEvenementAdmin.findIndex(
                (i) => i.label === "Tous les événements"
              ) && <EvenementsContainer />}

          {/* création d'événements  */}
          {authState.isAdmin &&
            itemsState.activeItemEvenementAdmin ===
              itemsState.itemsEvenementAdmin.findIndex(
                (itemIndex) => itemIndex.label === "Créer un événement"
              ) && (
              <EvenementsCreation
                activeIndex={itemsState.itemsEvenementAdmin.findIndex(
                  (itemIndex) => itemIndex.label === "Créer un événement"
                )}
                setActiveIndex={setActiveIndexAdmin}
                items={itemsState.itemsEvenementAdmin}
              />
            )}
          {(authState.isAlumni || authState.isPersonnel) &&
            itemsState.activeItemEvenement ===
              itemsState.itemsEvenement.findIndex(
                (itemIndex) => itemIndex.label === "Proposer un événement"
              ) && (
              <EvenementsCreation
                activeIndex={itemsState.itemsEvenement.findIndex(
                  (itemIndex) => itemIndex.label === "Proposer un événement"
                )}
                setActiveIndex={setActiveIndex}
                items={itemsState.itemsEvenement}
              />
            )}
          {/* gestion d'événements  */}
          {(authState.isAlumni || authState.isPersonnel) &&
            itemsState.activeItemEvenement ===
              itemsState.itemsEvenement.findIndex(
                (itemIndex) => itemIndex.label === "Gérer mes événements"
              ) && (
              <EvenementsManagement
                activeIndex={itemsState.itemsEvenement.findIndex(
                  (itemIndex) => itemIndex.label === "Gérer mes événements"
                )}
                setActiveIndex={setActiveIndex}
                items={itemsState.itemsEvenement}
              />
            )}
          {authState.isAdmin &&
            itemsState.activeItemEvenementAdmin ===
              itemsState.itemsEvenementAdmin.findIndex(
                (itemIndex) => itemIndex.label === "Gérer les événements"
              ) && (
              <EvenementsManagement
                activeIndex={itemsState.itemsEvenementAdmin.findIndex(
                  (itemIndex) => itemIndex.label === "Gérer les événements"
                )}
                setActiveIndex={setActiveIndexAdmin}
                items={itemsState.itemsEvenementAdmin}
              />
            )}
        </>
      )}

      <Footer />
    </div>
  );
};

Evenements.propTypes = {};

export default Evenements;
