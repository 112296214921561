/**
 * Défini les différents états de publication d'une offre, événement ou actualité.
 * - PUBLISH_RP_AND_ALUMNI: Publication pour RP et les alumni
 * - PUBLISH_ALUMNI: Publication pour les alumni
 * - PUBLISH_RP: Publication pour les RP
 * @readonly
 * @enum {Symbol}
 */
export const PublishState = Object.freeze({
  PUBLISH_RP_AND_ALUMNI: 0,
  PUBLISH_ALUMNI: 1,
  PUBLISH_RP: 2,
});

export const publishStateOptions = [
  {
    value: PublishState.PUBLISH_RP_AND_ALUMNI,
    label: "Réseau Pro et Réseau Alumni",
  },
  { value: PublishState.PUBLISH_ALUMNI, label: "Réseau Alumni uniquement" },
  { value: PublishState.PUBLISH_RP, label: "Réseau Pro uniquement" },
];
