import React, { useEffect, useState } from "react";
import "./searchCard.scss";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FaTrash } from "react-icons/fa";
import { VscChevronDown, VscChevronRight } from "react-icons/vsc";

const SearchCard = (props) => {
  const [searchDetails, setSearchDetails] = useState({
    domain: undefined,
    typeOfContract: undefined,
    categorieOffre: undefined,
    niveauEtudes: undefined,
    secteur: undefined,
  });

  const [openDetails, setOpenDetails] = useState(false);
  const [detailsLoaded, setDetailsLoaded] = useState(false);

  const fillArrays = () => {
    const getDataArray = (reduxData, responseData) => {
      let returnArray = [];
      if (responseData.length > 0) {
        reduxData.forEach(
          (element) =>
            responseData.some(
              (domaine) =>
                domaine.slice(domaine.lastIndexOf("/") + 1) == element.id
            ) && returnArray.push(element)
        );
      }
      return returnArray;
    };

    if (openDetails && props.secondaryTables && props.value) {
      setSearchDetails({
        domain: getDataArray(
          props.secondaryTables.domainsData,
          props.value.domain
        ),
        typeContrat: getDataArray(
          props.secondaryTables.typeOfContractsData,
          props.value.typeOfContract
        ),
        categorieOffre: getDataArray(
          props.secondaryTables.offerCategoriesData,
          props.value.offerCategory
        ),
        niveauEtudes: getDataArray(
          props.secondaryTables.levelOfEducationsData,
          props.value.levelOfEducation
        ),
        secteur: getDataArray(
          props.secondaryTables.sectorOfOffersData,
          props.value.sectorOfOffer
        ),
      });
      setDetailsLoaded(true);
    } else {
      setSearchDetails({
        domain: undefined,
        typeContrat: undefined,
        categorieOffre: undefined,
        niveauEtudes: undefined,
        secteur: undefined,
      });
      setDetailsLoaded(false);
    }
  };

  useEffect(() => {
    fillArrays();
  }, [openDetails, props.secondaryTables, props.value]);

  return (
    <div className="offre_search_card_container cardHover">
      <div className="offre_search_card">
        <div
          className="offre_search_card__title"
          onClick={() => {
            props.onSelect(props.value);
            props.setVisibleListOfSearch(false);
          }}
        >
          <label>{props.value.nameOfSearch}</label>
        </div>
        <div className="offre_search_card__buttons">
          <div className="offre_search_card__buttons__left">
            <FaTrash
              className="offre_search_card__buttons__left__trash"
              onClick={() => {
                props.onDelete(props.value);
              }}
            ></FaTrash>
            {/* <FaPen className="offre_search_card__buttons__left__pen"></FaPen> */}
          </div>
          <div className="offre_search_card__buttons__right">
            {openDetails ? (
              <VscChevronDown
                onClick={() => {
                  setOpenDetails(!openDetails);
                }}
              ></VscChevronDown>
            ) : (
              <VscChevronRight
                onClick={() => {
                  setOpenDetails(!openDetails);
                }}
              ></VscChevronRight>
            )}
          </div>
        </div>
      </div>
      {openDetails && detailsLoaded && (
        <div className="offre_search_card__details">
          <div className="offre_search_card__details__champs">
            <label className="name">{"Mots clés: "}</label>
            <label className="content">{props.value?.title}</label>
          </div>
          <div className="offre_search_card__details__champs">
            <label className="name">{"Lieu: "} </label>
            <label className="content">{props.value?.country}</label>
          </div>
          <div className="offre_search_card__details__champs">
            <label className="name">{"Salaire: "} </label>
            <label className="content">
              {props.value?.minSalary + " - " + props.value?.maxSalary}
            </label>
          </div>
          <div className="offre_search_card__details__champs">
            <label className="name">{"Domaine: "} </label>
            {searchDetails.domain?.map((element, key) => (
              <label className="content" key={key}>
                {element.label + " / "}
              </label>
            ))}
          </div>
          <div className="offre_search_card__details__champs">
            <label className="name">{"Secteur de l'offre: "} </label>
            {searchDetails.secteur?.map((element, key) => (
              <label className="content" key={key}>
                {element.label + " / "}
              </label>
            ))}
          </div>
          <div className="offre_search_card__details__champs">
            <label className="name">{"Catégorie de contrat: "} </label>
            {searchDetails.categorieOffre?.map((element, key) => (
              <label className="content" key={key}>
                {element.label + " / "}
              </label>
            ))}
          </div>

          <div className="offre_search_card__details__champs">
            <label className="name">{"Type de contrat: "} </label>
            {searchDetails.typeContrat?.map((element, key) => (
              <label className="content" key={key}>
                {element.label + " / "}
              </label>
            ))}
          </div>
          <div className="offre_search_card__details__champs">
            <label className="name">{"Niveau d'études: "} </label>
            {searchDetails.niveauEtudes?.map((element, key) => (
              <label className="content" key={key}>
                {element.label + " / "}
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

SearchCard.propTypes = {
  value: PropTypes.object,
  secondaryTables: PropTypes.object,
  onSelect: PropTypes.func,
  setVisibleListOfSearch: PropTypes.func,
  onDelete: PropTypes.func,
};

const mapStateToProps = (state) => ({
  secondaryTables: state.secondaryTables,
  auth: state.auth,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SearchCard);
