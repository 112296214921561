import React from "react";
import "./ProfilBarShow.scss";
import {
  FaMapMarkerAlt,
  FaRegCalendar,
  FaRegEnvelope,
  FaRegAddressBook,
  FaHandsHelping,
} from "react-icons/fa";
import facebook_logo from "assets/logo_facebook.webp";
import linkedin_logo from "assets/logo_linkedin.webp";
import twitter_logo from "assets/logo_twitter.webp";
import instagram_logo from "assets/logo_instagram.webp";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateAuth } from "Redux/Actions/authActions";
import { formatDate, truncate } from "../../../Services/functions";
import neutralUser from "assets/neutralUser.png";
import { useTranslation } from "react-i18next";

const ProfilBarShow = (props) => {
  const isMyProfil = props.user.id === props.auth.userConnected.id;

  const { t } = useTranslation("common");

  const fullAdressFunction = (user, isAdress) => {
    let localisation = "";
    if (user.address && isAdress) localisation += user.address + ", ";
    if (user.city) localisation += user.city + ", ";
    if (user.country) localisation += user.country;
    return localisation;
  };

  const showAdress = (user) => {
    const isAllEmpty = !user.address && !user.city && !user.country;
    const isAdressPublic = user.addressIsPublic;
    const isAllPrivate = !user.addressIsPublic && !user.cityAndCountryIsPublic;
    const isCityCountryEmpty = !user.city && !user.country;
    const fullAdress = fullAdressFunction(user, true);

    if (isMyProfil) {
      if (isAllEmpty) return "Non renseigné";
      else return fullAdress;
    } else {
      if (isAllPrivate) return "Indisponible";
      else if (isAllEmpty) return "Non renseigné";
      else if (isAdressPublic) return fullAdress;
      else {
        if (isCityCountryEmpty) return "Non renseigné";
        else return fullAdressFunction(user, false);
      }
    }
  };

  return (
    <div className="profilBarShow">
      <div className="profilBarShow__top">
        <div className="profilBarShow__top__image">
          <img
            src={
              props.user.imageUrl != null ? props.user.imageUrl : neutralUser
            }
          />
        </div>
        <div className="profilBarShow__top__middle">
          <h2>
            {props.user?.firstname} {props.user?.surname}
          </h2>
          <h3>{props.user?.profilTitle}</h3>
          <p>
            {props.user.profilDescription && (
              <>{truncate(props.user?.profilDescription, 350)}</>
            )}
          </p>
          {props.user.mentorAccept && (
            <div className="profilBarShow__top__middle__parrain">
              <p>
                <FaHandsHelping />
                {t("profilShow.mentor")}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="profilBarShow__right">
        <div className="profilBarShow__right__item">
          <label>
            <FaRegCalendar />
            {t("profilEdition.personalInfo.birthday")}
          </label>
          <p>
            {props.user.birthdayIsPublic || isMyProfil
              ? props.user.birthday
                ? // Dans le cas ou elle est publique, on vérifie si elle existe
                  formatDate(props.user.birthday)
                : // Sinon on affiche 'Non renseignée'
                  "Non renseignée"
              : "Indisponible"}
          </p>
        </div>
        <div className="profilBarShow__right__item">
          <label>
            <FaMapMarkerAlt />
            {t("profilEdition.personalInfo.address")}
          </label>
          <p>
            {/* {props.user.addressIsPublic || isMyProfil
                ? props.user.address
                  ? props.user.address
                  : "Non renseignée"
                : "Indisponible"}
              ,{" "}
              {props.user.cityAndCountryIsPublic || isMyProfil
                ? (props.user.city ? props.user.city : "Non renseignée",
                  props.user.country ? props.user.country : "Non renseigné")
                : "Indisponible"} */}
            {showAdress(props.user)}
          </p>
        </div>
        <div className="profilBarShow__right__item">
          <label>
            <FaRegEnvelope />
            {t("profilEdition.personalInfo.email")}
          </label>
          <p>
            {props.user.mailIsPublic || isMyProfil ? (
              props.user.email ? (
                <a href={`mailto:${props.user.email && props.user.email}`}>
                  {" "}
                  {props.user.email}
                </a>
              ) : (
                "Non renseignée"
              )
            ) : (
              "Indisponible"
            )}
          </p>
        </div>
        <div className="profilBarShow__right__item">
          <label>
            <FaRegAddressBook />
            Tel :
          </label>
          <p>
            {props.user.telephoneIsPublic || isMyProfil
              ? props.user.telephone
                ? props.user.telephone
                : "Non renseigné"
              : "Indisponible"}
          </p>
        </div>
        <div className="profilBarShow__right__logos">
          {props.user.facebookAccount && (
            <a
              href={
                // On vérifie si le lien commence par http ou https
                props.user.facebookAccount.startsWith("http")
                  ? props.user.facebookAccount
                  : "https://" + props.user.facebookAccount
              }
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebook_logo} />
            </a>
          )}
          {props.user.linkedinAccount && (
            <a
              href={
                // On vérifie si le lien commence par http ou https
                props.user.linkedinAccount.startsWith("http")
                  ? props.user.linkedinAccount
                  : "https://" + props.user.linkedinAccount
              }
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedin_logo} />
            </a>
          )}
          {props.user.twitterAccount && (
            <a
              href={
                // On vérifie si le lien commence par http ou https
                props.user.twitterAccount.startsWith("http")
                  ? props.user.twitterAccount
                  : "https://" + props.user.twitterAccount
              }
              target="_blank"
              rel="noreferrer"
            >
              <img src={twitter_logo} />
            </a>
          )}
          {props.user.instagramAccount && (
            <a
              href={
                // On vérifie si le lien commence par http ou https
                props.user.instagramAccount.startsWith("http")
                  ? props.user.instagramAccount
                  : "https://" + props.user.instagramAccount
              }
              target="_blank"
              rel="noreferrer"
            >
              <img src={instagram_logo} />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

ProfilBarShow.propTypes = {
  user: PropTypes.object,
  auth: PropTypes.object,
  handleUpdateAuth: PropTypes.func,
  setModified: PropTypes.func,
  image: PropTypes.string,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateAuth: (value) => {
    dispatch(updateAuth(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilBarShow);
