import React from "react";
import PropTypes from "prop-types";
import "./ModalSuppressionEvenementGroupe.scss";
import { connect } from "react-redux";
import Modal from "../Modal";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import { fetchDelete } from "Services/api";

const ModalSuppressionEvenementGroupe = ({ visible, setVisible, auth }) => {
  const handleAccept = async () => {
    const result = await fetchDelete(`/event_groups/${visible.id}`, auth.token);
    if (result && result.status === 204)
      setVisible({ ...visible, display: false, result: result });
    else setVisible({ ...visible, display: false, result: result });
  };

  const handleReject = () => {
    setVisible({ ...visible, display: false });
  };
  return (
    <div>
      <Modal
        visible={visible.display}
        setVisible={() => setVisible({ ...visible, display: false })}
        header={"Confirmation de suppression"}
        onHide={() => setVisible({ ...visible, display: false })}
        height="fit-content"
        width="80%"
        className="modal modal_delete_event_group"
      >
        <div className="delete_event_group-content">
          {`Souhaitez-vous supprimer l'événement de groupe '${visible.title}' ?`}
        </div>
        <div className={"delete_event_group-buttons"}>
          <BtnBlanc
            btnTexte="Annuler"
            action={"button"}
            btnAction={handleReject}
          />
          <BtnBleu
            btnTexte="Supprimer l'événement groupe"
            btnAction={handleAccept}
          />
        </div>
      </Modal>
    </div>
  );
};

ModalSuppressionEvenementGroupe.propTypes = {
  visible: PropTypes.object.isRequired,
  setVisible: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  groups: state.groups,
});

export default connect(mapStateToProps)(ModalSuppressionEvenementGroupe);
