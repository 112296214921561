import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import "./GroupeCardMember.scss";
// import { Button } from "primereact/button";
import BtnRond from "Components/Boutons/BtnRond/btn-rond";
import ModalAjoutMembres from "Components/Modal/ModalAjoutMembres/ModalAjoutMembres";
import { updateGroups } from "Redux/Actions/groupsActions";
import { updateMenu } from "Redux/Actions/menuActions";
import { truncate } from "Services/functions";
import img_groupe1 from "assets/img_groupe1.webp";
import img_groupe2 from "assets/img_groupe2.webp";
import img_groupe3 from "assets/img_groupe3.webp";
import img_groupe4 from "assets/img_groupe4.webp";
import img_groupe5 from "assets/img_groupe5.webp";
import img_groupe6 from "assets/img_groupe6.webp";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { useTranslation } from "react-i18next";
import { FaEllipsisH } from "react-icons/fa";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const GroupeCardMember = (props) => {
  const { t } = useTranslation("common");
  const menu = useRef(null);
  const navigate = useNavigate();

  const items = [
    {
      label: t("groupManagement.leave"),
      command: () => {
        props.setLeaveGroup({
          id: props.group?.id,
          state: true,
          title: props.group?.title,
        });
      },
    },
    { separator: true },
    {
      label: t("createGroup.addMembers"),
      command: () => {
        setAddMembers({ ...addMembers, state: true, group: props.group });
      },
    },
  ];

  const adminItems = [
    {
      label: t("createGroup.addMembers"),
      command: () => {
        setAddMembers({ ...addMembers, state: true, group: props.group });
      },
    },
  ];

  const [addMembers, setAddMembers] = useState({
    group: null,
    state: null,
    result: null,
  });

  const detailsToast = useRef(null);

  useEffect(async () => {
    if (addMembers.result) {
      detailsToast.current.show({
        visible: true,
        severity: addMembers.result.status == 200 ? "success" : "error",
        summary: "Invitation",
        detail:
          addMembers.result.status == 200
            ? "L'utilisateur a été invité à rejoindre le groupe"
            : "Une erreur est survenue",
      });
    }
    setAddMembers({ ...addMembers, result: null, group: null, state: null });
  }, [addMembers.result]);

  return (
    <>
      {props.group?.id && (
        <div className="groupe_card_member_container">
          <Toast ref={detailsToast} />
          <div className="groupe_image">
            {props.image == "img1" && (
              <img
                src={img_groupe1}
                alt="Logo du groupe"
                title="Logo du groupe"
              />
            )}
            {props.image == "img2" && (
              <img
                src={img_groupe2}
                alt="Logo du groupe"
                title="Logo du groupe"
              />
            )}
            {props.image == "img3" && (
              <img
                src={img_groupe3}
                alt="Logo du groupe"
                title="Logo du groupe"
              />
            )}
            {props.image == "img4" && (
              <img
                src={img_groupe4}
                alt="Logo du groupe"
                title="Logo du groupe"
              />
            )}
            {props.image == "img5" && (
              <img
                src={img_groupe5}
                alt="Logo du groupe"
                title="Logo du groupe"
              />
            )}
            {props.image == "img6" && (
              <img
                src={img_groupe6}
                alt="Logo du groupe"
                title="Logo du groupe"
              />
            )}
            {props.image == "link" && (
              <img src={props.link} alt="Fond groupe" />
            )}
            <Link
              to=""
              onClick={(e) => {
                e.preventDefault();
                if (props.setSelectGroup) props.setSelectGroup(props.group);
                else
                  props.handleGroups({
                    selectedgroup: props.group,
                  });
                props.handleMenu({
                  activeItemGroupesAdmin:
                    props.items.itemsGroupesAdmin.findIndex(
                      (v) => v.label == "Mes groupes d'échanges"
                    ),
                  activeItemGroupes: props.items.itemsGroupes.findIndex(
                    (v) => v.label == "Mes groupes d'échanges"
                  ),
                });
                navigate("/groupes");
              }}
            >
              {truncate(props.group?.label, 20)}
            </Link>
          </div>
          <div className="groupe_menu">
            <Menu
              appendTo={document.getElementById("app")}
              model={props.auth.isAdmin ? adminItems : items}
              popup
              ref={menu}
            />
            <BtnRond btnAction={(event) => menu.current.toggle(event)}>
              <FaEllipsisH size={"1.5rem"} />
            </BtnRond>
          </div>
          {addMembers.state && (
            <ModalAjoutMembres
              visible={addMembers}
              setVisible={setAddMembers}
            />
          )}
        </div>
      )}
    </>
  );
};

GroupeCardMember.propTypes = {
  auth: PropTypes.object,
  group: PropTypes.object,
  image: PropTypes.string,
  link: PropTypes.string,
  items: PropTypes.object,
  handleMenu: PropTypes.func,
  handleGroups: PropTypes.func,
  setSelectGroup: PropTypes.func,
  setLeaveGroup: PropTypes.func,
};

const mapStateToProps = (state) => ({
  items: state.items,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  handleMenu: (value) => {
    dispatch(updateMenu(value));
  },
  handleGroups: (value) => {
    dispatch(updateGroups(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupeCardMember);
