import React from "react";
import "./Requetes.scss";
import { connect } from "react-redux";
import PropTypes from "prop-types";

/**
 * Permet d'afficher la page de requêtes et exports
 * Page accessible uniquement pour les administrateurs
 */
// TO DO : une fois les specs finalisées, intégrer la page
const Requetes = (props) => {
  return (
    <div>
      {props.auth.isConnected && (
        <div className="article_actu">
          <div className="titre_de_page">
            <h1 className="h1_trait_dessus">
              {props.items[props.activeIndex].label}
            </h1>
          </div>
        </div>
      )}
    </div>
  );
};

Requetes.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.array,
  activeIndex: PropTypes.number,
  checked: PropTypes.bool,
  setChecked: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Requetes);
