import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Modal from "../Modal";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { useEffect } from "react";
import "./ModalDroitsAdminGroupe.scss";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import { fetchGet, useFetchGet, useFetchGetConditional } from "Services/api";
import { Dropdown } from "primereact/dropdown";
import { isNotEmptyArray } from "Services/functions";

const ModalDroitsAdminGroupe = ({ visible, setVisible, auth, states }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      member: null,
    },
  });

  const memberStatuses = useFetchGetConditional(
    "/group_member_statuses",
    auth.token,
    states.groupMemberStatesData
  );

  const membersRoles = useFetchGet("/roles", auth.token, states.rolesData);

  const [groupMembersList, setGroupMembersList] = useState([]);

  async function getGroupMembersList(id) {
    try {
      let members = await fetchGet("/group_members", auth.token, {
        groupSource: id,
        groupMemberStatus: memberStatuses.data.find(
          (status) => status.label === "ACTIF"
        ).id,
        "groupMemberRoles.role": membersRoles.data.find(
          (role) => role.roleName === "ROLE_GROUP_USER"
        ).id,
      });
      return members;
    } catch (error) {
      auth.toast.current.show({
        severity: "error",
        summary: "Erreur",
        detail:
          "Une erreur est survenue lors de la récupération des membres du groupe",
        life: 5000,
      });
    }
  }

  useEffect(async () => {
    if (visible?.group?.id && memberStatuses.loaded && membersRoles.loaded) {
      //? Récupération liste des membres du groupe qui n'ont pas le droit d'admin du groupe
      //? Et qui ne correspondent pas à l'utilisateur connecté
      let temp = await getGroupMembersList(visible.group.id);
      //? On crée temporairement un objet pour modifier l'array
      //? Ajout d'un champ displayLabel pour l'affichage dans le dropdown
      temp.forEach((member) => {
        member.user.displayLabel =
          member.user.firstname + " " + member.user.surname;
      });
      //? Enlève les utilisateurs déjà admins du groupe
      temp = temp.filter(
        (member) =>
          member.groupMemberRoles.find(
            (role) => role.roleName === "ROLE_GROUP_ADMIN"
          ) === undefined
      );
      setGroupMembersList([...temp]);
    }
  }, [visible, memberStatuses.loaded, membersRoles.loaded]);

  const promoteToAdmin = (data) => {
    var result = undefined;
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL_API}/group_members/admin/promote/${data.member.id}`,
        "{}",
        {
          headers: auth.token
            ? {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.token}`,
              }
            : {
                "Content-Type": "application/json",
              },
        }
      )
      .then((response) => (result = response))
      .catch((error) => (result = error.response))
      .finally(() => {
        if (result && result.status && result.status === 200)
          setVisible({ state: false, result: result });
        else
          setVisible({
            state: false,
            result: result,
          });
        reset();
      });
  };

  const onSubmit = (data) => {
    if (getValues("member")) promoteToAdmin(data);
  };

  /**
   * Afffichage d'une erreur si nécessaire dans le formulaire
   * @param {String} name nom du champ
   * @returns {FieldError, JSX.Element}
   */
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  return (
    <>
      <Modal
        visible={visible.state}
        setVisible={() => setVisible({ ...visible, state: false })}
        header="Ajout droits d'administrateur"
        height="fit-content"
        width="80%"
        className="modal modal_ajout-droits"
        blockScroll={true}
      >
        {groupMembersList && isNotEmptyArray(groupMembersList) && (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={"ajout_droits__form"}
          >
            <label htmlFor="content">Choix du membre</label>
            <Controller
              name="member"
              control={control}
              rules={{
                required: "Veuillez choisir un utilisateur",
              }}
              render={({ field }) => (
                <Dropdown
                  {...field}
                  filter
                  filterBy="user.displayLabel"
                  options={groupMembersList.sort((a, b) =>
                    a?.user?.displayLabel?.localeCompare(b?.user?.displayLabel)
                  )}
                  optionLabel="user.displayLabel"
                  className="ajout_droits__form-content"
                />
              )}
            />
            {getFormErrorMessage("member")}
            <div className="ajout_droits__form-buttons">
              <BtnBleu
                btnTexte="Valider"
                btnAction={() => {
                  onSubmit(getValues());
                }}
              />
              <BtnBlanc
                btnTexte="Annuler"
                action={"button"}
                btnAction={() => {
                  setVisible({ ...visible, state: false });
                  reset();
                }}
              />
            </div>
          </form>
        )}
      </Modal>
    </>
  );
};

ModalDroitsAdminGroupe.propTypes = {
  visible: PropTypes.object.isRequired,
  setVisible: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  states: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  states: state.states,
});

export default connect(mapStateToProps)(ModalDroitsAdminGroupe);
