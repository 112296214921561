export const ME = "me";
export const ETUDIANT = "Etudiant";
export const ALUMNI = "Alumni";
export const PERSONNEL = "Personnel";
export const ADMIN = "Admin";
export const RECRUTEUR = "Recruteur";
export const DIFFUSEUR = "Diffuseur";
export const ALL = "all";

export const newsMgmtButtons = [
  {
    isActive: true,
    text: "Mes actualités",
    transmitter: [ME],
  },
  {
    isActive: false,
    text: "Actus universités",
    transmitter: [ETUDIANT, ALUMNI, PERSONNEL, ADMIN],
  },
  {
    isActive: false,
    text: "Actus entreprises",
    transmitter: [RECRUTEUR],
  },
  {
    isActive: false,
    text: "Toutes les actus",
    transmitter: [ALL],
  },
];

export const eventMgmtButtons = [
  {
    isActive: true,
    text: "Mes événements",
    transmitter: [ME],
  },
  {
    isActive: false,
    text: "Événements universités",
    transmitter: [ETUDIANT, ALUMNI, PERSONNEL, ADMIN],
  },
  {
    isActive: false,
    text: "Événements entreprises",
    transmitter: [RECRUTEUR],
  },
  {
    isActive: false,
    text: "Tous les événements",
    transmitter: [ALL],
  },
];

export const offerMgmtButtons = [
  {
    isActive: true,
    text: "Mes offres",
    transmitter: [ME],
  },
  {
    isActive: false,
    text: "Offres universités",
    transmitter: [ETUDIANT, ALUMNI, PERSONNEL, ADMIN],
  },
  {
    isActive: false,
    text: "Offres entreprises",
    transmitter: [RECRUTEUR],
  },
  {
    isActive: false,
    text: "Offres diffuseurs",
    transmitter: [DIFFUSEUR],
  },
  {
    isActive: false,
    text: "Toutes les offres",
    transmitter: [ALL],
  },
];
