import { UPDATE_NEWS } from "Redux/Actions/newsActions";

const INITIAL_STATE = {
  etats: [],
  randomNews: [],
  randomConseil: [],
  currentTransmitter: [],
};

const newsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_NEWS: {
      return {
        ...state,
        ...action.value,
      };
    }
    default:
      return state;
  }
};

export default newsReducer;
