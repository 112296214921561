/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./GroupeManagement.scss";
import Header from "Components/Header/Header";
import NavBarTab from "Components/NavBar/NavBarTab/NavBarTab";
import GroupeCreation from "../GroupeCreation/GroupeCreation";
import { updateGroups } from "Redux/Actions/groupsActions";
import { updatePagination } from "Redux/Actions/paginationActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import GroupeGestion from "../GroupeGestion/GroupeGestion";
import { useEffect } from "react";

/**
 * Permet d'afficher la page de gestion des groupes
 * On peut également créer un groupe via cette page avec un changement de la variable creation
 */
const GroupeManagement = (props) => {
  // Affichager page création groupe
  const [creation, setCreation] = useState(false);

  const displayGroupeCreation = () =>
    props.auth.userConnected.userRoles.find(
      (role) => role.roleName === "ROLE_ETUDIANT"
    )
      ? false
      : true;

  useEffect(() => {
    if (props.auth.userConnected) setCreation(displayGroupeCreation());
  }, [props.auth.userConnected]);

  return (
    <div>
      <Header
        activeIndex={props.activeIndex}
        setActiveIndex={props.setActiveIndex}
        items={props.items}
      >
        {props.auth && props.auth.isAdmin ? (
          <NavBarTab
            select="groupesadmin"
            activeIndex={props.activeIndex}
            items={props.items}
          />
        ) : (
          <NavBarTab
            select="groupes"
            activeIndex={props.activeIndex}
            items={props.items}
          />
        )}
      </Header>
      {creation ? (
        <GroupeCreation creation={creation} setCreation={setCreation} />
      ) : (
        <GroupeGestion creation={creation} setCreation={setCreation} />
      )}
    </div>
  );
};

GroupeManagement.propTypes = {
  items: PropTypes.object,
  activeIndex: PropTypes.number,
  setActiveIndex: PropTypes.func,

  auth: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateGroups: (value) => {
    dispatch(updateGroups(value));
  },
  handleUpdatePagination: (value) => {
    dispatch(updatePagination(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupeManagement);
