import { UPDATE_ADMIN } from "../Actions/adminActions";

const INITIAL_STATE = {
  users: [],
  editManagement: [],
  editManagementBoolean: [],
  currentPage: 1,
  paginationBasicFirst: 0,
  paginationBasicRows: 8,
};

const adminReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case UPDATE_ADMIN: {
      return {
        ...state,
        ...action.value,
      };
    }

    default:
      return state;
  }
};

export default adminReducer;
