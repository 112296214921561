import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import "./ModalRejoindreGroupe.scss";
import { connect } from "react-redux";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import Modal from "../Modal";
import ModalCharteGroupes from "../ModalCharteGroupes/ModalCharteGroupes";

const ModalRejoindreGroupe = ({ visible, setVisible, auth }) => {
  const [visibleModalCharte, setvisibleModalCharte] = useState(false);

  const postJoin = async (url, data) => {
    let errorResponse = null;
    const response = await axios
      .post(`${process.env.REACT_APP_BASE_URL_API}${url}`, data, {
        headers: auth.token
          ? {
              accept: "application/json",
              Authorization: `Bearer ${auth.token}`,
            }
          : {
              accept: "application/json",
            },
      })
      .catch((error) => {
        errorResponse = error.response.data.detail;
      });
    return response || errorResponse;
  };

  const handleAccept = async () => {
    const groupMember = {
      groupSource: "/api/groups/" + visible.id,
      user: "/api/users/" + auth.userConnected.id,
    };
    const result = await postJoin("/group_members/join", groupMember);
    if (result && result.status === 200)
      setVisible({ ...visible, state: false, result: result });
    else setVisible({ ...visible, state: false, result: result });
  };

  const handleReject = () => {
    setVisible({ ...visible, state: false });
  };
  return (
    <div>
      <Modal
        visible={visible.state}
        setVisible={() => setVisible({ ...visible, state: false })}
        header={"Confirmation"}
        onHide={() => setVisible({ ...visible, state: false })}
        height="fit-content"
        width="80%"
        className="modal modal_rejoindre_groupe"
      >
        <div className="rejoindre_groupe-content">
          {visible.private
            ? `Souhaitez-vous rejoindre le groupe privé '${visible.title}' ?`
            : `Souhaitez-vous rejoindre le groupe public '${visible.title}' ?`}
        </div>
        <div className={"rejoindre_groupe-buttons"}>
          <BtnBlanc
            btnTexte="Annuler"
            action={"button"}
            btnAction={handleReject}
          />
          <BtnBleu
            btnTexte="Rejoindre le groupe"
            btnAction={() =>
              auth.userConnected.charteGroupSigned
                ? handleAccept()
                : setvisibleModalCharte(true)
            }
          />
        </div>
      </Modal>
      {visibleModalCharte && (
        <ModalCharteGroupes
          visibleModalCharte={visibleModalCharte}
          setvisibleModalCharte={setvisibleModalCharte}
          btnAccept={handleAccept}
          btnRefuse={handleReject}
        ></ModalCharteGroupes>
      )}
    </div>
  );
};

ModalRejoindreGroupe.propTypes = {
  visible: PropTypes.object.isRequired,
  setVisible: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ModalRejoindreGroupe);
