/* eslint-disable no-unused-vars */
import { Slider } from "primereact/slider";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import "./sliderSalaire.scss";

const SliderSalaire = ({
  valueSalary,
  categorie,
  onChangeAction,
  isSearchBar,
  isEditing,
}) => {
  const alternances = ["Alternance", "VIE/VIA"];
  const stages = ["Stage", "Service Civique"];
  const salaireName = () => {
    if (categorie.length > 0) {
      if (alternances.includes(categorie[0]?.label)) return "Salaire mensuel";
      if (stages.includes(categorie[0]?.label))
        return "Gratification mensuelle";
      if (categorie[0]?.label === "Emploi") return "Salaire annuel";
      if (categorie[0]?.label === "Job étudiant") return "Salaire horaire";
    } else return "Salaire";
  };

  const getMaxSalaire = () => {
    if (isEditing) return valueSalary[1];
    if (categorie.length > 0) {
      if (alternances.includes(categorie[0]?.label)) return 4000;
      if (stages.includes(categorie[0]?.label)) return 3000;
      if (categorie[0]?.label === "Emploi") return 100000;
      if (categorie[0]?.label === "Job étudiant") return 100;
    } else return 30000;
  };
  const getMinSalaire = () => {
    if (isEditing) return valueSalary[0];

    if (categorie.length > 0) {
      if (alternances.includes(categorie[0]?.label)) return 500;
      if (stages.includes(categorie[0]?.label)) return 0;
      if (categorie[0]?.label === "Emploi") return 10000;
      if (categorie[0]?.label === "Job étudiant") return 8;
    } else return 0;
  };

  const setSalary = (salary) => {
    valueSalary[0] < salary[0] || valueSalary[1] > salary[1]
      ? onChangeAction("salary", salary)
      : onChangeAction("salary", valueSalary);
  };

  useEffect(() => {}, [valueSalary]);

  useEffect(() => {
    if (isSearchBar) {
      if (categorie.length > 0 && !isEditing) {
        if (alternances.includes(categorie[0].label))
          onChangeAction([500, 4000]);
        if (stages.includes(categorie[0]?.label)) onChangeAction([0, 3000]);
        if (categorie[0]?.label === "Job étudiant") onChangeAction([8, 100]);
        if (categorie[0]?.label === "Emploi") onChangeAction([10000, 100000]);
      }
    }
  }, [categorie]);
  useEffect(() => {
    if (!isSearchBar && !isEditing) {
      if (alternances.includes(categorie[0].label))
        isSearchBar ? onChangeAction([500, 4000]) : setSalary([500, 4000]);
      if (stages.includes(categorie[0].label)) setSalary([0, 3000]);
      if (categorie[0].label === "Job étudiant") setSalary([8, 100]);
      if (categorie[0].label === "Emploi") {
        setSalary([10000, 100000]);
      }
    }
  }, [categorie[0]?.label]);

  return (
    <div className="salaire--slider">
      {categorie.length <= 1 ? (
        <>
          <p className="salaire--slider--label pSalaire">{`${salaireName()} `}</p>
          <div className="salaire--slider--container">
            <h6 className="salaire--slider--container--slider">
              <div>{valueSalary[0]}€</div>
              <div>{valueSalary[1]}€</div>
            </h6>
            <Slider
              min={getMinSalaire()}
              max={getMaxSalaire()}
              disabled={isEditing}
              step={categorie[0]?.label === "Job étudiant" ? 5 : 100}
              value={valueSalary}
              onChange={(e) =>
                isSearchBar
                  ? onChangeAction(e.value)
                  : onChangeAction("salary", e.value)
              }
              range
            />
          </div>
        </>
      ) : (
        <p>
          Vous ne pouvez pas indiquer de salaire si plusieurs catégories de
          contrat sont sélectionnées
        </p>
      )}
    </div>
  );
};

SliderSalaire.propTypes = {
  valueSalary: PropTypes.array,
  categorie: PropTypes.array,
  isEditing: PropTypes.bool,
  onChangeAction: PropTypes.func,
  isSearchBar: PropTypes.bool,
};

SliderSalaire.defaultProps = {
  isEditing: false,
};

export default SliderSalaire;
