import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Companies, Users } from "../Data/Const";
import { Button } from "primereact/button";
import { exportForExcel } from "../Data/Export";

const UsersTable = () => {
  const [data, setData] = useState(null);
  const dt = useRef(null);
  const unis = Companies.firstFiveUni;
  const levels = Users.usersProfiles;

  useEffect(() => {
    let generatedUsers = Users.generateUsersData(unis, levels);
    setData(generatedUsers);
  }, []);

  const cols = [
    { field: "name", header: "Nom", dataType: "name", sortable: true },
    {
      field: "numberConnections",
      header: "Nombre de connexions",
      dataType: "numeric",
      sortable: true,
    },
    {
      field: "registerDate",
      header: "Date d'inscription",
      dataType: "date",
      sortable: true,
    },
    {
      field: "lastConnectionDate",
      header: "Dernière connexion",
      dataType: "date",
      sortable: true,
    },
    { field: "uni", header: "Université", dataType: "name", sortable: true },
    { field: "level", header: "Niveau", dataType: "name", sortable: true },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const exportCSV = () => {
    dt.current.exportCSV(data);
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, data);
        doc.save("statutOffres.pdf");
      });
    });
  };

  const exportTableExcel = () => {
    exportForExcel("users-table", data, cols);
  };

  const header = (
    <div className="flex align-items-center export-buttons stats_container__table-tooltip">
      <Button
        type="button"
        icon="pi pi-file"
        onClick={() => exportCSV(false)}
        className="mr-2 csv"
        data-pr-tooltip="CSV"
      />
      <Button
        type="button"
        icon="pi pi-file-excel"
        onClick={exportTableExcel}
        className="p-button-success mr-2 xl"
        data-pr-tooltip="XLS"
      />
      <Button
        type="button"
        icon="pi pi-file-pdf"
        onClick={exportPdf}
        className="p-button-warning mr-2 pdf"
        data-pr-tooltip="PDF"
      />
    </div>
  );

  return (
    <div>
      <DataTable
        id="offers-table"
        value={data}
        responsiveLayout="scroll"
        sortField="numberConnections"
        sortOrder={-1}
        dataKey="id"
        header={header}
        ref={dt}
      >
        {cols.map((col, index) => (
          <Column
            key={index}
            field={col.field}
            header={col.header}
            filterMenuClassName="filter-menu"
            filterMatchMode={col.dataType === "date" ? "dateIs" : "contains"}
            sortable={col.sortable}
            filter={col.sortable}
            dataType={col.dataType}
          />
        ))}
      </DataTable>
    </div>
  );
};

export default UsersTable;
