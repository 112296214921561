import EntrepriseCard from "Components/EntreprisesPartenaires/EntrepriseCard/EntrepriseCard";
import ButtonsBarDisconnected from "Components/Header/ButtonsBarDisconnected/ButtonsBarDisconnected";
import Header from "Components/Header/Header";
import Loader from "Components/Loader/loader";
import NavBarTab from "Components/NavBar/NavBarTab/NavBarTab";
import TempPagination from "Components/Pagination/TempPagination";
import { useFetchGetPagination } from "Services/api";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Dropdown } from "primereact/dropdown";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import AnnuaireEntrepriseSearchBar from "../AnnuaireEntrepriseSearchBar/AnnuaireEntrepriseSearchBar";
import "./AnnuaireEntrepriseContainer.scss";

const AnnuaireEntrepriseContainer = (props) => {
  const { t } = useTranslation("common");

  const annuaireContainerRef = useRef(null);
  const [triEntreprises, setTriEntreprises] = useState(null);
  const triEntreprisesData = [
    { label: "Par défaut", value: "Default" },
    { label: "Par secteur", value: "Secteur" },
    { label: "Par nombre croissant d'offres", value: "Croissant" },
    { label: "Par nombre décroissant d'offres", value: "Decroissant" },
  ];

  const handleTriEntreprises = (event) => {
    if (urlCompany === "") return;
    setTriEntreprises(event.value);
    let url = new URL(urlCompany);
    // Suppression des paramètres de tri
    url.searchParams.delete("order[sector.label]");
    url.searchParams.delete("order[numberOfOffersPublished]");

    switch (event.value) {
      case "Default":
        // Par défaut, on tri par nombre d'offres décroissant
        url.searchParams.set("order[numberOfOffersPublished]", "desc");

        break;
      case "Secteur":
        url.searchParams.set("order[sector.label]", "asc");
        break;
      case "Croissant":
        url.searchParams.set("order[numberOfOffersPublished]", "asc");
        break;
      case "Decroissant":
        url.searchParams.set("order[numberOfOffersPublished]", "desc");
        break;
      default:
        // Si aucun cas ne correspond, on retourne le tri par défaut
        url.searchParams.set("order[numberOfOffersPublished]", "desc");
        break;
    }
    setUrlCompany(url);
  };

  const [urlCompany, setUrlCompany] = useState("");
  const companiesQuery = useFetchGetPagination(urlCompany, props.auth.token);

  useEffect(() => {
    let url = new URL(`${process.env.REACT_APP_BASE_URL_API}/companies`);
    url.searchParams.set("itemsPerPage", 20);
    setUrlCompany(url);
  }, []);
  return (
    <div>
      <Header
        activeIndex={props.activeIndex}
        setActiveIndex={props.setActiveIndex}
        items={props.items}
      >
        {props.auth.isConnected ? (
          <>
            <NavBarTab
              select="annuaire"
              activeIndex={props.items.activeItemAnnuaire}
              items={props.items}
            />
          </>
        ) : (
          <ButtonsBarDisconnected />
        )}
      </Header>

      <div className="article_offre">
        <div className="annuaire__container" ref={annuaireContainerRef}>
          <div>
            <h1 className="h1_trait_dessus">
              {t("companyYearbook.companyTitle")}
            </h1>
            <p>
              {/* <p>{offres.length} offres correspondent à vos critères</p> */}
              {companiesQuery.loaded && companiesQuery.totalItems > 0 && (
                <p>
                  {Number(companiesQuery?.totalItems || "").toLocaleString()}{" "}
                  {t("companyYearbook.companyNumber")}
                </p>
              )}
            </p>
          </div>
          <div className="annuaire_filtre">
            <div className="annuaire_filtre__label">
              <Dropdown
                value={triEntreprises}
                options={triEntreprisesData}
                onChange={(e) => handleTriEntreprises(e)}
                placeholder={t("companyYearbook.companyTri")}
              />
            </div>
          </div>
        </div>
        <AnnuaireEntrepriseSearchBar
          setUrlCompany={setUrlCompany}
          urlCompany={urlCompany}
        />
        <Accordion expandIcon="pi pi-sliders-h" collapseIcon="pi pi-sliders-h">
          <AccordionTab header="Fitres de recherche">
            <AnnuaireEntrepriseSearchBar
              setUrlCompany={setUrlCompany}
              urlCompany={urlCompany}
            />
          </AccordionTab>
        </Accordion>
        <div className="annuaire__container__cards">
          {companiesQuery.loaded ? (
            companiesQuery.data?.map((entreprise) => (
              <EntrepriseCard
                key={entreprise.id}
                type={
                  entreprise.structure
                    ? entreprise.structure.label
                    : entreprise.type.label
                }
                entreprise={entreprise}
                taille={
                  entreprise.totalStaff
                    ? entreprise.totalStaff.label
                    : "Non renseigné"
                }
                offresDisponibles={true}
              />
            ))
          ) : (
            <Loader></Loader>
          )}
        </div>

        <TempPagination
          result={companiesQuery}
          refItemParent={annuaireContainerRef}
          rows={20}
        />
      </div>
    </div>
  );
};

AnnuaireEntrepriseContainer.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.object,
  activeIndex: PropTypes.number,
  setActiveIndex: PropTypes.func,
  companies: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
  companies: state.companies,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnuaireEntrepriseContainer);
