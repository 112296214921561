import { UPDATE_GROUPS } from "Redux/Actions/groupsActions";

const INITIAL_STATE = {
  motsClés: "",
  myGroupsMotsClés: "",
  category: [],
  status: "Tous",
  date: "",
  groupsData: [],
  groupsFiltered: [],
  isSearchedGroups: false,
  myGroupsData: [],
  myGroupsFiltered: [],
  isSearchedMyGroups: false,
  groupsEvents: [],
  groupsEventsFiltered: [],
  groupsEventsParticipants: [],
  domaines: [],
  statuses: [],
  categories: [],
  member_statuses: [],
  composantes: [],

  roles: [],

  // EVENEMENTS DE GROUPES
  evenements: [],
  isSearchedEvent: false,

  publications: [],
  publicationsComments: [],
  domainesFormation: [],
  specialitesFormation: [],
  group_status_envoi: [],
  users: [],
  selectedGroup: null,
  filteringURL: null,
};

const groupsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_GROUPS: {
      return {
        ...state,
        ...action.value,
      };
    }
    default:
      return state;
  }
};

export default groupsReducer;
