import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import React from "react";
import "./EntrepriseDetail.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import fond from "assets/banniere_groupe.webp";
import { FaSuitcase, FaUsers } from "react-icons/fa";
import RecruteurCard from "Components/Annuaire/AnnuaireEntreprise/RecruteurCard/RecruteurCard";
import { useEffect, useState } from "react";
import {
  displayCompanyImage,
  formatDate,
  sanitizeHtml,
} from "Services/functions";
import { useFetchGet, useFetchGetPagination } from "Services/api";
import { useParams } from "react-router-dom";
import Offre from "Components/Offres/Offre/Offre";
import Loader from "Components/Loader/loader";
import parse from "html-react-parser";
import { Divider } from "primereact/divider";

const EntrepriseDetail = (props) => {
  const { id } = useParams();
  const [urlOffers, setUrlOffers] = useState("");

  const [logo, setLogo] = useState(null);

  const offersQuery = useFetchGetPagination(urlOffers, props.auth.token);
  const company = useFetchGet(`/companies/${id}`, props.auth.token);

  const [description, setDescription] = useState("");
  /**
   * Permet de parser la description de l'entreprise
   *
   * Etant un champ de type HTML, il est nécessaire de le parser pour éviter les failles XSS
   * et pour avoir un bon rendu
   * @returns {void}
   */
  const parseDescription = () => {
    let clearedDescription = parse(
      sanitizeHtml(
        company.data.description || "Aucune description n'a été renseignée"
      )
    );
    setDescription(clearedDescription);
  };

  useEffect(() => {
    if (company.loaded) {
      let urlOffers = new URL(`${process.env.REACT_APP_BASE_URL_API}/offers`);
      urlOffers.searchParams.set("companyName", company.data.name);
      urlOffers.searchParams.set(
        "transmitter",
        props.secondaryTables.transmittersData.find(
          (transmitter) => transmitter.name === "Recruteur"
        ).id
      );
      setUrlOffers(urlOffers);
      !description && parseDescription();
      !logo &&
        setLogo(
          displayCompanyImage(company.data) ||
            require("assets/placeholderLogo.jpg")
        );
    }
  }, [company.loaded]);

  return (
    <div>
      <Header noImage={true} />
      {props.auth.isConnected &&
        (company.loaded ? (
          <div className="entreprise recruteur">
            <div className="entreprise_top">
              <div className="entreprise_gauche">
                <div className="recruteur">
                  <h1>LES RECRUTEURS</h1>
                </div>
                {company.data?.recruiters?.length > 0 &&
                  company.data.recruiters.map((recruiter, index) => (
                    <RecruteurCard
                      key={index}
                      title={recruiter.profilTitle || "Non renseignée"}
                      nom={recruiter.surname}
                      prenom={recruiter.firstname}
                      telephone={recruiter.telephone}
                      phonePublic={recruiter.telephoneIsPublic}
                      image={recruiter.imagePath}
                      mailPublic={recruiter.mailIsPublic}
                      email={recruiter.email}
                    />
                  ))}
              </div>
              <div className="entreprise_droite">
                <div className="offre_main">
                  <div className="offre_main__top">
                    <img
                      src={fond}
                      alt="Fond de l'entreprise"
                      title="Fond de l'entreprise"
                    />
                    <img
                      src={logo}
                      alt="Logo de l'entreprise"
                      title="Logo de l'entreprise"
                      className="logo_entreprise"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = require(`assets/placeholderLogo.jpg`);
                      }}
                    />
                    <div className="offre_main__top__header">
                      <h5>{company.data.name}</h5>
                      <div className="offre_main__top__header__informations">
                        <p>
                          <FaUsers />{" "}
                          {company.data.totalStaff
                            ? company.data.totalStaff.label
                            : "Non renseigné"}
                        </p>
                        <p>
                          {company.structure
                            ? company.data.structure.label
                            : company.data.type.label}
                        </p>
                      </div>
                      <p className="offres_disponibles">
                        <FaSuitcase />{" "}
                        <span>
                          {offersQuery.data?.length} offres disponibles
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="offre_main__description">
                    <h5>Description de l&apos;entreprise</h5>
                    <Divider />
                    <p>{description}</p>
                    <Divider />
                  </div>
                </div>
              </div>
            </div>

            {offersQuery.loaded && offersQuery.data.length > 0 && (
              <div className="entreprise_bottom">
                <h1>LES DERNIÈRES OFFRES DE L&apos;ENTREPRISE</h1>
                <div className="entreprise__offres">
                  {offersQuery.data
                    .filter((offer) => offer.state.statusLabel === "Publié")
                    .sort((a, b) => {
                      return new Date(b.modifiedAt) - new Date(a.modifiedAt);
                    })
                    .map(
                      (offre, index) =>
                        index < 4 && (
                          <Offre
                            categorie={0}
                            url={
                              offre?.imageUrl || offre?.nexusImageUrl || "img1"
                            }
                            nexusUrl={offre?.nexusImageUrl}
                            offreDetails={offre}
                            date={formatDate(offre.postedAt, ".")}
                            accessibleForDisabled={offre.accessibleForDisabled}
                            access={index}
                            key={index}
                          />
                        )
                    )}
                </div>
              </div>
            )}
          </div>
        ) : (
          <Loader></Loader>
        ))}

      <Footer />
    </div>
  );
};

EntrepriseDetail.propTypes = {
  auth: PropTypes.object,
  secondaryTables: PropTypes.object,

  title: PropTypes.string,
  tailleEntreprise: PropTypes.string,
  nbSalaries: PropTypes.string,
  parrain: PropTypes.bool,

  entrepriseProps: PropTypes.any,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  secondaryTables: state.secondaryTables,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EntrepriseDetail);
