import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import "./CreationCompteEntreprise.scss";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { InputTextarea } from "primereact/inputtextarea";
import { updateAuth } from "Redux/Actions/authActions";
import { Checkbox } from "primereact/checkbox";
import ModalCharte from "../ModalCharte/ModalCharte";

const CreationCompteEntreprise = (props) => {
  const [showMessage, setShowMessage] = useState(false);
  const [formData, setFormData] = useState({});
  const [visibleModalCharte, setvisibleModalCharte] = useState(false);

  // constantes de l'utilisateur
  let defaultValues = {
    lastName: "",
    firstName: "",
    anneeDiplome: null,
    email: "",
    phone: "",
    description: "",
    accept: false,
    french: true,
    international: false,
    association: false,
    siret: "",
    companyName: "",
    role: "",
    CGU: false,
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useForm({ defaultValues });

  const onSubmit = () => {
    const data = getValues();
    setFormData(data);
    setShowMessage(true);

    reset();
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const civilityData = [
    { label: "M", id: "M" },
    { label: "Mme", id: "Mme" },
  ];

  return (
    <div className="form-demo">
      <Dialog
        appendTo={document.getElementById("app")}
        visible={showMessage}
        onHide={() => setShowMessage(false)}
        position="top"
        showHeader={false}
        breakpoints={{ "960px": "80vw" }}
        style={{ width: "30vw" }}
      >
        <div className="flex justify-content-center flex-column pt-6 px-3">
          <i
            className="pi pi-check-circle"
            style={{ fontSize: "5rem", color: "var(--green-500)" }}
          ></i>
          <h5>Création de compte terminée !</h5>
          <p style={{ lineHeight: 1.5, textIndent: "1rem" }}>
            Votre compte est enregistré sous le nom de <b>{formData.name}</b> .
            Un mail vous sera envoyé à <b>{formData.email}</b> une fois votre
            compte activé.
          </p>
        </div>
      </Dialog>
      <div className="test">
        <div className="flex justify-content-center">
          <div className="card">
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
              <div className="field">
                <h2>Vous êtes *</h2>

                <div className="checkboxes">
                  <div className="field checkbox">
                    <Controller
                      name="french"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          className="checkbox_french"
                          id={field.name}
                          checked={field.value}
                          onChange={(e) => {
                            field.onChange(e.checked);
                            setValue("international", !e.checked);
                            setValue("association", !e.checked);
                          }}
                          disabled={field.value}
                        />
                      )}
                    />
                    <label htmlFor="french">Entreprise située en France</label>
                    {getFormErrorMessage("french")}
                  </div>
                  <div className="field checkbox">
                    <Controller
                      name="international"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          className="checkbox_international"
                          id={field.name}
                          checked={field.value}
                          onChange={(e) => {
                            field.onChange(e.checked);
                            setValue("french", !e.checked);
                            setValue("association", !e.checked);
                          }}
                          disabled={field.value}
                        />
                      )}
                    />
                    <label htmlFor="international">
                      Une entreprise internationale
                    </label>
                    {getFormErrorMessage("international")}
                  </div>
                  <div className="field checkbox">
                    <Controller
                      name="association"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          className="checkbox_association"
                          id={field.name}
                          checked={field.value}
                          onChange={(e) => {
                            field.onChange(e.checked);
                            setValue("french", !e.checked);
                            setValue("international", !e.checked);
                          }}
                          disabled={field.value}
                        />
                      )}
                    />
                    <label htmlFor="association">Une association</label>
                    {getFormErrorMessage("association")}
                  </div>
                </div>
              </div>

              <div className="field">
                <h2>N° de SIRET de votre entreprise *</h2>
                <Controller
                  name="siret"
                  control={control}
                  rules={{ required: "Le prénom est requis" }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      placeholder="567 873 083 78243"
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                {getFormErrorMessage("siret")}
              </div>

              <div className="field">
                <h2>Nom de l&apos;entreprise *</h2>
                <Controller
                  name="companyName"
                  control={control}
                  rules={{ required: "Le nom est requis" }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      placeholder="Actimage ..."
                      {...field}
                      autoFocus
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                {getFormErrorMessage("companyName")}
              </div>

              <div className="field">
                <h2>Description de l&apos;entreprise *</h2>
                <Controller
                  name="description"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputTextarea
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                {getFormErrorMessage("description")}
              </div>

              <div className="field">
                <h2>Civilité</h2>
                <Controller
                  name="civility"
                  control={control}
                  rules={{ required: "Le niveau de diplôme est requis" }}
                  render={(field) => (
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      options={civilityData}
                      onChange={(e) => field.onChange(e.value)}
                    />
                  )}
                />

                {getFormErrorMessage("civility")}
              </div>

              <div className="ask_connexion_name">
                <div className="field">
                  <h2>Prénom *</h2>
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{ required: "Le prénom est requis" }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        placeholder="Marie, Arthur..."
                        //   autoFocus
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  {getFormErrorMessage("name")}
                </div>
                <div className="field">
                  <h2>Nom *</h2>
                  <Controller
                    name="lastName"
                    control={control}
                    rules={{ required: "Le nom est requis" }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        placeholder="Dupont..."
                        {...field}
                        autoFocus
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  {getFormErrorMessage("name")}
                </div>
              </div>

              <div className="field">
                <h2>Votre fonction dans l&apos;entreprise *</h2>
                <Controller
                  name="role"
                  control={control}
                  rules={{ required: "Le nom est requis" }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      placeholder="PDG, chargé de communication ..."
                      {...field}
                      autoFocus
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                {getFormErrorMessage("role")}
              </div>

              <div className="ask_connexion_coordinates">
                <div className="field">
                  <h2>Adresse mail professionnelle *</h2>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: "Email is required.",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message:
                          "Invalid email address. E.g. example@email.com",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        placeholder="hello123@gmail.com"
                        {...field}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  {getFormErrorMessage("email")}
                </div>
                <div className="field">
                  <h2>Numéro de téléphone</h2>
                  <Controller
                    name="phone"
                    control={control}
                    rules={{
                      required: "Le numéro de téléphone est requis.",
                    }}
                    render={({ field }) => (
                      <InputMask
                        id={field.name}
                        mask="+999 (9)9 99 99 99 99"
                        value={field.value}
                        placeholder="+999 (9)9 99 99 99 99"
                        onChange={(e) => field.onChange(e.value)}
                      ></InputMask>
                    )}
                  />

                  {getFormErrorMessage("email")}
                </div>
              </div>

              <BtnBlanc
                btnTexte="Envoyer"
                btnAction={(e) => {
                  e.preventDefault();
                  setvisibleModalCharte(!visibleModalCharte);
                }}
              />
            </form>
            {visibleModalCharte && (
              <ModalCharte
                visibleModal={visibleModalCharte}
                setVisibleModal={setvisibleModalCharte}
                btnRefuse={() => {
                  props.setVisibleModal(false);
                }}
                btnAction={() => {
                  onSubmit();
                }}
              >
                <div className="description">
                  <ModalCharte />
                </div>
              </ModalCharte>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

CreationCompteEntreprise.propTypes = {
  title: PropTypes.string,
  chapeau: PropTypes.string,
  bouton: PropTypes.string,
  styleFormulaireMembre: PropTypes.bool,
  utilisateur: PropTypes.any,
  handleAuth: PropTypes.func,
  auth: PropTypes.object,
  visibleModal: PropTypes.func,
  setVisibleModal: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  handleAuth: (value) => {
    dispatch(updateAuth(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreationCompteEntreprise);
