import React, { useState } from "react";
import Doughnut from "../Charts/Doughnut";
import Margin from "../Margin";

/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "Entreprises" }]*/
// Désactivation de la règle ESL pour cet import car nécessaire pour JSDOC
import Entreprises from "./Entreprises";

import {
  getStudentsByUni,
  getStudentsByUniInDoughnutOptions,
} from "../Data/Etudiants";

import {
  getUsersWhoApplied,
  getUsersWhoAppliedOptions,
} from "../Data/Utilisateurs";

/**
 * Composant Superadmin sert à afficher les graphiques de la partie super admin
 * à l'aide  de primeReact et de composants custom.
 *
 * Ces graphs servent à regrouper les données des différentes universités pour obtenir
 * une vue globale contrairement aux autres composants type {@link Entreprises} qui vont
 * se focaliser sur une université en particulier en fonction des droits accordés à la
 * personne.
 *
 * Les données ainsi ques les options proviennent du dossier Data, et sont
 * stockées grâce aux hooks {@link useState}.
 *
 * Les composants custom utilisés :
 * - {@link Line},
 * - {@link Doughnut}
 */

const Superadmin = () => {
  const [studentsUniData] = useState(getStudentsByUni());
  const [studentsUniInDoughnutOptions] = useState(
    getStudentsByUniInDoughnutOptions()
  );

  const [usersWhoAppliedData] = useState(getUsersWhoApplied());
  const [usersWhoAppliedOptions] = useState(getUsersWhoAppliedOptions());

  return (
    <div className="stats_container">
      <div className="stats_container__component-center">
        <Doughnut
          data={studentsUniData}
          options={studentsUniInDoughnutOptions}
        />
      </div>
      <Margin position="bottom" size="" />
      <div className="stats_container__component-center">
        <Doughnut data={usersWhoAppliedData} options={usersWhoAppliedOptions} />
      </div>
      <Margin position="bottom" size="s" />
    </div>
  );
};

export default Superadmin;
