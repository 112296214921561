import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { updateAuth } from "Redux/Actions/authActions";
import { updateMenu } from "Redux/Actions/menuActions";
import { connect } from "react-redux";
import { Divider } from "primereact/divider";
import laurier_gris from "assets/laurier_gris.webp";
import { useTranslation } from "react-i18next";

/**
 * Construit les menus déroulant de la barre de navigation sur desktop
 */
const MenuDepliant = (props) => {
  const { t } = useTranslation("common");
  return (
    <div>
      {props.item == "event" && (
        <ul>
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemEvenement: props.items.itemsEvenement.findIndex(
                  (v) => v.label == "Tous les événements"
                ),
              });
            }}
          >
            <div className="annuaireBox">
              {/* c'est ici que apparaît l'erreur de validate DOM  */}
              <Link to="/evenements">
                {t("evenementsHorsConnexion.title_page_evenements")}
              </Link>
            </div>
          </li>
          <Divider />
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemEvenement: props.items.itemsEvenement.findIndex(
                  (v) => v.label == "Proposer un événement"
                ),
              });
            }}
          >
            <div className="annuaireBox">
              <Link to="/evenements" className="alumni">
                {t("eventApresConnexion.submenuProposerAlumni")}
              </Link>
            </div>
          </li>
          <Divider />
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemEvenement: props.items.itemsEvenement.findIndex(
                  (v) => v.label == "Gérer mes événements"
                ),
              });
            }}
          >
            <div className="annuaireBox">
              <Link to="/evenements">
                {t("eventApresConnexion.submenuGererAlumni")}
              </Link>
            </div>
          </li>
        </ul>
      )}
      {props.item == "eventadmin" && (
        <ul>
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemEvenementAdmin:
                  props.items.itemsEvenementAdmin.findIndex(
                    (v) => v.label == "Tous les événements"
                  ),
              });
            }}
          >
            <div className="annuaireBox">
              <Link to="/evenements">
                {t("evenementsHorsConnexion.title_page_evenements")}
              </Link>
            </div>
          </li>
          <Divider />
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemEvenementAdmin:
                  props.items.itemsEvenementAdmin.findIndex(
                    (v) => v.label == "Créer un événement"
                  ),
              });
            }}
          >
            <div className="annuaireBox">
              <Link to="/evenements" className="alumni">
                {t("eventApresConnexion.submenuCreateAdmin")}
              </Link>
            </div>
          </li>
          <Divider />
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemEvenementAdmin:
                  props.items.itemsEvenementAdmin.findIndex(
                    (v) => v.label == "Gérer les événements"
                  ),
              });
            }}
          >
            <div className="annuaireBox">
              <Link to="/evenements">
                {t("eventApresConnexion.submenuGererAdmin")}
              </Link>
            </div>
          </li>
        </ul>
      )}
      {props.item == "actu" && (
        <ul>
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemActu: props.items.itemsActu.findIndex(
                  (v) => v.label == "Toutes les actus"
                ),
              });
            }}
          >
            <div className="annuaireBox">
              <Link to="/actualites">
                {t("actusApresConnexion.submenuAll")}
              </Link>
            </div>
          </li>
          <Divider />
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemActu: props.items.itemsActu.findIndex(
                  (v) => v.label == "Proposer une actu"
                ),
              });
            }}
          >
            <div className="annuaireBox">
              <Link to="/actualites">
                {t("actusApresConnexion.submenuCreateAlumni")}
              </Link>
            </div>
          </li>
          <Divider />
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemActu: props.items.itemsActu.findIndex(
                  (v) => v.label == "Gérer mes actus"
                ),
              });
            }}
          >
            <div className="annuaireBox">
              <Link to="/actualites">
                {t("actusApresConnexion.submenuGerer")}
              </Link>
            </div>
          </li>
        </ul>
      )}
      {props.item == "actuadmin" && (
        <ul>
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemActuAdmin: props.items.itemsActuAdmin.findIndex(
                  (v) => v.label == "Toutes les actus/conseils"
                ),
              });
            }}
          >
            <div className="annuaireBox">
              <Link to="/actualites">
                {t("actusApresConnexion.submenuAllAdmin")}
              </Link>
            </div>
          </li>
          <Divider />
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemActuAdmin: props.items.itemsActuAdmin.findIndex(
                  (v) => v.label == "Créer une actu/conseil"
                ),
              });
            }}
          >
            <div className="annuaireBox">
              <Link to="/actualites">
                {t("actusApresConnexion.submenuCreateAdmin")}
              </Link>
            </div>
          </li>
          <Divider />
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemActuAdmin: props.items.itemsActuAdmin.findIndex(
                  (v) => v.label == "Gérer les actus/conseils"
                ),
              });
            }}
          >
            <div className="annuaireBox">
              <Link to="/actualites">
                {t("actusApresConnexion.submenuGererAdmin")}
              </Link>
            </div>
          </li>
        </ul>
      )}
      {props.item == "offres" && (
        <ul>
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemOffre: props.items.itemsOffre.findIndex(
                  (v) => v.label == "Trouver une offre"
                ),
              });
            }}
          >
            <div className="annuaireBox">
              <Link to="/offres">{t("offerItem.findOffer")}</Link>
            </div>
          </li>
          <Divider />
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemOffre: props.items.itemsOffre.findIndex(
                  (v) => v.label == "Déposer une offre"
                ),
              });
            }}
          >
            <div className="annuaireBox">
              <Link to="/offres">{t("offerItem.createOffer")}</Link>
            </div>
          </li>
          <Divider />
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemOffre: props.items.itemsOffre.findIndex(
                  (v) => v.label == "Gérer mes dépôts d'offres"
                ),
              });
            }}
          >
            <div className="annuaireBox">
              <Link to="/offres">{t("offerItem.handleOffer")}</Link>
            </div>
          </li>
        </ul>
      )}
      {props.item == "groupe" && (
        <ul>
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemGroupes: props.items.itemsGroupes.findIndex(
                  (v) => v.label == "Mes groupes d'échanges"
                ),
              });
            }}
          >
            <div className="annuaireBox">
              <Link to="/groupes">
                {t("footerApresConnexion.li_groupes_echanges")}
              </Link>
            </div>
          </li>
          <Divider />
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemGroupes: props.items.itemsGroupes.findIndex(
                  (v) => v.label == "Trouver un groupe"
                ),
              });
            }}
          >
            <div className="annuaireBox">
              <Link to="/groupes">
                {t("boutonsAccueilApresConnexion.trouverGroupe")}
              </Link>
            </div>
          </li>
          <Divider />
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemGroupes: props.items.itemsGroupes.findIndex(
                  (v) => v.label == "Événements de groupe"
                ),
              });
            }}
          >
            <div className="annuaireBox">
              <Link to="/groupes">
                {t("footerApresConnexion.li_groupes_evenements")}
              </Link>
            </div>
          </li>
          <Divider />
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemGroupes: props.items.itemsGroupes.findIndex(
                  (v) => v.label == "Proposer et gérer mes groupes"
                ),
              });
            }}
          >
            <div className="annuaireBox">
              <Link to="/groupes">
                {t("boutonsAccueilApresConnexion.handleGroups")}
              </Link>
            </div>
          </li>
        </ul>
      )}
      {props.item == "groupeadmin" && (
        <ul>
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemGroupes: props.items.itemsGroupes.findIndex(
                  (v) => v.label == "Mes groupes d'échanges"
                ),
              });
            }}
          >
            <div className="annuaireBox">
              <Link to="/groupes">
                {t("footerApresConnexion.li_groupes_echanges")}
              </Link>
            </div>
          </li>
          <Divider />
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemGroupes: props.items.itemsGroupes.findIndex(
                  (v) => v.label == "Trouver un groupe"
                ),
              });
            }}
          >
            <div className="annuaireBox">
              <Link to="/groupes">
                {t("boutonsAccueilApresConnexion.trouverGroupe")}
              </Link>
            </div>
          </li>
          <Divider />
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemGroupes: props.items.itemsGroupes.findIndex(
                  (v) => v.label == "Événements de groupe"
                ),
              });
            }}
          >
            <div className="annuaireBox">
              <Link to="/groupes">
                {t("footerApresConnexion.li_groupes_evenements")}
              </Link>
            </div>
          </li>
          <Divider />
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemGroupes: props.items.itemsGroupes.findIndex(
                  (v) => v.label == "Créer et gérer mes groupes"
                ),
              });
            }}
          >
            <div className="annuaireBox">
              <Link to="/groupes">
                {t("boutonsAccueilApresConnexion.handleGroupsAdmin")}
              </Link>
            </div>
          </li>
        </ul>
      )}
      {props.item == "annuaire" && (
        <ul>
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemAnnuaire: props.items.itemsAnnuaire.findIndex(
                  (v) => v.label == "Annuaire Alumni"
                ),
              });
            }}
          >
            <div className="annuaireBox">
              <img
                src={laurier_gris}
                className="laurier"
                alt="Laurier alumni"
                title="Laurier alumni"
              />
              <i className="pi pi-star" style={{ fontSize: "1.2rem" }}>
                {" "}
              </i>
              <Link to="/annuaire" className="alumni">
                {t("academicYearbook.alumniTitle")}
              </Link>
            </div>
          </li>
          <Divider />
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemAnnuaire: props.items.itemsAnnuaire.findIndex(
                  (v) => v.label == "Annuaire entreprises"
                ),
              });
            }}
          >
            <div className="annuaireBox">
              <i className="pi pi-building" style={{ fontSize: "1.2rem" }}></i>
              <Link to="/annuaire">{t("companyYearbook.companyTitle")}</Link>
            </div>
          </li>
        </ul>
      )}
      {props.item == "parametres" && (
        <ul>
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemParametrage: props.items.itemsParametrage.findIndex(
                  (v) => v.label == "Requêtes et exports"
                ),
              });
            }}
          >
            <div className="annuaireBox">
              <Link to="/parametrage">Requêtes et exports</Link>
            </div>
          </li>
          <Divider />
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemParametrage: props.items.itemsParametrage.findIndex(
                  (v) => v.label == "Gestion des membres"
                ),
              });
            }}
          >
            <div className="annuaireBox">
              <Link to="/parametrage">Gestion des membres</Link>
            </div>
          </li>
          <Divider />
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemParametrage: props.items.itemsParametrage.findIndex(
                  (v) => v.label == "Gestion éditoriale"
                ),
              });
            }}
          >
            <div className="annuaireBox">
              <Link to="/parametrage">Gestion éditoriale</Link>
            </div>
          </li>
          <Divider />
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemParametrage: props.items.itemsParametrage.findIndex(
                  (v) => v.label == "Paramètres du site"
                ),
              });
            }}
          >
            <div className="annuaireBox">
              <Link to="/parametrage">Paramètres du site</Link>
            </div>
          </li>
          <Divider />
          <li
            onClick={(e) => {
              e.stopPropagation();
              props.handleMenu({
                activeItemParametrage: props.items.itemsParametrage.findIndex(
                  (v) => v.label == "Statistiques"
                ),
              });
            }}
          >
            <div className="annuaireBox">
              <Link to="/parametrage">Statistiques</Link>
            </div>
          </li>
        </ul>
      )}
    </div>
  );
};

MenuDepliant.propTypes = {
  menuNotConnected: PropTypes.any,
  role: PropTypes.string,
  item: PropTypes.string,

  navbarOpen: PropTypes.bool,
  handleToggle: PropTypes.func,
  auth: PropTypes.object,
  handleAuth: PropTypes.func,
  handleMenu: PropTypes.func,

  items: PropTypes.object,
  annuaire: PropTypes.number,

  activeIndex: PropTypes.number,
  setActiveIndex: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
});

const mapDispatchToProps = (dispatch) => ({
  handleAuth: (value) => {
    dispatch(updateAuth(value));
  },
  handleMenu: (value) => {
    dispatch(updateMenu(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuDepliant);
