import { Companies, Months, Offers, Utils } from "./Const";

export function getOffersInACompany() {
  return {
    labels: Offers.categories,
    datasets: [
      {
        data: Utils.generateData(6, 20, 200),
        backgroundColor: Offers.categoriesCompanyColor,
        hoverBackgroundColor: Offers.categoriesCompanyColorHover,
      },
    ],
  };
}

export function getOffersInACompanyOptions(company) {
  return {
    plugins: {
      datalabels: {
        formatter: (value, ctx) => Utils.calculatePercentage(value, ctx, true),
        display: true,
        color: "#FDFDFD",
      },
      legend: {
        labels: {
          color: "#495057",
        },
      },
      title: Utils.generateTitle(
        "Nombre d'offres par catégorie pour l'" +
          company +
          " sur l'université A"
      ),
    },
  };
}

export function getCompaniesAndTheirOffers() {
  return {
    labels: Companies.firstFiveCompanies,
    datasets: [
      {
        label: "Nombre d'offres",
        backgroundColor: Companies.firstFiveColors,
        hoverBackgroundColor: Companies.firstFiveColorsHover,
        data: Utils.generateData(5, 40, 500),
      },
    ],
  };
}

export function getCompaniesAndTheirOffersOptions() {
  return {
    plugins: {
      datalabels: {
        display: true,
        color: "#FDFDFD",
      },
      legend: {
        labels: {
          color: "#495057",
        },
      },
      title: Utils.generateTitle(
        "Nombre d'offres par entreprise sur l'université A"
      ),
    },
  };
}

export function getCompaniesAndOffersByMonth() {
  return {
    labels: Months.firstSeven,
    datasets: [
      {
        label: "Entreprises",
        backgroundColor: Companies.firstFiveColors.at(2),
        hoverBackgroundColor: Companies.firstFiveColorsHover.at(2),
        yAxisID: "y",
        data: Utils.generateAscendingData(7, 4, 9, 25),
      },
      {
        label: "Offres d'entreprises",
        backgroundColor: Companies.firstFiveColors.at(1),
        hoverBackgroundColor: Companies.firstFiveColorsHover.at(1),
        yAxisID: "y1",
        data: Utils.generateAscendingData(7, 40, 120, 90),
      },
    ],
  };
}

export function getCompaniesAndOffersByMonthOptions() {
  return {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      datalabels: {
        display: true,
        color: "#191B1B",
        align: "end",
        offset: 8,
      },
      legend: {
        labels: {
          color: "#495057",
        },
      },
      title: Utils.generateTitle(
        "Nombre total d'entreprises et nombre d'offres d'entreprises sur l'université A"
      ),
    },
    scales: {
      x: {
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
      y: {
        type: "linear",
        display: true,
        position: "left",
        ticks: {
          color: Companies.firstFiveColorsHover.at(2),
        },
        grid: {
          color: "#ebedef",
        },
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false,
          color: "#ebedef",
        },
        ticks: {
          color: Companies.firstFiveColorsHover.at(1),
        },
      },
    },
  };
}
