import React, { useState } from "react";
import Bar from "../Charts/Bar";
import Doughnut from "../Charts/Doughnut";
import Margin from "../Margin";

import {
  getNewUsersByMonth,
  getNewUsersByMonthAndChart,
  getNewUsersByMonthAndChartOptions,
  getNewUsersByMonthOptions,
  getTotalUsersAndAlumnis,
  getTotalUsersAndAlumnisOptions,
  getUsersRegardingChart,
  getUsersRegardingChartOptions,
} from "../Data/Utilisateurs";
import {
  getNewStudentsByLevelDaily,
  getNewStudentsByLevelDailyOptions,
  getStudentsByLevel,
  getStudentsByLevelOptions,
  getStudentsByMonthByUniversity,
  getStudentsByMonthByUniversityOptions,
} from "../Data/Etudiants";
import UsersTable from "../Tables/UsersTable";

/**
 * Composant Utilisateurs sert à afficher les graphiques de la partie utilisateurs
 * à l'aide  de primeReact et de composants custom.
 *
 * Les données ainsi ques les options proviennent du dossier Data, et sont
 * stockées grâce aux hooks {@link useState}.
 *
 * Les composants custom utilisés :
 * - {@link Bar},
 * - {@link Doughnut}
 */
const Utilisateurs = () => {
  const [totalUsersAndAlumniData] = useState(getTotalUsersAndAlumnis());
  const [totalUsersAndAlumniOptions] = useState(
    getTotalUsersAndAlumnisOptions()
  );

  const [newUsersByMonthData] = useState(getNewUsersByMonth());
  const [newUsersByMonthOptions] = useState(getNewUsersByMonthOptions());

  const [usersRegardingChartData] = useState(getUsersRegardingChart());
  const [usersRegardingChartOptions] = useState(
    getUsersRegardingChartOptions()
  );

  const [studentsMonthData] = useState(getStudentsByMonthByUniversity());
  const [studentsMonthOptions] = useState(
    getStudentsByMonthByUniversityOptions("A")
  );

  const [newUsersRegardingChartMonthlyData] = useState(
    getNewUsersByMonthAndChart()
  );
  const [newUsersRegardingChartMonthlyOptions] = useState(
    getNewUsersByMonthAndChartOptions()
  );

  const [studentsByLevelData] = useState(getStudentsByLevel());
  const [studentsByLevelOptions] = useState(getStudentsByLevelOptions());

  const [newStudentsByLevelDailyData] = useState(getNewStudentsByLevelDaily());
  const [newStudentsByLevelDailyOptions] = useState(
    getNewStudentsByLevelDailyOptions()
  );

  return (
    <div className="stats_container">
      <div className="stats_container__component-center">
        <Doughnut
          data={totalUsersAndAlumniData}
          options={totalUsersAndAlumniOptions}
        />
      </div>
      <Margin position="bottom" size="" />
      <Bar data={studentsMonthData} options={studentsMonthOptions} />
      <Margin position="bottom" size="" />
      <div className="stats_container__component-center">
        <Doughnut data={studentsByLevelData} options={studentsByLevelOptions} />
      </div>
      <Margin position="bottom" size="" />
      <Bar
        data={newStudentsByLevelDailyData}
        options={newStudentsByLevelDailyOptions}
      />
      <Margin position="bottom" size="" />
      <Bar data={newUsersByMonthData} options={newUsersByMonthOptions} />
      <Margin position="bottom" size="" />
      <div className="stats_container__component-center">
        <Doughnut
          data={usersRegardingChartData}
          options={usersRegardingChartOptions}
        />
      </div>
      <Margin position="bottom" size="" />
      <Bar
        data={newUsersRegardingChartMonthlyData}
        options={newUsersRegardingChartMonthlyOptions}
      />
      <Margin position="bottom" size="" />
      <UsersTable />
      <Margin position="bottom" size="s" />
    </div>
  );
};

export default Utilisateurs;
