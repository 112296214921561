import { Months, Companies, Utils, Days, Users } from "./Const";

export function getStudentsByUni() {
  return {
    datasets: [
      {
        data: [210, 180, 150, 220, 179],
        backgroundColor: Companies.firstFiveColors,
        hoverBackgroundColor: Companies.firstFiveColorsHover,
      },
    ],
    labels: Companies.firstFiveUni,
  };
}

export function getStudentsByUniInDoughnutOptions() {
  return {
    plugins: {
      datalabels: {
        formatter: (value, ctx) => Utils.calculatePercentage(value, ctx),
        display: true,
        color: "#FDFDFD",
      },
      legend: {
        labels: {
          color: "#495057",
        },
      },
      title: Utils.generateTitle("Nombre d'étudiants par université"),
    },
  };
}

export function getStudentsByMonthByUniversity() {
  return {
    labels: Months.all,
    datasets: [
      {
        label: "Nombre d'étudiants",
        backgroundColor: "#05668d",
        hoverBackgroundColor: "#0E3C4E",
        data: Utils.generateData(12, 50, 400),
      },
      {
        label: "Nombre d'alumni",
        backgroundColor: "#02c39a",
        hoverBackgroundColor: "#02765D",
        data: Utils.generateData(12, 20, 290),
      },
    ],
  };
}

export function getStudentsByMonthByUniversityOptions() {
  return {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      datalabels: {
        display: true,
        color: "#FDFDFD",
      },
      legend: {
        labels: {
          color: "#495057",
        },
      },
      title: Utils.generateTitle(
        "Nombre de nouveaux étudiants et alumni par mois dans RéseauPro"
      ),
    },
    scales: {
      x: {
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
      y: {
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
    },
  };
}

export function getNewStudentsByLevelDaily() {
  return {
    labels: Days.lastSevenDays,
    datasets: [
      {
        type: "bar",
        label: Users.usersProfiles[0],
        data: Utils.generateData(7, 20, 120),
        fill: false,
        backgroundColor: "#05668D",
        hoverBackgroundColor: "#02425A",
      },
      {
        type: "bar",
        label: Users.usersProfiles[1],
        data: Utils.generateData(7, 20, 120),
        fill: false,
        backgroundColor: "#11279A",
        hoverBackgroundColor: "#071562",
      },
      {
        type: "bar",
        label: Users.usersProfiles[2],
        data: Utils.generateData(7, 20, 120),
        fill: false,
        backgroundColor: "#00A33D",
        hoverBackgroundColor: "#006827",
      },
      {
        type: "bar",
        label: Users.usersProfiles[3],
        data: Utils.generateData(7, 20, 120),
        fill: false,
        backgroundColor: "#74CD00",
        hoverBackgroundColor: "#4A8300",
      },
      {
        type: "bar",
        label: Users.usersProfiles[4],
        data: Utils.generateData(7, 20, 120),
        fill: false,
        backgroundColor: "#C7DC00",
        hoverBackgroundColor: "#7F8D00",
      },
      {
        type: "bar",
        label: Users.usersProfiles[5],
        data: Utils.generateData(7, 20, 120),
        fill: false,
        backgroundColor: "#E0C300",
        hoverBackgroundColor: "#907D00",
      },
    ],
  };
}

export function getNewStudentsByLevelDailyOptions() {
  return {
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
      datalabels: {
        color: "#FFFFFF",
        display: true,
        font: {
          size: window.innerWidth > 830 ? 12 : 8,
        },
      },
      legend: {
        labels: {
          color: "#495057",
        },
      },
      title: Utils.generateTitle(
        "Nombre journalier de nouveaux étudiants par profil"
      ),
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: "#495057",
          padding: 5,
        },
        grid: {
          color: "#ebedef",
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
    },
  };
}

export function getStudentsByLevel() {
  return {
    labels: Users.usersProfiles,
    datasets: [
      {
        data: Utils.generateData(6, 50, 600),
        backgroundColor: Users.usersProfilesColor,
        hoverBackgroundColor: Users.usersProfilesHoverColor,
      },
    ],
  };
}

export function getStudentsByLevelOptions() {
  return {
    plugins: {
      datalabels: {
        formatter: (value, ctx) => Utils.calculatePercentage(value, ctx, true),
        display: true,
        color: "#FDFDFD",
      },
      legend: {
        labels: {
          color: "#495057",
          padding: 13,
        },
        title: Utils.generateTitle("Nombre d'étudiants par profil"),
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 0.9,
  };
}
