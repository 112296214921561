import React, { useEffect, useState } from "react";
import "./ChargementImage.scss";
import { FileUpload } from "primereact/fileupload";
import { Image } from "primereact/image";
import PropTypes from "prop-types";
import { Button } from "primereact/button";
import Loader from "Components/Loader/loader";
import { Dialog } from "primereact/dialog";
import { Galleria } from "primereact/galleria";
import { useFetchGet } from "Services/api";
import { shuffleArray } from "Services/functions";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const ChargementImage = (props) => {
  const { t } = useTranslation("common");
  // ------- VARIABLES CHARGEMENT IMAGES ----------
  // Récupération des images par défaut du serveur (en utilisant le path custom si il existe)
  var imageStocksData = useFetchGet(
    "/image_stocks/" + props.path,
    props.auth.token
  );
  // Index de l'image choisie de la photothèque
  const [activeIndex, setActiveIndex] = useState(0);
  // La photothèque doit-elle être affichée ?
  const [visiblePhototheque, setVisiblePhototheque] = useState(false);
  // Responsive de la photothèque
  const responsiveOptions = [
    {
      breakpoint: "768px",
      numVisible: 5,
    },
    {
      breakpoint: "560px",
      numVisible: 2,
    },
  ];

  // Options pour boutons upload / annuler d'upload d'image
  const uploadOptions = {
    label: " ",
    icon: "pi pi-check",
    className: props.imageFile ? "is_uploaded " : "bouton_valider",
  };
  const cancelOptions = {
    label: " ",
    icon: "pi pi-times",
    className: "bouton_supprimer",
  };

  // Une fois les images de la photothèque chargées, on les mélange aléatoirement:
  const [isShuffled, setIsShuffled] = useState(false);
  useEffect(() => {
    if (imageStocksData.loaded) {
      imageStocksData.data = shuffleArray(imageStocksData.data);
      props.setImageId(imageStocksData?.data[activeIndex]?.id);
      setIsShuffled(true);
    }
  }, [imageStocksData.loaded]);

  useEffect(() => {}, [props.image]);

  // Taille max d'une image (défaut 1 Mb)
  const MAX_IMAGE_SIZE = 1048576;

  /**
   * Fonction vérifiant si l'image respecte la limite maximum autorisée
   *
   * @param {Object} file - Fichier image à vérifier
   * @param {Number} file.size - Taille du fichier
   * @returns {Boolean}
   */
  const isWithinSizeLimit = (file) => {
    return file.size > MAX_IMAGE_SIZE;
  };

  /**
   * Fonction vérifiant si l'image est en portrait (sauf lors de l'upload d'une image de profil)
   *
   * @param {Object} image - Image à vérifier
   * @param {Number} image.naturalWidth - Largeur de l'image
   * @param {Number} image.naturalHeight - Hauteur de l'image
   * @returns {Boolean}
   */
  const isPortrait = (image) => {
    // Get dimensions
    const width = image.naturalWidth;
    const height = image.naturalHeight;
    return props.path !== "users" && width > height;
  };

  /**
   * Fonction s'occupant de la vérification de l'image uploadée
   *
   * @param {Object} file - Fichier image à vérifier
   * @returns
   */
  const checkImageConditions = (file) => {
    return new Promise((_, reject) => {
      // Create an Image object
      const img = new Image();
      // Once image will be loaded
      img.onload = () => {
        // Check if image is in portrait mode (except for users which can allow any image)
        isPortrait(img)
          ? null
          : reject(
              new Error(
                t("actusApresConnexion.chargementImageWrongOrientation")
              )
            );
        isWithinSizeLimit(file)
          ? null
          : reject(
              new Error(t("actusApresConnexion.chargementImageWrongSize"))
            );
        img.src = URL.createObjectURL(file);
      };
    });
  };

  /**
   * Fonction s'occupant de la vérification de l'image uploadée
   * @param {Event} event
   * @param {Object} event.files Liste des fichiers sélectionnés
   * @returns {Promise}
   */
  const handleUpload = (event) => {
    const file = event.files[0];
    checkImageConditions(file).catch((error) => {
      props.uploadToast.current.show({
        severity: "error",
        summary: t("groupManagement.error"),
        detail: error.message,
        life: 3000,
      });
    });
  };

  /**
   * Fonction s'occuppant de l'upload d'une image
   * @param {Event} event
   * @param {Object} event[].files Liste des fichiers sélectionnés
   */
  const uploadHandler = ({ files }) => {
    const file = files[0];
    const fileReader = new FileReader();

    if (!file.type.match("image.*")) {
      props.uploadToast.current.show({
        severity: "error",
        summary: t("groupManagement.error"),
        detail: t("actusApresConnexion.chargementImageNotImage"),
        life: 3000,
      });
      return;
    }

    //? Si le fichier n'est pas un .wepb, .jpeg ou .png, on affiche un toast d'erreur
    if (file.type !== "image/jpeg" && file.type !== "image/png") {
      props.uploadToast.current.show({
        severity: "error",
        summary: "Erreur",
        detail:
          "Seules les images aux formats .jpeg, .webp et .png sont acceptées",
        life: 3000,
      });
      return;
    }

    // Ajout événement : dès que le fichier est chargé, on l'affiche dans le formulaire + toast succés
    fileReader.onload = () => {
      props.setImageFile(file);
      props.setImage(file.objectURL);
      props.uploadToast.current.show({
        severity: "success",
        summary: t("groupManagement.success"),
        detail: t("actusApresConnexion.chargementImageSuccess", {
          name: file.name,
        }),
        life: 3000,
      });
      props.setPhototheque(false);
    };
    // Démarrage du chargement du fichier
    fileReader.readAsDataURL(file);
  };

  /**
   * Permet d'afficher du JSX pour chaque image de la photothèque (cover)
   * @param {Object} item Object contenant une image de la photothèque
   * @returns {JSX.Element} image en cover
   */
  const imageTemplate = (item) => {
    return (
      <img
        src={item.resourceUrl}
        alt={item.filename}
        className={props.long ? "cover-long" : "cover-standard"}
      />
    );
  };

  /**
   * Permet d'afficher du JSX pour chaque image de la photothèque (miniature)
   * @param {Object} item Object contenant une image de la photothèque
   * @returns {JSX.Element} image en miniature
   */
  const thumbnailTemplate = (item) => {
    return (
      <img
        src={item.resourceUrl}
        alt={item.filename}
        className={props.long ? "thumbnail-long" : "thumbnail-standard"}
      />
    );
  };

  return (
    <div className="chargement_image">
      <h2></h2>
      {props?.image && props.imageFile && (
        <Image
          src={props.image}
          alt="Image de couverture"
          className={
            props.long
              ? "chargement_image-image-long"
              : "chargement_image-image-standard"
          }
        />
      )}
      {props.currentImage && !props.imageFile && !props.phototheque ? (
        <Image
          src={props.currentImage}
          alt="Image de couverture"
          className={
            props.long
              ? "chargement_image-image-long"
              : "chargement_image-image-standard"
          }
        />
      ) : (
        <>
          {!props.imageFile &&
            isShuffled &&
            imageStocksData.data.length > 0 && (
              <Image
                src={imageStocksData.data.at(activeIndex).resourceUrl}
                alt="Image de couverture"
                className={
                  props.long
                    ? "chargement_image-image-long"
                    : "chargement_image-image-standard"
                }
              />
            )}
        </>
      )}
      <span className="chargement_image__infos">
        {props.path === "users"
          ? t("actusApresConnexion.chargementImageConditions")
          : t("actusApresConnexion.chargementImageConditionsAll")}
      </span>
      <div className="chargement_image__container">
        {props.hideCustomImage ? null : (
          <div className="chargement_image__container__item">
            <FileUpload
              name={props.image}
              className="fileupload"
              customUpload={true}
              uploadHandler={uploadHandler}
              onUpload={handleUpload}
              chooseLabel={t("actusApresConnexion.chargementImageModifyPC")}
              onClear={() => {
                props.setImage(null);
                props.setImageFile(null);
                props.currentImage
                  ? props.setPhototheque(false)
                  : props.setPhototheque(true);

                props.cancelToast.current.show({
                  severity: "error",
                  summary: "Suppression : ",
                  detail: t("actusApresConnexion.chargementImageDeleteSuccess"),
                  sticky: true,
                });
              }}
              uploadOptions={uploadOptions}
              cancelOptions={cancelOptions}
              accept="image/*"
            ></FileUpload>
          </div>
        )}
        <div className="chargement_image__container__item">
          <Button
            name={props.image}
            id={
              props.currentImage
                ? "chargement_image__container__item-button"
                : "chargement_image__container__item-button_last"
            }
            label={t("actusApresConnexion.chargementImageBanque")}
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setVisiblePhototheque(true);
              props.setPhototheque(true);
            }}
            onSubmit={(e) => {
              e.preventDefault();
            }}
            onKeyDown={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          ></Button>
        </div>

        {props.phototheque && props.currentImage && (
          <div className="chargement_image__container__item">
            <Button
              name={props.currentImage}
              id="chargement_image__container__item-button"
              label={t("actusApresConnexion.chargementImageCancel")}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                props.setImage(null);
                props.setImageFile(null);
                props.setPhototheque(false);
              }}
              onSubmit={(e) => {
                e.preventDefault();
              }}
            />
          </div>
        )}
      </div>
      <div className="chargement_image__phototheque">
        <div className="chargement_image__phototheque__container">
          <Dialog
            appendTo={document.getElementById("app")}
            header={t("actusApresConnexion.chargementImagePhototheque")}
            visible={visiblePhototheque}
            onHide={() => setVisiblePhototheque(false)}
            className="chargement_image__phototheque-card"
          >
            {imageStocksData.loaded && imageStocksData.data.length > 0 ? (
              <Galleria
                value={imageStocksData.data}
                item={imageTemplate}
                activeIndex={activeIndex}
                thumbnail={thumbnailTemplate}
                className="phototheque_dialog-galeria"
                numVisible={5}
                responsiveOptions={responsiveOptions}
                onItemChange={(e) => {
                  props.setImageId(imageStocksData.data[e.index].id);
                  setActiveIndex(e.index);
                  props.setImageFile(null);
                  props.setPhototheque(true);
                }}
              ></Galleria>
            ) : (
              <Loader />
            )}
          </Dialog>
        </div>
      </div>
    </div>
  );
};
ChargementImage.propTypes = {
  label: PropTypes.string,
  image: PropTypes.string,
  setImage: PropTypes.func,
  setImageFile: PropTypes.func,
  setPhototheque: PropTypes.func,
  phototheque: PropTypes.bool,
  hideCustomImage: PropTypes.bool,
  imageFile: PropTypes.any,
  uploadToast: PropTypes.any,
  cancelToast: PropTypes.any,
  setImageId: PropTypes.func,
  path: PropTypes.string,
  long: PropTypes.bool,
  auth: PropTypes.object,
  currentImage: PropTypes.string,
};

ChargementImage.defaultProps = {
  label: "Image de couverture",
  long: false,
  hideCustomImage: false,
  currentImage: null,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ChargementImage);
