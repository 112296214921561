import React from "react";
import "./btn-bleu.scss";
import PropTypes from "prop-types";

const BtnBleu = (props) => {
  return (
    <button
      className="btn-bleu"
      onClick={props.btnAction}
      type={props.type || "submit"}
    >
      {props.iconLeft ? (
        <>
          {props.children}
          {props.btnTexte}
        </>
      ) : (
        <>
          {props.btnTexte}
          {props.children}
        </>
      )}
    </button>
  );
};

BtnBleu.propTypes = {
  btnAction: PropTypes.func,
  btnTexte: PropTypes.string,
  children: PropTypes.any,
  iconLeft: PropTypes.bool,
  type: PropTypes.string,
};

export default BtnBleu;
