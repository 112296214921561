import React from "react";
import "./GroupeCardEvent.scss";
import PropTypes from "prop-types";
import img_groupe1 from "assets/Event1.webp";
import img_groupe2 from "assets/Event2.webp";
import img_groupe3 from "assets/Event3.webp";
import img_groupe4 from "assets/Event4.webp";
import img_groupe5 from "assets/Event5.webp";
import img_groupe6 from "assets/Event6.webp";
import { connect } from "react-redux";

const API_LINK = process.env.REACT_APP_BASE_URL_API.split("/api")[0];

const GroupeCardEvent = (props) => {
  return (
    <div className="event_card_container">
      <div className="event_card_container__logo">
        {props.image == "img1" && (
          <img src={img_groupe1} alt="Logo du groupe" title="Logo du groupe" />
        )}
        {props.image == "img2" && (
          <img src={img_groupe2} alt="Logo du groupe" title="Logo du groupe" />
        )}
        {props.image == "img3" && (
          <img src={img_groupe3} alt="Logo du groupe" title="Logo du groupe" />
        )}
        {props.image == "img4" && (
          <img src={img_groupe4} alt="Logo du groupe" title="Logo du groupe" />
        )}
        {props.image == "img5" && (
          <img src={img_groupe5} alt="Logo du groupe" title="Logo du groupe" />
        )}
        {props.image == "img6" && (
          <img src={img_groupe6} alt="Logo du groupe" title="Logo du groupe" />
        )}
        {props.image == "link" && props.link && (
          <img
            src={`${API_LINK}/${props.link}`}
            alt="Logo du groupe"
            title="Logo du groupe"
          />
        )}
      </div>
      <div className="event_card_container__info">
        <h5 className="title">{props.titre}</h5>
        <h2></h2>
        <div className="event_card_container__info__bottom">
          <i className="pi pi-calendar"></i>
          {props.dateFin ? (
            <div className="event_card_container__info__bottom__dates">
              <h2>Du : {props.dateDebut}</h2>
              <h2>Au : {props.dateFin}</h2>
            </div>
          ) : (
            <div className="event_card_container__info__bottom__dates">
              <h2>{props.dateDebut}</h2>
            </div>
          )}
          {props.participants && (
            <h2>
              <i className="pi pi-users"></i> {props.participants}{" "}
              {props.participants > 0 ? "participants" : "participant"}
            </h2>
          )}
        </div>
      </div>
    </div>
  );
};

GroupeCardEvent.propTypes = {
  titre: PropTypes.string,
  dateDebut: PropTypes.string,
  dateFin: PropTypes.string,
  participants: PropTypes.number,
  statut: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
  auth: PropTypes.object,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GroupeCardEvent);
