import { updateAuth } from "Redux/Actions/authActions";
import { useIntersectionObserver } from "Services/intersectionObserver";
import "primeicons/primeicons.css";
import PropTypes from "prop-types";
import React from "react";
import { FaHeart, FaSuitcase, FaUsers } from "react-icons/fa";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "./EntrepriseCard.scss";
import { displayCompanyImage } from "Services/functions";

const EntrepriseCard = (props) => {
  const [intersectionRef, isVisibleIntersection] = useIntersectionObserver();
  const navigate = useNavigate();
  const [backgroundImage, setBackgroundImage] = React.useState(null);
  const [logo, setLogo] = React.useState(null);

  const assignBackgroundImage = () => {
    const index = Math.floor(Math.random() * 3) + 1;
    setBackgroundImage(`entrepriseFond${index}.webp`);
  };

  React.useEffect(() => {
    if (props.entreprise?.id) {
      !backgroundImage && assignBackgroundImage();
      !logo &&
        setLogo(
          displayCompanyImage(props.entreprise) ||
            require("assets/placeholderLogo.jpg")
        );
    }
  }, [props.entreprise]);

  return (
    <div className="entreprises_cards" ref={intersectionRef}>
      <Link
        to={{
          pathname:
            props.auth.isConnected && props.entreprise?.id
              ? `/annuaire/detail/${props.entreprise?.id}`
              : "",
          params: props.entreprise?.companyId,
        }}
        key={props.entreprise?.companyId}
      >
        {isVisibleIntersection && backgroundImage && (
          <div className="entreprises_cards__top">
            <img
              src={require(`../../../assets/${backgroundImage}`)}
              alt="Fond d'entreprise"
              loading="lazy"
            />
            <div className="entreprises_cards__top__logo">
              <img
                src={logo}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = require(`../../../assets/placeholderLogo.jpg`);
                }}
                alt="Logo d'entreprise"
                loading="lazy"
              />
            </div>
          </div>
        )}
        <div className="entreprises_cards__corps">
          <h3>{props.entreprise.name}</h3>
          <p>
            <i className="pi pi-building"></i>
            {props.type}
          </p>
          <p>
            <FaUsers />
            {props.taille}
          </p>
        </div>
      </Link>

      <div className="entreprises_cards__bottom">
        {props.offresDisponibles && (
          <button
            className="btn_card"
            onClick={() =>
              props.auth.isConnected &&
              navigate(`/offres?keyword=${props.entreprise?.name}`)
            }
          >
            <FaSuitcase /> Voir les offres disponibles
          </button>
        )}
        <FaHeart
          className={
            props.changePartnerStatus
              ? props.entreprise?.isPartner
                ? "partner_company_heart isHovered"
                : "not_partner_company_heart isHovered"
              : props.entreprise?.isPartner
              ? "partner_company_heart"
              : "not_partner_company_heart"
          }
          onClick={() => {
            props.changePartnerStatus && props.changePartnerStatus();
          }}
        ></FaHeart>
      </div>
    </div>
  );
};

EntrepriseCard.propTypes = {
  type: PropTypes.string,
  taille: PropTypes.string,
  entreprise: PropTypes.object,
  offresDisponibles: PropTypes.bool,
  handleAuth: PropTypes.func,
  companies: PropTypes.object,
  auth: PropTypes.object,
  uploadToast: PropTypes.any,
  cancelToast: PropTypes.any,
  changePartnerStatus: PropTypes.func,
};

const mapStateToProps = (state) => ({
  companies: state.companies,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  handleAuth: (value) => {
    dispatch(updateAuth(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EntrepriseCard);
