import { store } from "Redux/store";

export const successToast = (message, summary = "Succès") => {
  const toast = store.getState().auth.toast;
  toast.current.show({
    severity: "success",
    summary: `${summary} : `,
    detail: message,
    life: 3000,
  });
};

export const errorToast = (message, summary = "Erreur", life = 3000) => {
  const toast = store.getState().auth.toast;
  toast.current.show({
    severity: "error",
    summary: `${summary} : `,
    detail: message,
    life: life,
  });
};
