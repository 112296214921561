import React from "react";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";

const Mecenat = () => {
  return (
    <div>
      <Header />
      <Footer />
    </div>
  );
};

Mecenat.propTypes = {};

export default Mecenat;
