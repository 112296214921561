import Loader from "Components/Loader/loader";
import Management from "Components/Management/Management";
import TempPagination from "Components/Pagination/TempPagination";
import { updateEvents } from "Redux/Actions/eventsActions";
import { updatePagination } from "Redux/Actions/paginationActions";
import { useFetchGetPagination } from "Services/api";
import {
  formatDate,
  formatDateDatabase,
  formatDateFrenchLocale,
} from "Services/functions";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Evenement from "../EvenementCard/EvenementCard";
import EvenementManagementCard from "./EvenementManagementCard/EvenementManagementCard";
import "./EvenementsManagement.scss";

/**
 * Permet d'afficher la page de gestion des événements
 */
const EvenementsManagement = (props) => {
  const { t } = useTranslation("common");
  const [url, setUrl] = useState(null);

  useFetchGetPagination(url, props.auth.token, props.handleUpdateEvents);
  // *****************************************************************
  // ****************** UseStates / UseRef *********************
  // *****************************************************************
  const EventMngRef = useRef(null);

  const [states, setStates] = useState([]);
  const [lastEvent, setLastEvent] = useState(null);
  const evenementsContainerRef = useRef(null);
  // State qui permet de gérer les boutons en fonction des clics
  const [btnList, setBtnList] = useState([
    {
      isActive: true,
      text: t("eventApresConnexion.manageMesEvent"),
      transmitter: ["Admin"],
    },
    {
      isActive: false,
      text: t("eventApresConnexion.manageUnivEvent"),
      transmitter: ["Etudiant", "Alumni", "Personnel"],
    },
    {
      isActive: false,
      text: t("eventApresConnexion.manageEntEvent"),
      transmitter: ["Recruteur"],
    },
    {
      isActive: false,
      text: t("eventApresConnexion.manageAllEvent"),
      transmitter: ["all"],
    },
  ]);

  // *****************************************************************
  // ****************** UseEffect *********************
  // *****************************************************************
  useEffect(() => {
    props.handleUpdateEvents({
      currentTransmitter: ["me"],
    });
    return () => {
      props.handleUpdateEvents({ dataFromPagination: null });
    };
  }, []);
  // Hook demise à zéro des informations de paginations pour ne pas interférer les affichages des autres pages
  useEffect(() => {
    if (EventMngRef.current !== null) {
      window.scroll({
        top: EventMngRef.current.offsetTop,
        behavior: "smooth",
      });
    }
    let newUrl = new URL(`${process.env.REACT_APP_BASE_URL_API}/events`);
    newUrl.searchParams.set("endingAt[after]", formatDateDatabase(new Date()));
    newUrl.searchParams.set("order[startingAt]", "asc");
    newUrl.searchParams.set("itemsPerPage", 20);
    props.auth.isAdmin
      ? newUrl.searchParams.set("transmitter[]", "Admin")
      : newUrl.searchParams.append("createdBy[]", props.auth.userConnected.id);

    setUrl(newUrl);
    return () => {
      props.handleUpdatePagination({ currentPage: 1 });
      props.handleUpdatePagination({ paginationBasicFirst: 0 });
      props.handleUpdatePagination({ paginationBasicRows: 12 });
    };
  }, []);

  // UseEffect de chargement
  useEffect(() => {
    /// Récupérer les states, et les filtrer par ordre alphabétique
    if (props.events.etats && props.events.etats.length > 0) {
      setStates([]);
      let tempArray = [];
      props.events.etats.forEach((state) => tempArray.push(state));
      tempArray = tempArray.sort((a, b) => a.name?.localeCompare(b.name));
      setStates(tempArray);
    }
  }, [props.events.etats]);

  // Hook qui se déclenche quand la liste des offres change
  useEffect(() => {
    // Charge la dernière offre en fonction de l'emetteur choisi
    url?.href?.includes("page=1") &&
      setLastEvent(props.events.dataFromPagination?.data[0]);
  }, [props.events.dataFromPagination?.data]);

  // Fonction qui affiche les offres en fonction des boutons de gauche
  const filterByTransmitter = (listTransmitter) => {
    let newUrl = new URL(`${process.env.REACT_APP_BASE_URL_API}/events`);

    if (listTransmitter[0] === "Recruteur") {
      newUrl = new URL(
        `${process.env.REACT_APP_BASE_URL_API}/events/recruiter`
      );
    }

    newUrl.search = url.search;
    newUrl.searchParams.delete("transmitter[]");
    listTransmitter[0] !== "all" &&
      listTransmitter.forEach((transmitter) => {
        newUrl.searchParams.append("transmitter[]", transmitter);
      });

    setUrl(newUrl);
  };

  return (
    <div className="gestion_evenements" ref={EventMngRef}>
      <div className="gestion_evenements__title">
        <h1 className="h1_trait_dessus">
          {props.auth.isAdmin
            ? t("eventApresConnexion.submenuGererAdmin")
            : t("eventApresConnexion.submenuGererAlumni")}
        </h1>
        <Management
          states={states}
          btnEntreprise={btnList[2]}
          actualPage={"événement"}
          currentTransmitter={
            btnList.filter((btn) => btn.isActive === true)[0].transmitter
          }
          updateFunction={props.handleUpdateEvents}
          setUrl={setUrl}
        ></Management>
      </div>
      <div className="gestion_evenements__body">
        <div className="gestion_evenements__body__left">
          {props.auth.isAdmin &&
            btnList.map((btnStatus, index) => {
              return (
                <button
                  className={
                    btnStatus.isActive
                      ? "btn-bleu " + "item" + index
                      : "btn-blanc " + "item" + index
                  }
                  onClick={() => {
                    filterByTransmitter(btnList[index].transmitter);
                    var i = 0;
                    setBtnList(
                      btnList.map((btn) => {
                        btn.isActive = i == index;
                        i++;
                        return btn;
                      })
                    );
                    props.handleUpdateEvents({
                      currentTransmitter: btnList[index].transmitter,
                    });
                  }}
                  key={index}
                >
                  {btnList[index].text}
                </button>
              );
            })}
          <h3>{t("eventApresConnexion.manageDernierEvent")}</h3>
          {/* Affichage du dernier evenement */}
          {lastEvent !== null && lastEvent !== undefined && (
            <Link
              to={{
                pathname: `/event-detail/${lastEvent.id}`,
                params: lastEvent.id,
              }}
            >
              <Evenement
                categorie={lastEvent.category}
                debutDate={formatDate(lastEvent.startingAt, ".")}
                finDate={formatDate(lastEvent.endingAt, ".")}
                inscription={lastEvent.fulfilled}
                title={lastEvent.title}
                chapeau={lastEvent.description}
                eventDetails={lastEvent}
                url={lastEvent?.imageUrl ? lastEvent.imageUrl : "event1"}
                registerEnd={lastEvent.registerEnd}
              />
            </Link>
          )}
        </div>
        <div
          className="gestion_evenements__body__right"
          ref={evenementsContainerRef}
        >
          {/* Tant que la data n'est pas chargé on affiche un loader */}
          {props.events.dataFromPagination?.loaded > 0 ? (
            props.events.dataFromPagination.data.length > 0 ? (
              props.events.dataFromPagination.data
                .filter(
                  (event) =>
                    event.state.statusLabel !== "Brouillon" ||
                    event.createdBy.id == props.auth.userConnected.id
                )
                .map((event, index) => (
                  <EvenementManagementCard
                    titre={event.title}
                    dateDebut={formatDateFrenchLocale(event.startingAt)}
                    dateFin={formatDateFrenchLocale(event.endingAt)}
                    participants={event.eventParticipants}
                    statut={event.state}
                    url={event?.imageUrl ? event.imageUrl : "event2"}
                    key={index}
                    id={event.id}
                    feedback={event.feedback?.feedback}
                    transmitterName={props.events.currentTransmitter[0]}
                  />
                ))
            ) : (
              <span>{t("eventApresConnexion.manageNoResult")}</span>
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>

      <TempPagination
        result={props.events.dataFromPagination}
        refItemParent={evenementsContainerRef}
        rows={20}
      />
    </div>
  );
};

EvenementsManagement.propTypes = {
  auth: PropTypes.object,
  nbActus: PropTypes.number,
  pageActus: PropTypes.string,
  filtre: PropTypes.string,
  filtres: PropTypes.array,
  setFiltre: PropTypes.func,
  events: PropTypes.any,
  handleUpdateEvents: PropTypes.func,
  pagination: PropTypes.object,
  handleUpdatePagination: PropTypes.func,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  events: state.events,
  pagination: state.pagination,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateEvents: (value) => {
    dispatch(updateEvents(value));
  },
  handleUpdatePagination: (value) => {
    dispatch(updatePagination(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EvenementsManagement);
