import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import { Menu } from "primereact/menu";
import React, { useRef, useState } from "react";
import { FaEllipsisH } from "react-icons/fa";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import neutralUser from "assets/neutralUser.png";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

const GroupeCardCommentaire = (props) => {
  const menu = useRef(null);
  const { t } = useTranslation("common");

  const [currentComment, setCurrentComment] = useState(undefined);
  const [commentCreator, setCommentCreator] = useState(undefined);
  const itemsOwner = [
    {
      label: t("groupPublication.modals.edit"),
      command: () => {
        props.setEditComment({
          id: currentComment.id,
          publicationId: currentComment.publication.id,
          visible: true,
          content: currentComment.content,
          state: true,
        });
      },
    },
    { separator: true },
    {
      label: t("groupPublication.modals.delete"),
      command: () => {
        props.setDeleteComment({
          id: currentComment.id,
          type: "comment",
          visible: true,
          state: true,
        });
      },
    },
  ];

  // eslint-disable-next-line no-unused-vars
  const itemsAdmin = [
    {
      label: t("groupPublication.modals.delete"),
      command: () => {
        props.setDeleteComment({
          id: currentComment.id,
          type: "comment",
          visible: true,
          state: true,
        });
      },
    },
  ];

  const itemsUser = [
    {
      label: t("groupPublication.modals.report"),
      command: () =>
        props.setReportComment({
          id: currentComment.id,
          type: "comment",
          visible: true,
          state: true,
        }),
    },
    { separator: true },
  ];

  useEffect(async () => {
    if (props.comment) setCurrentComment({ ...props.comment });
  }, [props.comment]);

  useEffect(async () => {
    if (currentComment) {
      setCommentCreator(currentComment.createdBy);
    }
  }, [currentComment]);

  return (
    <>
      {currentComment && commentCreator && (
        <div className="groupe_comment__messages__message">
          <img
            src={
              commentCreator.imageUrl ? commentCreator.imageUrl : neutralUser
            }
            alt="Avatar"
            title="avatar"
          />
          <div className="groupe_comment__messages__message__block">
            <div>
              <h4>
                <span className="groupe_comment__messages__message__block-user">
                  {commentCreator.firstname + " " + commentCreator.surname}
                </span>
                <span className="groupe_comment__messages__message__block-date">
                  {new Intl.DateTimeFormat(
                    props.i18n.language === "en" ? "en-GB" : "fr-FR",
                    {
                      year: "numeric",
                      month: "long",
                      day: "2-digit",
                      hour: "numeric",
                      minute: "numeric",
                    }
                  ).format(
                    DateTime.fromISO(currentComment.createdAt).toJSDate()
                  )}
                  {"  "}
                  {currentComment.updatedAt ? t("groupPublication.edited") : ""}
                </span>
              </h4>
              <h5>
                {currentComment.content
                  ? currentComment.content
                  : t("groupPublication.comment.error")}
              </h5>
            </div>
            {!props.finder && (
              <>
                <Menu
                  appendTo={document.getElementById("app")}
                  // Affichage du menu en fonction du rôle de l'utilisateur
                  model={
                    // Vérification si l'utilisateur n'est pas le créateur du commentaire
                    props.auth.userConnected.id != commentCreator.id
                      ? // Si oui, vérification si l'utilisateur est un administrateur ou non
                        props.auth.isAdmin || props.isGroupAdmin
                        ? itemsAdmin
                        : itemsUser
                      : itemsOwner
                  }
                  popup
                  ref={menu}
                  style={{ textAlign: "center" }}
                />
                <BtnBlanc btnAction={(event) => menu.current.toggle(event)}>
                  <FaEllipsisH size={"1.5rem"} />
                </BtnBlanc>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

GroupeCardCommentaire.propTypes = {
  comment: PropTypes.object,
  isGroupAdmin: PropTypes.bool,
  currentUser: PropTypes.object,
  setDeleteComment: PropTypes.func,
  setEditComment: PropTypes.func,
  setReportComment: PropTypes.func,
  auth: PropTypes.object,
  i18n: PropTypes.object,

  //? Si on est dans la recherche de groupe
  finder: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  i18n: state.i18n,
});
const mapDispatchToProps = () => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupeCardCommentaire);
