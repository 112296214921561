export class Days {
  static get lastSevenDays() {
    return [
      "10/06/2022",
      "11/06/2022",
      "12/06/2022",
      "13/06/2022",
      "14/06/2022",
      "15/06/2022",
      "16/06/2022",
    ];
  }
}

export class Months {
  static get all() {
    return [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];
  }

  static get firstSeven() {
    return ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet"];
  }
}

export class OffersStatus {
  constructor(
    id,
    status,
    offersNumber,
    applicationsNumber,
    viewsNumber,
    applicationButtonClickNumber,
    diffuserNumber,
    siteNumber,
    companyPortalNumber
  ) {
    this.id = id;
    this.status = status;
    this.offersNumber = offersNumber;
    this.applicationsNumber = applicationsNumber;
    this.viewsNumber = viewsNumber;
    this.applicationButtonClickNumber = applicationButtonClickNumber;
    this.diffuserNumber = diffuserNumber;
    this.siteNumber = siteNumber;
    this.companyPortalNumber = companyPortalNumber;
  }
}

export class User {
  constructor(
    id,
    name,
    numberConnections,
    registerDate,
    lastConnectionDate,
    uni,
    level
  ) {
    this.id = id;
    this.name = name;
    this.numberConnections = numberConnections;
    this.registerDate = registerDate;
    this.lastConnectionDate = lastConnectionDate;
    this.uni = uni;
    this.level = level;
  }
}

export class Users {
  static generateUsersData(unis, levels) {
    let users = [];
    for (let index = 0; index < 20; index++) {
      let randomDateOne = new Date(
        +new Date() - Math.floor(Math.random() * 10000000000)
      );
      let randomDateTwo = new Date(
        +new Date() - Math.floor(Math.random() * 10000000000)
      );
      let tempName = "Utilisateur " + index;
      let tempNumberConnections = Utils.generateData(1, 0, 500).at(0);
      let tempRegisterDate =
        randomDateOne.getFullYear() +
        "-" +
        (randomDateOne.getMonth() + 1) +
        "-" +
        randomDateOne.getDate();
      let lastConnectionDate =
        randomDateTwo.getFullYear() +
        "-" +
        (randomDateTwo.getMonth() + 1) +
        "-" +
        randomDateTwo.getDate();
      let tempUni = unis[Math.floor(Math.random() * unis.length)];
      let tempLevel = levels[Math.floor(Math.random() * levels.length)];

      let user = new User(
        index,
        tempName,
        tempNumberConnections,
        tempRegisterDate,
        lastConnectionDate,
        tempUni,
        tempLevel
      );
      users.push(user);
    }

    return users;
  }

  static get usersProfiles() {
    return [
      "BAC + 1",
      "BAC + 2",
      "Licence",
      "Master 1",
      "Master 2",
      "Doctorat",
    ];
  }

  static get usersProfilesColor() {
    return ["#05668D", "#11279A", "#00A33D", "#74CD00", "#C7DC00", "#E0C300"];
  }

  static get usersProfilesHoverColor() {
    return ["#02425A", "#071562", "#006827", "#4A8300", "#7F8D00", "#907D00"];
  }
}

export class Offers {
  static get categories() {
    return [
      "Jobs étudiants",
      "Emplois",
      "Stages",
      "Alternance",
      "VIE-VIA",
      "Service civique",
    ];
  }

  static get categoriesObject() {
    return [
      { name: "Jobs étudiants", code: "JE" },
      { name: "Emplois", code: "E" },
      { name: "Stages", code: "S" },
      { name: "Alternance", code: "A" },
      { name: "VIE-VIA", code: "VV" },
      { name: "Service civique", code: "SC" },
    ];
  }

  static get diffusersObject() {
    return [
      { name: "Diffuseur 1", code: "1" },
      { name: "Diffuseur 2", code: "2" },
      { name: "Diffuseur 3", code: "3" },
      { name: "Diffuseur 4", code: "4" },
      { name: "Diffuseur 5", code: "5" },
    ];
  }

  static status(statusNumber) {
    switch (statusNumber) {
      case 0:
        return "En ligne";
      case 4:
        return "Pourvue";
      case 3:
        return "Expirée";
      case 2:
        return "Archivée";
      case 1:
        return "A valider";
    }
  }

  static generateOfferStatuses(amount = 5) {
    let offersStatuses = [];
    for (let index = 0; index < amount; index++) {
      let tempStatus = this.status(index);
      let tempOffersNumber = Utils.generateData(1, 0, 80000).at(0);
      let tempApplicationsNumber = Utils.generateData(1, 0, 11000).at(0);
      let tempViewsNumber = Utils.generateData(1, 0, 10000).at(0);
      let tempApplicationButtonClickNumber = Utils.generateData(1, 0, 3000).at(
        0
      );
      let tempDiffuserNumber = Utils.generateData(1, 0, 600000).at(0);
      let tempSiteNumber = Utils.generateData(1, 0, 3000).at(0);
      let tempCompanyPortalNumber = Utils.generateData(1, 0, 20000).at(0);
      offersStatuses.push(
        new OffersStatus(
          index,
          tempStatus,
          tempOffersNumber,
          tempApplicationsNumber,
          tempViewsNumber,
          tempApplicationButtonClickNumber,
          tempDiffuserNumber,
          tempSiteNumber,
          tempCompanyPortalNumber
        )
      );
    }
    return offersStatuses;
  }

  /**
   * @returns {Array<String>} Tableau de couleurs pour les catégories
   */
  static get categoriesColor() {
    return [
      "#fd7f6f",
      "#7eb0d5",
      "#b2e061",
      "#bd7ebe",
      "#ffb55a",
      "#fdcce5",
      "#FC80C0",
      "#6C205A",
    ];
  }

  /**
   * @returns {Array<String>} Tableau de couleurs lors d'un hover pour les catégories
   */
  static get categoriesHoverColor() {
    return [
      "#e60049",
      "#0bb4ff",
      "#50e991",
      "#9b19f5",
      "#ffa300",
      "#dc0ab4",
      "#FA53A9",
      "#521945",
    ];
  }

  static get categoriesCompanyColor() {
    return ["#d6ccf9", "#a8a5ec", "#74a3f4", "#5e999a", "#4b8d6b", "#717576"];
  }

  static get categoriesCompanyColorHover() {
    return ["#a663cc", "#3d0066", "#3577E9", "#287375", "#68e807", "#444647"];
  }
}

export class Companies {
  static get firstFiveUni() {
    return [
      "Université A",
      "Université B",
      "Université C",
      "Université D",
      "Université E",
    ];
  }

  static getSectors() {
    return [
      "Secteur 1",
      "Secteur 2",
      "Secteur 3",
      "Secteur 4",
      "Secteur 5",
      "Secteur 6",
      "Secteur 7",
      "Secteur 8",
      "Secteur 9",
      "Secteur 10",
      "Secteur 11",
      "Secteur 12",
      "Secteur 13",
      "Secteur 14",
      "Secteur 15",
    ];
  }

  static sectorsData() {
    let data = [];

    for (let index = 0; index < Companies.getSectors().length; index++) {
      let name = Companies.getSectors()[index];
      let companiesNumber = Utils.generateData(1, 15, 754).at(0);
      data.push({ id: index, name, companiesNumber });
    }
    return data;
  }

  static get firstFiveOrigins() {
    return ["Secteur 1", "Secteur 2", "Secteur 3", "Secteur 4", "Secteur 5"];
  }

  static get firstFiveCompanies() {
    return [
      "Entreprise 1",
      "Entreprise 2",
      "Entreprise 3",
      "Entreprise 4",
      "Entreprise 5",
    ];
  }

  static get firstFiveColors() {
    return ["#d6ccf9", "#a8a5ec", "#74a3f4", "#5e999a", "#4b8d6b"];
  }

  static get firstFiveColorsHover() {
    return ["#a663cc", "#3d0066", "#3577E9", "#287375", "#68e807"];
  }
}

export class Utils {
  static calculatePercentage(value, ctx, realData = false) {
    let sum = 0;
    let dataArr = ctx.chart.data.datasets[0].data;
    dataArr.map((data) => {
      sum += data;
    });
    let percentageValue = ((value * 100) / sum).toFixed(2);
    let percentage = percentageValue + "%";
    if (realData === true) {
      if (percentageValue < 6) {
        return percentage + "\n" + "   (" + value + ")";
      }
      return percentage + "\n" + "  (" + value + ")";
    }
    return percentage;
  }

  static generateTitle(text, padding = 10, fontSize = 14) {
    return {
      text: text,
      display: true,
      padding: padding,
      font: {
        size: fontSize,
      },
    };
  }

  static generateData(amount, min, max) {
    let newData = [];
    for (let i = 0; i < amount; i++) {
      newData.push(Math.floor(Math.random() * (max - min + 1)) + min);
    }
    return newData;
  }

  static generateAscendingData(amount, min, max, starting = 0) {
    let newData = [];
    newData.push(starting);
    for (let i = 1; i < amount; i++) {
      newData.push(
        Math.floor(Math.random() * (max - min + 1)) + min + starting
      );
      starting = newData.at(i);
    }
    return newData;
  }

  static numberWithSpaces(x) {
    return x.toLocaleString().replaceAll(",", " ");
  }
}

// 20220620120437
// https://raw.githubusercontent.com/primefaces/primelocale/main/fr.json
export function getFrenchLocale() {
  return {
    fr: {
      startsWith: "Commence par",
      contains: "Contient",
      notContains: "Ne contient pas",
      endsWith: "Se termine par",
      equals: "Egal à",
      notEquals: "Différent de",
      noFilter: "Aucun filtre",
      lt: "Inférieur à",
      lte: "Inférieur ou égal à",
      gt: "Supérieur à",
      gte: "Supérieur ou égal à",
      dateIs: "La date est",
      dateIsNot: "La date n'est pas",
      dateBefore: "Avant le",
      dateAfter: "Après le",
      custom: "Personnalisé",
      clear: "Effacer",
      apply: "Appliquer",
      matchAll: "Correspond à tous",
      matchAny: "Au moins un Correspond",
      addRule: "Ajouter une règle",
      removeRule: "Retirer une règle",
      accept: "Oui",
      reject: "Non",
      choose: "Choisir",
      upload: "Envoyer",
      cancel: "Annuler",
      dayNames: [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
      ],
      dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
      dayNamesMin: ["Di", "Lu", "Mar", "Mer", "Je", "Ve", "Sa"],
      monthNames: [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ],
      monthNamesShort: [
        "Jan",
        "Fev",
        "Mar",
        "Avr",
        "Mai",
        "Jun",
        "Jui",
        "Août",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
      today: "Aujourd'hui",
      weekHeader: "Sem",
      firstDayOfWeek: 1,
      dateFormat: "dd/mm/yy",
      weak: "Faible",
      medium: "Moyen",
      strong: "Fort",
      passwordPrompt: "Saisissez un mot de passe",
      emptyMessage: "Aucune option disponible",
      emptyFilterMessage: "Aucun résultat trouvé",
      aria: {
        trueLabel: "Vrai",
        falseLabel: "Faux",
        nullLabel: "Aucune sélection",
        pageLabel: "Page",
        firstPageLabel: "Première Page",
        lastPageLabel: "Dernière Page",
        nextPageLabel: "Page Suivante",
        previousPageLabel: "Page Précédente",
      },
    },
  };
}
