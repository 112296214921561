import React from "react";
import "./btn-bleu-left.scss";
import PropTypes from "prop-types";

const BtnBleuLeft = (props) => {
  return (
    <button className="btn-bleu left" onClick={props.btnAction}>
      {props.children}
      {props.btnTexte}
    </button>
  );
};

BtnBleuLeft.propTypes = {
  btnAction: PropTypes.func,
  btnTexte: PropTypes.string,
  children: PropTypes.any,
};

export default BtnBleuLeft;
