/**
 * Redirection vers la page de login de Shibboleth en prod
 *
 * Ou vers le modal de login si l'utilisateur est en dev
 *
 * @returns {boolean} True en prod, false en dev
 */
export const authenticationRedirection = () => {
  //? En prod, on redirige vers la page de login de Shibboleth mais en dev, on ouvre le modal de login (retourne false)
  if (
    process.env.NODE_ENV === "production" ||
    process.env.REACT_APP_ENV?.includes("prod")
  ) {
    window.location.href = process.env.REACT_APP_SHIBBO_URL_LOGIN;
    return true;
  }
  return false;
};
