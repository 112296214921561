import React, { useMemo, useRef, useState } from "react";
import { ScrollPanel } from "primereact/scrollpanel";
import GroupeCardDemande from "../GroupeCard/GroupeCardDemande/GroupeCardDemande";
import { Divider } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Toast } from "primereact/toast";
import { useEffect } from "react";
import { isNotEmptyArray } from "Services/functions";
import { useFetchGetPagination } from "Services/api";
import Loader from "Components/Loader/loader";
import { useTranslation } from "react-i18next";

/**
 * Permet d'afficher dans la page gérer mes groupes la partie droite : gérer les demandes
 */
const GroupeDemande = (props) => {
  const [requestHandle, setRequestHandle] = useState({
    display: false,
    status: undefined,
    data: undefined,
  });
  const requestRef = useRef();
  const { t } = useTranslation("common");

  const [groupMembersUrl, setGroupMembersUrl] = useState(undefined);
  const groupMembersQuery = useFetchGetPagination(
    groupMembersUrl,
    props.auth.token
  );

  const handleGroupMembersRequest = () => {
    let groupIds = props.managedGroups.map((group) => group.id);
    let demandeStatus = props.states.groupMemberStatesData.find(
      (state) => state.label === "DEMANDE"
    );
    let url = new URL(`${process.env.REACT_APP_BASE_URL_API}/group_members`);
    if (demandeStatus) {
      // Ajout des ids des groupes sous forme de paramètre
      groupIds.forEach((groupId) =>
        url.searchParams.append("groupSource[]", groupId)
      );
      url.searchParams.append("groupMemberStatus", demandeStatus.id);
      setGroupMembersUrl(url);
    }
  };

  useMemo(() => {
    if (isNotEmptyArray(props.managedGroups)) handleGroupMembersRequest();
  }, [props.managedGroups]);

  useEffect(() => {
    if (requestHandle.display) {
      requestHandle.status === "success"
        ? requestRef.current.show({
            severity: requestHandle.status,
            summary: "Succès",
            detail: requestHandle.data,
            life: 3000,
          })
        : requestRef.current.show({
            severity: requestHandle.status,
            summary: "Erreur",
            detail: requestHandle.data,
            life: 5000,
          });
      setRequestHandle({ display: false, data: undefined, status: undefined });
      if (props.managedGroups) props.setUpdateManagedGroups(true);
    }
  }, [requestHandle.display, props.managedGroups]);

  return (
    <div className="membership_container">
      <Toast ref={requestRef} />
      <h2 className="membership_style_h2">
        {t("groupManagement.requestHeader")}
      </h2>
      <div className="membership_groupe_cards">
        {props.managedGroups.length > 0 ? (
          <ScrollPanel style={{ width: "100%", height: "58rem" }}>
            {groupMembersQuery.loaded ? (
              groupMembersQuery?.data.map((request, index) => {
                return (
                  <React.Fragment key={request}>
                    {props.managedGroups?.find(
                      (groupe) => groupe.id == request.groupSource.id
                    ) ? (
                      <>
                        <GroupeCardDemande
                          userName={
                            request.user.firstname + " " + request.user.surname
                          }
                          requestId={request.id}
                          group={
                            props.managedGroups?.find(
                              (groupe) => groupe.id == request.groupSource.id
                            ).label
                          }
                          image={
                            request.user.imageUrl
                              ? "link"
                              : "img" + ((index % 6) + 1).toString()
                          }
                          url={request.user.imageUrl}
                          setRequestHandle={setRequestHandle}
                        />
                        <Divider className="divider_desktop" />
                        <Divider layout="vertical" className="divider_mobile" />
                      </>
                    ) : null}
                  </React.Fragment>
                );
              })
            ) : (
              <Loader />
            )}
          </ScrollPanel>
        ) : (
          <div className="membership_groupe_cards-empty">
            {t("groupManagement.none")}
          </div>
        )}
      </div>
    </div>
  );
};

GroupeDemande.propTypes = {
  managedGroups: PropTypes.array,
  requests: PropTypes.array,
  users: PropTypes.object,
  groups: PropTypes.object,
  states: PropTypes.object,
  auth: PropTypes.object,
  setUpdateManagedGroups: PropTypes.func,
};
const mapStateToProps = (state) => ({
  users: state.users,
  auth: state.auth,
  groups: state.groups,
  states: state.states,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GroupeDemande);
