import React from "react";
import "./GroupeCardSuggestion.scss";
import image1 from "assets/img_groupe1.webp";
import BtnBlancLeft from "Components/Boutons/BtnBlancLeft/btn-blanc-left";
import { FaPlusCircle } from "react-icons/fa";
import PropTypes from "prop-types";
import { truncate } from "Services/functions";
import { useTranslation } from "react-i18next";

const GroupeCardSuggestion = (props) => {
  const { t } = useTranslation();
  return (
    <div className="groupe_card_suggestion">
      {props.url ? (
        <img src={`${props.url}`} alt="Logo du groupe" title="Logo du groupe" />
      ) : (
        <img src={image1} alt="Logo du groupe" title="Logo du groupe" />
      )}
      <div className="groupe_card_suggestion__content">
        <h6>{truncate(props?.title, 45)}</h6>
        {/* <h6 className="description">
        // TODO: voir si on garde la description ou pas
          {stripHtml(truncate(props?.description, 45))}
        </h6> */}
        <div className="boutons_desktop">
          <BtnBlancLeft
            btnTexte={t("groupManagement.join")}
            btnAction={() => {
              props.setJoinGroup({
                title: props.title,
                state: true,
                id: props.id,
                private: props.private,
              });
            }}
          >
            <FaPlusCircle />
          </BtnBlancLeft>
        </div>
        <div className="boutons_mobile">
          <BtnBlancLeft
            btnTexte={t("groupManagement.join")}
            btnAction={() => {
              props.setJoinGroup({
                title: props.title,
                state: true,
                id: props.id,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};
GroupeCardSuggestion.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  description: PropTypes.string,
  setJoinGroup: PropTypes.func.isRequired,
  private: PropTypes.bool,
};

export default GroupeCardSuggestion;
