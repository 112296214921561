import { createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import rootReducer from "./rootReducer";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["offers", "users", "news", "events", "groups", "pagination"],
  whitelist: [
    "items",
    "auth",
    "admin",
    "universities",
    "i18n",
    "states",
    "secondaryTables",
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export let store = createStore(persistedReducer, composeWithDevTools());
export let persistor = persistStore(store);
