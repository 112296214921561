import React, { useEffect, useState } from "react";
import "./GroupeCardDemande.scss";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchGet, fetchPost } from "Services/api";
import neutralUser from "assets/neutralUser.png";
import { useTranslation } from "react-i18next";

const GroupeCardDemande = (props) => {
  // Requête actuellement affichée
  const [currentRequest, setCurrentRequest] = useState(undefined);
  const { t } = useTranslation("common");

  // assigne la requête actuelle à la variable currentRequest depuis la BDD
  useEffect(async () => {
    if (props.requestId)
      setCurrentRequest(
        await fetchGet("/group_members/" + props.requestId, props.auth.token)
      );
  }, [props.requestId]);

  // Messages par défaut lors de l'acceptation/refus de la demande
  const DEFAULT_ACCEPT_MESSAGE = t("groupManagement.requestAcceptSuccess");
  const DEFAULT_REFUSE_MESSAGE = t("groupManagement.requestRefuseSuccess");
  const DEFAULT_ERROR_MESSAGE = t("groupManagement.apiFailure");

  /**
   * Permet d'accepter ou de refuser une demande d'adhésion à un groupe
   * @param {string} type - accept ou refuse
   */
  const handleRequest = async (type) => {
    var data = await fetchPost(
      "/group_members/join/" + props.requestId + "/" + type,
      "{}",
      props.auth.token
    );
    const isError = data instanceof Error;
    props.setRequestHandle({
      display: true,
      status: isError ? "error" : "success",
      data: isError
        ? isError.message || DEFAULT_ERROR_MESSAGE
        : type == "accept"
        ? DEFAULT_ACCEPT_MESSAGE
        : DEFAULT_REFUSE_MESSAGE,
      header: isError
        ? t("groupManagement.error")
        : t("groupManagement.success"),
    });
  };

  return (
    <>
      {currentRequest &&
        currentRequest.groupMemberStatus.label == "DEMANDE" && (
          <div className="groupe_card_demande_container">
            <div className="groupe_card_demande_container__logo">
              {!props.url || props.image == "image1" ? (
                <img src={neutralUser} alt="Avatar" title="Avatar" />
              ) : (
                <img src={props.url} alt="Avatar" title="Avatar" />
              )}
            </div>
            <div className="groupe_card_demande_container__info">
              <h6 className="title">{props.userName}</h6>
              <h6>
                {t("groupManagement.requestTitle")}&quot;{props.group}&quot;
              </h6>
              <div className="groupe_card_demande_container__info__bouton">
                <div className="accepter">
                  <BtnBlanc
                    btnTexte={t("groupManagement.requestAccept")}
                    action="button"
                    btnAction={() => handleRequest("accept")}
                  />
                </div>
                <div className="refuser">
                  <BtnBlanc
                    btnTexte={t("groupManagement.requestRefuse")}
                    action="button"
                    btnAction={() => handleRequest("refuse")}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
};

GroupeCardDemande.propTypes = {
  userName: PropTypes.string,
  url: PropTypes.string,
  group: PropTypes.string,
  private: PropTypes.bool,
  membres: PropTypes.number,
  image: PropTypes.string,
  requestId: PropTypes.number,
  setRequestHandle: PropTypes.func,
  auth: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GroupeCardDemande);
