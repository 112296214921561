import React from "react";
import "./ModalCharte.scss";
import Modal from "../Modal";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { updateAuth } from "Redux/Actions/authActions";
// import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import { FaExclamationTriangle } from "react-icons/fa";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import { useState } from "react";
import { Checkbox } from "primereact/checkbox";
import { findManagementData } from "Services/functions";
import { useTranslation } from "react-i18next";

const ModalCharte = (props) => {
  const [charteValidation, setCharteValisation] = useState(true);
  const { t } = useTranslation("common");
  return (
    <div className="modalCharteConnexion">
      <Modal
        visible={props.visibleModal}
        setVisible={props.setVisibleModal}
        header={t("header.charter")}
        className="charte__modal modal"
        closable={false}
      >
        <div className="modalCharteConnexion__description">
          <p className="modalCharteConnexion__description__header">
            <FaExclamationTriangle className="modalCharteConnexion__description__header__icon" />{" "}
            Si vous ne validez pas la charte Réseau Alumni, vous ne pourrez pas
            avoir accès au site.{" "}
            <FaExclamationTriangle className="modalCharteConnexion__description__header__icon" />
          </p>
          {findManagementData(
            "charteRPAlumni",
            props.admin?.editManagementNexus,
            props.admin?.editManagement
          )}
          <div className="modalCharteConnexion__boutons">
            <div className="validation_charte">
              <div className="charte_checkbox">
                <Checkbox
                  checked={charteValidation}
                  onChange={() => setCharteValisation(!charteValidation)}
                />
              </div>
              <label htmlFor="charte_checkbox">
                J&apos;ai lu et j&apos;accepte la charte Réseau Alumni
              </label>
            </div>

            <BtnBleu
              btnTexte={
                charteValidation ? "Accepter" : "Continuer sans accepter"
              }
              btnAction={() => {
                props.setVisibleModal(!props.visibleModal);
                if (charteValidation) props.btnAction();
                else props.btnRefuse();
              }}
            />
            {/* <BtnBlanc btnTexte="Refuser la charte" /> */}
          </div>
        </div>
      </Modal>
    </div>
  );
};

ModalCharte.propTypes = {
  auth: PropTypes.object,
  handleAuth: PropTypes.func,
  visibleModal: PropTypes.bool,
  setVisibleModal: PropTypes.func,
  btnAction: PropTypes.func,
  btnRefuse: PropTypes.func,
  admin: PropTypes.object,
};

const mapStateToProps = (state) => ({
  admin: state.admin,
});

const mapDispatchToProps = (dispatch) => ({
  handleAuth: (value) => {
    dispatch(updateAuth(value));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(ModalCharte);
