import React from "react";
import PropTypes from "prop-types";
import Reseauprologo from "assets/Reseauprologo.webp";
import { connect } from "react-redux";
import { SlideMenu } from "primereact/slidemenu";
import { updateAuth } from "Redux/Actions/authActions";
import { updateMenu } from "Redux/Actions/menuActions";
import {
  findItemInEditManagement,
  findItemInEditManagementVisibility,
} from "Services/functions";

/**
 * Construit les menus de la navigation mobile pour les différents profils connectés
 */
const NavBarMobile = (props) => {
  //items du burger menu pour un alumni connecté ----------------------------------------------------------------
  let itemsConnectedAlumni = [
    {
      label: findItemInEditManagement(
        "accueilTitle",
        props.admin.editManagement
      ),
      command: () => (window.location.href = "/"),
    },
    { separator: true },
    {
      label: "Evenements",
      items: [
        {
          label: "Tous les événements",
          command: () => {
            if (window.location.pathname != "/evenements") {
              window.location.pathname = "/evenements";
            }
            props.handleMenu({
              activeItemEvenement: props.items.itemsEvenement.findIndex(
                (v) => v.label == "Tous les événements"
              ),
            });
          },
        },
        {
          label: "Proposer un événement",
          command: () => {
            if (window.location.pathname != "/evenements") {
              window.location.pathname = "/evenements";
            }
            props.handleMenu({
              activeItemEvenement: props.items.itemsEvenement.findIndex(
                (v) => v.label == "Proposer un événement"
              ),
            });
          },
        },
        {
          label: "Gérer mes événements",
          command: () => {
            if (window.location.pathname != "/evenements") {
              window.location.pathname = "/evenements";
            }
            props.handleMenu({
              activeItemEvenement: props.items.itemsEvenement.findIndex(
                (v) => v.label == "Gérer mes événements"
              ),
            });
          },
        },
      ],
    },
    { separator: true },
    {
      label: findItemInEditManagement("actusTitle", props.admin.editManagement),
      items: [
        {
          label: "Toutes les actus",
          command: () => {
            if (window.location.pathname != "/actualites") {
              window.location.pathname = "/actualites";
            }
            props.handleMenu({
              activeItemActu: props.items.itemsActu.findIndex(
                (v) => v.label == "Toutes les actus"
              ),
            });
          },
        },
        {
          label: "Proposer une actu",
          command: () => {
            if (window.location.pathname != "/actualites") {
              window.location.pathname = "/actualites";
            }
            props.handleMenu({
              activeItemActu: props.items.itemsActu.findIndex(
                (v) => v.label == "Proposer une actu"
              ),
            });
          },
        },
        {
          label: "Gérer mes actus",
          command: () => {
            if (window.location.pathname != "/actualites") {
              window.location.pathname = "/actualites";
            }
            props.handleMenu({
              activeItemActu: props.items.itemsActu.findIndex(
                (v) => v.label == "Gérer mes actus"
              ),
            });
          },
        },
      ],
    },
    { separator: true },
    {
      label: findItemInEditManagement(
        "offresTitle",
        props.admin.editManagement
      ),
      items: [
        {
          label: "Trouver une offre",
          command: () => {
            if (window.location.pathname != "/offres") {
              window.location.pathname = "/offres";
            }
            props.handleMenu({
              activeItemOffre: props.items.itemsOffre.findIndex(
                (v) => v.label == "Trouver une offre"
              ),
            });
          },
        },
        {
          label: "Déposer une offre",
          command: () => {
            if (window.location.pathname != "/offres") {
              window.location.pathname = "/offres";
            }
            props.handleMenu({
              activeItemOffre: props.items.itemsOffre.findIndex(
                (v) => v.label == "Déposer une offre"
              ),
            });
          },
        },
        {
          label: "Gérer mes dépôts d'offres",
          command: () => {
            if (window.location.pathname != "/offres") {
              window.location.pathname = "/offres";
            }
            props.handleMenu({
              activeItemOffre: props.items.itemsOffre.findIndex(
                (v) => v.label == "Gérer mes dépôts d'offres"
              ),
            });
          },
        },
      ],
    },
    { separator: true },
    {
      label: findItemInEditManagement(
        "groupesTitle",
        props.admin.editManagement
      ),
      items: [
        {
          label: "Mes groupes d'échanges",
          command: () => {
            if (window.location.pathname != "/groupes") {
              window.location.pathname = "/groupes";
            }
            props.handleMenu({
              activeItemGroupes: props.items.itemsGroupes.findIndex(
                (v) => v.label == "Mes groupes d'échanges"
              ),
            });
          },
        },
        {
          label: "Trouver un groupe",
          command: () => {
            if (window.location.pathname != "/groupes") {
              window.location.pathname = "/groupes";
            }
            props.handleMenu({
              activeItemGroupes: props.items.itemsGroupes.findIndex(
                (v) => v.label == "Trouver un groupe"
              ),
            });
          },
        },
        {
          label: "Événements de groupe",
          command: () => {
            if (window.location.pathname != "/groupes") {
              window.location.pathname = "/groupes";
            }
            props.handleMenu({
              activeItemGroupes: props.items.itemsGroupes.findIndex(
                (v) => v.label == "Événements de groupe"
              ),
            });
          },
        },
        {
          label: "Proposer et gérer mes groupes",
          command: () => {
            if (window.location.pathname != "/groupes") {
              window.location.pathname = "/groupes";
            }
            props.handleMenu({
              activeItemGroupes: props.items.itemsGroupes.findIndex(
                (v) => v.label == "Proposer et gérer mes groupes"
              ),
            });
          },
        },
      ],
    },
    { separator: true },
    findItemInEditManagementVisibility(
      "visibleAnnuaire",
      props.admin.editManagementBoolean
    ) && {
      label: "Annuaire",
      items: [
        findItemInEditManagementVisibility(
          "visibleAnnuaireAlumni",
          props.admin.editManagementBoolean
        ) && {
          label: "Annuaire Alumni",
          command: () => {
            if (window.location.pathname != "/annuaire") {
              window.location.pathname = "/annuaire";
            }
            props.handleMenu({
              activeItemAnnuaire: props.items.itemsAnnuaire.findIndex(
                (v) => v.label == "Annuaire Alumni"
              ),
            });
          },
        },
        findItemInEditManagementVisibility(
          "visibleAnnuaireEntreprise",
          props.admin.editManagementBoolean
        ) && {
          label: "Annuaire entreprises",
          command: () => {
            if (window.location.pathname != "/annuaire") {
              window.location.pathname = "/annuaire";
            }
            props.handleMenu({
              activeItemAnnuaire: props.items.itemsAnnuaire.findIndex(
                (v) => v.label == "Annuaire entreprises"
              ),
            });
          },
        },
      ],
    },
    { separator: true },
    {
      label: "Déconnexion",
      command: () => {
        (window.location.pathname = "/"),
          props.handleAuth({
            isConnected: !props.auth.isConnected,
          });
      },
    },
    { separator: true },
    {
      image: { Reseauprologo },
      className: "logoRP_burger",
    },
  ];
  // items du burger menu pour un admin connecté --------------------------------------------------------------
  let itemsConnectedAdmin = [
    {
      label: findItemInEditManagement(
        "accueilTitle",
        props.admin.editManagement
      ),
      command: () => (window.location.href = "/"),
    },
    { separator: true },
    {
      label: "Evenements",
      items: [
        {
          label: "Tous les événements",
          command: () => {
            if (window.location.pathname != "/evenements") {
              window.location.pathname = "/evenements";
            }
            props.handleMenu({
              activeItemEvenementAdmin:
                props.items.itemsEvenementAdmin.findIndex(
                  (v) => v.label == "Tous les événements"
                ),
            });
          },
        },
        {
          label: "Créer un événement",
          command: () => {
            if (window.location.pathname != "/evenements") {
              window.location.pathname = "/evenements";
            }
            props.handleMenu({
              activeItemEvenementAdmin:
                props.items.itemsEvenementAdmin.findIndex(
                  (v) => v.label == "Créer un événement"
                ),
            });
          },
        },
        {
          label: "Gérer les événements",
          command: () => {
            if (window.location.pathname != "/evenements") {
              window.location.pathname = "/evenements";
            }
            props.handleMenu({
              activeItemEvenementAdmin:
                props.items.itemsEvenementAdmin.findIndex(
                  (v) => v.label == "Gérer les événements"
                ),
            });
          },
        },
      ],
    },
    { separator: true },
    {
      label: findItemInEditManagement("actusTitle", props.admin.editManagement),
      items: [
        {
          label: "Toutes les actus/conseils",
          command: () => {
            if (window.location.pathname != "/actualites") {
              window.location.pathname = "/actualites";
            }
            props.handleMenu({
              activeItemActuAdmin: props.items.itemsActuAdmin.findIndex(
                (v) => v.label == "Toutes les actus/conseils"
              ),
            });
          },
        },
        {
          label: "Créer une actu/conseil",
          command: () => {
            if (window.location.pathname != "/actualites") {
              window.location.pathname = "/actualites";
            }
            props.handleMenu({
              activeItemActuAdmin: props.items.itemsActuAdmin.findIndex(
                (v) => v.label == "Créer une actu/conseil"
              ),
            });
          },
        },
        {
          label: "Gérer les actus/conseils",
          command: () => {
            if (window.location.pathname != "/actualites") {
              window.location.pathname = "/actualites";
            }
            props.handleMenu({
              activeItemActuAdmin: props.items.itemsActuAdmin.findIndex(
                (v) => v.label == "Gérer les actus/conseils"
              ),
            });
          },
        },
      ],
    },
    { separator: true },
    {
      label: findItemInEditManagement(
        "offresTitle",
        props.admin.editManagement
      ),
      items: [
        {
          label: "Trouver une offre",
          command: () => {
            if (window.location.pathname != "/offres") {
              window.location.pathname = "/offres";
            }
            props.handleMenu({
              activeItemOffre: props.items.itemsOffre.findIndex(
                (v) => v.label == "Trouver une offre"
              ),
            });
          },
        },
        {
          label: "Déposer une offre",
          command: () => {
            if (window.location.pathname != "/offres") {
              window.location.pathname = "/offres";
            }
            props.handleMenu({
              activeItemOffre: props.items.itemsOffre.findIndex(
                (v) => v.label == "Déposer une offre"
              ),
            });
          },
        },
        {
          label: "Gérer mes dépôts d'offres",
          command: () => {
            if (window.location.pathname != "/offres") {
              window.location.pathname = "/offres";
            }
            props.handleMenu({
              activeItemOffre: props.items.itemsOffre.findIndex(
                (v) => v.label == "Gérer mes dépôts d'offres"
              ),
            });
          },
        },
      ],
    },
    { separator: true },
    {
      label: findItemInEditManagement(
        "groupesTitle",
        props.admin.editManagement
      ),
      items: [
        {
          label: "Mes groupes d'échanges",
          command: () => {
            if (window.location.pathname != "/groupes") {
              window.location.pathname = "/groupes";
            }
            props.handleMenu({
              activeItemGroupesAdmin: props.items.itemsGroupesAdmin.findIndex(
                (v) => v.label == "Mes groupes d'échanges"
              ),
            });
          },
        },
        {
          label: "Trouver un groupe",
          command: () => {
            if (window.location.pathname != "/groupes") {
              window.location.pathname = "/groupes";
            }
            props.handleMenu({
              activeItemGroupesAdmin: props.items.itemsGroupesAdmin.findIndex(
                (v) => v.label == "Trouver un groupe"
              ),
            });
          },
        },
        {
          label: "Événements de groupe",
          command: () => {
            if (window.location.pathname != "/groupes") {
              window.location.pathname = "/groupes";
            }
            props.handleMenu({
              activeItemGroupesAdmin: props.items.itemsGroupesAdmin.findIndex(
                (v) => v.label == "Événements de groupe"
              ),
            });
          },
        },
        {
          label: "Créer et gérer mes groupes",
          command: () => {
            if (window.location.pathname != "/groupes") {
              window.location.pathname = "/groupes";
            }
            props.handleMenu({
              activeItemGroupesAdmin: props.items.itemsGroupesAdmin.findIndex(
                (v) => v.label == "Créer et gérer mes groupes"
              ),
            });
          },
        },
      ],
    },
    { separator: true },
    findItemInEditManagementVisibility(
      "visibleAnnuaire",
      props.admin.editManagementBoolean
    ) && {
      label: "Annuaire",
      items: [
        findItemInEditManagementVisibility(
          "visibleAnnuaireAlumni",
          props.admin.editManagementBoolean
        ) && {
          label: "Annuaire Alumni",
          command: () => {
            if (window.location.pathname != "/annuaire") {
              window.location.pathname = "/annuaire";
            }
            props.handleMenu({
              activeItemAnnuaire: props.items.itemsAnnuaire.findIndex(
                (v) => v.label == "Annuaire Alumni"
              ),
            });
          },
        },
        findItemInEditManagementVisibility(
          "visibleAnnuaireEntreprise",
          props.admin.editManagementBoolean
        ) && {
          label: "Annuaire entreprises",
          command: () => {
            if (window.location.pathname != "/annuaire") {
              window.location.pathname = "/annuaire";
            }
            props.handleMenu({
              activeItemAnnuaire: props.items.itemsAnnuaire.findIndex(
                (v) => v.label == "Annuaire entreprises"
              ),
            });
          },
        },
      ],
    },
    findItemInEditManagementVisibility(
      "visibleAnnuaire",
      props.admin.editManagementBoolean
    ) && { separator: true },
    {
      label: "Déconnexion",
      command: () => {
        (window.location.pathname = "/"),
          props.handleAuth({
            isConnected: !props.auth.isConnected,
          });
      },
    },
    { separator: true },
    {
      image: { Reseauprologo },
      className: "logoRP_burger",
    },
  ];
  return (
    <div>
      {props.role == "admin" && (
        <SlideMenu
          appendTo={document.getElementById("app")}
          model={itemsConnectedAdmin}
          viewportHeight={220}
          menuWidth={175}
          popup
          ref={props.menuNotConnected}
          style={{ textAlign: "start", left: "0", width: "175px" }}
        />
      )}
      {props.role == "alumni" && (
        <SlideMenu
          appendTo={document.getElementById("app")}
          model={itemsConnectedAlumni}
          viewportHeight={220}
          menuWidth={175}
          popup
          ref={props.menuNotConnected}
          style={{ textAlign: "start", left: "0px", width: "200px" }}
        />
      )}
    </div>
  );
};

NavBarMobile.propTypes = {
  menuNotConnected: PropTypes.any,
  role: PropTypes.string,
  navbarOpen: PropTypes.bool,
  handleToggle: PropTypes.func,
  auth: PropTypes.object,
  handleAuth: PropTypes.func,
  handleMenu: PropTypes.func,
  admin: PropTypes.object,
  items: PropTypes.object,
  annuaire: PropTypes.number,
  activeIndex: PropTypes.number,
  setActiveIndex: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
  admin: state.admin,
});

const mapDispatchToProps = (dispatch) => ({
  handleAuth: (value) => {
    dispatch(updateAuth(value));
  },
  handleMenu: (value) => {
    dispatch(updateMenu(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBarMobile);
