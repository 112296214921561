import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";
import { useEffect } from "react";
import Modal from "../Modal";
import "./ModalSupprimer.scss";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";

const ModalSupprimer = ({ visible, setVisible, auth }) => {
  const [message, setMessage] = useState(undefined);

  /**
   * Fonction qui permet d'envoyer une requête de suppression d'une entité
   * @param {String} url - url de l'api
   * @param {String} data - données à envoyer
   * @returns - retourne la réponse de l'api
   */
  const deleteRequest = async (url) => {
    let errorResponse = null;
    const response = await axios
      .delete(`${process.env.REACT_APP_BASE_URL_API}${url}`, {
        headers: auth.token
          ? {
              accept: "application/json",
              Authorization: `Bearer ${auth.token}`,
            }
          : {
              accept: "application/json",
            },
      })
      .catch((error) => {
        errorResponse = error.response.data.detail;
      });
    return response || errorResponse;
  };

  /**
   * Callback appelé lorsque l'utilisateur valide la suppression
   *
   * En fonction du type de l'entité on envoie une requête différente
   * et on met à jour la propriété 'visible'
   */
  const handleAccept = async () => {
    var result = undefined;

    if (visible.type == "comment")
      result = await deleteRequest("/publication_comments/" + visible.id);
    else if (visible.type == "publication")
      result = await deleteRequest("/publications/" + visible.id);
    else return;
    if (result && result.status === 200)
      setVisible({ ...visible, state: false, result: result });
    else setVisible({ ...visible, state: false, result: result });
  };

  /**
   * Callback appelé lorsque l'utilisateur annule la suppression
   */
  const handleReject = async () => {
    setVisible({ ...visible, state: false, result: false });
  };

  useEffect(() => {
    if (visible.state && visible.type) {
      switch (visible.type) {
        case "comment":
          setMessage("Êtes-vous sûr de vouloir supprimer ce commentaire ?");
          break;
        case "publication":
          setMessage("Êtes-vous sûr de vouloir supprimer cette publication ?");
          break;
        default:
          setMessage(undefined);
          break;
      }
    }
  }, [visible]);

  return (
    <div>
      {message && (
        <>
          <Modal
            visible={visible.state}
            setVisible={() => setVisible({ ...visible, state: false })}
            header={"Confirmation de suppression"}
            onHide={() => setVisible({ ...visible, state: false })}
            height="fit-content"
            width="80%"
            className="modal modal_suppression"
          >
            <div className="suppression-content">
              <p>{message}</p>
            </div>
            <div className={"suppression-buttons"}>
              <BtnBleu
                btnTexte="Annuler"
                action={"button"}
                btnAction={handleReject}
              />
              <BtnBlanc btnTexte="Supprimer" btnAction={handleAccept} />
            </div>
          </Modal>
          {/* <ConfirmDialog
          breakpoints={{ "960px": "75vw", "640px": "100vw" }}
          style={{ width: "50vw" }}
          visible={visible.state}
          onHide={() => setVisible({ ...visible, state: false })}
          message={message}
          header="Suppression"
          accept={handleAccept}
          reject={handleReject}
          acceptLabel="Valider"
          rejectLabel="Annuler"
        /> */}
        </>
      )}
    </div>
  );
};

ModalSupprimer.propTypes = {
  visible: PropTypes.object.isRequired,
  setVisible: PropTypes.func.isRequired,
  auth: PropTypes.object,
  groups: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  groups: state.groups,
});

export default connect(mapStateToProps)(ModalSupprimer);
