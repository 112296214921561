import Loader from "Components/Loader/loader";
import Modal from "Components/Modal/Modal";
import { useFetchGetPagination } from "Services/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./GroupeMembers.scss";

/**
 * Composant GroupeMembers : affiche la liste des membres d'un groupe
 * sous forme de tableau dans une modale
 * - Colomnes : Nom, Prénom, Rôle
 * @returns {JSX.Element}
 */
const GroupeMembers = (props) => {
  const authState = useSelector((state) => state.auth);
  const statesState = useSelector((state) => state.states);

  const { t } = useTranslation("common");

  const ITEMS_PER_PAGE = 50;

  const [membersUrl, setMembersUrl] = useState(null);
  const membersQuery = useFetchGetPagination(membersUrl, authState.token);

  const buildUrl = async () => {
    const activeStatus = statesState.groupMemberStatesData.find(
      (status) => status.label == "ACTIF"
    );
    const url = new URL(process.env.REACT_APP_BASE_URL_API + "/group_members");
    url.searchParams.append("groupSource", props.id);
    url.searchParams.append("groupMemberStatus", activeStatus.id);
    url.searchParams.append("itemsPerPage", ITEMS_PER_PAGE);
    url.searchParams.append("page", 1);
    setMembersUrl(url);
  };

  // /**
  //  * Fonction pour trier les members dans l'ordre suivant :
  //  * 1. rôles : CREATEUR > ADMIN > USER
  //  * 2. nom de famille
  //  * 3. prénom
  //  * @param {{firstname: String, surname: String, isGroupAdmin: boolean, isGroupCreator: boolean}} firstMember - premier membre à comparer
  //  * @param {{firstname: String, surname: String, isGroupAdmin: boolean, isGroupCreator: boolean}} secondMember - second membre à comparer
  //  */
  // const sortMembers = (firstMember, secondMember) => {
  //   // 1. rôles : CREATEUR > ADMIN > USER
  //   // 1.1. CREATEUR
  //   if (firstMember.isGroupCreator && !secondMember.isGroupCreator) return -1;
  //   if (!firstMember.isGroupCreator && secondMember.isGroupCreator) return 1;
  //   // 1.2. ADMIN
  //   if (firstMember.isGroupAdmin && !secondMember.isGroupAdmin) return -1;
  //   if (!firstMember.isGroupAdmin && secondMember.isGroupAdmin) return 1;
  //   // 2. nom de famille
  //   if (firstMember.surname > secondMember.surname) return 1;
  //   if (firstMember.surname < secondMember.surname) return -1;
  //   // 3. prénom
  //   if (firstMember.firstname > secondMember.firstname) return 1;
  //   if (firstMember.firstname < secondMember.firstname) return -1;
  //   // Si tout est égal (cas improbable) on retourne 0
  //   return 0;
  // };

  /**
   * Retourne le template du rôle d'un membre du groupe
   * @param {{groupMemberRoles: [{role: {roleName: 'ROLE_GROUP_USER' | 'ROLE_GROUP_ADMIN'}}]}} rowData
   * @returns {JSX.Element}
   */
  const roleBodyTemplate = (rowData) => {
    // Si l'utilisateur est admin, afficher une icône de bouclier
    // Sinon, afficher un utilisateur
    return (
      <>
        {rowData.groupMemberRoles.some(
          (memberRole) => memberRole.role.roleName === "ROLE_GROUP_ADMIN"
        ) ? (
          <i
            className="pi pi-shield icon p-ml-2 admin_role"
            title="Administrateur du groupe"
            style={{
              cursor: "pointer",
            }}
          ></i>
        ) : (
          <i
            className="pi pi-user icon p-ml-2 user_role"
            title="Utilisateur"
            style={{
              cursor: "pointer",
            }}
          ></i>
        )}
      </>
    );
  };

  useEffect(async () => {
    props.id && (await buildUrl());
  }, [props.id]);

  return (
    <Modal
      visible={props.openModal}
      setVisible={props.setOpenModal}
      header={"Liste des membres"}
      height="50vh"
      width="50vw"
    >
      {membersQuery?.loaded ? (
        <DataTable
          className="group-list"
          value={membersQuery?.data || []}
          paginator
          dataKey="id"
          emptyMessage="Aucun membre trouvé"
          lazy
          onPage={(e) => {
            const url = new URL(membersUrl);
            const currentPage = url.searchParams.get("page");
            if (currentPage != e.page + 1) {
              membersQuery.setPage(e.page + 1);
            }
          }}
          totalRecords={membersQuery?.totalItems || 0}
          rows={ITEMS_PER_PAGE}
        >
          <Column
            field="user.surname"
            header={t("profilEdition.personalInfo.nom")}
          />
          <Column
            field="user.firstname"
            header={t("profilEdition.personalInfo.prenom")}
          />
          <Column header="Role" body={roleBodyTemplate}></Column>
        </DataTable>
      ) : (
        <Loader />
      )}
    </Modal>
  );
};

GroupeMembers.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
};

export default GroupeMembers;
