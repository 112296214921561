import React, { useMemo, useState } from "react";
import Modal from "../Modal";
import { InputTextarea } from "primereact/inputtextarea";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import PropTypes from "prop-types";
import { updateOffers } from "Redux/Actions/offersActions";
import { connect } from "react-redux";
import axios from "axios";

const ModalOffreSearchBar = (props) => {
  const [searchData, setSearchData] = useState({});
  const [nameOfSearch, setNameOfSearch] = useState("");

  useMemo(() => {
    setSearchData({
      user: "/api/users/" + props.auth.userConnected.id,
      url: "",
      title: props.offers.motsClés,
      description: props.offers.motsClés,
      city: props.offers.lieu,
      country: props.offers.lieu,
      sectorOfOffer: props.offers.secteur.map(
        (secteur) => "/api/sector_of_offers/" + secteur.id
      ),
      domain: props.offers.domaine.map(
        (domaine) => "/api/domains/" + domaine.id
      ),
      minSalary:
        props.isVisibleSalary && props.offers.categorieOffre.length <= 1
          ? props.valueSalary[0]
          : null,
      maxSalary:
        props.isVisibleSalary && props.offers.categorieOffre.length <= 1
          ? props.valueSalary[1]
          : null,
      typeOfContract: props.offers.typeContrat.map(
        (typeContrat) => "/api/type_of_contracts/" + typeContrat.id
      ),
      offerCategory: props.offers.categorieOffre.map(
        (categorieOffre) => "/api/offer_categories/" + categorieOffre.id
      ),
      levelOfEducation: props.offers.niveauEtudes.map(
        (niveauEtudes) => "/api/level_of_educations/" + niveauEtudes.id
      ),
      isActive: false,
      lastSearch: null,
    });
  }, [props.auth]);

  const saveSearch = () => {
    let data = {
      ...searchData,
      nameOfSearch: nameOfSearch === "" ? "Unknown" : nameOfSearch,
    };

    let url = `${process.env.REACT_APP_BASE_URL_API}/saved_offer_searches`;
    axios
      .post(url, JSON.stringify(data), {
        headers: props.auth.token
          ? {
              accept: "application/json",
              "Content-Type": "application/json",

              Authorization: `Bearer ${props.auth.token}`,
            }
          : {
              accept: "application/json",
              "Content-Type": "application/json",
            },
      })
      .then((res) => {
        props.handleUpdateOffers({
          savedSearches: props.offers.savedSearches.concat(res.data),
        });
        setNameOfSearch("");
      })
      .catch((error) => {
        console.err("Error modify : ", error.response);
      });
  };

  return (
    <Modal
      visible={props.visibleNamingSearch}
      setVisible={props.setVisibleNamingSearch}
      header={`Nommez votre filtre de recherche`}
    >
      <InputTextarea
        className="modal_search_name"
        rows={6}
        placeholder="Nom du filtre"
        value={nameOfSearch}
        onChange={(e) => {
          e.preventDefault();
          setNameOfSearch(e.target.value);
        }}
      />
      <BtnBleu
        btnTexte="Enregistrer"
        btnAction={() => {
          saveSearch();
          props.setVisibleNamingSearch(false);
        }}
      />
    </Modal>
  );
};

ModalOffreSearchBar.propTypes = {
  visibleNamingSearch: PropTypes.bool.isRequired,
  setVisibleNamingSearch: PropTypes.func.isRequired,

  isVisibleSalary: PropTypes.bool.isRequired,
  valueSalary: PropTypes.array.isRequired,

  auth: PropTypes.object.isRequired,
  offers: PropTypes.object.isRequired,
  handleUpdateOffers: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  offers: state.offers,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateOffers: (offers) => dispatch(updateOffers(offers)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalOffreSearchBar);
