import React, { useRef } from "react";
import { Checkbox } from "primereact/checkbox";
import "./BtnEcouteMarche.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateMenu } from "Redux/Actions/menuActions";
import { updateAuth } from "Redux/Actions/authActions";
import axios from "axios";
import { Toast } from "primereact/toast";
import { useTranslation } from "react-i18next";

const BtnEcouteMarche = (props) => {
  const successUpdate = useRef(null);
  const { t } = useTranslation("common");

  const putDataToApi = (data) => {
    const { availableToWork } = data;
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL_API}/users/${props.auth.userConnected.id}`,
        { availableToWork },
        {
          headers: props.auth.token
            ? {
                accept: "application/json",
                "Content-Type": "application/json",

                Authorization: `Bearer ${props.auth.token}`,
              }
            : {
                accept: "application/json",
                "Content-Type": "application/json",
              },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          props.handleUpdateAuth({ userConnected: res.data });
          showSuccess();
        }
      })
      .catch((err) => console.log(err))
      .finally((fin) => console.log(fin));
  };
  const showSuccess = () => {
    successUpdate.current.show({
      severity: "success",
      summary: "Mise à jour effectuée avec succès",
      detail: "Votre mise à jour a bien été prise en compte",
      life: 3000,
    });
  };

  return (
    <div className="ecoute_marche">
      <Toast ref={successUpdate} />
      <div className="ecoute_marche_button">
        <Checkbox
          id="binary"
          onChange={(e) => {
            let userUpdate = {
              ...props.auth.userConnected,
              availableToWork: e.checked,
            };
            const userObj = { ...userUpdate };
            delete userObj.id;
            putDataToApi(userObj);
          }}
          checked={props.auth.userConnected.availableToWork}
        />
        <label htmlFor="binary" className="ecoute_marche-label">
          {t("profilEdition.privacySettings.availableToWork")}
        </label>
      </div>

      <h6>{t("profilEdition.privacySettings.disclaimerOne")}</h6>
      <h6>
        {t("profilEdition.privacySettings.disclaimerTwo")}
        <a
          onClick={() =>
            props.handleMenu({
              activeItemProfilEdit: props.items.itemsProfilEdit.findIndex(
                (v) => v.label == "Paramètres de confidentialité"
              ),
            })
          }
        >
          {t("profilEdition.privacySettings.title")}
        </a>
        .
      </h6>
    </div>
  );
};

BtnEcouteMarche.propTypes = {
  items: PropTypes.any,
  auth: PropTypes.any,
  handleMenu: PropTypes.func,
  handleUpdateAuth: PropTypes.func,
};

const mapStateToProps = (state) => ({
  items: state.items,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  handleMenu: (value) => {
    dispatch(updateMenu(value));
  },
  handleUpdateAuth: (value) => {
    dispatch(updateAuth(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BtnEcouteMarche);
