import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import "./ModalCreationCompte.scss";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Calendar } from "primereact/calendar";
import DateFnsUtils from "@date-io/date-fns";
import { fr } from "date-fns/locale";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { callAPIAlumni, useFetchGetConditional } from "Services/api";
import Loader from "Components/Loader/loader";
import ModalCharte from "Components/Modal/ModalCharte/ModalCharte";
import { useTranslation } from "react-i18next";
import { formatDateDatabase } from "Services/functions";
import data from "assets/countries";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/input";

const CompteUniversite = (props) => {
  const niveauDiplomeData = useFetchGetConditional(
    "/level_of_educations",
    props.auth.token,
    props.secondaryTables.levelOfEducationsData
  );
  const { t } = useTranslation("common");
  const [visibleModalCharte, setvisibleModalCharte] = useState(false);

  // si le formulaire est utilisé au moment de la création de compte on prend des valeurs de base nulles
  // si le formulaire est utilisé au moment de la modification de profil on prend les valeurs de l'utilisateur
  function valeursFinales() {
    if (props.utilisateur == undefined) {
      return defaultValues;
    } else {
      defaultValues.email = props.utilisateur.email;
      return defaultValues;
    }
  }
  const [showMessage, setShowMessage] = useState(false);
  const [formData, setFormData] = useState({});
  const [country, setCountry] = useState("France");

  // constantes de l'utilisateur
  let defaultValues = {
    lastName: "",
    firstName: "",
    anneeDiplome: "2023",
    niveauDiplome: "",
    country: "France",
    email: "",
    date: null,
    phone: "",
    motivation: "",
    accept: false,
    alumni: true,
  };
  valeursFinales();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useForm({ defaultValues });

  const onSubmitCharte = () => {
    let data = getValues();
    data.accept = true;
    data.date = formatDateDatabase(data.date);
    setFormData(data);

    const body = {
      givenName: data.firstName,
      sn: data.lastName,
      supannOIDCDateDeNaissance: data.date,
      pager: data.phone,
      supannMailPerso: data.email,
      supannConsentement: data.accept,
      motivation: data.motivation,
      country: data.country,
    };

    callAPIAlumni("/check_alumni", body, props.auth.toast);

    reset();
  };

  const onSubmit = () => {
    setvisibleModalCharte(true);
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const dialogFooter = (
    <div className="flex justify-content-center">
      <Button
        label="OK"
        className="p-button-text"
        autoFocus
        onClick={() => {
          setShowMessage(false);
          props.setVisibleModal(false);
        }}
      />
    </div>
  );
  const DATE_OPTIONS = {
    year: "numeric",
  };
  return (
    <div
      className="form-demo"
      style={{
        width: "100%",
        maxWidth: "100%",
      }}
    >
      <Dialog
        appendTo={document.getElementById("app")}
        visible={showMessage}
        onHide={() => setShowMessage(false)}
        position="top"
        footer={dialogFooter}
        showHeader={false}
        breakpoints={{ "960px": "80vw" }}
        style={{ width: "30vw" }}
      >
        <div className="flex justify-content-center flex-column pt-6 px-3">
          <i
            className="pi pi-check-circle"
            style={{ fontSize: "5rem", color: "var(--green-500)" }}
          ></i>
          <h5>Création de compte terminée !</h5>
          <p style={{ lineHeight: 1.5, textIndent: "1rem" }}>
            Votre compte est enregistré sous le nom de{" "}
            <b>{formData.firstName}</b> <b>{formData.lastName}</b>. Un mail vous
            sera envoyé à <b>{formData.email}</b> une fois votre compte activé.
          </p>
        </div>
      </Dialog>

      <div className="card">
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
          <div className="field">
            <h2>{t("createAccount.intro")}</h2>

            <div className="checkboxes">
              {/* <div className="field checkbox">
                {/* <Controller
                  name="student"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      className="checkbox_student"
                      id={field.name}
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.checked);
                        setValue("alumni", !e.checked);
                      }}
                    />
                  )}
                />
                <label htmlFor="student">Etudiant</label>
                {getFormErrorMessage("student")} */}
              {/* </div> */}
              <div className="field checkbox">
                <Controller
                  name="alumni"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      className="checkbox_alumni"
                      id={field.name}
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.checked);
                        setValue("student", !e.checked);
                      }}
                    />
                  )}
                />
                <label htmlFor="alumni">Alumni</label>
                {getFormErrorMessage("alumni")}
              </div>
            </div>
          </div>

          <div className="ask_connexion_name">
            <div className="field">
              <h2>{t("createAccount.nom")}</h2>
              <Controller
                name="lastName"
                control={control}
                rules={{ required: "Le nom est requis" }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    placeholder="Dupont..."
                    {...field}
                    autoFocus
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              {getFormErrorMessage("lastName")}
            </div>
            <div className="field">
              <h2>{t("createAccount.prenom")}</h2>
              <Controller
                name="firstName"
                control={control}
                rules={{ required: "Le prénom est requis" }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    placeholder="Marie, Arthur..."
                    //   autoFocus
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              {getFormErrorMessage("firstName")}
            </div>
          </div>

          <div className="ask_connexion_country">
            <div className="field">
              <h2>{t("createAccount.date")}</h2>
              <Controller
                name="date"
                control={control}
                rules={{ required: "La date de naissance est requise" }}
                render={({ field, fieldState }) => (
                  <Calendar
                    id={field.name}
                    {...field}
                    format="dd/MM/yyyy"
                    placeholder="11/02/1999"
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              {getFormErrorMessage("date")}
            </div>
            <div className="field">
              <h2 htmlFor="country">{t("createAccount.country")}</h2>
              <Controller
                name="country"
                control={control}
                rules={{ required: "Le pays est requis" }}
                render={({ field }) => (
                  <Dropdown
                    id={field.name}
                    {...field}
                    value={country}
                    onChange={(e) => {
                      field.onChange(e.value);
                      setCountry(e.value);
                    }}
                    placeholder="France, Belgique..."
                    options={data}
                    className="box__input"
                  />
                )}
              />
              {getFormErrorMessage("country")}
            </div>
          </div>

          <div className="ask_connexion_university">
            <div className="field">
              <h2>{t("createAccount.promoDate")}</h2>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
                <DatePicker
                  className="datepicker_create_account"
                  name="anneeDiplome"
                  label="Année de diplôme"
                  placeholderText="Click to enter the start date"
                  selected={
                    defaultValues.anneeDiplome === " "
                      ? null
                      : defaultValues.anneeDiplome
                  }
                  onChange={(date) =>
                    setValue(
                      "anneeDiplome",
                      new Intl.DateTimeFormat("fr-FR", DATE_OPTIONS).format(
                        date,
                        "Y"
                      )
                    )
                  }
                  labelFunc={() =>
                    getValues("anneeDiplome") || defaultValues.anneeDiplome
                  }
                  views={["year"]}
                  cancelLabel="Annuler"
                />
              </MuiPickersUtilsProvider>
              {getFormErrorMessage("anneeDiplome")}
            </div>
            <div className="field"></div>
          </div>

          <div className="field half">
            <h2>{t("createAccount.promoNiv")}</h2>
            {niveauDiplomeData.loaded ? (
              <Controller
                name="niveauDiplome"
                control={control}
                rules={{ required: "Le niveau de diplôme est requis" }}
                render={({ field }) => (
                  <Dropdown
                    id={field.name}
                    placeholder="Bac, Bac+1, ..."
                    value={field.value}
                    options={niveauDiplomeData.data}
                    optionLabel="label"
                    onChange={(e) => field.onChange(e.value)}
                  />
                )}
              />
            ) : (
              <Loader></Loader>
            )}

            {getFormErrorMessage("niveauDiplome")}
          </div>

          <div className="ask_connexion_coordinates">
            <div className="field">
              <h2>{t("createAccount.email")}</h2>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Email is required.",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid email address. E.g. example@email.com",
                  },
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    placeholder="hello123@gmail.com"
                    {...field}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              {getFormErrorMessage("email")}
            </div>
            <div className="field">
              <h2>{t("createAccount.phone")}</h2>
              <Controller
                name="phone"
                control={control}
                rules={{
                  validate: (value) =>
                    value
                      ? isPossiblePhoneNumber(value) ||
                        t("profilEdition.personalInfo.phoneValidationPattern")
                      : t("profilEdition.personalInfo.phoneValidationRequired"),
                }}
                render={({ field }) => (
                  <PhoneInput
                    id={field.name}
                    {...field}
                    onChange={(e) => field.onChange(e || "")}
                    placeholder="+33 6 00 00 00 00"
                    className="p-inputtext p-component"
                  />
                )}
              />

              {getFormErrorMessage("phone")}
            </div>
          </div>

          <div className="field full">
            <h2>{t("createAccount.motivLabel")}</h2>
            <Controller
              name="motivation"
              control={control}
              render={({ field, fieldState }) => (
                <InputTextarea
                  id={field.name}
                  placeholder={t("createAccount.motivPlaceholder")}
                  {...field}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                />
              )}
            />
            {getFormErrorMessage("name")}
          </div>

          <div
            className="field centered"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <BtnBlanc btnTexte="Envoyer" />
          </div>
        </form>
        {visibleModalCharte && (
          <ModalCharte
            visibleModal={visibleModalCharte}
            setVisibleModal={setvisibleModalCharte}
            btnRefuse={() => {
              props.setVisibleModal(false);
            }}
            btnAction={() => {
              onSubmitCharte();
            }}
          ></ModalCharte>
        )}
      </div>
    </div>
  );
};

CompteUniversite.propTypes = {
  title: PropTypes.string,
  chapeau: PropTypes.string,
  bouton: PropTypes.string,
  styleFormulaireMembre: PropTypes.bool,
  utilisateur: PropTypes.any,
  auth: PropTypes.object,
  secondaryTables: PropTypes.object,
  visibleModal: PropTypes.func,
  setVisibleModal: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  secondaryTables: state.secondaryTables,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CompteUniversite);
