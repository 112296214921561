import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import ProfilBarShow from "Components/ProfilBar/ProfilBarShow/ProfilBarShow";
import { useFetchGet } from "Services/api";
import crown from "assets/crown.png";
import axios from "axios";
import { Divider } from "primereact/divider";
import { Skeleton } from "primereact/skeleton";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { displayEducationDates, formatDate } from "../../../Services/functions";
import "./ProfilShow.scss";

/**
 * Permet d'afficher la page de détails du profil d'une personne sélectionnée dans l'annuaire
 * Un utilisateur peut aussi avoir un aperçu de cette page pour son profil via "aperçu du profil" dans /profil
 * Accessible pour tous les utilisateurs
 */
const ProfilShow = (props) => {
  const { id } = useParams();
  const userData = useFetchGet(
    id == props.auth.userConnected.id ? `/users/${id}/private` : `/users/${id}`,
    props.auth.token
  );
  const { t } = useTranslation("common");

  const downloadRPResume = () => {
    let cvId = userData.data.resume.id;
    if (!cvId) {
      props.auth?.toast?.current?.show({
        severity: "error",
        summary: "Erreur",
        detail: "Cet utilisateur n'a pas de CV",
        life: 5000,
      });
    }
    let url = `${process.env.REACT_APP_BASE_URL_API}/cvs/${cvId}/pdf`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${props.auth.token}`,
          accept: "application/json",
        },
        responseType: "blob",
      })
      .then((res) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(res.data);
        let filename =
          res.headers["content-disposition"]?.split("filename=")[1];
        // On supprime ce qu'il y a après le '.pdf' dans le nom du fichier
        filename = filename?.split(".pdf")[0];
        // On supprime les guillemets
        filename = filename?.replace(/"/g, "");
        link.setAttribute("download", filename || "cv.pdf");
        link.click();
      })
      // Si il y a une erreur, on l'affiche dans la console
      .catch((error) => {
        props.auth?.toast?.current?.show({
          severity: "error",
          summary: "Erreur",
          detail: error?.response?.data?.detail || "Une erreur est survenue",
          life: 5000,
        });
      });
  };

  return (
    <div className="profil_show">
      <Header>
        <div className="profilPage__headerProfilBar">
          {props.auth.isConnected && userData.loaded && (
            <ProfilBarShow user={userData.data} />
          )}
        </div>
      </Header>
      <div className="profil_show__container">
        <div className="profil_show__container-flex">
          {props.auth.isConnected && userData.loaded ? (
            <>
              <div className="profil__item">
                <div className="profil__item__title">
                  <h1 className="h1_trait_dessus">
                    {t("profilEdition.studiesDegrees.title")}
                  </h1>
                </div>
                <div className="profil__item__container">
                  {userData.loaded &&
                    userData.data.resume &&
                    userData.data.resume.educations
                      // Tri des formations par :
                      // 1 - Formation actuelle
                      // 2 - Date de fin
                      // 3 - Date de début
                      ?.sort((a, b) => {
                        if (b?.current - a?.current !== 0) {
                          return b?.current - a?.current;
                        } else if (
                          new Date(b?.endDate || Date.now()) -
                            new Date(a?.endDate || Date.now()) !==
                          0
                        ) {
                          return (
                            new Date(b?.endDate || Date.now()) -
                            new Date(a?.endDate || Date.now())
                          );
                        } else {
                          return (
                            new Date(b?.startDate) - new Date(a?.startDate)
                          );
                        }
                      })
                      ?.map((education) => {
                        if (education?.certified)
                          return (
                            <div
                              className="profil__item__container__block"
                              key={education.id}
                            >
                              {/* Seuls les diplômes sont certifiés (et pas les formations) */}
                              {education?.obtainedDiplome && (
                                <img src={crown} />
                              )}
                              <h4>
                                {education?.current
                                  ? education?.currentEtape?.label
                                  : education?.obtainedDiplome?.label}
                              </h4>
                              <p className="profil__item__container__block-date">
                                {displayEducationDates(
                                  education,
                                  props.i18n.language || "fr"
                                )}
                              </p>
                              <div className="profil__item__container__block-content">
                                {/* Seuls les diplômes sont certifiés (et pas les formations) */}
                                <span className="highlight">
                                  {education?.obtainedDiplome &&
                                    t(
                                      "profilEdition.professionalExperiences.certifiedFormation"
                                    )}
                                  <br />
                                </span>
                                {education.school}
                                <br />
                                {education?.domain?.label}
                              </div>
                              <p>
                                <span className="highlight">
                                  {education?.studyLevel?.label}
                                </span>
                              </p>
                              <Divider />
                            </div>
                          );
                        else
                          return (
                            <div
                              className="profil__item__container__block"
                              key={education.id}
                            >
                              <h4>{education.diploma}</h4>
                              <p className="profil__item__container__block-date">
                                {displayEducationDates(
                                  education,
                                  props.i18n.language || "fr"
                                )}
                              </p>
                              <div className="profil__item__container__block-content">
                                {education.school}
                                <br />
                                {education.domain?.label}
                              </div>
                              <p className="highlight">
                                <span>{education.studyLevel?.label}</span>
                              </p>
                              <Divider />
                            </div>
                          );
                      })}
                </div>
              </div>
              <div className="profil__item">
                <div className="profil__item__title">
                  <h1 className="h1_trait_dessus">
                    {t("profilEdition.professionalExperiences.title")}
                  </h1>
                </div>
                <div className="profil__item__container">
                  {userData.loaded &&
                    userData.data.resume &&
                    userData.data.resume.experiences
                      // Tri des expériences professionnelles par :
                      // 1 - Expérience actuelle
                      // 2 - Date de fin
                      // 3 - Date de début
                      ?.sort((a, b) => {
                        if (b?.current - a?.current !== 0) {
                          return b?.current - a?.current;
                        } else if (
                          new Date(b?.endDate || Date.now()) -
                            new Date(a?.endDate || Date.now()) !==
                          0
                        ) {
                          return (
                            new Date(b?.endDate || Date.now()) -
                            new Date(a?.endDate || Date.now())
                          );
                        } else {
                          return (
                            new Date(b?.startDate) - new Date(a?.startDate)
                          );
                        }
                      })
                      .map((xp) => (
                        <div
                          className="profil__item__container__block"
                          key={xp.id}
                        >
                          <h4>
                            {xp.company} - {xp.city}
                          </h4>
                          <p className="profil__item__container__block-date">
                            {formatDate(xp.startDate, "/")} -{" "}
                            {xp.current
                              ? t("profilEdition.professionalExperiences.today")
                              : formatDate(xp.endDate, "/")}
                          </p>
                          <div className="profil__item__container__block-content-line">
                            <h5>
                              <strong>
                                {t(
                                  "profilEdition.professionalExperiences.role"
                                )}
                              </strong>{" "}
                            </h5>
                            <p>{xp.jobname}</p>
                          </div>
                          <div className="profil__item__container__block-content-line">
                            <h5>
                              <strong>
                                {t(
                                  "profilEdition.professionalExperiences.sector"
                                )}
                              </strong>{" "}
                              :{" "}
                            </h5>
                            <p>{xp.sector?.label || xp.sector}</p>
                          </div>
                          <div className="profil__item__container__block-content-line">
                            <h5>
                              <strong>
                                {t(
                                  "profilEdition.professionalExperiences.domain"
                                )}
                              </strong>{" "}
                              :{" "}
                            </h5>
                            <p>{xp.domain?.label || xp.domain}</p>
                          </div>
                          <div className="profil__item__container__block-content-line">
                            <h5>
                              <strong>
                                {t(
                                  "profilEdition.professionalExperiences.category"
                                )}
                              </strong>{" "}
                              :{" "}
                            </h5>
                            <p>
                              {xp.typeOfContract?.label || xp.category?.label}
                            </p>
                          </div>
                          {xp.description && (
                            <div className="profil__item__container__block-content-line">
                              <h5>
                                <strong>
                                  {t("profilEdition.personalInfo.description")}
                                </strong>{" "}
                                :{" "}
                              </h5>
                              <p>{xp.description}</p>
                            </div>
                          )}
                          <Divider />
                        </div>
                      ))}
                </div>
              </div>
              <div className="profil__item">
                <div className="profil__item__title">
                  <h1 className="h1_trait_dessus">
                    {t("profilEdition.skills.title")}
                  </h1>
                </div>
                <div className="profil__item__container">
                  {userData.loaded &&
                    userData.data.resume &&
                    userData.data.resume.skills.map((item) => (
                      <div
                        className="profil__item__container-competence"
                        key={item.id}
                      >
                        <h4>{item.skill}</h4>
                      </div>
                    ))}
                </div>
              </div>
            </>
          ) : (
            <React.Fragment>
              <div className="profil__item">
                <Skeleton height="20svh" />
              </div>
              <div className="profil__item">
                <Skeleton height="20svh" />
              </div>
              <div className="profil__item">
                <Skeleton height="20svh" />
              </div>
            </React.Fragment>
          )}
        </div>
        {userData.loaded && userData.data.resume && (
          <BtnBleu
            btnAction={() => {
              downloadRPResume();
            }}
          >
            {t("profilShow.download")}
          </BtnBleu>
        )}
      </div>
      <Footer />
    </div>
  );
};

ProfilShow.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.object,
  i18n: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
  i18n: state.i18n,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilShow);
