import { combineReducers } from "redux";
import authReducer from "./Reducers/authReducer";
import menuReducer from "./Reducers/menuReducer";
import offersReducer from "./Reducers/offersReducer";
import usersReducer from "./Reducers/usersReducer";
import newsReducer from "./Reducers/newsReducer";
import eventsReducer from "./Reducers/eventsReducer";
import adminReducer from "./Reducers/adminReducer";
import groupsReducer from "./Reducers/groupsReducer";
import paginationReducer from "./Reducers/paginationReducer";
import universitiesReducer from "./Reducers/universitiesReducer";
import i18nReducer from "./Reducers/i18nReducer";
import statesReducer from "./Reducers/statesReducer";
import secondaryTablesReducer from "./Reducers/secondaryTablesReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  items: menuReducer,
  offers: offersReducer,
  users: usersReducer,
  news: newsReducer,
  events: eventsReducer,
  admin: adminReducer,
  groups: groupsReducer,
  pagination: paginationReducer,
  universities: universitiesReducer,
  i18n: i18nReducer,
  states: statesReducer,
  secondaryTables: secondaryTablesReducer,
});

export default rootReducer;
