import React, { useEffect, useMemo, useState, useRef } from "react";
import "./GroupeEvent.scss";
import PropTypes from "prop-types";
import Header from "Components/Header/Header";
import NavBarTab from "Components/NavBar/NavBarTab/NavBarTab";
import { connect } from "react-redux";
import { HiSearch } from "react-icons/hi";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Accordion, AccordionTab } from "primereact/accordion";
import {
  formatDateDatabase,
  formatDateLocale,
  isNotEmptyArray,
} from "Services/functions";
import { updatePagination } from "Redux/Actions/paginationActions";
import { updateGroups } from "Redux/Actions/groupsActions";
import ReactDatePicker from "react-datepicker";
import { CgClose } from "react-icons/cg";
import { useFetchGetPagination } from "Services/api";
import TempPagination from "Components/Pagination/TempPagination";
import GroupeEventCard from "./GroupeEventCard/GroupeEventCard";
import ModalCreationEvenementGroupe from "Components/Modal/ModalCreationEvenementGroupe/ModalCreationEvenementGroupe";
import ModalSuppressionEvenementGroupe from "Components/Modal/ModalSuppressionEvenementGroupe/ModalSuppressionEvenementGroupe";
import { Toast } from "primereact/toast";
import { useTranslation } from "react-i18next";
import Loader from "Components/Loader/loader";

/**
 * Permet d'afficher la page des événements de groupe
 * Elle regroupe les évenements des groupes dont l'utilisateur est membre
 */
const GroupeEvent = (props) => {
  const { t } = useTranslation("common");
  const evenementContainerRef = React.useRef();
  const [filtreDate, setFiltreDate] = useState(null);
  const [filtreMotsCles, setFiltreMotsCles] = useState("");
  const [filtreGroupe, setFiltreGroupe] = useState(null);

  const [eventsURL, setEventsURL] = useState(null);
  const eventsQuery = useFetchGetPagination(eventsURL, props.auth.token);
  const [events, setEvents] = useState([]);

  const [myGroupsURL, setMyGroupsURL] = useState(null);
  const myGroupsQuery = useFetchGetPagination(myGroupsURL, props.auth.token);
  const [myGroups, setMyGroups] = useState([]);

  // ----- Gestion des actions de l'utilisateur -----
  // eslint-disable-next-line no-unused-vars
  const [deleteEvent, setDeleteEvent] = useState({
    groupId: null,
    groupEvent: null,
    display: false,
    result: null,
  });

  const deleteToast = useRef(null);

  const [editEventDisplay, setEditEventDisplay] = useState(false);
  const [editEvent, setEditEvent] = useState({
    groupId: null,
    groupEvent: null,
  });

  useEffect(() => {
    return () => {
      props.handleUpdateGroups({
        dataFromPagination: null,
        filteringURL: null,
      });
    };
  }, []);

  // On récupère les groupes dont l'utilisateur est membre
  useMemo(() => {
    if (myGroupsQuery.loaded && myGroupsQuery?.data)
      setMyGroups(myGroupsQuery.data);
  }, [myGroupsQuery.loaded]);

  // On récupère les événements des groupes dont l'utilisateur est membre
  useMemo(() => {
    if (eventsQuery.loaded && eventsQuery?.data) setEvents(eventsQuery.data);
  }, [eventsQuery.loaded]);

  const buildGroupEventUrl = () => {
    let url = process.env.REACT_APP_BASE_URL_API;
    url += "/event_groups";
    url = new URL(url);
    return url;
  };

  const handleQueryParams = (url) => {
    url.searchParams.set("endingAt[after]", formatDateDatabase(new Date()));
    url.searchParams.set("order[startingAt]", "asc");
    url.searchParams.set("itemsPerPage", 20);
    //? Si l'user n'est pas ADMIN :
    //? Pour chaque groupe auquel l'utilisateur appartient, on ajoute un filtre
    if (!props.auth.isAdmin) {
      myGroups?.forEach((group) => {
        url.searchParams.append("associatedGroup[]", group.id);
      });
    }

    return url;
  };

  const handleFilter = (url) => {
    if (filtreMotsCles != "") url.searchParams.set("keyword", filtreMotsCles);
    if (filtreGroupe) url.searchParams.set("associatedGroup", filtreGroupe.id);
    if (filtreDate && filtreDate[0])
      url.searchParams.set(
        "startingAt[after]",
        formatDateDatabase(filtreDate[0])
      );
    if (filtreDate && filtreDate[1])
      url.searchParams.set(
        "endingAt[before]",
        formatDateDatabase(filtreDate[1])
      );
    return url;
  };

  const updateGroupEventsList = () => {
    let url = buildGroupEventUrl();
    url = handleQueryParams(url);
    url = handleFilter(url);
    setEventsURL(url);
  };

  useEffect(() => {
    if (myGroupsQuery.loaded) updateGroupEventsList();
  }, [filtreGroupe, filtreDate, filtreMotsCles, myGroupsQuery.loaded]);

  useMemo(() => {
    if (eventsQuery.loaded) setEvents([...(eventsQuery?.data || [])]);
  }, [eventsQuery?.data]);

  const buildMyGroupsURL = (page = 1) => {
    let url = process.env.REACT_APP_BASE_URL_API;
    url += props.auth.isAdmin
      ? "/groups"
      : "/groups/member/" + props.auth.userConnected.id;

    url = new URL(url);
    url.searchParams.append("order[name]", "asc");
    url.searchParams.append("page", page);
    url.searchParams.append("itemsPerPage", 100);
    if (!props.auth.isAdmin)
      url.searchParams.append(
        "memberState[]",
        props.states.groupMemberStatesData.find(
          (status) => status.label === "ACTIF"
        ).id
      );
    setMyGroupsURL(url);
  };

  // On récupère les groupes de l'utilisateur pour les afficher dans le filtre
  useMemo(() => {
    buildMyGroupsURL();
  }, []);

  // En cas de suppression d'un événement on affiche un toast en fonction du résultat
  // et on met à jour la liste des événements si la suppression a réussi
  useMemo(() => {
    if (deleteEvent.result) {
      let success = deleteEvent.result.status === 204;
      deleteToast.current.show({
        severity: success ? "success" : "error",
        summary: "Suppression d'un événement",
        detail: success
          ? "L'événement a bien été supprimé"
          : "Une erreur est survenue",
        life: 3000,
      });
      success && updateGroupEventsList();
      setDeleteEvent({
        groupId: null,
        groupEvent: null,
        display: false,
        result: null,
      });
    }
  }, [deleteEvent.result]);

  return (
    <div>
      <Toast ref={deleteToast} />
      <Header
        activeIndex={props.activeIndex}
        setActiveIndex={props.setActiveIndex}
        items={props.items}
      >
        {props.auth.isAdmin ? (
          <NavBarTab
            select="groupesadmin"
            activeIndex={props.activeIndex}
            items={props.items}
          />
        ) : (
          <NavBarTab
            select="groupes"
            activeIndex={props.activeIndex}
            items={props.items}
          />
        )}
      </Header>
      <div className="evenements_groupes_container">
        <div className="evenements_groupes_all">
          <div className="evenements_groupes_container__top">
            <div>
              <div className="evenements_groupes_container__top__title">
                <h1 className="h1_trait_dessus">
                  {t("footerApresConnexion.li_groupes_evenements")}
                </h1>
                <div className="evenements_groupes_container__top__title__visible">
                  <div className="evenements_groupes_container__top__title__filtres">
                    <span>
                      <HiSearch className="searchBar_groupe_finder_icon" />
                      <InputText
                        placeholder={t(
                          "evenementsHorsConnexion.placeholder_research"
                        )}
                        className="membership_input_text"
                        value={filtreMotsCles}
                        onChange={(e) => {
                          props.handleUpdateGroups({
                            /// Mettre la recherche à true, seulement si au moins l'un des filtres est activé
                            isSearchedEvent:
                              e.target.value !== "" ||
                              filtreDate != null ||
                              filtreGroupe != null,
                          });
                          setFiltreMotsCles(e.target.value.toLowerCase());
                        }}
                      />
                    </span>
                    <div>
                      <ReactDatePicker
                        placeholderText={t("actusApresConnexion.filtreDate")}
                        className={"small_item p-inputtext p-component"}
                        selected={filtreDate && filtreDate[0]}
                        onChange={(e) => {
                          setFiltreDate(e);
                        }}
                        dateFormat="dd/MM/yyyy"
                        startDate={filtreDate && filtreDate[0]}
                        endDate={filtreDate && filtreDate[1] && filtreDate[1]}
                        selectsRange
                      />
                    </div>
                    <Dropdown
                      value={filtreGroupe}
                      showClear
                      panelClassName="event_groups_panel"
                      options={myGroups || []}
                      filter
                      filterBy="label"
                      onChange={(e) => {
                        setFiltreGroupe(e.target.value);
                      }}
                      placeholder={t("groupManagement.filterGroups")}
                    />
                  </div>
                </div>
                <Accordion
                  className="accordion_events"
                  expandIcon="pi pi-sliders-h"
                  collapseIcon="pi pi-sliders-h"
                >
                  <AccordionTab header="Filtres de recherche">
                    <div className="evenements_groupes_container__top__title__filtres">
                      <span>
                        <HiSearch className="searchBar_groupe_finder_icon" />
                        <InputText
                          placeholder={t(
                            "evenementsHorsConnexion.placeholder_research"
                          )}
                          className="membership_input_text"
                          value={filtreMotsCles}
                          onChange={(e) => {
                            props.handleUpdateGroups({
                              /// Mettre la recherche à true, seulement si au moins l'un des filtres est activé
                              isSearchedEvent:
                                e.target.value !== "" ||
                                filtreDate != null ||
                                filtreGroupe != null,
                            });
                            setFiltreMotsCles(e.target.value.toLowerCase());
                          }}
                        />
                      </span>
                      <div>
                        <ReactDatePicker
                          placeholderText={t("actusApresConnexion.filtreDate")}
                          className={"small_item p-inputtext p-component"}
                          selected={filtreDate && filtreDate[0]}
                          onChange={(e) => {
                            setFiltreDate(e);
                          }}
                          dateFormat="dd/MM/yyyy"
                          startDate={filtreDate && filtreDate[0]}
                          endDate={filtreDate && filtreDate[1] && filtreDate[1]}
                          selectsRange
                        />
                        {filtreDate !== null && (
                          <CgClose
                            onClick={() => {
                              setFiltreDate(null);
                            }}
                          ></CgClose>
                        )}
                      </div>
                      <Dropdown
                        value={filtreGroupe}
                        panelClassName="event_groups_panel"
                        options={myGroups || []}
                        filter
                        filterBy="label"
                        onChange={(e) => {
                          setFiltreGroupe(e.target.value);
                        }}
                        placeholder={t("groupManagement.filterGroups")}
                      />
                    </div>
                  </AccordionTab>
                </Accordion>
              </div>
            </div>
          </div>
          {/* Si la liste des évènements de groupe n'est pas vide, on les affiche */}
          <div
            className="evenements_groupes_container__body"
            ref={evenementContainerRef}
          >
            {isNotEmptyArray(events) ? (
              events?.map((evenement) => (
                <GroupeEventCard
                  key={evenement.id}
                  evenement={evenement}
                  debutDate={
                    (props.i18n.language === "en" ? "From " : "Du ") +
                    formatDateLocale(evenement.startingAt, props.i18n.language)
                  }
                  finDate={
                    (props.i18n.language === "en" ? "to " : "au ") +
                    formatDateLocale(evenement.endingAt, props.i18n.language)
                  }
                  url={evenement?.imageUrl ? evenement.imageUrl : "event1"}
                  inscription={evenement?.register}
                  participants={evenement?.eventGroupParticipants}
                  maxParticipants={evenement?.maxNumberOfParticipants}
                  groupe
                  groupeName={evenement.associatedGroup.label}
                  setDeleteEvent={setDeleteEvent}
                  setEditEvent={setEditEvent}
                  setEditEventDisplay={setEditEventDisplay}
                />
              ))
            ) : eventsQuery.loaded ? (
              <div className="evenements_groupes_container__body-empty">
                {t("groupEvent.noneFound")}
              </div>
            ) : (
              <Loader />
            )}
          </div>
          {
            // Si la liste des évènements de groupe n'est pas vide, on affiche la pagination
            isNotEmptyArray(events) && (
              <TempPagination
                result={eventsQuery}
                rows={20}
                refItemParent={evenementContainerRef}
              />
            )
          }
        </div>
      </div>
      {editEventDisplay && (
        <ModalCreationEvenementGroupe
          visibleModalEvent={editEventDisplay}
          setVisibleModalEvent={setEditEventDisplay}
          currentGroupId={editEvent.groupId}
          edit
          groupEvent={editEvent.groupEvent}
        />
      )}
      {deleteEvent && (
        <ModalSuppressionEvenementGroupe
          visible={deleteEvent}
          setVisible={setDeleteEvent}
        />
      )}
    </div>
  );
};

GroupeEvent.propTypes = {
  title: PropTypes.string,
  chapeau: PropTypes.string,
  bouton: PropTypes.string,
  auth: PropTypes.object,
  groups: PropTypes.object,
  states: PropTypes.object,
  handleUpdateGroups: PropTypes.func,
  items: PropTypes.object,
  activeIndex: PropTypes.number,
  setActiveIndex: PropTypes.func,
  pagination: PropTypes.object,
  handleUpdatePagination: PropTypes.func,
  i18n: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
  groups: state.groups,
  pagination: state.pagination,
  states: state.states,
  i18n: state.i18n,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdatePagination: (value) => dispatch(updatePagination(value)),
  handleUpdateGroups: (value) => dispatch(updateGroups(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupeEvent);
