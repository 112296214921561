import { UPDATE_SECONDARY_TABLES } from "Redux/Actions/secondaryTablesActions.js";

const INITIAL_STATE = {
  levelOfEducationsData: [],
  domainsData: [],
  educationComposanteData: [],
  educationSpecialitiesData: [],
  levelOfExperiencesData: [],
  offerCategoriesData: [],
  sectorOfOffersData: [],
  statesData: [],
  typeOfContractsData: [],
  transmittersData: [],
};

const secondaryTablesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_SECONDARY_TABLES: {
      return {
        ...state,
        ...action.value,
      };
    }
    default:
      return state;
  }
};

export default secondaryTablesReducer;
