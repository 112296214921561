import Loader from "Components/Loader/loader";
import { updateGroups } from "Redux/Actions/groupsActions";
import { updateMenu } from "Redux/Actions/menuActions";
import { isNotEmptyArray } from "Services/functions";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { ScrollPanel } from "primereact/scrollpanel";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiSearch } from "react-icons/hi";
import { connect } from "react-redux";
import { debounce } from "../../../Services/debounce";
import GroupeCardMember from "../GroupeCard/GroupeCardMember/GroupeCardMember";
import "./GroupeMembership.scss";

const GroupeMembership = (props) => {
  const { t } = useTranslation("common");
  const [filtreMotsCles, setFiltreMotsCles] = useState("");

  const handleFilter = (url) => {
    url.searchParams.append("label", filtreMotsCles);
    return url;
  };

  const makeMyGroupsUrl = () => {
    let url = process.env.REACT_APP_BASE_URL_API;
    // Si on est admin, on récupère tous les groupes
    // Sinon, on récupère les groupes dont l'utilisateur est membre
    url += props.auth.isAdmin
      ? "/groups"
      : "/groups/member/" + props.auth.userConnected.id;
    let filteringURL = new URL(url);
    filteringURL.searchParams.append(
      "state[]",
      props.states.statesData.find((status) => status.statusLabel === "Actif")
        .id
    );
    if (!props.auth.isAdmin)
      filteringURL.searchParams.append(
        "memberState[]",
        props.states.groupMemberStatesData.find(
          (status) => status.label === "ACTIF"
        ).id
      );
    // filteringURL.searchParams.set("order[datePosted]", "desc");
    filteringURL.searchParams.append("itemsPerPage", 10);
    filteringURL = handleFilter(filteringURL);
    debounceGroupUrl(filteringURL);
  };

  /**
   * Mise en debounce du changement d'url pour éviter les appels trop fréquents
   * à l'API lors de la saisie dans la barre de recherche
   * @see {makeMyGroupsUrl} - fonction appelant le debounce
   * @type {(function(...[*]): void)|*}
   */
  const debounceGroupUrl = useCallback(
    debounce((filteringURL) => {
      props.handleUpdateGroups({
        filteringURL,
      });
    }, 500),
    []
  );

  // mise à jour du filtre de recherche
  useEffect(() => {
    let check =
      isNotEmptyArray(props.states.statesData) &&
      isNotEmptyArray(props.states.groupMemberStatesData);
    if (check) makeMyGroupsUrl();
  }, [
    props.states.statesData,
    props.states.groupMemberStatesData,
    filtreMotsCles,
  ]);

  //? Affichage du groupe sélectionné depuis la recherche
  useEffect(() => {
    if (props.groups.selectedGroup) {
      setFiltreMotsCles(props.groups.selectedGroup?.label || "");
    }
  }, [props.groups.selectedGroup]);

  return (
    <div className="membership_container">
      <h2 className="membership_style_h2">
        {t("footerApresConnexion.li_groupes_echanges")}
      </h2>
      <span>
        <HiSearch className="searchBar_groupe_echange_icon" />
        <InputText
          placeholder={t("groupManagement.searchGroup")}
          className="membership_input_text"
          value={filtreMotsCles}
          onChange={(e) => setFiltreMotsCles(e.target.value.toLowerCase())}
        />
      </span>
      <div className="membership_groupe_cards" key={props.myGroups}>
        <ScrollPanel style={{ width: "100%", height: "28rem" }}>
          {!props.myGroups.loaded && <Loader />}
          {props.myGroups.data &&
          props.myGroups.data.length > 0 &&
          props.myGroups.loaded
            ? // affichage des groupes filtrés
              props.myGroups.data.map((group, index) => (
                <React.Fragment key={index}>
                  <GroupeCardMember
                    group={group}
                    image={group.imageUrl ? "link" : "img1"}
                    link={group.imageUrl}
                    setSelectGroup={
                      props.setSelectGroup ? props.setSelectGroup : null
                    }
                    setLeaveGroup={
                      props.setLeaveGroup ? props.setLeaveGroup : null
                    }
                  />
                  {index != props.myGroups.data.length - 1 && (
                    <>
                      <Divider className="divider_desktop" />
                      <Divider layout="vertical" className="divider_mobile" />
                    </>
                  )}
                </React.Fragment>
              ))
            : props.myGroups.totalItems == 0 && (
                <p style={{ textAlign: "center" }}>
                  {filtreMotsCles
                    ? t("groupManagement.noGroupsMatch")
                    : t("groupManagement.noGroupsBelong")}
                </p>
              )}
        </ScrollPanel>
      </div>
    </div>
  );
};
GroupeMembership.propTypes = {
  groups: PropTypes.any,
  myGroups: PropTypes.object,
  handleUpdateGroups: PropTypes.func,
  setSelectGroup: PropTypes.func,
  items: PropTypes.any,
  handleMenu: PropTypes.func,
  auth: PropTypes.object,
  from: PropTypes.string,
  setLeaveGroup: PropTypes.func,
  states: PropTypes.any,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  groups: state.groups,
  items: state.items,
  states: state.states,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateGroups: (value) => {
    dispatch(updateGroups(value));
  },
  handleMenu: (value) => {
    dispatch(updateMenu(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupeMembership);
