import { UPDATE_MENU } from "../Actions/menuActions";

const INITIAL_STATE = {
  itemsAnnuaire: [
    {
      label: "Annuaire Alumni",
      icon: "pi pi-fw pi-star",
      data: "visibleAnnuaireAlumni",
    },
    {
      label: "Annuaire entreprises",
      icon: "pi pi-fw pi-building",
      data: "visibleAnnuaireEntreprise",
    },
  ],
  activeItemAnnuaire: {},
  itemsProfilEdit: [
    {
      label: "Informations personnelles",
      icon: "pi pi-fw pi-user-edit",
    },
    {
      label: "Formations et diplômes",
      icon: "pi pi-fw pi-briefcase",
    },
    {
      label: "Expériences professionnelles",
      icon: "pi pi-fw pi-building",
    },
    {
      label: "Compétences",
      icon: "pi pi-fw pi-book",
    },
    {
      label: "Paramètres de confidentialité",
      icon: "pi pi-fw pi-lock",
    },
  ],
  activeItemProfilEdit: 0,
  itemsProfil: [
    {
      label: "Dernières offres d'emploi",
      icon: "pi pi-fw pi-briefcase",
    },
    {
      label: "Offres sauvegardées",
      icon: "pi pi-fw pi-bookmark",
    },
    {
      label: "Candidatures envoyées",
      icon: "pi pi-fw pi-user-edit",
    },
    {
      label: "Mes événements à venir",
      icon: "pi pi-fw pi-calendar",
    },
  ],
  activeItemProfil: 0,
  itemsCVTheque: [
    {
      label: "Cv thèque",
      icon: "pi pi-fw pi-users",
    },
    {
      label: "Cv sauvegardés",
      icon: "pi pi-fw pi-bookmark",
    },
    {
      label: "Paniers de cv",
      icon: "pi pi-fw pi-shopping-bag",
    },
  ],
  activeItemCVTheque: 0,

  itemsGroupes: [
    {
      label: "Mes groupes d'échanges",
      icon: "pi pi-fw pi-comments",
    },
    {
      label: "Trouver un groupe",
      icon: "pi pi-fw pi-search",
    },
    {
      label: "Événements de groupe",
      icon: "pi pi-fw pi-calendar",
    },
    {
      label: "Proposer et gérer mes groupes",
      icon: "pi pi-fw pi-user-edit",
    },
  ],
  activeItemGroupes: 0,
  itemsGroupesAdmin: [
    {
      label: "Mes groupes d'échanges",
      icon: "pi pi-fw pi-comments",
    },
    {
      label: "Trouver un groupe",
      icon: "pi pi-fw pi-search",
    },
    {
      label: "Événements de groupe",
      icon: "pi pi-fw pi-calendar",
    },
    {
      label: "Créer et gérer mes groupes",
      icon: "pi pi-fw pi-user-edit",
    },
  ],
  activeItemGroupesAdmin: 0,

  itemsOffre: [
    {
      label: "Trouver une offre",
      icon: "pi pi-fw pi-search",
    },
    {
      label: "Déposer une offre",
      icon: "pi pi-fw pi-briefcase",
    },
    {
      label: "Gérer mes dépôts d'offres",
      icon: "pi pi-fw pi-book",
    },
  ],
  itemsOffreAdmin: [
    {
      label: "Trouver une offre",
      icon: "pi pi-fw pi-search",
    },
    {
      label: "Déposer une offre",
      icon: "pi pi-fw pi-briefcase",
    },
    {
      label: "Gérer les dépôts d'offres",
      icon: "pi pi-fw pi-book",
    },
  ],
  activeItemOffre: 0,

  itemsEvenement: [
    {
      label: "Tous les événements",
      icon: "pi pi-fw pi-calendar",
    },
    {
      label: "Proposer un événement",
      icon: "pi pi-fw pi-pencil",
    },
    {
      label: "Gérer mes événements",
      icon: "pi pi-fw pi-cog",
    },
  ],
  activeItemEvenement: 0,
  itemsEvenementAdmin: [
    {
      label: "Tous les événements",
      icon: "pi pi-fw pi-calendar",
    },
    {
      label: "Créer un événement",
      icon: "pi pi-fw pi-pencil",
    },
    {
      label: "Gérer les événements",
      icon: "pi pi-fw pi-cog",
    },
  ],
  activeItemEvenementAdmin: 0,

  itemsActu: [
    {
      label: "Toutes les actus",
      icon: "pi pi-fw pi-book",
    },
    {
      label: "Proposer une actu",
      icon: "pi pi-fw pi-pencil",
    },
    {
      label: "Gérer mes actus",
      icon: "pi pi-fw pi-cog",
    },
  ],
  activeItemActu: 0,
  itemsActuAdmin: [
    {
      label: "Toutes les actus/conseils",
      icon: "pi pi-fw pi-book",
    },
    {
      label: "Créer une actu/conseil",
      icon: "pi pi-fw pi-pencil",
    },
    {
      label: "Gérer les actus/conseils",
      icon: "pi pi-fw pi-cog",
    },
  ],
  activeItemActuAdmin: 0,
  itemsParametrage: [
    {
      label: "Requêtes et exports",
      icon: "pi pi-fw pi-book",
    },
    {
      label: "Gestion des droits",
      icon: "pi pi-fw pi-users",
    },
    {
      label: "Gestion des adhésions",
      icon: "pi pi-fw pi-building",
    },
    {
      label: "Gestion éditoriale",
      icon: "pi pi-fw pi-pencil",
    },
    {
      label: "Paramètres du site",
      icon: "pi pi-fw pi-cog",
    },
    {
      label: "Statistiques",
      icon: "pi pi-fw pi-chart-line",
    },
  ],
  activeItemParametrage: 0,
};

const menuReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case UPDATE_MENU: {
      return {
        ...state,
        ...action.value,
      };
    }

    default:
      return state;
  }
};

export default menuReducer;
