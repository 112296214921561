import React, { useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { connect } from "react-redux";
import "./ModalSignaler.scss";
import Modal from "../Modal";
import { Controller, useForm } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
const ModalSignaler = ({ visible, setVisible, auth }) => {
  const [message, setMessage] = React.useState(undefined);
  /**
   * Fonction qui permet d'envoyer une requête de signalement d'une entité
   * @param {String} url - url de l'api
   * @param {String} data - données à envoyer
   * @returns - retourne la réponse de l'api
   */
  const postReport = async (url, data) => {
    let errorResponse = null;
    const response = await axios
      .post(`${process.env.REACT_APP_BASE_URL_API}${url}`, data, {
        headers: auth.token
          ? {
              accept: "application/json",
              Authorization: `Bearer ${auth.token}`,
            }
          : {
              accept: "application/json",
            },
      })
      .catch((error) => {
        errorResponse = error.response.data.detail;
      });
    return response || errorResponse;
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      reason: null,
    },
  });

  /**
   * Callback appelé lorsque l'utilisateur valide le signalement
   *
   * En fonction du type de l'entité on envoie une requête différente
   * et on met à jour la propriété 'visible'
   */
  const reportEntity = async (data) => {
    var result = undefined;
    if (visible.type === "comment")
      result = await postReport(
        "/publication_comments/" + visible.id + "/report",
        data
      );
    else if (visible.type === "publication")
      result = await postReport(
        "/publications/" + visible.id + "/report",
        data
      );
    else return;
    reset();
    if (result && result.status === 200)
      setVisible({ ...visible, state: false, result: result });
    else setVisible({ ...visible, state: false, result: result });
  };

  const onSubmit = (data) => {
    reportEntity(data);
  };

  /**
   * Afffichage d'une erreur si nécessaire dans le formulaire
   * @param {String} name nom du champ
   * @returns {FieldError, JSX.Element}
   */
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  useEffect(() => {
    if (visible.state && visible.type) {
      switch (visible.type) {
        case "comment":
          setMessage("Êtes-vous sûr de vouloir supprimer ce commentaire ?");
          break;
        case "publication":
          setMessage("Êtes-vous sûr de vouloir supprimer cette publication ?");
          break;
        default:
          setMessage(undefined);
          break;
      }
    }
  }, [visible]);

  return (
    <div>
      {message && (
        <Modal
          visible={visible.state}
          setVisible={() => setVisible({ ...visible, state: false })}
          header={
            visible.type == "comment"
              ? "Signalement du commentaire"
              : "Signalement de la publication"
          }
          onHide={() => setVisible({ ...visible, state: false })}
          height="fit-content"
          width="80%"
          className="modal modal_signalement"
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={"signalement__form"}
          >
            <label htmlFor="content">Raison du signalement *</label>
            <Controller
              name="reason"
              control={control}
              rules={{
                required: "Veuillez spécifier une raison",
              }}
              render={({ field }) => (
                <InputText
                  {...field}
                  className={"signalement__form-content"}
                  placeholder="Ne respecte pas les règles..."
                />
              )}
            />
            {getFormErrorMessage("member")}
            <div className={"signalement__form-buttons"}>
              <BtnBlanc
                btnTexte="Annuler"
                action={"button"}
                btnAction={() => {
                  setVisible({ ...visible, state: false });
                  reset();
                }}
              />
              <BtnBleu btnTexte="Valider" />
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
};

ModalSignaler.propTypes = {
  visible: PropTypes.object.isRequired,
  setVisible: PropTypes.func.isRequired,
  auth: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  groups: state.groups,
});

export default connect(mapStateToProps)(ModalSignaler);
