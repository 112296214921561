import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./Pages/App/App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store } from "./Redux/store";
import { persistor } from "./Redux/store";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import "react-icons";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import { createInstance, MatomoProvider } from "@jonkoops/matomo-tracker-react";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_fr from "./translations/fr/commonFR.json";
import common_en from "./translations/en/commonEN.json";

/* Matomo part is commented because it requires to be running on a Docker container that I setup on the back-end docker compose. */

const URL = "//localhost:8080/";

const instance = createInstance({
  urlBase: URL,
  siteId: 1,
});

const queryClient = new QueryClient();
//? Getter du state de redux
const state = store.getState();
//? Initialisation de l'internationalisation avec 2 langues (FR,EN)
i18next.init({
  interpolation: { escapeValue: false },
  lng: state.i18n.language,
  resources: {
    fr: {
      common: common_fr,
    },
    en: {
      common: common_en,
    },
  },
});
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MatomoProvider value={instance}>
        <QueryClientProvider client={queryClient}>
          <I18nextProvider i18n={i18next}>
            <App />
          </I18nextProvider>
        </QueryClientProvider>
      </MatomoProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
