import React from "react";
import PropTypes from "prop-types";
import "./RecruteurCard.scss";
import { useLocation } from "react-router-dom";
import { truncate } from "Services/functions";
import { FaLinkedin, FaRegEnvelope, FaRegAddressBook } from "react-icons/fa";
import neutralUser from "assets/neutralUser.png";

const RecruteurCard = (props) => {
  return (
    <div
      className={
        useLocation().pathname == "/" ||
        useLocation().pathname == "/profil" ||
        useLocation().pathname == "/offres" ||
        useLocation().pathname == "/depot-offre" ||
        useLocation().pathname == "/annuaire" ||
        useLocation().pathname == "/gestion-offre"
          ? "offre cardHover"
          : "offre recruteur_card"
      }
    >
      <div className="annuaire_etudiant__image">
        <img
          src={props.imageUrl ? props.imageUrl : neutralUser}
          alt="Logo de l'entreprise"
          onError={(e) => {
            e.target.onerror = null;

            e.target.src = neutralUser;
          }}
          title="Logo de l'entreprise"
        />
        <span>
          {props.prenom} {props.nom}{" "}
        </span>
      </div>
      <div className="annuaire_etudiant__container">
        <div className="annuaire_etudiant__container__description">
          <div className="annuaire_etudiant__container__description__title recruteur_card">
            <div className="annuaire_etudiant__container__description__title__item recruteur_card">
              <label>Fonction :</label>
              <p>{truncate(props.title, 26)}</p>
            </div>
            <div className="annuaire_etudiant__container__description__title__item recruteur_card">
              <label>
                <FaRegEnvelope />
                Adresse mail :
              </label>
              <p>
                {props.mailPublic !== false ? (
                  props.email ? (
                    <a href={`mailto:${props.email && props.email}`}>
                      {" "}
                      {props.email}
                    </a>
                  ) : (
                    "Non renseignée"
                  )
                ) : (
                  "Indisponible"
                )}
              </p>
            </div>
            <div className="annuaire_etudiant__container__description__title__item recruteur_card">
              <label>
                <FaRegAddressBook />
                Tel :
              </label>
              <p>
                {props.phonePublic !== false
                  ? props.telephone
                    ? props.telephone
                    : "Non renseigné"
                  : "Indisponible"}
              </p>
            </div>

            {props.linkedin && (
              <FaLinkedin className="linkedin" size={"2rem"} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

RecruteurCard.propTypes = {
  title: PropTypes.string,
  nom: PropTypes.string,
  prenom: PropTypes.string,
  telephone: PropTypes.string,
  linkedin: PropTypes.bool,
  email: PropTypes.bool,
  image: PropTypes.string,
  phonePublic: PropTypes.bool,
  mailPublic: PropTypes.bool,
  imageUrl: PropTypes.string,
};

export default RecruteurCard;
