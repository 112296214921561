import React, { useState, useEffect, useRef } from "react";
import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import "./Profil.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import ProfilBar from "Components/ProfilBar/ProfilBar";
import OffresSauvegardees from "../../Components/Offres/OffresSauvegardees/OffresSauvegardees";
import CandidaturesEnvoyees from "../../Components/Candidatures/CandidaturesEnvoyees/CandidaturesEnvoyees";
import DernieresOffres from "Components/Offres/DernieresOffres/DernieresOffres";
import NavBarTab from "Components/NavBar/NavBarTab/NavBarTab";
import { useFetchGet } from "Services/api";
import { updateEvents } from "Redux/Actions/eventsActions";
import EvenementsAVenir from "../../Components/Evenements/EvenementsAVenir/EvenementsAVenir";
import { updateAuth, updateCompletionLevel } from "Redux/Actions/authActions";
import { updateGroups } from "Redux/Actions/groupsActions";

/**
 * Permet d'afficher la page de profil de l'utilisateur
 * Accessible pour tous les utilisateurs après connexion
 * Affichage des dernières offres d'emploi, des offres sauvegardées par l'utilisateur,
 * des candidatures envoyées, des ressources et guides et de ses évenements à venir
 */
const Profil = (props) => {
  const [offres, setOffres] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [loaderVisible, setLoaderVisible] = useState(false);
  const completionLevel = useFetchGet(
    `/users/${props.auth.userConnected.id}/profile_completion_rate`,
    props.auth.token
  );
  const profilRef = useRef(null);

  const dataGroups = useFetchGet("/groups", props.auth.token);

  useEffect(() => {
    if (dataGroups.loaded)
      props.handleUpdateGroups({
        groupsData: dataGroups.data,
      });
  }, [dataGroups.loaded]);

  useEffect(() => {
    if (profilRef.current !== null) {
      window.scroll({
        top: profilRef.current.offsetTop,
        behavior: "smooth",
      });
    }
    completionLevel.loaded &&
      props.handleUpdateCompletionLevel(completionLevel.data);
  }, []);
  // récupération des offres de l'api
  const getOffres = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL_API}/offers`, {
        headers: props.auth.token
          ? {
              accept: "application/json",
              Authorization: `Bearer ${props.auth.token}`,
            }
          : {
              accept: "application/json",
            },
      })
      .then(function (response) {
        setOffres(response.data);
        setLoaderVisible(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // remplissage de Redux avec les offres
  useEffect(() => {
    setLoaderVisible(true);
    getOffres();
  }, []);

  // récupération des événements spécifiques à l'utilisateur
  const dataMyEvents = useFetchGet(
    `/user_events/${props.auth.userConnected.id}`,
    props.auth.token
  );

  // remplissage de Redux avec les données des événements spécifiques à l'utilisateur
  useEffect(() => {
    dataMyEvents.loaded &&
      dataMyEvents.data &&
      dataMyEvents.data.length > 0 &&
      props.handleUpdateEvents({
        myEvents: dataMyEvents.data,
      });
  }, [dataMyEvents]);

  return (
    <div className="profilPage" ref={profilRef}>
      <Header>
        <div className="profilPage__headerProfilBar">
          {props.auth.isConnected && (
            <>
              <ProfilBar id="desktop" />

              {props.items.activeItemProfil ==
                props.items.itemsProfil.findIndex(
                  (v) => v.label == "Dernières offres d'emploi"
                ) && (
                <NavBarTab
                  id="mobile"
                  select="profil"
                  activeIndex={props.items.activeItemProfil}
                  items={props.items.itemsProfil}
                />
              )}
              {props.items.activeItemProfil ==
                props.items.itemsProfil.findIndex(
                  (v) => v.label == "Offres sauvegardées"
                ) && (
                <NavBarTab
                  id="mobile"
                  select="profil"
                  activeIndex={props.items.activeItemProfil}
                  items={props.items.itemsProfil}
                />
              )}
              {props.items.activeItemProfil ==
                props.items.itemsProfil.findIndex(
                  (v) => v.label == "Candidatures envoyées"
                ) && (
                <NavBarTab
                  id="mobile"
                  select="profil"
                  activeIndex={props.items.activeItemProfil}
                  items={props.items.itemsProfil}
                />
              )}
              {props.items.activeItemProfil ==
                props.items.itemsProfil.findIndex(
                  (v) => v.label == "Ressources et guides"
                ) && (
                <NavBarTab
                  id="mobile"
                  select="profil"
                  activeIndex={props.items.activeItemProfil}
                  items={props.items.itemsProfil}
                />
              )}
              {props.items.activeItemProfil ==
                props.items.itemsProfil.findIndex(
                  (v) => v.label == "Mes événements à venir"
                ) && (
                <NavBarTab
                  id="mobile"
                  select="profil"
                  activeIndex={props.items.activeItemProfil}
                  items={props.items.itemsProfil}
                />
              )}
            </>
          )}
        </div>
      </Header>
      {props.auth.isConnected && (
        <div>
          {props.items.activeItemProfil ==
            props.items.itemsProfil.findIndex(
              (v) => v.label == "Dernières offres d'emploi"
            ) && (
            <DernieresOffres
              activeIndex={props.items.itemsProfil.findIndex(
                (v) => v.label == "Dernières offres d'emploi"
              )}
              items={props.items.itemsProfil}
              offres={offres}
            />
          )}
          {props.items.activeItemProfil ==
            props.items.itemsProfil.findIndex(
              (v) => v.label == "Offres sauvegardées"
            ) && (
            <OffresSauvegardees
              activeIndex={props.items.itemsProfil.findIndex(
                (v) => v.label == "Offres sauvegardées"
              )}
              items={props.items.itemsProfil}
              offres={offres}
            />
          )}
          {props.items.activeItemProfil ==
            props.items.itemsProfil.findIndex(
              (v) => v.label == "Candidatures envoyées"
            ) && (
            <CandidaturesEnvoyees
              activeIndex={props.items.itemsProfil.findIndex(
                (v) => v.label == "Candidatures envoyées"
              )}
              items={props.items.itemsProfil}
            />
          )}
          {props.items.activeItemProfil ==
            props.items.itemsProfil.findIndex(
              (v) => v.label == "Mes événements à venir"
            ) && (
            <EvenementsAVenir
              activeIndex={props.items.itemsProfil.findIndex(
                (v) => v.label == "Mes événements à venir"
              )}
              items={props.items.itemsProfil}
            />
          )}
        </div>
      )}

      <Footer />
    </div>
  );
};

Profil.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.object,
  groups: PropTypes.object,
  handleUpdateEvents: PropTypes.func,
  handleUpdateAuth: PropTypes.func,
  handleUpdateCompletionLevel: PropTypes.func,
  handleUpdateGroups: PropTypes.func,
  states: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
  groups: state.groups,
  states: state.states,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateEvents: (value) => {
    dispatch(updateEvents(value));
  },
  handleUpdateAuth: (value) => {
    dispatch(updateAuth(value));
  },
  handleUpdateCompletionLevel: (value) => {
    dispatch(updateCompletionLevel(value));
  },
  handleUpdateGroups: (value) => {
    dispatch(updateGroups(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Profil);
