import React, { useState } from "react";
import Modal from "../Modal";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { updateAuth } from "Redux/Actions/authActions";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import "./ModalConnexion.scss";
import { t } from "i18next";
import ModalCreationCompte from "../ModalCreationCompte/ModalCreationCompte";
import HeaderUserLogin from "Components/Header/HeaderTop/HeaderUserLogin/HeaderUserLogin";

const ModalConnexion = (props) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleModalCreationCompte, setVisibleModalCreationCompte] =
    useState(false);

  return (
    <div>
      <Modal
        visible={props.visibleModal}
        setVisible={props.setVisibleModal}
        header="Se connecter - Choisissez votre université"
      >
        <div className="description">
          <div className="universites__connexion"></div>
          <BtnBlanc
            btnTexte="Se connecter"
            btnAction={() => {
              setVisibleModal(!visibleModal);
            }}
          />
          <BtnBlanc
            btnTexte="Créer un compte"
            btnAction={() => {
              setVisibleModalCreationCompte(!visibleModalCreationCompte);
            }}
          />
        </div>
      </Modal>
      {visibleModal && (
        <HeaderUserLogin
          loginVisible={visibleModal}
          setLoginVisible={setVisibleModal}
        />
      )}
      {visibleModalCreationCompte && (
        <Modal
          visible={visibleModalCreationCompte}
          setVisible={setVisibleModalCreationCompte}
          header={t("header.compte_creation")}
          width="70vw"
          blockScroll={true}
        >
          <ModalCreationCompte
            visibleModal={visibleModalCreationCompte}
            setVisibleModal={setVisibleModalCreationCompte}
          ></ModalCreationCompte>
        </Modal>
      )}
    </div>
  );
};

ModalConnexion.propTypes = {
  auth: PropTypes.object,
  handleAuth: PropTypes.func,

  visibleModal: PropTypes.bool,
  setVisibleModal: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  handleAuth: (value) => {
    dispatch(updateAuth(value));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(ModalConnexion);
