import { ALL, ME, RECRUTEUR } from "Components/Management/ManagementButtons";
import { store } from "Redux/store";
import { HTMLParseOptions } from "Services/htmlParseOptions";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import { DateTime } from "luxon";

/**
 * Fonction pour afficher la ou les dates de début et/ou de fin d'un diplôme
 * en fonction de sa certification et de son statut actuel
 * @param {{startDate: String|null, endDate: String|null, current: Boolean|null, certified: Boolean|null}} education
 * @param {"en" | "fr"} language - Langue de l'application
 * @returns {String} - Date de début et/ou de fin du diplôme
 */
export function displayEducationDates(education, language = "fr") {
  if (education.current === true) {
    let message = language === "fr" ? "En cours" : "Ongoing";
    if (education.startDate) {
      const displayStart = convert(education.startDate);
      message += ` ${language === "fr" ? "depuis" : "since"} ${displayStart}`;
    }
    return message;
  }
  if (education.startDate || education.endDate) {
    let displayStart = "";
    let displayEnd = "";

    if (education.startDate) {
      displayStart = convert(education.startDate);
    }

    if (education.endDate) {
      displayEnd = convert(education.endDate);
      const endDate = new Date(education.endDate);

      if (education.certified && !isNaN(endDate)) {
        const promotionYear = endDate.getFullYear() - 1;
        return `Promotion ${promotionYear} - ${promotionYear + 1}`;
      }
    }
    return `${displayStart} - ${displayEnd}`;
  } else return "";
}

/**
 *
 * @param {{imageUrl: string?, companyUrl: string}} company
 * @param {File | null} imageUpload
 * @returns {string | null} url à afficher pour l'image de l'entreprise
 */
export function displayCompanyImage(company, imageUpload = null) {
  if (imageUpload?.objectURL) return imageUpload.objectURL;
  if (company?.imageUrl) return company.imageUrl;
  if (company?.companyUrl) {
    try {
      const url = new URL(`${company?.companyUrl}`);
      return `https://logo.clearbit.com/${url.hostname}`;
    } catch (e) {
      return null;
    }
  }
  return null;
}

/**
 * Fonction permettant de récupérer un cookie par son nom
 * @param {string} name - nom du cookie
 * @returns {string} - valeur du cookie
 */
export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

/**
 * Fonction qui créé un objet Date à partir de la date de l'event et se sont heure
 * @param {String} eventDate
 * @param {String} eventTime
 * @returns {Date} objet de type date
 */
export const formatDateTimeEvent = (eventDate, eventTime) => {
  const eventSplitDate = formatDate(eventDate).split("/");
  let eventSplitHour = [];
  let finalDate = null;

  if (eventTime) {
    eventSplitHour = formatHourFrenchLocale(eventTime).split("h");
    finalDate = new Date(
      eventSplitDate[2],
      eventSplitDate[1],
      eventSplitDate[0],
      eventSplitHour[0],
      eventSplitHour[1]
    );
  } else {
    finalDate = new Date(
      eventSplitDate[2],
      eventSplitDate[1],
      eventSplitDate[0]
    );
  }

  return finalDate;
};

/**
 * Fonction permettant de convertir une date comme Tue Aug 30 2022 00:00:00 GMT+0200
 * @param {Date} date
 * @returns {String} Date DD/MM/YYYY
 */
export const convert = (str) => {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [day, mnth, date.getFullYear()].join("/");
};

/**
 * Fonction pour formatter une date dans un locale donné
 * @param {Date | String} date - Date à formater
 * @param {"en" | "fr"} locale - Locale de la date
 * @returns {String} Date formatée avec Intl.DateTimeFormat
 */
export const formatDateLocale = (date, locale) => {
  const jsDate = DateTime.fromISO(date).toJSDate() || new Date(date);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const dateFormat = Intl.DateTimeFormat(
    locale === "en" ? "en-GB" : "fr-FR",
    options
  );
  return dateFormat.format(jsDate);
};

/**
 * Fonction permettant d'afficher une date sous forme de texte en français
 * @param {Date} date
 * @returns {String} Date en français (ex: lundi 8 janvier 2022) | vide si date invalide
 */
export const formatDateFrenchLocale = (date) => {
  if (isNaN(Date.parse(date))) {
    console.log("The date '" + date + "' is not a valid date");
    return "";
  }
  const jsDate = new Date(date);
  const actualDate = DateTime.fromJSDate(jsDate, { locale: "fr" });
  return (
    actualDate.weekdayLong +
    " " +
    actualDate.day +
    " " +
    actualDate.monthLong +
    " " +
    actualDate.year
  );
};

/**
 *
 * @param {Date} date
 * @returns {String} Date en nomenclature DD.MM.YYYY | vide si date invalide
 */
export const formatDate = (date, separator = "/") => {
  if (isNaN(Date.parse(date))) {
    // console.log("The date '" + date + "' is not a valid date");
    return date || "";
  }

  const actualDate = new Date(date);
  const day = actualDate.getDate().toLocaleString("fr-FR", {
    minimumIntegerDigits: 2,
  });
  const month = (actualDate.getMonth() + 1).toLocaleString("fr-FR", {
    minimumIntegerDigits: 2,
  });
  const year = actualDate.getFullYear();
  return day + separator + month + separator + year;
};

/**
 *
 * @param {Date} date
 * @returns {String} Date en nomenclature MM.YYYY | vide si la date n'est pas déjà sur ce format
 */
export const formatDateWihoutDays = (date) => {
  if (date !== null) {
    if (date.length !== 7) {
      const actualDate = new Date(date);

      const month = (actualDate.getUTCMonth() + 1).toLocaleString("fr-FR", {
        minimumIntegerDigits: 2,
      });
      const year = actualDate.getUTCFullYear();
      return `${month}/${year}`;
    } else return date;
  }
};

/**
 * Fonction permettant de formater une date qui n'a pas de jour et de changer l'ordre des mois et de l'année
 * (en prenant en compte l'offset de la timezone)
 * @param {Date} date format month/year
 * @returns {String} la date formatée en string : YYYY-MM-DD |
 */
export const changeOrderDate = (date) => {
  const dateSplited = date.split("/");
  const month = dateSplited[0];
  const year = dateSplited[1];
  return `${year}-${month}-01`;
};

/**
 * Fonction permettant de formater une date et de changer l'ordre des mois et de l'année et des jours
 * (en prenant en compte l'offset de la timezone)
 * @param {Date} date format month/year
 * @returns {String} la date formatée en string : YYYY-MM-DD |
 */
export const changeOrderDateWithDay = (date) => {
  const dateSplited = date.split("/");
  const day = dateSplited[0];
  const month = dateSplited[1];
  const year = dateSplited[2];
  return `${year}-${month}-${day}`;
};
/**
 * Fonction permettant de formater une date en français, en string
 * (en prenant en compte l'offset de la timezone)
 * @param {Date} date
 * @returns {String} la date formatée en string : YYYY-MM-DD | vide si date invalide
 */
export const formatDateDatabase = (date) => {
  if (isNaN(Date.parse(date))) {
    console.log("The date '" + date + "' is not a valid date");
    return "";
  }

  let newDate = new Date(date - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split("T")[0];
  return newDate;
};

/**
 * Fonction permettant de formater une date en français, en string
 * (en prenant en compte l'offset de la timezone) avec l'heure et les minutes
 * @param {Date} date
 * @returns {String} la date formatée en string : DD/MM/YYY à HHhmm | vide si date invalide
 */
export const formatDateHourFrenchLocale = (date) => {
  if (isNaN(Date.parse(date))) {
    console.log("The date '" + date + "' is not a valid date");
    return "";
  }

  const actualDate = new Date(date);
  const day = actualDate.getDate().toLocaleString("fr-FR", {
    minimumIntegerDigits: 2,
  });
  const month = (actualDate.getUTCMonth() + 1).toLocaleString("fr-FR", {
    minimumIntegerDigits: 2,
  });
  const year = actualDate.getUTCFullYear();
  let newHour = actualDate.getHours().toLocaleString("fr-FR", {
    minimumIntegerDigits: 2,
  });
  let newMin = actualDate.getMinutes().toLocaleString("fr-FR", {
    minimumIntegerDigits: 2,
  });
  return day + "/" + month + "/" + year + " à " + newHour + "h" + newMin;
};

/**
 * Fonction permettant de formater une date en français, en string
 * (en prenant en compte l'offset de la timezone) avec l'heure et les minutes
 * @param {Date} date - la date à formatter
 * @param {"en" | "fr"} locale - la locale de la date
 * @returns {String} HHhmm | vide si date invalide
 */
export const formatHourFrenchLocale = (date, locale = "fr") => {
  if (isNaN(Date.parse(date))) {
    return "";
  }

  const actualDate = new Date(date);
  return Intl.DateTimeFormat(locale === "en" ? "en-GB" : "fr-FR", {
    hour: "2-digit",
    minute: "2-digit",
  }).format(actualDate);
};

/**
 * Fonction permettant de formater un mot donné avec une majuscule au début
 * @param {String} word - le mot à formatter
 * @param {boolean} lowerCaseRest - défini si le reste du mot doit être en minuscule ou pas
 * @returns {String} la string, formaté | vide si word n'est pas une string
 */
export const upperCaseFirstLetter = (word, lowerCaseRest = false) => {
  if (typeof word !== "string") {
    console.log("The parameter '" + word + "' is not a string");
    return "";
  }

  if (lowerCaseRest)
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  return word.charAt(0).toUpperCase() + word.slice(1);
};

/**
 * Fonction permettant de générer un ordre aléatoire pour le contenu
 * d'un array donnée en paramètre
 * @param {Array} array
 * @returns  {Array} array avec un ordre aléatoire | vide si array n'est pas un array
 */
export const shuffleArray = (array) => {
  if (!Array.isArray(array)) {
    console.log("The parameter '" + array + "' is not an array");
    return [];
  }

  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

/**
 * Fonction permettant de trier un array d'Objet par Date.
 * La clé contenant la date est donnée en paramètre
 *
 * @param {Array} tableau
 * @return {Array} array trié par ordre croissant | vide si array n'est pas un array / ou ne contient pas de date / ou ne contient pas de clé
 */
export const sortArrayByDateByKey = (tableau, key) => {
  if (!Array.isArray(tableau)) {
    console.log("The parameter '" + tableau + "' is not an array");
    return [];
  }
  if (!tableau.every((element) => !isNaN(Date.parse(element[key])))) {
    console.log(
      "The parameter '" +
        tableau +
        "' is not an array of object with Date property in the key '" +
        key +
        "'"
    );
    return [];
  }

  function compare(a, b) {
    let aDate = new Date(a[key]);
    let bDate = new Date(b[key]);
    if (aDate < bDate) {
      return -1;
    }
    if (aDate > bDate) {
      return 1;
    }
    return 0;
  }
  return tableau.sort(compare);
};

/**
 * Fonction permettant de récupérer une valeur aléatoire d'un tableau
 * @param {Array} tab
 * @return {Array} étément de l'array
 */
export const getRandomItemNonEqual = (tab, item) => {
  if (!Array.isArray(tab) || tab.length === 0) {
    console.log("The parameter '" + tab + "' is not an array or is empty");
    return null;
  }
  if (tab.includes(item) && tab.length === 1) {
    console.log(
      "The parameter '" +
        tab +
        "' only has one element and is equal to '" +
        item +
        "'"
    );
    return null;
  }

  let randomItem = getRandomItem(tab);
  while (item == randomItem) randomItem = getRandomItem(tab, item);
  return randomItem;
};

/**
 * Fonction permettant de récupérer une valeur aléatoire d'un tableau
 * @param {Array} tab
 * @return {Array} étément de l'array
 */
export const getRandomItem = (tab) => {
  return tab[Math.floor(Math.random() * tab.length)];
};

/**
 * Fonction permettant de récupérer le contrat d'une offre sous le bon format (majuscules, minuscules)
 * @param {String} contrat
 * @return {String} contrat formaté | vide si contrat n'est pas une string ou vide
 */

export const formatContrat = (contrat) => {
  if (typeof contrat !== "string" || !contrat) return "";

  if (
    contrat == "CONTRAT D'APPRENTISSAGE" ||
    contrat == "POST-DOCTORANT" ||
    contrat == "INTERIM" ||
    contrat == "CONTRAT DE PROFESSIONNALISATION"
  )
    return upperCaseFirstLetter(contrat, true);
  else return contrat;
};

/**
 *  Fonction permettant de vérifier si la variable passée en paramètre
 *  peut être parsée en JSON
 *  @param {String} String
 *  @return {Boolean} true si la string peut être parsée en JSON, false sinon
 */ export const isJsonParsable = (string) => {
  try {
    JSON.parse(string);
  } catch (e) {
    return false;
  }
  return true;
};

/**
 * Fonction permettant de vérifier si le tableau passé en paramètre
 * est tout d'abord non null, le cas échéant, si il n'est pas vide
 * @param {Array} array
 */
export const isNotEmptyArray = (array) => {
  return array !== null && array != undefined && array.length > 0;
};

/**
 * Fonction permettant d'ajouter un jour a une date de format MM/YYYY puis de vérifier les dates entre elles
 * est tout d'abord non null, le cas échéant, si il n'est pas vide
 * @param {String} string
 * @return {Boolean} true si le premier paramètre est inférieure au deuxième paramètre
 */
export const addDayToDateAndCompare = (
  startDateToFormated,
  endDateToFormated
) => {
  const monthStart = startDateToFormated.split("/")[0];
  const yearStart = startDateToFormated.split("/")[1];
  const monthEnd = formatDate(endDateToFormated).split("/")[1];
  const yearEnd = formatDate(endDateToFormated).split("/")[2];
  const [newStartDate, newEndDate] = [new Date(), new Date()];
  newStartDate.setMonth(monthStart - 1);
  newStartDate.setFullYear(yearStart);
  newEndDate.setMonth(monthEnd - 1);
  newEndDate.setFullYear(yearEnd);
  return newEndDate >= newStartDate;
};

/**
 * Fonction qui à partir d'une liste de name retourne une liste d'id de transmitter
 * @param {Array} array - Array de string avec des names de transmitter
 */
export const getTransmitterId = (array) => {
  const transmittersList = store.getState().secondaryTables.transmittersData;
  let outputArray = [];
  transmittersList.forEach((baseTransmitter) => [
    array.some((transmitter) => baseTransmitter.name === transmitter) &&
      outputArray.push(baseTransmitter.id),
  ]);
  return outputArray;
};

/**
 * Fonction qui retourne une nouvelle URL de filtrage quand les transmitters changent
 * @param {String} string - route de l'url (ex : offers, news, events)
 * @param {URL} url - Url actuelle
 * @param {Array} array - Liste des noms des nouveaux transmitters
 * @param {Number} number - Id of the User
 */
export const filterByTransmitter = (
  route,
  currentUrl,
  transmitters,
  userId
) => {
  let newUrl = new URL(`${process.env.REACT_APP_BASE_URL_API}/${route}`);
  if (transmitters[0] === RECRUTEUR) {
    newUrl = new URL(
      `${process.env.REACT_APP_BASE_URL_API}/${route}/recruiter`
    );
  }
  newUrl.search = currentUrl ? currentUrl.search : "";
  newUrl.searchParams.delete("transmitter[]");
  newUrl.searchParams.delete("createdByUser[]");
  newUrl.searchParams.delete("createdBy[]");

  if (transmitters[0] === ALL || transmitters[0] === RECRUTEUR) return newUrl;
  if (transmitters[0] === ME) {
    if (route === "offers") {
      newUrl.searchParams.append("createdByUser[]", userId);
    } else {
      newUrl.searchParams.append("createdBy[]", userId);
    }
  } else {
    getTransmitterId(transmitters).forEach((transmitter) => {
      newUrl.searchParams.append("transmitter[]", transmitter);
    });
  }
  return newUrl;
};

/**
 * Fonction permettant de calculer le nombre de membres actifs dans un groupe
 * (en plus du créateur)
 * @param {{groupMembers: [{id: Number, groupMemberStatus: {id: Number, label: String}}], createdBy: {id: Number}}} group
 * @return {String} Contenant le nombre de membres actifs + créateur ainsi que le mot membre au singulier ou pluriel
 */
export const getNumberOfMembersInGroup = (group) => {
  // Si les propriétés ne sont pas valides, on retourne 0

  if (!group || !group.groupMembers || group.groupMembers.length == 0)
    return " " + 0 + " membre";

  // Sinon on récupère le nombre de membres actifs auquel on additionne le créateur
  let numberOfMembers = group.groupMembers.filter(
    (member) => member.groupMemberStatus.label == "ACTIF"
  ).length;

  // Formattage de la réponse : x membre(s)
  return (
    " " +
    numberOfMembers.toString() +
    (numberOfMembers > 1 ? " membres" : " membre")
  );
};

export const findItemInEditManagement = (itemToFind, reduxData) => {
  if (reduxData != null && reduxData?.length > 0 && reduxData != undefined) {
    const itemFound = reduxData.filter((item) => item?.label === itemToFind);
    if (itemFound != undefined) {
      if (itemFound[0]?.contentManagement.length >= 1) {
        const itemLocal = itemFound[0].contentManagement.find(
          (item) => item.editAvailable === "local"
        );
        if (itemLocal != undefined) {
          return itemLocal.content;
        } else {
          let content = itemFound[0].contentManagement?.find(
            (item) => item.editAvailable === "global"
          )?.content;
          if (content) return content;
        }
      }
      return itemFound[0]?.contentManagement?.find(
        (item) => item.editAvailable === "default"
      )?.content;
    }
  }
};

export const findManagementData = (
  itemToFind,
  nexusManagement,
  univManagement,
  parseResult = true
) => {
  let managementData = null;
  if (nexusManagement && nexusManagement?.length > 0) {
    let result = findItemInEditManagement(itemToFind, nexusManagement);
    if (result)
      managementData = parseResult ? parse(result, HTMLParseOptions) : result;
  }
  if (!managementData && univManagement && univManagement?.length > 0) {
    let result = findItemInEditManagement(itemToFind, univManagement);
    if (result)
      managementData = parseResult ? parse(result, HTMLParseOptions) : result;
  }
  return managementData ? managementData : "";
};

export const findUnivManagementData = (
  itemToFind,
  nexusManagement,
  univManagement,
  univSlug
) => {
  let managementData = null;
  if (nexusManagement && nexusManagement?.length > 0) {
    let result = findItemInEditManagementUniv(
      itemToFind,
      nexusManagement,
      univSlug
    );
    if (result) managementData = parse(result, HTMLParseOptions);
  }
  if (!managementData && univManagement && univManagement?.length > 0) {
    let result = findItemInEditManagementUniv(
      itemToFind,
      univManagement,
      univSlug
    );
    if (result) managementData = parse(result, HTMLParseOptions);
  }
  return managementData ? managementData : "";
};

export const findItemInEditManagementVisibility = (itemToFind, reduxData) => {
  if (reduxData != null && reduxData?.length > 0 && reduxData != undefined) {
    const itemFound = reduxData.filter((item) => item?.label === itemToFind);
    if (itemFound != undefined) {
      return itemFound[0]?.valueManagement.length > 1
        ? itemFound[0].valueManagement.find(
            (item) => item.editAvailable === "local"
          ).value
        : itemFound[0].valueManagement[0].value;
    }
  }
};

export const findItemInEditManagementUniv = (itemToFind, reduxData, slug) => {
  if (reduxData != null && reduxData?.length > 0 && reduxData != undefined) {
    const itemFound = reduxData.filter((item) => {
      return item?.label == itemToFind;
    });
    if (itemFound != undefined) {
      if (itemFound[0]?.contentManagement.length > 1) {
        const itemLocal = itemFound[0].contentManagement.find(
          (item) =>
            item.editAvailable === "local" &&
            item.editedByUniversity.slug == slug
        );
        if (itemLocal != undefined) {
          return itemLocal?.content;
        } else {
          const itemGlobal = itemFound[0].contentManagement.find(
            (item) =>
              item.editAvailable === "global" &&
              item.editedByUniversity.slug == slug
          );
          if (itemGlobal != undefined)
            return (
              itemFound[0]?.contentManagement.find(
                (item) =>
                  item.editAvailable === "global" &&
                  item.editedByUniversity.slug == slug
              )?.content || ""
            );
          else {
            return (
              itemFound[0]?.contentManagement.find(
                (item) =>
                  item.editAvailable === "default" &&
                  item.editedByUniversity.slug == slug
              )?.content || ""
            );
          }
        }
      }
    }
  }
};

/**
 * Fonction permettant de parser une string en base64 en objet JSON (JWT)
 * @param {String} token - token de l'utilisateur en base64
 * @returns {String} - token de l'utilisateur parsé depuis base64
 */
export const parseJwt = (token) => {
  try {
    if (!token || typeof token !== "string") throw new Error("Invalid token");
    var base64Url = token.split(".")[1];
    if (!base64Url) throw new Error("Invalid token");
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    const parsed = JSON.parse(jsonPayload);
    return parsed;
  } catch (e) {
    return null;
  }
};

/**
 * Fonction purifiant une chaîne contenant du HTML
 * avec la librairie DOMPurify
 *
 * Si la chaîne ne contient pas de tag HTML,
 * on retourne la chaîne telle quel
 *
 * @param {any} html - 'String' contenant du HTML
 * @returns {String} - String purifié
 */
export const sanitizeHtml = (html) => {
  if (html === null || html === "" || typeof html !== "string") return "";
  if (!html.match(/<(.*)>.?|<(.) \/>/g)) return html;

  return DOMPurify.sanitize(html);
};

/**
 * Fonction permettant de tronquer une string au delà d'un nombre de caractères (n)
 * @param {String} str - String à tronquer
 * @param {Number} n - Taille max de la string
 * @returns - String tronqué pour être affiché
 */
export const truncate = (str, n) => {
  if (str === null || str === "" || !str) return "";
  else str = str.toString();
  //? Si la string contient un tag HTML, on 'purifie' la string <(.)>.?|<(.) />
  if (str.match(/<(.*)>.?|<(.) \/>/g)) str = sanitizeHtml(str);
  else {
    if (str.length <= n) return str; // Si la string est plus petite que n, on la retourne telle quelle
  }
  let truncated = str.slice(0, n);

  // Check if we're in the middle of an HTML entity
  const entityStart = truncated.lastIndexOf("&");
  const entityEnd = truncated.indexOf(";", entityStart);
  if (entityStart !== -1 && (entityEnd === -1 || entityEnd > n)) {
    // Adjust the cut-off point to be before the entity starts
    truncated = truncated.slice(0, entityStart);
  }

  return truncated + "...";
};

/**
 * Fonction permettant de retirer les tags HTML d'une string
 * @param {String} str - String contenant des tags HTML
 */
export const stripHtml = (str) => {
  if (str === null || str === "") return "";
  else str = str.toString();

  const parser = new DOMParser();

  const parsed = parser.parseFromString(str, "text/html");

  return parsed.body.textContent || "";
};

/**
 * Fonction qui à partir d'un IRI retourne le nom du transmitter
 * @param {String} iri - IRI de transmitter
 */
export const TransmitterIRItoName = (iri) => {
  const id = Number(iri.split("/")[3]);
  const transmittersList = store.getState().secondaryTables.transmittersData;
  const transmitter = transmittersList.find(
    (transmitter) => transmitter.id === id
  );
  return transmitter.name;
};
