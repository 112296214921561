import React, { useEffect, useRef } from "react";
import "./DernieresOffres.scss";
import PropTypes from "prop-types";
import ProfilBar from "Components/ProfilBar/ProfilBar";
import NavBarTab from "Components/NavBar/NavBarTab/NavBarTab";
import { useFetchGet, useFetchGetPagination } from "Services/api";
import axios from "axios";
import { useState } from "react";
import { connect } from "react-redux";
import Offre from "../Offre/Offre";
import { formatDate } from "Services/functions";
import Pagination from "Components/Pagination/Pagination";
import { updatePagination } from "Redux/Actions/paginationActions";
import { updateOffers } from "Redux/Actions/offersActions";
import Loader from "Components/Loader/loader";
import { useTranslation } from "react-i18next";

const DernieresOffres = (props) => {
  const LastOffreRef = useRef(null);
  const { t } = useTranslation("common");
  const listOfSavedSearch = useFetchGet(
    "/saved_offer_searches",
    props.auth.token
  );
  const [linkedOffers, setLinkedOffers] = useState([]);
  const [urlLastOffers, setUrlLastOffers] = useState(null);
  const offersQuery = useFetchGetPagination(urlLastOffers, props.auth.token);

  useEffect(() => {
    if (listOfSavedSearch.loaded && props.states.statesData.length > 0) {
      let tempArray = listOfSavedSearch.data.filter(
        (search) => search.user.split("/")[3] == props.auth.userConnected.id
      );
      if (tempArray.length > 0) {
        tempArray.forEach((search) => handleSearch(search));
      } else {
        let url = new URL(`${process.env.REACT_APP_BASE_URL_API}/offers`);
        url.searchParams.set("order[modifiedAt]", "desc");
        url.searchParams.set(
          "state",
          props.states.statesData.filter(
            (etat) => etat.statusLabel === "Publié"
          )[0].id
        );
        setUrlLastOffers(url);
      }
    }
  }, [listOfSavedSearch.loaded, props.states.statesData.length]);

  const handleSearch = (search) => {
    let url = new URL(`${process.env.REACT_APP_BASE_URL_API}/offers`);
    url.searchParams.set("order[modifiedAt]", "desc");
    url.searchParams.set(
      "state",
      props.states.statesData.filter((etat) => etat.statusLabel === "Publié")[0]
        .id
    );
    //Gestion des string city, minsalary, maxsalary et keyword
    if (search.city != "") {
      url.searchParams.set("city", search.city);
    }
    if (search.country != "") {
      url.searchParams.set("country", search.country);
    }

    if (search.minSalary > 0)
      url.searchParams.set("minSalary[gt]", search.minSalary);
    if (search.maxSalary > 0)
      url.searchParams.set("maxSalary[lt]", search.maxSalary);
    if (search.title != "") url.searchParams.set("keyword", search.title);

    //Gestion des Arrays of string pour Contract, Category, Education, Sector et Domain
    if (search.typeOfContract.length > 0) {
      search.typeOfContract.forEach((item) =>
        url.searchParams.append("typeOfContract[]", item.split("/")[3])
      );
    }
    if (search.offerCategory.length > 0) {
      search.offerCategory.forEach((item) =>
        url.searchParams.append("offerCategory[]", item.split("/")[3])
      );
    }
    if (search.sectorOfOffer.length > 0) {
      search.sectorOfOffer.forEach((item) =>
        url.searchParams.append("sector[]", item.split("/")[3])
      );
    }
    if (search.levelOfEducation.length > 0) {
      search.levelOfEducation.forEach((item) =>
        url.searchParams.append("levelOfEducations[]", item.split("/")[3])
      );
    }
    if (search.domain.length > 0) {
      search.domain.forEach((item) =>
        url.searchParams.append("domains[]", item.split("/")[3])
      );
    }
    getDataFiltered(url);
  };

  // récupération des données filtrées
  const getDataFiltered = (url) => {
    axios
      .get(url.toString(), {
        headers: props.auth.token
          ? {
              accept: "application/json",
              Authorization: `Bearer ${props.auth.token}`,
            }
          : {
              accept: "application/json",
            },
      })
      .then((response) => {
        if (response.data?.length > 0) {
          let tempArray = linkedOffers.map((x) => x);
          response.data.forEach((offer) => tempArray.push(offer));
          setLinkedOffers(tempArray);
        }
      })
      .catch((error) =>
        props.auth.toast?.current?.show({
          severity: "error",
          summary: "Erreur",
          detail: error?.response?.data?.detail || "Une erreur est survenue",
          life: 5000,
        })
      );
  };

  return (
    <div className="dernieres_offres" ref={LastOffreRef}>
      <ProfilBar id="mobile" />
      <NavBarTab
        id="desktop"
        select="profil"
        activeIndex={props.activeIndex}
        items={props.items}
      />
      {offersQuery.loaded || linkedOffers.length > 0 ? (
        linkedOffers.length > 0 ? (
          <div className="linked_offers_container">
            <div className="linked_offers_container__title">
              <h1 className="h1_trait_dessus">
                {"Offres liées à mes recherches"}
              </h1>
            </div>
            <div className="linked_offers_container__offers">
              {linkedOffers
                .slice(
                  props.pagination.paginationBasicFirst,
                  props.pagination.paginationBasicRows *
                    props.pagination.currentPage
                )
                .map((offre, index) => (
                  <Offre
                    categorie={index}
                    url={offre.imageUrl || offre?.nexusImageUrl || "img1"}
                    nexusUrl={offre?.nexusImageUrl}
                    offreDetails={offre}
                    date={formatDate(offre.postedAt, ".")}
                    accessibleForDisabled={offre.accessibleForDisabled}
                    key={offre.id}
                  />
                ))}
            </div>
            <Pagination data={linkedOffers} refItemParent={LastOffreRef} />
          </div>
        ) : (
          <div className="linked_offers_container">
            <div className="linked_offers_container__title">
              <h1 className="h1_trait_dessus">
                {t("profilItem.latestOffers")}
              </h1>
            </div>
            <div className="linked_offers_container__offers">
              {offersQuery?.data?.slice(0, 20).map((offre, index) => (
                <Offre
                  categorie={index}
                  url={offre.imageUrl || offre?.nexusImageUrl || "img1"}
                  nexusUrl={offre?.nexusImageUrl}
                  offreDetails={offre}
                  date={formatDate(offre.postedAt, ".")}
                  accessibleForDisabled={offre.accessibleForDisabled}
                  key={offre.id}
                />
              ))}
            </div>
          </div>
        )
      ) : (
        <Loader></Loader>
      )}
    </div>
  );
};

DernieresOffres.propTypes = {
  offers: PropTypes.any,
  items: PropTypes.array,
  activeIndex: PropTypes.number,
  auth: PropTypes.any,
  pagination: PropTypes.object,
  handleUpdatePagination: PropTypes.func,
  handleUpdateOffers: PropTypes.func,
  states: PropTypes.object,
};

const mapStateToProps = (state) => ({
  offers: state.offers,
  auth: state.auth,
  pagination: state.pagination,
  states: state.states,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdatePagination: (value) => {
    dispatch(updatePagination(value));
  },
  handleUpdateOffers: (value) => {
    dispatch(updateOffers(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DernieresOffres);
