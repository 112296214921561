import { updateSecondaryTables } from "Redux/Actions/secondaryTablesActions";
import { useFetchGet, useFetchGetConditional } from "Services/api";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";
import { connect } from "react-redux";
import "./AnnuaireEntrepriseSearchBar.scss";

const AnnuaireEntrepriseSearchBar = (props) => {
  const { t } = useTranslation("common");

  const [motsclés, setMotsclés] = useState("");
  const [country, setCountry] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [secteur, setSecteur] = useState([]);
  const [typeEntreprise, setTypeEntreprise] = useState([]);
  const [effectif, setEffectif] = useState([]);
  const [isSearched, setIsSearched] = useState(false);

  const secteurData = useFetchGetConditional(
    "/sector_of_offers",
    props.auth.token,
    props.secondaryTables.sectorOfOffersData
  );
  const typeEntrepriseData = useFetchGet("/company_types", props.auth.token);
  const effectifData = useFetchGet("/company_total_staffs", props.auth.token);

  useEffect(() => {
    secteurData.loaded &&
      props.handleUpdateSecondaryTables({
        sectorOfOffersData: secteurData.data,
      });
  }, [secteurData.loaded]);

  const handleFilter = (isSearch) => {
    let url =
      props.urlCompany ||
      new URL(`${process.env.REACT_APP_BASE_URL_API}/companies`);

    // Si false, on réinitialise les paramètres de recherche
    if (!isSearch) {
      const offerOrder = url.searchParams.get("order[numberOfOffersPublished]");
      const sectorOrder = url.searchParams.get("order[sector.label]");
      let params = new URLSearchParams();
      params.set("itemsPerPage", 20);
      if (offerOrder) params.set("order[numberOfOffersPublished]", offerOrder);
      if (sectorOrder) params.set("order[sector.label]", sectorOrder);
      url.search = params.toString();
      // Nouvel objet URL pour permettre le re-render du composant parent
      props.setUrlCompany(new URL(url.toString()));
      return;
    }

    if (motsclés != "") url.searchParams.set("keyword", motsclés);
    if (nom != "") url.searchParams.set("surname", nom);
    if (prenom != "") url.searchParams.set("firstname", prenom);
    if (country != "") url.searchParams.set("country", country);

    //Gestion des Arrays of string pour Contract, Category, Education, Sector et Domain
    if (secteur.length > 0) {
      secteur.forEach((item) => url.searchParams.append("sector[]", item.id));
    }
    if (typeEntreprise.length > 0) {
      typeEntreprise.forEach((item) =>
        url.searchParams.append("type[]", item.id)
      );
    }
    if (effectif.length > 0) {
      effectif.forEach((item) =>
        url.searchParams.append("totalStaff[]", item.id)
      );
    }
    props.setUrlCompany(new URL(url.toString()));
  };

  return (
    <div className="annuaire_search_bar etudiant_bar">
      <h2 className="annuaire_search_bar__title">Trouvez une entreprise</h2>
      <div className="annuaire_search_bar__container">
        <div className="annuaire_search_bar__container__top">
          <div className="annuaire_search_bar__container__top__boxes">
            <div className="annuaire_search_bar__container__top__boxes__box">
              <label className="label-annuaire">
                {t("companyYearbook.companyKeyword")}
              </label>
              <span className="annuaire_p-input-icon-left">
                <InputText
                  value={motsclés}
                  onChange={(e) => setMotsclés(e.target.value)}
                  placeholder=" Poste, Entreprise..."
                  className="box__input"
                />
              </span>
            </div>
            <Divider layout="vertical" />
            <div className="annuaire_search_bar__container__top__boxes__box">
              <label className="label-annuaire">
                {" "}
                {t("companyYearbook.companySector")}
              </label>
              <MultiSelect
                appendTo={document.getElementById("app")}
                value={secteur}
                options={secteurData.data?.sort((a, b) =>
                  a.label.localeCompare(b.label)
                )}
                onChange={(e) => setSecteur(e.value)}
                optionLabel="label"
                placeholder={t("companyYearbook.companySectorPlace")}
                maxSelectedLabels={3}
                selectedItemsLabel={`${secteur.length} options sélectionnées`}
                className="box__input blanc"
              />
            </div>
            <Divider layout="vertical" />
            <div className="annuaire_search_bar__container__top__boxes__box">
              <label className="label-annuaire">
                {" "}
                {t("companyYearbook.companyBusiness")}
              </label>
              <MultiSelect
                appendTo={document.getElementById("app")}
                value={typeEntreprise}
                options={typeEntrepriseData.data?.sort((a, b) =>
                  a.label.localeCompare(b.label)
                )}
                onChange={(e) => setTypeEntreprise(e.value)}
                optionLabel="label"
                placeholder={t("companyYearbook.companyBusinessPlace")}
                maxSelectedLabels={3}
                selectedItemsLabel={`${typeEntreprise.length} options sélectionnées`}
                className="box__input blanc"
              />
            </div>
            <Divider layout="vertical" />
            <div className="annuaire_search_bar__container__top__boxes__box">
              <label className="label-annuaire">
                {t("companyYearbook.companyWorkforce")}
              </label>
              <MultiSelect
                appendTo={document.getElementById("app")}
                value={effectif}
                options={effectifData.data}
                onChange={(e) => setEffectif(e.value)}
                optionLabel="label"
                placeholder={t("companyYearbook.companyWorkforcePlace")}
                maxSelectedLabels={1}
                selectedItemsLabel={`${effectif.length} options sélectionnées`}
                className="box__input blanc"
              />
            </div>
            <Divider layout="vertical" />
            <div className="annuaire_search_bar__container__top__boxes__box">
              <label className="label-annuaire">
                {t("companyYearbook.companyLocation")}
              </label>
              <InputText
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                placeholder={t("companyYearbook.companyLocationPlace")}
                className="box__input"
              />
            </div>
          </div>
          <div
            className="annuaire_search_bar__container__top__inputSearch not_mobile"
            onClick={() => {
              setIsSearched(true);
              handleFilter(true);
            }}
          >
            <FaSearch />
          </div>
        </div>
        <div className="annuaire_search_bar__container__bottom">
          <ul className="ul_entreprises">
            <li>
              <label className="label-annuaire">
                {t("companyYearbook.companyPrenom")}
              </label>
              <label className="label-min-prenom">
                {t("companyYearbook.companyPrenom")}
              </label>
              <InputText
                value={prenom}
                onChange={(e) => setPrenom(e.target.value)}
                placeholder="Alex, Emma, Julie"
                className="box__input"
              />
            </li>
            <li>
              <label className="label-annuaire">
                {t("companyYearbook.companyNom")}
              </label>
              <label className="label-min-nom">
                {t("companyYearbook.companyNom")}
              </label>
              <InputText
                value={nom}
                onChange={(e) => setNom(e.target.value)}
                placeholder="Lebon, Dupont"
                className="box__input"
              />
            </li>
            {isSearched && (
              <li className="search_bar_refresh search_bar_refresh__annuaireEntreprise">
                <i
                  className="pi pi-refresh"
                  style={{ fontSize: "1.5rem", fontWeight: "600" }}
                  onClick={() => {
                    setIsSearched(false);
                    setMotsclés("");
                    setNom("");
                    setPrenom("");
                    setCountry("");
                    setSecteur([]);
                    setTypeEntreprise([]);
                    setEffectif([]);
                    handleFilter(false);
                  }}
                />
              </li>
            )}
          </ul>
        </div>
        <div
          className="annuaire_search_bar__container__top__inputSearch not_desktop"
          onClick={() => {
            setIsSearched(true);
            handleFilter(true);
          }}
        >
          <FaSearch />
        </div>
      </div>
    </div>
  );
};

AnnuaireEntrepriseSearchBar.propTypes = {
  auth: PropTypes.object,
  setUrlCompany: PropTypes.func,
  urlCompany: PropTypes.objectOf(URL),
  secondaryTables: PropTypes.object,
  handleUpdateSecondaryTables: PropTypes.func,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  secondaryTables: state.secondaryTables,
});
const mapDispatchToProps = (dispatch) => ({
  handleUpdateSecondaryTables: (value) => {
    dispatch(updateSecondaryTables(value));
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnuaireEntrepriseSearchBar);
