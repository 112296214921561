import React from "react";
import { TabMenu } from "primereact/tabmenu";
import PropTypes from "prop-types";
// import { useState } from "react";
// import Footer from "../../../Footer/Footer";
import { connect } from "react-redux";
import { updateMenu } from "Redux/Actions/menuActions";
import { ScrollPanel } from "primereact/scrollpanel";
import "./NavBarTab.scss";
import { useTranslation } from "react-i18next";

const NavBarTab = (props) => {
  const { t } = useTranslation("common");

  return (
    <div className="styleNavBarActive" id={props.id}>
      <div className="navBarProfil">
        {props.select == "annuaire" && (
          <ScrollPanel className="navBarProfil__scrollMenu">
            <TabMenu
              className="navBarProfil__tabMenu"
              model={props.items.itemsAnnuaire.map((annuaireItem) => {
                let label = "";
                switch (annuaireItem.label) {
                  case "Annuaire Alumni":
                    label = t("academicYearbook.alumniTitle");
                    break;
                  case "Annuaire entreprises":
                    label = t("companyYearbook.companyTitle");
                    break;
                  default:
                    label = annuaireItem.label;
                }
                return {
                  ...annuaireItem,
                  label,
                };
              })}
              activeIndex={props.items.activeItemAnnuaire}
              onTabChange={(e) =>
                props.handleMenu({
                  activeItemAnnuaire: props.items.itemsAnnuaire.findIndex(
                    (v) => {
                      switch (e.value.label) {
                        case t("academicYearbook.alumniTitle"):
                          return v.label == "Annuaire Alumni";
                        case t("companyYearbook.companyTitle"):
                          return v.label == "Annuaire entreprises";
                        default:
                          return v.label == e.value.label;
                      }
                    }
                  ),
                })
              }
            />
          </ScrollPanel>
        )}
        {props.select == "profiledit" && (
          <ScrollPanel className="navBarProfil__scrollMenu">
            <TabMenu
              className="navBarProfil__tabMenu"
              model={props.items.itemsProfilEdit.map((profilItem) => {
                let label = "";
                switch (profilItem.label) {
                  case "Informations personnelles":
                    label = t("profilEdition.personalInfo.title");
                    break;
                  case "Formations et diplômes":
                    label = t("profilEdition.studiesDegrees.title");
                    break;
                  case "Expériences professionnelles":
                    label = t("profilEdition.professionalExperiences.title");
                    break;
                  case "Compétences":
                    label = t("profilEdition.skills.title");
                    break;
                  case "Paramètres de confidentialité":
                    label = t("profilEdition.privacySettings.title");
                    break;
                  default:
                    label = profilItem.label;
                }
                return {
                  ...profilItem,
                  label,
                };
              })}
              activeIndex={props.items.activeItemProfilEdit}
              onTabChange={(e) => {
                props.handleMenu({
                  activeItemProfilEdit: props.items.itemsProfilEdit.findIndex(
                    (v) => {
                      switch (e.value.label) {
                        case t("profilEdition.personalInfo.title"):
                          return v.label == "Informations personnelles";
                        case t("profilEdition.studiesDegrees.title"):
                          return v.label == "Formations et diplômes";
                        case t("profilEdition.professionalExperiences.title"):
                          return v.label == "Expériences professionnelles";
                        case t("profilEdition.skills.title"):
                          return v.label == "Compétences";
                        case t("profilEdition.privacySettings.title"):
                          return v.label == "Paramètres de confidentialité";
                        default:
                          return v.label == e.value.label;
                      }
                    }
                  ),
                });
              }}
            />
          </ScrollPanel>
        )}
        {props.select == "profil" && (
          <ScrollPanel className="navBarProfil__scrollMenu">
            <TabMenu
              className="navBarProfil__tabMenu"
              model={props.items.itemsProfil.map((profilItem) => {
                let label = "";
                switch (profilItem.label) {
                  case "Dernières offres d'emploi":
                    label = t("profilItem.latestOffers");
                    break;
                  case "Offres sauvegardées":
                    label = t("profilItem.savedOffers");
                    break;
                  case "Candidatures envoyées":
                    label = t("profilItem.sentApplications");
                    break;
                  case "Mes événements à venir":
                    label = t("profilItem.upcomingEvents");
                    break;
                  default:
                    label = profilItem.label;
                }
                return {
                  ...profilItem,
                  label,
                };
              })}
              activeIndex={props.items.activeItemProfil}
              onTabChange={(e) =>
                props.handleMenu({
                  activeItemProfil: props.items.itemsProfil.findIndex((v) => {
                    switch (e.value.label) {
                      case t("profilItem.latestOffers"):
                        return v.label == "Dernières offres d'emploi";
                      case t("profilItem.savedOffers"):
                        return v.label == "Offres sauvegardées";
                      case t("profilItem.sentApplications"):
                        return v.label == "Candidatures envoyées";
                      case t("profilItem.upcomingEvents"):
                        return v.label == "Mes événements à venir";
                      default:
                        return v.label == e.value.label;
                    }
                  }),
                })
              }
            />
          </ScrollPanel>
        )}
        {props.select == "cvtheque" && (
          <ScrollPanel className="navBarProfil__scrollMenu">
            <TabMenu
              className="navBarProfil__tabMenu"
              model={props.items.itemsCVTheque}
              activeIndex={props.items.activeItemCVTheque}
              onTabChange={(e) =>
                props.handleMenu({
                  activeItemCVTheque: props.items.itemsCVTheque.findIndex(
                    (v) => v.label == e.value.label
                  ),
                })
              }
            />
          </ScrollPanel>
        )}
        {props.select == "depotoffre" && (
          <ScrollPanel className="navBarProfil__scrollMenu">
            <TabMenu
              className="navBarProfil__tabMenu"
              model={props.items.itemsDepotOffre}
              activeIndex={props.items.activeItemDepotOffre}
              onTabChange={(e) =>
                props.handleMenu({
                  activeItemDepotOffre: props.items.itemsDepotOffre.findIndex(
                    (v) => v.label == e.value.label
                  ),
                })
              }
            />
          </ScrollPanel>
        )}
        {props.select == "groupes" && (
          <ScrollPanel className="navBarProfil__scrollMenu">
            <TabMenu
              className="navBarProfil__tabMenu"
              model={props.items.itemsGroupes.map((groupeItem) => {
                let label = "";
                switch (groupeItem.label) {
                  case "Mes groupes d'échanges":
                    label = t("footerApresConnexion.li_groupes_echanges");
                    break;
                  case "Trouver un groupe":
                    label = t("boutonsAccueilApresConnexion.trouverGroupe");
                    break;
                  case "Événements de groupe":
                    label = t("groupManagement.groupEvents");
                    break;
                  case "Proposer et gérer mes groupes":
                    label = t("boutonsAccueilApresConnexion.handleGroups");
                    break;
                  default:
                    label = groupeItem.label;
                }
                return {
                  ...groupeItem,
                  label,
                };
              })}
              activeIndex={props.items.activeItemGroupes}
              onTabChange={(e) =>
                props.handleMenu({
                  activeItemGroupes: props.items.itemsGroupes.findIndex((v) => {
                    switch (e.value.label) {
                      case t("footerApresConnexion.li_groupes_echanges"):
                        return v.label == "Mes groupes d'échanges";
                      case t("boutonsAccueilApresConnexion.trouverGroupe"):
                        return v.label == "Trouver un groupe";
                      case t("groupManagement.groupEvents"):
                        return v.label == "Événements de groupe";
                      case t("boutonsAccueilApresConnexion.handleGroups"):
                        return v.label == "Proposer et gérer mes groupes";
                      default:
                        return v.label == e.value.label;
                    }
                  }),
                })
              }
            />
          </ScrollPanel>
        )}
        {props.select == "groupesadmin" && (
          <ScrollPanel className="navBarProfil__scrollMenu">
            <TabMenu
              className="navBarProfil__tabMenu"
              model={props.items.itemsGroupesAdmin.map((groupeItem) => {
                let label = "";
                switch (groupeItem.label) {
                  case "Mes groupes d'échanges":
                    label = t("footerApresConnexion.li_groupes_echanges");
                    break;
                  case "Trouver un groupe":
                    label = t("boutonsAccueilApresConnexion.trouverGroupe");
                    break;
                  case "Événements de groupe":
                    label = t("groupManagement.groupEvents");
                    break;
                  case "Créer et gérer mes groupes":
                    label = t("boutonsAccueilApresConnexion.handleGroupsAdmin");
                    break;
                  default:
                    label = groupeItem.label;
                }
                return {
                  ...groupeItem,
                  label,
                };
              })}
              activeIndex={props.items.activeItemGroupesAdmin}
              onTabChange={(e) =>
                props.handleMenu({
                  activeItemGroupesAdmin:
                    props.items.itemsGroupesAdmin.findIndex((v) => {
                      switch (e.value.label) {
                        case t("footerApresConnexion.li_groupes_echanges"):
                          return v.label == "Mes groupes d'échanges";
                        case t("boutonsAccueilApresConnexion.trouverGroupe"):
                          return v.label == "Trouver un groupe";
                        case t("groupManagement.groupEvents"):
                          return v.label == "Événements de groupe";
                        case t(
                          "boutonsAccueilApresConnexion.handleGroupsAdmin"
                        ):
                          return v.label == "Créer et gérer mes groupes";
                        default:
                          return v.label == e.value.label;
                      }
                    }),
                })
              }
            />
          </ScrollPanel>
        )}
        {props.select == "offre" && (
          <ScrollPanel className="navBarProfil__scrollMenu">
            <TabMenu
              className="navBarProfil__tabMenu"
              model={props.items.itemsOffre.map((profilItem) => {
                let label = "";
                switch (profilItem.label) {
                  case "Trouver une offre":
                    label = t("offerItem.findOffer");
                    break;
                  case "Déposer une offre":
                    label = t("offerItem.createOffer");
                    break;
                  case "Gérer mes dépôts d'offres":
                    label = t("offerItem.handleOffer");
                    break;
                  default:
                    label = profilItem.label;
                }
                return {
                  ...profilItem,
                  label,
                };
              })}
              activeIndex={props.items.activeItemOffre}
              onTabChange={(e) =>
                props.handleMenu({
                  activeItemOffre: props.items.itemsOffre.findIndex((v) => {
                    switch (e.value.label) {
                      case t("offerItem.findOffer"):
                        return v.label == "Trouver une offre";
                      case t("offerItem.createOffer"):
                        return v.label == "Déposer une offre";
                      case t("offerItem.handleOffer"):
                        return v.label == "Gérer mes dépôts d'offres";
                      default:
                        return v.label == e.value.label;
                    }
                  }),
                })
              }
            />
          </ScrollPanel>
        )}
        {props.select == "evenement" && (
          <ScrollPanel className="navBarProfil__scrollMenu">
            <TabMenu
              className="navBarProfil__tabMenu"
              model={props.items.itemsEvenement.map((profilItem) => {
                let label = "";
                switch (profilItem.label) {
                  case "Tous les événements":
                    label = t("evenementsHorsConnexion.title_page_evenements");
                    break;
                  case "Proposer un événement":
                    label = t("eventApresConnexion.submenuProposerAlumni");
                    break;
                  case "Gérer mes événements":
                    label = t("eventApresConnexion.submenuGererAlumni");
                    break;
                  default:
                    label = profilItem.label;
                }
                return {
                  ...profilItem,
                  label,
                };
              })}
              activeIndex={props.items.activeItemEvenement}
              onTabChange={(e) =>
                props.handleMenu({
                  activeItemEvenement: props.items.itemsEvenement.findIndex(
                    (v) => {
                      switch (e.value.label) {
                        case t("evenementsHorsConnexion.title_page_evenements"):
                          return v.label == "Tous les événements";
                        case t("eventApresConnexion.submenuProposerAlumni"):
                          return v.label == "Proposer un événement";
                        case t("eventApresConnexion.submenuGererAlumni"):
                          return v.label == "Gérer mes événements";
                        default:
                          return v.label == e.value.label;
                      }
                    }
                  ),
                })
              }
            />
          </ScrollPanel>
        )}
        {props.select == "evenementadmin" && (
          <ScrollPanel className="navBarProfil__scrollMenu">
            <TabMenu
              className="navBarProfil__tabMenu"
              model={props.items.itemsEvenementAdmin.map((profilItem) => {
                let label = "";
                switch (profilItem.label) {
                  case "Tous les événements":
                    label = t("evenementsHorsConnexion.title_page_evenements");
                    break;
                  case "Créer un événement":
                    label = t("eventApresConnexion.submenuCreateAdmin");
                    break;
                  case "Gérer les événements":
                    label = t("eventApresConnexion.submenuGererAdmin");
                    break;
                  default:
                    label = profilItem.label;
                }
                return {
                  ...profilItem,
                  label,
                };
              })}
              activeIndex={props.items.activeItemEvenementAdmin}
              onTabChange={(e) =>
                props.handleMenu({
                  activeItemEvenementAdmin:
                    props.items.itemsEvenementAdmin.findIndex((v) => {
                      switch (e.value.label) {
                        case t("evenementsHorsConnexion.title_page_evenements"):
                          return v.label == "Tous les événements";
                        case t("eventApresConnexion.submenuCreateAdmin"):
                          return v.label == "Créer un événement";
                        case t("eventApresConnexion.submenuGererAdmin"):
                          return v.label == "Gérer les événements";
                        default:
                          return v.label == e.value.label;
                      }
                    }),
                })
              }
            />
          </ScrollPanel>
        )}
        {props.select == "actu" && (
          <ScrollPanel className="navBarProfil__scrollMenu">
            <TabMenu
              className="navBarProfil__tabMenu"
              model={props.items.itemsActu.map((profilItem) => {
                let label = "";
                switch (profilItem.label) {
                  case "Toutes les actus":
                    label = t("actusApresConnexion.submenuAll");
                    break;
                  case "Proposer une actu":
                    label = t("actusApresConnexion.submenuCreateAlumni");
                    break;
                  case "Gérer mes actus":
                    label = t("actusApresConnexion.submenuGererAlumni");
                    break;
                  default:
                    label = profilItem.label;
                }
                return {
                  ...profilItem,
                  label,
                };
              })}
              activeIndex={props.items.activeItemActu}
              onTabChange={(e) =>
                props.handleMenu({
                  activeItemActu: props.items.itemsActu.findIndex((v) => {
                    switch (e.value.label) {
                      case t("actusApresConnexion.submenuAll"):
                        return v.label == "Toutes les actus";
                      case t("actusApresConnexion.submenuCreateAlumni"):
                        return v.label == "Proposer une actu";
                      case t("actusApresConnexion.submenuGererAlumni"):
                        return v.label == "Gérer mes actus";
                      default:
                        return v.label == e.value.label;
                    }
                  }),
                })
              }
            />
          </ScrollPanel>
        )}
        {props.select == "actuadmin" && (
          <ScrollPanel className="navBarProfil__scrollMenu">
            <TabMenu
              className="navBarProfil__tabMenu"
              model={props.items.itemsActuAdmin.map((profilItem) => {
                let label = "";
                switch (profilItem.label) {
                  case "Toutes les actus/conseils":
                    label = t("actusApresConnexion.submenuAllAdmin");
                    break;
                  case "Créer une actu/conseil":
                    label = t("actusApresConnexion.submenuCreateAdmin");
                    break;
                  case "Gérer les actus/conseils":
                    label = t("actusApresConnexion.submenuGererAdmin");
                    break;
                  default:
                    label = profilItem.label;
                }
                return {
                  ...profilItem,
                  label,
                };
              })}
              activeIndex={props.items.activeItemActuAdmin}
              onTabChange={(e) =>
                props.handleMenu({
                  activeItemActuAdmin: props.items.itemsActuAdmin.findIndex(
                    (v) => v.label == e.value.label
                  ),
                })
              }
            />
          </ScrollPanel>
        )}
        {props.select == "parametrage" && (
          <ScrollPanel className="navBarProfil__scrollMenu">
            <TabMenu
              className="navBarProfil__tabMenu"
              model={props.items.itemsParametrage}
              activeIndex={props.items.activeItemParametrage}
              onTabChange={(e) =>
                props.handleMenu({
                  activeItemParametrage: props.items.itemsParametrage.findIndex(
                    (v) => v.label == e.value.label
                  ),
                })
              }
            />
          </ScrollPanel>
        )}
      </div>
    </div>
  );
};

NavBarTab.propTypes = {
  select: PropTypes.string,
  items: PropTypes.object,
  activeIndex: PropTypes.number,
  setActiveIndex: PropTypes.func,
  handleAuth: PropTypes.func,
  handleMenu: PropTypes.func,
  id: PropTypes.string,
};

const mapStateToProps = (state) => ({
  items: state.items,
});

const mapDispatchToProps = (dispatch) => ({
  handleMenu: (value) => {
    dispatch(updateMenu(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBarTab);
