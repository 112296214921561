import React from "react";
import "./Localisation.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";

/**
 * Permet d'afficher la page d'annuaire
 */
// TO DO : gestion éditoriale : intégrer la visibilité de l'annuaire, la modification de titre et la visibilité des différents annuaires
const Localisation = (props) => {
  let listOfCity = [];
  let listOfCountry = [];

  if (props.listOfData.countries.length > 0) {
    props.listOfData.countries.forEach(
      (element) =>
        element !== "" &&
        element.toLowerCase().includes(props.location) &&
        listOfCountry.push(element)
    );
  }
  if (props.listOfData.cities.length > 0) {
    props.listOfData.cities.forEach(
      (element) =>
        element !== "" &&
        element.toLowerCase().includes(props.location) &&
        listOfCity.push(element)
    );
  }
  return (
    <div className="listOfLocation">
      {listOfCity.length === 0 && listOfCountry.length === 0 && (
        <span className="message_error">
          Aucune offre ne correspond à ce lieu
        </span>
      )}
      {listOfCity.length > 0 && (
        <div className="listOfCity group">
          <span>Villes</span>
          {listOfCity.map((element, key) => (
            <div
              className="city location"
              key={key}
              onClick={() => {
                props.setIsVisibleLocation(false);
                props.btnAction(element);
              }}
            >
              {element}
            </div>
          ))}
        </div>
      )}

      {listOfCountry.length > 0 && (
        <div className="listOfCountry group">
          <span>Pays</span>
          {listOfCountry.map((element, key) => (
            <div
              className="country location"
              key={key}
              onClick={() => {
                props.setIsVisibleLocation(false);
                props.btnAction(element);
              }}
            >
              {element}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

Localisation.propTypes = {
  auth: PropTypes.object,
  location: PropTypes.string,
  listOfData: PropTypes.array,
  setIsVisibleLocation: PropTypes.func,
  btnAction: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Localisation);
