import React, { useEffect, useRef, useState } from "react";

/* eslint-disable no-unused-vars */
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import Header from "Components/Header/Header";
import "./GroupeCardEventDetails.scss";
import event1 from "assets/Event1.webp";
import Footer from "Components/Footer/Footer";
import { Link, useParams } from "react-router-dom";
import Loader from "Components/Loader/loader";
import {
  formatDate,
  formatDateFrenchLocale,
  formatDateHourFrenchLocale,
  formatHourFrenchLocale,
  isNotEmptyArray,
} from "Services/functions";
import { Menu } from "primereact/menu";
import { fetchGet, useFetchGet } from "Services/api";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateEvents } from "Redux/Actions/eventsActions";
import EventActuConseilRandom from "Components/EventActuConseilRandom/EventActuConseilRandom";
import axios from "axios";
import { Toast } from "primereact/toast";
import ModalQuestion from "Components/Evenements/EvenementDetail/ModalQuestion";
import { updateGroups } from "Redux/Actions/groupsActions";
import { DateTime } from "luxon";
import EvenementCard from "Components/Evenements/EvenementCard/EvenementCard";
import { updateUniversities } from "Redux/Actions/universitiesActions";
import parse from "html-react-parser";
import { useMemo } from "react";
import { HTMLParseOptions } from "Services/htmlParseOptions";
import { useTranslation } from "react-i18next";

const GroupeCardEventDetails = (props) => {
  const [loaderIsVisible, setLoaderIsVisible] = useState(false);
  // récupération des événements
  let { id } = useParams();
  const groupEventQuery = useFetchGet(`/event_groups/${id}`, props.auth.token);
  const [groupEventDetail, setGroupEventDetail] = useState(null);

  const [groupEventParticipants, setGroupEventParticipants] = useState(null);
  const [updateParticipants, setUpdateParticipants] = useState(true);

  const groupEvents = useFetchGet("/event_groups", props.auth.token);

  const [loaderRegister, setLoaderRegister] = useState(false);
  const groupEventDetailRef = useRef(null);
  let groupEventsRegistered = {
    data: null,
    loaded: false,
  };
  const { t } = useTranslation("common");

  const [registeredGroupsEvents, setRegisteredGroupsEvents] = useState(null);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [groupSuggestions, setGroupSuggestions] = useState();
  const [myGroups, setMyGroups] = useState(undefined);

  useEffect(() => {
    groupEventsRegistered;
    if (groupEventQuery.loaded) {
      setGroupEventDetail(groupEventQuery.data);
    }
  }, [groupEventQuery.loaded]);

  useEffect(() => {
    setLoaderIsVisible(true);
    if (groupEventDetailRef.current !== null) {
      window.scroll({
        top: groupEventDetailRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  }, [loaderRegister]);

  // récupération et affichage des participants à un événement
  const menu = useRef(null);

  const participants = useFetchGet(
    `/event_groups/participant_list/${id}`,
    props.auth.token
  );
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (
      participants.loaded &&
      participants.data &&
      participants.data.length > 0
    ) {
      const newArray = [];
      for (const person of participants.data) {
        newArray.push({ label: person.name });
      }
      props.handleUpdateGroups({ participants: items });
    } else {
      setItems([{ label: "Aucun particpant inscrit" }]);
      props.handleUpdateGroups({ participants: items });
    }
  }, [participants.loaded]);

  useEffect(async () => {
    if (groupEventDetail && updateParticipants) {
      setGroupEventParticipants(
        await fetchGet(`/event_group_participants`, props.auth.token, {
          eventGroup: groupEventDetail.id,
        })
      );
      setUpdateParticipants(false);
    }
  }, [groupEventDetail, updateParticipants]);

  useEffect(async () => {
    setRegisteredGroupsEvents(
      await fetchGet(
        `/user_event_groups/${props.auth.userConnected.id}`,
        props.auth.token
      )
    );
  }, []);

  const findIdOfEvent = (idEvent) => {
    const eventParticipantsArray = [...groupEventParticipants];

    const eventParticipantsArrayWithStringSplited = [];
    for (let i = 0; i < eventParticipantsArray.length; i++) {
      if (typeof eventParticipantsArray[i].eventGroup != "number") {
        eventParticipantsArray[i].eventGroup = parseInt(
          eventParticipantsArray[i].eventGroup.split("/")[
            eventParticipantsArray[i].eventGroup.split("/").length - 1
          ]
        );
      }
      eventParticipantsArrayWithStringSplited.push(eventParticipantsArray[i]);
    }
    return eventParticipantsArrayWithStringSplited.find(
      (eventItem) => eventItem.eventGroup == idEvent
    );
  };
  const registerParticipant = (idEvent) => {
    setLoaderRegister((prec) => !prec);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL_API}/event_group_participants/registration`,
        {
          eventGroup: `/api/event_groups/${idEvent}`,
        },
        {
          headers: props.auth.token
            ? {
                accept: "application/json",
                "Content-Type": "application/json",

                Authorization: `Bearer ${props.auth.token}`,
              }
            : {
                accept: "application/json",
                "Content-Type": "application/json",
              },
        }
      )
      .then(
        (res) =>
          res.status === 201 &&
          (getUserEvents(),
          setUpdateParticipants(true),
          setLoaderRegister((prec) => !prec))
      )
      .catch((error) => console.log(error));
  };

  const unregisterParticipant = (idEvent) => {
    setLoaderRegister((prec) => !prec);
    const idEventParticipant = groupEventParticipants.find(
      (participant) => participant.user.id == props.auth.userConnected.id
    );
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL_API}/event_group_participants/${idEventParticipant?.id}`,
        {
          headers: props.auth.token
            ? {
                accept: "application/json",
                "Content-Type": "application/json",

                Authorization: `Bearer ${props.auth.token}`,
              }
            : {
                accept: "application/json",
                "Content-Type": "application/json",
              },
        }
      )
      .then(
        (res) =>
          res.status == 204 &&
          (getUserEvents(),
          setUpdateParticipants(true),
          setLoaderRegister((prec) => !prec))
      )
      .catch((error) => console.log(error));
  };

  const getUserEvents = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL_API}/user_event_groups/${props.auth.userConnected.id}`,
        {
          headers: props.auth.token
            ? {
                accept: "application/json",
                "Content-Type": "application/json",

                Authorization: `Bearer ${props.auth.token}`,
              }
            : {
                accept: "application/json",
                "Content-Type": "application/json",
              },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          setRegisteredGroupsEvents(res.data);
          setUpdateParticipants(true);
        }
      })
      .catch((error) => console.log(error));
  };
  const successUpdate = useRef(null);

  const showSuccess = () => {
    successUpdate.current.show({
      severity: "success",
      summary: "Question envoyée",
      detail: "Votre question a bien était posée",
      life: 3000,
    });
  };

  // Récupère les groupes de l'utilisateur
  useMemo(async () => {
    let url = props.auth.isAdmin
      ? "/groups"
      : "/groups/member/" + props.auth.userConnected.id;
    let userGroups = await fetchGet(url, props.auth.token, {
      "state[]": props.states.statesData.find(
        (state) => state.statusLabel == "Actif"
      ).id,
    });
    setMyGroups(userGroups["hydra:member"] || []);
  }, [props.auth.userConnected]);

  const toBeComingFilter = (listEvents, currentDate) => {
    /// Filtre les évènements qui n'ont pas commencé selon la date actuelle
    /// oui qui commence aujourd'hui
    return (
      listEvents
        .filter((event) => {
          return (
            // On compare la date de l'événement en fonction de la date actuelle
            DateTime.fromISO(event.startingAt) >=
              DateTime.fromISO(currentDate) ||
            DateTime.fromISO(event.endingAt) >= DateTime.fromISO(currentDate)
          );
        })
        // Sort par date de début pour voir ceuix qui arrivent bientôt
        .sort((a, b) => {
          return new Date(a.startingAt) - new Date(b.startingAt);
        })
    );
  };

  useEffect(() => {
    setLoaderIsVisible(true);
    if (groupEventDetailRef.current !== null) {
      window.scroll({
        top: groupEventDetailRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  }, [id]);

  useEffect(() => {
    // S la liste des groupes de l'user et des évènements de groupes ont été chargées
    if (groupEvents.loaded && myGroups && isNotEmptyArray(myGroups) && id) {
      // On récupère une suggestion d'évènements de groupes
      setGroupSuggestions([]);
    }
  }, [groupEvents.loaded, myGroups, id]);

  const downloadParticipantList = () => {
    let url = `${process.env.REACT_APP_BASE_URL_API}/event_groups/download_participant_list/${id}/csv`;
    axios
      .get(url, {
        Authorization: `Bearer ${props.auth.token}`,
        accept: "application/json",
        responseType: "blob",
      })
      .then((res) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(res.data);
        link.setAttribute("download", `Participant_List`);
        link.click();
      })
      // Si il y a une erreur, on l'affiche dans la console
      .catch((error) => {
        console.log(error.message);
      });
  };
  return (
    <div className="evenement_detail_container" ref={groupEventDetailRef}>
      <Header />
      <Toast ref={successUpdate} />
      <h1 className="evenement_detail_container__title h1_trait_dessus">
        {t("groupManagement.eventDetail")}
      </h1>
      {groupEventDetail ? (
        <div className="evenement_detail_container__top" key={loaderRegister}>
          <div className="evenement_detail_container__top__body">
            {groupEventDetail.imageUrl ? (
              <div
                className="evenement_detail_container__top__body__img"
                style={{
                  backgroundImage: `url(${groupEventDetail.imageUrl})`,
                }}
              ></div>
            ) : (
              <div
                className="evenement_detail_container__top__body__img"
                style={{ backgroundImage: `url(${event1})` }}
              ></div>
            )}

            <div className="evenement_detail_container__top__body__texte">
              <h1>{groupEventDetail.title}</h1>
              <h5 className="evenement_detail_container__top__body__texte__date">
                {groupEventDetail.endingAt ? (
                  <>
                    {(props.i18n.language === "en" ? "From " : "Du ") +
                      formatDate(groupEventDetail.startingAt)}
                    {groupEventDetail.startingHour &&
                      (props.i18n.language === "en" ? " at " : " au ") +
                        formatHourFrenchLocale(
                          groupEventDetail.startingHour
                        )}{" "}
                    {(props.i18n.language === "en" ? "to " : "au ") +
                      formatDate(groupEventDetail.endingAt)}
                    {groupEventDetail.endingHour &&
                      (props.i18n.language === "en" ? " at " : " au ") +
                        formatHourFrenchLocale(groupEventDetail.endingHour)}
                  </>
                ) : (
                  <>
                    {(props.i18n.language === "en" ? "On the " : "Le ") +
                      formatDateHourFrenchLocale(groupEventDetail.startingAt)}
                  </>
                )}
              </h5>
              <h5 className="evenement_detail_container__top__body__texte__categorie">
                {t("groupManagement.createdBy")}{" "}
                {`${groupEventDetail.createdBy?.surname} ${groupEventDetail.createdBy?.firstname}`}
              </h5>
              <h5 className="evenement_detail_container__top__body__texte__categorie">
                {t("createGroup.category")} :{" "}
                {groupEventDetail?.category?.label}
              </h5>
              <h5 className="evenement_detail_container__top__body__texte__categorie">
                {t("offresApresConnexionPage.searchBarBottomLieu")} :{" "}
                {groupEventDetail?.location}{" "}
              </h5>
              {groupEventDetail.link && (
                <h5 className="evenement_detail_container__top__body__texte__lien">
                  {t("createGroup.link")}{" "}
                  <span
                    onClick={() =>
                      // Ouverture du lien dans un nouvel onglet
                      window.open(
                        groupEventDetail.link.includes("http")
                          ? groupEventDetail.link
                          : `http://${groupEventDetail.link.replace(" ", "")}`,
                        "_blank"
                      )
                    }
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {groupEventDetail.link}
                  </span>
                </h5>
              )}

              {groupEventDetail.register && (
                <>
                  <div
                    className="evenement_detail_container__top__body__texte__inscriptionStatus"
                    style={{
                      color:
                        groupEventDetail.register &&
                        groupEventParticipants?.length >=
                          groupEventDetail.maxNumberOfParticipants
                          ? "#e24c4c"
                          : "#5eb6a1",
                    }}
                  >
                    {groupEventDetail.register &&
                    groupEventParticipants?.length >=
                      groupEventDetail.maxNumberOfParticipants
                      ? t("evenements.closedInscriptions")
                      : t("evenements.openInscriptions")}
                  </div>
                </>
              )}

              {groupEventParticipants && groupEventParticipants.length > 0 && (
                <h5
                  className="evenement_detail_container__top__body__texte__inscription"
                  onClick={() => {
                    downloadParticipantList();
                  }}
                >
                  {groupEventParticipants.length}{" "}
                  {groupEventParticipants.length > 1
                    ? "participants"
                    : "participant"}
                  <Menu
                    appendTo={document.getElementById("app")}
                    model={groupEventParticipants}
                    popup
                    ref={menu}
                    style={{ textAlign: "center" }}
                  />
                </h5>
              )}

              <p className="evenement_detail_container__top__body__texte__description">
                {parse(groupEventDetail.description, HTMLParseOptions)}
              </p>
              <div className="evenement_detail_container__top__body__btns">
                {props.auth.isConnected &&
                  (loaderRegister ? (
                    <Loader />
                  ) : groupEventParticipants?.length >=
                    groupEventDetail.maxNumberOfParticipants ? (
                    groupEventParticipants?.some(
                      (participant) =>
                        participant.user.id == props.auth.userConnected.id
                    ) && (
                      <BtnBleu
                        key={groupEventParticipants}
                        btnAction={() =>
                          groupEventParticipants?.some(
                            (participant) =>
                              participant.user.id == props.auth.userConnected.id
                          )
                            ? unregisterParticipant(groupEventDetail.id)
                            : registerParticipant(groupEventDetail.id)
                        }
                        btnTexte={t("evenements.unregister")}
                      />
                    )
                  ) : (
                    <BtnBleu
                      btnAction={() =>
                        groupEventParticipants?.some(
                          (participant) =>
                            participant.user.id == props.auth.userConnected.id
                        )
                          ? unregisterParticipant(groupEventDetail.id)
                          : registerParticipant(groupEventDetail.id)
                      }
                      btnTexte={
                        groupEventParticipants?.some(
                          (participant) =>
                            participant.user.id == props.auth.userConnected.id
                        )
                          ? t("evenements.unregister")
                          : t("evenements.register")
                      }
                    />
                  ))}
              </div>
              {modalIsVisible && (
                <ModalQuestion
                  modalIsVisible={modalIsVisible}
                  setModalIsVisible={setModalIsVisible}
                  idEvent={findIdOfEvent(groupEventDetail.id)}
                  showSuccess={() => showSuccess()}
                />
              )}
              {/* {props.events.myEvents.some((event) => event.id == id) && (
                <BtnBleu
                  btnAction={() => setModalIsVisible((prec) => !prec)}
                  btnTexte={"Poser une question à l'intervenant"}
                />
              )} */}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}

      <div className="evenement_detail_container__bottom">
        <div className="evenement_detail_container__bottom__title">
          <h1>{t("evenements.evenementsSimilaires")}</h1>
        </div>
        <EventActuConseilRandom event={groupEventDetail} />
        <div className="evenement_detail_container__bottom__event"></div>
      </div>
      <Footer />
    </div>
  );
};

GroupeCardEventDetails.propTypes = {
  handleUpdateGroups: PropTypes.func,
  handleUpdateUniversities: PropTypes.func,
  groups: PropTypes.object,
  auth: PropTypes.object,
  states: PropTypes.object,
  universities: PropTypes.object,
  i18n: PropTypes.object,
};

const mapStateToProps = (state) => ({
  groups: state.groups,
  universities: state.universities,
  auth: state.auth,
  states: state.states,
  i18n: state.i18n,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateGroups: (value) => {
    dispatch(updateGroups(value));
  },
  handleUpdateUniversities: (value) => {
    dispatch(updateUniversities(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupeCardEventDetails);
