import React, { useRef, useEffect, useState } from "react";
import "./CandidaturesEnvoyees.scss";
import PropTypes from "prop-types";
import NavBarTab from "Components/NavBar/NavBarTab/NavBarTab";
import ProfilBar from "Components/ProfilBar/ProfilBar";
import { useFetchGetPagination } from "Services/api";
import { connect } from "react-redux";
import TempPagination from "Components/Pagination/TempPagination";
import Loader from "Components/Loader/loader";
import { formatDate } from "Services/functions";
import Offre from "Components/Offres/Offre/Offre";
import { useTranslation } from "react-i18next";

const CandidaturesEnvoyees = (props) => {
  const candidatureRef = useRef();
  const { t } = useTranslation("common");

  const [url, setUrl] = useState(null);
  const candidaturesQuery = useFetchGetPagination(url, props.auth.token, null);
  useEffect(() => {
    setUrl(
      new URL(
        `${process.env.REACT_APP_BASE_URL_API}/candidatures/user/${props.auth.userConnected.id}`
      )
    );
  }, []);
  return (
    <div className="dernieres_offres">
      <ProfilBar id="mobile" />
      <NavBarTab
        id="desktop"
        select="profil"
        activeIndex={props.activeIndex}
        items={props.items}
      />
      <div className="candidature_envoyee">
        <div className="candidature_title">
          <h1 className="h1_trait_dessus">
            {t("profilItem.sentApplications")}
          </h1>
        </div>

        <div className="candidatures">
          {candidaturesQuery.loaded ? (
            candidaturesQuery.data.length > 0 ? (
              candidaturesQuery.data.map((candidature, index) => (
                <Offre
                  url={
                    candidature.offer.imageUrl
                      ? candidature.offer.imageUrl
                      : "img1"
                  }
                  offreDetails={candidature.offer}
                  date={formatDate(candidature.offer.postedAt, ".")}
                  accessibleForDisabled={
                    candidature.offer.accessibleForDisabled
                  }
                  key={index}
                />
              ))
            ) : (
              t("profilItem.noSentApplications")
            )
          ) : (
            <Loader></Loader>
          )}
        </div>
      </div>
      <TempPagination
        result={candidaturesQuery}
        refItemParent={candidatureRef}
        rows={50}
      />
    </div>
  );
};

CandidaturesEnvoyees.propTypes = {
  items: PropTypes.array,
  activeIndex: PropTypes.number,
  auth: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidaturesEnvoyees);
