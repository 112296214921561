import React, { useState, useEffect } from "react";
import Header from "Components/Header/Header";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import GroupeContainer from "Components/Groupes/GroupeContainer/GroupeContainer";
import GroupeFinder from "Components/Groupes/GroupeFinder/GroupeFinder";
import GroupeManagement from "Components/Groupes/GroupeManagement/GroupeManagement";
import Footer from "Components/Footer/Footer";
import GroupeEvent from "Components/Groupes/GroupeEvent/GroupeEvent";
import { useFetchGet, useFetchGetConditional } from "Services/api";
import { updateGroups } from "Redux/Actions/groupsActions";
import { updateAuth } from "Redux/Actions/authActions";
import { updateEvents } from "Redux/Actions/eventsActions";
import { updateSecondaryTables } from "Redux/Actions/secondaryTablesActions";

/**
 * Redirige vers les différentes pages des groupes en fonction de l'item du menu sélectionné
 * Accessible pour tous les profils
 * Seuls les administrateurs peuvent créer des groupes sans attente de validation
 * Ils sont également les seuls à pouvoir créer les groupes institutionnels
 * (affichés dans les suggestions de la page "mes groupes d'échanges")
 */
const Groupes = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [activeIndex, setActiveIndex] = useState(0);

  // récupération des données des groupes de l'api
  const dataRoles = useFetchGet("/roles", props.auth.token);

  const specialites = useFetchGet("/education_specialities", props.auth.token);
  const event_categories = useFetchGet("/event_categories", props.auth.token);
  const eventsParticipants = useFetchGet(
    "/event_group_participants",
    props.auth.token
  );
  const evenements = useFetchGet("/event_groups", props.auth.token);

  const publications = useFetchGet("/publications", props.auth.token);
  const publicationsComments = useFetchGet(
    "/publication_comments",
    props.auth.token
  );

  const domaines = useFetchGetConditional(
    "/domains",
    props.auth.token,
    props.secondaryTables.domainsData
  );
  const composantes = useFetchGetConditional(
    "/education_composantes",
    props.auth.token,
    props.secondaryTables.educationComposanteData
  );

  const groupCategories = useFetchGetConditional(
    "/group_categories",
    props.auth.token,
    props.groups.categoriesData
  );

  useEffect(() => {
    composantes.loaded &&
      domaines.loaded &&
      props.handleUpdateSecondaryTables({
        educationComposanteData: composantes.data,
        domainsData: domaines.data,
      });
  }, [composantes.loaded, domaines.loaded]);

  // remplissage de Redux avec les données de l'api

  useEffect(() => {
    props.handleUpdateGroups({
      categories: groupCategories.loaded ? groupCategories.data : [],
    });
  }, [groupCategories.loaded]);

  useEffect(() => {
    props.handleUpdateGroups({
      statuses:
        props.states.statesData.length > 0 ? props.states.statesData : [],
      composantes: composantes.loaded ? composantes.data : [],
      domainesFormation: domaines.loaded ? domaines.data : [],
      specialitesFormation: specialites.loaded ? specialites.data : [],
      evenements: evenements.loaded ? evenements.data : [],
      publications: publications.loaded ? publications.data : [],
      publicationsComments: publicationsComments.loaded
        ? publicationsComments.data
        : [],
      groupsEventsParticipants: eventsParticipants.loaded
        ? eventsParticipants.data
        : [],
      roles: dataRoles.loaded ? dataRoles.data : [],
    });
    props.handleUpdateEvents({
      categoriesData: event_categories.loaded ? event_categories.data : [],
    });
  }, [
    event_categories.loaded,
    groupCategories.loaded,
    publications.loaded,
    dataRoles.loaded,
    props.states.statesData.length,
  ]);

  return (
    <div>
      {!props.auth.isConnected ? (
        <Header />
      ) : (
        <>
          {/* on est connecté et sans le rôle admin (étudiant, personnel, alumni)  */}
          {props.auth.isConnected && !props.auth.isAdmin ? (
            <>
              {props.items.activeItemGroupes ==
                props.items.itemsGroupes.findIndex(
                  (v) => v.label == "Mes groupes d'échanges"
                ) && (
                <GroupeContainer
                  activeIndex={props.items.itemsGroupes.findIndex(
                    (v) => v.label == "Mes groupes d'échanges"
                  )}
                  setActiveIndex={setActiveIndex}
                  items={props.items.itemsGroupes}
                />
              )}
              {props.items.activeItemGroupes ==
                props.items.itemsGroupes.findIndex(
                  (v) => v.label == "Trouver un groupe"
                ) && (
                <GroupeFinder
                  activeIndex={props.items.itemsGroupes.findIndex(
                    (v) => v.label == "Trouver un groupe"
                  )}
                  setActiveIndex={setActiveIndex}
                  items={props.items.itemsGroupes}
                />
              )}
              {props.items.activeItemGroupes ==
                props.items.itemsGroupes.findIndex(
                  (v) => v.label == "Événements de groupe"
                ) && (
                <GroupeEvent
                  activeIndex={props.items.itemsGroupes.findIndex(
                    (v) => v.label == "Événements de groupe"
                  )}
                  setActiveIndex={setActiveIndex}
                  items={props.items.itemsGroupes}
                />
              )}
              {props.items.activeItemGroupes ==
                props.items.itemsGroupes.findIndex(
                  (v) => v.label == "Proposer et gérer mes groupes"
                ) && (
                <GroupeManagement
                  activeIndex={props.items.itemsGroupes.findIndex(
                    (v) => v.label == "Proposer et gérer mes groupes"
                  )}
                  setActiveIndex={setActiveIndex}
                  items={props.items.itemsGroupes}
                />
              )}
            </>
          ) : (
            <>
              {/* on est connectés avec le rôle admin  */}
              {props.items.activeItemGroupesAdmin ==
                props.items.itemsGroupesAdmin.findIndex(
                  (v) => v.label == "Mes groupes d'échanges"
                ) && (
                <GroupeContainer
                  activeIndex={props.items.itemsGroupesAdmin.findIndex(
                    (v) => v.label == "Mes groupes d'échanges"
                  )}
                  setActiveIndex={setActiveIndex}
                  items={props.items.itemsGroupesAdmin}
                />
              )}

              {props.items.activeItemGroupesAdmin ==
                props.items.itemsGroupesAdmin.findIndex(
                  (v) => v.label == "Trouver un groupe"
                ) && (
                <GroupeFinder
                  activeIndex={props.items.itemsGroupesAdmin.findIndex(
                    (v) => v.label == "Trouver un groupe"
                  )}
                  setActiveIndex={setActiveIndex}
                  items={props.items.itemsGroupesAdmin}
                />
              )}

              {props.items.activeItemGroupesAdmin ==
                props.items.itemsGroupesAdmin.findIndex(
                  (v) => v.label == "Événements de groupe"
                ) && (
                <GroupeEvent
                  activeIndex={props.items.itemsGroupesAdmin.findIndex(
                    (v) => v.label == "Événements de groupe"
                  )}
                  setActiveIndex={setActiveIndex}
                  items={props.items.itemsGroupesAdmin}
                />
              )}
              {props.items.activeItemGroupesAdmin ==
                props.items.itemsGroupesAdmin.findIndex(
                  (v) => v.label == "Créer et gérer mes groupes"
                ) && (
                <GroupeManagement
                  activeIndex={props.items.itemsGroupesAdmin.findIndex(
                    (v) => v.label == "Créer et gérer mes groupes"
                  )}
                  setActiveIndex={setActiveIndex}
                  items={props.items.itemsGroupesAdmin}
                />
              )}
            </>
          )}
        </>
      )}

      <Footer />
    </div>
  );
};

Groupes.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.object,
  users: PropTypes.object,
  groups: PropTypes.object,
  states: PropTypes.object,
  secondaryTables: PropTypes.object,

  handleUpdateGroups: PropTypes.func,
  handleUpdateUsers: PropTypes.func,
  handleUpdateAuth: PropTypes.func,
  handleUpdateEvents: PropTypes.func,
  handleUpdateSecondaryTables: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
  users: state.users,
  groups: state.groups,
  states: state.states,
  secondaryTables: state.secondaryTables,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateGroups: (value) => {
    dispatch(updateGroups(value));
  },
  handleUpdateAuth: (value) => {
    dispatch(updateAuth(value));
  },
  handleUpdateEvents: (value) => {
    dispatch(updateEvents(value));
  },
  handleUpdateSecondaryTables: (value) => {
    dispatch(updateSecondaryTables(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Groupes);
