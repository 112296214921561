import React, { useEffect, useState } from "react";
import "./app.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Evenements from "../Evenements/Evenements";
import Actualites from "Pages/Actualites/Actualites";
import Offres from "../Offres/Offres";
import Groupes from "../Groupes/Groupes";
import Annuaire from "Pages/Annuaire/Annuaire";
import Search from "Pages/Search/Search";
import Accueil from "../Accueil/Accueil";
import Profil from "../Profil/Profil";
import OffreDetail from "Components/Offres/Offre/OffreDetail/OffreDetail";
import ProfilEdit from "Pages/Profil/ProfilEdit/ProfilEdit";
import EntrepriseDetail from "Components/Annuaire/AnnuaireEntreprise/EntrepriseDetail/EntrepriseDetail";
import Error401 from "Components/Error/Error401/Error401";
import ProfilShow from "Pages/Profil/ProfilShow/ProfilShow";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import EvenementDetail from "Components/Evenements/EvenementDetail/EvenementDetail";
import ActualiteDetail from "Components/Actualites/ActualiteDetail/ActualiteDetail";
import Parametrage from "Pages/Parametrage/Parametrage";
import Contact from "Pages/Footer/Contact/Contact";
import Aide from "Pages/Footer/Aide/Aide";
import Accessibilite from "Pages/Footer/Accessibilite/Accessibilite";
import GestionCookies from "Pages/Footer/GestionCookies/GestionCookies";
import MentionsLegales from "Pages/Footer/MentionsLegales/MentionsLegales";
import PlanDuSite from "Pages/Footer/PlanDuSite/PlanDuSite";
import { fetchGet, useFetchGet, useFetchGetConditional } from "Services/api";
import { updateAdmin } from "Redux/Actions/adminActions";
import Mecenat from "Pages/Mecenat/Mecenat";
import Partenaires from "Pages/Partenaires/Partenaires";
import { getCookie } from "Services/functions";
import { updateAuth } from "Redux/Actions/authActions";
import GroupeCardEventDetails from "Components/Groupes/GroupeCard/GroupeCardEvent/GroupeCardEventDetails/GroupeCardEventDetails";
import axios from "axios";
import ModalCharte from "Components/Modal/ModalCharte/ModalCharte";
import { checkTokenValidity, fetchUser, processToken } from "Services/auth";
import i18next from "i18next";
import { addLocale, locale } from "primereact/api";
import { getFrenchLocale } from "Components/Stats/Data/Const";
import Loader from "Components/Loader/loader";
import { Toast } from "primereact/toast";
import { updateStates } from "Redux/Actions/statesActions";

/**
 * Composant mère dont dépendent tous les autres composants qui seront affichés
 */
function App(props) {
  // Utilisation du locale fr pour les components date + heures primeReact
  addLocale("fr", getFrenchLocale().fr);
  locale("fr");
  //? Récupération des données de la gestion éditoriale
  const gestionEdit = useFetchGet("/editorial_managements");
  const statesData = useFetchGetConditional(
    "/states",
    props.auth.token,
    props.states.statesData
  );
  const currentUniv = useFetchGetConditional(
    "/this_university",
    props.auth.token,
    props.auth.universityConnected
  );
  const groupMemberStatesData = useFetchGetConditional(
    "/group_member_statuses",
    props.auth.token,
    props.states.groupMemberStatesData
  );

  const [visibleModalCharte, setvisibleModalCharte] = useState(false);

  const [verifyUser, setVerifyUser] = useState(false);
  const [displayApp, setDisplayApp] = useState(false);

  const interval = React.useRef(null);
  const toast = React.useRef(null);

  const CHECK_EXPIRATION_TIME_INTERVAL = 20000;

  /**
   * Met à jour une propriété de l'état states, si nécessaire
   * @param {String} propertyName
   * @param {*} value
   */
  const updateStatesProperty = (propertyName, value) => {
    if (!props.states[propertyName] || props.states[propertyName]?.length == 0)
      props.handleStates({
        [propertyName]: value,
      });
  };

  useEffect(() => {
    props.handleAuth({
      toast: toast,
    });
  }, [toast]);

  useEffect(() => {
    if (statesData.loaded) updateStatesProperty("statesData", statesData.data);
    if (groupMemberStatesData.loaded)
      updateStatesProperty("groupMemberStatesData", groupMemberStatesData.data);
  }, [statesData.loaded, groupMemberStatesData.loaded]);

  useEffect(() => {
    if (currentUniv.loaded && !props.auth.universityConnected?.id) {
      props.handleAuth({
        universityConnected: currentUniv.data || null,
      });
    }
  }, [currentUniv.loaded]);

  /**
   * Hook qui vérifie si le token est valide, exécuté à chaque fois que le token change
   * Sur un intervalle de 20 secondes
   */
  useEffect(async () => {
    clearInterval(interval.current);
    interval.current = setInterval(async () => {
      const token = props.auth.token;
      if (!token) return;
      // On récupère le token
      if (!props.auth.token) return;
      const newToken = await processToken(props.auth.token);

      // Si token n'est pas null et n'est pas une promise on met à jour l'état d'authentification
      if (newToken != null && !newToken?.then) {
        props.handleAuth({
          token: newToken,
        });
      }
    }, CHECK_EXPIRATION_TIME_INTERVAL);
    return () => {
      clearInterval(interval.current);
    };
  }, [props.auth.token]);

  /**
   * It checks for the presence of a cookie every second for 2 seconds.
   * If the cookie is found, it tries to authenticate the user.
   * @returns A function that clears the interval.
   */
  const checkForCookie = () => {
    let counter = 0;
    let isAuthenticating = false;
    let intervalId = null;

    const checkFunction = async () => {
      const conditions =
        counter > 3 ||
        (props.auth.token &&
          props.auth.isConnected &&
          props.auth.userConnected);
      if (conditions) {
        clearInterval(intervalId);
        setVerifyUser(true);
        return;
      }

      const cookie = getCookie("t");
      if (!cookie) counter++;

      if (!isAuthenticating) {
        isAuthenticating = true;
        const check = await authenticateUser();
        if (check) {
          setVerifyUser(true);
          clearInterval(intervalId);
          counter++;
        }
        isAuthenticating = false;
      }
    };

    clearInterval(intervalId);
    setTimeout(async () => {
      checkFunction();
      intervalId = setInterval(checkFunction, 1000);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  };

  /**
   * Vérifie si l'utilisateur est connecté, en fonction du cookie
   */
  useEffect(async () => {
    checkForCookie();
  }, []);

  /**
   * Change la langue de l'application
   * @param language - La langue à appliquer (fr ou en)
   */
  const setLanguage = (language) => {
    i18next.changeLanguage(language);
  };

  // Applique la langue de l'application au chargement de la page
  useEffect(() => {
    setLanguage(props.i18n.language || "fr");
  }, []);

  useEffect(() => {
    if (gestionEdit.loaded) {
      props.handleAdmin({
        editManagement: gestionEdit.data,
      });
    }
  }, [gestionEdit.loaded]);
  useEffect(async () => {
    if (props.auth.isConnected) {
      const gestionEditBoolean = await fetchGet(
        "/editorial_management_booleans"
      );
      props.handleAdmin({
        editManagementBoolean: gestionEditBoolean,
      });
    }
  }, [props.auth.isConnected]);

  const removeUserData = () => {
    props.handleAuth({
      token: null,
      userConnected: null,
      isConnected: false,
      isAdmin: false,
      isCreator: false,
      isValidator: false,
      isAlumni: false,
      isPersonnel: false,
    });
  };

  const chartSigned = async () => {
    axios.put(
      `${process.env.REACT_APP_BASE_URL_API}/users/${props.auth.userConnected.id}`,
      { charteSigned: true },
      {
        headers: props.auth.token
          ? {
              accept: "application/json",
              Authorization: `Bearer ${props.auth.token}`,
            }
          : {
              accept: "application/json",
            },
      }
    );
  };

  const verifiyCharte = async (user) => {
    if (!user.charteSigned) {
      setvisibleModalCharte(true);
    }
  };

  // Fonction pour remplir les données de l'user dans le store
  const fillUser = (user, token) => {
    props.handleAuth({
      token: token,
      userConnected: user,
      isConnected: true,
      isAdmin: user.userRoles.some(
        (role) => role.roleName == "ROLE_ADMIN_DU_PORTAIL"
      ),
      isCreator: user.userRoles.some((role) =>
        role.roleName.includes("REDACTEUR_ACTU")
      ),
      isValidator: user.userRoles.some((role) =>
        role.roleName.includes("VALIDATEUR_OFFRE")
      ),
      isAlumni: user.userRoles.some((role) =>
        role.roleName.includes("ROLE_ALUMNI")
      ),
      isPersonnel: user.userRoles.some((role) =>
        role.roleName.includes("ROLE_PERSONNEL_UNIVERSITE")
      ),
    });
  };

  const authenticateUser = async () => {
    // On check les cookies pour voir s'il y a un token / refresh token
    let token = getCookie("t");
    if (!token) return false;
    let user = null;
    try {
      user = await fetchUser(token);
    } catch (exception) {
      if (axios.isAxiosError(exception)) {
        /** @var AxiosError */
        removeUserData();
        //? 401 = Unauthorized (session expirée ou token invalide...)
        //? Donc on force l'utilisateur à se reconnecter pour recréer une session et un token valide
        if (exception.response.status === 401)
          window.location.href = `${process.env.REACT_APP_BASE_URL_API}/logout`;
      }
      return false;
    }
    if (user) {
      fillUser(user, token);
      await verifiyCharte(user, token);
      return true;
    } else {
      removeUserData();
      return false;
    }
  };

  useEffect(() => {
    if (verifyUser) {
      if (props.auth.isConnected) {
        const isValid = !checkTokenValidity(props.auth?.token);
        if (!isValid) removeUserData();
      } else removeUserData();
      setDisplayApp(true);
    }
  }, [verifyUser]);

  return (
    <>
      {displayApp ? (
        <div className="app" id="app" data-theme={props.auth.mode}>
          <BrowserRouter>
            <Toast ref={toast} />
            <Routes>
              <Route path="/" element={<Accueil />} />
              <Route path="/evenements" element={<Evenements />} />
              <Route path="/actualites" element={<Actualites />} />
              <Route path="/mecenat" element={<Mecenat />} />
              <Route path="/partenaires" element={<Partenaires />} />
              {/* <Route path="/boutique" element={<Boutique />} /> */}

              <Route path="/offres" element={<Offres />} />
              <Route path="/groupes" element={<Groupes />} />
              <Route path="/annuaire" element={<Annuaire />} />
              <Route path="/search" element={<Search />} />
              {props.user.isConnected && (
                <Route path="/profil" element={<Profil />} />
              )}
              <Route path="/parametrage" element={<Parametrage />} />
              <Route path="/profil-edit" element={<ProfilEdit />} />
              <Route path="/profil-show/:id" element={<ProfilShow />} />
              {/* <Route path="/offre-detail/:idOffre" exact></Route> */}
              <Route path={"/offre-detail/:id"} element={<OffreDetail />} />
              <Route path={"/event-detail/:id"} element={<EvenementDetail />} />
              <Route
                path={"/group_event-detail/:id"}
                element={<GroupeCardEventDetails />}
              />

              <Route path={"/actu-detail/:id"} element={<ActualiteDetail />} />
              <Route
                path="/annuaire/detail/:id"
                element={<EntrepriseDetail />}
              />
              <Route path="/contact" element={<Contact />} />
              <Route path="/aide" element={<Aide />} />
              <Route path="/accessibilite" element={<Accessibilite />} />
              <Route path="/gestion-cookies" element={<GestionCookies />} />
              <Route path="/mentions-legales" element={<MentionsLegales />} />
              <Route path="/plan-du-site" element={<PlanDuSite />} />
              {/* 404 */}
              <Route path="/error401" element={<Error401 />} />
              <Route path="*" element={<Accueil />} />
            </Routes>
          </BrowserRouter>
          {visibleModalCharte && (
            <ModalCharte
              visibleModal={visibleModalCharte}
              setVisibleModal={setvisibleModalCharte}
              btnRefuse={() => {
                props.handleAuth({
                  isConnected: false,
                  token: null,
                  isAdmin: false,
                  isAlumni: false,
                  isCreator: false,
                  isValidator: false,
                  isPersonnel: false,
                  userConnected: null,
                });
              }}
              btnAction={() => {
                chartSigned();
              }}
            ></ModalCharte>
          )}
        </div>
      ) : (
        <div className="app__loading">
          <Loader />
        </div>
      )}
    </>
  );
}

App.propTypes = {
  user: PropTypes.object,
  handleAdmin: PropTypes.func,
  handleAuth: PropTypes.func,
  handleStates: PropTypes.func,
  auth: PropTypes.object,
  universities: PropTypes.object,
  i18n: PropTypes.object,
  states: PropTypes.object,
};
const mapStateToProps = (state) => ({
  user: state.auth,
  admin: state.admin,
  states: state.states,
  universities: state.universities,
  auth: state.auth,
  i18n: state.i18n,
});

const mapDispatchToProps = (dispatch) => ({
  handleAdmin: (value) => {
    dispatch(updateAdmin(value));
  },
  handleAuth: (value) => {
    dispatch(updateAuth(value));
  },
  handleStates: (value) => {
    dispatch(updateStates(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
