import React from "react";
import "./EntrepriseCard.scss";
import PropTypes from "prop-types";
import { FaSuitcase, FaUsers } from "react-icons/fa";
import "primeicons/primeicons.css";
import { FaHeart } from "react-icons/fa";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { updateAuth } from "Redux/Actions/authActions";

const EntrepriseCard = (props) => {
  const navigate = useNavigate();
  return (
    <div className="entreprises_cards">
      <Link
        to={{
          pathname: props.auth.isConnected
            ? `/annuaire/detail/${props.companyId}`
            : "",
          params: props.companyId,
        }}
        key={props.companyId}
      >
        <div className="entreprises_cards__top">
          <img
            src={require(`../../../../assets/${props.imageFond}.webp`)}
            alt="Fond d'entreprise"
          />
          <div className="entreprises_cards__top__logo">
            <img
              src={require(`../../../../assets/logoOffre1.webp`)}
              alt="Logo d'entreprise"
            />
          </div>
        </div>
        <div className="entreprises_cards__corps">
          <h3>{props.nomEntreprise}</h3>
          <p>
            <i className="pi pi-building"></i>
            {props.type}
          </p>
          <p>
            <FaUsers />
            {props.taille}
          </p>
        </div>
      </Link>

      <div className="entreprises_cards__bottom">
        {props.offresDisponibles && (
          <button className="btn_card" onClick={() => navigate("/offres")}>
            <FaSuitcase /> Voir les offres disponibles
          </button>
        )}
        <FaHeart
          className={
            props.isPartner
              ? "partner_company_heart"
              : "not_partner_company_heart"
          }
        ></FaHeart>
      </div>
    </div>
  );
};

EntrepriseCard.propTypes = {
  nomEntreprise: PropTypes.string,
  type: PropTypes.string,
  taille: PropTypes.string,
  imageLogo: PropTypes.string,
  imageFond: PropTypes.string,
  offresDisponibles: PropTypes.bool,
  isPartner: PropTypes.bool,
  companyId: PropTypes.number,
  handleAuth: PropTypes.func,
  companies: PropTypes.object,
  auth: PropTypes.object,
  uploadToast: PropTypes.any,
  cancelToast: PropTypes.any,
};

const mapStateToProps = (state) => ({
  companies: state.companies,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  handleAuth: (value) => {
    dispatch(updateAuth(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EntrepriseCard);
