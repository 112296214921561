import Loader from "Components/Loader/loader";
import Management from "Components/Management/Management";
import TempPagination from "Components/Pagination/TempPagination";
import { updateMenu } from "Redux/Actions/menuActions";
import { updateNews } from "Redux/Actions/newsActions";
import { updatePagination } from "Redux/Actions/paginationActions";
import { useFetchGetPagination } from "Services/api";
import {
  filterByTransmitter,
  formatDateFrenchLocale,
} from "Services/functions";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import ActualiteManagerCard from "./ActualiteCard/ActualiteCard";
import "./ActualitesManagement.scss";
import { RECRUTEUR } from "../../Management/ManagementButtons";

/**
 * Permet d'afficher la page de gestion des actus
 * Accessible pour les administrateurs, alumni et personnels
 */
const ActualitesManagement = (props) => {
  const { t } = useTranslation("common");
  const [url, setUrl] = useState(null);

  useFetchGetPagination(url, props.auth.token, props.handleUpdateNews);
  // *****************************************************************
  // ****************** UseStates / UseRef *********************
  // *****************************************************************

  const [states, setStates] = useState([]);
  // State qui permet de gérer les boutons en fonction des clics
  const [btnList, setBtnList] = useState([
    {
      isActive: true,
      text: t("actusApresConnexion.manageAdminButtonActus"),
      transmitter: ["Admin"],
    },
    {
      isActive: false,
      text: t("actusApresConnexion.manageAdminButtonUniversity"),
      transmitter: ["Etudiant", "Alumni", "Personnel"],
    },
    {
      isActive: false,
      text: t("actusApresConnexion.manageAdminButtonEntreprise"),
      transmitter: ["Recruteur"],
    },
    {
      isActive: false,
      text: t("actusApresConnexion.manageAdminButtonAllActus"),
      transmitter: ["all"],
    },
  ]);

  const actualitesContainerRef = useRef(null);
  const newsMngRef = useRef(null);

  useEffect(() => {
    props.handleUpdateNews({ currentTransmitter: ["me"] });
    return () => {
      props.handleUpdateNews({ dataFromPagination: null });
    };
  }, []);

  // Hook demise à zéro des informations de paginations pour ne pas interférer les affichages des autres pages
  useEffect(() => {
    if (states.length > 0) {
      if (newsMngRef.current !== null) {
        window.scroll({
          top: newsMngRef.current.offsetTop,
          behavior: "smooth",
        });
      }
      let newUrl = new URL(`${process.env.REACT_APP_BASE_URL_API}/news`);
      newUrl.searchParams.set("order[modifiedAt]", "desc");
      newUrl.searchParams.set("itemsPerPage", 20);
      states.forEach((state) => {
        newUrl.searchParams.append("state[]", state.id);
      });
      newUrl.searchParams.append(
        "university",
        props.auth.universityConnected?.id
      );
      props.auth.isAdmin
        ? newUrl.searchParams.append("transmitter[]", "Admin")
        : newUrl.searchParams.append(
            "createdBy[]",
            props.auth.userConnected.id
          );

      setUrl(newUrl);
    }
  }, [states]);

  useEffect(() => {
    /// Récupérer les statuts, et les filtrer par ordre alphabétique
    if (props.news.etats && props.news.etats.length > 0) {
      setStates([]);
      let tempArray = props.news.etats.map((state) => {
        return { ...state };
      });
      tempArray = tempArray.sort((a, b) => a.name.localeCompare(b.name));
      tempArray.forEach((state) => {
        if (state.name.lastIndexOf("é") === state.name.length - 1) {
          state.name = state.name + "e";
        }
      });
      setStates(tempArray);
    }
  }, [props.news.etats]);

  /**
   * Fonction qui permet de gérer les boutons de gauche,
   * et notamment de changer l'url en fonction du transmetteur
   * - Entreprise : on enlève le statut "Publié"
   * - Autre : on rajoute le statut "Publié" s'il n'est pas déjà présent
   * @param {Number} index
   * @returns {void}
   */
  const onBtnClick = (index) => {
    const newUrl = filterByTransmitter(
      "news",
      url,
      btnList[index].transmitter,
      props.auth.userConnected.id
    );
    const publishedState = props.news.etats.find(
      (state) => state.statusLabel === "Publié"
    ).id;
    const stateParams = newUrl.searchParams.getAll("state[]");
    // Si le transmetteur actuel est recruteur, on enlève le statut "Publié"
    if (
      btnList[index].transmitter[0] === RECRUTEUR &&
      btnList[index].transmitter?.length === 1
    ) {
      newUrl.searchParams.delete("state[]");

      stateParams
        .filter((state) => state != publishedState)
        .forEach((state) => {
          newUrl.searchParams.append("state[]", state);
        });
    }
    //* Sinon on le rajoute s'il n'est pas déjà présent
    else if (
      stateParams.findIndex((state) => state === publishedState) === -1
    ) {
      newUrl.searchParams.append("state[]", publishedState);
    }
    setUrl(newUrl);

    props.handleUpdateNews({
      currentTransmitter: btnList[index].transmitter,
      dataFromPagination: null,
    });
    let i = 0;
    setBtnList(
      btnList.map((btn) => {
        btn.isActive = i === index;
        i++;
        return btn;
      })
    );
  };

  return (
    <>
      <div className="gestion_evenements" ref={newsMngRef}>
        <div className="gestion_evenements__title">
          <h1 className="h1_trait_dessus">
            {props.auth.isAdmin
              ? t("actusApresConnexion.submenuGererAdmin")
              : t("actusApresConnexion.submenuGerer")}
          </h1>
          <Management
            states={states}
            btnEntreprise={btnList[2]}
            actualPage={"actu"}
            currentTransmitter={
              btnList.filter((btn) => btn.isActive === true)[0].transmitter
            }
            updateFunction={props.handleUpdateNews}
            setUrl={setUrl}
          ></Management>
        </div>
        <div className="gestion_evenements__body">
          <div className="gestion_evenements__body__left">
            {props.auth.isAdmin &&
              btnList.map((btnStatus, index) => {
                return (
                  <button
                    className={
                      btnStatus.isActive
                        ? "btn-bleu " + "item" + index
                        : "btn-blanc " + "item" + index
                    }
                    onClick={() => onBtnClick(index)}
                    key={index}
                  >
                    {btnList[index].text}
                  </button>
                );
              })}
          </div>
          <div
            className="gestion_evenements__body__right"
            ref={actualitesContainerRef}
            key={props.news.dataFromPagination}
          >
            {props.news.dataFromPagination?.loaded ? (
              props.news.dataFromPagination.data.length > 0 ? (
                props.news.dataFromPagination.data
                  .filter(
                    (actu) =>
                      actu.state.statusLabel !== "Brouillon" ||
                      actu.createdBy.id == props.auth.userConnected.id
                  )
                  .sort(
                    (a, b) => new Date(b.modifiedAt) - new Date(a.modifiedAt)
                  )
                  .map((news, index) => (
                    <ActualiteManagerCard
                      titre={news.title ? news.title : "Titre non renseigné"}
                      date={formatDateFrenchLocale(news.publishedAt)}
                      statut={news.state}
                      image={news.imageUrl ? news.imageUrl : "img1"}
                      key={index}
                      id={news.id}
                      feedback={news.feedback?.feedback}
                      transmitterName={props.news.currentTransmitter[0]}
                    />
                  ))
              ) : (
                <span>{t("actusApresConnexion.manageError")}</span>
              )
            ) : (
              <Loader></Loader>
            )}
          </div>
        </div>
        <TempPagination
          result={props.news.dataFromPagination}
          refItemParent={actualitesContainerRef}
          rows={20}
        />
      </div>
    </>
  );
};

ActualitesManagement.propTypes = {
  auth: PropTypes.object,
  activeIndex: PropTypes.number,
  news: PropTypes.object,
  handleUpdateNews: PropTypes.func,
  handleMenu: PropTypes.func,
  items: PropTypes.object,
  pagination: PropTypes.object,
  handleUpdatePagination: PropTypes.func,
  admin: PropTypes.object,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  news: state.news,
  items: state.items,
  pagination: state.pagination,
  admin: state.admin,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateNews: (value) => {
    dispatch(updateNews(value));
  },
  handleMenu: (value) => {
    dispatch(updateMenu(value));
  },
  handleUpdatePagination: (value) => {
    dispatch(updatePagination(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActualitesManagement);
