/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./headerUserConnected.scss";
import PropTypes from "prop-types";
import Modal from "Components/Modal/Modal";
import { updateAuth } from "Redux/Actions/authActions";
import { connect } from "react-redux";
import "primeicons/primeicons.css";
import image_profil from "assets/neutralUser.png";
import { FaAngleDown } from "react-icons/fa";
import { updateMenu } from "Redux/Actions/menuActions";
import { VscColorMode } from "react-icons/vsc";
import { useTranslation } from "react-i18next";
import GlobalSearch from "Components/GlobalSearch/GlobalSearch";
import { updateI18n } from "Redux/Actions/i18nActions";

const headerUserConnected = (props) => {
  const [openConnexionInterface, setOpenConnexionInterface] = useState(false);
  const [openI18nInterface, setOpenI18nInterface] = useState(false);

  const [t, i18n] = useTranslation("common");

  // Fonction pour aller sur la page de déconnexion du serveur
  const navigateToLogout = () => {
    window.location.href = `${process.env.REACT_APP_BASE_URL_API}/logout`;
  };

  // Supprime les cookies de connexion
  const removeCookies = () => {
    // Suppression des cookies de connexion et de rafraichissement du token
    // - Authentification locale
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    // - Shibboleth (commenté car géré par le serveur)
    // document.cookie = "t=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    // document.cookie = "rft=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  };

  // Déconnecte l'utilisateur
  const disconnectUser = () => {
    props.handleAuth({
      isConnected: false,
      token: null,
      userConnected: null,
      isAdmin: false,
      isCreator: false,
      isValidator: false,
    });
    removeCookies();
    navigateToLogout();
  };

  const [visibleModal, setVisibleModal] = useState(false);
  return (
    <div className="header__bandeau__userConnected">
      <div className="header__bandeau__userConnected__home">
        <button
          className="header__bandeau__userConnected__home__contraste"
          onClick={() => {
            props.handleAuth({
              mode: props.auth.mode === "light" ? "dark" : "light",
            });
          }}
        >
          <VscColorMode />
        </button>
        <GlobalSearch></GlobalSearch>
        <div
          onMouseEnter={() => setOpenI18nInterface(true)}
          onMouseLeave={() => setOpenI18nInterface(false)}
        >
          <button className="header__bandeau__userDisconnected__home__language">
            {props.i18n.language.toUpperCase()}
          </button>
          {openI18nInterface && (
            <div className="connexionInterface language">
              <ul>
                <li
                  onClick={() => {
                    props.handleUpdateI18n({ language: "fr" }),
                      i18n.changeLanguage("fr");
                  }}
                >
                  {t("header.language_fr")}
                </li>
                <li
                  onClick={() => {
                    props.handleUpdateI18n({ language: "en" }),
                      i18n.changeLanguage("en");
                  }}
                >
                  {t("header.language_en")}
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="userConnected__right__image_button">
        <button
          onMouseEnter={() => setOpenConnexionInterface(true)}
          onMouseLeave={() => setOpenConnexionInterface(false)}
          className={`userConnectedButton ${
            props.auth?.userConnected?.portalName
              ? props.auth?.userConnected?.portalName === "parisAlumni"
                ? "alumni"
                : "other"
              : "none"
          }`}
        >
          {props.auth.isConnected && props.auth.userConnected && (
            <>
              <img
                src={
                  props.auth.userConnected.imageUrl
                    ? props.auth.userConnected.imageUrl
                    : image_profil
                }
                alt="Photo de profil utilisateur"
                title="Photo de profil utilisateur"
                className="userConnected__picture"
                onClick={() => (window.location.href = "./profil")}
              />
              {props.auth.userConnected.firstname}
            </>
          )}

          <FaAngleDown />
          {openConnexionInterface && props.auth.userConnected && (
            <div
              className="connexionInterfaceConnected"
              onMouseEnter={() => setOpenConnexionInterface(true)}
              onMouseLeave={() => setOpenConnexionInterface(false)}
            >
              <ul>
                <li>
                  {props.auth.isAdmin && "Admin"}
                  {props.auth.isAlumni && "Alumni"}
                  {props.auth.isPersonnel && "Personnel"}
                </li>
                <li
                  onClick={() => {
                    props.handleMenu({
                      activeItemProfil: props.items.itemsProfil.findIndex(
                        (v) => v.label == "Dernières offres d'emploi"
                      ),
                    });
                    window.location.pathname = "/profil";
                  }}
                >
                  {t("footerApresConnexion.title_profil")}
                </li>
                <li onClick={() => disconnectUser()}>
                  {t("footerApresConnexion.logout")}
                </li>
              </ul>
            </div>
          )}
        </button>
        {visibleModal && (
          <Modal
            visible={visibleModal}
            setVisible={setVisibleModal}
            header="Se connecter"
          >
            <form action="/action_page.php">
              <label htmlFor="fname">First name:</label>
              <input type="text" id="fname" name="fname" value="John" />
              <label htmlFor="lname">Last name:</label>
              <input type="text" id="lname" name="lname" value="Doe" />
              <input type="submit" value="Submit" />
            </form>
          </Modal>
        )}
      </div>
    </div>
  );
};

headerUserConnected.propTypes = {
  auth: PropTypes.object,
  i18n: PropTypes.object,
  handleAuth: PropTypes.func,
  items: PropTypes.object,
  handleMenu: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
  i18n: state.i18n,
});

const mapDispatchToProps = (dispatch) => ({
  handleAuth: (value) => {
    dispatch(updateAuth(value));
  },
  handleMenu: (value) => {
    dispatch(updateMenu(value));
  },
  handleUpdateI18n: (value) => {
    dispatch(updateI18n(value));
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(headerUserConnected);
