import React, { useState, useEffect, useRef } from "react";
import "./EvenementsContainer.scss";
import Evenement from "../EvenementCard/EvenementCard";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import { FaChevronRight, FaRegCalendarCheck } from "react-icons/fa";
import PropTypes from "prop-types";
import { HiSearch } from "react-icons/hi";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import BtnBlancLeft from "Components/Boutons/BtnBlancLeft/btn-blanc-left";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Accordion, AccordionTab } from "primereact/accordion";
import { formatDateDatabase, formatDateLocale } from "Services/functions";
import { updateEvents } from "Redux/Actions/eventsActions";
import { useFetchGet, useFetchGetPagination } from "Services/api";
import { updateMenu } from "Redux/Actions/menuActions";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { CgClose } from "react-icons/cg";
import { fr } from "date-fns/locale";
import TempPagination from "Components/Pagination/TempPagination";
import Loader from "Components/Loader/loader";
import { useTranslation } from "react-i18next";
import { authenticationRedirection } from "Services/redirections";

registerLocale("fr", fr);

const EvenementsContainer = (props) => {
  const { t } = useTranslation("common");

  const evenementContainerRef = useRef(null);
  const categoriesData = useFetchGet("/event_categories", props.auth.token);
  const [categories, setCategories] = useState([]);
  const [filtreCategory, setFiltreCategory] = useState(null);
  const [filtreMotsCles, setFiltreMotsCles] = useState("");
  const [filtreDate, setFiltreDate] = useState(null);
  const [filtreCreation, setFiltreCreation] = useState(null);
  const [visibleModalEvent, setVisibleModalEvent] = useState(false);
  const [resetStatus, setResetStatus] = useState(false);
  const [eventsUrl, setEventsUrl] = useState(null);

  const filtresCreation = [
    { label: "Tous", value: "Tous" },
    { label: "Que j'ai créé", value: "Cree" },
  ];

  const eventsQuery = useFetchGetPagination(
    eventsUrl,
    props.auth.token,
    props.handleUpdateEvents
  );

  useEffect(() => {
    return () => {
      props.handleUpdateEvents({ dataFromPagination: null });
    };
  }, []);

  useEffect(() => {
    /// Récupérer les catégories, et les filtrer par ordre alphabétique
    if (categoriesData.loaded) {
      setCategories([]);
      let tempArray = [];
      categoriesData?.data.forEach((category) => tempArray.push(category));
      tempArray = tempArray.sort((a, b) => a.label.localeCompare(b.label));
      setCategories(tempArray);
    }
  }, [categoriesData.loaded]);

  const handleFilter = () => {
    if (props.states.statesData.length > 0) {
      let url = new URL(`${process.env.REACT_APP_BASE_URL_API}/events`);
      url.searchParams.set(
        "state",
        props.states.statesData.filter(
          (etat) => etat.statusLabel === "Publié"
        )[0].id
      );
      url.searchParams.set("endingAt[after]", formatDateDatabase(new Date()));
      url.searchParams.set("order[startingAt]", "asc");
      if (filtreMotsCles != "") url.searchParams.set("keyword", filtreMotsCles);
      if (filtreCategory) url.searchParams.set("category", filtreCategory.id);
      if (filtreDate && filtreDate[0])
        url.searchParams.set(
          "startingAt[after]",
          formatDateDatabase(filtreDate[0])
        );
      if (filtreDate && filtreDate[1])
        url.searchParams.set(
          "endingAt[before]",
          formatDateDatabase(filtreDate[1])
        );

      setEventsUrl(url);
    }
  };

  useEffect(() => {
    if (!filtreDate || (filtreDate[0] && filtreDate[1])) {
      handleFilter();
    }
  }, [filtreCategory, filtreDate, resetStatus, props.states.statesData.length]);

  return (
    <div className="evenements_container">
      <div className="evenements_all">
        <div className="evenements_container__top">
          {props.pageEvents == "accueil" ? (
            // si on est dans la page d'accueil on n'affiche pas les filtres
            <h1 className="h1_trait_dessus">
              {props.title ? props.title : "Tou les événements"}
            </h1>
          ) : (
            <div>
              {/* si on est sur la page Evenements on affiche les filtres */}
              <div className="evenements_container__top__title">
                <h1 className="h1_trait_dessus">
                  {/* {props.title ? props.title : "Tous les événements"} */}
                  {t("evenementsHorsConnexion.title_page_evenements")}
                </h1>
                <div className="evenements_container__top__title__visible">
                  <div className="evenements_container__top__title__filtres">
                    <span className="evenements_container__top__title__filtres__searchInput">
                      <HiSearch
                        className="searchBar_groupe_finder_icon"
                        onClick={(e) => {
                          e.preventDefault();
                          handleFilter();
                        }}
                      />
                      <InputText
                        placeholder={t(
                          "evenementsHorsConnexion.placeholder_research"
                        )}
                        value={filtreMotsCles}
                        className="membership_input_text"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleFilter();
                          }
                        }}
                        onChange={(e) => {
                          setFiltreMotsCles(e.target.value.toLowerCase());
                        }}
                      />
                    </span>
                    <span>
                      {!props.title && categories.length > 0 && (
                        <Dropdown
                          value={filtreCategory}
                          optionLabel="label"
                          showClear
                          options={categories}
                          onChange={(e) => {
                            setFiltreCategory(e.target.value);
                          }}
                          placeholder={t(
                            "evenementsHorsConnexion.placeholder_filter"
                          )}
                        />
                      )}
                    </span>
                    {props.auth.isConnected && (
                      <>
                        <div
                          className={
                            !filtreDate || (filtreDate[0] && filtreDate[1])
                              ? "management_new_event_offer_filtres__top__date"
                              : "management_new_event_offer_filtres__top__date warning"
                          }
                        >
                          <ReactDatePicker
                            placeholderText={t(
                              "eventApresConnexion.filtreDate"
                            )}
                            selected={filtreDate && filtreDate[0]}
                            onChange={(e) => {
                              setFiltreDate(e);
                            }}
                            dateFormat="dd/MM/yyyy"
                            startDate={filtreDate && filtreDate[0]}
                            endDate={
                              filtreDate && filtreDate[1] && filtreDate[1]
                            }
                            selectsRange
                          />
                          {filtreDate !== null && (
                            <CgClose
                              onClick={() => {
                                setFiltreDate(null);
                              }}
                            ></CgClose>
                          )}
                        </div>
                        {props.title && (
                          <>
                            <Dropdown
                              value={filtreCreation}
                              options={filtresCreation}
                              onChange={(e) => setFiltreCreation(e.value)}
                              placeholder="Filtrer"
                            />
                            <BtnBlancLeft
                              btnTexte="Créer un événement de groupe "
                              btnAction={() =>
                                setVisibleModalEvent(!visibleModalEvent)
                              }
                            >
                              <FaRegCalendarCheck />
                            </BtnBlancLeft>
                          </>
                        )}
                      </>
                    )}
                    {(filtreCategory ||
                      (filtreDate && filtreDate[1]) ||
                      filtreMotsCles) && (
                      <li className="evenements_container__top__title__filtres__refresh">
                        <i
                          className="pi pi-refresh"
                          style={{ fontSize: "1.5rem", fontWeight: "600" }}
                          onClick={() => {
                            setFiltreCategory(null);
                            setFiltreDate(null);
                            setFiltreMotsCles("");
                            setResetStatus(!resetStatus);
                          }}
                        />
                      </li>
                    )}
                    {props.auth.isConnected && (
                      <Link to="/profil">
                        <div>
                          <BtnBlanc
                            btnTexte={t("eventApresConnexion.buttonNextEvents")}
                            btnAction={() => {
                              props.handleUpdateMenu({
                                activeItemProfil:
                                  props.items.itemsProfil.findIndex(
                                    (v) => v.label == "Mes événements à venir"
                                  ),
                              });
                            }}
                          />
                        </div>
                      </Link>
                    )}
                  </div>
                </div>
                <Accordion
                  className="accordion_events"
                  expandIcon="pi pi-sliders-h"
                  collapseIcon="pi pi-sliders-h"
                >
                  <AccordionTab header="Filtres de recherche">
                    <div className="evenements_container__top__title__filtres">
                      <span className="evenements_container__top__title__filtres__searchInput">
                        <HiSearch
                          className="searchBar_groupe_finder_icon"
                          onClick={(e) => {
                            e.preventDefault();
                            handleFilter();
                          }}
                        />
                        <InputText
                          placeholder="Rechercher un événement"
                          className="membership_input_text"
                          value={filtreMotsCles}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              handleFilter();
                            }
                          }}
                          onChange={(e) => {
                            setFiltreMotsCles(e.target.value.toLowerCase());
                          }}
                        />
                      </span>
                      {!props.title && categories.length > 0 && (
                        <Dropdown
                          value={filtreCategory}
                          optionLabel="label"
                          options={categories}
                          showClear
                          onChange={(e) => {
                            setFiltreCategory(e.target.value);
                          }}
                          placeholder="Filtrer par catégorie"
                        />
                      )}

                      {props.auth.isConnected && (
                        <>
                          <div
                            className={
                              !filtreDate || (filtreDate[0] && filtreDate[1])
                                ? "management_new_event_offer_filtres__top__date"
                                : "management_new_event_offer_filtres__top__date warning"
                            }
                          >
                            <ReactDatePicker
                              placeholderText="Filtrer par date"
                              selected={filtreDate && filtreDate[0]}
                              onChange={(e) => {
                                setFiltreDate(e);
                              }}
                              dateFormat="dd/MM/yyyy"
                              startDate={filtreDate && filtreDate[0]}
                              endDate={
                                filtreDate && filtreDate[1] && filtreDate[1]
                              }
                              selectsRange
                            />
                            {filtreDate !== null && (
                              <CgClose
                                onClick={() => {
                                  setFiltreDate(null);
                                }}
                              ></CgClose>
                            )}
                          </div>
                          {props.title ? (
                            <>
                              <Dropdown
                                value={filtreCreation}
                                options={filtresCreation}
                                onChange={(e) => setFiltreCreation(e.value)}
                                placeholder="Filtrer"
                              />
                              <BtnBlancLeft
                                btnTexte="Créer un événement de groupe "
                                btnAction={() =>
                                  setVisibleModalEvent(!visibleModalEvent)
                                }
                              >
                                <FaRegCalendarCheck />
                              </BtnBlancLeft>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                      {(filtreCategory ||
                        (filtreDate && filtreDate[1]) ||
                        filtreMotsCles) && (
                        <li className="evenements_container__top__title__filtres__refresh">
                          <i
                            className="pi pi-refresh"
                            style={{ fontSize: "1.5rem", fontWeight: "600" }}
                            onClick={() => {
                              setFiltreCategory(null);
                              setFiltreDate(null);
                              setFiltreMotsCles("");
                              setResetStatus(!resetStatus);
                            }}
                          />
                        </li>
                      )}
                    </div>
                  </AccordionTab>
                </Accordion>
              </div>
            </div>
          )}
        </div>

        <div className="evenements_container__body" ref={evenementContainerRef}>
          {eventsQuery?.data ? (
            eventsQuery.data.length > 0 ? (
              (props.nbEvents && props.nbEvents != 20
                ? eventsQuery.data.slice(0, props.nbEvents)
                : eventsQuery.data
              ).map((event, index) =>
                props.auth.userConnected || event.public ? (
                  <Link
                    to={{
                      pathname: `/event-detail/${event.id}`,
                      params: event.id,
                    }}
                    key={index}
                  >
                    <Evenement
                      key={index}
                      categorie={event.category}
                      inscription={event.fulfilled}
                      debutDate={
                        (props.i18n.language === "en" ? "From " : "Du ") +
                        formatDateLocale(event.startingAt, props.i18n.language)
                      }
                      finDate={
                        (props.i18n.language === "en" ? "to " : "au ") +
                        formatDateLocale(event.endingAt, props.i18n.language)
                      }
                      title={event.title}
                      chapeau={event.description}
                      eventDetails={event}
                      url={event?.imageUrl ? event.imageUrl : "event1"}
                      registerEnd={event.registerEnd}
                    />
                  </Link>
                ) : (
                  <Evenement
                    key={index}
                    categorie={event.category}
                    inscription={event.fulfilled}
                    debutDate={
                      (props.i18n.language === "en" ? "From " : "Du ") +
                      formatDateLocale(event.startingAt, props.i18n.language)
                    }
                    finDate={
                      (props.i18n.language === "en" ? "to " : "au ") +
                      formatDateLocale(event.endingAt, props.i18n.language)
                    }
                    title={event.title}
                    chapeau={event.description}
                    eventDetails={event}
                    url={event?.imageUrl ? event.imageUrl : "event1"}
                    onClick={() => {
                      authenticationRedirection();
                    }}
                    registerEnd={event.registerEnd}
                  />
                )
              )
            ) : (
              <div
                className="
            evenements_container__body-empty
            "
              >
                <span>{t("erreur.erreur_event")}</span>
              </div>
            )
          ) : (
            <Loader></Loader>
          )}
        </div>
        <div className="evenements_container__button">
          {props.pageEvents == "accueil" ? (
            <BtnBleu
              btnTexte="Voir tous les événements"
              btnAction={() => (window.location.href = "./evenements")}
            >
              <FaChevronRight />
            </BtnBleu>
          ) : (
            <TempPagination
              result={eventsQuery}
              refItemParent={evenementContainerRef}
              rows={50}
            />
          )}
        </div>
      </div>
    </div>
  );
};

EvenementsContainer.propTypes = {
  auth: PropTypes.object,
  nbEvents: PropTypes.number,
  pageEvents: PropTypes.string,
  filtres: PropTypes.array,
  events: PropTypes.any,
  title: PropTypes.string,
  registerEnd: PropTypes.string,
  handleUpdateEvents: PropTypes.func,
  items: PropTypes.object,
  handleUpdateMenu: PropTypes.func,
  states: PropTypes.object,
  i18n: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  events: state.events,
  items: state.items,
  states: state.states,
  i18n: state.i18n,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateEvents: (value) => {
    dispatch(updateEvents(value));
  },
  handleUpdateMenu: (value) => dispatch(updateMenu(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EvenementsContainer);
