import React, { useRef, useState } from "react";
import Modal from "Components/Modal/Modal";
import PropTypes from "prop-types";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import axios from "axios";
import "./ModalQuestion.scss";
import { connect } from "react-redux";

const ModalQuestion = (props) => {
  const modalIsVisible = props.modalIsVisible;
  const setModalIsVisible = props.setModalIsVisible;
  const idEvent = props.idEvent;
  const showSuccess = props.showSuccess;
  const textAreaRef = useRef(null);
  const [error, setError] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    textAreaRef?.current?.value != ""
      ? axios
          .post(
            `${process.env.REACT_APP_BASE_URL_API}/event_questions`,
            {
              eventParticipant: `/api/event_participants/${idEvent.id}`,
              question: textAreaRef?.current?.value,
            },
            {
              headers: props.auth.token
                ? {
                    accept: "application/json",
                    "Content-Type": "application/json",

                    Authorization: `Bearer ${props.auth.token}`,
                  }
                : {
                    accept: "application/json",
                    "Content-Type": "application/json",
                  },
            }
          )
          .then(() => (setModalIsVisible(false), setError(""), showSuccess()))
          .catch((error) => console.log(error))
      : setError("Veuillez poser une question");
  };
  return (
    <div className="modalQuestion">
      <Modal
        visible={modalIsVisible}
        setVisible={setModalIsVisible}
        header={"Poser une question à l'intervenant"}
      >
        <form className="modalQuestion__form" onSubmit={handleSubmit}>
          <textarea
            name="question"
            id="question"
            // cols="30"
            rows="10"
            placeholder="Posez votre question..."
            ref={textAreaRef}
            className="modalQuestion__form__textarea"
          ></textarea>
          {error.length > 0 && (
            <p className="modalQuestion__form__error">{error}</p>
          )}
          <BtnBleu
            className="modalQuestion__button"
            btnTexte={"Soumission"}
            // btnAction={() => handleSubmit()}
          />
        </form>
      </Modal>
    </div>
  );
};

ModalQuestion.propTypes = {
  modalIsVisible: PropTypes.bool,
  idEvent: PropTypes.number,
  setModalIsVisible: PropTypes.func,
  showSuccess: PropTypes.func,
  auth: PropTypes.object,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ModalQuestion);
