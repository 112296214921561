import React, { useState } from "react";
import "./Stats.scss";
import "../NavBar/NavBarTab/NavBarTab.scss";

import { TabMenu } from "primereact/tabmenu";
import { ScrollPanel } from "primereact/scrollpanel";

import PropTypes from "prop-types";

import Offres from "./Catégories/Offres";
import Utilisateurs from "./Catégories/Utilisateurs";
import Entreprises from "./Catégories/Entreprises";
import Matomo from "./Catégories/Matomo";
import Margin from "./Margin";
import Superadmin from "./Catégories/Superadmin";

import { addLocale, locale } from "primereact/api";
import { getFrenchLocale } from "./Data/Const";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useFetchGet } from "Services/api";
import { updateUsers } from "Redux/Actions/usersActions";
import { updateOffers } from "Redux/Actions/offersActions";
import Loader from "Components/Loader/loader";

/**
 * Le composant {@link Stats} est le point d'ancrage de la partie statistiques.
 * Il va définir le menu qui va permettre d'accéder aux différentes parties,
 * définies elles-mêmes dans d'autres composants.
 *
 *
 * Les différentes parties étant :
 * - {@link Offres}
 * - {@link Utilisateurs}
 * - {@link Entreprises}
 * - {@link Matomo}
 * - {@link Superadmin}
 *
 */
const Stats = (props) => {
  const dataOffers = useFetchGet("/offers");
  const dataUsers = useFetchGet("/users");
  const [index, setIndex] = useState(0);
  addLocale("fr", getFrenchLocale().fr);
  locale("fr");

  useEffect(() => {
    dataOffers.loaded &&
      props.handleUpdateOffers({ offersData: dataOffers.data });
    dataUsers.loaded && props.handleUpdateUsers({ usersData: dataUsers.data });
  }, [dataOffers, dataUsers]);

  const items = [
    { label: "Offres", icon: "pi pi-fw pi-folder" },
    { label: "Entreprises", icon: "pi pi-fw pi-building" },
    { label: "Utilisateurs", icon: "pi pi-fw pi-users" },
    { label: "Matomo", icon: "pi pi-fw pi-database" },
    { label: "Superadmin", icon: "pi pi-fw pi-lock" },
  ];

  return (
    <div className="stats_container">
      <div className="stats_container__title">
        <h1>
          <div className="trait_dessus"></div>
          Statistiques
        </h1>
      </div>
      {dataOffers.loaded && dataUsers.loaded ? (
        <div>
          <ScrollPanel className="stats_container__scrollmenu">
            <TabMenu
              model={items}
              onTabChange={(e) => setIndex(e.index)}
              activeIndex={index}
            />
          </ScrollPanel>
          <Margin position="bottom" size="xs" />

          {index === 0 && <Offres />}
          {index === 1 && <Entreprises />}
          {index === 2 && <Utilisateurs />}
          {index === 3 && <Matomo />}
          {index === 4 && <Superadmin />}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

Stats.propTypes = {
  offers: PropTypes.any,
  users: PropTypes.any,
  handleUpdateOffers: PropTypes.func,
  handleUpdateUsers: PropTypes.func,
};
const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateOffers: (value) => {
    dispatch(updateOffers(value));
  },
  handleUpdateUsers: (value) => {
    dispatch(updateUsers(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Stats);
