import React, { useEffect, useState } from "react";
import "./ALaUne.scss";
import ALaUneCard from "./ALaUneCard/ALaUneCard";
import { Carousel } from "react-bootstrap";
import Loader from "Components/Loader/loader";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const ALaUne = (props) => {
  const [randomItems, setRandomItems] = useState([]);

  /**
   * Cette fonction permet de récupérer les données des deux requêtes et de les concaténer
   * Puis de les mélanger et d'en retourner 3, des plus récentes parmi des News et des Events
   */
  const setItems = () => {
    // On concatène les données des deux requêtes
    const allData = props.news.dataFromPagination.data.concat(
      props.events.dataFromPagination.data
    );
    let randomItemsArray = [];

    // On vérifie qu'il y a au moins 3 items
    if (allData.length > 3) {
      // Si oui, on récupère tour à tour un item de chaque tableau
      // Tout en vérifiant qu'on ne dépasse pas la taille du tableau et qu'il existe
      let index = 0;
      while (randomItemsArray.length < 3) {
        const eventItem = props.events?.dataFromPagination?.data?.at(index);
        const newsItem = props.news?.dataFromPagination?.data?.at(index);

        if (eventItem && randomItemsArray.length < 3)
          randomItemsArray.push(eventItem);
        if (newsItem && randomItemsArray.length < 3)
          randomItemsArray.push(newsItem);
        index++;
      }
      setRandomItems(randomItemsArray);
      // Si non, on affiche tout le tableau
    } else {
      setRandomItems(allData);
    }
  };

  useEffect(() => {
    if (
      props.events.dataFromPagination &&
      props.news.dataFromPagination &&
      props.events?.dataFromPagination.loaded &&
      props.news?.dataFromPagination.loaded
    )
      setItems();
  }, [props.events?.dataFromPagination, props.news?.dataFromPagination]);

  return (
    <div>
      {props.events.dataFromPagination &&
      props.news.dataFromPagination &&
      props.events?.dataFromPagination.loaded &&
      props.news?.dataFromPagination.loaded ? (
        randomItems.length > 0 && (
          <Carousel style={{ width: "100%" }} className="carouselALaUne">
            {randomItems.map((item, key) => (
              <Carousel.Item key={key}>
                <ALaUneCard
                  title={item.title}
                  description={item.description}
                  imageUrl={item.imageUrl}
                  date={item.publishedAt ? item.publishedAt : item.startingAt}
                  public={item.public}
                  itemId={item.id}
                  type={item.publishedAt ? "news" : "event"}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        )
      ) : (
        <Loader></Loader>
      )}
    </div>
  );
};
ALaUne.propTypes = {
  events: PropTypes.object,
  news: PropTypes.object,
};

const mapStateToProps = (state) => ({
  events: state.events,
  news: state.news,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ALaUne);
