/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./headerUserDisconnected.scss";
import PropTypes from "prop-types";
import Modal from "../../..//Modal/Modal";
import ModalCharte from "Components/Modal/ModalCharte/ModalCharte";
import { updateAuth } from "../../../../Redux/Actions/authActions";
import { connect } from "react-redux";
import CompteUniversite from "../../../Modal/ModalCreationCompte/ModalCreationCompte";
import { VscColorMode, VscLock } from "react-icons/vsc";
import HeaderUserLogin from "../HeaderUserLogin/HeaderUserLogin";
import { updateI18n } from "Redux/Actions/i18nActions";
import { useTranslation } from "react-i18next";
import GlobalSearch from "Components/GlobalSearch/GlobalSearch";
import { BsArrowUpRightSquare } from "react-icons/bs";
import { authenticationRedirection } from "Services/redirections";

const headerUserDisconnected = (props) => {
  const [openConnexionInterface, setOpenConnexionInterface] = useState(false);
  const [openI18nInterface, setOpenI18nInterface] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleModalCreationCompte, setVisibleModalCreationCompte] =
    useState(false);
  const [visibleModalCharte, setvisibleModalCharte] = useState(false);
  const [loginVisible, setLoginVisible] = useState(false);
  const [t, i18n] = useTranslation("common");

  return (
    <div className="header__bandeau__userDisconnected">
      <div className="header__bandeau__userDisconnected__buttonAccess">
        <button
          className="header__bandeau__buttonAccess"
          onClick={() => authenticationRedirection()}
        >
          <BsArrowUpRightSquare /> {t("header.acces")}
        </button>
      </div>
      <div className="rightBandeau">
        <div className="header__bandeau__userDisconnected__home">
          <button
            className="header__bandeau__userDisconnected__home__contraste"
            onClick={() => {
              props.handleAuth({
                mode: props.auth.mode === "light" ? "dark" : "light",
              });
            }}
          >
            <VscColorMode />
          </button>
          <GlobalSearch></GlobalSearch>
          <div
            onMouseEnter={() => setOpenI18nInterface(true)}
            onMouseLeave={() => setOpenI18nInterface(false)}
          >
            <button className="header__bandeau__userDisconnected__home__language">
              {props.i18n.language.toUpperCase()}
            </button>
            {openI18nInterface && (
              <div className="connexionInterface language">
                <ul>
                  <li
                    onClick={() => {
                      props.handleUpdateI18n({ language: "fr" }),
                        i18n.changeLanguage("fr");
                    }}
                  >
                    {t("header.language_fr")}
                  </li>
                  <li
                    onClick={() => {
                      props.handleUpdateI18n({ language: "en" }),
                        i18n.changeLanguage("en");
                    }}
                  >
                    {t("header.language_en")}
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div
          className="header__bandeau__userDisconnected__homeRight"
          onMouseEnter={() => setOpenConnexionInterface(true)}
          onMouseLeave={() => setOpenConnexionInterface(false)}
        >
          <button className="header__bandeau__userDisconnected__homeRight__ent">
            <VscLock /> ENT{" "}
          </button>
          {openConnexionInterface && (
            <div className="connexionInterface">
              <ul>
                <li
                  onClick={() => {
                    if (!authenticationRedirection())
                      setVisibleModal(!visibleModal);
                  }}
                >
                  {t("header.connexion")}
                </li>
                <li
                  onClick={() =>
                    setVisibleModalCreationCompte(!visibleModalCreationCompte)
                  }
                >
                  {t("header.compte_creation")}
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>

      {visibleModal && (
        <HeaderUserLogin
          loginVisible={visibleModal}
          setLoginVisible={setVisibleModal}
        />
      )}

      {visibleModalCharte && (
        <ModalCharte
          visibleModal={visibleModalCharte}
          setVisibleModal={setvisibleModalCharte}
        >
          <div className="description">
            <ModalCharte />
          </div>
        </ModalCharte>
      )}

      {visibleModalCreationCompte && (
        <Modal
          visible={visibleModalCreationCompte}
          setVisible={setVisibleModalCreationCompte}
          header={t("header.compte_creation")}
          width="50vw"
          blockScroll={true}
        >
          <CompteUniversite
            visibleModal={visibleModalCreationCompte}
            setVisibleModal={setVisibleModalCreationCompte}
          ></CompteUniversite>
        </Modal>
      )}
    </div>
  );
};

headerUserDisconnected.propTypes = {
  auth: PropTypes.object,
  i18n: PropTypes.object,
  handleAuth: PropTypes.func,
  handleUpdateI18n: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  i18n: state.i18n,
});

const mapDispatchToProps = (dispatch) => ({
  handleAuth: (value) => {
    dispatch(updateAuth(value));
  },
  handleUpdateI18n: (value) => {
    dispatch(updateI18n(value));
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(headerUserDisconnected);
