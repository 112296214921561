import { truncate } from "Services/functions";
import graduation from "assets/graduation-cap.png";
import facebook from "assets/logo_facebook.webp";
import instagram from "assets/logo_instagram.webp";
import linkedin from "assets/logo_linkedin.webp";
import twitter from "assets/logo_twitter.webp";
import mail from "assets/mail-icone.png";
import neutralUser from "assets/neutralUser.png";
import smartphone from "assets/smartphone-call.png";
import "primeicons/primeicons.css";
import PropTypes from "prop-types";
import React from "react";
import { FaHandsHelping } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import "./EtudiantCard.scss";

const EtudiantCard = (props) => {
  return (
    <div
      className={
        (useLocation().pathname == "/" ||
          useLocation().pathname == "/profil" ||
          useLocation().pathname == "/offres" ||
          useLocation().pathname == "/depot-offre" ||
          useLocation().pathname == "/annuaire" ||
          useLocation().pathname == "/gestion-offre") &&
        "etudiant etudiantCardHover"
      }
    >
      {/* "etudiant etudiantcardHover" */}
      {/* : "etudiant" */}
      <Link
        to={{
          pathname: `/profil-show/${props.id}`,
          params: props.id,
        }}
        title={`Voir le profil de l'étudiant ${props.firstname} ${props.surname}`}
      >
        <div className="annuaire_etudiant__image">
          <div className="annuaire_etudiant__image__info">
            <span>{props.firstname} </span>
            <span> {props.surname}</span>
          </div>

          <div className="annuaire_etudiant__image__logo">
            {
              <img
                src={props.imageUrl ? props.imageUrl : neutralUser}
                className="avatar"
                alt="Avatar de l'étudiant"
                title="Avatar de l'étudiant"
              />
            }
          </div>
        </div>
      </Link>
      <div className="annuaire_etudiant__container">
        <div className="annuaire_etudiant__container__description">
          <div className="annuaire_etudiant__container__description__title">
            <div className="annuaire_etudiant__container__description__title__item__formation">
              <h3>
                {props.profilTitle
                  ? truncate(props.profilTitle, 22)
                  : "Non renseigné"}
              </h3>
              <br />
            </div>
            {/* <div className="annuaire_etudiant__container__description__title__item">
              <label>
                <i className="pi pi-building"></i>
              </label>
              <p>
                {props.domaine.substr(0, 25)}
                {props.domaine.substr(25, 1) != null ? "" : "..."}
              </p>
            </div> */}
            <div className="annuaire_etudiant__container__description__title__item">
              <label>
                <img src={graduation} />
              </label>
              <p>Promo : {props.promo ? props.promo : "Non renseignée"}</p>
            </div>
            <div className="annuaire_etudiant__container__description__title__item">
              <label>
                <img src={mail} />
              </label>
              <p className="highlight_mail">
                {props.mailIsPublic ? (
                  props.email ? (
                    <a
                      href={`mailto:${props.email && props.email}`}
                      title={`Envoyer un email à ${props.email && props.email}`}
                    >
                      {" "}
                      {props.email}
                    </a>
                  ) : (
                    "Non renseignée"
                  )
                ) : (
                  "Indisponible"
                )}
              </p>
            </div>
            <div className="annuaire_etudiant__container__description__title__item">
              <label>
                <img src={smartphone} />
              </label>
              <p>
                {props.telephoneIsPublic
                  ? props.telephone
                    ? props.telephone
                    : "Non renseigné"
                  : "Indisponible"}
              </p>
            </div>
          </div>
          <div className="all_bottom">
            <div className="annuaire_etudiant__container__description__bottom">
              {/* {props.etudiantDetail.linkedinAccount ? ( */}
              {props.linkedinAccount && (
                <a
                  href={
                    // On vérifie si le lien commence par http
                    props.linkedinAccount.startsWith("http")
                      ? props.linkedinAccount
                      : "https://" + props.linkedinAccount
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={linkedin}
                    alt="Logo LinkedIn"
                    title="Logo LinkedIn"
                    className="img_card"
                  />
                </a>
              )}
              {/* {props.etudiantDetail.facebookAccount ? ( */}
              {props.facebookAccount && (
                <a
                  href={
                    // On vérifie si le lien commence par http
                    props.facebookAccount.startsWith("http")
                      ? props.facebookAccount
                      : "https://" + props.facebookAccount
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={facebook}
                    alt="Logo Facebook"
                    title="Logo Facebook"
                    className="img_card"
                  />
                </a>
              )}
              {/* {props.etudiantDetail.twitterAccount ? ( */}
              {props.twitterAccount && (
                <a
                  href={
                    // On vérifie si le lien commence par http
                    props.twitterAccount.startsWith("http")
                      ? props.twitterAccount
                      : "https://" + props.twitterAccount
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={twitter}
                    alt="Logo Twitter"
                    title="Logo Twitter"
                    className="img_card"
                  />
                </a>
              )}
              {/* {props.etudiantDetail.instagramAccount ? ( */}
              {props.instagramAccount && (
                <a
                  href={
                    // On vérifie si le lien commence par http
                    props.instagramAccount.startsWith("http")
                      ? props.instagramAccount
                      : "https://" + props.instagramAccount
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={instagram}
                    alt="Logo Instagram"
                    title="Logo Instagram"
                    className="img_card"
                  />
                </a>
              )}
            </div>
            {(props.parrain || props.createur) && (
              <div className="annuaire_etudiant__container__description__alumni">
                {props.parrain && (
                  <p className="parrain">
                    <FaHandsHelping />
                  </p>
                )}
                {props.createur && (
                  <p className="createur">
                    <i className="pi pi-fw pi-building" />
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

EtudiantCard.propTypes = {
  instagram: PropTypes.string,
  firstname: PropTypes.string,
  surname: PropTypes.string,
  promo: PropTypes.number,
  id: PropTypes.number,
  domaine: PropTypes.string,
  email: PropTypes.string,
  linkedinAccount: PropTypes.string,
  facebookAccount: PropTypes.string,
  twitterAccount: PropTypes.string,
  instagramAccount: PropTypes.string,
  telephone: PropTypes.string,
  profilTitle: PropTypes.string,
  parrain: PropTypes.bool,
  birthday: PropTypes.string,
  createur: PropTypes.bool,
  imageUrl: PropTypes.string,
  mailIsPublic: PropTypes.bool,
  telephoneIsPublic: PropTypes.bool,
};

export default EtudiantCard;
