import React from "react";
import PropTypes from "prop-types";
import "./ModalDesactiverCommentaires.scss";
import { connect } from "react-redux";
import { fetchPost } from "Services/api";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import Modal from "../Modal";

const ModalDesactiverCommentaires = ({ visible, setVisible, auth }) => {
  const handleAccept = async () => {
    let result = await fetchPost(
      "/publications/" + visible.id + "/disable_comments",
      "{}",
      auth.token
    );
    setVisible({
      state: false,
      type: result.availableForComments === false ? "success" : "error",
      message:
        result.availableForComments === false
          ? "Les commentaires ont été désactivés"
          : "Erreur lors de la désactivation des commentaires",
      header: "Désactivation des commentaires",
    });
  };

  const handleReject = () => {
    setVisible({ ...visible, state: false });
  };
  return (
    <div>
      <>
        <Modal
          visible={visible.state}
          setVisible={() => setVisible({ ...visible, state: false })}
          header="Désactiver les commentaires"
          height="fit-content"
          width="80%"
          className="modal modal_désactivation-commentaire"
        >
          <div className="desactiver_commentaires-content">
            <p>
              Souhaitez-vous désactiver les commentaires de cette publication ?
            </p>
            <p>Les commentaires actuels resteront visibles</p>
          </div>
          <div className="desactiver_commentaires-buttons">
            <BtnBlanc
              btnTexte="Annuler"
              action={"button"}
              btnAction={handleReject}
            />
            <BtnBleu btnAction={handleAccept} btnTexte="Désactiver" />
          </div>
        </Modal>
      </>
    </div>
  );
};

ModalDesactiverCommentaires.propTypes = {
  visible: PropTypes.object.isRequired,
  setVisible: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  groups: state.groups,
});

export default connect(mapStateToProps)(ModalDesactiverCommentaires);
