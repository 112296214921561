import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import "./GroupeFinder.scss";
import PropTypes from "prop-types";
import Header from "Components/Header/Header";
import NavBarTab from "Components/NavBar/NavBarTab/NavBarTab";
import GroupeMembership from "../GroupeMembership/GroupeMembership";
import GroupeCardFind from "../GroupeCard/GroupeCardFind/GroupeCardFind";
import { InputText } from "primereact/inputtext";
import { HiSearch } from "react-icons/hi";
import { Dropdown } from "primereact/dropdown";
import { connect } from "react-redux";
import { updateGroups } from "Redux/Actions/groupsActions";
import { isNotEmptyArray, stripHtml } from "Services/functions";
import { updatePagination } from "Redux/Actions/paginationActions";
import Loader from "Components/Loader/loader";
import { Toast } from "primereact/toast";
import ModalRejoindreGroupe from "Components/Modal/ModalRejoindreGroupe/ModalRejoindreGroupe";
import ModalDétailsGroupe from "Components/Modal/ModalDetailsGroupe/ModalDetailsGroupe";
import ModalQuitterGroupe from "Components/Modal/ModalQuitterGroupe/ModalQuitterGroupe";
import TempPagination from "Components/Pagination/TempPagination";
import { useFetchGetPagination } from "Services/api";
import { useTranslation } from "react-i18next";
import { debounce } from "../../../Services/debounce";

/**
 * Permet d'afficher la page trouver un groupe
 * Sur la gauche, se trouve la barre des groupes dont l'utilisateur est membre GroupeMembership
 * Sur la droite se trouvent des groupes avec possibilité de faire une recherche parmi ces groupes selon plusieurs critères (catégorie et visibilité)
 */
const GroupeFinder = (props) => {
  const { t } = useTranslation("common");

  const groupsContainerRef = useRef(null);
  const [filtrePrivacy, setfiltrePrivacy] = useState(null);
  const filtresStatus = [
    { label: t("profil.all_group"), value: "Tous" },
    { label: t("createGroup.public"), value: "Groupes publics" },
    { label: t("createGroup.private"), value: "Groupes privés" },
  ];

  const [categories, setCategories] = useState([]);
  const [filtreCategory, setFiltreCategory] = useState(null);
  const [filtreMotsCles, setFiltreMotsCles] = useState("");

  // ------- TOASTS --------
  // Ref pour les toasts rejoindre / détails d'un groupe / quitter groupe
  const groupJoinRef = useRef();
  const groupDetailsRef = useRef();
  const groupLeaveRef = useRef();

  // Affichage dialog si l'utilisateur veut rejoindre un groupe
  const [joinGroup, setJoinGroup] = useState({
    title: "",
    id: null,
    visible: false,
    state: null,
    result: null,
  });

  // Affichage dialog si l'utilisateur clique sur un groupe
  const [detailsGroup, setDetailsGroup] = useState({
    id: null,
    visible: false,
    state: null,
    result: null,
  });

  // Affichage dialog si l'utilisateur veut quitter un groupe
  const [leaveGroup, setLeaveGroup] = useState({
    title: "",
    id: null,
    visible: false,
    state: null,
    result: null,
  });

  // Gestion des offres de l'user affichées avec pagination
  const [updateMyGroups, setUpdateMyGroups] = useState(true);
  const myGroupsQuery = useFetchGetPagination(
    props.groups.filteringURL,
    props.auth.token,
    props.handleUpdateGroups
  );

  // Gestion des offres affichées avec pagination
  const [groupFinderUrl, setGroupFinderUrl] = useState("");
  const [updateGroups, setUpdateGroups] = useState(true);
  const groupsFinderQuery = useFetchGetPagination(
    groupFinderUrl,
    props.auth.token,
    props.handleUpdateGroups
  );

  useEffect(async () => {
    // Si une demande de rejoindre un groupe a été faite :
    if (joinGroup.result) {
      // On affiche le toast de confirmation
      if (joinGroup.result.status === 200) {
        groupJoinRef.current.show({
          severity: "success",
          summary: "Rejoindre un groupe",
          detail: joinGroup.private
            ? "Vous avez demandé à rejoindre ce groupe"
            : "Vous avez rejoint ce groupe",
          life: 5000,
        });
        //? On ferme le modal s'il est ouvert
        if (detailsGroup.visible) {
          setDetailsGroup({
            id: null,
            visible: false,
            state: null,
            result: null,
          });
        }
        // On met à jour les groupes et groupes de l'utilisateur
        setUpdateGroups(true);
        setUpdateMyGroups(true);
        // On affiche le toast d'erreur
      } else {
        groupJoinRef.current.show({
          severity: "error",
          summary: "Erreur",
          detail: joinGroup.result,
          life: 5000,
        });
      }
      // On remet à null
      setJoinGroup({
        title: "",
        id: null,
        visible: false,
        state: null,
      });
    }
  }, [joinGroup.result]);

  useEffect(async () => {
    // Si une demande de quitter un groupe a été faite :
    if (leaveGroup.result) {
      // On affiche le toast de confirmation
      if (leaveGroup.result.status === 200) {
        groupLeaveRef.current.show({
          severity: "success",
          summary: "Succès",
          detail: "Vous avez quitté ce groupe avec succès",
          life: 5000,
        });
        setUpdateGroups(true);
        setUpdateMyGroups(true);
        // On affiche le toast d'erreur
      } else {
        groupLeaveRef.current.show({
          severity: "error",
          summary: "Erreur",
          detail: leaveGroup.result,
          life: 5000,
        });
      }
    }
  }, [leaveGroup.result]);

  // ----------------------------

  const filterByCategory = (url) => {
    if (filtreCategory != null) {
      if (props.auth.isAdmin)
        url.searchParams.append("groupCategory[]", filtreCategory.id);
      else url.searchParams.append("category[]", filtreCategory.id);
    }
    return url;
  };

  const filterByPrivacy = (url) => {
    if (filtrePrivacy != null && filtrePrivacy !== "Tous") {
      if (props.auth.isAdmin)
        url.searchParams.append(
          "public",
          filtrePrivacy === "Groupes publics" ? "1" : "0"
        );
      else
        url.searchParams.append("public", filtrePrivacy === "Groupes publics");
    }
    return url;
  };

  const filterByKeyWords = (url) => {
    if (filtreMotsCles !== "") url.searchParams.append("label", filtreMotsCles);
    return url;
  };

  const handleFilter = (url) => {
    url = filterByPrivacy(url);
    url = filterByKeyWords(url);
    url = filterByCategory(url);
    return url;
  };

  const makeMyGroupsUrl = () => {
    let url = process.env.REACT_APP_BASE_URL_API;
    // Si on est admin, on récupère tous les groupes
    // Sinon, on récupère les groupes dont l'utilisateur est membre
    url += props.auth.isAdmin
      ? "/groups"
      : "/groups/member/" + props.auth.userConnected.id;
    let filteringURL = new URL(url);
    filteringURL.searchParams.append(
      "state[]",
      props.states.statesData.find((status) => status.statusLabel === "Actif")
        .id
    );
    if (!props.auth.isAdmin)
      filteringURL.searchParams.append(
        "memberState[]",
        props.states.groupMemberStatesData.find(
          (status) => status.label === "ACTIF"
        ).id
      );
    // filteringURL.searchParams.set("order[datePosted]", "desc");
    filteringURL.searchParams.set("itemsPerPage", 10);
    props.handleUpdateGroups({
      filteringURL,
    });
  };

  useMemo(() => {
    let check =
      isNotEmptyArray(props.states.statesData) &&
      isNotEmptyArray(props.states.groupMemberStatesData);
    if (updateMyGroups && check) {
      makeMyGroupsUrl();
      setUpdateMyGroups(false);
    }
  }, [
    updateMyGroups,
    props.states.statesData,
    props.states.groupMemberStatesData,
  ]);

  const makeGroupFinderUrl = () => {
    let url = process.env.REACT_APP_BASE_URL_API;
    url += props.auth.isAdmin
      ? "/groups"
      : "/groups/not_member/" + props.auth.userConnected.id;
    let filteringURL = new URL(url);
    filteringURL.searchParams.append(
      "state[]",
      props.states.statesData.find((status) => status.statusLabel === "Actif")
        .id
    );
    filteringURL.searchParams.append("itemsPerPage", 7);
    return filteringURL;
  };

  //? Remise à zéro des informations de paginations pour tout les groupes en cas de changement de filtre
  useMemo(() => {
    // Si on est admin, on récupère tous les groupes
    // Sinon, on récupère les groupes dont l'utilisateur n'est pas membre
    if (updateGroups) {
      let url = makeGroupFinderUrl();
      setGroupFinderUrl(handleFilter(url));
      setUpdateGroups(false);
    }
  }, [groupFinderUrl, updateGroups]);

  /**
   * Met à jour le lien de recherche des groupes
   * avec un debounce pour éviter de faire trop de requêtes API
   * @type {(function(...[*]): void)|*}
   */
  const updateGroupsFinderUrl = useCallback(
    debounce((url) => {
      setGroupFinderUrl(url);
    }, 500),
    []
  );

  useEffect(() => {
    let url = makeGroupFinderUrl();
    updateGroupsFinderUrl(handleFilter(url));
  }, [filtreMotsCles, filtrePrivacy, filtreCategory]);

  useEffect(() => {
    /// Récupérer les catégories, et les filtrer par ordre alphabétique
    if (isNotEmptyArray(props.groups.categories)) {
      setCategories([]);
      let tempArray = [];
      props.groups.categories.forEach((category) => tempArray.push(category));
      tempArray = tempArray.sort((a, b) => a.label.localeCompare(b.label));
      setCategories(tempArray);
    }
  }, [props.groups.categories]);

  return (
    <div>
      <Toast ref={groupJoinRef} />
      <Toast ref={groupDetailsRef} />
      <Toast ref={groupLeaveRef} />
      <Header
        activeIndex={props.activeIndex}
        setActiveIndex={props.setActiveIndex}
        items={props.items}
      >
        {props.auth.isAdmin ? (
          <NavBarTab
            select="groupesadmin"
            activeIndex={props.activeIndex}
            items={props.items}
          />
        ) : (
          <NavBarTab
            select="groupes"
            activeIndex={props.activeIndex}
            items={props.items}
          />
        )}
      </Header>

      <div className="groupe_container">
        <div className="groupe_container__title">
          <div>
            <h1 className="h1_trait_dessus">
              {t("boutonsAccueilApresConnexion.trouverGroupe")}
            </h1>
          </div>

          {/* <p> Sous-titres à définir</p> */}
          <div className="groupe_container__title__filtres">
            <span>
              <HiSearch className="searchBar_groupe_finder_icon" />
              <InputText
                placeholder={t("groupManagement.searchGroup")}
                value={filtreMotsCles}
                className="membership_input_text"
                onChange={(e) => {
                  props.handleUpdateGroups({
                    motsClés: e.target.value.toLowerCase(),
                    /// Mettre la recherche à true, seulement si au moins l'un des filtres est activé
                    isSearchedGroups:
                      e.target.value !== "" || filtreCategory != null,
                    // filtreDate != null,
                  });
                  setFiltreMotsCles(e.target.value.toLowerCase());
                }}
              />
            </span>
            <Dropdown
              value={filtrePrivacy}
              options={filtresStatus}
              showClear
              placeholder={t("groupManagement.filterGroups")}
              onChange={(e) => {
                props.handleUpdateGroups({
                  status: e.value,
                  /// Mettre la recherche à true, seulement si au moins l'un des filtres est activé
                  isSearchedGroups:
                    e.target.value !== "" ||
                    filtrePrivacy != null ||
                    filtreCategory != null,
                });
                setfiltrePrivacy(e.value);
              }}
            />
            <Dropdown
              value={filtreCategory}
              options={categories}
              showClear
              onChange={(e) => {
                props.handleUpdateGroups({
                  category: e.target.value,
                  /// Mettre la recherche à true, seulement si au moins l'un des filtres est activé
                  isSearchedGroups:
                    e.target.value !== "" ||
                    filtreCategory != null ||
                    filtrePrivacy != null,
                });
                setFiltreCategory(e.target.value);
              }}
              placeholder={t("evenementsHorsConnexion.placeholder_filter")}
            />
          </div>
        </div>

        <div className="groupe_main_section">
          <div className="groupe_container_left">
            <GroupeMembership
              from="finder"
              myGroups={myGroupsQuery}
              setLeaveGroup={setLeaveGroup}
            />
          </div>
          <div
            className="groupe_container_finder_right"
            ref={groupsContainerRef}
          >
            {groupsFinderQuery?.loaded ? (
              <React.Fragment>
                {groupsFinderQuery?.data?.map((group) => (
                  <GroupeCardFind
                    key={group.id}
                    titre={group.label}
                    id={group.id}
                    isPortalAdmin={props.auth.isAdmin}
                    category={group.groupCategory}
                    description={stripHtml(group.description)}
                    private={!group.public}
                    group={group}
                    image={group.imageUrl ? "link" : "img1"}
                    url={group.imageUrl}
                    setJoinGroup={setJoinGroup}
                    setDetailsGroup={setDetailsGroup}
                  />
                ))}
                <TempPagination
                  result={groupsFinderQuery}
                  refItemParent={groupsContainerRef}
                  rows={8}
                />
              </React.Fragment>
            ) : (
              <Loader />
            )}
          </div>
        </div>
        <ModalRejoindreGroupe visible={joinGroup} setVisible={setJoinGroup} />
        <ModalDétailsGroupe
          dataLoaded={groupsFinderQuery.loaded}
          detailsVisible={detailsGroup}
          setDetailsVisible={setDetailsGroup}
          joinVisible={joinGroup}
          setJoinVisible={setJoinGroup}
        />
        <ModalQuitterGroupe visible={leaveGroup} setVisible={setLeaveGroup} />
      </div>
    </div>
  );
};

GroupeFinder.propTypes = {
  title: PropTypes.string,
  chapeau: PropTypes.string,
  bouton: PropTypes.string,
  auth: PropTypes.object,
  users: PropTypes.object,
  items: PropTypes.object,
  activeIndex: PropTypes.number,
  setActiveIndex: PropTypes.func,
  groups: PropTypes.object,
  handleUpdateGroups: PropTypes.func,
  pagination: PropTypes.object,
  handleUpdatePagination: PropTypes.func,
  states: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
  groups: state.groups,
  users: state.users,
  pagination: state.pagination,
  states: state.states,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateGroups: (value) => {
    dispatch(updateGroups(value));
  },
  handleUpdatePagination: (value) => dispatch(updatePagination(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupeFinder);
