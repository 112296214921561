import React from "react";
import "./btn-blanc-left.scss";
import PropTypes from "prop-types";

const BtnBlanc = (props) => {
  return (
    <button
      className="btn-blanc left"
      onClick={(event) => {
        if (props.btnAction.length > 0) {
          props.btnAction(event);
        } else props.btnAction();
      }}
    >
      {props.children}
      {props.btnTexte}
    </button>
  );
};

BtnBlanc.propTypes = {
  btnAction: PropTypes.func,
  btnTexte: PropTypes.string,
  children: PropTypes.any,
};

export default BtnBlanc;
