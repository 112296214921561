import React, { useState } from "react";
import "./Header.scss";
import HeaderTop from "./HeaderTop/headerTop";
import PropTypes from "prop-types";
import NavBar from "..//NavBar/Navbar";
// import fondAlumni from "assets/fondAlumni.jpg";
import { connect } from "react-redux";

const Header = (props) => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const handleToggle = () => {
    setNavbarOpen(!navbarOpen);
  };
  return (
    <header id="header">
      <HeaderTop />
      <NavBar
        navbarOpen={navbarOpen}
        handleToggle={handleToggle}
        activeIndex={props.activeIndex}
        setActiveIndex={props.setActiveIndex}
        items={props.items}
      />
      {!(props.noImage == true && props.auth.isConnected == true) && (
        <div
          className="menu"
          // style={{
          //   backgroundImage: props.auth.isConnected
          //     ? `linear-gradient(lightgrey, grey), url(${fondAlumni})`
          //     : `linear-gradient(#5a5a5a, #5a5a5a), url(${fondAlumni})`,
          //   backgroundBlendMode: props.auth.isConnected
          //     ? "multiply"
          //     : "multiply",
          //   // backgroundColor: navbarOpen ? `backgroundColor : "#777777"` : "",
          //   // display: props.noImage ? ` none` : "",
          // }}
        >
          {props.auth.isConnected ? (
            <div className="menu__connected">{props.children}</div>
          ) : (
            <div className="menu__notConnected">{props.children}</div>
          )}
        </div>
      )}
    </header>
  );
};

Header.propTypes = {
  auth: PropTypes.object,
  children: PropTypes.any,
  items: PropTypes.object,
  activeIndex: PropTypes.number,
  setActiveIndex: PropTypes.func,

  noImage: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
