import React, { useEffect, useState } from "react";
import "./Actualites.scss";
import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import ButtonsBarDisconnected from "Components/Header/ButtonsBarDisconnected/ButtonsBarDisconnected";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ActualitesContainer from "Components/Actualites/ActualitesContainer/ActualitesContainer";
import NavBarTab from "Components/NavBar/NavBarTab/NavBarTab";
import ActualitesManagement from "Components/Actualites/ActualitesManagement/ActualitesManagement";
import ActualitesCreation from "Components/Actualites/ActualitesCreation/ActualitesCreation";
import { updateNews } from "../../Redux/Actions/newsActions";
import { useFetchGetConditional } from "../../Services/api";
import { updateStates } from "../../Redux/Actions/statesActions";

/**
 * Permet d'accéder à la page d'actualités
 * Les actus avant connexion et pour les étudiants sont similaires
 * Les alumni et personnels peuvent proposer des actus et les gérer
 * Les admins peuvent créer des actus et des conseils et les gérer
 */
const Actualites = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [_, setActiveIndex] = useState(0);
  const [filtre, setFiltre] = useState(null);
  const filtres = [
    { label: "Tous", value: "Tous" },
    { label: "Conseil", value: "Conseil" },
    { label: "Accompagnement", value: "En attente" },
    { label: "International", value: "International" },
  ];

  const dataStates = useFetchGetConditional(
    "/states",
    props.auth.token,
    props.states.statesData
  );

  useEffect(() => {
    if (dataStates.loaded && dataStates.data?.length > 0)
      props.handleUpdateStates({
        statesData: dataStates.data,
      });
  }, [dataStates.loaded]);

  useEffect(() => {
    if (props.states.statesData.length > 0) {
      const dataNewsStatusModified = props.states.statesData.map((state) => {
        return {
          id: state.id,
          statusLabel: state.statusLabel,
          name: state.statusLabel,
        };
      });
      const listOfStatus = ["Brouillon", "Refusé", "En Attente", "Publié"];

      props.handleUpdateNews({
        etats: dataNewsStatusModified.filter(
          (state) => listOfStatus.indexOf(state.name) !== -1
        ),
      });
    }
  }, [props.states.statesData.length]);

  return (
    <div className="actus">
      {props.auth.isConnected &&
        (props.auth.isAlumni || props.auth.isPersonnel) && (
          <Header noImage={false}>
            <NavBarTab
              select="actu"
              activeIndex={props.items.activeItemActu}
              items={props.items.itemsActu}
            />
          </Header>
        )}
      {props.auth.isConnected && props.auth.isAdmin && (
        <Header noImage={false}>
          <NavBarTab
            select="actuadmin"
            activeIndex={props.items.activeItemActuAdmin}
            items={props.items.itemsActuAdmin}
          />
        </Header>
      )}
      {!props.auth.isConnected && (
        <Header noImage={true}>
          <ButtonsBarDisconnected />
        </Header>
      )}

      {!props.auth.isConnected && (
        <ActualitesContainer
          filtre={filtre}
          setFiltre={setFiltre}
          filtres={filtres}
        />
      )}
      {props.auth.isConnected && (
        <>
          {/* toutes les actus et conseils ---------------------------- */}
          {(props.auth.isAlumni || props.auth.isPersonnel) &&
            props.items.activeItemActu ===
              props.items.itemsActu.findIndex(
                (v) => v.label === "Toutes les actus"
              ) && (
              <ActualitesContainer
                activeIndex={props.items.itemsActu.findIndex(
                  (v) => v.label === "Toutes les actus"
                )}
                setActiveIndex={setActiveIndex}
                items={props.items.itemsActu}
              ></ActualitesContainer>
            )}
          {props.auth.isAdmin &&
            props.items.activeItemActuAdmin ===
              props.items.itemsActuAdmin.findIndex(
                (v) => v.label === "Toutes les actus/conseils"
              ) && (
              <ActualitesContainer
                activeIndex={props.items.itemsActuAdmin.findIndex(
                  (v) => v.label === "Toutes les actus/conseils"
                )}
                setActiveIndex={setActiveIndex}
                items={props.items.itemsActuAdmin}
              />
            )}
          {/* proposer une actu ou créer une actu/conseil ---------------------- */}
          {(props.auth.isAlumni || props.auth.isPersonnel) &&
            props.items.activeItemActu ===
              props.items.itemsActu.findIndex(
                (v) => v.label === "Proposer une actu"
              ) && (
              <ActualitesCreation
                activeIndex={props.items.itemsActu.findIndex(
                  (v) => v.label === "Proposer une actu"
                )}
                setActiveIndex={setActiveIndex}
                items={props.items.itemsActu}
              />
            )}
          {props.auth.isAdmin &&
            props.items.activeItemActuAdmin ===
              props.items.itemsActuAdmin.findIndex(
                (v) => v.label === "Créer une actu/conseil"
              ) && (
              <ActualitesCreation
                activeIndex={props.items.itemsActuAdmin.findIndex(
                  (v) => v.label === "Créer une actu/conseil"
                )}
                setActiveIndex={setActiveIndex}
                items={props.items.itemsActuAdmin}
              />
            )}
          {/* gestion des actus/conseils ------------------ */}
          {(props.auth.isAlumni || props.auth.isPersonnel) &&
            props.items.activeItemActu ===
              props.items.itemsActu.findIndex(
                (v) => v.label === "Gérer mes actus"
              ) && (
              <ActualitesManagement
                activeIndex={props.items.itemsActu.findIndex(
                  (v) => v.label === "Gérer mes actus"
                )}
                setActiveIndex={setActiveIndex}
                items={props.items.itemsActu}
              />
            )}
          {props.auth.isAdmin &&
            props.items.activeItemActuAdmin ===
              props.items.itemsActuAdmin.findIndex(
                (v) => v.label === "Gérer les actus/conseils"
              ) && (
              <ActualitesManagement
                activeIndex={props.items.itemsActuAdmin.findIndex(
                  (v) => v.label === "Gérer les actus/conseils"
                )}
                setActiveIndex={setActiveIndex}
                items={props.items.itemsActuAdmin}
              />
            )}
        </>
      )}

      <Footer />
    </div>
  );
};

Actualites.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.object,
  handleUpdateNews: PropTypes.func,
  handleUpdateStates: PropTypes.func,
  news: PropTypes.object,
  ressources: PropTypes.object,
  states: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
  news: state.news,
  ressources: state.ressources,
  states: state.states,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateNews: (value) => dispatch(updateNews(value)),
  handleUpdateStates: (value) => dispatch(updateStates(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Actualites);
