import { RECRUTEUR } from "Components/Management/ManagementButtons";
import { formatContrat, truncate } from "Services/functions";
import recruteursImage from "assets/recruteurs.png";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import GestionDepotOffreActions from "../GestionDepotOffreActions/GestionDepotOffreActions";
import "./GestionDepotOffreCard.scss";

const GestionDepotOffreCard = (props) => {
  const API_LINK = process.env.REACT_APP_BASE_URL_API.split("/api")[0];

  /**
   * Permet de renvoyer l'url du logo de l'entreprise soit par l'url de notre API, soit par clearbit
   * @param {{companyImageUrl: null|String, companyUrl: null|String}} offre
   * @returns {String} url du logo de l'entreprise
   */
  const findCompanyLogo = (offre) => {
    // Si l'offre a un logo on l'utilise
    if (offre?.companyImageUrl || offre?.company?.imageUrl)
      return offre.companyImageUrl || offre.company.imageUrl;
    if (!offre?.companyUrl && !offre?.company?.companyUrl)
      return recruteursImage;
    // Sinon on utilise clearbit pour trouver le logo de l'entreprise
    try {
      const url = new URL(`${offre?.companyUrl || offre?.company?.companyUrl}`);
      return `https://logo.clearbit.com/${url.hostname}`;
    } catch (e) {
      return recruteursImage;
    }
  };

  const findLogo = (offre) => {
    if (offre?.company?.id || offre?.companyUrl) return findCompanyLogo(offre);
    const logo =
      offre.university?.imageTransparentAndColorUrl ||
      props.universities?.universitiesData?.find(
        (university) => university.slug === offre.universitySlug
      )?.imageTransparentAndColorUrl ||
      "";

    if (!offre?.transmitter?.name && offre.company) {
      return API_LINK + (offre.company?.logoLink || logo);
    }

    switch (offre.transmitter?.name) {
      case "Diffuseur":
        return props.secondaryTables.transmittersData.find(
          (transmitter) => transmitter.name === "Diffuseur"
        )?.imageUrl;
      case "Etudiant":
        return API_LINK + logo;
      case "Admin":
        return props.secondaryTables.transmittersData
          ? props.secondaryTables.transmittersData.find(
              (transmitter) => transmitter.name === "Admin"
            ).imageUrl
          : API_LINK + logo;
      case "Université":
        return API_LINK + logo;
      case "Recruteur":
        return API_LINK + (offre?.company?.logoLink || logo);
      case "Personnel":
        return API_LINK + logo;
      case "Alumni":
        return API_LINK + logo;
      default:
        return recruteursImage;
    }
  };

  /**
   * Vérifie si l'offre doit être récupérée depuis le endpoint recruteur ou non
   * @param {{state: {statusLabel: String}}} offreDetail - Détails de l'offre
   * @returns {Boolean} - true si l'offre doit être récupérée depuis le endpoint recruteur
   */
  const isFromRecruteur = (offreDetail) => {
    //! Gestion d'erreur
    if (
      !props.offers.currentTransmitter?.length ||
      !offreDetail?.state?.statusLabel
    )
      return false;
    // On vérifie si le transmetteur actuel est Recruteur ou non
    if (props.offers?.currentTransmitter[0] !== RECRUTEUR) return false;

    // Si oui, on vérifie le status de l'offre
    switch (offreDetail.state.statusLabel) {
      //? Si l'offre est publié ou archivé, elle est forcément récupérée depuis UNIV
      case "Publié":
      case "Archivé":
        return false;
      //* Sinon En attente ou Refusé, elle est récupérée depuis le endpoint recruteur
      case "En Attente":
      case "Refusé":
        return true;
      default:
        break;
    }
    //? Si aucune condition n'est remplie, on retourne false
    return false;
  };

  const isRecruteur = isFromRecruteur(props.offre);

  return (
    <div className="gestion_offre_container">
      <div className="gestion_offre_container__logo">
        <div className="logo__card__gestion">
          <img
            src={findLogo(props.offre)}
            alt="Logo"
            title="Logo entreprise"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = recruteursImage;
            }}
          />{" "}
          <div
            className="image__bg"
            style={{ backgroundImage: `url(${findLogo(props.offre)})` }}
          ></div>
        </div>
        <GestionDepotOffreActions
          statut={props.statut}
          id={props.id}
          offre={props.offre}
          feedback={props.feedback}
          secondClass="mobile"
          isRecruteur={isRecruteur}
        ></GestionDepotOffreActions>
      </div>
      <Link
        to={{
          pathname: `/offre-detail/${props.id}`,
          search: `?r=${isRecruteur}`,
        }}
      >
        <div className="gestion_offre_container__info">
          <h5 className="title" title={props?.titre}>
            {props.titre ? (
              <>{props?.titre && truncate(props?.titre, 45)}</>
            ) : (
              "Titre non renseigné"
            )}
          </h5>
          <div className="gestion_offre_container__info__bottom">
            <h2>
              <i className="pi pi-building" title={props?.entreprise}></i>{" "}
              {truncate(props.entreprise, 30)}
            </h2>
            <h2>
              <i className="pi pi-map-marker" title={props?.lieu}></i>{" "}
              {truncate(props.lieu, 27)}
            </h2>

            <h2>
              <i
                className="pi pi-briefcase"
                title="Type de contrat ou catégorie de l'offre"
              ></i>{" "}
              {formatContrat(props.contrat)}
            </h2>

            <h2>
              <i
                className="pi pi-id-card"
                title="Niveau d'éducation demandé"
              ></i>{" "}
              {props.niveau}
            </h2>
          </div>
          <div className="description">
            <span>
              {props?.description && truncate(props?.description, 170)}
            </span>
          </div>
        </div>
      </Link>

      <div className="gestion_offre_container__bouton">
        <GestionDepotOffreActions
          statut={props.statut}
          id={props.id}
          offre={props.offre}
          isRecruteur={isRecruteur}
          feedback={props.feedback}
          secondClass="desktop"
        ></GestionDepotOffreActions>
        {props.statut.statusLabel === "Archivé" && (
          <span className="desactive">Archivée</span>
        )}
        {props.statut.statusLabel === "Publié" && (
          <span className="actif">Publiée</span>
        )}
        {props.statut.statusLabel === "En Attente" && (
          <span className="attente">En attente</span>
        )}
        {props.statut.statusLabel === "Refusé" && (
          <span className="refuse">Refusée</span>
        )}
        {props.statut.statusLabel === "Brouillon" && (
          <span className="brouillon">Brouillon</span>
        )}
      </div>
    </div>
  );
};

GestionDepotOffreCard.propTypes = {
  titre: PropTypes.string,
  lieu: PropTypes.string,
  contrat: PropTypes.string,
  niveau: PropTypes.string,
  statut: PropTypes.object,
  description: PropTypes.string,
  image: PropTypes.string,
  entreprise: PropTypes.string,
  id: PropTypes.number,
  offre: PropTypes.object,
  feedback: PropTypes.string,
  secondaryTables: PropTypes.object,
  universities: PropTypes.object,
  offers: PropTypes.object,
};

const mapStateToProps = (state) => ({
  secondaryTables: state.secondaryTables,
  universities: state.universities,
  offers: state.offers,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GestionDepotOffreCard);
