import React, { useState, useEffect } from "react";
import "./Parametrage.scss";
import Footer from "Components/Footer/Footer";
import Header from "Components/Header/Header";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import GestionEditoriale from "Components/GestionAdmin/GestionEditoriale/GestionEditoriale";
import NavBarTab from "Components/NavBar/NavBarTab/NavBarTab";
import Requetes from "Components/GestionAdmin/Requetes/Requetes";
import GestionMembres from "Components/GestionAdmin/GestionMembres/GestionMembres";
import ParametresSite from "Components/GestionAdmin/ParametresSite/ParametresSite";
import Stats from "Components/Stats/Stats";
import { useFetchGet } from "Services/api";
import { updateAdmin } from "Redux/Actions/adminActions";

/**
 * Page qui redirige vers les composants en fonction du TabMenu
 * Accessible uniquement pour les administrateurs
 */
const Parametrage = (props) => {
  const [checked, setChecked] = useState(false);

  const users = useFetchGet("/users");

  useEffect(() => {
    if (users.loaded && users.data && users.data.length > 0) {
      props.handleUpdateAdmin({
        users: users.data,
      });
    }
  }, [users.loaded]);

  return (
    <div className="profil_edit">
      {!props.auth.userConnected?.userRoles.some(
        (role) => role.roleName == "ROLE_ADMIN_DU_PORTAIL"
      ) && <Header />}
      {props.auth.userConnected?.userRoles.some(
        (role) => role.roleName == "ROLE_ADMIN_DU_PORTAIL"
      ) && (
        <>
          <Header>
            <div className="profilPage__headerProfilBar">
              {props.auth.isConnected && (
                <>
                  {props.items.activeItemParametrage ==
                    props.items.itemsParametrage.findIndex(
                      (v) => v.label == "Requêtes et exports"
                    ) && (
                    <NavBarTab
                      // id="mobile"
                      select="parametrage"
                      activeIndex={props.items.activeItemParametrage}
                      items={props.items.itemsParametrage}
                    />
                  )}
                  {props.items.activeItemParametrage ==
                    props.items.itemsParametrage.findIndex(
                      (v) => v.label == "Gestion des membres"
                    ) && (
                    <NavBarTab
                      // id="mobile"
                      select="parametrage"
                      activeIndex={props.items.activeItemParametrage}
                      items={props.items.itemsParametrage}
                    />
                  )}
                  {props.items.activeItemParametrage ==
                    props.items.itemsParametrage.findIndex(
                      (v) => v.label == "Gestion éditoriale"
                    ) && (
                    <NavBarTab
                      // id="mobile"
                      select="parametrage"
                      activeIndex={props.items.activeItemParametrage}
                      items={props.items.itemsParametrage}
                    />
                  )}
                  {props.items.activeItemParametrage ==
                    props.items.itemsParametrage.findIndex(
                      (v) => v.label == "Paramètres du site"
                    ) && (
                    <NavBarTab
                      // id="mobile"
                      select="parametrage"
                      activeIndex={props.items.activeItemParametrage}
                      items={props.items.itemsParametrage}
                    />
                  )}
                  {props.items.activeItemParametrage ==
                    props.items.itemsParametrage.findIndex(
                      (v) => v.label == "Statistiques"
                    ) && (
                    <NavBarTab
                      // id="mobile"
                      select="parametrage"
                      activeIndex={props.items.activeItemParametrage}
                      items={props.items.itemsParametrage}
                    />
                  )}
                </>
              )}
            </div>
          </Header>
          {props.auth.isConnected && (
            <>
              {props.items.activeItemParametrage ==
                props.items.itemsParametrage.findIndex(
                  (v) => v.label == "Requêtes et exports"
                ) && (
                <Requetes
                  activeIndex={props.items.itemsParametrage.findIndex(
                    (v) => v.label == "Requêtes et exports"
                  )}
                  items={props.items.itemsParametrage}
                  checked={checked}
                  setChecked={setChecked}
                />
              )}
              {props.items.activeItemParametrage ==
                props.items.itemsParametrage.findIndex(
                  (v) => v.label == "Gestion des membres"
                ) && (
                <GestionMembres
                  activeIndex={props.items.itemsParametrage.findIndex(
                    (v) => v.label == "Gestion des membres"
                  )}
                  items={props.items.itemsParametrage}
                  checked={checked}
                  setChecked={setChecked}
                />
              )}
              {props.items.activeItemParametrage ==
                props.items.itemsParametrage.findIndex(
                  (v) => v.label == "Gestion éditoriale"
                ) && (
                <GestionEditoriale
                  activeIndex={props.items.itemsParametrage.findIndex(
                    (v) => v.label == "Gestion éditoriale"
                  )}
                  items={props.items.itemsParametrage}
                  checked={checked}
                  setChecked={setChecked}
                />
              )}
              {props.items.activeItemParametrage ==
                props.items.itemsParametrage.findIndex(
                  (v) => v.label == "Paramètres du site"
                ) && (
                <ParametresSite
                  activeIndex={props.items.itemsParametrage.findIndex(
                    (v) => v.label == "Paramètres du site"
                  )}
                  items={props.items.itemsParametrage}
                  checked={checked}
                  setChecked={setChecked}
                />
              )}
              {props.items.activeItemParametrage ==
                props.items.itemsParametrage.findIndex(
                  (v) => v.label == "Statistiques"
                ) && (
                <Stats
                  activeIndex={props.items.itemsParametrage.findIndex(
                    (v) => v.label == "Statistiques"
                  )}
                  items={props.items.itemsParametrage}
                  checked={checked}
                  setChecked={setChecked}
                />
              )}
            </>
          )}
        </>
      )}
      <Footer />
    </div>
  );
};

Parametrage.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.object,
  admin: PropTypes.object,
  handleUpdateAdmin: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
  admin: state.admin,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateAdmin: (value) => dispatch(updateAdmin(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Parametrage);
