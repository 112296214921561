import React, { useEffect } from "react";
import { Carousel } from "react-bootstrap";
import "./CarouselEvent.scss";
import PropTypes from "prop-types";
import { useState } from "react";
import { Link } from "react-router-dom";
import Evenement from "Components/Evenements/EvenementCard/EvenementCard";
import { formatDateLocale } from "Services/functions";
import { connect } from "react-redux";
import Loader from "Components/Loader/loader";
import { useTranslation } from "react-i18next";
import HeaderUserLogin from "Components/Header/HeaderTop/HeaderUserLogin/HeaderUserLogin";
import { authenticationRedirection } from "Services/redirections";

const CarouselEvent = (props) => {
  const [loginVisible, setLoginVisible] = useState(false);
  const [clickedEventId, setClickedEventId] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { t } = useTranslation("common");

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const indexForCaroussel = (index, place) => {
    let multiplyer = 3;
    if (windowWidth < 1600) multiplyer = 2;
    if (windowWidth < 1200) multiplyer = 1;

    return index > (place - 1) * multiplyer - 1 && index < place * multiplyer;
  };

  const multiplyerForCaroussel = (place) => {
    let multiplyer = 3;
    if (windowWidth < 1600) multiplyer = 2;
    if (windowWidth < 1200) multiplyer = 1;

    return multiplyer * place;
  };

  return (
    <div>
      <div className="evenements_container__top">
        <div>
          <div className="evenements_container__top__title">
            <h1 className="h1_trait_dessus">
              {props.auth.isConnected
                ? t("evenements.title")
                : t("evenementsHorsConnexion.title_accueil")}
            </h1>
          </div>
        </div>
      </div>

      {/* Event, hors connexion mobile => sans carousel */}
      <div className="noCarouselEvent">
        {props.events?.dataFromPagination?.loaded ? (
          props.events.dataFromPagination.data &&
          props.events.dataFromPagination.data.map(
            (event, index) =>
              index < 2 &&
              (props.auth.userConnected || event.public ? (
                <Link
                  to={{
                    pathname: `/event-detail/${event.id}`,
                    params: event.id,
                  }}
                  key={index}
                >
                  <Evenement
                    key={index}
                    categorie={event.category}
                    // pas en bdd donc valeur par défaut
                    inscription={event.fulfilled}
                    debutDate={
                      (props.i18n.language === "en" ? "From " : "Du ") +
                      formatDateLocale(event.startingAt, props.i18n.language)
                    }
                    finDate={
                      (props.i18n.language === "en" ? "to " : "au ") +
                      formatDateLocale(event.endingAt, props.i18n.language)
                    }
                    title={event.title}
                    chapeau={event.description}
                    eventDetails={event}
                    url={event?.imageUrl ? event.imageUrl : "event1"}
                    registerEnd={event.registerEnd}
                  />
                </Link>
              ) : (
                <Evenement
                  key={index}
                  categorie={event.category}
                  // pas en bdd donc valeur par défaut
                  inscription={event.fulfilled}
                  debutDate={
                    (props.i18n.language === "en" ? "From " : "Du ") +
                    formatDateLocale(event.startingAt, props.i18n.language)
                  }
                  finDate={
                    (props.i18n.language === "en" ? "to " : "au ") +
                    formatDateLocale(event.endingAt, props.i18n.language)
                  }
                  title={event.title}
                  chapeau={event.description}
                  eventDetails={event}
                  url={event?.imageUrl ? event.imageUrl : "event1"}
                  registerEnd={event.registerEnd}
                />
              ))
          )
        ) : (
          <Loader />
        )}
        <div className="carouselEvent-button">
          <button className="btn-bleu"> Voir tous les événements</button>
        </div>
      </div>

      <div className="carouselDesktopEvent">
        <div className="logo_container">
          <div className="logos_univ">
            {props.events?.dataFromPagination?.loaded ? (
              <Carousel style={{ width: "100%" }}>
                <Carousel.Item className="carousel_event_accueil_alumni">
                  <div className="item-carousel">
                    {props.events.dataFromPagination.data ? (
                      props.events.dataFromPagination?.data.map(
                        (event, index) =>
                          indexForCaroussel(index, 1) &&
                          (props.auth.userConnected || event.public ? (
                            <Link
                              to={{
                                pathname: `/event-detail/${event.id}`,
                                params: event.id,
                              }}
                              key={index}
                            >
                              <Evenement
                                key={index}
                                categorie={event.category}
                                // pas en bdd donc valeur par défaut
                                inscription={event.fulfilled}
                                debutDate={
                                  (props.i18n.language === "en"
                                    ? "From "
                                    : "Du ") +
                                  formatDateLocale(
                                    event.startingAt,
                                    props.i18n.language
                                  )
                                }
                                finDate={
                                  (props.i18n.language === "en"
                                    ? "to "
                                    : "au ") +
                                  formatDateLocale(
                                    event.endingAt,
                                    props.i18n.language
                                  )
                                }
                                title={event.title}
                                chapeau={event.description}
                                eventDetails={event}
                                url={
                                  event?.imageUrl ? event.imageUrl : "event1"
                                }
                                registerEnd={event.registerEnd}
                              />
                            </Link>
                          ) : (
                            <Evenement
                              key={index}
                              categorie={event.category}
                              // pas en bdd donc valeur par défaut
                              inscription={event.fulfilled}
                              debutDate={
                                (props.i18n.language === "en"
                                  ? "From "
                                  : "Du ") +
                                formatDateLocale(
                                  event.startingAt,
                                  props.i18n.language
                                )
                              }
                              finDate={
                                (props.i18n.language === "en" ? "to " : "au ") +
                                formatDateLocale(
                                  event.endingAt,
                                  props.i18n.language
                                )
                              }
                              title={event.title}
                              chapeau={event.description}
                              eventDetails={event}
                              url={event?.imageUrl ? event.imageUrl : "event1"}
                              registerEnd={event.registerEnd}
                              onClick={() => {
                                if (!authenticationRedirection()) {
                                  setLoginVisible(true);
                                  setClickedEventId(event.id);
                                }
                              }}
                            />
                          ))
                      )
                    ) : (
                      <React.Fragment />
                    )}
                  </div>
                </Carousel.Item>
                {props.events?.dataFromPagination?.data?.length >
                  multiplyerForCaroussel(1) && (
                  <Carousel.Item className="carousel_event_accueil_alumni">
                    <div className="item-carousel">
                      {props.events.dataFromPagination?.data.map(
                        (event, index) =>
                          indexForCaroussel(index, 2) &&
                          (props.auth.userConnected || event.public ? (
                            <Link
                              to={{
                                pathname: `/event-detail/${event.id}`,
                                params: event.id,
                              }}
                              key={index}
                            >
                              <Evenement
                                key={index}
                                categorie={event.category}
                                // pas en bdd donc valeur par défaut
                                inscription={event.fulfilled}
                                debutDate={
                                  (props.i18n.language === "en"
                                    ? "From "
                                    : "Du ") +
                                  formatDateLocale(
                                    event.startingAt,
                                    props.i18n.language
                                  )
                                }
                                finDate={
                                  (props.i18n.language === "en"
                                    ? "to "
                                    : "au ") +
                                  formatDateLocale(
                                    event.endingAt,
                                    props.i18n.language
                                  )
                                }
                                title={event.title}
                                chapeau={event.description}
                                eventDetails={event}
                                url={
                                  event?.imageUrl ? event.imageUrl : "event1"
                                }
                                registerEnd={event.registerEnd}
                              />
                            </Link>
                          ) : (
                            <Evenement
                              key={index}
                              categorie={event.category}
                              // pas en bdd donc valeur par défaut
                              inscription={event.fulfilled}
                              debutDate={
                                (props.i18n.language === "en"
                                  ? "From "
                                  : "Du ") +
                                formatDateLocale(
                                  event.startingAt,
                                  props.i18n.language
                                )
                              }
                              finDate={
                                (props.i18n.language === "en" ? "to " : "au ") +
                                formatDateLocale(
                                  event.endingAt,
                                  props.i18n.language
                                )
                              }
                              title={event.title}
                              chapeau={event.description}
                              eventDetails={event}
                              url={event?.imageUrl ? event.imageUrl : "event1"}
                              registerEnd={event.registerEnd}
                              onClick={() => {
                                if (!authenticationRedirection()) {
                                  setLoginVisible(true);
                                  setClickedEventId(event.id);
                                }
                              }}
                            />
                          ))
                      )}
                    </div>
                  </Carousel.Item>
                )}
                {props.events?.dataFromPagination?.data?.length >
                  multiplyerForCaroussel(2) && (
                  <Carousel.Item className="carousel_event_accueil_alumni">
                    <div className="item-carousel">
                      {props.events.dataFromPagination?.data.map(
                        (event, index) =>
                          indexForCaroussel(index, 3) &&
                          (props.auth.userConnected || event.public ? (
                            <Link
                              to={{
                                pathname: `/event-detail/${event.id}`,
                                params: event.id,
                              }}
                              key={index}
                            >
                              <Evenement
                                key={index}
                                categorie={event.category}
                                // pas en bdd donc valeur par défaut
                                inscription={event.fulfilled}
                                debutDate={
                                  (props.i18n.language === "en"
                                    ? "From "
                                    : "Du ") +
                                  formatDateLocale(
                                    event.startingAt,
                                    props.i18n.language
                                  )
                                }
                                finDate={
                                  (props.i18n.language === "en"
                                    ? "to "
                                    : "au ") +
                                  formatDateLocale(
                                    event.endingAt,
                                    props.i18n.language
                                  )
                                }
                                title={event.title}
                                chapeau={event.description}
                                eventDetails={event}
                                url={
                                  event?.imageUrl ? event.imageUrl : "event1"
                                }
                                registerEnd={event.registerEnd}
                              />
                            </Link>
                          ) : (
                            <Evenement
                              key={index}
                              categorie={event.category}
                              // pas en bdd donc valeur par défaut
                              inscription={event.fulfilled}
                              debutDate={
                                (props.i18n.language === "en"
                                  ? "From "
                                  : "Du ") +
                                formatDateLocale(
                                  event.startingAt,
                                  props.i18n.language
                                )
                              }
                              finDate={
                                (props.i18n.language === "en" ? "to " : "au ") +
                                formatDateLocale(
                                  event.endingAt,
                                  props.i18n.language
                                )
                              }
                              title={event.title}
                              chapeau={event.description}
                              eventDetails={event}
                              url={event?.imageUrl ? event.imageUrl : "event1"}
                              registerEnd={event.registerEnd}
                              onClick={() => {
                                if (!authenticationRedirection()) {
                                  setLoginVisible(true);
                                  setClickedEventId(event.id);
                                }
                              }}
                            />
                          ))
                      )}
                    </div>
                  </Carousel.Item>
                )}
              </Carousel>
            ) : (
              <Loader />
            )}
          </div>
          <div className="carouselEvent-button">
            <button
              className="btn-bleu"
              onClick={() => (window.location.href = "/evenements")}
            >
              {" "}
              {t("evenementsHorsConnexion.button")}
            </button>
          </div>
        </div>
      </div>
      {loginVisible && (
        <HeaderUserLogin
          loginVisible={loginVisible}
          setLoginVisible={setLoginVisible}
          redirect={clickedEventId ? `/event-detail/${clickedEventId}` : null}
        />
      )}
    </div>
  );
};

CarouselEvent.propTypes = {
  pageEvents: PropTypes.string,
  title: PropTypes.string,
  events: PropTypes.any,
  auth: PropTypes.object,
  i18n: PropTypes.object,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  events: state.events,
  i18n: state.i18n,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CarouselEvent);
