import React from "react";
import "./ProfilBarShowApercu.scss";
import {
  FaMapMarkerAlt,
  FaRegCalendar,
  FaRegEnvelope,
  FaRegAddressBook,
  FaHandsHelping,
} from "react-icons/fa";
import facebook_logo from "assets/logo_facebook.webp";
import linkedin_logo from "assets/logo_linkedin.webp";
import twitter_logo from "assets/logo_twitter.webp";
import instagram_logo from "assets/logo_instagram.webp";
import PropTypes from "prop-types";
import { formatDate, truncate } from "Services/functions";
import neutralUser from "assets/neutralUser.png";

const ProfilBarShowApercu = ({ currentUser, image }) => {
  return (
    <div className="profilBarShowApercu">
      <div className="profilBarShowApercu__top">
        <div className="profilBarShowApercu__top__image">
          <img src={image || currentUser.imageUrl || neutralUser} />
        </div>
        <div className="profilBarShowApercu__top__middle">
          <h2>
            {currentUser?.firstname} {currentUser?.surname}
          </h2>
          <h3>{currentUser?.profilTitle}</h3>
          <p>
            {currentUser.profilDescription && (
              <>{truncate(currentUser?.profilDescription, 350)}</>
            )}
          </p>
          {currentUser.mentorAccept && (
            <div className="profilBarShowApercu__top__middle__parrain">
              <p>
                <FaHandsHelping /> Parrain/Marraine
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="profilBarShowApercu__bottom">
        <div className="profilBarShowApercu__bottom__item">
          <label>
            <FaRegCalendar />
            Date de naissance :
          </label>
          <p>
            {currentUser.birthday
              ? formatDate(currentUser.birthday)
              : "Non renseignée"}
          </p>
        </div>
        <div className="profilBarShowApercu__bottom__item">
          <label>
            <FaMapMarkerAlt />
            Adresse postale :
          </label>
          <p>
            {currentUser.address ? currentUser.address : "Non renseignée"},{" "}
            {currentUser.city ? currentUser.city : "Non renseignée"},{" "}
            {currentUser.country ? currentUser.country : "Non renseigné"}
          </p>
        </div>
        <div className="profilBarShowApercu__bottom__item">
          <label>
            <FaRegEnvelope />
            Adresse mail :
          </label>
          <p>{currentUser.email ? currentUser.email : "Non renseignée"}</p>
        </div>
        <div className="profilBarShowApercu__bottom__item">
          <label>
            <FaRegAddressBook />
            Tel :
          </label>
          <p>
            {currentUser.telephone ? currentUser.telephone : "Non renseigné"}
          </p>
        </div>
        <div className="profilBarShowApercu__bottom__logos">
          {currentUser.facebookAccount && (
            <a
              href={
                // On vérifie si le lien commence par http ou https
                currentUser.facebookAccount.startsWith("http")
                  ? currentUser.facebookAccount
                  : "https://" + currentUser.facebookAccount
              }
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebook_logo} />
            </a>
          )}
          {currentUser.linkedinAccount && (
            <a
              href={
                // On vérifie si le lien commence par http ou https
                currentUser.linkedinAccount.startsWith("http")
                  ? currentUser.linkedinAccount
                  : "https://" + currentUser.linkedinAccount
              }
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedin_logo} />
            </a>
          )}
          {currentUser.twitterAccount && (
            <a
              href={
                // On vérifie si le lien commence par http ou https
                currentUser.twitterAccount.startsWith("http")
                  ? currentUser.twitterAccount
                  : "https://" + currentUser.twitterAccount
              }
              target="_blank"
              rel="noreferrer"
            >
              <img src={twitter_logo} />
            </a>
          )}
          {currentUser.instagramAccount && (
            <a
              href={
                // On vérifie si le lien commence par http ou https
                currentUser.instagramAccount.startsWith("http")
                  ? currentUser.instagramAccount
                  : "https://" + currentUser.instagramAccount
              }
              target="_blank"
              rel="noreferrer"
            >
              <img src={instagram_logo} />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

ProfilBarShowApercu.propTypes = {
  currentUser: PropTypes.object,
  image: PropTypes.string,
};

export default ProfilBarShowApercu;
