import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./ModalCharteGroupes.scss";
import { connect } from "react-redux";
import Modal from "../Modal";
import { Checkbox } from "primereact/checkbox";
import { BsExclamationTriangle } from "react-icons/bs";
import axios from "axios";
import { updateAuth } from "Redux/Actions/authActions";
import { fetchGet } from "Services/api";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { HTMLParseOptions } from "Services/htmlParseOptions";

const ModalRejoindreGroupe = (props) => {
  const [charteValidation, setCharteValidation] = useState(true);
  const [displayCharte, setDisplayCharte] = useState(false);

  const [charte, setCharte] = useState("");
  const { t } = useTranslation("common");

  useEffect(async () => {
    setCharte(await fetchGet("/groups/charte", props.auth.token));
  }, []);

  const acceptCharte = () => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL_API}/users/${props.auth.userConnected.id}`,
        { charteGroupSigned: true },
        {
          headers: props.auth.token
            ? {
                accept: "application/json",
                Authorization: `Bearer ${props.auth.token}`,
              }
            : {
                accept: "application/json",
              },
        }
      )
      .then((res) => {
        props.handleAuth({
          userConnected: res.data,
        });
        props.setvisibleModalCharte(false);
      })
      .catch((error) => {
        console.log(error);
        props.setvisibleModalCharte(false);
        props.btnRefuse();
      });
  };
  return (
    <Modal
      visible={props.visibleModalCharte}
      setVisible={props.setvisibleModalCharte}
      header={t("groupCharter.title")}
      width="60%"
    >
      <div className="charte_groupes_container">
        <BsExclamationTriangle className="charte_groupes_container_exclamation"></BsExclamationTriangle>
        <p className="charte_groupes_container_text">
          {t("groupCharter.text")}
        </p>
        <a
          className="charte_groupes_container_display"
          onClick={() => {
            setDisplayCharte(!displayCharte);
          }}
        >
          {displayCharte
            ? t("groupCharter.toggleOff")
            : t("groupCharter.toggleOn")}
        </a>
        {displayCharte && (
          <p className="charte_groupes_container_charte">
            <div>{parse(charte, HTMLParseOptions)}</div>
          </p>
        )}

        <div className="charte_groupes_container_conditions">
          <div className="charte_groupes_container_conditions_checkbox">
            <Checkbox
              checked={charteValidation}
              onChange={() => setCharteValidation(!charteValidation)}
            />
          </div>
          <label
            htmlFor="charte_groupes_container_conditions_checkbox"
            className="charte_groupes_container_conditions_label"
          >
            {t("groupCharter.acceptDetail")}
          </label>
        </div>
        <button
          className="charte_groupes_container_validate btn-bleu"
          onClick={() => {
            if (charteValidation) {
              props.btnAccept();
              acceptCharte();
            } else {
              props.btnRefuse();
              props.setvisibleModalCharte(false);
            }
          }}
        >
          {charteValidation
            ? t("groupCharter.accept")
            : t("groupCharter.decline")}
        </button>
      </div>
    </Modal>
  );
};

ModalRejoindreGroupe.propTypes = {
  visibleModalCharte: PropTypes.object,
  setvisibleModalCharte: PropTypes.func,
  btnAccept: PropTypes.func,
  btnRefuse: PropTypes.func,
  auth: PropTypes.object,
  handleAuth: PropTypes.func,
  token: PropTypes.string,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  handleAuth: (value) => {
    dispatch(updateAuth(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalRejoindreGroupe);
