import React, { useEffect, useRef, useState } from "react";
import "./OffresContainer.scss";
import Offre from "../Offre/Offre";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updatePagination } from "Redux/Actions/paginationActions";
// import Pagination from "Components/Pagination/Pagination";
import { FaChevronRight } from "react-icons/fa";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import OffreSearchBar from "Components/Offres/OffreSearchBar/OffreSearchBar";
import { Accordion, AccordionTab } from "primereact/accordion";
import { formatDate } from "Services/functions";
import { useFetchGetPagination } from "Services/api";
import { updateOffers } from "Redux/Actions/offersActions";
import { updateMenu } from "Redux/Actions/menuActions";
import { updateAuth } from "Redux/Actions/authActions";
import TempPagination from "Components/Pagination/TempPagination";
import Loader from "Components/Loader/loader";
import { useTranslation } from "react-i18next";
import { authenticationRedirection } from "Services/redirections";
import { useLocation } from "react-router-dom";
/**
 * permet d'afficher un container avec les offres d'emploi de l'api
 * si un nombre d'offres est saisi dans les props, la pagination ne sera pas affichée, un bouton la remplacera
 * et seulement le nombre d'offres saisi sera affiché
 * permet d'avoir un affichage différent pour l'accueil et les autres pages d'offres
 * Les offres affichées doivent obligatoirement avoir le label "publiée" dans leur objet offer_status_id
 * La vérification se fait grâce à des filters directement avant la fonction array.prototype.map()
 */
const OffresContainer = (props) => {
  const { t } = useTranslation("common");
  const [offersUrl, setOffersUrl] = useState(null);

  // Gestion des offres affichées avec pagination
  const offersQuery = useFetchGetPagination(
    offersUrl,
    props.auth.token,
    props.handleUpdateOffers
  );

  const location = useLocation();

  const offresContainerRef = useRef(null);

  useEffect(() => {
    return () => {
      props.handleUpdateOffers({
        dataFromPagination: null,
      });
    };
  }, []);

  useEffect(() => {
    if (props.states.statesData.length > 0) {
      const params = new URLSearchParams(location.search);
      const keyword = params.get("keyword");
      let url = new URL(`${process.env.REACT_APP_BASE_URL_API}/offers`);
      url.searchParams.set("page", 1);

      if (props.offers.currentSearch !== "") {
        url.search = props.offers.currentSearch.search;
        props.handleUpdateOffers({ isSearched: true });
      } else {
        url.searchParams.set("order[modifiedAt]", "desc");
        url.searchParams.append(
          "state",
          props.states.statesData.find(
            (status) => status.statusLabel === "Publié"
          ).id
        );
      }

      if (keyword) {
        url.searchParams.set("keyword", keyword);
        props.handleUpdateOffers({ isSearched: true, motsClés: keyword });
        window.history.replaceState({}, "", location.pathname);
      }
      setOffersUrl(url);
    }
  }, [props.states.statesData.length]);

  return (
    <div className="article_offre" id="article_offre">
      <div className="article_offre__container">
        <h1 className="h1_trait_dessus">{props.title}</h1>
        {props.subTitle && <p className="sous_titre_offre">{props.subTitle}</p>}
        {!props.nbOffre &&
          (!props.offers.isSearched ? (
            <p>
              {offersQuery?.totalItems > 0
                ? t("offresApresConnexionPage.xLatest", {
                    x: offersQuery.totalItems,
                  })
                : t("offresApresConnexionPage.noOffers")}
            </p>
          ) : (
            offersQuery && (
              <p>
                {t("offresApresConnexion.xMatches", {
                  x: offersQuery.totalItems,
                })}
              </p>
            )
          ))}
      </div>
      {!props.nbOffre && (
        <>
          <div className="offre_search_bar__filtres">
            <OffreSearchBar id="navbar_desktop" setUrl={setOffersUrl} />
          </div>
          <Accordion
            expandIcon="pi pi-sliders-h"
            collapseIcon="pi pi-sliders-h"
          >
            <AccordionTab header="Fitres de recherche">
              <OffreSearchBar id="navbar_desktop" setUrl={setOffersUrl} />
            </AccordionTab>
          </Accordion>
        </>
      )}
      <div className="article_offre__offres" ref={offresContainerRef}>
        {offersQuery?.loaded ? (
          offersQuery.data.length > 0 ? (
            (offersQuery.data || []).slice(0, 50).map((offre, index) =>
              props.nbOffre ? ( //&& props.nbOffre != 20
                index < props.nbOffre &&
                (props.auth.isConnected ? (
                  <Offre
                    categorie={index}
                    offreDetails={offre}
                    url={offre?.imageUrl || offre?.nexusImageUrl || "img1"}
                    nexusUrl={offre?.nexusImageUrl}
                    date={formatDate(
                      offre.postedAt?.date || offre.postedAt,
                      "."
                    )}
                    accessibleForDisabled={offre.accessibleForDisabled}
                    key={offre.id}
                  />
                ) : (
                  <Offre
                    key={offre.id}
                    categorie={index}
                    offreDetails={offre}
                    url={offre?.imageUrl || offre?.nexusImageUrl || "img1"}
                    nexusUrl={offre?.nexusImageUrl}
                    date={formatDate(
                      offre.postedAt?.date || offre.postedAt,
                      "."
                    )}
                    accessibleForDisabled={offre.accessibleForDisabled}
                    onClick={() => {
                      authenticationRedirection();
                    }}
                  />
                ))
              ) : (
                <Offre
                  key={offre.id}
                  categorie={index % 4}
                  offreDetails={offre}
                  url={offre?.imageUrl || offre?.nexusImageUrl || "img1"}
                  nexusUrl={offre?.nexusImageUrl}
                  date={formatDate(offre.postedAt?.date || offre.postedAt, ".")}
                  accessibleForDisabled={offre.accessibleForDisabled}
                />
              )
            )
          ) : (
            <span>{t("erreur.erreur_offer")}</span>
          )
        ) : (
          <Loader />
        )}
      </div>

      <div className="article_offre__button">
        {props.nbOffre ? (
          <BtnBleu
            btnTexte={props.bouton}
            btnAction={() => {
              props.bouton === "Voir toutes les offres d'emploi" &&
                props.handleUpdateMenu({
                  activeItemOffre: props.items.itemsOffre.findIndex(
                    (item) => item.label == "Trouver une offre"
                  ),
                });
              if (props.auth.userConnected != null) {
                window.location.href = "./offres";
              } else {
                authenticationRedirection();
              }
            }}
          >
            <FaChevronRight />
          </BtnBleu>
        ) : (
          <TempPagination
            result={offersQuery}
            refItemParent={offresContainerRef}
            rows={50}
          />
        )}
      </div>
    </div>
  );
};

OffresContainer.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  bouton: PropTypes.string,
  nbOffre: PropTypes.number,
  sousTitre: PropTypes.bool,
  offers: PropTypes.any,
  pagination: PropTypes.object,
  handleUpdatePagination: PropTypes.func,
  handleUpdateOffers: PropTypes.func,
  handleUpdateMenu: PropTypes.func,
  handleUpdateAuth: PropTypes.func,
  sauvegarde: PropTypes.bool,
  items: PropTypes.object,
  auth: PropTypes.object,
  states: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  offers: state.offers,
  pagination: state.pagination,
  items: state.items,
  states: state.states,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdatePagination: (value) => dispatch(updatePagination(value)),
  handleUpdateOffers: (offers) => dispatch(updateOffers(offers)),
  handleUpdateMenu: (value) => dispatch(updateMenu(value)),
  handleUpdateAuth: (value) => dispatch(updateAuth(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OffresContainer);
