import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import Loader from "Components/Loader/loader";
import { updateGroups } from "Redux/Actions/groupsActions";
import { fetchGet, fetchPost } from "Services/api";
import {
  formatDate,
  formatDateLocale,
  isNotEmptyArray,
} from "Services/functions";
import { HTMLParseOptions } from "Services/htmlParseOptions";
import banniere_groupe from "assets/banniere_groupe.webp";
import parse from "html-react-parser";
import { DateTime } from "luxon";
import "primeicons/primeicons.css";
import { Menu } from "primereact/menu";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEllipsisH } from "react-icons/fa";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import GroupeCardEvent from "../GroupeCard/GroupeCardEvent/GroupeCardEvent";
import GroupeMembers from "../GroupeMembers/GroupeMembers";
import GroupePublication from "../GroupePublication/GroupePublication";
import "./GroupeDetails.scss";

/**
 * Permet d'afficher le détail d'un groupe lorsque l'on a cliqué dessus dans la page mes groupes d'échanges
 */
const GroupeDetails = (props) => {
  const { t } = useTranslation("common");
  let { id } = useParams();
  const menuGroupe = useRef(null);

  const [isGroupAdmin, setIsGroupAdmin] = useState(false);

  const [numberOfMembers, setNumberOfMembers] = useState(null);
  const [displayMembersModal, setDisplayMembersModal] = useState(false);

  const [currentGroup, setCurrentGroup] = useState(undefined);
  const [groupEvents, setGroupEvents] = useState([]);

  // -----------  Désactivation du groupe

  const handleDeactivateGroup = async () => {
    const response = await fetchPost(
      "/groups/" + currentGroup.id + "/disable",
      "{}",
      props.auth.token
    );
    let isError = false;
    if (!response?.id || response?.id != currentGroup.id) isError = true;
    props.setDeactivateGroup({
      visible: true,
      type: isError ? "error" : "success",
      detail: isError
        ? "Une erreur est survenue lors de la désactivation du groupe"
        : "Le groupe a bien été désactivé",
      header: "Désactivation du groupe",
    });
  };

  // ---------- GESTION DES MEMBRES DU GROUPE
  /**
   * Vérifie si l'utilisateur connecté est admin du groupe ou non
   * - Si admin du portail, il est admin du groupe
   * - Sinon, on regarde dans la liste des membres du groupe, s'il figure en tant qu'admin (ROLE_GROUP_ADMIN)
   * @returns {Promise<void>}
   */
  const isCurrentUserGroupAdmin = async () => {
    if (props.auth.isAdmin) {
      setIsGroupAdmin(true);
      return;
    }
    const user = await fetchGet("/group_members", props.auth.token, {
      groupSource: currentGroup.id,
      user: props.auth.userConnected.id,
    });
    if (!user) setIsGroupAdmin(false);
    else {
      const isGroupAdmin = user.groupMemberRoles.some(
        (groupMember) => groupMember.role.roleName == "ROLE_GROUP_ADMIN"
      );
      setIsGroupAdmin(isGroupAdmin);
    }
  };

  /**
   * Récupère le nombre de membres actifs dans le groupe
   * @returns {Promise<void>}
   */
  const getNumberOfMembers = async () => {
    const numbersCount = await fetchGet(
      `/groups/${currentGroup.id}/number_of_active_members_in_the_group`,
      props.auth.token
    );
    setNumberOfMembers(numbersCount);
  };

  useEffect(async () => {
    if (currentGroup != null) {
      await getNumberOfMembers();
      await isCurrentUserGroupAdmin();
    }
  }, [currentGroup, props.items.selectedGroup]);

  // ----------------------------------------------

  // Menu trois petits point dans image couverture en fonction du rôle de l'utilisateur
  const itemGroupeUser = [{}];
  const itemGroupeAdmin = [
    // { label: "Partager" },
    // { separator: true },
    {
      label: t("groupManagement.disable"),
      command: () => {
        handleDeactivateGroup();
      },
    },
  ];

  useMemo(async () => {
    if (id) setCurrentGroup(await fetchGet("/groups/" + id, props.auth.token));
    else if (props.groupId != null)
      setCurrentGroup(
        await fetchGet("/groups/" + props.groupId, props.auth.token)
      );
  }, [props.groupId, id]);

  // on récupère les événements du groupe
  useMemo(async () => {
    if (currentGroup) {
      let groupEvents = [];
      groupEvents = await fetchGet("/event_groups", props.auth.token, {
        associatedGroup: currentGroup.id,
        "startingAt[after]": DateTime.local().toISO(),
      });
      setGroupEvents(groupEvents);
    }
  }, [currentGroup]);

  return (
    <div className="groupe_details_container">
      {props.dataLoaded && currentGroup && (
        <>
          <div className="groupe_details_img">
            {!props.finder && (
              <div className="bouton_groupe">
                <BtnBlanc
                  btnAction={(event) => menuGroupe.current.toggle(event)}
                >
                  <FaEllipsisH />
                </BtnBlanc>
                {/* Si le composant est affiché depuis la page de recherche, on
              n'affiche pas le menu */}
                <Menu
                  appendTo={document.getElementById("app")}
                  model={isGroupAdmin ? itemGroupeAdmin : itemGroupeUser}
                  popup
                  ref={menuGroupe}
                  style={{ textAlign: "center", width: "fit-content" }}
                />
              </div>
            )}

            <img
              src={
                currentGroup?.imageUrl
                  ? `${currentGroup.imageUrl}`
                  : banniere_groupe
              }
              alt="Bannière du groupe"
              title="Bannière du groupe"
            />
          </div>
          <div className="groupe_details_header">
            <div className="groupe_details_header__title">
              <h2>{currentGroup.label}</h2>
              {/* Si l'user n'est pas admin du portail, on affiche le bouton rejoindre ou quitter le groupe */}
              {!props.auth.isAdmin &&
                (props.finder ? (
                  // Le membre n'est pas dans le groupe
                  <>
                    <button
                      className="leave"
                      onClick={() => {
                        props.setJoinVisible({
                          title: currentGroup.label,
                          state: true,
                          id: currentGroup.id,
                          private: currentGroup.private,
                        });
                      }}
                    >
                      Rejoindre le groupe
                    </button>
                  </>
                ) : (
                  // Le membre est dans le groupe
                  <>
                    <button
                      className="leave"
                      onClick={() => {
                        props.setLeaveGroup({
                          id: currentGroup.id,
                          state: true,
                          title: currentGroup.label,
                        });
                      }}
                    >
                      {" "}
                      Quitter le groupe{" "}
                    </button>
                  </>
                ))}
            </div>

            <div className="infos">
              <p>
                {currentGroup?.public ? (
                  <React.Fragment>
                    <i className="pi pi-lock-open icon_infos"></i>
                    {t("createGroup.public")}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <i className="pi pi-lock icon_infos"></i>
                    {t("createGroup.private")}
                  </React.Fragment>
                )}
              </p>
              <p onClick={() => setDisplayMembersModal(true)} className="click">
                {numberOfMembers !== null ? (
                  <i className="pi pi-users icon_infos">
                    {numberOfMembers > 1
                      ? " " +
                        numberOfMembers +
                        " " +
                        t("groupManagement.members")
                      : " " +
                        numberOfMembers +
                        " " +
                        t("groupManagement.member")}
                  </i>
                ) : (
                  <i className="pi pi-spin pi-spinner icon_infos"></i>
                )}
              </p>
              <p>
                {props.i18n.language === "en"
                  ? "Created on " +
                    formatDateLocale(
                      currentGroup.createdAt,
                      props.i18n.language
                    )
                  : "Créé le " +
                    formatDateLocale(
                      currentGroup.createdAt,
                      props.i18n.language
                    )}
              </p>
            </div>
          </div>
          <div className="groupe_details_description">
            <h5>Description</h5>
            <div>{parse(currentGroup.description, HTMLParseOptions)}</div>
            {isNotEmptyArray(groupEvents) && (
              <>
                <h6>Prochain événement de mon groupe</h6>
                <Link
                  to={"/group_event-detail/" + groupEvents[0].id}
                  key={groupEvents[0]}
                >
                  <GroupeCardEvent
                    image={groupEvents[0].imageUrl ? "link" : "img1"}
                    link={groupEvents[0].imageUrl}
                    titre={groupEvents[0].title}
                    dateDebut={formatDate(groupEvents[0].startingAt, ".")}
                    dateFin={formatDate(groupEvents[0].endingAt, ".")}
                    participants={groupEvents[0].maxNumberOfParticipants}
                  />
                </Link>
              </>
            )}
            {currentGroup && currentGroup.id ? (
              <>
                <GroupePublication
                  currentGroupId={currentGroup.id}
                  finder={props.finder}
                  isGroupAdmin={isGroupAdmin}
                />
              </>
            ) : (
              <Loader />
            )}
          </div>

          {displayMembersModal && (
            <GroupeMembers
              id={currentGroup?.id}
              title={currentGroup?.label}
              openModal={displayMembersModal}
              setOpenModal={setDisplayMembersModal}
            />
          )}
        </>
      )}
    </div>
  );
};

GroupeDetails.propTypes = {
  title: PropTypes.string,
  groups: PropTypes.object,
  auth: PropTypes.object,
  handleUpdateGroups: PropTypes.func,
  groupId: PropTypes.number,
  users: PropTypes.object,
  items: PropTypes.object,
  setLeaveGroup: PropTypes.func,
  finder: PropTypes.bool,
  setJoinVisible: PropTypes.func,
  setDeactivateGroup: PropTypes.func,
  states: PropTypes.any,
  i18n: PropTypes.object,

  dataLoaded: PropTypes.bool,
};

GroupeDetails.defaultProps = {
  dataLoaded: false,
};

const mapStateToProps = (state) => ({
  items: state.items,
  auth: state.auth,
  users: state.users,
  groups: state.groups,
  states: state.states,
  i18n: state.i18n,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateGroups: (value) => {
    dispatch(updateGroups(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupeDetails);
