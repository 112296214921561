import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Modal from "../Modal";
import { Controller, useForm } from "react-hook-form";
import { InputTextarea } from "primereact/inputtextarea";
import "./ModalEditerPublication.scss";
import axios from "axios";
import { useEffect } from "react";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import { useTranslation } from "react-i18next";

const ModalEditerPublication = ({ visible, setVisible, auth }) => {
  const { t } = useTranslation("common");
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      content: visible.content,
    },
  });

  const editPublication = (data) => {
    var result = undefined;
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL_API}/publications/` + visible.id,
        data,
        {
          headers: auth.token
            ? {
                accept: "application/json",
                Authorization: `Bearer ${auth.token}`,
              }
            : {
                accept: "application/json",
              },
        }
      )
      .then((response) => (result = response))
      .catch((error) => (result = error.response.data.detail))
      .finally(() => {
        if (result && result.status === 200)
          setVisible({ state: false, result: result });
        else setVisible({ state: false, result: result });
      });
  };

  const onSubmit = (data) => {
    editPublication(data);
  };

  /**
   * Afffichage d'une erreur si nécessaire dans le formulaire
   * @param {String} name nom du champ
   * @returns {FieldError, JSX.Element}
   */
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  useEffect(() => {
    setValue("content", visible.content);
  }, [visible.content]);

  return (
    <>
      <Modal
        visible={visible.state}
        setVisible={() => setVisible({ ...visible, state: false })}
        header={t("groupPublication.form.edit")}
        height="fit-content"
        width="80%"
        className="modal modal_edition-publication"
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={"edition_publication__form"}
        >
          <label htmlFor="content">{t("groupPublication.form.edit")}</label>
          <Controller
            name="content"
            control={control}
            rules={{
              required: t("groupPublication.form.required"),
            }}
            render={({ field }) => (
              <InputTextarea
                {...field}
                className="edition_publication__form-content"
                placeholder={t("groupPublication.form.edit")}
                cols={40}
                autoResize
              />
            )}
          />
          {getFormErrorMessage("content")}
          <div className="edition_publication__form-buttons">
            <BtnBlanc
              btnTexte={t("groupPublication.modals.cancel")}
              action={"button"}
              btnAction={() => setVisible({ ...visible, state: false })}
            />
            <BtnBleu btnTexte={t("groupPublication.modals.edit")} />
          </div>
        </form>
      </Modal>
    </>
  );
};

ModalEditerPublication.propTypes = {
  visible: PropTypes.object.isRequired,
  setVisible: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  groups: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  groups: state.groups,
});

export default connect(mapStateToProps)(ModalEditerPublication);
