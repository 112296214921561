import React, { useCallback, useRef, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Modal from "../Modal";
import { Controller, useForm } from "react-hook-form";
import "./ModalEditerGroupe.scss";
import { useEffect } from "react";
import { isNotEmptyArray } from "Services/functions";
import { Toast } from "primereact/toast";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import Loader from "Components/Loader/loader";
import ChargementImage from "Components/ChargementImage/ChargementImage";
import { Checkbox } from "primereact/checkbox";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import axios from "axios";
import ReactQuill from "react-quill";
import QuillOptions from "Components/QuillOptions/QuillOptions";
import DOMPurify from "dompurify";
import { fetchGet } from "Services/api";
import { debounce } from "../../../Services/debounce";
import { PublishState, publishStateOptions } from "Services/publishStates.d";

const ModalEditerGroupe = (props) => {
  // ------- VARIABLES DES GROUPES
  // composante de formation associée au groupe
  const composanteData = props.secondaryTables.educationComposanteData;
  // domaine de formation associé au groupe
  const formationData = props.secondaryTables.domainsData;
  // spécialité de formation associée au groupe
  const specialiteData = props.groups.specialitesFormation;

  const [submitted, setSubmitted] = useState(false);

  // ------- VARIABLES GESTION IMAGES ----------
  // La photo vient-elle de la photothèque ?
  const [phototheque, setPhototheque] = useState(false);
  // Contiendra l'url de l'image choisie (upload ou photothèque)
  const [imageFile, setImageFile] = useState("");
  // ID de l'image choisie (phototheque)
  const [imageId, setImageId] = useState("");

  // Contiendra le fichier upload par l'utilisateur
  const [image, setImage] = useState("");

  // ------- VARIABLES GESTION FORMULAIRE ----------
  // catégorie de l'actu choisie
  const [categories, setCategories] = useState([]);
  // Défini si l'actualité a bien été publiée / soumise ou non
  // const [isSubmitted, setSubmitted] = useState(false);

  // ------- TOASTS ----------
  // Toast de succès d'upload d'image
  const uploadToast = useRef(null);
  // Toast de suppression de l'image upload
  const cancelToast = useRef(null);

  const [groupState, setGroupState] = useState(null);

  // variables du formulaire
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    trigger,
    getValues,
  } = useForm();

  const editGroup = async (data) => {
    // Variable qui stockera la réponse du serveur
    let result = null;
    // On crée un FormData qui sera envoyé au serveur
    var dataForm = new FormData();
    // On ajoute les données du formulaire au formData
    for (var key in data) dataForm.append(key, data[key]);

    // for (var pair of dataForm.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }

    // Url en fonction de l'état du groupe
    let url =
      groupState.statusLabel == "Désactivé" ||
      groupState.statusLabel == "Refusé"
        ? "/request_validation_again"
        : "/edit";
    // On envoie le formData au serveur
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL_API}/groups/` +
          props.visible.group.id +
          `${url}`,
        dataForm,
        {
          headers: props.auth.token
            ? {
                accept: "application/json",
                "Content-Type": "multipart/form-data",

                Authorization: `Bearer ${props.auth.token}`,
              }
            : {
                accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
        }
      )
      .then(async (response) => {
        result = response;
        if (data.imageStockId) {
          await updateImage(data.imageStockId);
        }
      })
      .catch((error) => (result = error.response.data.detail))
      .finally(() => {
        console.log("result EDIT groupe", result);
        if (result && result.status === 201)
          props.setVisible({ state: false, result: result });
        else props.setVisible({ state: false, result: result });
      });
  };

  const updateImage = async (imageStockId) => {
    if (props.visible.group?.imageStockId === imageStockId) return;
    await axios.post(
      `${process.env.REACT_APP_BASE_URL_API}/groups/${props.visible.group.id}/updateImageStock`,
      { imageStockId },
      {
        headers: props.auth.token
          ? {
              accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${props.auth.token}`,
            }
          : {
              accept: "application/json",
              "Content-Type": "application/json",
            },
      }
    );
  };

  useEffect(() => {
    if (props.visible.group) {
      setGroupState(props.visible.group.state);
      let currentGroup = { ...props.visible.group };
      ["id", "createdAt", "createdBy", "state", "events"].forEach(
        (k) => delete currentGroup[k]
      );
      reset({
        ...currentGroup,
        publishOn: currentGroup.publishOn || PublishState.PUBLISH_ALUMNI,
        groupCategory: currentGroup.groupCategory.label,
        educationComposante: currentGroup.educationComposante
          ? formatComposantes([currentGroup.educationComposante])[0]
          : null,
      });
    }
  }, [props.visible.group]);

  // Une fois les catégories chargées, on les stocke dans un tableau :
  // Une fois les catégories chargées, on les stocke dans un tableau :
  useEffect(async () => {
    /// Récupérer les catégories, et les filtrer par ordre alphabétique
    const categoriesList = isNotEmptyArray(props.groups.categories)
      ? props.groups.categories
      : await fetchGet("/group_categories", props.auth.token);
    setCategories([]);
    let tempArray = [];
    categoriesList.forEach((category) => tempArray.push(category));
    tempArray = tempArray.sort((a, b) => a.label.localeCompare(b.label));
    setCategories(tempArray);
  }, [props.groups.categories]);

  const [specialites, setSpecialites] = useState(null);

  useEffect(() => {
    if (!specialiteData) return;

    let tempArray = [];
    specialiteData.forEach((specialite) => {
      let diplomes = specialite?.diplomes;
      tempArray.push({
        ...specialite,
        customLabel: `${specialite?.label}${
          isNotEmptyArray(diplomes) ? " (" + diplomes[0]?.codeDiplome + ")" : ""
        }`,
      });
    });
    tempArray = tempArray?.sort((a, b) => a?.label?.localeCompare(b?.label));
    setSpecialites(tempArray);
  }, [specialiteData]);

  /**
   * Remplissage de la key image selon différentes conditions
   * @param {Object} data données pour la requêtes
   * @returns {Object}
   */
  const fillImage = (data) => {
    // Si une image a été téléchargée, on met son url local dans la key imageFile
    if (imageFile && !phototheque) {
      data.imageFile = imageFile;
      delete data.imageStockId;
    }
    // Sinon, on met l'url de l'image choisie de la photothèque
    else if (phototheque) {
      data.imageStockId = imageId;
      delete data.imageFile;
      delete data.image;
    }
    return data;
  };

  /**
   * Remplissage de la key category selon différentes conditions
   * @param {Object} data données pour la requêtes
   * @returns {Object}
   */
  const fillCategory = (data) => {
    // Si catégory est vide on met la catégorie par défaut 'Autre'
    if (!data.groupCategory) data.groupCategory = "Autre";

    // Si la key id existe, pas de besoin de chercher l'id de la catégorie
    if (data.groupCategory.id)
      data.groupCategory = "/api/group_categories/" + data.groupCategory.id;
    /* 
    Sinon, on cherche si la catégorie existe déjà :
    // Si oui : on met l'id de la catégorie
     Si non : on spécifie qu'elle doit être créée
     */ else {
      const category = categories.find(
        (category) => category.label === data.groupCategory
      );
      if (category) data.groupCategory = "/api/group_categories/" + category.id;
      else data.groupCategory = JSON.stringify({ label: data.groupCategory });
    }
    return data;
  };

  /**
   * Remplissage des keys educationComposante, domain et educationSpeciality
   * selon différentes conditions
   * @param {Object} data données pour la requêtes
   * @returns {Object} - données pour la requêtes
   */
  const fillAssociations = (data) => {
    // Si le champ spécialité est renseigné, on met l'id de la spécialité
    if (data.educationSpeciality && data.educationSpeciality.id)
      data.educationSpeciality =
        "/api/education_specialities/" + data.educationSpeciality.id;
    // Si le champ domaine est renseigné, on met l'id du domaine
    if (data.educationDomain && data.educationDomain.id)
      data.educationDomain = "/api/domains/" + data.educationDomain.id;
    // Si le champ composante est renseigné, on met l'id de la composante
    if (data.educationComposante && data.educationComposante.id)
      data.educationComposante =
        "/api/education_composantes/" + data.educationComposante.id;
    return data;
  };

  const cleanEmptyKeys = (data) => {
    // Suppression des keys qui ne sont pas remplies
    for (var key in data) if (data[key] === "" || !data[key]) delete data[key];
    return data;
  };

  const postForm = (data) => {
    data.description = DOMPurify.sanitize(data.description);
    data = fillImage(data);
    data = fillCategory(data);
    data = cleanEmptyKeys(data);
    data = fillAssociations(data);

    editGroup(data);
  };

  /**
   * Fonction permettant de combiner le code de la composante et son nom, si ce n'est pas déjà fait
   * puis de les trier par ordre alphabétique
   * @param {[{label: String, code_composante: String}]} composantes - liste des composantes
   * @returns {[{label: String, code_composante: String, displayLabel: String}]} - liste des composantes formatées et triées
   */
  const formatComposantes = (composantes) => {
    // Si la liste est vide ou ne comporte pas les bons champs, on retourne une liste vide
    if (
      !composantes ||
      !composantes.at(0).displayLabel ||
      !composantes.at(0).label
    )
      return [];

    composantes
      .map((composante) => {
        if (!composante?.label.match("\\d+[':']"))
          composante.displayLabel =
            composante["code_composante"] + ": " + composante.label;
        else composante.displayLabel = composante.label;
        return composante;
      })
      .sort((a, b) => a.displayLabel.localeCompare(b.displayLabel));
    return composantes;
  };

  /**
   * Afffichage d'une erplreur si nécessaire dans le formulaire
   * @param {String} name nom du champ
   * @returns {FieldError, JSX.Element}
   */
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const onSubmit = (data) => {
    postForm(data);
  };

  const submit = () => {
    postForm(getValues());
  };

  const debounceGroupName = useCallback(debounce(validateGroupName, 500), []);

  /**
   * Fonction permettant de vérifier si le nom de groupe est déjà utilisé
   * ou non
   * @param value - nom du groupe
   * @returns {Promise<string|boolean>} - message d'erreur ou true
   */
  async function validateGroupName(value) {
    // Si le nom est le même que le nom original, on ne fait rien
    if (value === props.visible.group?.title) return true;
    let exists = await fetchGet(`/groups?label=${value}`, props.auth.token);

    return exists && exists.length > 0 && value === exists?.at(0)?.label
      ? "Ce titre de groupe existe déjà"
      : true;
  }

  return (
    <>
      <Modal
        visible={props.visible.state}
        setVisible={() => props.setVisible({ ...props.visible, state: false })}
        header="Editer un groupe"
        height="fit-content"
        width="80%"
        className="modal modal_edition-groupe"
        blockScroll
      >
        <Toast ref={uploadToast} />
        <Toast ref={cancelToast} />
        <div className="groupe_container">
          {/* <p> Sous-titres à définir</p> */}
          <div className="edition_groupe_container">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="question_columns large">
                <div className="question">
                  <h2
                    htmlFor="label"
                    className={classNames({ "p-error": errors.label })}
                  >
                    Titre du groupe *
                  </h2>
                  <Controller
                    name="label"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Veuillez entrer un titre",
                      },
                      maxLength: {
                        message:
                          "Le titre ne peut avoir que 50 caractères maximum",
                        value: 50,
                      },
                      validate: debounceGroupName,
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        value={field.value}
                        id={field.name}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          trigger("label");
                        }}
                        placeholder="Groupe de ..."
                        className={
                          (classNames({
                            "p-invalid": fieldState.invalid,
                          }),
                          "input_groupe")
                        }
                      />
                    )}
                  />
                  {getFormErrorMessage("label")}
                </div>
                <div className="question">
                  <h2
                    htmlFor="groupCategory"
                    className={classNames({
                      "p-error": errors.groupCategory,
                    })}
                  >
                    Catégorie du groupe
                  </h2>
                  {categories.length > 0 ? (
                    <Controller
                      name="groupCategory"
                      control={control}
                      render={({ field, fieldState }) => (
                        <Dropdown
                          optionLabel="label"
                          editable
                          id={field.name}
                          showClear
                          value={field.value}
                          options={categories}
                          placeholder="Sport, international, culture..."
                          onChange={(e) => field.onChange(e.value)}
                          className={
                            (classNames({
                              "p-invalid": fieldState.invalid,
                            }),
                            "input_groupe")
                          }
                        />
                      )}
                    />
                  ) : (
                    <Loader />
                  )}
                  {getFormErrorMessage("groupCategory")}
                </div>
              </div>

              <div className="question column">
                <h2>Associer le groupe à :</h2>
              </div>
              <div className="question_columns">
                <div className="question">
                  <label
                    htmlFor="educationComposante"
                    className={classNames({
                      "p-error": errors.educationComposante,
                    })}
                  >
                    Une composante
                  </label>
                  <Controller
                    name="educationComposante"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Dropdown
                        optionLabel="displayLabel"
                        id={field.name}
                        value={field.value}
                        filter
                        showClear
                        options={formatComposantes(composanteData)}
                        placeholder="Composante"
                        onChange={(e) => field.onChange(e.value)}
                        className={
                          (classNames({
                            "p-invalid": fieldState.invalid,
                          }),
                          "input_groupe groupes-dropdown")
                        }
                      />
                    )}
                  />
                </div>
                <div className="question">
                  <label
                    htmlFor="educationDomain"
                    className={classNames({
                      "p-error": errors.educationDomain,
                    })}
                  >
                    Un domaine de formation
                  </label>
                  <Controller
                    name="educationDomain"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Dropdown
                        optionLabel="label"
                        id={field.name}
                        showClear
                        value={field.value}
                        options={formationData}
                        placeholder="Domaine de formation"
                        onChange={(e) => field.onChange(e.value)}
                        className={
                          (classNames({
                            "p-invalid": fieldState.invalid,
                          }),
                          "input_groupe groupes-dropdown")
                        }
                      />
                    )}
                  />
                </div>
                <div className="question">
                  <label
                    htmlFor="educationSpeciality"
                    className={classNames({
                      "p-error": errors.educationSpeciality,
                    })}
                  >
                    Une spécialité
                  </label>
                  <Controller
                    name="educationSpeciality"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Dropdown
                        optionLabel="customLabel"
                        id={field.name}
                        value={field.value}
                        showClear
                        options={specialites}
                        placeholder="Spécialité"
                        onChange={(e) => field.onChange(e.value)}
                        className={
                          (classNames({
                            "p-invalid": fieldState.invalid,
                          }),
                          "input_groupe groupes-dropdown")
                        }
                      />
                    )}
                  />
                </div>
              </div>

              <ChargementImage
                image={image}
                setImage={setImage}
                imageFile={imageFile}
                setImageFile={setImageFile}
                setImageId={setImageId}
                uploadToast={uploadToast}
                cancelToast={cancelToast}
                phototheque={phototheque}
                setPhototheque={setPhototheque}
                path="groups"
                hideCustomImage
                long
                currentImage={
                  getValues("imageUrl") || props.visible.group?.imageUrl
                }
              />
              <div className="question search">
                <h2
                  htmlFor="description"
                  className={classNames({ "p-error": errors.description })}
                  style={{ marginTop: "1rem" }}
                >
                  Description du groupe *
                </h2>
                <Controller
                  name="description"
                  control={control}
                  rules={{
                    required: {
                      message: "La description est requise",
                      value: true,
                    },
                  }}
                  render={({ field }) => (
                    <ReactQuill
                      id={field.name}
                      {...field}
                      theme="snow"
                      modules={QuillOptions.modules}
                      formats={QuillOptions.formats}
                      rows={5}
                    />
                  )}
                />
                {getFormErrorMessage("description")}
              </div>

              {
                // on ne choisit la visibilité que si l'on est admin
                props.auth.userConnected.isAdmin && (
                  <div className="question search">
                    <h2>Choisir la visiblité du groupe *</h2>
                    <Controller
                      name="publishOn"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          id="publishOn"
                          value={field.value}
                          options={publishStateOptions}
                          onChange={(e) => field.onChange(e.value)}
                          optionLabel="label"
                          placeholder="Choisir la visibilité"
                        />
                      )}
                    />
                  </div>
                )
              }

              <div className="groupe_prive">
                <div className="question checkbox">
                  <Controller
                    name="public"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Checkbox
                        id={field.name}
                        checked={!field.value}
                        onChange={(e) => field.onChange(!e.checked)}
                        className={
                          (classNames({
                            "p-invalid": fieldState.invalid,
                          }),
                          "input_groupe")
                        }
                      />
                    )}
                  />
                  <label
                    htmlFor="public"
                    className={classNames({ "p-error": errors.public })}
                  >
                    <i className="pi pi-lock"></i> Groupe privé
                  </label>
                  {getFormErrorMessage("public")}
                </div>
                <div className="question checkbox">
                  <Controller
                    name="public"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Checkbox
                        id={field.name}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.checked)}
                        className={
                          (classNames({
                            "p-invalid": fieldState.invalid,
                          }),
                          "input_groupe")
                        }
                      />
                    )}
                  />
                  <label
                    htmlFor="public"
                    className={classNames({ "p-error": errors.public })}
                  >
                    <i className="pi pi-lock-open"></i> Groupe public
                  </label>
                  {getFormErrorMessage("public")}
                </div>
              </div>
              {props.auth.userConnected.userRoles.some(
                (role) =>
                  role.roleName == "ROLE_ADMIN_DU_PORTAIL" ||
                  role.roleName == "ROLE_PERSONNEL_UNIVERSITE"
              ) && (
                <div className="groupe_prive">
                  <div className="question checkbox">
                    <Controller
                      name="institutional"
                      control={control}
                      render={({ field, fieldState }) => (
                        <Checkbox
                          id={field.name}
                          checked={field.value}
                          onChange={(e) => field.onChange(e.checked)}
                          className={
                            (classNames({
                              "p-invalid": fieldState.invalid,
                            }),
                            "input_groupe")
                          }
                        />
                      )}
                    />
                    <label
                      htmlFor="institutional"
                      className={classNames({
                        "p-error": errors.institutional,
                      })}
                    >
                      <i className="pi pi-lock"></i> Ce groupe est un groupe
                      institutionnel
                    </label>
                    {getFormErrorMessage("institutional")}
                  </div>
                </div>
              )}

              <div className="question bouton">
                {submitted && (
                  <Modal
                    visible={submitted}
                    setVisible={setSubmitted}
                    header={"Votre groupe a bien été modifié"}
                  >
                    <i className="pi pi-send"></i>
                    <div className="description">
                      <p>
                        <center>Votre groupe a bien été modifié</center>
                      </p>
                    </div>
                    <div className="modals-buttons">
                      <BtnBleu
                        btnTexte="Déposer un nouveau groupe"
                        btnAction={(e) => {
                          e.preventDefault();
                          setSubmitted(false);
                        }}
                      />
                      <BtnBlanc
                        btnTexte="Retour sur mes groupes"
                        btnAction={(e) => {
                          e.preventDefault();
                          props.setCreation(!props.creation);
                          setSubmitted(false);
                        }}
                      />
                    </div>
                  </Modal>
                )}
                {cancelToast.current && (
                  <button
                    type="submit"
                    className="btn-bleu"
                    onClick={() =>
                      !isValid
                        ? cancelToast.current.show({
                            severity: "error",
                            summary: "Validation",
                            detail: "Le formulaire n'est pas valide",
                            sticky: true,
                          })
                        : submit()
                    }
                  >
                    Editer le groupe
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

ModalEditerGroupe.propTypes = {
  visible: PropTypes.object,
  setVisible: PropTypes.func,
  auth: PropTypes.object,
  groups: PropTypes.object,
  setCreation: PropTypes.func,
  creation: PropTypes.bool,
  users: PropTypes.object,
  secondaryTables: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  groups: state.groups,
  users: state.users,
  secondaryTables: state.secondaryTables,
});

export default connect(mapStateToProps)(ModalEditerGroupe);
