import React, { useState } from "react";
import "./GestionMembres.scss";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import { InputText } from "primereact/inputtext";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import MembreCard from "./MembreCard/MembreCard";

/**
 * Permet d'afficher la page de gestion des membres
 * Page accessible uniquement pour les administrateurs
 */
// TO DO : récupérer les bons rôles de l'api et la date d'expiration
// TO DO : accéder à la date de dernière connexion, de création de compte
const GestionMembres = (props) => {
  const [page1, setPage1] = useState(true);
  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [recherche, setRecherche] = useState(false);

  /**
   * Permet de filtrer les membres par nom et prénom
   */
  const Recherche = (users) => {
    if (firstName != "") {
      if (name != "") {
        return users.filter(
          (user) => user.surname == name && user.firstname == firstName
        );
      } else {
        return users.filter((user) => user.firstname == firstName);
      }
    }
    if (name != "") {
      return users.filter((user) => user.surname == name);
    } else {
      return users;
    }
  };
  return (
    <div className="gestion_membres">
      {props.auth.isConnected && (
        <div className="gestion_membres__container">
          <div className="gestion_membres__container__title">
            <h1 className="h1_trait_dessus">
              {props.items[props.activeIndex].label}
            </h1>
            {page1 ? (
              <BtnBlanc
                btnAction={() => setPage1(false)}
                btnTexte="Voir les contributeurs"
              />
            ) : (
              <BtnBlanc
                btnAction={() => setPage1(true)}
                btnTexte="Affecter des rôles"
              />
            )}
          </div>
          {page1 ? (
            <div className="gestion_membres__container__article">
              <h3>Affecter des rôles</h3>
              <div className="gestion_membres__container__article__form">
                <div className="gestion_membres__container__article__form__champ">
                  <label>Prénom</label>
                  <InputText
                    value={firstName}
                    placeholder="Marie, Paul..."
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="gestion_membres__container__article__form__champ">
                  <label>Nom</label>
                  <InputText
                    value={name}
                    placeholder="Dupont, Gary..."
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <BtnBleu
                btnTexte="Rechercher"
                btnAction={() => setRecherche(true)}
              />

              {recherche &&
                Recherche(props.admin.users).map((user, index) => (
                  <MembreCard
                    key={index}
                    dejaRole={true}
                    nom={user.surname}
                    prenom={user.firstname}
                    role="Contributeur"
                    dateFin="22/06/2023"
                    dateCreationCompte="22/06/2022"
                    dateDerniereConnexion="25/06/2022"
                  />
                ))}
            </div>
          ) : (
            <div className="gestion_membres__container__article">
              <h3>Liste des contributeurs</h3>
              <div>
                {props.admin.users.filter((user) =>
                  user?.userRoles.some(
                    (role) =>
                      role == "ROLE_CONTRIBUTEUR" ||
                      role == "ROLE_VALIDATION_OFFRES"
                  )
                ).length > 0 ? (
                  props.admin.users
                    .filter((user) =>
                      user?.userRoles.some(
                        (role) => role.roleName == "ROLE_CONTRIBUTEUR"
                      )
                    )
                    .map((user, index) => (
                      <MembreCard
                        key={index}
                        contributeur
                        dejaRole={true}
                        nom={user.surname}
                        prenom={user.name}
                        role="Contributeur"
                        dateFin="22/06/2023"
                        dateCreationCompte="22/06/2022"
                        dateDerniereConnexion="25/06/2022"
                      />
                    ))
                ) : (
                  <p>
                    Aucun utilisateur n&apos;a le rôle validation d&apos;offres
                    ou contributeur
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

GestionMembres.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.array,
  activeIndex: PropTypes.number,
  checked: PropTypes.bool,
  setChecked: PropTypes.func,
  admin: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  admin: state.admin,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GestionMembres);
