/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react";
import "./OffreSearchBar.scss";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { FaPlus, FaSearch } from "react-icons/fa";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { updateOffers } from "Redux/Actions/offersActions";
import axios from "axios";
import BtnBleuLeft from "Components/Boutons/BtnBleuLeft/btn-bleu-left";
import SliderSalaire from "../SliderSalaire/sliderSalaire";
import { InputTextarea } from "primereact/inputtextarea";
import Modal from "Components/Modal/Modal";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import { useEffect } from "react";
import { useFetchGet } from "Services/api";
import SearchCard from "./SearchCard/searchCard";
import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";
import { useTranslation } from "react-i18next";
import ModalOffreSearchBar from "Components/Modal/ModalOffreSearchBar/ModalOffreSearchBar";

/**
 * Barre de recherche des offres
 */
const OffreSearchBar = (props) => {
  const { t } = useTranslation("common");

  const [isVisibleSalary, setIsVisibleSalary] = useState(false);

  const listOfSavedSearch = useFetchGet(
    "/saved_offer_searches",
    props.auth.token
  );
  // Set du type de contrat en fonction de la catégorie
  const [typeOfContract, setTypeOfContract] = useState(
    props.secondaryTables.typeOfContractsData
  );
  // Gère si le multiselect type de contrat est disponible ou non
  const [isDisabled, setIsDisabled] = useState(false);
  const uploadToast = useRef(null);
  const cancelToast = useRef(null);

  // Fonction qui adapte le type de contrat à la catégorie
  const adaptTypeOfContract = (category) => {
    // Par défaut le type de contrat est disponible
    setIsDisabled(false);

    // Si on sélectionne plusieurs catégorie on ne filtre pas sur le type
    if (category.length !== 1) {
      setTypeOfContract(props.secondaryTables.typeOfContractsData);
      return;
    }

    // filtre sur le type en fonction de la catégorie
    if (category[0].label === "Job étudiant") {
      setTypeOfContract(
        props.secondaryTables.typeOfContractsData.filter((item) => {
          return (
            item.label === "CDI" ||
            item.label === "CDD" ||
            item.label === "INTERIM"
          );
        })
      );
    } else if (category[0].label === "Alternance") {
      setTypeOfContract(
        props.secondaryTables.typeOfContractsData.filter((item) => {
          return (
            item.label === "CONTRAT D'APPRENTISSAGE" ||
            item.label === "CONTRAT DE PROFESSIONNALISATION"
          );
        })
      );
    } else if (category[0].label === "Emploi") {
      setTypeOfContract(
        props.secondaryTables.typeOfContractsData.filter((item) => {
          return (
            item.label === "CDI" ||
            item.label === "CDD" ||
            item.label === "INTERIM" ||
            item.label === "CIFRE" ||
            item.label === "POST-DOCTORANT"
          );
        })
      );
    }
    // S'il n'y a pas de type de contrat associé à la catégorie on disable le type de contrat
    else {
      setIsDisabled(true);
    }
  };

  // récupération des données et ajout des paramètres dans l'url de recherche
  const handleSearch = () => {
    if (props.offers.statusData.length > 0) {
      let url = new URL(`${process.env.REACT_APP_BASE_URL_API}/offers`);
      url.searchParams.set(
        "state",
        props.offers.statusData.filter(
          (etat) => etat.statusLabel === "Publié"
        )[0].id
      );
      url.searchParams.set("order[modifiedAt]", "desc");
      //Gestion des string city, minsalary, maxsalary et keyword
      if (props.offers.lieu !== "") {
        url.searchParams.set("localisation", props.offers.lieu);
      }

      if (isVisibleSalary && props.offers.categorieOffre.length < 2) {
        if (valueSalary[0] > 0)
          url.searchParams.set("minSalary[gte]", valueSalary[0]);
        if (valueSalary[1] > 0)
          url.searchParams.set("maxSalary[lte]", valueSalary[1]);
      }

      if (props.offers.motsClés !== "")
        url.searchParams.set("keyword", props.offers.motsClés);

      //Gestion des Arrays of string pour Contract, Category, Education, Sector et Domain
      if (props.offers.typeContrat.length > 0) {
        props.offers.typeContrat.forEach((item) =>
          url.searchParams.append("typeOfContract[]", item.id)
        );
      }
      if (props.offers.categorieOffre.length > 0) {
        props.offers.categorieOffre.forEach((item) =>
          url.searchParams.append("offerCategory[]", item.id)
        );
      }
      if (
        props.offers.niveauEtudes.length > 0 &&
        props.offers.niveauEtudes.length <
          props.secondaryTables.levelOfEducationsData.length - 1
      ) {
        props.offers.niveauEtudes.forEach((item) => {
          item.label !== "Indifférent" &&
            url.searchParams.append("levelOfEducations[]", item.id);
        });
      }
      if (props.offers.secteur.length > 0) {
        props.offers.secteur.forEach((item) =>
          url.searchParams.append("sector[]", item.id)
        );
      }
      if (props.offers.domaine.length > 0) {
        props.offers.domaine.forEach((item) =>
          url.searchParams.append("domains[]", item.id)
        );
      }
      props.setUrl(url);
      props.handleUpdateOffers({
        isSearched: true,
        currentSearch: url,
      });
    }
  };

  const handleEmptySearch = () => {
    if (props.offers.statusData.length > 0) {
      let url = new URL(`${process.env.REACT_APP_BASE_URL_API}/offers`);
      url.searchParams.set(
        "state",
        props.offers.statusData.filter(
          (etat) => etat.statusLabel === "Publié"
        )[0].id
      );
      url.searchParams.set("order[modifiedAt]", "desc");
      props.setUrl(url);
    }
  };

  const [valueSalary, setValueSalary] = useState([0, 30000]);

  const [visibleNamingSearch, setVisibleNamingSearch] = useState(false);
  const [visibleListOfSearch, setVisibleListOfSearch] = useState(false);

  const completeSearch = (value) => {
    const getDataArray = (reduxData, responseData) => {
      let returnArray = [];
      if (responseData.length > 0) {
        reduxData.forEach(
          (element) =>
            responseData.some(
              (domaine) =>
                domaine.slice(domaine.lastIndexOf("/") + 1) === element.id
            ) && returnArray.push(element)
        );
      }
      return returnArray;
    };

    props.handleUpdateOffers({
      motsClés: value.title,
      lieu: value.country,
      salaireMin: value.minSalary,
      salaireMax: value.maxSalary,
      secteur: getDataArray(
        props.secondaryTables.sectorOfOffersData,
        value.sectorOfOffer
      ),
      domaine: getDataArray(props.secondaryTables.domainsData, value.domain),
      typeContrat: getDataArray(
        props.secondaryTables.typeOfContractsData,
        value.typeOfContract
      ),
      categorieOffre: getDataArray(
        props.secondaryTables.offerCategoriesData,
        value.offerCategory
      ),
      niveauEtudes: getDataArray(
        props.secondaryTables.levelOfEducationsData,
        value.levelOfEducation
      ),
    });
    setValueSalary([value.minSalary, value.maxSalary]);
    uploadToast.current.show({
      severity: "success",
      summary: "Succès : ",
      detail: "Vos critères de recherche ont bien été importés",
      life: 3000,
    });
  };

  const deleteSearch = (value) => {
    let url = `${process.env.REACT_APP_BASE_URL_API}/saved_offer_searches/${value.id}`;
    axios
      .delete(url, {
        headers: props.auth.token
          ? {
              accept: "application/json",
              Authorization: `Bearer ${props.auth.token}`,
            }
          : {
              accept: "application/json",
            },
      })
      .catch((error) => {
        console.err("Error modify : ", error.response);
      })
      .finally(() => {
        props.handleUpdateOffers({
          savedSearches: props.offers.savedSearches.filter((search) => {
            return search.id !== value.id;
          }),
        });
        cancelToast.current.show({
          severity: "error",
          summary: "Suppression : ",
          detail: "Le filtre de recherche a bien été supprimé",
          life: 800,
        });
      });
  };

  useEffect(() => {
    if (listOfSavedSearch.loaded) {
      props.handleUpdateOffers({
        savedSearches: listOfSavedSearch.data.filter((search) => {
          return (
            search.user.slice(search.user.lastIndexOf("/") + 1) ==
            props.auth.userConnected.id
          );
        }),
      });
    }
  }, [listOfSavedSearch.loaded]);

  return (
    <div className="offre_search_bar" id={props.id}>
      <div className="offre_search_bar__container">
        <Toast ref={uploadToast} />
        <Toast ref={cancelToast} />
        <form>
          <div className="offre_search_bar__container__top">
            <div className="offre_search_bar__container__top__boxes">
              <div className="offre_search_bar__container__top__boxes__box">
                <label>{t("offresApresConnexionPage.searchBarMotsCles")}</label>
                <span className="p-input-icon-left">
                  <InputText
                    value={props.offers.motsClés}
                    onChange={(e) =>
                      props.handleUpdateOffers({
                        motsClés: e.target.value.toLowerCase(),
                      })
                    }
                    placeholder={t(
                      "offresApresConnexionPage.searchBarMotsClesPlaceHolder"
                    )}
                    className="box__input"
                  />
                </span>
              </div>
              <Divider layout="vertical" />
              <div className="offre_search_bar__container__top__boxes__box">
                <label>{t("offresApresConnexionPage.searchBarCategory")}</label>
                <MultiSelect
                  appendTo={document.getElementById("article_offre")}
                  value={props.offers.categorieOffre}
                  options={props.secondaryTables.offerCategoriesData}
                  onChange={(e) => {
                    props.handleUpdateOffers({
                      categorieOffre: e.target.value,
                    });
                    adaptTypeOfContract(e.target.value);
                  }}
                  optionLabel="label"
                  placeholder={t(
                    "offresApresConnexionPage.searchBarCategoryPlaceHolder"
                  )}
                  maxSelectedLabels={3}
                  selectedItemsLabel={`${props.offers.categorieOffre.length} options sélectionnées`}
                  maxLength={20}
                  className="box__input blanc"
                />
              </div>

              <Divider layout="vertical" />
              <div className="offre_search_bar__container__top__boxes__box">
                <label>{t("offresApresConnexionPage.searchBarSecteur")}</label>
                <MultiSelect
                  appendTo={document.getElementById("article_offre")}
                  value={props.offers.secteur}
                  options={props.secondaryTables.sectorOfOffersData}
                  onChange={(e) =>
                    props.handleUpdateOffers({
                      secteur: e.target.value,
                    })
                  }
                  optionLabel="label"
                  placeholder={t(
                    "offresApresConnexionPage.searchBarSecteurPlaceHolder"
                  )}
                  maxSelectedLabels={3}
                  selectedItemsLabel={`${props.offers.secteur.length} options sélectionnées`}
                  maxLength={20}
                  className="box__input blanc"
                />
              </div>
              <Divider layout="vertical" />
              <div className="offre_search_bar__container__top__boxes__box">
                <label>{t("offresApresConnexionPage.searchBarDomaine")}</label>
                <MultiSelect
                  appendTo={document.getElementById("article_offre")}
                  value={props.offers.domaine}
                  options={props.secondaryTables.domainsData}
                  onChange={(e) =>
                    props.handleUpdateOffers({
                      domaine: e.target.value,
                    })
                  }
                  optionLabel="label"
                  placeholder={t(
                    "offresApresConnexionPage.searchBarDomainePlaceHolder"
                  )}
                  maxSelectedLabels={3}
                  selectedItemsLabel={`${props.offers.domaine.length} options sélectionnées`}
                  maxLength={20}
                  className="box__input blanc"
                />
              </div>
            </div>
            <button
              type="submit"
              className="offre_search_bar__container__top__inputSearch mobile"
              onClick={(e) => {
                e.preventDefault();
                handleSearch();
              }}
            >
              <FaSearch />
            </button>
          </div>
          <div className="offre_search_bar__container__bottom">
            <>
              <ul className="offre_search_bar__container__bottom__list">
                <li>
                  <div className="input_lieu">
                    <p className="lieu_title">
                      {t("offresApresConnexionPage.searchBarBottomLieu")}
                    </p>
                    <InputText
                      value={props.offers.lieu.toLowerCase()}
                      onChange={(e) => {
                        props.handleUpdateOffers({
                          lieu: e.target.value,
                        });
                      }}
                      placeholder={t(
                        "offresApresConnexionPage.searchBarBottomLieuPlaceHolder"
                      )}
                      className="box__input blanc"
                    />
                  </div>
                </li>
                <li>
                  <label>
                    {t("offresApresConnexionPage.searchBarBottomTypeContrat")}
                  </label>
                  <MultiSelect
                    appendTo={document.getElementById("article_offre")}
                    value={props.offers.typeContrat}
                    options={typeOfContract}
                    onChange={(e) =>
                      props.handleUpdateOffers({
                        typeContrat: e.target.value,
                      })
                    }
                    disabled={isDisabled}
                    optionLabel="label"
                    placeholder={t(
                      "offresApresConnexionPage.searchBarBottomTypeContrat"
                    )}
                    maxSelectedLabels={3}
                    selectedItemsLabel={`${props.offers.typeContrat.length} options sélectionnées`}
                    maxLength={5}
                    className="box__input"
                  />
                </li>
                <li>
                  <label>
                    {t("offresApresConnexionPage.searchBarBottomNiveau")}
                  </label>
                  <MultiSelect
                    appendTo={document.getElementById("article_offre")}
                    value={props.offers.niveauEtudes}
                    options={props.secondaryTables.levelOfEducationsData.filter(
                      (level) => level.label !== "Indifférent"
                    )}
                    onChange={(e) =>
                      props.handleUpdateOffers({
                        niveauEtudes: e.target.value,
                      })
                    }
                    optionLabel="label"
                    placeholder={t(
                      "offresApresConnexionPage.searchBarBottomNiveau"
                    )}
                    maxSelectedLabels={3}
                    selectedItemsLabel={`${props.offers.niveauEtudes.length} options sélectionnées`}
                    className="box__input"
                  />
                </li>
                <li className="offre_search_bar__container__bottom__salaire">
                  <div className="display_salary_checkbox">
                    <Checkbox
                      checked={isVisibleSalary}
                      onChange={() => setIsVisibleSalary(!isVisibleSalary)}
                    />
                  </div>
                  {!isVisibleSalary && (
                    <span>
                      {t("offresApresConnexionPage.searchBarBottomSalaire")}
                    </span>
                  )}

                  {isVisibleSalary && (
                    <SliderSalaire
                      valueSalary={valueSalary}
                      categorie={props.offers.categorieOffre}
                      onChangeAction={setValueSalary}
                      isSearchBar={true}
                    />
                  )}
                </li>
                {props.offers.isSearched && (
                  <li className="search_bar_refresh search_bar_refresh__offres">
                    <i
                      className="pi pi-refresh"
                      style={{ fontSize: "1.5rem", fontWeight: "600" }}
                      onClick={() => {
                        props.handleUpdateOffers({
                          motsClés: "",
                          secteur: [],
                          domaine: [],
                          lieu: "",
                          typeContrat: [],
                          categorieOffre: [],
                          niveauEtudes: [],
                          isSearched: false,
                          currentSearch: "",
                        });
                        setValueSalary([0, 30000]);
                        handleEmptySearch();
                      }}
                    />
                  </li>
                )}
              </ul>
            </>
          </div>
          <div
            className="offre_search_bar__container__top__inputSearch desktop"
            onClick={(e) => {
              e.preventDefault();
              handleSearch();
            }}
          >
            <FaSearch />
          </div>
        </form>
        {props.offers.isSearched && (
          <div className="search_bar_save">
            <BtnBleuLeft
              btnTexte="Sauvegarder ma recherche"
              btnAction={() => {
                setVisibleNamingSearch(true);
              }}
            >
              <FaPlus />
            </BtnBleuLeft>
          </div>
        )}
        {visibleNamingSearch && (
          <ModalOffreSearchBar
            visibleNamingSearch={visibleNamingSearch}
            setVisibleNamingSearch={setVisibleNamingSearch}
            valueSalary={valueSalary}
            isVisibleSalary={isVisibleSalary}
          />
        )}

        {props.offers.savedSearches.length > 0 && (
          <div className="offre_search_bar__container__top__boxes__box myOffers_button">
            <BtnBleu
              btnAction={() => {
                setVisibleListOfSearch(true);
              }}
            >
              Mes recherches
            </BtnBleu>
          </div>
        )}
        {visibleListOfSearch && (
          <Modal
            visible={visibleListOfSearch}
            setVisible={setVisibleListOfSearch}
            header={`Mes recherches sauvegardées`}
          >
            {props.offers.savedSearches.length > 0 ? (
              props.offers.savedSearches.map((search, index) => (
                <SearchCard
                  key={index}
                  value={search}
                  onSelect={(e) => {
                    completeSearch(e);
                  }}
                  onDelete={(e) => deleteSearch(e)}
                  setVisibleListOfSearch={setVisibleListOfSearch}
                ></SearchCard>
              ))
            ) : (
              <span className="no_offers_saved">Aucun filtre disponible</span>
            )}
          </Modal>
        )}
      </div>
    </div>
  );
};

OffreSearchBar.propTypes = {
  id: PropTypes.string,
  handleUpdateOffers: PropTypes.func,
  offers: PropTypes.object,
  auth: PropTypes.object,
  secondaryTables: PropTypes.object,
  setUrl: PropTypes.func,
};

const mapStateToProps = (state) => ({
  offers: state.offers,
  auth: state.auth,
  secondaryTables: state.secondaryTables,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateOffers: (value) => {
    dispatch(updateOffers(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OffreSearchBar);
