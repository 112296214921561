import React from "react";
import PropTypes from "prop-types";
import "./GroupeEventCard.scss";
import event1 from "assets/Event1.webp";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import { FaEllipsisH, FaUsers } from "react-icons/fa";
import { Menu } from "primereact/menu";
import { useRef } from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";
import { formatDate, stripHtml, truncate } from "Services/functions";
import { updateUniversities } from "Redux/Actions/universitiesActions";
import { HTMLParseOptions } from "Services/htmlParseOptions";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const API_LINK = `${process.env.REACT_APP_BASE_URL_API}`.replace("/api", "");

const GroupeEventCard = (props) => {
  const menu = useRef(null);
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  const items = [
    {
      label: t("groupPublication.modals.edit"),
      command: () => handleAction("EDIT"),
    },
    { separator: true },
    {
      label: t("groupPublication.modals.delete"),
      command: () => handleAction("DELETE"),
    },
  ];

  const handleAction = (action) => {
    if (action === "DELETE")
      props.setDeleteEvent({
        id: props.evenement.id,
        title: props.evenement.title,
        display: true,
      });
    else if (action === "EDIT") {
      props.setEditEvent({
        groupId: props.evenement.associatedGroup.id,
        groupEvent: props.evenement,
      });
      props.setEditEventDisplay(true);
    }
  };

  // Fonction pour naviguer vers la page de détails de l'événement
  const navigateToDetails = () =>
    navigate(`/group_event-detail/${props.evenement.id}`);

  const hasManagementRights = () => {
    // Si l'utilisateur est admin ou créateur de l'événement
    // Il peut modifier ou supprimer l'événement
    if (
      props.auth.isAdmin ||
      props.evenement.createdBy.id === props.auth.userConnected.id
    )
      return true;
    return false;
  };

  return (
    <div
      className="group_event_container cardHoverGroupEvent"
      onClick={() => {
        props.onClick && props.onClick();
      }}
    >
      <div className="group_event_container__top">
        <div className="group_event_container__top__categorie">
          <span className="etiquette"> {props.evenement.category.label} </span>
        </div>
        <div
          className="group_event_container__image"
          onClick={navigateToDetails}
        >
          {props.url == "event1" ? (
            <img src={event1} alt="Fond événement" />
          ) : (
            <img src={props.url} alt="Fond événement" />
          )}
        </div>
      </div>
      <div className="group_event_container__corps">
        <div className="group_event_container__corps__debutdate">
          {props.debutDate}
          {props.groupe &&
            props.evenement.createdBy &&
            hasManagementRights() && (
              <>
                <Menu
                  appendTo={document.getElementById("app")}
                  model={items}
                  popup
                  ref={menu}
                  style={{ textAlign: "center" }}
                />
                <div
                  onClick={(event) => menu.current.toggle(event)}
                  className="btn-point"
                >
                  <BtnBlanc
                    btnAction={() => (event) => menu.current.toggle(event)}
                  >
                    <FaEllipsisH />
                  </BtnBlanc>
                </div>
              </>
            )}
        </div>
        <div
          onClick={navigateToDetails}
          className="group_event_container__corps__findate"
        >
          {props.finDate}
        </div>
        <div onClick={navigateToDetails} className="event_groupe">
          <FaUsers /> {props.groupeName}
        </div>
        <div
          onClick={navigateToDetails}
          className="group_event_container__corps__inscription"
        >
          {props.inscription &&
            (props.participants?.length >= props.maxParticipants
              ? t("evenements.closedInscriptions")
              : `${t("evenements.openInscriptions")} ${
                  props?.registerEnd != undefined
                    ? t("evenements.openInscriptionsUntil", {
                        date: formatDate(props.registerEnd),
                      })
                    : ""
                }`)}
        </div>

        <div
          onClick={navigateToDetails}
          className="group_event_container__corps__title"
        >
          {truncate(props.evenement.title, 26)}
        </div>
        <div
          onClick={navigateToDetails}
          className="group_event_container__corps__chapeau"
        >
          <span>
            {parse(
              truncate(stripHtml(props.evenement?.description), 80),
              HTMLParseOptions
            )}
          </span>
        </div>
      </div>
      <div
        onClick={navigateToDetails}
        className="group_event_container__bottom"
      >
        <div className="group_event_container__bottom__lien">
          {t("aLaUne.savoirPlus")}
        </div>
        <div className="group_event_container__bottom__logo">
          <img
            src={
              API_LINK +
              "/media/logos_univs/logo_pantheon_sorbonne_transparent_couleur.webp"
            }
            alt={"Logo Université"}
            title={"Logo Université"}
          />
        </div>
      </div>
    </div>
  );
};

GroupeEventCard.propTypes = {
  debutDate: PropTypes.string,
  evenement: PropTypes.object,
  finDate: PropTypes.string,
  inscription: PropTypes.bool,
  participants: PropTypes.array,
  maxParticipants: PropTypes.number,
  logo: PropTypes.any,
  image: PropTypes.any,
  groupe: PropTypes.bool,
  groupeName: PropTypes.string,
  url: PropTypes.string,
  onClick: PropTypes.func,
  universities: PropTypes.object,
  handleUpdateUniversities: PropTypes.func,
  registerEnd: PropTypes.string,
  auth: PropTypes.object,
  setDeleteEvent: PropTypes.func,
  setEditEvent: PropTypes.func,
  setEditEventDisplay: PropTypes.func,
};

const mapStateToProps = (state) => ({
  universities: state.universities,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateUniversities: (value) => {
    dispatch(updateUniversities(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupeEventCard);
