import React, { useEffect, useRef, useState } from "react";
import Margin from "../Margin";
import Doughnut from "../Charts/Doughnut";
import Bar from "../Charts/Bar";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import OffersTable from "../Tables/OffersTable";
import PropTypes from "prop-types";

import {
  getOffersByCategory,
  getOffersByCategoryByMonth,
  getOffersByCategoryByMonthOptions,
  getOffersByCategoryOptions,
  getOffersByDiffuserByCategoriesMonthly,
  getOffersByDiffuserByCategoriesMonthlyOptions,
  getOffersByDiffuserByType,
  getOffersByDiffuserByTypeOptions,
  getOffersByUniProfile,
  getOffersByUniProfileOptions,
  getOffersByUniType,
  getOffersByUniTypeOptions,
  getOffersNumberByMonth,
  getOffersNumberByMonthOptions,
  getOffersViewsByMonth,
  getOffersViewsByMonthOptions,
  getOffersViewsCategoriesByMonth,
  getOffersViewsCategoriesByMonthOptions,
} from "../Data/Offres";
import Line from "../Charts/Line";
import { connect } from "react-redux";
import Loader from "Components/Loader/loader";

/**
 * Composant Offres sert à afficher les graphiques de la partie offres
 * à l'aide  de primeReact et de composants custom.
 *
 * Les données ainsi ques les options proviennent du dossier Data, et sont
 * stockées grâce aux hooks {@link useState}.
 *
 * Les composants custom utilisés :
 * - {@link Doughnut}
 * - {@link Bar}
 */
const Offres = (props) => {
  /* Données pour graphique des offres par catégorie */
  const [offersByCategoryData] = useState(
    getOffersByCategory(props.offers.offersData)
  );
  const [offersByCategoryOptions] = useState(
    getOffersByCategoryOptions(props.offers.offersData)
  );

  const op = useRef();

  const [offerNumberByMonthData] = useState(getOffersNumberByMonth());
  const [offerNumberByMonthOptions] = useState(getOffersNumberByMonthOptions());

  const [offersByCategoryByMonthData] = useState(getOffersByCategoryByMonth());
  const [offersByCategoryByMonthOptions] = useState(
    getOffersByCategoryByMonthOptions()
  );

  const [offersViewByMonthData] = useState(getOffersViewsByMonth());
  const [offersViewsByMonthOptions] = useState(getOffersViewsByMonthOptions());

  const [offersByUniType] = useState(getOffersByUniType());
  const [offersByUniTypeOptions] = useState(getOffersByUniTypeOptions());

  const [offersByUniProfileData] = useState(getOffersByUniProfile());
  const [offersByUniProfileOptions] = useState(getOffersByUniProfileOptions());

  const [offersViewsCategoriesByMonthData] = useState(
    getOffersViewsCategoriesByMonth()
  );
  const [offersViewsCategoriesByMonthOptions] = useState(
    getOffersViewsCategoriesByMonthOptions()
  );

  const [selectedDiffuser, setSelectedDiffuser] = useState("Diffuseur 1");

  const [offersByDiffuserByType] = useState(getOffersByDiffuserByType());
  const [offersByDiffuserByTypeOptions, setOffersByDiffuserByTypeOptions] =
    useState(getOffersByDiffuserByTypeOptions(selectedDiffuser));

  const [offersByDiffuserByCategoriesMonthlyData] = useState(
    getOffersByDiffuserByCategoriesMonthly()
  );
  const [
    offersByDiffuserByCategoriesMonthlyOptions,
    setOffersByDiffuserByCategoriesMonthlyOptions,
  ] = useState(getOffersByDiffuserByCategoriesMonthlyOptions(selectedDiffuser));

  useEffect(() => {
    setOffersByDiffuserByCategoriesMonthlyOptions(
      getOffersByDiffuserByCategoriesMonthlyOptions(selectedDiffuser)
    );
    setOffersByDiffuserByTypeOptions(
      getOffersByDiffuserByTypeOptions(selectedDiffuser)
    );
  }, [selectedDiffuser]);

  const handleClick = (e) => {
    console.log(e);
    setSelectedDiffuser(e.target.outerText);
    op.current.hide();
  };

  return (
    <div className="stats_container">
      <div className="stats_container__component-center">
        {/* Graphique des offres par type */}
        {offersByCategoryData && offersByCategoryOptions ? (
          <Doughnut
            data={offersByCategoryData}
            options={offersByCategoryOptions}
          />
        ) : (
          <Loader />
        )}
      </div>
      <Margin position="bottom" size="" />
      <Bar
        data={offersByCategoryByMonthData}
        options={offersByCategoryByMonthOptions}
      />
      <Margin position="bottom" size="" />
      <div className="stats_container__component-center">
        <Doughnut data={offersByUniType} options={offersByUniTypeOptions} />
      </div>
      <Margin position="bottom" size="" />
      <div className="stats_container__component-center">
        <Doughnut
          data={offersByUniProfileData}
          options={offersByUniProfileOptions}
        />
      </div>
      <Margin position="bottom" size="" />
      <Bar data={offerNumberByMonthData} options={offerNumberByMonthOptions} />
      <Margin position="bottom" size="" />
      <Bar data={offersViewByMonthData} options={offersViewsByMonthOptions} />
      <Margin position="bottom" size="" />
      <Line
        data={offersViewsCategoriesByMonthData}
        options={offersViewsCategoriesByMonthOptions}
      />
      <Margin position="bottom" size="" />
      <Button
        type="button"
        label={selectedDiffuser}
        onClick={(e) => op.current.toggle(e)}
      />
      <OverlayPanel ref={op}>
        <Button id="1" label="Diffuseur 1" onClick={handleClick} />
        <Button id="2" label="Diffuseur 2" onClick={handleClick} />
        <Button id="3" label="Diffuseur 3" onClick={handleClick} />
        <Button id="4" label="Diffuseur 4" onClick={handleClick} />
        <Button id="5" label="Diffuseur 5" onClick={handleClick} />
        <Button id="6" label="Diffuseur 6" onClick={handleClick} />
        <Button id="7" label="Diffuseur 7" onClick={handleClick} />
        <Button id="8" label="Diffuseur 8" onClick={handleClick} />
        <Button id="9" label="Diffuseur 9" onClick={handleClick} />
        <Button id="10" label="Diffuseur 10" onClick={handleClick} />
      </OverlayPanel>
      <Margin position="bottom" size="xs" />
      <div className="stats_container__component-center">
        <Doughnut
          data={offersByDiffuserByType}
          options={offersByDiffuserByTypeOptions}
        />
      </div>
      <Margin position="bottom" size="" />
      <Bar
        data={offersByDiffuserByCategoriesMonthlyData}
        options={offersByDiffuserByCategoriesMonthlyOptions}
      />
      <Margin position="bottom" size="" />
      <OffersTable />
      <Margin position="bottom" size="s" />
    </div>
  );
};

Offres.propTypes = {
  offers: PropTypes.any,
};

const mapStateToProps = (state) => ({
  offers: state.offers,
});

export default connect(mapStateToProps)(Offres);
