import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import Loader from "Components/Loader/loader";
import Modal from "Components/Modal/Modal";
import { updateAuth } from "Redux/Actions/authActions";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import "./HeaderUserLogin.scss";

const HeaderUserLogin = (props) => {
  const defaultValues = {
    username: "",
    password: "",
  };

  useEffect(() => {
    !props.loginVisible && setIsLoging(false);
  }, [props.loginVisible]);
  // variables du formulaire
  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm({ defaultValues });

  const loginSubmission = (data) => {
    var apiResponse = axios
      .post(`${process.env.REACT_APP_BASE_URL_API}/login_check`, data, {
        headers: props.auth.token
          ? {
              accept: "application/json",
              Authorization: `Bearer ${props.auth.token}`,
            }
          : {
              accept: "application/json",
            },
      })
      .catch((error) => {
        apiResponse = error;
      })
      .then((response) => {
        apiResponse = response;
      })
      .finally(() => {
        if (
          apiResponse &&
          apiResponse.status === 200 &&
          apiResponse.data.token
        ) {
          document.cookie = `t=${apiResponse.data.token}`;
          // Refresh de la page
          window.location.reload();
        } else {
          setError("password", {
            type: "manual",
            message: "Identifiants incorrects",
          });
          setIsLoging(false);
        }
      });
  };

  /**
   * Afffichage d'une erreur si nécessaire dans le formulaire
   * @param {String} name nom du champ
   * @returns {FieldError, JSX.Element}
   */
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const [isloging, setIsLoging] = useState(false);

  return (
    <>
      <Modal
        header="Connexion"
        visible={props.loginVisible}
        setVisible={props.setLoginVisible}
        width="30vw"
        height="50vw"
        className={"modal modal-login"}
      >
        <form className="login__form" onSubmit={handleSubmit(loginSubmission)}>
          <div className="login__form__field">
            <h4 htmlFor="email">Adresse email</h4>
            <Controller
              name="username"
              control={control}
              rules={{
                required: "L'email est obligatoire",
              }}
              render={({ field }) => (
                <InputText
                  {...field}
                  placeholder="Adresse email"
                  className="login__form__field-email"
                  type="email"
                />
              )}
            />
            {getFormErrorMessage("email")}
          </div>
          <div className="login__form__field">
            <h4 htmlFor="password">Mot de passe</h4>
            <Controller
              name="password"
              control={control}
              rules={{
                required: "Le mot de passe est obligatoire",
              }}
              render={({ field }) => (
                <Password
                  {...field}
                  placeholder={"Mot de passe"}
                  className="login__form__field-password"
                  feedback={false}
                />
              )}
            />
            {getFormErrorMessage("password")}
          </div>
          <div className="login__form__button">
            <BtnBleu
              btnAction={() => {
                setIsLoging(true);
              }}
            >
              {isloging ? <Loader /> : "Se connecter"}
            </BtnBleu>
          </div>
        </form>
      </Modal>
    </>
  );
};

HeaderUserLogin.propTypes = {
  auth: PropTypes.object,
  handleAuth: PropTypes.func,
  loginVisible: PropTypes.bool,
  setLoginVisible: PropTypes.func,
  redirect: PropTypes.string,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  handleAuth: (value) => {
    dispatch(updateAuth(value));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(HeaderUserLogin);
