import React, { useEffect } from "react";
import { Paginator } from "primereact/paginator";
import "./Pagination.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updatePagination } from "Redux/Actions/paginationActions";

/**
 * Permet de gérer et d'afficher la pagination
 */
const Pagination = (props) => {
  const onBasicPageChange = (event) => {
    props.handleUpdatePagination({ currentPage: event.page + 1 });
    props.handleUpdatePagination({ paginationBasicFirst: event.first });
    props.handleUpdatePagination({ paginationBasicRows: event.rows });
    if (props.pagination.currentPage !== 1) {
      window.scroll({
        top: props.refItemParent.current.offsetTop,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    if (props.pagination.paginationBasicFirst > 0) {
      window.scroll({
        top: props.refItemParent.current.offsetTop,
        behavior: "smooth",
      });
    }
  }, [props.pagination.paginationBasicFirst]);
  return (
    <div className="pagination">
      {props.data ? (
        <Paginator
          first={props.pagination.paginationBasicFirst}
          pageLinkSize={screen.width <= 830 ? 1 : 3}
          rows={props.pagination.paginationBasicRows}
          totalRecords={props.data.length}
          leftContent={"Page précédente"}
          rightContent={"Page suivante"}
          onPageChange={onBasicPageChange}
        />
      ) : (
        <Paginator />
      )}
    </div>
  );
};
Pagination.propTypes = {
  auth: PropTypes.object,
  data: PropTypes.array,
  handleUpdatePagination: PropTypes.func,
  pagination: PropTypes.any,
  refItemParent: PropTypes.object,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
  pagination: state.pagination,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdatePagination: (value) => dispatch(updatePagination(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);
