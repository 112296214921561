import React, { useRef } from "react";
import "./GroupePublicationCreation.scss";
import { InputText } from "primereact/inputtext";
import { FaRegCalendarCheck, FaPaperclip } from "react-icons/fa";
import { useState } from "react";
import PropTypes from "prop-types";
import ModalCreationEvenementGroupe from "Components/Modal/ModalCreationEvenementGroupe/ModalCreationEvenementGroupe";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { connect } from "react-redux";
import { updateGroups } from "Redux/Actions/groupsActions";
import neutralUser from "assets/neutralUser.png";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";

const GroupePublicationCreation = (props) => {
  const { t } = useTranslation("common");
  const [visibleModalEvent, setVisibleModalEvent] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      content: "",
      availableForComments: true,
      label: "",
      sourceGroup: undefined,
    },
  });

  const [file, setFile] = useState(null);
  const [isPublishing, setIsPublishing] = useState(false);

  const hiddenFileRef = useRef(null);

  /**
   * Fonction qui permet d'envoyer une requête post pour créer une publication
   * @param {{content: string, availableForComments: boolean, label: string, sourceGroup: string}} data données du formulaire
   */
  const postPublication = (data) => {
    let response = undefined;
    // On crée un FormData qui sera envoyé au serveur
    const formData = new FormData();
    // On ajoute les données du formulaire au formData
    for (const key in data) formData.append(key, data[key]);
    // On envoie le formData au serveur
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL_API}${"/publications/create"}`,
        formData,
        {
          headers: props.auth.token
            ? {
                accept: "application/json",
                Authorization: `Bearer ${props.auth.token}`,
              }
            : {
                accept: "application/json",
              },
        }
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {
        props.auth.toast.current.show({
          severity: "error",
          summary: error.response.data["hydra:title"],
          detail: error.response.data["hydra:description"],
          sticky: true,
        });
      })
      .finally(async () => {
        if (response) {
          reset();
          setFile(null);
          setIsPublishing(false);
          props.auth.toast.current.show({
            severity: "success",
            summary: "Succès : ",
            detail: "La publication a bien été créée",
            sticky: true,
          });
          props.reloadPublications();
        }
      });
  };

  /**
   * Fonction s'occuppant de l'upload d'un fichier
   * @param {Event} event
   * @param {Object} event[].files Liste des fichiers sélectionnés
   */
  const uploadHandler = (event) => {
    const file = event.target.files[0];
    const fileReader = new FileReader();

    // Ajout événement : dès que le fichier est chargé, on l'affiche dans le formulaire + toast succés
    fileReader.onload = () => {
      setFile(file);
      props.auth.toast.current.show({
        severity: "success",
        summary: "Succès : ",
        detail: "Le fichier " + file.name + " a bien été téléchargé",
        sticky: true,
      });
    };
    // Démarrage du chargement du fichier
    fileReader.readAsDataURL(file);
  };

  /**
   *  Fonction qui va remplir la propriété sourceGroup avec le groupe actuel
   * @param {{content: string, availableForComments: boolean, label: string, sourceGroup: string}} data données du formulaire
   * @returns données du formulaire avec champ sourceGroup renseigné
   */
  const assignSourceGroup = (data) => {
    data = {
      ...data,
      sourceGroup: "/api/groups/" + props.currentGroupId.toString(),
    };
    return data;
  };

  /**
   *  Fonction qui va remplir la propriété documentFile avec le fichier uploadé
   * @param {{content: string, availableForComments: boolean, documentFile: FilePrototype, label: string, sourceGroup: string}} data données du formulaire
   * @returns données du formulaire avec champ documentFile renseigné
   */
  const assignFile = (data) => {
    if (file)
      data = {
        ...data,
        documentFile: file,
      };
    return data;
  };

  const onSubmit = (data) => {
    setIsPublishing(true);
    data = assignSourceGroup(data);
    data = assignFile(data);
    postPublication(data);
  };

  /**
   * Afffichage d'une erreur si nécessaire dans le formulaire
   * @param {String} name nom du champ
   * @returns {FieldError, JSX.Element}
   */
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  return (
    <div>
      <ModalCreationEvenementGroupe
        visibleModalEvent={visibleModalEvent}
        currentGroupId={props.currentGroupId}
        setVisibleModalEvent={setVisibleModalEvent}
      />

      <div className="groupe_publication">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="groupe_publication__form"
        >
          <div className="groupe_publication__top">
            <h4>{t("groupPublication.create")}</h4>
            {getFormErrorMessage("content")}
            <div className="groupe_publication__top__input">
              <img
                src={
                  props.currentUser.imageUrl
                    ? props.currentUser.imageUrl
                    : neutralUser
                }
                alt="Mon avatar"
                title="Mon avatar"
              />
              <Controller
                name="content"
                rules={{
                  required: t("groupPublication.form.required"),
                }}
                control={control}
                render={({ field }) => (
                  <InputText
                    {...field}
                    placeholder={t("groupPublication.form.placeholder")}
                  />
                )}
              />
            </div>
          </div>
          <div className="groupe_publication__bottom">
            <div className="boutons_desktop">
              <Button
                className="publication_btn"
                onClick={() => setVisibleModalEvent(!visibleModalEvent)}
                type="button"
              >
                {t("groupPublication.groupEventPropose")}
                <FaRegCalendarCheck className={"publication_btn_icon"} />
              </Button>
            </div>
            <div className="boutons_mobile">
              <Button
                className="publication_btn"
                type="button"
                onClick={() => setVisibleModalEvent(!visibleModalEvent)}
              >
                <FaRegCalendarCheck />
              </Button>
            </div>
            <div className="boutons_desktop">
              <>
                {file ? (
                  <>
                    <Button
                      action="button"
                      className={"publication_btn uploaded"}
                      onClick={() => {
                        setFile(null);
                        props.auth.toast.current.show({
                          severity: "error",
                          summary: "Annulation",
                          detail: "Le fichier a bien été supprimé",
                          sticky: true,
                        });
                      }}
                    >
                      {file.name || "Nom du fichier"}
                      <input
                        type={"file"}
                        style={{ display: "none" }}
                        onChange={uploadHandler}
                        ref={hiddenFileRef}
                      />
                      <FaPaperclip className={"publication_btn_icon"} />
                    </Button>
                  </>
                ) : (
                  <Button
                    type="button"
                    className={"publication_btn"}
                    onClick={() => hiddenFileRef.current.click()}
                  >
                    {t("groupPublication.addDocument")}
                    <FaPaperclip className={"publication_btn_icon"} />
                    <input
                      type={"file"}
                      style={{ display: "none" }}
                      onChange={uploadHandler}
                      ref={hiddenFileRef}
                    />
                  </Button>
                )}
              </>
            </div>
            <div className="boutons_mobile">
              <>
                {file ? (
                  <Button
                    type="button"
                    className={"publication_btn uploaded"}
                    title={file.name || "Nom du fichier"}
                    onClick={() => {
                      setFile(null);
                      props.auth.toast.current.show({
                        severity: "error",
                        summary: "Annulation",
                        detail: "Le fichier a bien été supprimé",
                        sticky: true,
                      });
                    }}
                  >
                    <input
                      type={"file"}
                      style={{ display: "none" }}
                      onChange={uploadHandler}
                      ref={hiddenFileRef}
                    />
                    <FaPaperclip className={"publication_btn_icon"} />
                  </Button>
                ) : (
                  <Button
                    type="button"
                    className={"publication_btn"}
                    onClick={() => hiddenFileRef.current.click()}
                  >
                    <input
                      type={"file"}
                      style={{ display: "none" }}
                      onChange={uploadHandler}
                      ref={hiddenFileRef}
                    />
                    <FaPaperclip />
                  </Button>
                )}
              </>
            </div>

            <Button className="publication_btn" loading={isPublishing}>
              {!isPublishing && t("groupPublication.publish")}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

GroupePublicationCreation.propTypes = {
  groups: PropTypes.object,
  handleUpdateGroups: PropTypes.func,
  currentUser: PropTypes.object.isRequired,
  currentGroupId: PropTypes.number.isRequired,
  auth: PropTypes.object,
  reloadPublications: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  groups: state.groups,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateGroups: (value) => {
    dispatch(updateGroups(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupePublicationCreation);
