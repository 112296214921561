import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Modal from "../Modal";
import axios from "axios";
import "./ModalAjoutMembres.scss";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import SearchPeople from "Components/SearchPeople/SearchPeople";
import { useMemo } from "react";
import { fetchGet } from "Services/api";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";

const ModalDroitsAdminGroupe = ({ visible, setVisible, auth, states }) => {
  const [member, setMember] = useState(null);
  const [error, setError] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation("common");

  const inviteUser = () => {
    setIsLoading(true);
    const body = {
      groupSource: "/api/groups/" + visible.group.id,
      user: "/api/users/" + member.id,
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL_API}/group_members/invite`,
        body,
        {
          headers: auth.token
            ? {
                accept: "application/json",
                Authorization: `Bearer ${auth.token}`,
              }
            : {
                accept: "application/json",
              },
        }
      )
      .then(() => {
        setMember(null);
        auth.toast.current.show({
          severity: "success",
          summary: "Succès",
          detail: "L'utilisateur a bien été invité",
          life: 5000,
        });
        setVisible({ ...visible, state: false });
      })
      .catch((error) => {
        auth.toast.current.show({
          severity: "error",
          summary: error?.response?.data["hydra:title"] || "Erreur",
          detail:
            error?.response?.data["hydra:description"] ||
            "Une erreur est survenue",
          life: 5000,
        });
      })
      .finally(() => setIsLoading(false));
  };

  /**
   * It checks if a user is already a member of a group
   * @param member - the user to add to the group (and to check)
   * @returns A boolean value.
   */
  const isNotAlreadyMember = async (member) => {
    const statuses = [
      states.groupMemberStatesData.find((state) => state.label === "INVITE")
        ?.id,
      states.groupMemberStatesData.find((state) => state.label === "ACTIF")?.id,
      states.groupMemberStatesData.find((state) => state.label === "DEMANDE")
        ?.id,
    ];
    if (!statuses) return false;
    const isMember = await fetchGet("/group_members", auth.token, {
      groupSource: visible.group.id,
      user: member.id,
      "groupMemberStatus[]": statuses,
    });
    if (isMember && isMember?.length > 0) return false;
    return true;
  };

  useMemo(() => {
    if (error) setError(null);
  }, [member]);

  return (
    <Modal
      visible={visible.state}
      setVisible={() => setVisible({ ...visible, state: false })}
      header="Ajout utilisateurs"
      className="modal modal_inviter"
      blockScroll
    >
      <div className="inviter__form">
        <SearchPeople className="content" setUser={setMember}></SearchPeople>
        {error && <p className="inviter__form-error">{error}</p>}
        <div className="inviter__form-buttons">
          <Button
            loading={isLoading}
            onClick={async () => {
              if (member && (await isNotAlreadyMember(member))) inviteUser();
              else setError(t("addPeople.errorDetail"));
            }}
          >
            {t("addMember.invite")}
          </Button>
          <BtnBlanc
            btnTexte="Annuler"
            action={"button"}
            btnAction={() => {
              setVisible({ ...visible, state: false });
              setMember(null);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

ModalDroitsAdminGroupe.propTypes = {
  visible: PropTypes.object.isRequired,
  setVisible: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  states: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  states: state.states,
});

export default connect(mapStateToProps)(ModalDroitsAdminGroupe);
