import { UPDATE_OFFERS } from "../Actions/offersActions.js";

const INITIAL_STATE = {
  motsClés: "",
  secteur: [],
  domaine: [],
  lieu: "",
  typeContrat: [],
  categorieOffre: [],
  niveauEtudes: [],
  salaireMin: "",
  salaireMax: "",
  offersData: [],
  isSearched: false,
  statusData: [],
  status: "",
  date: "",
  entreprise: "",
  diffuseur: "",
  savedSearches: [],
  currentSearch: "",
  currentTransmitter: [],
};

const filterOffersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_OFFERS: {
      return {
        ...state,
        ...action.value,
      };
    }
    default:
      return state;
  }
};

export default filterOffersReducer;
