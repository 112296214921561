function formatDataBarLine(data, options, type) {
  const formattedData = [
    data.labels.map((label) => {
      return label;
    }),
  ];
  formattedData.at(0).unshift([""]);
  data.datasets.forEach((dataset) => {
    let tempData = dataset.data;
    tempData.unshift(dataset.label);
    formattedData.push(tempData);
  });
  const title =
    options.plugins.title === undefined
      ? options.plugins.legend.title.text
      : options.plugins.title.text;
  import("xlsx").then((xlsx) => {
    const workBook = xlsx.utils.book_new();
    const workSheetData = formattedData;
    const workSheet = xlsx.utils.aoa_to_sheet(workSheetData);
    xlsx.utils.book_append_sheet(workBook, workSheet, type);
    xlsx.writeFile(
      workBook,
      title
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "")
        .replace(/[^a-z0-9]/gi, "_")
        .toLowerCase() + ".xlsx"
    );
  });
}

/**
 *
 * @param {Object[]} data Données du graphique au format ChartJS
 * @param {Object[]} data.datasets Couleur et valeur pour chaque donnée
 * @param {String[]} data.labels Titre pour chaque donnée
 * @param {Object} options Options du graphique au format ChartJS
 * @param {Object[]} options.plugins Les plugins du graphique
 * @param {String} type Nom de la page excel
 *
 */
function formatDataPieDoughnut(data, options, type) {
  import("xlsx").then((xlsx) => {
    /* Formattage des données (valeurs) du graphique */
    const formattedData = [].concat.apply([], data.datasets.at(0).data);
    const title =
      options.plugins.title === undefined
        ? options.plugins.legend.title.text
        : options.plugins.title.text;
    /* Ajout du champs total au label + sa valeur */
    data.labels.push("TOTAL");
    formattedData.push(formattedData.reduce((a, b) => a + b, 0));
    /* Création du fichier Excel */
    const workBook = xlsx.utils.book_new();
    /* Ajout des colonnes et lignes au fichier Excel */
    const workSheetData = [data.labels, formattedData];
    const workSheet = xlsx.utils.aoa_to_sheet(workSheetData);
    xlsx.utils.book_append_sheet(workBook, workSheet, type);
    /* Enregistrement du fichier Excel */
    xlsx.writeFile(
      workBook,
      title
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "")
        .replace(/[^a-z0-9]/gi, "_")
        .toLowerCase() + ".xlsx"
    );
  });
}

function formatDataUsersTable(data, options) {
  const formattedData = data.map((row) => {
    return [
      row.name,
      row.numberConnections,
      row.registerDate,
      row.lastConnectionDate,
      row.uni,
      row.level,
    ];
  });
  import("xlsx").then((xlsx) => {
    const workBook = xlsx.utils.book_new();
    const workSheetData = [options.map((col) => col.header), ...formattedData];
    const workSheet = xlsx.utils.aoa_to_sheet(workSheetData);
    xlsx.utils.book_append_sheet(
      workBook,
      workSheet,
      "Statistiques utilisateurs"
    );
    xlsx.writeFile(workBook, "statsUtilisateurs.xlsx");
  });
}

function formatDataOffersTable(data, options) {
  import("xlsx").then((xlsx) => {
    const newData = data.map((row) => {
      return [
        row.status,
        row.offersNumber,
        row.applicationsNumber,
        row.viewsNumber,
        row.applicationButtonClickNumber,
        row.diffuserNumber,
        row.siteNumber,
        row.companyPortalNumber,
      ];
    });
    const workBook = xlsx.utils.book_new();
    const workSheetData = [options.map((col) => col.header), ...newData];
    const workSheet = xlsx.utils.aoa_to_sheet(workSheetData);
    xlsx.utils.book_append_sheet(workBook, workSheet, "Statut des offres");
    xlsx.writeFile(workBook, "statutOffres.xlsx");
  });
}

function formatDataCompaniesTable(data, options) {
  import("xlsx").then((xlsx) => {
    const newData = data.map((row) => {
      return [row.name, row.companiesNumber];
    });
    const workBook = xlsx.utils.book_new();
    const workSheetData = [options.map((col) => col.header), ...newData];
    console.log(workSheetData);
    const workSheet = xlsx.utils.aoa_to_sheet(workSheetData);
    xlsx.utils.book_append_sheet(
      workBook,
      workSheet,
      "Entreprises par secteur"
    );
    xlsx.writeFile(workBook, "secteurs.xlsx");
  });
}

/**
 * @param {String} dataType Type du graphique (bar, line, pie, xx-table, doughnut)
 * @param {Object[]} data Données du graphique au format ChartJS
 * @param {Object[]} options Options du graphique au format ChartJS
 * @param {String} type Nom de la page excel
 *
 * Fonction qui va répartir les arguments dans les fonctions correspondantes au type de graph
 *
 * {@link formatDataBarLine}
 *
 * {@link formatDataPieDoughnut}
 *
 * {@link formatDataUsersTable}
 *
 * {@link formatDataOffersTable}
 *
 * {@link formatDataCompaniesTable}
 */
export const exportForExcel = (
  dataType,
  data,
  options,
  type = "statistiques"
) => {
  switch (dataType) {
    case "bar":
    case "line":
      formatDataBarLine(data, options, type);
      break;
    case "doughnut":
    case "pie":
      formatDataPieDoughnut(data, options, type);
      break;
    case "users-table":
      formatDataUsersTable(data, options);
      break;
    case "offers-table":
      formatDataOffersTable(data, options);
      break;
    case "companies-table":
      formatDataCompaniesTable(data, options);
      break;
    case "default":
      break;
  }
};
