export const UPDATE_AUTH = "UPDATE_AUTH";
export const UPDATE_EDUCATIONS = "UPDATE_EDUCATIONS";
export const UPDATE_EXPERIENCE = "UPDATE_EXPERIENCE";
export const UPDATE_BIRTHDAY = "UPDATE_BIRTHDAY";
export const UPDATE_COMPLETION_LEVEL = "UPDATE_COMPLETION_LEVEL";
export const UPDATE_EVENTS_REGISTERED = "UPDATE_EVENTS_REGISTERED";

export const updateAuth = (value) => ({
  type: UPDATE_AUTH,
  value,
});

export const updateEducation = (value) => ({
  type: UPDATE_EDUCATIONS,
  value,
});
export const updateExperience = (value) => ({
  type: UPDATE_EXPERIENCE,
  value,
});

export const updateBirthday = (value) => ({
  type: UPDATE_BIRTHDAY,
  value,
});

export const updateCompletionLevel = (value) => ({
  type: UPDATE_COMPLETION_LEVEL,
  value,
});

export const updateEventsRegistered = (value) => ({
  type: UPDATE_EVENTS_REGISTERED,
  value,
});
