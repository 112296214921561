import React, { useEffect, useRef, useState } from "react";
import "./EvenementsCreation.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { addLocale, locale } from "primereact/api";
import { getFrenchLocale } from "Components/Stats/Data/Const";
import { Checkbox } from "primereact/checkbox";
import { formatDateDatabase, isJsonParsable } from "Services/functions";
import { fetchGet, fetchPost, useFetchGet } from "Services/api";
import { Dropdown } from "primereact/dropdown";
import Loader from "Components/Loader/loader";
import ChargementImage from "Components/ChargementImage/ChargementImage";
import { DateTime } from "luxon";
import { Toast } from "primereact/toast";
import { FaWheelchair } from "react-icons/fa";
import Brouillon from "Components/Brouillons/Brouillon";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import Modal from "Components/Modal/Modal";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import { Link } from "react-router-dom";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import { updateMenu } from "Redux/Actions/menuActions";
import ReactQuill from "react-quill";
import QuillOptions from "Components/QuillOptions/QuillOptions";
import DOMPurify from "dompurify";
import { updateEvents } from "Redux/Actions/eventsActions";
import { useTranslation } from "react-i18next";
import { errorToast, successToast } from "Services/toastFunctions";
import axios from "axios";
import { PublishState, publishStateOptions } from "Services/publishStates.d";

registerLocale("fr", fr);

const EvenementsCreation = (props) => {
  const { t } = useTranslation("common");

  // Utilisation du locale fr pour les components date + heures primeReact
  addLocale("fr", getFrenchLocale().fr);
  locale("fr");

  // ------- TOASTS ----------
  // Toast de succès d'upload d'image
  const uploadToast = useRef(null);
  // Toast de suppression de l'image upload
  const cancelToast = useRef(null);

  const [submitted, setSubmitted] = useState(false);

  const [isPublic, setPublic] = useState(
    props.value ? props.value.public : false
  );

  const [inscription, setInscription] = useState(false);

  const [questions, setQuestions] = useState([""]);

  const [countForm, setCountForm] = useState(0);

  const [categories, setCategories] = useState([]);

  // Variable pour savoir si on doit charger les données de l'événement
  const [mustLoadDetails, setMustLoadDetails] = useState(
    props.value?.id ? true : false
  );

  // ------- VARIABLES GESTION IMAGES ----------
  // La photo vient-elle de la photothèque ?
  const [phototheque, setPhototheque] = useState(true);
  // Contiendra l'url de l'image choisie (upload ou photothèque)
  const [imageFile, setImageFile] = useState("");
  // Contiendra le fichier upload par l'utilisateur
  const [image, setImage] = useState("");
  // Id de l'image choisie (phototheque)
  const [imageId, setImageId] = useState("");

  const [waitingLoader, setWaitingLoader] = useState(false);

  // Constantes comprenant les données du formulaire de création d'un événement pour les admins et personnel
  let defaultValues = {
    title: "",
    category: null,
    startingAt: "",
    endingAt: "",
    register: false,
    registerBegin: "",
    registerEnd: "",
    startingHour: "",
    endingHour: "",
    description: "",
    location: "",
    maxNumberOfParticipants: "",
    image: "",
    link: "",
    accessibleForDisabled: false,
    publishOn: PublishState.PUBLISH_RP_AND_ALUMNI,
    emailCreator: "",
    imageUrl: "",
  };

  // Méthodes et variables de react-hook-form
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    watch,
  } = useForm({ defaultValues });

  const categoriesData = useFetchGet("/event_categories", props.auth.token);

  const [isModifying, setIsModifying] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  useEffect(() => {
    if (props.value) {
      setIsModifying(true);
      setCurrentCategory(
        categoriesData.data !== null &&
          categoriesData.data.find(
            (category) => category.label === props.value.category.label
          )
      );
    }
  }, [categoriesData.loaded]);
  // Une fois les catégories chargées, on les stocke dans un tableau :
  useEffect(() => {
    /// Récupérer les catégories, et les filtrer par ordre alphabétique
    if (categoriesData.loaded) {
      setCategories([]);
      let tempArray = [];
      categoriesData.data.forEach((category) => tempArray.push(category));
      tempArray = tempArray.sort((a, b) => a.label.localeCompare(b.label));
      setCategories(tempArray);
    }
  }, [categoriesData.loaded]);

  const setDefaultValues = async () => {
    if (props.value) {
      const eventDetails = await fetchGet(
        "/events/" + props.value.id,
        props.auth.token
      );
      if (eventDetails?.id) {
        //? On remplit le formulaire avec les données de l'événement
        reset({
          title: eventDetails.title,
          category: eventDetails.category.label,
          startingAt: new Date(eventDetails.startingAt),
          endingAt: new Date(eventDetails.endingAt),
          register: eventDetails.register,
          registerBegin: eventDetails.registerBegin
            ? new Date(eventDetails.registerBegin)
            : "",
          registerEnd: eventDetails.registerEnd
            ? new Date(eventDetails.registerEnd)
            : "",
          startingHour: eventDetails.startingHour
            ? new Date(eventDetails.startingHour)
            : "",
          endingHour: eventDetails.endingHour
            ? new Date(eventDetails.endingHour)
            : "",
          description: eventDetails.description,
          publishOn: eventDetails.publishOn,
          location: eventDetails.location,
          maxNumberOfParticipants: eventDetails.maxNumberOfParticipants,
          image: eventDetails.image,
          link: eventDetails.link,
          accessibleForDisabled: eventDetails.accessibleForDisabled,
          emailCreator: eventDetails.emailCreator,
          imageUrl: eventDetails.imageUrl,
        });
        setMustLoadDetails(false);
      }
    }
  };

  useEffect(async () => {
    props.value && setPhototheque(false);
    await setDefaultValues();
  }, [props.value]);

  // Actualisation du champ register si changement de valeur
  useEffect(() => {}, [watch("register")]);

  useEffect(() => {}, [watch("startingAt")]);

  const resetQuestion = () => {
    setQuestions([""]);
  };

  const addQuestion = () => {
    setCountForm(countForm + 1);
    const newArray = [...questions];
    newArray.push("");
    setQuestions(newArray);
  };
  const majQuestion = (e, i) => {
    const newArray = [...questions];
    newArray[i] = e;
    setQuestions(newArray);
  };

  const sendRequest = async (data) => {
    // On crée un FormData qui sera envoyé au serveur
    var dataForm = new FormData();
    // On ajoute les données du formulaire au formData
    for (var key in data) dataForm.append(key, data[key]);
    // Url de l'api
    let url = "/events/request/validation";
    const createdEvent = await fetchPost(url, dataForm, props.auth.token);
    setWaitingLoader(false);
    // Gestion des erreurs
    if (axios.isAxiosError(createdEvent)) {
      const err = createdEvent.response;
      if (err?.statusText === "Unprocessable Entity" || err?.status == 422) {
        errorToast(
          err?.data?.detail ||
            "L'image doit être au format paysage et ne doit pas dépasser 1Mo"
        );
      } else {
        errorToast(err?.data?.detail || "Une erreur est survenue.");
      }
      return;
    }
    // Gestion du succès
    reset();
    resetQuestion();
    setInscription(false);
    setSubmitted(true);
  };

  const putForm = async (data) => {
    // URL de la requête POST
    let url = null;

    if (!props.events?.currentTransmitter) return;

    if (props.value.state.statusLabel === "Brouillon") {
      url = `/events/${props.value.id}/edit/draft`;
    } else if (props.value.state.statusLabel === "Refusé") {
      url = `/events/${props.value.id}/request/validation`;
    } else {
      url = `/events/${props.value.id}/edit/publish`;
    }

    if (props.events.currentTransmitter[0] === "Recruteur") {
      url += "/recruiter";
    }
    // On crée un FormData qui sera envoyé au serveur
    let formData = new FormData();
    // On ajoute les données du formulaire au formData
    for (let key in data) formData.append(key, data[key]);
    // On envoie le formData au serveur
    const editedEvent = await fetchPost(url, formData, props.auth.token);
    setWaitingLoader(false);
    // Gestion des erreurs
    if (editedEvent.error) {
      errorToast(
        "Une erreur s'est produite lors de la modification de l'événement"
      );
      return;
    }
    // Gestion du succès
    if (props.events.dataFromPagination?.data) {
      let tempArray = props.events.dataFromPagination;
      tempArray.data = tempArray.data.map((event) => {
        if (event.id === editedEvent.id) return editedEvent;
        else return event;
      });
      props.handleUpdateEvents({ dataFromPagination: tempArray });
    } else {
      props.handleUpdateEvents({
        dataFromPagination: {
          data: [editedEvent],
          loaded: true,
          page: 1,
          setPage: null,
          totalItems: 1,
        },
      });
    }
    props.setVisible(false);
    successToast("L'événement a bien été modifié");
  };

  /**
   * Remplissage de la key image selon différentes conditions
   * @param {Object} data données pour la requêtes
   * @returns {Object}
   */
  const fillImage = (data) => {
    // Si une image a été téléchargée, on met son url local dans la key imageFile
    if (imageFile && !phototheque) {
      data.imageFile = imageFile;
      delete data.imageStockId;
    }
    // Sinon, on met l'url de l'image choisie de la photothèque
    else if (phototheque) {
      data.imageStockId = imageId;
      delete data.imageFile;
      delete data.image;
    }
    return data;
  };

  /**
   * Remplissage des keys concernant les dates selon différentes conditions
   * Formattage avec {@link formatDateDatabase}
   * @param {Object} data données pour la requêtes
   * @returns {Object}
   */
  const fillDates = (data) => {
    // Assignation des dates de création, de début et de fin de l'événement
    data.startingAt = data.startingAt
      ? formatDateDatabase(data.startingAt)
      : null;
    data.endingAt = data.endingAt ? formatDateDatabase(data.endingAt) : null;
    // Assignation des dates de début et de fin de l'événement si remplies dans le formulaire
    if (data.startingHour != "" && data.startingHour != null)
      data.startingHour = data.startingHour.toLocaleTimeString(
        navigator.language,
        {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }
      );
    if (data.endingHour != "" && data.endingHour != null)
      data.endingHour = data.endingHour.toLocaleTimeString(navigator.language, {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    // Gestion de l'inscription obligatoire ou non à l'événement
    data.register = inscription;
    // Si oui : assignation des dates de début et de fin de l'inscription
    if (inscription) {
      data.registerEnd = data.registerEnd
        ? formatDateDatabase(data.registerEnd)
        : null;
      data.registerBegin = data.registerBegin
        ? formatDateDatabase(data.registerBegin)
        : null;
    }
    return data;
  };

  /**
   * Remplissage de la key "questions" avec les questions du formulaire
   * @param {Object} data données pour la requêtes
   * @returns {Object}
   */
  const fillQuestions = (data) => {
    // Assignation des questions du formulaire à la key eponyme qui ne sont pas nulles
    data.questions = JSON.stringify(
      questions.filter((question) => question != "")
    );
    // Si aucune question n'est remplie, on supprime la key questions
    if (data.questions.length == 0) delete data.questions;
    return data;
  };

  /**
   * Remplissage de la key category selon différentes conditions
   * @param {Object} data données pour la requêtes
   * @returns {Object}
   */
  const fillCategory = (data) => {
    // Si catégory est vide on met la catégorie par défaut 'Autre'
    if (!data.category) data.category = "Autre";

    // Si la key id existe, pas de besoin de chercher l'id de la catégorie
    if (data.category.id)
      data.category = "/api/event_categories/" + data.category.id;
    /* 
    Sinon, on cherche si la catégorie existe déjà :
    // Si oui : on met l'id de la catégorie
     Si non : on spécifie qu'elle doit être créée
     */ else {
      const category = categories.find(
        (category) => category.label === data.category
      );
      if (category) data.category = "/api/event_categories/" + category.id;
      else data.category = JSON.stringify({ label: data.category });
    }
    return data;
  };

  const cleanEmptyKeys = (data) => {
    // Suppression des keys qui ne sont pas remplies
    for (var key in data) if (data[key] === "" || !data[key]) delete data[key];
    return data;
  };

  const setFormData = (data, formType = "multiform") => {
    let createdByUser = "/api/users/" + props.auth.userConnected.id;
    data.description = DOMPurify.sanitize(data.description);
    data.createdById = createdByUser;
    data.inscription = inscription;
    data = fillCategory(data);
    data = fillDates(data);
    data = fillQuestions(data);
    data = fillImage(data);
    data.public = isPublic;
    data.createdBy = createdByUser;
    data.forAllUniversities = true;
    if (isNaN(data.maxNumberOfParticipants))
      delete data.maxNumberOfParticipants;
    if (data.url == "" || data.url === undefined) delete data.url;
    if (formType === "json") {
      data.category = isJsonParsable(data.category)
        ? JSON.parse(data.category)
        : data.category;
      data.questions = isJsonParsable(data.questions)
        ? JSON.parse(data.questions)
        : [];
    }
    data = cleanEmptyKeys(data);
    return data;
  };

  const onSubmit = (data) => {
    setWaitingLoader(true);
    data = setFormData(data);
    if (isModifying) {
      putForm(data);
    } else {
      sendRequest(data);
    }
  };

  const fillFormFromDraft = (draft) => {
    setInscription(draft.register);
    setQuestions(draft.questions);
    setPublic(draft.public);
    reset({
      category: draft.category.label,
      title: draft.title,
      startingAt: draft.startingAt
        ? DateTime.fromISO(draft.startingAt).toJSDate()
        : null,
      startingHour: draft.startingHour
        ? DateTime.fromISO(draft.startingHour).toJSDate()
        : null,
      endingHour: draft.endingHour
        ? DateTime.fromISO(draft.endingHour).toJSDate()
        : null,
      endingAt: draft.endingAt
        ? DateTime.fromISO(draft.endingAt).toJSDate()
        : null,
      registerBegin: draft.registerBegin
        ? DateTime.fromISO(draft.registerBegin).toJSDate()
        : null,
      registerEnd: draft.registerEnd
        ? DateTime.fromISO(draft.registerEnd).toJSDate()
        : null,
      description: draft.description,
      url: draft.url,
      link: draft.link,
      maxNumberOfParticipants: draft.maxNumberOfParticipants,
      location: draft.location,
      publishOn: draft.publishOn,
      accessibleForDisabled: draft.accessibleForDisabled,
      emailCreator: draft.emailCreator,
    });
  };

  const validateHours = (values) => {
    if (!values.startingAt || !values.endingAt) return true;
    // Check if start and end dates are identical AND hours are filled
    const startingDate = DateTime.fromJSDate(values.startingAt);
    const endingDate = DateTime.fromJSDate(values.endingAt);
    if (
      values.startingHour &&
      values.endingHour &&
      startingDate.ordinal === endingDate.ordinal &&
      startingDate.year === endingDate.year
    ) {
      // Check if start hour is before end hour
      if (
        DateTime.fromJSDate(values.startingHour) >
        DateTime.fromJSDate(values.endingHour)
      )
        return t("eventApresConnexion.createHoraireValidate");
    }
    return true;
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {
          // S'il n'y a pas besoin de charger les détails de l'événement, on affiche le formulaire
          !mustLoadDetails ? (
            <div className="evenement_formulaire">
              <Toast ref={uploadToast} />
              <Toast ref={cancelToast} />
              <div className="evenement_formulaire__title">
                <h1 className="h1_trait_dessus">
                  {isModifying
                    ? t("eventApresConnexion.submenuEditAdmin")
                    : props.auth.isAdmin
                    ? t("eventApresConnexion.submenuCreateAdmin")
                    : t("eventApresConnexion.submenuProposerAlumni")}
                </h1>
                <Brouillon
                  setFields={setFormData}
                  fillFormFromDraft={fillFormFromDraft}
                  path="/events"
                  create="/create"
                  formValues={getValues}
                  className="btn-bleu"
                />
              </div>
              <div className="evenement_formulaire__container">
                <div className="evenement_formulaire__title__filtres"></div>
                <div className="column_line">
                  <div className="column_line__item">
                    <h2
                      htmlFor="title"
                      className={classNames({ "p-error": errors.title })}
                    >
                      {t("eventApresConnexion.createTitle")}
                    </h2>
                    <Controller
                      name="title"
                      control={control}
                      rules={{ required: "Le titre est requis" }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          placeholder="Forum de l'emploi, job dating de rentrée"
                          autoFocus
                          className={
                            (classNames({
                              "p-invalid": fieldState.invalid,
                            }),
                            "input_actu")
                          }
                        />
                      )}
                    />
                    {getFormErrorMessage("title")}
                  </div>
                  <div className="column_line__item">
                    <h2
                      htmlFor="category"
                      className={classNames({ "p-error": errors.category })}
                    >
                      {t("eventApresConnexion.createCategory")}
                    </h2>
                    {categories.length > 0 ? (
                      <Controller
                        name="category"
                        control={control}
                        render={({ field, fieldState }) => (
                          <Dropdown
                            id={field.name}
                            {...field}
                            editable
                            // showClear
                            value={
                              isModifying
                                ? currentCategory
                                : getValues().category
                            }
                            onChange={(e) => {
                              setCurrentCategory(e.target.value);
                              field.onChange(e.value);
                            }}
                            optionLabel="label"
                            options={categories}
                            placeholder="After-work, forum, job dating"
                            className={
                              (classNames({
                                "p-invalid": fieldState.invalid,
                              }),
                              "input_evenement")
                            }
                          />
                        )}
                      />
                    ) : (
                      <Loader />
                    )}

                    {getFormErrorMessage("category")}
                  </div>
                </div>

                <div className="column_line">
                  <div className="column_line__item">
                    <h2
                      className={classNames({ "p-error": errors.startingAt })}
                    >
                      {t("eventApresConnexion.createDateTitle")}
                    </h2>
                    <div className="column_line">
                      <div className="column_line__item-date">
                        <label>
                          {" "}
                          {t("eventApresConnexion.createDateStart")}
                        </label>
                        <Controller
                          name="startingAt"
                          control={control}
                          rules={{
                            required: "La date de début est requise",
                            validate: () =>
                              DateTime.fromJSDate(getValues().startingAt) <=
                              DateTime.fromJSDate(getValues().endingAt)
                                ? true
                                : "La date de début doit être avant la date de fin",
                          }}
                          render={({ field, fieldState }) => (
                            <ReactDatePicker
                              autoComplete="off"
                              {...field}
                              id={field.name}
                              selected={getValues().startingAt}
                              dateFormat="dd/MM/yyyy"
                              onChange={(e) => {
                                field.onChange(e);
                              }}
                              className={
                                (classNames({
                                  "p-invalid": fieldState.invalid,
                                }),
                                "small_item p-inputtext p-component")
                              }
                            />
                          )}
                        />
                        {getFormErrorMessage("startingAt")}
                      </div>
                      <div className="column_line__item-date">
                        <label> {t("eventApresConnexion.createDateEnd")}</label>
                        <Controller
                          name="endingAt"
                          control={control}
                          rules={{
                            required: "La date de fin est requise",
                          }}
                          render={({ field, fieldState }) => (
                            <ReactDatePicker
                              autoComplete="off"
                              {...field}
                              selected={getValues().endingAt}
                              dateFormat="dd/MM/yyyy"
                              onChange={(e) => field.onChange(e)}
                              className={
                                (classNames({
                                  "p-invalid": fieldState.invalid,
                                }),
                                "small_item p-inputtext p-component")
                              }
                            />
                          )}
                        />
                        {getFormErrorMessage("endingAt")}
                      </div>
                    </div>
                  </div>
                  <div className="column_line__item">
                    <h2> {t("eventApresConnexion.createHoraireTitle")}</h2>
                    <div className="column_line">
                      <div className="column_line__item-date">
                        <label>
                          {" "}
                          {t("eventApresConnexion.createHoraireStart")}
                        </label>
                        <Controller
                          name="startingHour"
                          control={control}
                          rules={{
                            validate: () => validateHours(getValues()),
                          }}
                          render={({ field, fieldState }) => (
                            <Calendar
                              id={field.name}
                              {...field}
                              format="HH:mm"
                              showTime
                              timeOnly
                              showButtonBar
                              stepMinute={15}
                              hourFormat="24"
                              className={
                                (classNames({
                                  "p-invalid": fieldState.invalid,
                                }),
                                "input_evenement")
                              }
                            />
                          )}
                        />
                        {getFormErrorMessage("startingHour")}
                      </div>
                      <div className="column_line__item-date">
                        <label>
                          {" "}
                          {t("eventApresConnexion.createHoraireEnd")}
                        </label>
                        <Controller
                          name="endingHour"
                          control={control}
                          render={({ field, fieldState }) => (
                            <Calendar
                              id={field.name}
                              {...field}
                              format="HH:mm"
                              showTime
                              stepMinute={15}
                              showButtonBar
                              timeOnly
                              hourFormat="24"
                              className={
                                (classNames({
                                  "p-invalid": fieldState.invalid,
                                }),
                                "input_evenement")
                              }
                            />
                          )}
                        />
                        {getFormErrorMessage("endingHour")}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="column_line">
                  <div className="column_line__item">
                    <h2
                      htmlFor="location"
                      className={classNames({ "p-error": errors.location })}
                    >
                      {t("eventApresConnexion.createLieu")}
                    </h2>
                    <Controller
                      name="location"
                      control={control}
                      rules={{ required: "Le lieu est requis" }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          placeholder="Jardin du Luxembourg, Visio"
                          className={
                            (classNames({
                              "p-invalid": fieldState.invalid,
                            }),
                            "input_evenement")
                          }
                        />
                      )}
                    />
                    {getFormErrorMessage("location")}
                  </div>
                  <div className="column_line__item">
                    <h2
                      htmlFor="maxNumberOfParticipants"
                      className={classNames({
                        "p-error": errors.maxNumberOfParticipants,
                      })}
                    >
                      {t("eventApresConnexion.createNombreParticipants")}
                    </h2>
                    <Controller
                      name="maxNumberOfParticipants"
                      control={control}
                      rules={{
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Veuillez entrer un nombre",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          placeholder="50"
                          className={
                            (classNames({
                              "p-invalid": fieldState.invalid,
                            }),
                            "input_evenement",
                            "eventNumberInput")
                          }
                        />
                      )}
                    />
                    {getFormErrorMessage("maxNumberOfParticipants")}
                  </div>
                </div>

                <div className="fourth_line">
                  <div className="descritpion_evenement">
                    <h2
                      htmlFor="description"
                      className={classNames({ "p-error": errors.description })}
                    >
                      {t("eventApresConnexion.createDescription")}
                    </h2>
                    <Controller
                      name="description"
                      control={control}
                      rules={{
                        required: "La description est requise",
                        minLength: {
                          value: 12,
                          message:
                            "La description doit faire au moins 12 caractères",
                        },
                      }}
                      render={({ field }) => (
                        <ReactQuill
                          id={field.name}
                          {...field}
                          theme="snow"
                          modules={QuillOptions.modules}
                          formats={QuillOptions.formats}
                          rows={5}
                        />
                      )}
                    />
                    {getFormErrorMessage("description")}
                  </div>
                </div>

                <div className="fith_line_evenement">
                  <div className="select_actu">
                    <h2>{t("eventApresConnexion.publishState")} *</h2>
                    <Controller
                      name="publishOn"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          id="publishOn"
                          value={field.value}
                          options={publishStateOptions}
                          onChange={(e) => field.onChange(e.value)}
                          optionLabel="label"
                          placeholder="Choisir la visibilité"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="fith_line_evenement">
                  <Controller
                    name="register"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Checkbox
                        inputId={field.name}
                        onChange={(e) => {
                          field.onChange(e.checked);
                          setInscription(e.checked);
                        }}
                        checked={inscription}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  <label
                    htmlFor="register"
                    className={classNames(
                      { "p-error": errors.register },
                      "label_checkbox"
                    )}
                  >
                    {t("eventApresConnexion.createInscription")}
                  </label>
                </div>

                {inscription && (
                  <div className="column_line">
                    <div className="column_line__item">
                      <h2
                        className={classNames({
                          "p-error": errors.registerBegin,
                        })}
                      >
                        {t("eventApresConnexion.createInscriptionLimites")}
                      </h2>
                      <div className="column_line">
                        <div className="column_line__item-date">
                          <label>
                            {t(
                              "eventApresConnexion.createInscriptionLimitesStart"
                            )}
                          </label>
                          <Controller
                            name="registerBegin"
                            control={control}
                            rules={{
                              required: "La date de début est requise",
                            }}
                            render={({ field, fieldState }) => (
                              <ReactDatePicker
                                autoComplete="off"
                                {...field}
                                selected={field.value}
                                dateFormat="dd/MM/yyyy"
                                onChange={(e) => field.onChange(e)}
                                className={
                                  (classNames({
                                    "p-invalid": fieldState.invalid,
                                  }),
                                  "small_item p-inputtext p-component")
                                }
                              />
                            )}
                          />
                          {getFormErrorMessage("registerBegin")}
                        </div>
                        <div className="column_line__item-date">
                          <label>
                            {t(
                              "eventApresConnexion.createInscriptionLimitesEnd"
                            )}
                          </label>
                          <Controller
                            name="registerEnd"
                            control={control}
                            rules={{
                              required: "La date de fin est requise",
                            }}
                            render={({ field, fieldState }) => (
                              <ReactDatePicker
                                autoComplete="off"
                                {...field}
                                selected={field.value}
                                dateFormat="dd/MM/yyyy"
                                onChange={(e) => field.onChange(e)}
                                className={
                                  (classNames({
                                    "p-invalid": fieldState.invalid,
                                  }),
                                  "small_item p-inputtext p-component")
                                }
                              />
                            )}
                          />
                          {getFormErrorMessage("registerEnd")}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="column_line">
                  <div className="column_line__item">
                    <h2>{t("eventApresConnexion.createLienURL")}</h2>
                    <Controller
                      name="link"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputText
                          type="url"
                          onInvalid={(e) =>
                            e.target.setCustomValidity(
                              "Entrez une url de type https://myurl.com (ou http)"
                            )
                          }
                          onInput={(e) => e.target.setCustomValidity("")}
                          placeholder="https://"
                          id={field.name}
                          {...field}
                          value={field.value}
                          className={
                            (classNames({
                              "p-invalid": fieldState.invalid,
                            }),
                            "input_evenement")
                          }
                        />
                      )}
                    />
                    {getFormErrorMessage("link")}
                  </div>
                </div>
                <ChargementImage
                  image={image}
                  setImage={setImage}
                  imageFile={imageFile}
                  setImageFile={setImageFile}
                  setImageId={setImageId}
                  uploadToast={uploadToast}
                  cancelToast={cancelToast}
                  phototheque={phototheque}
                  setPhototheque={setPhototheque}
                  path="events"
                  currentImage={props.value ? getValues("imageUrl") : null}
                />
                <div className="checkbox_line">
                  <div className="checkbox_line__item">
                    <Checkbox
                      checked={!isPublic}
                      onChange={(e) => {
                        setPublic(!e.checked);
                      }}
                    />
                    <label className="label_publique">
                      <i className="pi pi-lock spaceCheckbox" />{" "}
                      {t("eventApresConnexion.createCheckPrive")}
                    </label>
                  </div>
                  <div className="checkbox_line__item">
                    <Checkbox
                      checked={isPublic}
                      onChange={(e) => {
                        setPublic(e.checked);
                      }}
                    />
                    <label className="label_publique">
                      <i className="pi pi-lock-open spaceCheckbox" />{" "}
                      {t("eventApresConnexion.createCheckPublic")}
                    </label>
                  </div>
                </div>
                <div className="checkbox_line">
                  <div className="checkbox_line__item">
                    <Controller
                      name="accessibleForDisabled"
                      control={control}
                      render={({ field, fieldState }) => (
                        <Checkbox
                          inputId={field.name}
                          onChange={(e) => field.onChange(e.checked)}
                          checked={field.value}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                        />
                      )}
                    />
                    <label
                      htmlFor="accessibleForDisabled"
                      className={classNames({
                        "p-error": errors.accessibleForDisabled,
                      })}
                    >
                      <FaWheelchair className="spaceCheckbox" />
                      {t("eventApresConnexion.createCheckHandi")}
                    </label>
                  </div>
                </div>
              </div>

              {props.auth.isConnected && (
                <>
                  <div className="evenement_formulaire__title">
                    <h1 className="h1_trait_dessus">
                      {t("eventApresConnexion.createQuestionTitle")}
                    </h1>
                  </div>
                  <div className="evenement_formulaire__container">
                    <div className="url_container">
                      {questions.map((item, indexQuestion) => (
                        <div className="url_event" key={indexQuestion}>
                          <h2>
                            {t("eventApresConnexion.createQuestionSubtitle")}
                          </h2>
                          <p></p>
                          <InputText
                            value={item}
                            placeholder={t(
                              "eventApresConnexion.createQuestionPlaceholder"
                            )}
                            onChange={(e) =>
                              majQuestion(e.target.value, indexQuestion)
                            }
                            className="input_evenement"
                          />
                        </div>
                      ))}

                      <a
                        onClick={addQuestion}
                        className="evenement_formulaire__add_question_create_event"
                      >
                        <i className="pi pi-plus-circle"></i>{" "}
                        {t("eventApresConnexion.createQuestionButton")}
                      </a>
                      {questions[0]?.length > 0 && (
                        <>
                          <h2
                            htmlFor="emailCreator"
                            className={classNames({
                              "p-error": errors.emailCreator,
                            })}
                          >
                            Email du créateur *
                          </h2>
                          <Controller
                            name="emailCreator"
                            control={control}
                            rules={{
                              required: questions[0]?.length > 0 ? true : false,
                            }}
                            render={({ field, fieldState }) => (
                              <InputText
                                placeholder="Renseigner votre adresse mail"
                                id={field.name}
                                {...field}
                                value={field.value}
                                className={
                                  (classNames({
                                    "p-invalid": fieldState.invalid,
                                  }),
                                  "input_evenement input_evenement_mail")
                                }
                              />
                            )}
                          />
                        </>
                      )}
                      {getFormErrorMessage("emailCreator")}
                    </div>
                  </div>
                </>
              )}

              <div className="evenement_formulaire__container">
                <div className="buttons_bottom">
                  {submitted && (
                    <Modal
                      visible={submitted}
                      setVisible={setSubmitted}
                      header={
                        props.auth.isAdmin
                          ? "Votre évènemment a bien été publié"
                          : "Votre évènemment a bien été soumis"
                      }
                    >
                      <i className="pi pi-send"></i>
                      <div className="description">
                        <p>
                          <center>
                            {props.auth.isAdmin
                              ? "Votre évènemment a bien été publié"
                              : "Votre évènemment a bien été enregistré. Il sera publié après validation"}
                          </center>
                        </p>
                      </div>
                      <div className="modals-buttons">
                        <BtnBleu
                          btnTexte="Déposer un nouvel événement"
                          btnAction={() => window.location.reload()}
                        />
                        <Link to="/evenements">
                          <div>
                            <BtnBlanc
                              btnTexte="Voir tous mes évènements"
                              btnAction={() => {
                                props.handleUpdateMenu({
                                  activeItemEvenement:
                                    props.items.itemsEvenement.findIndex(
                                      (v) => v.label == "Gérer mes événements"
                                    ),
                                  activeItemEvenementAdmin:
                                    props.items.itemsEvenementAdmin.findIndex(
                                      (v) => v.label == "Gérer les événements"
                                    ),
                                });
                              }}
                            />
                          </div>
                        </Link>
                      </div>
                    </Modal>
                  )}
                  {!waitingLoader ? (
                    isModifying ? (
                      <button className="btn-bleu publier_button" type="submit">
                        {props.auth.isAdmin
                          ? t("eventApresConnexion.submenuEditAdmin")
                          : props.value.state.statusLabel === "Refusé"
                          ? t("eventApresConnexion.submenuRenewAlumni")
                          : t("eventApresConnexion.submenuEditAdmin")}
                      </button>
                    ) : (
                      <button className="btn-bleu publier_button" type="submit">
                        {props.auth.isAdmin
                          ? t("eventApresConnexion.submenuCreateAdmin")
                          : t("eventApresConnexion.submenuProposerAlumni")}
                      </button>
                    )
                  ) : (
                    <button className="waiting_button btn-bleu">
                      <Loader></Loader>
                    </button>
                  )}
                  <Brouillon
                    setFields={setFormData}
                    fillFormFromDraft={fillFormFromDraft}
                    path="/events"
                    create="/create"
                    formValues={getValues}
                    className="btn-blanc"
                    btnText={t("eventApresConnexion.brouillonButton")}
                  />
                  <button
                    className="btn-blanc annuler_button"
                    onClick={() => {
                      isModifying
                        ? props.setVisible(false)
                        : (reset(
                            {
                              categorie: null,
                              title: "",
                              description: "",
                              startingAt: "",
                              endingAt: "",
                              registerBegin: "",
                              registerEnd: "",
                              link: "",
                              public: false,
                              accessibleForDisabled: false,
                              location: "",
                              register: false,
                            },
                            {
                              keepErrors: true,
                              keepDirty: true,
                              keepIsSubmitted: false,
                              keepTouched: false,
                              keepIsValid: false,
                              keepSubmitCount: false,
                            }
                          ),
                          resetQuestion(),
                          setInscription(false),
                          setImage(null),
                          setImageFile(null),
                          setPhototheque(true));
                    }}
                  >
                    {t("eventApresConnexion.cancelButton")}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <Loader />
          )
        }
      </form>
    </>
  );
};

EvenementsCreation.propTypes = {
  activeIndex: PropTypes.number,
  nbevents: PropTypes.number,
  pageevents: PropTypes.string,
  filtre: PropTypes.string,
  filtres: PropTypes.array,
  setFiltre: PropTypes.func,
  boutons: PropTypes.bool,
  auth: PropTypes.object,
  value: PropTypes.object,
  setVisible: PropTypes.func,
  handleUpdateMenu: PropTypes.func,
  items: PropTypes.object,
  events: PropTypes.object,
  handleUpdateEvents: PropTypes.func,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
  events: state.events,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateMenu: (value) => dispatch(updateMenu(value)),
  handleUpdateEvents: (value) => {
    dispatch(updateEvents(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EvenementsCreation);
