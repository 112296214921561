/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./Accueil.scss";
import PropTypes from "prop-types";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import CarouselEvent from "Components/CarouselEvent/CarouselEvent";
import OffresContainer from "Components/Offres/OffresContainer/OffresContainer";
import { connect } from "react-redux";
import PresentationRPAlumni from "Components/PresentationRPAlumni/PresentationRPAlumni";
import CarouselActu from "Components/CarouselActu/CarouselActu";
import ButtonsBarDisconnected from "Components/Header/ButtonsBarDisconnected/ButtonsBarDisconnected";
import ButtonsBarConnected from "Components/Header/ButtonsBarConnected/ButtonsBarConnected";
import SearchBar from "Components/SearchBar/SearchBar";
import { fetchGet, useFetchGet, useFetchGetPagination } from "Services/api";
import ALaUne from "Components/ALaUne/ALaUne";
import ReseauDynamique from "Components/ReseauDynamique/ReseauDynamique";
// import FaireDon from "Components/FaireDon/FaireDon";
import useWindowDimensions from "Services/useWindowsDimensions";
import MecenatGroupeAccueil from "Components/MecenatGroupeAccueil/MecenatGroupeAccueil";
import MapContainer from "Components/Map/MapContainer.jsx";
import OffresAccueilHorsConnexion from "Components/Offres/OffresAccueilHorsConnexion/OffresAccueilHorsConnexion";
import { useRef } from "react";
import { formatDateDatabase } from "Services/functions";
import { updateEvents } from "Redux/Actions/eventsActions";
import { updateNews } from "Redux/Actions/newsActions";
import { updateStates } from "Redux/Actions/statesActions";
import { useTranslation } from "react-i18next";
import { updateSecondaryTables } from "Redux/Actions/secondaryTablesActions";

/**
 * Permet d'afficher la page d'accueil
 * La version avant connexion et après connexion sont un peu différentes
 */
const Accueil = (props) => {
  const { t } = useTranslation("common");

  const mapRef = useRef(null);

  const [urlEvents, setUrlEvents] = useState("");
  const [urlNews, setUrlNews] = useState("");

  // récupération des données de l'api pour alimenter la page
  const dataTransmitters = useFetchGet("/transmitters", props.auth.token);

  useFetchGetPagination(urlEvents, props.auth.token, props.handleUpdateEvents);
  useFetchGetPagination(urlNews, props.auth.token, props.handleUpdateNews);

  useEffect(() => {
    dataTransmitters.loaded &&
      props.handleUpdateSecondaryTables({
        transmittersData: dataTransmitters.data,
      });
  }, [dataTransmitters.loaded]);

  useEffect(() => {
    if (props.states.statesData) {
      const idState = props.states.statesData.filter(
        (etat) => etat.statusLabel === "Publié"
      )[0]?.id;

      let urlEvent = new URL(`${process.env.REACT_APP_BASE_URL_API}/events`);
      urlEvent.searchParams.set("state", idState);
      urlEvent.searchParams.set("order[startingAt]", "asc");
      urlEvent.searchParams.set(
        "endingAt[after]",
        formatDateDatabase(new Date())
      );
      setUrlEvents(urlEvent);

      let urlNews = new URL(`${process.env.REACT_APP_BASE_URL_API}/news`);
      urlNews.searchParams.set("state", idState);
      urlNews.searchParams.set("order[modifiedAt]", "desc");
      setUrlNews(urlNews);
    }
  }, [props.states.statesData]);

  const { height, width } = useWindowDimensions();

  return (
    <div>
      <Header>
        {props.auth.isConnected ? (
          <>
            <SearchBar searchText="Rechercher un emploi, découvrir les prochains événements..." />
            <ButtonsBarConnected mapRef={mapRef} />
          </>
        ) : (
          <ButtonsBarDisconnected />
        )}
      </Header>

      {!props.auth.isConnected && <PresentationRPAlumni />}

      <div className="accueil">
        {props.auth.isConnected ? (
          <>
            <div className="article_MecenatGroupeAccueil">
              <MecenatGroupeAccueil />
            </div>
            <div className="article_evenements">
              <CarouselEvent />
            </div>
            <div className="article_actualites">
              <CarouselActu />
            </div>
            <div className="article_offres">
              <OffresContainer
                title={t("offresApresConnexion.title")}
                chapeau="9 offres correspondent à vos critères"
                bouton={t("offresApresConnexion.button")}
                nbOffre={width > 1302 && width < 1682 ? 3 : 4}
                sousTitre={false}
              />
            </div>
          </>
        ) : (
          <>
            <div className="article_ALaUne">
              <ALaUne />
            </div>
            <div className="article_ReseauDynamique">
              <ReseauDynamique />
            </div>
            {/* <div className="article_FaireDon">
              <FaireDon />
            </div> */}
            <div className="article_evenements">
              <CarouselEvent />
            </div>
            <div className="article_actualites">
              <CarouselActu />
            </div>
            <div className="article_offresHorsConnexion">
              <OffresAccueilHorsConnexion />
            </div>
          </>
        )}
      </div>
      <div ref={mapRef}></div>
      <MapContainer />
      <Footer />
    </div>
  );
};

Accueil.propTypes = {
  auth: PropTypes.object,
  handleUpdateEvents: PropTypes.func,
  handleUpdateNews: PropTypes.func,
  handleUpdateSecondaryTables: PropTypes.func,
  states: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  states: state.states,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateEvents: (value) => {
    dispatch(updateEvents(value));
  },
  handleUpdateNews: (value) => dispatch(updateNews(value)),
  handleUpdateSecondaryTables: (value) =>
    dispatch(updateSecondaryTables(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Accueil);
