import React from "react";
import PropTypes from "prop-types";
import "./Actualite.scss";
import event2 from "assets/Event2.webp";
import { connect } from "react-redux";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { HTMLParseOptions } from "Services/htmlParseOptions";

const API_LINK = process.env.REACT_APP_BASE_URL_API.split("/api")[0];
/**
 * Permet d'afficher la card d'une actualité
 * Elle est affichée dans les pages actus et detail-actu
 */
const Actualite = (props) => {
  const { t } = useTranslation("common");

  /**
   * Permet de :
   * - Remplacer les balises <br> par des espaces
   * - Tronquer le chapeau à 120 caractères
   * - Parser le chapeau en HTML
   *
   * @returns {string} Le chapeau de l'actualité modifié pour être prévisualisé
   */
  const sanitizeChapeau = () => {
    let chapeau = props?.chapeau?.replace(/<\/?br\s*\/?>/gi, " ");

    return parse(chapeau, HTMLParseOptions);
  };

  return (
    <div
      className="actualite_container cardHoverActualite"
      style={{ minHeight: props?.random && "95.5%" }}
      onClick={() => {
        props.onClick && props.onClick();
      }}
    >
      <div className="actualite_container__top__categorie">
        <span className="etiquette"> {props.categorie} </span>
      </div>
      <div className="actualite_container__image">
        {props.url == "img1" ? (
          <>
            <img src={event2} alt="Fond news" />
            <div
              className="image__bg"
              style={{ backgroundImage: `url(${event2})` }}
            ></div>
          </>
        ) : (
          <>
            <img src={props.url} alt="Fond news" />
            <div
              className="image__bg"
              style={{
                backgroundImage: `url(${props.url})`,
              }}
            ></div>
          </>
        )}
      </div>
      <div className="actualite_container__corps">
        <div className="actualite_container__corps__debutdate">
          {props.publicationDate}
        </div>
        <div className="actualite_container__corps__title" title={props.title}>
          {parse(props.title)}
        </div>
        <div
          className="actualite_container__corps__chapeau"
          title={props.chapeau}
        >
          <span>{sanitizeChapeau()}</span>
        </div>
      </div>
      <div className="actualite_container__bottom">
        <div className="actualite_container__bottom__lien">
          {t("aLaUne.lirePlus")}
        </div>
        <div className="actualite_container__bottom__logo">
          <img
            src={
              API_LINK +
              "/media/logos_univs/logo_pantheon_sorbonne_transparent_couleur.webp"
            }
            alt={"Logo Université"}
            title={"Logo Université"}
          />
        </div>
      </div>
    </div>
  );
};

Actualite.propTypes = {
  categorie: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  chapeau: PropTypes.any,
  lien: PropTypes.string,
  logo: PropTypes.string,
  publicationDate: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
  random: PropTypes.bool,
  onClick: PropTypes.func,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Actualite);
