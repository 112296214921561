import Loader from "Components/Loader/loader";
import { updateAuth } from "Redux/Actions/authActions";
import { fetchPost } from "Services/api";
import { formatContrat, formatDate } from "Services/functions";
import { useIntersectionObserver } from "Services/intersectionObserver";
import { errorToast, successToast } from "Services/toastFunctions";
import iconeBuildingColor from "assets/iconeBuildingColor.png";
import iconeLaurierColor from "assets/iconeLaurierColor.png";
import defaultOffer1 from "assets/offre1.webp";
import defaultOffer2 from "assets/offre2.webp";
import defaultOffer3 from "assets/offre3.webp";
import defaultOffer4 from "assets/offre4.webp";
import recruitersImage from "assets/recruteurs.png";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  FaBookmark,
  FaBuilding,
  FaMapMarkerAlt,
  FaRegBookmark,
  FaSuitcase,
  FaWheelchair,
} from "react-icons/fa";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import "./Offre.scss";

const API_LINK = process.env.REACT_APP_BASE_URL_API.split("/api")[0];

const Offre = (props) => {
  const [intersectionRef, isVisibleIntersection] = useIntersectionObserver();
  var bookmark = props.auth.userConnected?.savedOffers.some(
    (offer) => offer.id === props.offreDetails.id
  );

  const [offerBg, setOfferBg] = useState(null);

  const [isLoader, setIsLoader] = useState(false);
  const addOfferToSaveList = async () => {
    const url = `/offers/${props.offreDetails.id}/save`;
    const data = { savedByUsers: [`api/users/${props.auth.userConnected.id}`] };
    setIsLoader(true);
    const response = await fetchPost(url, data, props.auth.token);
    setIsLoader(false);
    if (response.error) {
      errorToast(
        response.error?.response?.data?.detail || "Une erreur est survenue"
      );
      return;
    }
    props.handleAuth({
      userConnected: {
        ...props.auth.userConnected,
        savedOffers: props.auth.userConnected.savedOffers.concat({
          id: response.data.id,
        }),
      },
    });
    successToast("L'offre a bien été ajoutée aux offres sauvegardées");
  };

  const removeOfferFromSaveList = async () => {
    const url = `/offers/${props.offreDetails.id}/erase`;
    const data = { savedByUsers: [`api/users/${props.auth.userConnected.id}`] };
    setIsLoader(true);
    const response = await fetchPost(url, data, props.auth.token);
    setIsLoader(false);
    if (response.error) {
      errorToast(
        response.error?.response?.data?.detail || "Une erreur est survenue"
      );
      return;
    }
    props.handleAuth({
      userConnected: {
        ...props.auth.userConnected,
        savedOffers: props.auth.userConnected.savedOffers.filter(
          (saved) => saved.id !== response.data.id
        ),
      },
    });
    successToast("L'offre a bien été retirée des offres sauvegardées");
  };

  /**
   * Permet de renvoyer l'url du logo de l'entreprise soit par l'url de notre API, soit par clearbit
   * @param {{companyImageUrl: null|String, companyUrl: null|String}} offre
   * @returns {String} url du logo de l'entreprise
   */
  const findCompanyLogo = (offre) => {
    // Si l'offre a un logo on l'utilise
    if (offre?.companyImageUrl || offre?.company?.imageUrl)
      return offre.companyImageUrl || offre.company.imageUrl;
    if (!offre?.companyUrl && !offre?.company?.companyUrl)
      return recruitersImage;
    // Sinon on utilise clearbit pour trouver le logo de l'entreprise
    try {
      const url = new URL(`${offre?.companyUrl || offre?.company?.companyUrl}`);
      return `https://logo.clearbit.com/${url.hostname}`;
    } catch (e) {
      return recruitersImage;
    }
  };

  const findLogo = (offre) => {
    if (offre?.company || offre?.companyUrl) return findCompanyLogo(offre);
    const logo = offre.university?.imageTransparentAndColorUrl || "";

    switch (offre.transmitter?.name || offre?.transmitterName) {
      case "Diffuseur":
        return props.secondaryTables.transmittersData.find(
          (transmitter) => transmitter.name === "Diffuseur"
        )?.imageUrl;
      case "Admin":
      case "Etudiant":
        return props.secondaryTables.transmittersData
          ? props.secondaryTables.transmittersData.find(
              (transmitter) => transmitter.name === "Admin"
            ).imageUrl
          : API_LINK + logo;
      case "Université":
        return API_LINK + logo;
      case "Recruteur":
        return findCompanyLogo(props.offreDetails);
      case "Personnel":
        return API_LINK + logo;
      case "Alumni":
        return API_LINK + logo;
      default:
        return recruitersImage;
    }
  };

  useEffect(() => {
    // Check si l'url est une image ou non
    // Obligé de le faire fonctionnellement car le CSS bgImage n'a pas de gestion onError
    const checkImage = () => {
      const img = new Image();
      img.src = props.url;
      img.onload = () => {
        setOfferBg(props.url);
      };
      img.onerror = () => {
        const defaultOffers = [
          defaultOffer1,
          defaultOffer2,
          defaultOffer3,
          defaultOffer4,
        ];
        const randomIndex = Math.floor(Math.random() * defaultOffers.length);
        setOfferBg(defaultOffers[randomIndex]);
      };
    };
    if (!offerBg) checkImage();
  }, [props.url, offerBg]);

  return (
    <div
      className={
        useLocation().pathname == "/" ||
        useLocation().pathname == "/profil" ||
        useLocation().pathname == "/offres" ||
        useLocation().pathname == "/depot-offre" ||
        useLocation().pathname == "/gestion-offre"
          ? "offre cardHover"
          : "offre"
      }
      onClick={() => props.onClick && props.onClick()}
      ref={intersectionRef}
    >
      <Link
        to={{
          pathname: props.auth.isConnected
            ? `/offre-detail/${props.offreDetails.id}`
            : "/",
          params: props.offreDetails.id,
        }}
      >
        {isVisibleIntersection && offerBg && (
          <div className="offre__image">
            <div className="offre__image__header">
              <img
                src={offerBg}
                alt="logo"
                onError={(e) => {
                  e.target.src = defaultOffer2;
                }}
                loading="lazy"
              />
              <div
                className="image__bg"
                style={{
                  backgroundImage: `url(${offerBg})`,
                }}
              ></div>
            </div>
            <div className="offre__image__logo">
              <img
                src={findLogo(props.offreDetails)}
                alt="Logo offre"
                title="Logo offre"
                onError={(e) => {
                  e.target.src = recruitersImage;
                }}
                loading="lazy"
              />
            </div>
          </div>
        )}
      </Link>

      <Link
        to={{
          pathname: props.auth.isConnected
            ? `/offre-detail/${props.offreDetails.id}`
            : "/",
          params: props.offreDetails.id,
        }}
      >
        <div className="offre__container">
          <div className="offre__container__description">
            <div
              className="offre__container__description__title"
              title={props.offreDetails?.title}
            >
              <span>
                {props.offreDetails.title && props.offreDetails.title}{" "}
              </span>
            </div>
            <div className="offre__container__description__statut">
              <FaSuitcase title="Type de contrat ou catégorie de l'offre" />
              {props.offreDetails?.typeOfContract?.label ||
              props.offreDetails?.typeofContractLabel ? (
                <span>
                  {formatContrat(
                    props.offreDetails?.typeOfContract?.label ||
                      props.offreDetails?.typeofContractLabel
                  )}
                </span>
              ) : (
                <span>
                  {props.offreDetails.offerCategory?.label ||
                    props.offreDetails.offerCategoryLabel ||
                    "Non renseigné"}
                </span>
              )}
            </div>
            <div className="offre__container__description__statut">
              <FaMapMarkerAlt title="Lieu de l'offre" />
              <span>
                {props.offreDetails.city} ({props.offreDetails.country})
              </span>
            </div>

            {props.offreDetails.companyName && !props.offreDetails.company && (
              <div className="offre__container__description__statut">
                <FaBuilding title="Nom de l'entreprise" />
                <span
                  title={
                    props.offreDetails.companyName ||
                    props.offreDetails.company?.name
                  }
                >
                  {props.offreDetails.companyName ||
                    props.offreDetails.company.name}
                </span>
              </div>
            )}
            <div className="offre__container__ description__handicap">
              {props.accessibleForDisabled && (
                <div className="offre__container__description__handicap__icon">
                  <FaWheelchair />
                  <span>Handi-accueillant</span>
                </div>
              )}
            </div>
            <div
              className="offre__categorie"
              style={{
                marginTop: props.accessibleForDisabled ? "0rem" : "2.5rem",
              }}
            >
              {props.offreDetails.company &&
              props.offreDetails.company.isPartner ? (
                <span className="offre__categorie__entreprise">
                  <img
                    src={iconeBuildingColor}
                    alt="Partenaire"
                    loading="lazy"
                  />
                  Entreprise Partenaire
                </span>
              ) : (
                props.offreDetails?.transmitter?.name === "Alumni" && (
                  <span className="offre__categorie__alumni">
                    <img src={iconeLaurierColor} alt="Alumni" loading="lazy" />
                    Alumni
                  </span>
                )
              )}
            </div>
          </div>
        </div>
      </Link>
      <div className="offre__date">
        {formatDate(props.offreDetails.dateLodified, ".")}{" "}
        {!isLoader ? (
          bookmark ? (
            <div className="tooltip">
              <FaBookmark
                onClick={() => {
                  removeOfferFromSaveList();
                }}
              />
            </div>
          ) : (
            <div className="tooltip">
              <FaRegBookmark
                onClick={() => {
                  addOfferToSaveList();
                }}
              />
            </div>
          )
        ) : (
          <Loader></Loader>
        )}
        <div className="offre__date__logo">
          <img
            src={
              API_LINK +
              props.offreDetails?.university.imageTransparentAndColorUrl
            }
            alt="Logo Université"
            title={"Panthéon Sorbonne"}
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

Offre.propTypes = {
  date: PropTypes.string,
  bookmark: PropTypes.bool,
  offreDetails: PropTypes.any,
  test: PropTypes.string,
  image: PropTypes.number,
  categorie: PropTypes.number,
  logoEntreprise: PropTypes.any,
  employes: PropTypes.string,
  accessibleForDisabled: PropTypes.any,
  url: PropTypes.string,
  onClick: PropTypes.func,
  auth: PropTypes.object,
  handleAuth: PropTypes.func,
  offers: PropTypes.object,
  companies: PropTypes.object,
  universities: PropTypes.object,
  secondaryTables: PropTypes.object,
  nexusUrl: PropTypes.string,
};

const mapStateToProps = (state) => ({
  items: state.items,
  auth: state.auth,
  offers: state.offers,
  companies: state.companies,
  secondaryTables: state.secondaryTables,
  universities: state.universities,
});

const mapDispatchToProps = (dispatch) => ({
  handleAuth: (value) => {
    dispatch(updateAuth(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Offre);
