/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./MapContainer.scss";
import SearchContainer from "./SearchContainer/SearchContainer";
import {
  MapContainer as MapLeafLet,
  TileLayer,
  useMap,
  useMapEvents,
} from "react-leaflet";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useFetchGetConditional, useFetchGetPagination } from "Services/api";
import { updateUsers } from "Redux/Actions/usersActions";
import { updateSecondaryTables } from "Redux/Actions/secondaryTablesActions";
import { useTranslation } from "react-i18next";
import UsersList from "./UsersList/UsersList";

//? Composant principal de la carte présente sur la page d'accueil au dessus du footer
const MapContainer = (props) => {
  const { t } = useTranslation("common");
  const [centerPosition, setCenterPosition] = useState();
  const [isSearch, setIsSearch] = useState(true);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchRadioValue, setSearchRadioValue] = useState("surname");
  const [urlUsers, setUrlUsers] = useState("");
  const usersQuery = useFetchGetPagination(urlUsers, props.token);
  const domainsData = useFetchGetConditional(
    "/domains",
    props.token,
    props.secondaryTables.domainsData
  );

  useEffect(() => {
    domainsData.loaded &&
      props.handleUpdateSecondaryTables({
        domainsData: domainsData.data,
      });
  }, [domainsData.loaded]);

  const handleSearch = () => {
    let url = new URL(`${process.env.REACT_APP_BASE_URL_API}/users`);
    url.searchParams.set("datasVisibleForAllMembers", 1);
    url.searchParams.set("latitude[gt]", props.users.mapBounds[0][0]);
    url.searchParams.set("latitude[lt]", props.users.mapBounds[1][0]);
    url.searchParams.set("longitude[gt]", props.users.mapBounds[0][1]);
    url.searchParams.set("longitude[lt]", props.users.mapBounds[1][1]);

    if (searchInputValue !== "") {
      if (searchRadioValue === "surname")
        url.searchParams.set(
          "surname",
          searchInputValue
            .toLowerCase()
            .trim()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        );
      else if (searchRadioValue === "domain")
        url.searchParams.append("domain[]", searchInputValue.id);
    }
    setUrlUsers(url);
  };

  function GetNewPosition() {
    const mapEvents = useMapEvents({
      zoomend: (e) => {
        const bounds = e.target.getBounds();
        props.handleUpdateUsers({
          mapBounds: [
            [bounds._southWest.lat, bounds._southWest.lng],
            [bounds._northEast.lat, bounds._northEast.lng],
          ],
        });
      },
      mouseup: (e) => {
        const bounds = e.target.getBounds();
        props.handleUpdateUsers({
          mapBounds: [
            [bounds._southWest.lat, bounds._southWest.lng],
            [bounds._northEast.lat, bounds._northEast.lng],
          ],
        });
      },
    });
    return null;
  }

  const isDefaultBounds = () => {
    return (
      props.users.mapBounds[0][0] === -44 &&
      props.users.mapBounds[0][1] === -206 &&
      props.users.mapBounds[1][0] === 83 &&
      props.users.mapBounds[1][1] === 212
    );
  };

  function SetterViewAndZoomOnResearch() {
    const map = useMap();
    if (isSearch) {
      handleSearch();
      if (searchRadioValue == "Ville" && searchInputValue !== "")
        map.setView(centerPosition, 9);
      if (isDefaultBounds()) map.setView([48.8588897, 2.320041], 2);
      setSearchInputValue("");
    }
    setIsSearch(false);
    return null;
  }

  return (
    <div className="mapContainer">
      <h2 className="mapContainer__title">
        {t("mapHorsConnexion.title_accueil")}
      </h2>
      <h3 className="mapContainer__subtitle">
        {t("mapHorsConnexion.subtitle_accueil")}{" "}
      </h3>
      {domainsData.loaded && (
        <div className="mapContainer__container">
          <SearchContainer
            setCenterPosition={setCenterPosition}
            setIsSearch={setIsSearch}
            searchInputValue={searchInputValue}
            setSearchInputValue={setSearchInputValue}
            searchRadioValue={searchRadioValue}
            setSearchRadioValue={setSearchRadioValue}
            domainsData={domainsData.data}
            handleSearch={handleSearch}
          />
          <MapLeafLet
            className="mapContainer__container__map"
            bounds={props.users.mapBounds}
            scrollWheelZoom={false}
          >
            <GetNewPosition></GetNewPosition>
            <SetterViewAndZoomOnResearch></SetterViewAndZoomOnResearch>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
            />
            <UsersList key={usersQuery.data} users={usersQuery.data} />
          </MapLeafLet>
        </div>
      )}
    </div>
  );
};

MapContainer.propTypes = {
  handleUpdateUsers: PropTypes.func,
  handleUpdateSecondaryTables: PropTypes.func,

  users: PropTypes.object,
  secondaryTables: PropTypes.object,
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  users: state.users,
  secondaryTables: state.secondaryTables,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateUsers: (value) => {
    dispatch(updateUsers(value));
  },
  handleUpdateSecondaryTables: (value) => {
    dispatch(updateSecondaryTables(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);
