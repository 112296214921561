import React, { useRef, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./SearchPeople.scss";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";

const AttribuerDroits = (props) => {
  const [surname, setSurname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [id, setId] = useState(null); //Id de l'utilisateur sélectionné dans la liste de résultats
  const [userList, setUserList] = useState([]);
  const signalToast = useRef(null);
  const { t } = useTranslation("common");

  const [isLoading, setIsLoading] = useState(false);

  const handleRecherche = () => {
    setIsLoading(true);
    let url = new URL(
      `${process.env.REACT_APP_BASE_URL_API}/users/search/people`
    );
    //Si les champs ne sont pas vides on ajoute les paramètres de recherche à l'url
    if (surname != "") {
      url.searchParams.set("surname", surname);
    }
    if (firstname != "") {
      url.searchParams.set("firstname", firstname);
    }

    axios
      .get(url.toString(), {
        headers: props.auth.token
          ? {
              accept: "application/json",
              Authorization: `Bearer ${props.auth.token}`,
            }
          : {
              accept: "application/json",
            },
      })
      .then((response) => {
        if (response.data.length === 0)
          signalToast.current.show({
            severity: "info",
            summary: t("searchPeople.infoSummary"),
            detail: t("searchPeople.infoDetail"),
            life: 3000,
          });
        setUserList(
          response.data
            // Tri par nom de famille, et prénom si égalité
            .sort((a, b) => {
              const check = a.surname.localeCompare(b.surname);
              return check === 0
                ? a.firstname.localeCompare(b.firstname)
                : check;
            })
        );
      })
      .catch((error) =>
        props.auth.toast.current.show({
          severity: "error",
          summary: error?.response?.data["hydra:title"] || "Erreur",
          detail:
            error?.response?.data["hydra:description"] ||
            "Une erreur est survenue lors de la recherche.",
          life: 3000,
        })
      )
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="search_people_component">
      <Toast ref={signalToast} />
      <div className="search_people_component_search">
        <div className="search_people_component_search_inputs">
          <div className="search_people_component_search_inputs_surname">
            <label>{t("searchPeople.lastName")}</label>
            <InputText
              value={surname}
              placeholder="Dupont, Gary..."
              onChange={(e) => setSurname(e.target.value)}
            />
          </div>
          <div className="search_people_component_search_inputs_firstname">
            <label>{t("searchPeople.firstName")}</label>
            <InputText
              value={firstname}
              placeholder="Marie, Paul..."
              onChange={(e) => setFirstname(e.target.value)}
            />
          </div>
        </div>
        <Button
          type={"submit"}
          loading={isLoading}
          onClick={(e) => {
            e.preventDefault();
            handleRecherche();
          }}
        >
          {isLoading ? "" : t("searchPeople.search")}
        </Button>
      </div>

      {userList.length > 0 && (
        <>
          <div className={"search_people_component_amount"}>
            {`${userList.length}${userList.length === 50 ? "+" : ""} ${t(
              "searchPeople.amount"
            )}`}
          </div>
          <div className="search_people_component_results">
            {userList.map(
              (user, index) =>
                user.id !== props.auth.userConnected.id && (
                  <span
                    className={
                      "search_people_component_results_user" +
                      (id === user.id ? " selected" : "")
                    }
                    key={index}
                    onClick={() => {
                      setFirstname(user.firstname);
                      setSurname(user.surname);
                      setId(user.id);
                      if (props.setUser) props.setUser(user);
                      else if (
                        props.setUserList &&
                        !props.userList.find((item) => item.id === user.id)
                      )
                        props.setUserList(props.userList.concat(user));
                      else
                        signalToast.current.show({
                          severity: "error",
                          summary: t("searchPeople.errorSummary"),
                          detail: t("searchPeople.errorDetail"),
                          life: 3000,
                        });
                    }}
                  >
                    {user.surname.toUpperCase() + " "}
                    {user.firstname.toUpperCase()}
                  </span>
                )
            )}
          </div>
        </>
      )}
    </div>
  );
};

AttribuerDroits.propTypes = {
  auth: PropTypes.object,
  setUser: PropTypes.func,
  setUserList: PropTypes.func,
  userList: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AttribuerDroits);
