import Loader from "Components/Loader/loader";
import Management from "Components/Management/Management";
import { ME, RECRUTEUR } from "Components/Management/ManagementButtons";
import TempPagination from "Components/Pagination/TempPagination";
import { updateOffers } from "Redux/Actions/offersActions";
import { updatePagination } from "Redux/Actions/paginationActions";
import { useFetchGetPagination } from "Services/api";
import { filterByTransmitter, stripHtml, truncate } from "Services/functions";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import "./GestionDepotOffre.scss";
import GestionDepotOffreCard from "./GestionDepotOffreCard/GestionDepotOffreCard";
import { updateUniversities } from "Redux/Actions/universitiesActions";

const GestionDepotOffre = (props) => {
  const { t } = useTranslation("common");
  const [url, setUrl] = useState(null);
  // *****************************************************************
  // ****************** UseStates / UseRef *********************
  // *****************************************************************
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const depotOffreRef = useRef(null);
  const [states, setStates] = useState(null);
  const [btnList, setBtnList] = useState([
    {
      isActive: true,
      text: t("offresApresConnexionPage.manageMesOffres"),
      transmitter: ["Admin"],
    },
    {
      isActive: false,
      text: t("offresApresConnexionPage.manageUnivOffre"),
      transmitter: ["Etudiant", "Alumni", "Personnel"],
    },
    {
      isActive: false,
      text: t("offresApresConnexionPage.manageEntOffre"),
      transmitter: ["Recruteur"],
    },
    {
      isActive: false,
      text: t("offresApresConnexionPage.manageDiffuOffre"),
      transmitter: ["Diffuseur"],
    },
    {
      isActive: false,
      text: t("offresApresConnexionPage.manageAllOffre"),
      transmitter: ["all"],
    },
  ]);

  //? On ne fait pas de requête si aucun état n'est sélectionné
  //? Des états par défaut sont appliqués dans un useEffect
  //! Permet d'éviter de faire deux requêtes au chargement de la page
  useFetchGetPagination(url, props.auth.token, props.handleUpdateOffers);

  // *****************************************************************
  // ****************** UseEffect *********************
  // *****************************************************************
  useEffect(() => {
    props.handleUpdateOffers({
      currentTransmitter: [ME],
    });
    return () => {
      props.handleUpdateOffers({ dataFromPagination: null });
    };
  }, []);

  useEffect(() => {
    if (states && states?.length > 0) {
      if (depotOffreRef.current !== null) {
        window.scroll({
          top: depotOffreRef.current.offsetTop,
          behavior: "smooth",
        });
      }
      let newUrl = new URL(`${process.env.REACT_APP_BASE_URL_API}/offers`);

      newUrl.searchParams.set("order[modifiedAt]", "desc");
      newUrl.searchParams.set("itemsPerPage", 20);
      newUrl.searchParams.set("university", props.auth.universityConnected?.id);
      states.forEach((state) => {
        newUrl.searchParams.append("state[]", state?.id);
      });
      newUrl.searchParams.append(
        "createdByUser[]",
        props.auth.userConnected.id
      );
      setUrl(newUrl);
    }
  }, [states]);

  // UseEffect de chargement
  useEffect(() => {
    /// Récupérer les states, et les filtrer par ordre alphabétique
    if (props.offers.statusData && props.offers.statusData.length > 0) {
      setStates([]);
      let tempArray = props.offers.statusData.map((state) => {
        return { ...state };
      });
      tempArray = tempArray.sort((a, b) => a.name.localeCompare(b.name));
      tempArray.forEach((state) => {
        if (state.name.lastIndexOf("é") === state.name.length - 1) {
          state.name = state.name + "e";
        }
      });

      setStates(tempArray);
    }
  }, [props.offers.statusData]);

  /**
   * Fonction qui permet de gérer les boutons de gauche,
   * et notamment de changer l'url en fonction du transmetteur
   * - Entreprise : on enlève le statut "Publié"
   * - Autre : on rajoute le statut "Publié" s'il n'est pas déjà présent
   * @param {Number} index
   * @returns {void}
   */

  const onBtnClick = (index) => {
    const newUrl = filterByTransmitter(
      "offers",
      url,
      btnList[index].transmitter,
      props.auth.userConnected.id
    );
    const publishedState = props.offers.statusData.find(
      (state) => state.statusLabel === "Publié"
    ).id;
    const stateParams = [
      ...newUrl.searchParams.getAll("state"),
      ...newUrl.searchParams.getAll("state[]"),
    ];

    newUrl.searchParams.delete("state[]");
    newUrl.searchParams.delete("state");
    if (stateParams.length === 1) {
      props.offers.statusData.forEach((state) => {
        if (state?.id === stateParams[0]) {
          if (
            btnList[index].transmitter.length === 1 &&
            btnList[index].transmitter[0] === RECRUTEUR
          ) {
            if (state?.id !== publishedState) {
              newUrl.searchParams.append("state[]", state?.id);
            }
          } else {
            newUrl.searchParams.append("state[]", state?.id);
          }
        }
      });
    } else {
      props.offers.statusData.forEach((state) => {
        if (
          btnList[index].transmitter[0] === RECRUTEUR &&
          btnList[index].transmitter?.length === 1 &&
          state?.id === publishedState
        ) {
          return;
        }
        newUrl.searchParams.append("state[]", state?.id);
      });
    }
    // Ajoute l'université
    newUrl.searchParams.set("university", props.auth.universityConnected?.id);
    setUrl(newUrl);
    props.handleUpdateOffers({
      currentTransmitter: btnList[index].transmitter,
      dataFromPagination: null,
    });
    var i = 0;
    setBtnList(
      btnList.map((btn) => {
        btn.isActive = i === index;
        i++;
        return btn;
      })
    );
  };

  return (
    <div className="gestion_depot_offre" ref={depotOffreRef}>
      <div className="gestion_depot_offre__title">
        <h1 className="h1_trait_dessus">
          {props.auth.isAdmin || props.auth.isValidator
            ? t("offerItem.handleOfferAdmin")
            : t("offerItem.handleOffer")}
        </h1>
        <Management
          states={states}
          btnEntreprise={btnList[2]}
          btnDiffuseur={btnList[3]}
          actualPage={"offre"}
          currentTransmitter={
            btnList.filter((btn) => btn.isActive === true)[0].transmitter
          }
          updateFunction={props.handleUpdateOffers}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          url={url}
          setUrl={setUrl}
        ></Management>
      </div>

      <div className="gestion_depot_offre__container">
        <div className="gestion_depot_offre__container__left">
          {/* Gestion des boutons de gauche */}
          {(props.auth.isAdmin || props.auth.isValidator) &&
            btnList.map((btnStatus, index) => {
              return (
                <button
                  className={
                    btnStatus.isActive
                      ? "btn-bleu " + "item" + index
                      : "btn-blanc " + "item" + index
                  }
                  onClick={() => onBtnClick(index)}
                  key={index}
                >
                  {btnList[index].text}
                </button>
              );
            })}
        </div>

        <div className="gestion_depot_offre__container__right">
          {props.offers.dataFromPagination?.loaded > 0 ? (
            props.offers.dataFromPagination.data.length > 0 ? (
              props.offers.dataFromPagination.data[0]?.state &&
              props.offers.dataFromPagination.data
                .filter(
                  (offre) =>
                    offre.state.statusLabel !== "Brouillon" ||
                    offre.createdByUser?.id === props.auth.userConnected.id
                )
                .sort((a, b) => new Date(b.modifiedAt) - new Date(a.modifiedAt))
                .map((offre) => (
                  <GestionDepotOffreCard
                    key={offre.id}
                    titre={offre.title}
                    entreprise={
                      offre.companyName ? offre.companyName : "Non renseigné"
                    }
                    contrat={
                      offre.typeOfContract
                        ? offre.typeOfContract.label
                        : offre.offerCategory?.label
                    }
                    lieu={offre.city ? offre.city : "Non renseigné"}
                    statut={offre.state}
                    image={offre.company?.url}
                    niveau={
                      offre.levelOfEducations[0] !== undefined &&
                      offre.levelOfEducations[0]
                        ? offre.levelOfEducations[0].label
                        : "Non renseigné"
                    }
                    description={truncate(stripHtml(offre?.description), 80)}
                    id={offre.id}
                    offre={offre}
                    feedback={offre.feedback?.feedback}
                  />
                ))
            ) : (
              <span>{t("offresApresConnexionPage.manageError")}</span>
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
      <TempPagination
        result={props.offers.dataFromPagination}
        refItemParent={depotOffreRef}
        rows={itemsPerPage}
      />
    </div>
  );
};

GestionDepotOffre.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.object,
  handleUpdateOffers: PropTypes.func,
  handleUpdateUniversities: PropTypes.func,
  offers: PropTypes.object,
  pagination: PropTypes.object,
  handleUpdatePagination: PropTypes.func,
  universities: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
  offers: state.offers,
  pagination: state.pagination,
  universities: state.universities,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateOffers: (value) => {
    dispatch(updateOffers(value));
  },
  handleUpdateUniversities: (value) => {
    dispatch(updateUniversities(value));
  },
  handleUpdatePagination: (value) => {
    dispatch(updatePagination(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GestionDepotOffre);
