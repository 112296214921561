import React from "react";
import PropTypes from "prop-types";
import "./SearchBar.scss";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

// import { HiSearch } from "react-icons/hi";

/**
 * Barre de recherche dans tout le site
 */
// TO DO : intégrer la barre de recherche
const SearchBar = (props) => {
  const { t } = useTranslation("common");

  return (
    <div className="searchBar">
      {!props.auth.isConnected ? (
        <h2 className="searchBar__title">{t("searchBar.introduction")}</h2>
      ) : (
        <h2 className="searchBar__titleDisconnected">
          {t("searchBar.introduction")}
        </h2>
      )}
      <div className="textContainer">
        <p className="searchBar__text">{t("searchBar.description")}</p>
      </div>
      {/* <HiSearch className="searchBar__icon" />
      <input
        type="text"
        className="searchBar__input"
        placeholder={searchText}
      /> */}
    </div>
  );
};

SearchBar.propTypes = {
  searchText: PropTypes.string,
  auth: PropTypes.object,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
