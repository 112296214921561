import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import "./ModalQuitterGroupe.scss";
import { connect } from "react-redux";
import Modal from "../Modal";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import { fetchGet } from "Services/api";

const ModalQuitterGroupe = ({ visible, setVisible, auth }) => {
  const postLeave = async (url, data) => {
    let errorResponse = null;
    const response = await axios
      .post(`${process.env.REACT_APP_BASE_URL_API}${url}`, data, {
        headers: auth.token
          ? {
              Authorization: `Bearer ${auth.token}`,
            }
          : {},
      })
      .catch((error) => {
        errorResponse = error.response.data.detail;
      });
    return response || errorResponse;
  };

  const fetchGroupMemberId = async () => {
    const groupMembers = await fetchGet("/group_members", auth.token, {
      user: auth.userConnected.id,
      groupSource: visible.id,
    });
    return groupMembers.find(
      (member) => member.groupMemberStatus.label === "ACTIF"
    ).id;
  };

  const handleAccept = async () => {
    // On récupère l'id du membre du groupe, qui est associé à la fois au groupe et à l'utilisateur connecté
    const groupMemberId = await fetchGroupMemberId();
    const result = groupMemberId
      ? await postLeave("/group_members/" + groupMemberId + "/leave_group", {})
      : "Une erreur est survenue";
    if (result && result.status === 200)
      setVisible({ ...visible, state: false, result: result });
    else setVisible({ ...visible, state: false, result: result });
  };

  const handleReject = () => {
    setVisible({ ...visible, state: false });
  };

  return (
    <div>
      <Modal
        visible={visible.state}
        setVisible={() => setVisible({ ...visible, state: false })}
        header={"Confirmation"}
        onHide={() => setVisible({ ...visible, state: false })}
        height="fit-content"
        width="80%"
        className="modal modal_quitter_groupe"
      >
        <div className="quitter_groupe-content">
          {`Souhaitez-vous quitter le groupe '${visible.title}' ?`}
        </div>
        <div className={"quitter_groupe-buttons"}>
          <BtnBlanc
            btnTexte="Annuler"
            action={"button"}
            btnAction={handleReject}
          />
          <BtnBleu btnTexte="Quitter le groupe" btnAction={handleAccept} />
        </div>
      </Modal>
    </div>
  );
};

ModalQuitterGroupe.propTypes = {
  visible: PropTypes.object.isRequired,
  setVisible: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ModalQuitterGroupe);
