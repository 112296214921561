import React from "react";
import { connect } from "react-redux";
import { updateAuth } from "Redux/Actions/authActions";
import { updateMenu } from "Redux/Actions/menuActions";
import "./OffresAccueilHorsConnexion.scss";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const OffresAccueilHorsConnexion = (props) => {
  const { t } = useTranslation("common");

  return (
    <div className="offresHorsConnexionContainer">
      <h2 className="offresHorsConnexionContainer__h2">
        {t("offresHorsConnexion.title_accueil")}
      </h2>
      <button
        className="offresHorsConnexionContainer__button"
        onClick={() => {
          props.handleUpdateMenu({
            activeItemOffre: props.items.itemsOffre.findIndex(
              (item) => item.label == "Trouver une offre"
            ),
          });
          window.location.href = "./offres";
        }}
      >
        {t("offresHorsConnexion.button")}
      </button>
    </div>
  );
};

OffresAccueilHorsConnexion.propTypes = {
  auth: PropTypes.object.isRequired,
  items: PropTypes.object.isRequired,
  handleUpdateMenu: PropTypes.func.isRequired,
  handleUpdateAuth: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateMenu: (value) => dispatch(updateMenu(value)),
  handleUpdateAuth: (value) => dispatch(updateAuth(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OffresAccueilHorsConnexion);
