import React, { useEffect } from "react";
import { ProgressBar } from "primereact/progressbar";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ProgressBarCustom = (props) => {
  const userConnected = props.auth.userConnected;
  useEffect(() => {}, [userConnected]);
  const { t } = useTranslation("common");

  return (
    <p>
      <p>
        {t("profil.completion_status") +
          userConnected.profileCompletionRate / 100}
        %
      </p>
      <ProgressBar
        value={userConnected.profileCompletionRate / 100}
        showValue={false}
        className="profilDescription__progressBar"
      ></ProgressBar>
    </p>
  );
};

ProgressBarCustom.propTypes = {
  auth: PropTypes.object,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ProgressBarCustom);
