import React, { useState, useRef } from "react";
import "./GestionEditoriale.scss";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { InputTextarea } from "primereact/inputtextarea";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import logo_paris1 from "assets/logo_paris1.webp";
import { updateAdmin } from "Redux/Actions/adminActions";
import { formatDateHourFrenchLocale } from "Services/functions";
import { Toast } from "primereact/toast";
import axios from "axios";

/**
 * Permet d'afficher la page de gestion éditoriale
 * Page accessible uniquement pour les administrateurs
 */
const GestionEditoriale = (props) => {
  const successUpdate = useRef(null);
  const [openMenu, setOpenMenu] = useState("Présentation Réseau Alumni");

  // fonction permettant de faire apparaitre la partie droite au niveau du scss
  function setClass(item) {
    if (item == openMenu) {
      return "Ouvert";
    } else {
      return "nonOuvert";
    }
  }

  const putDataToApi = async () => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL_API}/editorial_managements/${props.admin.editManagement.id}`,
        { ...props.admin.editManagement, editedAt: new Date() },
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        res.status === 200 &&
          props.handleAdmin({
            editManagement: {
              ...res.data,
            },
          });
        res.status === 200 && showSuccess();
      })
      .catch((err) => console.log(err))
      .finally((fin) => console.log(fin));
  };
  //? Fonction pour appeler le Toast
  const showSuccess = () => {
    successUpdate.current.show({
      severity: "success",
      summary: "Mise à jour effectuée avec succès",
      detail: "Votre mise à jour a bien été prise en compte",
      life: 3000,
    });
  };
  //? MAJ de Redux
  function handleUpdate(currentEvent) {
    const name = currentEvent.name;
    const value = currentEvent.value;
    const newEditManagement = { ...props.admin.editManagement, [name]: value };
    props.handleAdmin({
      editManagement: {
        ...newEditManagement,
      },
    });
  }
  //? Rendu du composant à droite en fonction de la navbar de gauche
  function getContent(item) {
    switch (item) {
      case "Présentation Réseau Alumni":
        return (
          <>
            <h2>Présentation Réseau Alumni</h2>
            <h6>Présentation Réseau Alumni</h6>
            <InputTextarea
              rows={5}
              name="presentationRPAlumni"
              value={props.admin.editManagement.presentationRPALumni}
              onChange={(e) => handleUpdate(e.target)}
            />
          </>
        );
      case "Nos universités":
        return (
          <>
            <h2>Nos universités</h2>
            <h6>Comment faire partie de Réseau Alumni</h6>
            <InputTextarea
              rows={5}
              name="partieRP"
              value={props.admin.editManagement.partieRP}
              onChange={(e) => handleUpdate(e.target)}
            />
            <h6>Présentation de votre universités (500 caractètres max)</h6>
            <InputTextarea
              rows={5}
              name="presentationUniv"
              value={props.admin.editManagement.presentationUniv}
              onChange={(e) => handleUpdate(e.target)}
            />
          </>
        );
      case "Espace recruteur":
        return (
          <>
            <h2>Espace recruteur</h2>
            <h6>Comment rejoindre Réseau Alumni</h6>
            <InputTextarea
              rows={5}
              name="rejoindreRP"
              value={props.admin.editManagement.rejoindreRP}
              onChange={(e) => handleUpdate(e.target)}
            />
          </>
        );
      case "Charte Réseau Alumni":
        return (
          <>
            <h2>Charte Réseau Alumni</h2>
            <h6>Charte Réseau Alumni</h6>
            <InputTextarea
              name="charteRPAlumni"
              rows={5}
              value={props.admin.editManagement.charteRPAlumni}
              onChange={(e) => handleUpdate(e.target)}
            />
          </>
        );
      default:
        break;
    }
  }

  //? Navigation latérale
  function setMenu(item) {
    switch (item) {
      case "Présentation Réseau Alumni":
        setOpenMenu("Présentation Réseau Alumni");
        break;
      case "Nos universités":
        setOpenMenu("Nos universités");
        break;
      case "Espace recruteur":
        setOpenMenu("Espace recruteur");
        break;
      case "Charte Réseau Alumni":
        setOpenMenu("Charte Réseau Alumni");
        break;
      default:
        break;
    }
  }

  return (
    <div className="gestion_editoriale">
      <Toast ref={successUpdate} />
      {props.auth.isConnected && (
        <div className="gestion_editoriale__container">
          <div className="gestion_editoriale__container__title">
            <h1 className="h1_trait_dessus">
              {props.items[props.activeIndex].label}
            </h1>
            <div className="gestion_editoriale__container__title__filtre">
              <h6>
                Dernière modification le{" "}
                {formatDateHourFrenchLocale(
                  props.admin.editManagement.editedAt
                )}
              </h6>
              <img
                src={logo_paris1}
                alt="Logo université Paris 1"
                title="Logo université Paris 1"
              />
            </div>
          </div>
          <div className="gestion_editoriale__container__content">
            <div className="menu_vertical">
              <h2 className="top">AVANT CONNEXION</h2>
              <h3
                onClick={() => setMenu("Présentation Réseau Alumni")}
                className={setClass("Présentation Réseau Alumni")}
              >
                Présentation Réseau Alumni
              </h3>
              <h3
                onClick={() => setMenu("Nos universités")}
                className={setClass("Nos universités")}
              >
                Nos universités
              </h3>
              <h3
                onClick={() => setMenu("Espace recruteur")}
                className={setClass("Espace recruteur")}
              >
                Espace recruteur
              </h3>
              <h2>APRÈS CONNEXION</h2>
              <h3
                onClick={() => setMenu("Charte Réseau Alumni")}
                className={setClass("Charte Réseau Alumni")}
              >
                Charte Réseau Alumni
              </h3>
            </div>
            <div className="gestion_editoriale__container__content__right">
              {getContent(openMenu)}
              <BtnBleu
                btnTexte="Mettre à jour"
                btnAction={() => putDataToApi()}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

GestionEditoriale.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.array,
  activeIndex: PropTypes.number,
  checked: PropTypes.bool,
  setChecked: PropTypes.func,
  admin: PropTypes.object,
  handleAdmin: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  admin: state.admin,
});

const mapDispatchToProps = (dispatch) => ({
  handleAdmin: (value) => {
    dispatch(updateAdmin(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GestionEditoriale);
