import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateMenu } from "Redux/Actions/menuActions";
import "./MecenatGroupeAccueil.scss";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const MecenatGroupeAccueil = (props) => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  const donBlock = () => {
    return (
      <>
        <h2 className="title">{t("dons.title_mecene")}</h2>
        <div className="mecenatProvisoire" title={t("dons.description")}>
          {t("dons.description")}
        </div>
      </>
    );
  };

  const groupBlock = () => {
    return (
      <>
        <h2 className="title">{t("reseauDynamique.title_groupe")}</h2>
        <button
          className="MecenatGroupeAccueil btn-bleu"
          onClick={() => {
            props.handleMenu({
              activeItemGroupes: props.items.itemsGroupes.findIndex(
                (v) => v.label == "Mes groupes d'échanges"
              ),
            });
            navigate("/groupes");
          }}
        >
          {t("reseauDynamique.button_connected")}
        </button>
      </>
    );
  };

  return (
    <div className="MecenatGroupeAccueil_container">
      {/* Desktop */}
      <div className="MecenatGroupeAccueil_container__boxes">
        <div className="MecenatGroupeAccueil_container__boxes__box first">
          {donBlock()}
        </div>
        <div className="MecenatGroupeAccueil_container__boxes__box second"></div>
        <div className="MecenatGroupeAccueil_container__boxes__box third">
          {groupBlock()}
        </div>
        <div className="MecenatGroupeAccueil_container__boxes__box fourth"></div>
      </div>
      {/* Mobile */}
      <div className="MecenatGroupeAccueil_container__boxesResponsive">
        <div className="MecenatGroupeAccueil_container__boxesResponsive__box first">
          {donBlock()}
        </div>
        <div className="MecenatGroupeAccueil_container__boxesResponsive__box second">
          {groupBlock()}
        </div>
      </div>
    </div>
  );
};

MecenatGroupeAccueil.propTypes = {
  handleMenu: PropTypes.func,
  items: PropTypes.object,
};

const mapStateToProps = (state) => ({
  items: state.items,
});

const mapDispatchToProps = (dispatch) => ({
  handleMenu: (value) => {
    dispatch(updateMenu(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MecenatGroupeAccueil);
