import React from "react";
import { Link } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import "../BtnBlanc/btn-blanc.scss";
import BtnBlanc from "../BtnBlanc/btn-blanc";
import { useTranslation } from "react-i18next";

/**
 * Permet d'afficher les boutons blancs
 */
const BtnRetour = () => {
  const { t } = useTranslation("common");
  return (
    <Link to={{ pathname: `/profil` }}>
      <BtnBlanc
        btnIcone={<FaChevronLeft />}
        btnTexte={t("profil.title_profil")}
      />
    </Link>
  );
};

export default BtnRetour;
