import React, { useState, useRef } from "react";
import "./Annuaire.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Footer from "Components/Footer/Footer";
import AnnuaireEntrepriseContainer from "Components/Annuaire/AnnuaireEntreprise/AnnuaireEntrepriseContainer/AnnuaireEntrepriseContainer";
import AnnuaireAlumniContainer from "Components/Annuaire/AnnuaireAlumni/AnnuaireAlumniContainer/AnnuaireAlumniContainer";
import Header from "Components/Header/Header";
import "../../Components/Annuaire/AnnuaireEtudiant/AnnuaireEtudiantContainer/AnnuaireEtudiantContainer.scss";
import "../../Components/Annuaire/AnnuaireEtudiant/AnnuaireEtudiantSearchBar/AnnuaireEtudiantSearchBar.scss";

/**
 * Permet d'afficher la page d'annuaire
 */
const Annuaire = (props) => {
  const [, setActiveIndex] = useState(0);

  const annuaire = useRef(0);

  function setActiveAnnuaire(i) {
    annuaire.current = i;
  }
  function getComponentWithIndex() {
    switch (props.items.activeItemAnnuaire) {
      case 0:
        return (
          <AnnuaireAlumniContainer
            setActiveIndex={setActiveAnnuaire}
            items={props.items.itemsAnnuaire}
            bouton="VOIR TOUS LES ALUMNI "
            activeIndex={annuaire.current}
          />
        );
      case 1:
        return (
          <AnnuaireEntrepriseContainer
            setActiveIndex={setActiveIndex}
            items={props.items.itemsAnnuaire}
            bouton="VOIR TOUTES LES ENTREPRISES "
          />
        );
      default:
        return (
          <AnnuaireAlumniContainer
            setActiveIndex={setActiveAnnuaire}
            items={props.items.itemsAnnuaire}
            bouton="VOIR TOUS LES ALUMNI "
            activeIndex={annuaire.current}
          />
        );
    }
  }
  return (
    <div className="annuaire">
      {!props.auth.isConnected && <Header />}
      {props.auth.isConnected && getComponentWithIndex()}
      <Footer />
    </div>
  );
};

Annuaire.propTypes = {
  auth: PropTypes.object,
  items: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  items: state.items,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Annuaire);
