import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Tooltip } from "primereact/tooltip";
import React, { useEffect, useRef, useState } from "react";
import { Companies } from "../Data/Const";
import Margin from "../Margin";

const CompaniesTable = () => {
  const [data, setData] = useState(null);
  const dt = useRef(null);

  useEffect(() => {
    setData(Companies.sectorsData);
  }, []);

  const cols = [
    { field: "name", header: "Nom" },
    { field: "companiesNumber", header: "Nombre d'entreprises" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const exportCSV = () => {
    dt.current.exportCSV(data);
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, data);
        doc.save("secteurs.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const newData = data.map((row) => {
        return [row.name, row.companiesNumber];
      });
      const workBook = xlsx.utils.book_new();
      const workSheetData = [cols.map((col) => col.header), ...newData];
      console.log(workSheetData);
      const workSheet = xlsx.utils.aoa_to_sheet(workSheetData);
      xlsx.utils.book_append_sheet(
        workBook,
        workSheet,
        "Entreprises par secteur"
      );
      xlsx.writeFile(workBook, "secteurs.xlsx");
    });
  };

  const header = (
    <div className="flex align-items-center export-buttons stats_container__table-tooltip">
      <Button
        type="button"
        icon="pi pi-file"
        onClick={() => exportCSV(false)}
        className="mr-2 csv"
        data-pr-tooltip="CSV"
      />
      <Button
        type="button"
        icon="pi pi-file-excel"
        onClick={exportExcel}
        className="p-button-success mr-2 xl"
        data-pr-tooltip="XLS"
      />
      <Button
        type="button"
        icon="pi pi-file-pdf"
        onClick={exportPdf}
        className="p-button-warning mr-2 pdf"
        data-pr-tooltip="PDF"
      />
    </div>
  );

  return (
    <div>
      <div className="stats_container__table">
        <h5 className="stats_container__table-title">
          Nombre d&apos;entreprises par secteur
        </h5>
        <Margin position="bottom" size="xxs" />
        <Tooltip target=".export-buttons>button" position="bottom" />
        <DataTable
          id="offers-table"
          value={data}
          responsiveLayout="scroll"
          paginator
          dataKey="id"
          header={header}
          scrollable
          scrollHeight="500px"
          ref={dt}
          sortField="companiesNumber"
          sortOrder={-1}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Affichage de {first} à {last} sur {totalRecords}"
          rows={10}
          rowsPerPageOptions={[10, 20, 50]}
        >
          {cols.map((col, index) => (
            <Column
              key={index}
              field={col.field}
              header={col.header}
              sortable
            />
          ))}
        </DataTable>
      </div>
    </div>
  );
};

export default CompaniesTable;
