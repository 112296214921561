import React, { useState } from "react";
import "./ReseauDynamique.scss";
import ReseauDynamiqueImg from "assets/ReseauDynamique.webp";
import ReseauDynamiqueIcone from "assets/ReseauDynamiqueIcone.png";
import { useTranslation } from "react-i18next";
import { authenticationRedirection } from "Services/redirections";
import ModalConnexion from "Components/Modal/ModalConnexion/ModalConnexion";

const ReseauDynamique = () => {
  const [visibleModal, setVisibleModal] = useState(false);
  const { t } = useTranslation("common");

  return (
    <div className="ReseauDynamique_container">
      <div className="ReseauDynamique_container__left">
        {" "}
        <img
          src={ReseauDynamiqueImg}
          className="ReseauDynamique_container__left__img"
          alt=""
        />
      </div>
      <div className="ReseauDynamique_container__right">
        <div className="ReseauDynamique_container__right__title">
          {" "}
          <b>{t("reseauDynamique.title")}</b>
        </div>
        <div className="ReseauDynamique_container__right__text">
          {t("reseauDynamique.description")}
        </div>
        <img
          src={ReseauDynamiqueIcone}
          alt=""
          className="ReseauDynamique_container__right__img"
        />
        <button
          onClick={() => {
            if (!authenticationRedirection()) setVisibleModal(!visibleModal);
          }}
          className="ReseauDynamique_container__right__button btn-bleu"
        >
          {t("reseauDynamique.button")}{" "}
        </button>
      </div>
      {visibleModal && (
        <ModalConnexion
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
        />
      )}
    </div>
  );
};

export default ReseauDynamique;
