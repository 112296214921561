import React from "react";
import PropTypes from "prop-types";
import MarkerCustom from "../MarkerCustom/MarkerCustom";

const UsersList = (props) => {
  //? Fonction permettant de retourner le tableau des coordonnées nécessaires pour le marker sur la carte
  const createPositionArray = (lat, lon) => {
    return [parseFloat(lat), parseFloat(lon)];
  };

  return (
    props.users &&
    props.users?.length > 0 &&
    props.users.map((user) => {
      return (
        user?.addressIsPublic && (
          <>
            <MarkerCustom
              position={createPositionArray(user.latitude, user.longitude)}
              userDetail={user}
            />
          </>
        )
      );
    })
  );
};

UsersList.propTypes = {
  users: PropTypes.array,
};

export default UsersList;
