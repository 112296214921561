import React, { useEffect, useRef, useState } from "react";
import "./ModalCreationEvenementGroupe.scss";
import PropTypes from "prop-types";
import { InputText } from "primereact/inputtext";
import BtnBlanc from "Components/Boutons/BtnBlanc/btn-blanc";
import { connect } from "react-redux";
import { updateMenu } from "Redux/Actions/menuActions";
import Modal from "../Modal";
import { Checkbox } from "primereact/checkbox";
import BtnBleu from "Components/Boutons/BtnBleu/btn-bleu";
import { Dropdown } from "primereact/dropdown";
import ChargementImage from "Components/ChargementImage/ChargementImage";
import { Controller, useForm } from "react-hook-form";
import { DateTime } from "luxon";
import ReactDatePicker from "react-datepicker";
import { classNames } from "primereact/utils";
import Loader from "Components/Loader/loader";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import { formatDateDatabase, isNotEmptyArray } from "Services/functions";
import axios from "axios";
import { fetchGet } from "Services/api";
import { updateGroups } from "Redux/Actions/groupsActions";
// import { filterUserGroups } from "Pages/Groupes/Groupes";
// import { updateUsers } from "Redux/Actions/usersActions";
import ReactQuill from "react-quill";
import QuillOptions from "Components/QuillOptions/QuillOptions";
import DOMPurify from "dompurify";
import { t } from "i18next";

const ModalCreationEvenementGroupe = (props) => {
  const [categories, setCategories] = useState(undefined);
  const [inscription, setInscription] = useState(false);

  // ------- TOASTS ----------
  // Toast de succès d'upload d'image
  const uploadToast = useRef(null);
  // Toast de suppression de l'image upload
  const cancelToast = useRef(null);

  // ------- VARIABLES GESTION IMAGES ----------
  // La photo vient-elle de la photothèque ?
  const [phototheque, setPhototheque] = useState(true);
  // Contiendra l'url de l'image choisie (upload ou photothèque)
  const [imageFile, setImageFile] = useState("");
  // ID de l'image choisie (phototheque)
  const [imageId, setImageId] = useState("");

  // Contiendra le fichier upload par l'utilisateur
  const [image, setImage] = useState("");

  // Offre soumise avec succès ?
  const [submitted, setSubmitted] = useState(false);

  // Constantes comprenant les données du formulaire de création d'un événement pour les admins et personnel
  const defaultValues = {
    title: "",
    category: null,
    startingAt: "",
    endingAt: "",
    register: false,
    registerBegin: "",
    registerEnd: "",
    startingHour: "",
    endingHour: "",
    description: "",
    location: "",
    maxNumberOfParticipants: "",
    image: "",
    link: "",
    groupe: props.currentGroupId,
  };

  // Méthodes et variables de react-hook-form
  const {
    control,
    formState: { errors },
    watch,
    getValues,
    setValue,
    handleSubmit,
    reset,
    trigger,
  } = useForm({ defaultValues });

  // Actualisation du champ register si changement de valeur
  useEffect(() => {}, [
    watch("register"),
    watch("startingAt"),
    watch("endingAt"),
    watch("groupe"),
  ]);

  useEffect(() => {
    setValue("groupe", props.currentGroupId);
  }, [props.currentGroupId]);

  // Une fois les catégories chargées, on les stocke dans un tableau :
  useEffect(() => {
    /// Récupérer les catégories, et les filtrer par ordre alphabétique
    if (isNotEmptyArray(props.events.categoriesData)) {
      setCategories([]);
      let tempArray = [];
      props.events.categoriesData.forEach((category) =>
        tempArray.push(category)
      );
      tempArray = tempArray.sort((a, b) => a.label.localeCompare(b.label));
      setCategories(tempArray);
    }
  }, [props.events.categoriesData]);

  // on met à jour les données en local suite à la soumission du formulaire
  const updateEventLists = async () => {
    let groupEventsList = await fetchGet("/event_groups", props.auth.token);
    // let groupsList = await fetchGet("/groups", props.auth.token);
    // let usersList = await fetchGet("/users", props.auth.token);

    props.handleUpdateGroups({
      evenements: groupEventsList,
      // groupsData: groupsList,
      // myGroupsData: filterUserGroups(
      //   groupsList,
      //   usersList,
      //   props.auth.userConnected.id
      // ),
    });
    // props.handleUpdateUsers({
    //   usersData: usersList,
    // });
  };

  const postForm = async (data) => {
    // Variable qui stockera la réponse du serveur
    let response = null;
    // On crée un FormData qui sera envoyé au serveur
    var dataForm = new FormData();
    // On ajoute les données du formulaire au formData
    for (var key in data) dataForm.append(key, data[key]);
    // for (var pair of dataForm.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }

    // Url de l'api
    let url = `${process.env.REACT_APP_BASE_URL_API}${"/event_groups/create"}`;
    axios
      .post(url, dataForm, {
        headers: props.auth.token
          ? {
              accept: "application/json",
              Authorization: `Bearer ${props.auth.token}`,
            }
          : {
              accept: "application/json",
            },
      })
      .then((res) => {
        response = res;
      })
      .catch((error) => console.log(error.message))
      .finally(() => {
        if (response) {
          reset();
          setInscription(false);
          updateEventLists();
          setSubmitted(true);
        }
      });
  };

  /**
   * Remplissage de la key image selon différentes conditions
   * @param {Object} data données pour la requêtes
   * @returns {Object}
   */
  // eslint-disable-next-line no-unused-vars
  const fillImage = (data) => {
    // Si une image a été téléchargée, on met son url local dans la key imageFile
    if (imageFile && !phototheque) {
      data.imageFile = imageFile;
      delete data.imageStockId;
    }
    // Sinon, on met l'url de l'image choisie de la photothèque
    else if (phototheque) {
      data.imageStockId = imageId;
      delete data.imageFile;
      delete data.image;
    }
    return data;
  };

  /**
   * Remplissage de la key category selon différentes conditions
   * @param {Object} data données pour la requêtes
   * @returns {Object}
   */
  const fillCategory = (data) => {
    // Si catégory est vide on met la catégorie par défaut 'Autre'
    if (!data.category) data.category = "Autre";

    // Si la key id existe, pas de besoin de chercher l'id de la catégorie
    if (data.category.id)
      data.category = "/api/event_categories/" + data.category.id;
    /* 
      Sinon, on cherche si la catégorie existe déjà :
      // Si oui : on met l'id de la catégorie
       Si non : on spécifie qu'elle doit être créée
       */ else {
      const category = categories.find(
        (category) => category.label === data.category
      );
      if (category) data.category = "/api/event_categories/" + category.id;
      else data.category = JSON.stringify({ label: data.category });
    }
    return data;
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const cleanEmptyKeys = (data) => {
    // Suppression des keys qui ne sont pas remplies
    for (var key in data) if (data[key] === "" || !data[key]) delete data[key];
    return data;
  };

  /**
   * Remplissage des keys concernant les dates selon différentes conditions
   * Formattage avec {@link formatDateDatabase}
   * @param {Object} data données pour la requêtes
   * @returns {Object}
   */
  const fillDates = (data) => {
    // Assignation des dates de création, de début et de fin de l'événement
    data.createdAt = formatDateDatabase(new Date());
    data.startingAt = data.startingAt
      ? formatDateDatabase(data.startingAt)
      : null;
    data.endingAt = data.endingAt ? formatDateDatabase(data.endingAt) : null;
    // Assignation des dates de début et de fin de l'événement si remplies dans le formulaire
    if (data.startingHour != "" && data.startingHour != null)
      data.startingHour = data.startingHour.toLocaleTimeString(
        navigator.language,
        {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }
      );
    if (data.endingHour != "" && data.endingHour != null)
      data.endingHour = data.endingHour.toLocaleTimeString(navigator.language, {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    // Gestion de l'inscription obligatoire ou non à l'événement
    data.register = inscription;
    // Si oui : assignation des dates de début et de fin de l'inscription
    if (inscription) {
      data.registerEnd = data.registerEnd
        ? formatDateDatabase(data.registerEnd)
        : null;
      data.registerBegin = data.registerBegin
        ? formatDateDatabase(data.registerBegin)
        : null;
    }
    return data;
  };

  // Remplissage du formulaire manuel pour certains cas
  const fillData = (data) => {
    // On récupère le créateur de l'événement de groupe
    let createdByUser = "/api/users/" + props.auth.userConnected.id;
    data.description = DOMPurify.sanitize(data.description);
    if (data.groupe)
      data.associatedGroup = "/api/groups/" + data.groupe.toString();
    delete data.grouep;
    data.createdById = createdByUser;
    if (isNaN(data.maxNumberOfParticipants))
      delete data.maxNumberOfParticipants;
    data.forAllUniversities = true;
    if (data.url == "" || data.url === undefined) delete data.url;
    // Remplissage partie photothèque
    data = fillImage(data);
    // Remplissage catégorie de l'événement de groupe
    data = fillCategory(data);
    // Remplissage des champs dates
    data = fillDates(data);
    // On vide les champs qui sont nuls
    data = cleanEmptyKeys(data);
    return data;
  };

  const validateHours = (values) => {
    if (!values.startingAt || !values.endingAt) return true;
    // Check if start and end dates are identical AND hours are filled
    const startingDate = DateTime.fromJSDate(values.startingAt);
    const endingDate = DateTime.fromJSDate(values.endingAt);
    if (
      values.startingHour &&
      values.endingHour &&
      startingDate.ordinal === endingDate.ordinal &&
      startingDate.year === endingDate.year
    ) {
      // Check if start hour is before end hour
      if (
        DateTime.fromJSDate(values.startingHour) >
        DateTime.fromJSDate(values.endingHour)
      )
        return t("eventApresConnexion.createHoraireValidate");
    }
    return true;
  };

  // Callback en cas de soumission du formulaire (et validation réussie côté front)
  const onSubmit = (data) => {
    data = fillData(data);
    console.log(data);
    postForm(data);
  };

  return (
    <div key={props.currentGroupId}>
      <Modal
        visible={props.visibleModalEvent}
        setVisible={props.setVisibleModalEvent}
        header={
          props.auth.isAdmin
            ? "Créer un événement de groupe"
            : "Proposer un événement de groupe"
        }
        height="fit-content"
        width="80%"
      >
        <div className="groupe_container">
          <Toast ref={uploadToast} />
          <Toast ref={cancelToast} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="creation_groupe_container">
              <div className="question_double_column">
                <div className="question">
                  <h2
                    htmlFor="title"
                    className={classNames({ "p-error": errors.title })}
                  >
                    Titre de l&apos;événement *
                  </h2>
                  <Controller
                    name="title"
                    control={control}
                    rules={{ required: "Le titre est requis" }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        placeholder="Forum de l'emploi, job dating de rentrée"
                        autoFocus
                        className={
                          (classNames({
                            "p-invalid": fieldState.invalid,
                          }),
                          "input_actu")
                        }
                      />
                    )}
                  />
                  {getFormErrorMessage("title")}
                </div>
                <div className="question">
                  <h2
                    htmlFor="category"
                    className={classNames({ "p-error": errors.category })}
                  >
                    Catégorie de l&apos;événement
                  </h2>
                  {categories && categories.length > 0 ? (
                    <Controller
                      name="category"
                      control={control}
                      render={({ field, fieldState }) => (
                        <Dropdown
                          id={field.name}
                          {...field}
                          editable
                          onChange={(e) => field.onChange(e.value)}
                          optionLabel="label"
                          options={categories}
                          placeholder="After-work, forum, job dating"
                          className={
                            (classNames({
                              "p-invalid": fieldState.invalid,
                            }),
                            "input_evenement")
                          }
                        />
                      )}
                    />
                  ) : (
                    <Loader />
                  )}

                  {getFormErrorMessage("category")}
                </div>
              </div>
              <div className="question_double_column">
                <div className="question">
                  <h2
                    htmlFor="startingAt"
                    className={classNames({ "p-error": errors.startingAt })}
                  >
                    Dates de l&apos;événement *
                  </h2>
                  <div className="question_double">
                    <div className="question">
                      <label>Date de début</label>
                      <Controller
                        name="startingAt"
                        control={control}
                        rules={{
                          required: "La date de début est requise",
                          validate: () =>
                            DateTime.fromJSDate(getValues().startingAt) <=
                            DateTime.fromJSDate(getValues().endingAt)
                              ? true
                              : "La date de début doit être avant la date de fin",
                        }}
                        render={({ field, fieldState }) => (
                          <ReactDatePicker
                            autoComplete="off"
                            {...field}
                            id={field.name}
                            wrapperClassName="modal_creation_react_date_picker"
                            selected={field.value}
                            dateFormat="dd/MM/yyyy"
                            locale="fr"
                            onChange={(e) => {
                              field.onChange(e);
                              trigger("startingAt");
                            }}
                            className={
                              (classNames({
                                "p-invalid": fieldState.invalid,
                              }),
                              "small_item p-inputtext p-component")
                            }
                          />
                        )}
                      />
                    </div>
                    <div className="question">
                      <label>Date de fin</label>
                      <Controller
                        name="endingAt"
                        control={control}
                        rules={{
                          required: "La date de fin est requise",
                        }}
                        render={({ field, fieldState }) => (
                          <ReactDatePicker
                            autoComplete="off"
                            {...field}
                            selected={field.value}
                            locale="fr"
                            dateFormat="dd/MM/yyyy"
                            onChange={(e) => {
                              field.onChange(e);
                              trigger("startingAt");
                            }}
                            className={
                              (classNames({
                                "p-invalid": fieldState.invalid,
                              }),
                              "small_item p-inputtext p-component")
                            }
                          />
                        )}
                      />
                    </div>
                  </div>
                  {getFormErrorMessage("startingAt")}
                  {getFormErrorMessage("endingAt")}
                </div>
                <div className="question">
                  <h2>Horaires de l&apos;événement</h2>
                  <div className="question_double">
                    <div className="question">
                      <label>Heure de début</label>
                      <Controller
                        name="startingHour"
                        control={control}
                        rules={{
                          validate: () => validateHours(getValues()),
                        }}
                        render={({ field, fieldState }) => (
                          <Calendar
                            id={field.name}
                            {...field}
                            format="HH:mm"
                            showTime
                            timeOnly
                            stepMinute={15}
                            hourFormat="24"
                            className={
                              (classNames({
                                "p-invalid": fieldState.invalid,
                              }),
                              "input_evenement text-center")
                            }
                          />
                        )}
                      />
                      {getFormErrorMessage("startingHour")}
                    </div>
                    <div className="question">
                      <label>Heure de fin</label>
                      <Controller
                        name="endingHour"
                        control={control}
                        render={({ field, fieldState }) => (
                          <Calendar
                            id={field.name}
                            {...field}
                            format="HH:mm"
                            showTime
                            stepMinute={15}
                            timeOnly
                            hourFormat="24"
                            className={
                              (classNames({
                                "p-invalid": fieldState.invalid,
                              }),
                              "input_evenement text-center")
                            }
                          />
                        )}
                      />
                      {getFormErrorMessage("endingHour")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="question_double_column">
                <div className="question">
                  <h2
                    htmlFor="location"
                    className={classNames({ "p-error": errors.location })}
                  >
                    Lieu de l&apos;événement *
                  </h2>
                  <Controller
                    name="location"
                    control={control}
                    rules={{ required: "Le lieu est requis" }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        placeholder="Jardin du Luxembourg, Visio"
                        className={
                          (classNames({
                            "p-invalid": fieldState.invalid,
                          }),
                          "input_evenement")
                        }
                      />
                    )}
                  />
                  {getFormErrorMessage("location")}
                </div>
                <div className="question">
                  <h2
                    htmlFor="maxNumberOfParticipants"
                    className={classNames({
                      "p-error": errors.maxNumberOfParticipants,
                    })}
                  >
                    Nombre maximum de participants
                  </h2>
                  <Controller
                    name="maxNumberOfParticipants"
                    control={control}
                    rules={{
                      pattern: {
                        value: /^[0-9]*$/,
                        message: "Veuillez entrer un nombre",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        placeholder="50"
                        className={
                          (classNames({
                            "p-invalid": fieldState.invalid,
                          }),
                          "input_evenement",
                          "small_item")
                        }
                      />
                    )}
                  />
                  {getFormErrorMessage("maxNumberOfParticipants")}
                </div>
              </div>
              <div className="desc">
                <h2
                  htmlFor="description"
                  className={classNames({ "p-error": errors.description })}
                >
                  Description de l&apos;événement *
                </h2>
                <Controller
                  name="description"
                  control={control}
                  rules={{
                    required: "La description est requise",
                    minLength: {
                      value: 12,
                      message:
                        "La description doit faire au moins 12 caractères",
                    },
                  }}
                  render={({ field }) => (
                    <ReactQuill
                      id={field.name}
                      {...field}
                      theme="snow"
                      modules={QuillOptions.modules}
                      formats={QuillOptions.formats}
                      rows={5}
                    />
                  )}
                />
                {getFormErrorMessage("description")}
              </div>
              <div className="question checkbox">
                <Controller
                  name="register"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Checkbox
                      inputId={field.name}
                      onChange={(e) => {
                        field.onChange(e.checked);
                        setInscription(e.checked);
                      }}
                      checked={inscription}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label
                  htmlFor="register"
                  className={classNames(
                    { "p-error": errors.register },
                    "label_checkbox label_modalEvent"
                  )}
                >
                  Inscription obligatoire pour participer à l&apos;événement
                </label>
              </div>

              {inscription && (
                <div className="question_double_column">
                  <div className="question">
                    <h2
                      className={classNames({
                        "p-error": errors.registerBegin,
                      })}
                    >
                      Dates limites des inscriptions
                    </h2>
                    <div className="question_double">
                      <div className="question">
                        <h2
                          className={classNames({
                            "p-error": errors.registerBegin,
                          })}
                        >
                          Date de début d&apos;inscription
                        </h2>
                        <Controller
                          name="registerBegin"
                          control={control}
                          rules={{
                            required: "La date de début est requise",
                            validate: () =>
                              DateTime.fromJSDate(getValues().registerBegin) <=
                              DateTime.fromJSDate(getValues().registerEnd)
                                ? true
                                : "La date de début doit être avant la date de fin",
                          }}
                          render={({ field, fieldState }) => (
                            <ReactDatePicker
                              autoComplete="off"
                              {...field}
                              selected={field.value}
                              locale="fr"
                              dateFormat="dd/MM/yyyy"
                              onChange={(e) => field.onChange(e)}
                              className={
                                (classNames({
                                  "p-invalid": fieldState.invalid,
                                }),
                                "small_item p-inputtext p-component")
                              }
                            />
                          )}
                        />
                        {getFormErrorMessage("registerBegin")}
                      </div>
                      <div className="question">
                        <h2
                          className={classNames({
                            "p-error": errors.registerBegin,
                          })}
                        >
                          Date de fin d&apos;inscription
                        </h2>
                        <Controller
                          name="registerEnd"
                          control={control}
                          rules={{
                            required: "La date de fin est requise",
                          }}
                          render={({ field, fieldState }) => (
                            <ReactDatePicker
                              autoComplete="off"
                              {...field}
                              selected={field.value}
                              locale="fr"
                              dateFormat="dd/MM/yyyy"
                              onChange={(e) => field.onChange(e)}
                              className={
                                (classNames({
                                  "p-invalid": fieldState.invalid,
                                }),
                                "small_item p-inputtext p-component")
                              }
                            />
                          )}
                        />
                        {getFormErrorMessage("registerEnd")}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="question">
                <h2> Ajouter un lien URL</h2>
                <Controller
                  name="link"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      placeholder="https://"
                      id={field.name}
                      {...field}
                      value={field.value}
                      className={
                        (classNames({
                          "p-invalid": fieldState.invalid,
                        }),
                        "input_evenement")
                      }
                    />
                  )}
                />
                {getFormErrorMessage("link")}
              </div>

              <div className="question">
                <ChargementImage
                  image={image}
                  setImage={setImage}
                  imageFile={imageFile}
                  setImageFile={setImageFile}
                  setImageId={setImageId}
                  uploadToast={uploadToast}
                  cancelToast={cancelToast}
                  phototheque={phototheque}
                  setPhototheque={setPhototheque}
                  path="events"
                />
              </div>
              <div className="question bouton">
                {submitted && (
                  <Modal
                    visible={submitted}
                    setVisible={setSubmitted}
                    header={"Votre événemment de groupe a bien été publié"}
                    className="modal modal-event"
                  >
                    <i className="pi pi-send"></i>
                    <div className="description">
                      <p>
                        <center>
                          Votre événemment de groupe a bien été publié
                        </center>
                      </p>
                    </div>
                    <div className="modals-buttons">
                      <BtnBleu
                        btnTexte="Déposer un nouvel Événement de groupe"
                        btnAction={(e) => {
                          e.preventDefault();
                          setSubmitted(false);
                        }}
                      />
                      <BtnBlanc
                        btnTexte="Retour sur mes groupes"
                        btnAction={(e) => {
                          e.preventDefault();
                          setSubmitted(false);
                          props.setVisibleModalEvent(false);
                        }}
                      />
                    </div>
                  </Modal>
                )}
                <BtnBleu btnTexte={"Publier l'événement de groupe"} />
                <BtnBlanc
                  btnTexte="Annuler"
                  btnAction={() => props.setVisibleModalEvent(false)}
                />
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

ModalCreationEvenementGroupe.propTypes = {
  title: PropTypes.string,
  chapeau: PropTypes.string,
  bouton: PropTypes.string,
  activeIndex: PropTypes.number,
  setActiveIndex: PropTypes.func,
  items: PropTypes.object,
  handleMenu: PropTypes.func,
  creation: PropTypes.bool,
  setCreation: PropTypes.func,
  visibleModalEvent: PropTypes.bool,
  setVisibleModalEvent: PropTypes.func,
  currentGroupId: PropTypes.number,
  // users: PropTypes.object,
  events: PropTypes.object,
  auth: PropTypes.object,
  groups: PropTypes.object,
  handleUpdateGroups: PropTypes.func,
  // handleUpdateUsers: PropTypes.func,
};

const mapStateToProps = (state) => ({
  items: state.items,
  auth: state.auth,
  groups: state.groups,
  // users: state.users,
  events: state.events,
});

const mapDispatchToProps = (dispatch) => ({
  handleMenu: (value) => {
    dispatch(updateMenu(value));
  },
  handleUpdateGroups: (value) => {
    dispatch(updateGroups(value));
  },
  // handleUpdateUsers: (value) => {
  //   dispatch(updateUsers(value));
  // },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalCreationEvenementGroupe);
